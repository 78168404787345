<template>
  <div>
    <a-row>
      <a-col :span="12" class="tabs">
        <span @click="tabs=1" :class="{active:tabs===1}">新车销量统计</span>
        <span @click="tabs=2"  :class="{active:tabs===2}">售后进厂台次统计</span>
      </a-col>
      <a-col :span="6">

      </a-col>
      <a-col :span="6">
        <a-month-picker @change="onChange" placeholder="请选择报表月份"/>
        <a-button style="margin-left: 10px" type="primary">查看</a-button>
        <a-button>重置</a-button>
      </a-col>
    </a-row>
    <a-row style="clear: both;margin-top: 30px">
      <a-col v-show="tabs===1" :span="24">
        <ve-histogram id="chart" ref="chart" :data="chartData" :colors="colors" :settings="chartSettings"></ve-histogram>
      </a-col>
      <a-col style="min-height: 300px" v-show="tabs===2" :span="24">
        <map-chart style="width: 100%"></map-chart>
      </a-col>
    </a-row>
  </div>
</template>
<script>

import MapChart from "./mapChart";

export default {
  components: {  MapChart },
  data () {
    this.colors = ['#24619e']
    this.chartSettings = {
      axisSite: { right: ['下单率'] },
      yAxisName: ["辆/日"],
    }
    return {
      tabs: 1,
      axisData: [],

      chartData: {
        columns: ["日期", "数值"],
        rows: [
          { "日期": "1", "数值": 30 },
          { "日期": "2", "数值": 12 },
          { "日期": "3", "数值": 29 },
          { "日期": "4", "数值": 17 },
          { "日期": "5", "数值": 23 },
          { "日期": "6", "数值": 10 },
          { "日期": "７", "数值": 22 },
          { "日期": "８", "数值": 33 },
          { "日期": "９", "数值": 22 },
          { "日期": "10", "数值": 44 },
          { "日期": "11", "数值": 34 },
          { "日期": "12", "数值": 23 },
          { "日期": "13", "数值": 34 },
          { "日期": "14", "数值": 23 },
          { "日期": "15", "数值": 12 },
          { "日期": "16", "数值": 3 },
          { "日期": "17", "数值": 15 },
          { "日期": "18", "数值": 2 },
          { "日期": "19", "数值": 16 },
          { "日期": "20", "数值": 2 },
          { "日期": "21", "数值": 25 },
          { "日期": "22", "数值": 6 },
          { "日期": "23", "数值": 13 },
          { "日期": "24", "数值": 21 },
          { "日期": "25", "数值": 12 },
          { "日期": "26", "数值": 25 },
          { "日期": "27", "数值": 12 },
          { "日期": "28", "数值": 22 },
          { "日期": "29", "数值": 23 },
          { "日期": "30", "数值": 12 },
        ],
      },
    }
        ;
  },
  created () {

  },
  methods: {
    onChange (date, dateString) {
     // console.log(date, dateString);
    },
  },
  mounted () {

  },
};
</script>

<style lang="less" scoped>
.tabs {
  span {
    padding: 10px 0;
    margin: 0 10px;
    color: #000000;
    cursor: pointer;
  }

  .active {
    color: #333333;
    font-weight: bold;
    border-bottom: 2px solid #24619e;
    box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  }

}

#chart {
  width: 100%;
  height: 570px;
}
</style>
