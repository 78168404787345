const MONTH_REPORT_API = {
    //单店分析报告（按指标取值）
    GetDealerKpiReportPost: {
        method: "post",
        url: "/XCalculator/GetDealerKpiReport",
    },

    // 获取首页指标数据
    GetMainIndexValue: {
        method: "post",
        url: "/XCalculator/GetMainIndexValue",
    },

    // 全国/区域经营报告
    GetAreaDealerKpiReport: {
        method: "post",
        url: "/XCalculator/GetAreaDealerKpiReport",
    },
    // 全国/区域经营报告_按月份
    GetAreaDealerKpiReportWithMonth: {
        method: "post",
        url: "/XCalculator/GetAreaDealerKpiReportWithMonth",
    },

    // 全国/业绩运营分析(月)_加载菜单
    GetPushDownReportMenu: {
        method: "get",
        url: "/XCalculator/GetPushDownReportMenu",
    },
    // 全国/业绩运营分析(月)_显示报表
    GetPushDownReport: {
        method: "get",
        url: "/XCalculator/GetPushDownReport",
    },

    // 全国/区域经营报告店数量
    GetDealerInputStatistics: {
        method: "get",
        url: "/XCalculator/GetDealerInputStatistics",
    },

    // 根据 Url 生成 PDF
    PdfGenerateByUrl: {
        method: "post",
        url: "/sss/Pdf/GenerateByUrl",
    },

    // 新的店端看板统一接口
    DealerBusinessAnalysisReport: {
        method: "post",
        url: "/XCalculator/DealerBusinessAnalysisReport",
    },
};
export default MONTH_REPORT_API;
