import { simpleFixed } from "@/until/simpleFixed.ts";

const colors = [];
const barMaxWidth = 30;
const axisLine = { show: true };
const yAxisShow = false;
const grid = {
    left: "-2%",
    right: "-4%",
    bottom: "14px",
    // containLabel: true,
};
const position = "bottom";
const grid2 = {
    left: "-1%",
    right: "1%",
    bottom: "14px",
    containLabel: true,
};
const gridLine = {
    left: "-3%",
    right: "1%",
    bottom: "14px",
    containLabel: true,
};

const xAxis = [{
    axisLabel: {
        textStyle: {
            fontSize: 22, color: "black",
        },
        interval: 0,
    }, axisLine,
    // X轴刻度
    /* axisTick: {
        show:true
    }*/
}];
const lineStyle = {
    width: 8,
};
const symbolSize = 20;
const PotItemStyle = {
    borderWidth: 1,
    // backgroundColor: "#000",
    //  borderColor: "#000",
    // color: "#000",
};

/*一个柱图bar万元,一个LINE百分比*/
function myOptionTwo(fontSize) {
    let textStyle = {
        fontSize, color: "black",
    };
    return {
        legend: {
            textStyle,
        },
        xAxis, grid,
        yAxis: [
            {
                name: "万元",
                show: yAxisShow,
                nameTextStyle: {
                    fontSize,
                },
                type: "value",
                axisLine,
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    textStyle,
                    formatter: (val) => {
                        return `${val / 10000}`;
                    },
                },
            }, {
                show: false,
                axisLabel: {
                    textStyle,
                    formatter: (val => {
                        return percentage(val);
                    }),
                },
            },
        ],
        series: [
            {
                barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle,
                        formatter: function(params) {
                            return (params.value / 10000).toFixed(2);
                        },
                    },

                },
            }, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    show: true,
                    position,
                    textStyle,
                    formatter: function(params) {
                        return (params.value * 100).toFixed(1) + "%";
                    },
                },
            },
        ],
    };
}

/*一个柱图bar无单位,一个LINE百分比*/
function myOptionOne(fontSize) {
    let textStyle = {
        fontSize, color: "black",
    };
    return {
        legend: {
            textStyle,
        },
        xAxis, grid,
        yAxis: [
            {
                show: yAxisShow,
                type: "value",
                axisLine,
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    textStyle,
                    formatter: (val) => {
                        return `${val}`;
                    },
                },
            }, {
                show: yAxisShow,
                axisLabel: {
                    textStyle,
                    formatter: (val => {
                        return percentage(val);
                    }),
                },
            },
        ],
        series: [
            {
                barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle,
                        formatter: function(params) {
                            return (params.value).toFixed(0);
                        },
                    },

                },
            }, {
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle,
                        formatter: function(params) {
                            return (params.value * 100).toFixed(1) + "%";
                        },
                    },
                },
            },
        ],
    };
}

/*堆积图黑色 格式化成万元*/
function myOptionStack(fontSize) {
    let textStyle = {
        fontSize, color: "black",
    };
    return {
        legend: {
            textStyle,
        },
        xAxis, grid,
        yAxis: [
            {
                name: "万元",
                show: yAxisShow,
                nameTextStyle: {
                    fontSize,
                },
                type: "value",
                axisLine,
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    textStyle,
                    formatter: (val) => {
                        return `${val / 10000}`;
                    },
                },
            },
        ],
        series: [
            {
                barMaxWidth,
                label: {
                    normal: {
                        show: true,
                        textStyle,
                        formatter: function(params) {
                            return (params.value / 10000).toFixed(2);
                        },
                    },
                },
            }, {
                label: {
                    normal: {
                        show: true,
                        textStyle,
                        formatter: function(params) {
                            return (params.value / 10000).toFixed(2);
                        },
                    },
                },
            }, {
                label: {
                    normal: {
                        show: true,
                        textStyle,
                        formatter: function(params) {
                            return (params.value / 10000).toFixed(2);
                        },
                    },
                },
            },
        ],
    };
}

/*不格式化,只改黑色 传几就几个
* */
function myOptionBlack(val, fontSize) {
    let ser = [];
    for (let i = 0; i < Number(val); i++) {
        ser.push({

            label: {
                normal: {
                    show: true,
                    //   position:'insideRight',
                    textStyle: {
                        fontSize,
                        color: "black",
                    },
                },
            },
        });
    }
    return {
        legend: {
            textStyle: {
                fontSize,
            },
        },

        series: ser,
    };
}

/*第一个柱图变色不格式化,后边LINE 只变色*/
function myOptionColor(fontSize) {
    let textStyle = {
        fontSize, color: "black",
    };
    return {
        legend: {
            textStyle,
        },
        xAxis, grid,
        yAxis: [
            {
                show: yAxisShow,
                axisLabel: {
                    textStyle,
                    formatter: (val) => {
                        return ThousandYuan(val);
                    },
                },
            }, {
                show: yAxisShow,
                axisLabel: {
                    textStyle,
                    formatter: (val) => {
                        return percentage(val);
                    },
                },
            },
        ],
        series: [
            {
                barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        textStyle,
                    },
                },
            }, {
                barMaxWidth,
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            },
        ],
    };
}

/*全是百分比*/
function myOptionPercentage(fontSize) {
    let textStyle = {
        fontSize, color: "black",
    };
    return {
        legend: {
            textStyle,
        },
        xAxis, grid: gridLine,
        yAxis: [
            {
                show: yAxisShow,
                axisLabel: {
                    textStyle,
                    formatter: (val) => {
                        return percentage(val);
                    },
                },
            },
        ],
        series: [
            {
                barMaxWidth,
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,

                },
            }, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            },
        ],
    };
}

/*调整图例的字体*/
function myOptionFont(fontSize) {
    return {
        legend: {
            textStyle: {
                fontSize,
            },
        }, xAxis, grid,
        yAxis: [
            {
                show: yAxisShow,
                axisLabel: {
                    textStyle: {
                        fontSize, color: "black",
                    },
                    formatter: (val) => {
                        return ThousandYuan(val);
                    },
                },
            }, {
                show: yAxisShow,

                axisLabel: {
                    textStyle: {
                        fontSize, color: "black",
                    },
                    formatter: (val) => {
                        return percentage(val);
                    },
                },
            },
        ],
        series: [
            {
                barMaxWidth,
            },

            {}, {
                //      symbol: 'triangle',
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                },
            },
        ],
    };
}

/*调整图例的字体*/
function myOptionFontThousandYuan(fontSize) {

    return {
        legend: {
            textStyle: {
                fontSize,
            },
        },

        xAxis, grid,
        yAxis: [
            {
                show: yAxisShow,
                axisLabel: {
                    textStyle: {
                        fontSize, color: "black",
                    },
                    formatter: (val) => {
                        return ThousandYuan(val);
                    },
                },
            }, {
                axisLabel: {
                    textStyle: {
                        fontSize, color: "black",
                    },
                    formatter: (val) => {
                        return percentage(val);
                    },
                },
            },
        ],
    };
}

/*百分比*/
function percentage(val) {
    if (!val) val = 0;
    return simpleFixed(Number(val) * 100, 1) + "%";
// return Number(Number(Number(val ?? 0) * 100).toFixed(2)).toFixed(1) + "%";
}

/*万元*/
function tenThousandYuan(val) {
    if (!val) val = 0;
   // return Number(Number(val ?? 0) / 10000).toFixed(2);
   return simpleFixed(Number(val) / 10000, 2);
}

/*Y轴万元*/
function YtenThousandYuan(val) {
    if (!val) val = 0;
    return `${val / 10000}`;
}

/*元*/
function ThousandYuan(val) {
    if (!val) val = 0;
    return Number(val ?? 0).toFixed(0);
}

export {
    barMaxWidth, position,
    grid, grid2, gridLine, xAxis, yAxisShow, axisLine, lineStyle, PotItemStyle, symbolSize,
    myOptionOne,
    myOptionTwo,
    myOptionStack,
    myOptionBlack,
    myOptionColor,
    myOptionFont,
    percentage,
    tenThousandYuan,
    YtenThousandYuan,
    ThousandYuan,
    myOptionFontThousandYuan,
    myOptionPercentage,
};

