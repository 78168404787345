<template>
    <section>
        <div>
            <div class="chart" ref="chart"></div>
        </div>
        <div class="chart" ref="chart_gauge"></div>
        <p>{{ name.name }}：{{ name.value }} </p>
        <ve-histogram
                :data="chartDataV"
                :events="chartEvents"
        >
        </ve-histogram>
    </section>
</template>
<script>
import * as echarts5 from "echarts5";
import { personSvg, myModelSvg } from "@/components/svg";

export default {
 data() {
  this.colors = [
   "#6dc231",
   "#bda29a",
   "#61a0a8",
   "#d48265",
   "#91c7ae",
   "#749f83",
   "#ca8622",
   "#bda29a",
   "#6e7074",
   "#546570",
   "#c4ccd3",
  ];

  return {
   chartDataV: {
    columns: ["日期", "访问用户"],
    rows: [
     { "日期": "1/1", "访问用户": 1393 },
     { "日期": "1/2", "访问用户": 3530 },
     { "日期": "1/3", "访问用户": 2923 },
     { "日期": "1/4", "访问用户": 1723 },
     { "日期": "1/5", "访问用户": 3792 },
     { "日期": "1/6", "访问用户": 4593 },
    ],
   },
   name: "",
   mydata: [],
   chartSettings: {
    // selectedMode: 'single',
   },
   chartEvents: {},
   chartData: {
    columns: ["日期", "访问用户", "下单用户", "下单率"],
    rows: [
     { 日期: "1/1", 访问用户: 1393, 下单用户: 1093, 下单率: 0.32 },
     { 日期: "1/2", 访问用户: 3530, 下单用户: 3230, 下单率: 0.26 },
     { 日期: "1/3", 访问用户: 2923, 下单用户: 2623, 下单率: 0.76 },
     { 日期: "1/4", 访问用户: 1723, 下单用户: 1423, 下单率: 0.49 },
     { 日期: "1/5", 访问用户: 3792, 下单用户: 3492, 下单率: 0.323 },
     { 日期: "1/6", 访问用户: 4593, 下单用户: 4293, 下单率: 0.78 },
    ],
   },

  };
 },
 created() {
  this.mydata = [35, 20, 36, 10, 10, 20];
  // console.log(this.mydata)
  //   this.sys();
  let self = this;
  this.chartEvents = {
   click: function(e) {
    self.name = e;
   },
  };
 },
 methods: {
  // /apis/Asset/img/avatars/css.svg art.svg
  async sys() {
   let myChartG = echarts5.init(this.$refs.chart_gauge);
   let op_gauge = {};
   echarts5.registerMap("organ_diagram", { svg: myModelSvg });
   op_gauge = {
    tooltip: {},
    series: [{
     name: "名称",
     type: "map",
     map: "organ_diagram",
     roam: false,
     emphasis: {
      label: {
       show: false,// 内容内显示文字
      },
      itemStyle: {
       color: null,
       borderColor: "green",
       borderWidth: 0,
      },
     },
     selectedMode: false,

     data: [
      { name: "总毛利", value: 15 },
      { name: "固定费用", value: 15 },
      { name: "利润", value: 15 },
     ],
    }],

   };
   myChartG.setOption(op_gauge);
  },

 },
 mounted() {
  let mychart = this.$echarts.init(this.$refs.chart);
  const op = {
   title: {
    text: "ECharts 入门示例",
   },
   tooltip: {},
   legend: {
    data: ["销量"],
   },
   xAxis: {
    data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
   },
   yAxis: {},
   series: [
    {
     name: "销量",
     type: "bar",
     data: this.mydata,
    },
   ],
  };
  mychart.setOption(op);
  this.sys();

 },
};
</script>

<style scoped>
.chart {
    width: 1000px;
    height: 500px;
    }
</style>
