import Vue from "vue";
import Router from "vue-router";

/*测试*/
import testRouter from "./test";
/*主页*/
import mainRouter from "./main";
/*系统设置*/
import systemSettingRouter from "./systemSettings";
/*业绩跟踪*/
import performanceRouter from "./performanceRouter";
/*业务管理*/
import businessManageRouter from "./businessManage";
/*数据填报*/
import dataReportRouter from "./dataReportRouter";
/*报表管理*/
import reportTaberRouter from "./reportTableRouter";

import improvePlanRouter from "@/router/improvePlan";

const ChildrenRoutes = [].concat(testRouter, mainRouter, systemSettingRouter, reportTaberRouter, dataReportRouter, businessManageRouter, performanceRouter,improvePlanRouter);

/*全屏的包*/
import Login from "../views/Login/login";
import printReport2 from '@/views/monthReport/print2'
import countryPrintReport2 from '@/views/countryMonthReport/print2'
import regionPrintReport2 from '@/views/regionMonthReport/print2'
import Index from "@/views/layouts/index";
import diagnosisReportComponent from "@/views/diagnosisReport/diagnosisReportComponent";

Vue.use(Router);
const router = new Router({
    mode: "history",
    routes: [{
        path: "*",
        redirect: "/login",
    }, {
        path: "/login",
        component: Login,
    },

        // 新版店端经营分析报告打印
        {
            path: "/monthReport/print2",
            component: printReport2
        },
        // 新版全国经营分析报告打印
        {
            path: "/countryMonthReport/print2",
            component: countryPrintReport2,
        },

        // 新版区域经营分析报告打印
        {
            path: "/regionMonthReport/print2",
            component: regionPrintReport2,
        },

        //诊断报告打印
        {
            path: "/diagnosisReportPrint",
            component: diagnosisReportComponent,
        },

        {
            path: "/index",
            component: Index,
            children: ChildrenRoutes,
        },
    ],
});

export default router;
