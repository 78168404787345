<template>
  <a-row id="one_2" :gutter="[24,24]">
<!--    <a-col :span="24">
      <ve-funnel :after-set-option="afterSetOption"></ve-funnel>
    </a-col>-->
    <a-col :span="24">
      <a-card title="1、新车销量及业务盈利">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_1_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_2_1_1_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_2_1_2_Data"/>
        </a-col>
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_1_2"></monthReportTableComponent>
        </a-col>
      </a-card>
      <div id="two_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="2、渠道销量占比">
        <chartComponent :chart-data="data.chart_2_2_1_Data"/>
      </a-card>
      <div id="there_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="3、销售过程效率">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_3_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-height="'500px'" :chart-data="data.chart_2_3_1_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_2_3_2_Data"/>
        </a-col>
      </a-card>
      <div id="four_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="4、各车型销售及库存情况">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_2"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" >
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_3"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_6"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_8"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_10"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" >
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_11"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_12"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" >
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_13"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_14"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_15"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_4_16"></monthReportTableComponent>
        </a-col>
      </a-card>
      <div id="five_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="5、新车库存周转天数及库龄占比">
        <a-col :span="12">
            <br>
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_5_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
            <div class="legend">
                <span> <b>---</b>全国标杆</span>
                <span> <b>---</b>区域标杆</span>
            </div>
          <chartComponent :chart-data="data.chart_2_5_1_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_2_5_2_Data"/>
        </a-col>
      </a-card>
      <div id="six_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="6、销售水平事业">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_6_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_2_6_1_Data" chart-height="250px"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_2_6_2_Data" chart-height="250px"/>
        </a-col>
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_6_2"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData" :table-data="data.tableData_2_6_3"></monthReportTableComponent>
        </a-col>
      </a-card>
    </a-col>
    <a-anchor v-if="isClose">
      <div class="close" @click="isClose=false">
        <a-icon type="close"/>
        关闭
      </div>
      <a-anchor-link href="#one_2" title="1、新车销量及业务盈利"/>
      <a-anchor-link href="#two_2" title="2、渠道销量占比"/>
      <a-anchor-link href="#there_2" title="3、销售过程效率"/>
      <a-anchor-link href="#four_2" title="4、各车型销售及库存情况"/>
      <a-anchor-link href="#five_2" title="5、新车库存周转天数及库龄占比"/>
      <a-anchor-link href="#six_2" title="6、销售水平事业"/>
    </a-anchor>
  </a-row>
</template>

<script>
import monthReportTableComponent from "../../../../components/monthReportTableComponent";
import chartComponent from "../../../../components/chartComponent";
import { getEmptyTemplate, loadReportData } from "@/until/reportCalculate";
import {storeReportSales} from "../sales.js";

export default {
  name: "salesComponent",
  props: {
    reportParams: {
      ReportDate: "",
      DealerCode: "",
      PartName: "",
    },
    setData: [],
  },
  components: {
    monthReportTableComponent, chartComponent,
  },
  data () {
    return {
      isClose: true,
      emptyTemplate: getEmptyTemplate(),
      columnsData: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
          customRender: (text, record) => {
            if (record.indentation > 0)
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            return text;
          },
        },
        {
          title: "单位",
          dataIndex: "Unit",
          key: "Unit",
        },
        {
          title: "全国标杆值",
          dataIndex: "NationwideStandardText",
          key: "NationwideStandard",
        },
        {
          title: "区域标杆值",
          dataIndex: "RegionalStandardText",
          key: "RegionalStandard",
        },
        {
          title: "当月值",
          dataIndex: "CurrentText",
          key: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.CurrentText) < parseFloat(record.NationwideStandardText);
            if (record.PercentileSort === "DESC") flag = !flag;
            if (flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑</div>;
            }
          },
        },
        {
          title: "上月值",
          dataIndex: "N-1Text",
          key: "N-1",
        },
        {
          title: "去年同期",
          dataIndex: "LastYearText",
          key: "LastYear",
        },
        {
          title: "年度累计",
          dataIndex: "CurrentYearText",
          key: "CurrentYear",
        }],
      chartNameRelations: {
        "全国标杆": "NationwideStandard",
        "区域标杆": "RegionalStandard",
        "当月值": "Current",
        "上月值": "N-1",
      },
      data: storeReportSales
    };
  },
  watch: {
    reportParams: {
      handler: function () {
        this.loadData();
      },
      deep: true,
    },
  },
  async created () {
    this.loadData();
  },
  computed: {
    // a computed getter
    // eslint-disable-next-line vue/no-dupe-keys
  },
  methods: {
    async loadData () {

      await loadReportData(this, this.data, this.chartNameRelations, this.reportParams);

    },

  },
};
</script>
<style lang="less" scoped>
@import "./storeManagment.less";
</style>
