<template>
  <a-row :gutter="[18,18]">
    <a-col :span="8">
      <div class="con_title">一、经营业绩</div>
      <a-row :gutter="[18,0]">
        <a-col :span="24">
          <div class=" borderF" style="overflow: hidden;padding-top: 0;height: 663px">
            <div class="descriptionsItem5">
              <ul>
                <li v-for="(item,i) of descriptionsItem" :key="i++"><p>{{ item.title }} <span>{{ item.value }}</span>
                </p></li>
                <li>
                  <p style="overflow:hidden;height: 76px; padding-top: 3px;padding-left: 3px;">
                    <b style="display: block;font-weight: 500; float:left;width:39%;text-align: right">售后业务综合产值</b>
                    <span style="display:block;float:left;width: 60%;padding-top: 15px;">{{
                        sysValue(rich["B0104202"]) }}万元</span>
                  </p>
                </li>
                <li><p style="overflow:hidden;height: 76px; padding-top: 3px;padding-left: 3px;">
                  <b style="display: block;font-weight: 500; float:left;width:39%;text-align: right">售后业务综合毛利</b>
                  <span style="display:block;float:left;width: 60%;padding-top: 15px;">{{
                      sysValue(rich["B0105113"]) }}万元</span>
                </p></li>
              </ul>
            </div>
            <h2 style="font-size: 22px;text-indent: 1em;">来厂台次及事故车来厂台次</h2>
            <chartComponent :chart-height="'560px'" :chart-data="chartData.chartData1"></chartComponent>
          </div>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">二、利润表</div>
                <a-my-table style="overflow:hidden;height: 665px;" :columns-data="tableData.tableData1.columns" :table-data="tableData.tableData1" class="borderQ" ></a-my-table>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">售后业务综合产值及毛利</div>
          <chartComponent class="borderF" :chart-data="chartData.chartData2" :chart-height="'614px'"></chartComponent>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">售后水平事业</div>
          <chartComponent class="borderF" :chart-data="chartData.chartData3" :chart-height="'620px'"></chartComponent>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="8">
      <a-row :gutter="[18,0]">
        <a-col :span="24">
          <div class="con_title">单车售后详情</div>
          <chartComponent class="borderF" :chart-data="chartData.chartData4" :chart-height="'624px'"></chartComponent>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">三、客户管理</div>
          <chartComponent class="borderF" :chart-data="chartData.chartData5" :chart-height="'625px'"></chartComponent>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">客户维系率</div>
          <chartComponent class="borderF" :chart-data="chartData.chartData6" :chart-height="'614px'"></chartComponent>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">四、运营效率</div>
            <div class="borderQ" >
                <a-my-table :columns-data="tableData.tableData2.columns" :table-data="tableData.tableData2" class="" ></a-my-table>
            </div>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="8">
      <a-row :gutter="[18,0]">
        <a-col :span="24">
          <div class="con_title">首保实施率及首保付费保养进厂率</div>
          <chartComponent class="borderF" :chart-data="chartData.chartData7" :chart-height="'624px'"></chartComponent>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">五、售后关键KPI及工位效率</div>
            <div class="borderQ" style="overflow:hidden;height: 665px;">
                <a-my-table :columns-data="tableData.tableData3.columns" :table-data="tableData.tableData3" ></a-my-table>
            </div>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">六、人员效率</div>
          <div class=" borderF" style="overflow: hidden;padding-top: 0;height: 650px">
            <a-row :gutter="[18,0]">
              <a-col class="incomeStatement" :span="6" v-for="(item,i) in descriptionsItem1" :key="i">
                <div>{{ item.title }}: <span>{{ item.value }}</span></div>
              </a-col>
              <a-col span="24" style="margin-top: 10px;height: 556px;">
                <a-my-table :columns-data="tableData.tableData4.columns" :table-data="tableData.tableData4"  style="overflow:hidden;"></a-my-table>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24" class="margin10">
          <div class="con_title">七、零部件库存管理</div>
          <div class=" borderF" style="overflow: hidden;padding-top: 0;height: 660px">
            <a-row :gutter="[18,0]">
              <a-col class="incomeStatement" :span="6" v-for="(item,i) in descriptionsItem2" :key="i">
                <div>{{ item.title }}: <span>{{ item.value }}</span></div>
              </a-col>
              <h2 style="font-size: 22px;text-indent: 1em;">库存金额占比</h2>
              <chartComponent :chart-height="'580px'" :chart-data="chartData.chartData8"></chartComponent>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import {
  fontSize, itemStyle,
  lineStyle,
  oneData,
  PrintXAxis, PrintyAxis,
  symbolSize,
  textStyle,
} from "@/views/monthReport/shopAnalysis/component/myData";
import {
  barMaxWidth,
  mouth12Chart,
  settingsLabel,
  tableGetData, xAxis,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import { percentage } from "@/until/option";
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import aCustomTable from "@/views/monthReport/shopAnalysis/component/aCustomTable";
import chartComponent from "@/components/chartComponent";

export default {
  name: "thereAftersale",
  props: {
    allData: { type: Object },
    dataParams: {},
  },
  components: { aMyTable, aCustomTable, chartComponent },
  data() {
    return {
      params: {},
      monthRank: {},
      monthPush: {},
      rich: {},
      monthStandardPush: {},
      yearRank: {},
      columnsC: [
        {
          key: "name", title: "指标名称", dataIndex: "name", width: 260,
          customRender: (text, record) => {
            if (record.indentation > 0) {
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            }
            return text;
          },
        },
        { key: "Unit", title: "单位", dataIndex: "Unit" },
        {
          key: "Current", title: "当月值", dataIndex: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
            if (record.PercentileSort === "DESC") flag = !flag;
            if (flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑</div>;
            }
          },
        },
        { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
        { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
        { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
        { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
        { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
        { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
      ],
      descriptionsItem: [
        { title: "本月服务满足率", value: "0台", kpiCode: "C0207021" },
        { title: "来厂台次", value: "0台", kpiCode: "B0302001" },
        { title: "事故车台次", value: "0台", kpiCode: "B0302121" },
      ],
      descriptionsItem1: [
        { title: "本月零部件人数", value: "0人", kpiCode: "C0302317" },
        { title: "本月服务顾问人数", value: "0人", kpiCode: "C0302210" },
        { title: "本月机电技师人数", value: "0人", kpiCode: "C0302213" },
        { title: "本月钣喷技师人数", value: "0人", kpiCode: "C0302318" },
      ],
      descriptionsItem2: [
        { title: "本月0-90天库存", value: "0元", kpiCode: "B0402005" },
        { title: "本月91-180天库存", value: "0元", kpiCode: "B0402006" },
        { title: "本月181-365天库存", value: "0元", kpiCode: "B0402007" },
        { title: "本月>365天库存", value: "0元", kpiCode: "B0402008" },
      ],
      tableData: {
        tableData1: {
          table: [
            { name: "售后业务综合收入", kpiCode: "B0104202", ut: "1" },
            { name: "售后维修业务收入", kpiCode: "B0103097", ut: "1", indentation: 1 },
            { name: "售后水平事业收入", kpiCode: "B0103099", ut: "1", indentation: 1 },
            { name: "售后业务综合毛利", kpiCode: "B0105113", ut: "1" },
            { name: "售后维修业务毛利", kpiCode: "B0104165", ut: "1", indentation: 1 },
            { name: "售后水平事业毛利", kpiCode: "B0104187", ut: "1", indentation: 1 },
            { name: "售后水平事业毛利率", kpiCode: "B0105108" },
          ],
          columns: [],
          data: [],
        },
        tableData2: {
          table: [
            { name: "首次实施率", kpiCode: "B0303025" },
            { name: "首次付费保养实施率", kpiCode: "B0303026" },
            { name: "首保招揽成功率", kpiCode: "B0303071" },
            { name: "定保招揽成功率", kpiCode: "B0303072" },
            { name: "次新车续保率", kpiCode: "B0303031" },
            { name: "售后养护品渗透率", kpiCode: "B0303006" },
            { name: "售后续保渗透率", kpiCode: "B0304003" },
            { name: "非新车延保渗透率", kpiCode: "B0303008" },
          ],
          columns: [],
          data: [],
        },
        tableData3: {
          table: [
            { name: "零件工时比", kpiCode: "B0104259" },
            { name: "事故车占比", kpiCode: "B0303101" },
            { name: "钣喷金额占比", kpiCode: "B0105150" },
            { name: "机电工位日均周转次数", kpiCode: "B0304001" },
            { name: "机电工位数", kpiCode: "B0302028" },
            { name: "机电工位日均周转次数", kpiCode: "B0304001" },
            { name: "烤房数量", kpiCode: "B0302032" },
            { name: "平均每个烤房喷漆面数", kpiCode: "B0303017" },
          ],
          columns: [],
          data: [],
        },
        tableData4: {
          table: [
            { name: "零部件人员人均生产力", kpiCode: "B0105133" },
            { name: "服务顾问人均日均接车量", kpiCode: "B0303036" },
            { name: "机电技师人均生产力", kpiCode: "B0104256" },
            { name: "钣喷技师人均生产力", kpiCode: "B0104283" },
            { name: "准时竣工率", kpiCode: "B0303029" },
            { name: "一次性修复率", kpiCode: "B0303032" },
          ],
          columns: [],
          data: [],
        },
      },
      chartData: {
        chartData1: {
          columns: ["日期", "来厂台次", "来厂台次全国标杆", "事故车来厂台次", "事故车来厂台次全国标杆"],
          kpiCode: [
            { Code: "B0302001", Name: "来厂台次" },
            { Code: "B0302001", Name: "来厂台次全国标杆" },
            { Code: "B0302121", Name: "事故车来厂台次" },
            { Code: "B0302121", Name: "事故车来厂台次全国标杆" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            //  stack: { all: ["来厂台次", "事故车来厂台次"] },
            showLine: ["来厂台次全国标杆", "事故车来厂台次全国标杆"],
            axisSite: { right: ["来厂台次全国标杆", "事故车来厂台次全国标杆"] },
            yAxisType: ["0", "0"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);

                  },
                },
              },
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
            ],
          },
        },
        chartData2: {
          columns: ["日期", "售后业务综合产值", "售后业务综合产值全国标杆", "售后业务综合毛利", "售后业务综合毛利全国标杆"],
          kpiCode: [
            { Code: "B0104202", Name: "售后业务综合产值" },
            { Code: "B0104202", Name: "售后业务综合产值全国标杆" },
            { Code: "B0105113", Name: "售后业务综合毛利" },
            { Code: "B0105113", Name: "售后业务综合毛利全国标杆" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            //   stack: { all: ["售后业务综合产值", "售后业务综合毛利"] },
            showLine: ["售后业务综合产值全国标杆", "售后业务综合毛利全国标杆"],
            axisSite: { right: ["售后业务综合产值全国标杆", "售后业务综合毛利全国标杆"] },
            yAxisType: ["0", "0"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "万元",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d / 10000).toFixed(0);
                  },
                },
              },
              {
                type: "value",
                name: "万元",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d / 10000).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
            ],
          },
        },
        chartData3: {
          columns: oneData.afterData.chartData.chartData3.columns,
          kpiCode: oneData.afterData.chartData.chartData3.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["售后水平事业收入", "售后水平事业毛利"] },
            showLine: ["售后水平事业毛利率"],
            axisSite: { right: ["售后水平事业毛利率"] },
            yAxisType: ["0", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: PrintyAxis,
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              }, {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
            ],
          },
        },
        chartData4: {
          columns: oneData.afterData.chartData.chartData4.columns,
          kpiCode: oneData.afterData.chartData.chartData4.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["单车售后产值", "单车零部件毛利", "单车工时毛利"] },
            showLine: ["售后维修业务毛利率"],
            axisSite: { right: ["售后维修业务毛利率"] },
            yAxisType: ["0", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "元",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
              {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d * 100).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData5: {
          columns: oneData.afterData.chartData.chartData5.columns,
          kpiCode: oneData.afterData.chartData.chartData5.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["A类客户数", "B类客户数", "C类客户数", "新销售未回厂数"] },
            yAxisType: ["0"],
            label: {
              show: true, // 显示数字
              //   position: "top",
              textStyle: {
                fontSize, color: "black",
              },
              formatter: function(params) {
                return (params.value).toFixed(0);
              },
            },
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis:
              {
                type: "value",
                name: "个",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
              },
            ],
          },
        },
        chartData6: {
          columns: oneData.afterData.chartData.chartData6.columns,
          kpiCode: oneData.afterData.chartData.chartData6.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["客户维系率"],
            axisSite: { right: ["客户维系率"] },
            yAxisType: ["0", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "个",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
              {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d * 100).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData7: {
          columns: ["日期", "首保实施率", "首次付费保养进厂率"],
          kpiCode: [
            { Code: "B0303025", Name: "首保实施率" },
            { Code: "B0303026", Name: "首次付费保养进厂率" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["首保实施率", "首次付费保养进厂率"],
            yAxisType: ["0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: {
              type: "value",
              name: "%",
              nameTextStyle: {
                fontSize,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
              axisLabel: {
                fontSize,
                formatter: (d) => {
                  return Number(d * 100).toFixed(0);
                },
              },
            },
            xAxis: PrintXAxis,
            series: [
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              }, {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData8: {
          columns: ["日期", "0-90天库存金额占比", "91-180天库存金额占比", "181-365天库存金额占比", ">365天库存金额占比"],
          kpiCode: [
            { Code: "B0403003", Name: "0-90天库存金额占比" },
            { Code: "B0403004", Name: "91-180天库存金额占比" },
            { Code: "B0403005", Name: "181-365天库存金额占比" },
            { Code: "B0403006", Name: ">365天库存金额占比" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["0-90天库存金额占比", "91-180天库存金额占比", "181-365天库存金额占比", ">365天库存金额占比"] },
            yAxisType: ["0.[0]%"],
            label: {
              show: true, // 显示数字
              //   position: "top",
              textStyle: {
                fontSize, color: "black",
              },
              formatter: function(params) {
                return (params.value * 100).toFixed(1) + "%";
              },
            },
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: {
              type: "value",
              name: "%",
              nameTextStyle: {
                fontSize,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
              axisLabel: {
                fontSize,
                formatter: (d) => {
                  return Number(d * 100).toFixed(1);
                },
              },
            },
            xAxis:PrintXAxis,
            series: [
              {
                barMaxWidth,
              },
            ],
          },
        },
      },
    };
  },
  created() {
    if (this.allData) {
      this.getDescriptionsItem();
      this.getChartData();
      this.getTableData();
    }
  },
  methods: {
    getDescriptionsItem() {
      if (!this.rich) {
        return false;
      }
      const desData = this.rich;
      this.descriptionsItem.forEach((d, i) => {
        if (!this.rich[d.kpiCode]) {
          return false;
        }
        let desData = this.rich;
        if (i === 0) {
          d.value = Number(desData[d.kpiCode].Current * 100 ?? 0).toFixed(1) + desData[d.kpiCode].Unit;
        } else if (i >= 3) {
          d.value = Number(desData[d.kpiCode].Current / 10000 ?? 0).toFixed(2) + "万元";
        } else {
          d.value = Number(desData[d.kpiCode].Current ?? 0).toFixed(0) + desData[d.kpiCode].Unit;
        }
      });
      this.descriptionsItem1.forEach(d => {
        if (!desData[d.kpiCode]) {
          return false;
        }
        if (d.kpiCode) {
          d.value = (desData[d.kpiCode].Current ?? 0) + desData[d.kpiCode].Unit;
        }
      });
      this.descriptionsItem2.forEach(d => {
        if (!desData[d.kpiCode]) {
          return false;
        }
        if (d.kpiCode) {
          d.value = Number(desData[d.kpiCode].Current / 10000 ?? 0).toFixed(2) + "万元";
        }
      });
    },
    getTableData() {
      Object.keys(this.tableData).forEach(d => {
        tableGetData(this.tableData[d], this.rich);
      });
    },
    getChartData() {
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], this.monthPush, this.monthStandardPush, this.dataParams.ReportDate);
      });
    },
    sysValue(val) {
      if (!val) {
        return 0;
      }
      return Number(Number(val.Current) / 10000).toFixed(2);
    },
  },
  watch: {
    allData: {
      handler: function() {
        this.monthRank = this.allData["MonthRank"];
        this.monthPush = this.allData["MonthPush"];
        this.rich = this.allData["Rich"];
        this.monthStandardPush = this.allData["MonthStandardPush"];
        this.yearRank = this.allData["YearRank"];
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "monthReport.less";
</style>
