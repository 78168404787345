const fontSize = 14;
const barMaxWidth = "35%";
const color = "#666666";
const axisLine = {
 show:false,
 lineStyle:{
	color:"#999999",
 },
};
const splitLine = {
 show:true,
 lineStyle:{
	color:"#999999",
	type:"dashed",
 },
};
const axisTick = { show:false };
const legend = {
  data:[],
 // 图例文字的样式
 textStyle:{
	color,
	fontSize,
 },
  top:'-5px',
};
const axisLabel = {
 textStyle:{
	color,
 },
};
const grid = {
 bottom:"15px",
 right:"22px",
 containLabel:true,
};
const xAxis = {
 axisLabel:{
	interval:0,
 },
 axisLine:{
	show:true,
	lineStyle:{ type:"sold",width:1.3 },
 },
};
const yAxisDefault = {
 splitLine,
 axisLine,
 axisTick,
};
// tenThousandYuan
const yAxisTenThousandYuan = {
 name: "万元",
 splitLine,
 axisLine,
 axisTick,axisLabel:{
	formatter:(val) =>{
	if (val<10) return `${val}`
	return `${val / 10000}`
	},
  },
};
const yAxisRight = {
 show:true,
 splitLine:{
	show:false,
	lineStyle:{ type:"dashed" },
 },
 axisLine,
 axisTick,
};


function yAxisDefaultUnit(unit)  {
  return{
    name:unit,
	splitLine,
	axisLine,
	axisTick,
  }
}

function yAxisRightUnit(unit) {
 return{
   name:unit,
   show:true,
   splitLine:{show:false,lineStyle:{ type:"dashed" },
   },
   axisLine,
   axisTick,
 }
}

//左侧百分比
const yAxisRight2 = {
 show:true,
 splitLine:{
	show:true,
	lineStyle:{ type:"dashed" },
 },
 axisLine,
 axisTick,
};

const lineStyle = {
 width:3,
};
const symbolSize = 10;
const itemStyle = {
 borderWidth:1,
 // backgroundColor: "#000",
 //  borderColor: "#000",
 // color: "#000",
};
// 拆线过渡用直线
const smooth = false;

const seriesLine = {
 lineStyle,
 symbolSize,
 itemStyle,
 smooth,
};
const seriesBar = {
 barMaxWidth,
 lineStyle:{
	color,
 },

};

export {
 fontSize,
 color,
 axisLine,
 splitLine,
 grid,
 xAxis,
 yAxisDefault,
 yAxisRight,
    yAxisRight2,
 axisTick,
 legend,
 axisLabel,
 barMaxWidth,
 seriesLine,
 seriesBar,smooth,yAxisTenThousandYuan,
  yAxisDefaultUnit,
  yAxisRightUnit,
};
