<template>
  <section>
    <a-card>
      <a-tabs v-model="activeGroup">
        <a-tab-pane v-for="group in data" :key="group.Name" :tab="group.Name">
          <div v-if="group.Name === activeGroup">
            <ul>
              <li>资产负债类科目按期末余额填列；损益科目按本期发生额填列；</li>
              <li>金额保留两位小数；人数、个数、批次、台次、工单数取整；无数据处请填写 0，不可为空；</li>
              <li>请对填写提示列未通过的数据进行自查，修正；</li>
              <li>本业收集数据共 {{ group.Items.length }} 项。</li>
            </ul>
            <a-table :rowKey="(record) => record.KpiCode" :columns="columns" :data-source="group.Items"
                     :pagination="{pageSize:10}"
                     bordered
            >
              <div slot="subjectName" :style="getWhiteSpaceStyle(text)" slot-scope="text">{{ text }}</div>
              <div slot="value" slot-scope="text,record">
                <a-input v-model="record.Value" @change="validateValue(record)"/>
              </div>
              <div slot="tip" slot-scope="text,record">
                <a-tooltip>
                  <template slot="title">{{ record.Description }}</template>
                  <a-alert v-if="record.Tip!=null" type="error" :message="record.Tip" show-icon/>
                  <a-alert v-else type="success" message="正确" show-icon/>
                </a-tooltip>
              </div>
            </a-table>
          </div>
        </a-tab-pane>
      </a-tabs>
      <div style="text-align: center;padding-top: 20px;"><a-button  type="primary" >保存</a-button></div>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      activeGroup: "",
      data: [],
      validateRules: {},
      codes: {},
      columns: [{
        title: "项目",
        width: 120,
        dataIndex: "FirstProjectName",
        key: "FirstProjectName",
        align: "center",
        customRender: (value, record, index) => {
          let rowSpan = 0;
          if (index === 0 || this.lastFirstProjectName !== value) {
            this.lastFirstProjectName = value;
            rowSpan = record.FirstProjectRowSpan;
          }
          return {
            children: value,
            attrs: { rowSpan },
          };
        },
      }, {
        title: "二级项目",
        width: 120,
        dataIndex: "SecondProjectName",
        key: "SecondProjectName",
        align: "center",
        customRender: (value, record, index) => {
          let rowSpan = 0;
          if (index === 0 || this.lastSecondProjectRowSpan !== value) {
            this.lastSecondProjectRowSpan = value;
            rowSpan = record.SecondProjectRowSpan;
          }
          return {
            children: value,
            attrs: { rowSpan },
          };
        },
      }, {
        title: "科目名称",
        width: 300,
        dataIndex: "SubjectName",
        key: "SubjectName",
        scopedSlots: { customRender: "subjectName" },
      }, {
        title: "方向",
        width: 80,
        dataIndex: "Direction",
        key: "Direction",
      }, {
        title: "金额/数量",
        width: 200,
        dataIndex: "Value",
        key: "Value",
        scopedSlots: { customRender: "value" },
      }, {
        title: "单位",
        width: 100,
        dataIndex: "Unit",
        key: "Unit",
      }, {
        title: "填写提示",
        key: "tip",
        scopedSlots: { customRender: "tip" },
      }],
    };
  },
  created () {
    this.getTemplate();
  },
  methods: {
    async getTemplate () {
      let res = await this.$Http.getInputTemplate();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      // 加载值以及规则
      let rules = {};
      let codes = {};
      let codeRegex = /[A-Za-z]+[0-9]+/g;
      res.Data.forEach(group => {
        group.ValidateItems.forEach(item => {
          item.Codes = item.Formula.match(codeRegex);
          // TODO 待解决 NULL 的问题
          if (item.Codes != null) {
            item.Codes.forEach(code => {
              let rule = rules[code];
              if (rule == null) {
                rule = [];
                rules[code] = rule;
              }
              rule.push(item);
            });
          }
        });
        group.Items.forEach(item => {
          codes[item.KpiCode] = item;
        });
      });
      // 加载错误提示
      res.Data.forEach(group => {
        group.Items.forEach(item => {
          if (item.Tip == null)
            this.validateValue(item);
        });
      });
      if (res.Data.length > 0)
        this.activeGroup = res.Data[0].Name;

      this.validateRules = rules;
      this.codes = codes;
      this.data = res.Data;
    },
    getWhiteSpaceStyle (value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] === " ") {
          continue;
        }
        if (i > 0)
          return "padding-left: " + i / 3 + "em";
        else
          break;
      }
      return "";
    },
    validateValue (record, onlySelf) {
      let value = record.Value;
      if (value === "" || value == null || isNaN(value)) {
        record.Tip = "请输入正确的数值";
        return;
      }
      record.Tip = null;
      let rules = this.validateRules[record.KpiCode];
      if (rules != null) {
        let codeGroup = [];
        for (let i = 0; i < rules.length; i++) {
          if (record.Tip != null) {
            break;
          }
          let rule = rules[i];
          let formula = rule.Formula;
          for (let j = 0; j < rule.Codes.length; j++) {
            if (record.Tip != null) {
              break;
            }
            let code = rule.Codes[j];
            codeGroup.push(code);
            let codeValue = this.codes[code].Value;
            if (codeValue === "" || codeValue == null || isNaN(codeValue)) {
              record.Tip = rule.ErrorTip;
              break;
            }
            formula = formula.replace(new RegExp(code,"gm"), codeValue);
          }
          if (record.Tip != null) {
            break;
          }
          // 不正确则设置错误信息
          formula = formula.replace(/=/g, "==");
          try {
            // console.log(formula);
            if (!eval(formula)) {
              record.Tip = rule.ErrorTip;
              break;
            }
          } catch {
            record.Tip = rule.ErrorTip;
            break;
          }
        }
        codeGroup.forEach(code => {
          this.codes[code].Tip = record.Tip;
        });
      }
    },
  },
};
</script>
