<template>
  <section>
    <a-row style="width: 99.2%" v-if="isCloseA">
      <a-col :span="24">
        <a-anchor :offsetTop="45" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
          <div class="fix_border">
            报告月份：
            <a-month-picker @change="handleReportChange" placeholder="请选择报告月份" v-model="ReportDateMoment"
                :disabledDate="disabledDate" style=" margin-right: 4px;width:110px" :allowClear="false"
            />
            <a-space size="small" class="space">
              <a-anchor-link href="#one" title="整体预览"/>
              <a-anchor-link href="#two" title="销售业务"/>
              <a-anchor-link href="#there" title="售后业务"/>
            </a-space>
            <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false"><a-icon type="close"/></span>
            <a-tooltip placement="topLeft" :title="`点击生成《${moment(ReportDateMoment).format('YYYY年M月')}全国看板报告》`">
              <!--<a-button style="float: right;" type="primary" @click="generateImages()">生成长图</a-button>-->
            </a-tooltip>
          </div>
        </a-anchor>
      </a-col>
    </a-row>
    <div ref="capture">
      <div v-if="titleView" style="text-align: center;padding-top: 20px; font-size: 36px;font-weight: 600">{{
          moment(ReportDateMoment).format("YYYY年M月") }}全国看板报告
      </div>
      <div id="one" style="clear: both">&nbsp;</div>
      <overall :salesRatioData="salesRatioData" :kpi-code-data="allData"/>
      <div id="two"></div>
      <sales :kpi-code-data="allData" :salesRatioData="salesRatioData"/>
      <div id="there"></div>
      <afterSales :after-sale-data="AfterSaleData" :kpi-code-data="allData"/>
    </div>
  </section>
</template>

<script>
import overall from "./mainComponets/overall";
import sales from "./mainComponets/sales";
import afterSales from "./mainComponets/aftersales";
import { ListKpiCode } from "./Code";
import moment from "moment";
import { disabledDate } from "@/until/dayFormat";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  name: "nationalHomepage",
  props: {},
  components: {
    overall, sales, afterSales,
  },
  data() {
    return {
      disabledDate, moment,
      isCloseA: true,
      titleView: false,
      params: {
        ReportDate: "",
        DealerCode: "",
      },
      search: {
        reportDate: moment().add(-1, "M"),
        choseDealer: [],
        upToStandard: null,
      },
      dealers: [],
      dealerMap: {},
      choseDealer: [],
      allData: [],
      AfterSaleData: {
        AfterSaleData1: [],
        AfterSaleData2: [],
      },
      salesRatioData: [], // 12个月的售后来厂台次数据
      partList: [],
    };
  },

  methods: {
    getParams() {
      //js判断是否为空对象
      if(Object.keys(this.$route.query).length > 0) {
        this.params = this.$route.query;
      } else {
        //若没有参数（从菜单点击），则自动获取上个月报告
        this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
        this.getData();
      }
    },
    generateImages() {
      // this.$router.push("/nationalHomepageImg?ReportDate=" + this.params.ReportDate);
      message.loading("生成中,请稍后....", 4);
      this.titleView = true; // 隐藏填报说明
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, `${moment(this.ReportDateMoment).format("YYYY年M月")}全国看板报告.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${moment(this.ReportDateMoment).format("YYYY年M月")}全国看板报告.png`);
            a.click();
          }
          this.titleView = false;
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.partList = res.Data;
      this.partList.push({
        Dealers: "",
        PartId: "",
        PartName: "全国",
      });
      this.partList.reverse();
      if(!this.params.PartName && this.partList.length > 0) {
        this.params.PartName = res.Data[0].PartName;
      }
    },
    // 获取当前选择的经销商信息
    async getDealerInfo() {
      /* if (this.params.DealerCode === "333") {
         this.params.DealerCode = "";
       }*/
      let res = await this.$Http.getCurrDealerInfo({
        DealerCode: this.params.DealerCode,
      });
      if(!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.choseDealer = this.dealerMap[res.Data.DealerCode];
      if(this.choseDealer == null) {
        this.params.DealerCode = this.dealerMap[Object.keys(this.dealerMap)[0]][1];
        if(this.params.DealerCode != null) {
          await this.getDealerInfo();
        }
        return;
      }

      this.dealerInfo = res.Data;
      this.params.DealerCode = res.Data.DealerCode;
      await this.getData();
    },
    async getData() {
      let res = await this.$Http.CountryPartDealerDashBord({
        ListKpiCode,
        PartName: "",
        YearMonth: this.params.ReportDate,
      });
      if(res.Data.length < 1) {
        this.$message.error("无数据,请重新选择有数据的月份!");
        //   return false;
      }
      this.allData = res.Data;
      await this.GetMiniChart();
    },
    async handleReportChange(date, dateString) {
      this.params.ReportDate = dateString + "-01";
      //日期修改后同步数据给子组件
      await this.getData();
    },
    /*售后总产值 miniChart*/
    async GetMiniChart() {
      let res = await this.$Http["CountryPartGetYearKpiCode"]({
        KpiCode: "B0103097", // 售后总产值 固定CODE不用修改
        YearMonth: this.params.ReportDate,
        PartName: "",
      });
      this.AfterSaleData.AfterSaleData1 = res.Data;
      await this.GetSalesRatioData();
      await this.GetMiniChart2();
    },
    /*售后来厂台次 miniChart*/
    async GetMiniChart2() {
      let res = await this.$Http["CountryPartGetYearKpiCode"]({
        KpiCode: "B0302001", // 售后来厂台次 固定CODE不用修改
        YearMonth: this.params.ReportDate,
        PartName: "",
      });
      this.AfterSaleData.AfterSaleData2 = res.Data;
    },
    /*新车总销量环比,占比*/
    async GetSalesRatioData() {
      let res = await this.$Http["CountryPartGetCarTypeHbTb"]({
        ListKpiCode: ["A0303311", "A0303312", "A0303313", "A0303314", "A0303315", "A0303316", "A0303317", "A0303318", "A0303319", "A0303320", "A0303321", "A0303322", "A0303323", "A0303324",
          "A1303461", "A1303462", "A1303463", "A1303464", "A1303465", "A1303466", "A1303467", "A1303468", "A1303469", "A1303470", "A1303471", "A1303472", "A1303473", "A1303474", "A1303475", "A1303476"],
        YearMonth: this.params.ReportDate,
        PartName: "",
      });
      this.salesRatioData = res.Data;
    },
  },
  async created() {
    this.getParams();
    await this.getDealersByRole();
//  await this.getDealerInfo();
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
};
</script>

<style lang="less" scoped>
section {
  margin-bottom: 20px;
  }

.fix_border {
  padding: 20px 10px 20px 30px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  margin-left: -2px;
  }

.space {
  margin-left: 3%;
  }

::v-deep {
  .ant-anchor-link {
    padding: 10px 20px;
    border: 1px solid #eaeaea;
    }

  .ant-anchor-link-active {
    background: #24619e;

    .ant-anchor-link-title {
      color: #ffffff;
      }
    }

  .ant-anchor-ink-ball {
    left: -10px
    }
  }
</style>
