<template>
  <section>
    <div class="top">
      <span>上传次数 <b>{{ PassPercentData.UploadNum === 0 ? "暂无数据" : PassPercentData.UploadNum }}</b>次</span>
      <span>校核失败次数 <b>{{ PassPercentData.FaileCount === 0 ? "暂无数据" : PassPercentData.FaileCount }}</b>次</span>
      <span>一次性通过率 <b>{{ PassPercentData.PassPercent === "0" ? "暂无数据" : PassPercentData.PassPercent }}</b></span>
    </div>
    <a-card>
      <div class="sea">
        <span>报表月份：</span>
        <a-month-picker @change="onDataChange" :disabledDate="disabledDate" placeholder="请选择报表月份"/>
        <span style="padding-left: 36px">系统校核状态：</span>
        <a-select style="width: 120px" placeholder="全部状态" @change="validatestatusChange">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="0">
            未校核
          </a-select-option>
          <a-select-option value="1">
            校核成功
          </a-select-option>
          <a-select-option value="2">
            特殊校核成功
          </a-select-option>
          <a-select-option value="-1">
            校核失败
          </a-select-option>
        </a-select>
        <!--        <span style="padding-left: 36px">经理审核状态：</span>
                <a-select style="width: 120px" placeholder="全部状态" @change="auditStatusChange">
                  <a-select-option value="">
                    全部
                  </a-select-option>
                  <a-select-option value="0">
                    待经理审核
                  </a-select-option>
                  <a-select-option value="1">
                    经理审核成功
                  </a-select-option>
                  <a-select-option value="-1">
                    经理审核不通过
                  </a-select-option>
                </a-select>-->
        <a-button type="primary" @click="sea" style="margin-right: 20px;margin-left: 50px;">
          搜索
        </a-button>
      </div>
    </a-card>
    <div style="margin-top: 10px;margin-bottom: 10px;line-height: 40px;padding: 10px 0; background: #FFFBE6;border: 1px solid #FFE58F;
border-radius:4px;"
    >
      <div class="tips">
        <span style="font-weight: bold;padding-left: 6px;">
          <a-icon type="info-circle" style="color: #ffc100;font-size: 20px;padding-right: 6px;" theme="filled"/> 注意事项:
        </span>

        <div>
          • 当【系统校核状态】显示【等待校核】时，请点击【操作】框内【校核】按钮；
        <br>
          • 当【系统校核状态】显示【校核失败】时，请点击【操作】框内【查看错误】确认数据是否错误。如确实无错误，确有特殊原因的，点击【特殊校核】填写原因；
        <br>
          • 当【系统校核状态】显示【校核成功】/【特殊校核成功】时，即表示数据上传成功。
       </div>
      </div>
    </div>
    <a-card style="margin-top: 8px;">
      <a-table :customRow="Rowclick" :columns="columns" :data-source="data" :rowKey="(record, index) => index">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <a slot="ExcelName" slot-scope="record" @click.stop="newDownExcel(record.ObjectKey)">
          <b style="font-weight: 500;text-decoration: underline ;"> {{ record.ExcelName }} </b>
        </a>
        <span slot="YearMonth" slot-scope="text">{{ day(text) }}</span>
        <span slot="CreateTime" slot-scope="text">{{ Creadeday(text) }}</span>
        <span slot="tags" slot-scope="tags">
         <a-badge :color="tagsColor(tags)"/>
          <span>
            {{ tags }}
          </span>
        </span>
        <span slot="AuditType" slot-scope="record">
          <a v-if="record.AuditType==='厂端管理员驳回' || record.AuditType==='厂端巡回员驳回'" @click.stop="getAuditTypeData(record)">{{
              record.AuditType }}</a>
          <span v-else>{{ record.AuditType }}</span>
        </span>
        <span slot="action" class="action" slot-scope="text, record">

<!--      IsInitInspec==0 和 IsKong >0 查看错误
          IsInitInspec==1 或  IsInitInspec ==2  查看报告
          IsInitInspec ==-1 和 jhError>0  查看错误
          IsInitInspec ==-1 和 jhError>0  特殊校核
          IsInitInspec==0 和 IsKong ==0 校核
          IsInitInspec ==2 查看特殊校核备注    -->

          <a v-if="record.IsInitInspec===0 && record.IsKong>0" @click.stop="() => showError(record)">查看错误 </a>
          <span v-if="PermissionName!=='财务经理填报'">
          <a  @click.stop="() => GOReport(record)">查看报告</a>
          </span>
          <a v-if="record.IsInitInspec===-1 && record.jhError>0" @click.stop="() => showError(record)">查看错误</a>
          <a v-if="record.IsInitInspec===-1 " @click.stop="() => showError(record)">查看错误</a>
          <a v-if="record.IsInitInspec===-1 " @click.stop="() => showSpecialModal(record)">特殊校核</a>
          <a v-if="record.IsInitInspec===0 && record.IsKong===0" @click.stop="() => noPassOk(record)">校核</a>
          <a v-if="record.IsInitInspec===2" @click.stop="() => showPassModal(record)">查看特殊校核备注</a>
     </span>
      </a-table>

    </a-card>
    <!-- 审核不通过    -->
    <a-modal :width="800" v-model="noPass" title="查看特殊校核备注" :pagination="false" :footer="null">
      <a-row>
        <a-table :columns="noPasscolumns" :data-source="noPassData" :rowKey="(record,index)=>index"></a-table>
      </a-row>
    </a-modal>
    <!-- 审核不通过    -->
    <!-- 特殊校核    -->
    <a-modal :width="1024" v-model="noSpecial" title="特殊校核原因" @ok="noSpecialOk">
      <a-table :columns="SpecialColumns" :data-source="SpecialData" :row-key="(record,index)=>index">
        <span slot="ErrDesc" slot-scope="record">
          【{{ record.ErrVarName }}】 {{ record.ErrDesc }}
        </span>
        <span slot="DealerCode" slot-scope="record">
<!--         <a-select style="width:200px" placeholder="请选择原因"  @change="changeSelect">
            <a-select-option value="">
              请选择原因
            </a-select-option>
            <a-select-option :value="item.Id" v-for="(item,index) in SpecialCheckListData" :key="index">
             {{ item.Id }}.{{ item.Name }}
            </a-select-option>
         </a-select>-->
       </span>
        <span slot="ErrVarName" slot-scope="text,record">
         <a-textarea placeholder="请输入特殊校核原因" :rows="1" v-model="record.CommentData"/>
       </span>
      </a-table>
    </a-modal>
    <!-- 特殊校核    -->

    <!--校核结果查看    -->
    <a-modal :width="800" v-model="isError" title="校核结果查看" :footer="null">
      <!--      <div><b>人工填报</b></div>-->
      <!--      <div style="height: 500px;overflow:auto;">
              <p v-for="(item,index) in errorData" :key="index">
                ({{ item.ErrVarName }}) {{ item.ErrDesc }}
              </p>
            </div>-->
      <a-table :columns="errorColumns" :data-source="errorData" :row-key="(record)=>record"></a-table>

      <div style="text-align: center;padding-top: 20px;">
        <a-button type="">
          <a @click="newDownExcel(downUrl)">下载标错报表</a>
        </a-button>
        <a-button type="primary" @click="showDrag">
          重新上传
        </a-button>
      </div>
    </a-modal>
    <!-- 文件拖拽上传   -->
    <a-modal v-model="isDrag" title="上传报表" :footer="null">
      <a-upload-dragger class="uploadStyle" name="file" :showUploadList="false" :customRequest="handleUpload">
        <p>将文件拖拽至此上传</p>
        <div class="">请上传Excel格式文件，需要支持HTML</div>
      </a-upload-dragger>
    </a-modal>
    <!-- 文件拖拽上传   -->
    <a-modal :width="800" v-model="isFile" title="错误列表" :footer="null" :maskClosable="true">
      <div style="height: 500px;overflow:auto;">
        <a-table :columns="errorColumns" :data-source="errorData" :row-key="(record,index)=>index"></a-table>
      </div>
      <p style="padding-top: 30px;text-align: center">
        <a-button type="primary" @click="FileClick">知道了</a-button>
      </p>
    </a-modal>
    <div class="foot-pad30"></div>
    <!-- 查看驳回原因    -->
    <a-modal v-model="AuditTypeData.isShow" :title="null" :footer="null" @cancel="AuditTypeData.isShow = false">
      <div style="height: 30px">&nbsp;</div>
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="驳回原因">
          <p>{{ AuditTypeData.RejectTitle }}</p>
        </a-form-item>
        <a-form-item label="审批意见">
          <a-textarea readonly :value="AuditTypeData.RejectContent" placeholder="暂无" :rows="4"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import CheckFail from "./checkFail";
import { dayFormat, CreadeDay, dayFormatMouth } from "../../../until/dayFormat";
import { disabledDate }  from '../../../until/dayFormat.js'

export default {
  name: "DataList",
  components: { CheckFail },
  data () {
    return {
      columns: [
        {
          title: "序号",
          width: 60,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "区域名称",
          dataIndex: "AreaName",
          key: "AreaName",
        },
        {
          title: "报表下载",
          key: "ExcelName",
          scopedSlots: { customRender: "ExcelName" },
        },
        {
          title: "上传月份",
          dataIndex: "YearMonth",
          key: "YearMonth",
          scopedSlots: { customRender: "YearMonth" },
        },
        {
          title: "上传次数",
          dataIndex: "UploadNum",
          key: "UploadNum",
        },
        {
          title: "最新上传时间",
          dataIndex: "LastUpdateTime",
          key: "LastUpdateTime",
          //    scopedSlots: { customRender: "CreateTime" },
        },
        {
          title: "系统校核状态",
          key: "ReportStatus",
          dataIndex: "ReportStatus",
          scopedSlots: { customRender: "tags" },
        },
        {
          title: "审核时间",
          dataIndex: "AuditTime",
          key: "AuditTime",
        },
        {
          title: "审核状态",
          key: "AuditType",
          scopedSlots: { customRender: "AuditType" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      errorColumns: [
        {
          title: "序号",
          width: "60px",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "指标名称",
          dataIndex: "ErrVarName",
          key: "ErrVarName",
        },
        {
          title: "店端实际数据",
          dataIndex: "NumValue",
          key: "NumValue",
        }, {
          title: "错误详情",
          dataIndex: "ErrDesc",
          key: "ErrDesc",
        },
      ],
      SpecialColumns: [
        {
          title: "序号",
          width: "60px",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "校核错误描述",
          //  dataIndex: "ErrDesc",
          key: "ErrDesc",
          scopedSlots: { customRender: "ErrDesc" },
        },/* {
          title: "原因",
          key: "DealerCode",
          scopedSlots: { customRender: "DealerCode" },
        },*/ {
          title: "原因",
          dataIndex: "ErrVarName",
          key: "ErrVarName",
          scopedSlots: { customRender: "ErrVarName" },
        },
      ],
      noPasscolumns: [
        {
          title: "序号",
          width: 60,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "指标名称",
          dataIndex: "KpiName",
          key: "KpiName",
        }, /*{
          title: "错误原因",
          dataIndex: "Name",
          key: "Name",
        }, */{
          title: "原因",
          dataIndex: "Memo",
          key: "Memo",
        },
      ],
      data: [],
      noPass: false,
      isFile: false,
      noPassval: "",
      noPassData: [],
      NocheckReson: "",
      PermissionName: "",
      CommentData: "",
      DealerCode: "",
      YearMonth: "", // 月份
      validatestatus: "", // 校核状态
      auditStatus: "", // 审核状态
      noSpecial: false,
      isDrag: false,
      isError: false,
      uploading: false,
      fileList: [],
      errorData: [],
      SpecialData: [],
      SpecialCheckListData: [],
      PassPercentData: {
        PassPercent: "0",
        UploadNum: 0,
        FaileCount: 0,
      },
      downUrl: "",
      checkConect: 0,
      AuditTypeData: {
        isShow: false,
        RejectTitle: "",
        RejectContent: "",
      },
    };
  },
  created () {
    this.getData("", "", "");
    this.checkConect = 0;
    this.sysSpecialCheckList();
  },
  methods: {
    disabledDate,
    /* 特殊校核原因*/
    async sysSpecialCheckList () {
      let res = await this.$Http.GetSpecialCheckList();
      if (res.Success) {
        this.SpecialCheckListData = res.Data;
      }
    },
    day (day) {
      return dayFormatMouth(day);
    },
    Creadeday (day) {
      return CreadeDay(day);
    },
    async getData (yearMonth, validateStatus, auditStatus) {

      /*获取当前的用户*/
      let ras = await this.$Http.GetOwnerPermissionName();
      if (ras.Success) {
        this.PermissionName = ras.Data[0].PermissionName;
      } else {
        this.$message.error(res.Message);
      }

      let fromData = {
        pageSize: 10,
        pageIndex: 1,
        yearMonth: yearMonth,
        validateStatus: validateStatus,
        auditStatus: auditStatus,
      };
      /*获取表格数据*/
      let res = await this.$Http.GetExcelUpListByPage(fromData);
      if (res.Success) {
        this.data = res.Data;
        if (res.Data.length > 0) {
          let DealerCode = res.Data[0].DealerCode;
          let YearMonth = res.Data[0].YearMonth;
          this.getPassPercent(DealerCode, YearMonth);
        }

      } else {
        this.$message.error("错误");
      }
    },
    /* 行点击 */
    Rowclick (record, index) {
      return {
        on: {
          click: () => {
            let DealerCode = record.DealerCode;
            let YearMonth = record.YearMonth.toString().substr(0, 10);
            //   let YearMonth = dayjs(record.YearMonth, "MM-DD-YYYY");

            this.getPassPercent(DealerCode, YearMonth);
          },
          // 鼠标移入
          /* mouseenter: () => {
             console.log(record, index, 2222);
             this.$message.error('2')
           }*/
        },
      };
    },
    /* 获取填报通过率 */
    async getPassPercent (DealerCode, YearMonth) {

      let res = await this.$Http.GetPassPercent({
        DealerCode, YearMonth,
      });
      if (res.Success) {
        if (res.Data.length > 0) {
          if (res.Data.length > 0) {
            this.PassPercentData = res.Data[0];
          }
        } else {
          this.$message.info("未获取到数据！");
        }
      } else {
        this.$message.error("错误");
      }
    },
    onDataChange (date, dateString) {
      this.yearMonth = dateString;
    },
    tagsColor (tags) {
      switch (tags) {
        case "校核成功":
          return "#19A47A";
        case "特殊校核成功":
          return "#19A47A";
        case "校核失败":
          return "#FD595A";
        case "等待校核":
          return "gold";
        default:
          return "#856AFF";
      }
    },
    /*是否显示通过权限*/
    isShowPass (AuditStatus) {
      if (AuditStatus === 0) {
        return true;
      } else if (this.PermissionName === "超级管理员") {
        return true;
      } else if (this.PermissionName === "销售经理") {
        return true;
      } else {
        return false;
      }
    },
    /*审核通过*/
    checkPass (id) {
      // console.log(id);
    },
    /*审核不通过*/
    async showPassModal (val) {
      this.noPass = !this.noPass;
      // this.noPassData = val;
      let YearMonth = val.YearMonth.toString().substr(0, 10);

      let res = await this.$Http.GetSpecialMemo({
        DealerCode: val.DealerCode,
        YearMonth: YearMonth,
      });

      this.noPassData = res.Data;

    },
    async noPassOk (val) {
      if (this.checkConect === 0) {
        this.checkConect = 1;
      } else {
        this.$message.error("请不要频繁操作！");
        return;
      }
      let YearMonth = val.YearMonth.toString().substr(0, 10);
      let res = await this.$Http.ValidateKpi({
        DealerCode: val.DealerCode,
        ObjectKey: val.ObjectKey,
        DealerName: val.DealerName,
        YearMonth: YearMonth,
        //   Comment: this.noPassval,
        // IsCheck: 0,
      });
      if (res.Success) {
        this.$message.success(res.Data);
      } else {
        this.$message.error(res.Message);
      }

      this.getData("", "", "");
    },
    /*特殊校核*/
    async showSpecialModal (val) {
      this.NocheckReson = "";
      this.CommentData = "";
      this.noPassData = val;
      this.noSpecial = !this.noSpecial;
      let YearMonth = val.YearMonth.toString().substr(0, 10);
      let res = await this.$Http.GetErrorKpiDetail({
        DealerCode: val.DealerCode,
        YearMonth,
      });
      this.SpecialData = res.Data;
    },
    changeSelect (value) {
      //   console.log(`selected ${value}`);
    },
    FileClick () {
      this.isFile = false;
      //   this.router.push("/ddataList");
    },
    async noSpecialOk () {
      let ListDetailInfo = [];
      let IsSubmit = false;
      this.SpecialData.forEach((item) => {

        ListDetailInfo.push({
          KpiCode: item.ErrVarCode,
          // CheckResonId: item.NocheckReson,
          Memo: item.CommentData,
        });

      });
      for (let key = 0; key < ListDetailInfo.length; key++) {
        let page = 0;
        let pageCol = 0;
        if (key > 9) {
          pageCol = key - 9;
          page = (key + 1) % 10;
        } else {
          pageCol = key + 1;
        }
        if (typeof ListDetailInfo[key].Memo === "undefined") {
          //   this.$message.error(`${page + 1}页${pageCol}行未填！`);
          this.$message.error("请正确填写原因");
          return false;
        }

        if (ListDetailInfo[key].Memo.length < 4) {
          //  this.$message.error(`${page + 1}页${pageCol}行写的太短了！`);
          this.$message.error("请正确填写原因");
          return false;
        }

        /*if (this.hasNumber(ListDetailInfo[key].Memo)) {
        //  this.$message.error(`${page + 1}页${pageCol}行不能包含数字！`);
          this.$message.error('请正确填写原因');
          return false;
        }*/
      }

      let YearMonth = this.noPassData.YearMonth.toString().substr(0, 10);
      let res = await this.$Http.SpecialValidateKpi({
        DealerCode: this.noPassData.DealerCode,
        YearMonth: YearMonth,
        //  NocheckReson: this.NocheckReson,
        ListDetailInfo: ListDetailInfo,
      });
      if (res.Success) {
        this.$message.success("特殊校核成功！");
      } else {
        this.$message.error(res.Data);
      }

      this.getData("", "", "");
      this.noSpecial = !this.noSpecial;
    },
    // 上传文件
    showDrag () {
      this.isDrag = !this.isDrag;
    },
    hasNumber (str) {
      for (let i in str) {
        var asc = str.charCodeAt(i);
        if (asc >= 48 && asc <= 57) {
          return true;
        }
      }
      return false;
    },
    // 查看错误文件 工具
    async showError (val) {
      this.isError = !this.isError;
      this.downUrl = val.ObjectKey;
      let YearMonth = val.YearMonth.toString().substr(0, 10);
      let res = await this.$Http.GetErrorLogs({
        DealerCode: val.DealerCode,
        YearMonth,
      });
      if (res.Success) {
        this.errorData = res.Data;
      }
      if (this.errorData.length === 0) {
        let rcs = await this.$Http.GetErrorKpiDetail({
          DealerCode: val.DealerCode,
          YearMonth,
        });
        this.errorData = rcs.Data;
      }
    },
    uploadOk (e) {
      this.isDrag = !this.isDrag;
    },
    async handleUpload (file) {
      const formData = new FormData();
      formData.append("file", file.file);
      let res = await this.$Http.uploadFile(formData);
      if (res.Success) {
        this.$message.success(res.Data);
      } else {
        if (res.Message === "object") {
          this.$message.success("上传文件有错误，请查看！");
          this.isFile = true;
          this.errorData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      }
      setTimeout(() => {
        this.getData("", "", "");
      }, 500);
      this.isError = false;
      this.isDrag = false;
      this.checkConect = 0; // 重置 校核可以点击
    },
    validatestatusChange (val) {
      this.validatestatus = val;
    },
    auditStatusChange (val) {
      this.auditStatus = val;
    },
    async sea () {
      if (this.yearMonth === undefined || this.yearMonth === "") {
        this.yearMonth = "";
      } else {
        this.yearMonth += "-01";
      }
      this.pageIndex=1;
      this.getData(this.yearMonth, this.validatestatus, this.auditStatus);
    },
    GOReport (record) {
      let YearMonth = record.YearMonth.toString().substr(0, 10);
      window.open("/AllDashboard?DealerCode=" + record.DealerCode + "&ReportDate=" + YearMonth, "_blank");
    },
    /* 直接下载EXCEL*/
    DownExcel (val) {
      window.open(val);
    },
    /* 接口下载EXCEL*/
    async newDownExcel (val) {
      let res = await this.$Http.FileShareLink({
        objectkey: val,
      });
      if (res.Success) {
        window.open(res.Data);
      }
    },
    async getAuditTypeData(data) {
      const res = await this.$Http.GetRejectDetail({
        rowId: data.Id,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      if(res.Data) {
        this.AuditTypeData.RejectTitle = res.Data.RejectTitle;
        this.AuditTypeData.RejectContent = res.Data.RejectContent;
      } else {
        this.AuditTypeData.RejectTitle = "";
        this.AuditTypeData.RejectContent = "";
      }
      this.AuditTypeData.isShow = true;
    },
  },
};
</script>

<style lang="less" scoped>

// @import url('./dataList.less');
.top {
  margin-bottom: 8px;
  background: #E6F7FF;
  height: 38px;
  line-height: 38px;
  padding: 0 24px;
  border: 1px solid #BAE7FF;

  span {
    padding-right: 36px;
    color: #444444;

    b {
      color: #24619e;
      font-weight: 500;
      text-align: center;
    }
  }

  .sea {
    span {
      padding-left: 36px;
      color: red;
    }
  }


}

.action {
  a {
    padding: 0 5px;
  }
}


.tips {
  color: #444;
  font-weight: 500;
  font-size: 14px;
  padding-left: 25px;
  div{
    display: block;
    padding-left: 30px;
  }
}
</style>
