<template>
  <section>
    <a-card>
      <a-form
          :form="form"
          layout="horizontal"
          :labelCol="{ span: 10 }"
          :wrapperCol="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="关键词">
              <a-input placeholder="关键词" v-model="form.keyWords"/>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="getList(1)"> 查询</a-button>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="addMsg"> 新增</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card style="margin-top: 18px;">
      <a-table
          rowKey="ID"
          :columns="columns"
          :data-source="tableData"
          :pagination="paginationParam"
          @change="tableChange"
      >
        <span slot="MsgType" slot-scope="MsgType">
          {{ getMsgType(MsgType) }}
        </span>
        <span slot="PushState" slot-scope="PushState">
          {{ PushState == 0 ? "未推送" : "已推送" }}
        </span>
        <span slot="action" slot-scope="record">
          <a-button size="small" type="primary" @click="doView(record)"
          >查看站内信</a-button
          >
          <a-divider type="vertical" v-if="record.PushState == 0"/>
          <a-button
              size="small"
              type="primary"
              @click="doEdit(record)"
              v-if="record.PushState == 0"
          >编辑</a-button
          >
          <a-divider type="vertical" v-if="record.PushState == 0"/>
          <a-popconfirm
              title="确定推送吗？"
              @confirm="() => doPush(record)"
              v-if="record.PushState == 0"
          >
            <a-button size="small" type="primary">推送</a-button>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.PushState == 0"/>
          <a-popconfirm
              title="确定删除吗？"
              @confirm="() => doDel(record)"
              v-if="record.PushState == 0"
          >
            <a-button size="small" type="primary">删除</a-button>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.PushState == 1"/>
          <a-button
              size="small"
              type="primary"
              v-if="record.PushState == 1"
              @click="viewPushPeople(record)"
          >查看已推送人员</a-button
          >
        </span>
      </a-table>
    </a-card>
    <a-modal
        v-model="visible"
        title="已推送人员"
        :footer="null"
        @cancel="publishedPeople = []"
    >
      <a-table
          :columns="columns2"
          :data-source="publishedPeople"
          rowKey="ID"
          :scroll="{ y: 400 }"
      >
      </a-table
      >
    </a-modal>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
import { msgTypeEnum } from "@/enum/enum.js";

const columns = [
  {
    title: "序号",
    width: "60px",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "消息标题",
    dataIndex: "MsgTitle",
    key: "MsgTitle",
  },
  {
    title: "消息类型",
    key: "MsgType",
    dataIndex: "MsgType",
    scopedSlots: { customRender: "MsgType" },
  },
  {
    title: "是否已推送",
    dataIndex: "PushState",
    scopedSlots: { customRender: "PushState" },
    key: "PushState",
  },
  {
    title: "推送时间",
    dataIndex: "PushTime",
    scopedSlots: { customRender: "PushTime" },
    key: "PushTime",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    key: "action",
  },
];

const columns2 = [
  {
    title: "姓名",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "用户名",
    key: "UserName",
    dataIndex: "UserName",
  },
];

export default {
  data() {
    return {
      form: {
        //关键字
        keyWords: "",
      },
      //分页数据
      pageData: {},
      //表格数据
      tableData: [],
      columns,
      columns2,
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      //消息类型
      msgTypeList: [
        {
          label: "系统消息",
          value: msgTypeEnum.systemMsg,
        },
        {
          label: "填报消息",
          value: msgTypeEnum.reportMsg,
        },
      ],
      visible: false,
      //已推送人员
      publishedPeople: [],
    };
  },
  methods: {
    getPermission() {
      const Permission = window.localStorage.getItem("permission");
      if(Permission.indexOf("站内信管理") < 0) {
        this.$message.error("您没有权限,请联系管理员!");
        setTimeout(() => {
          this.$router.push("/main");
        },2000);
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.getList(this.paginationParam.current);
    },

    //获取列表
    async getList(current) {
      let self = this;
      //将分页参数传入form对象
      self.form.pageSize = self.paginationParam.pageSize;
      self.form.pageIndex = self.paginationParam.current = current;
      let res = await self.$Http.GetMsgListByPage(self.form);
      self.paginationParam.total = res.TotalCount;
      self.tableData = res.Data;
    },
    //查看
    doView(record) {
      this.$router.push({ path: "/msgMannage/view", query: record });
    },
    //编辑
    doEdit(record) {
      this.$router.push({ path: "/msgMannage/edit", query: record });
    },
    //删除
    async doDel(record) {
      let self = this;
      let res = await self.$Http.DeleteMsg({ msgId: record.ID });
      if(res.Success) {
        self.$message.success(res.Data);
        self.getList(1);
      } else {
        self.$message.error(res.Message);
      }
    },
    //推送
    async doPush(record) {
      let self = this;
      let res = await self.$Http.PushMsg({ msgId: record.ID });
      if(res.Success) {
        self.$message.success(res.Data);
        self.getList(1);
      } else {
        self.$message.error(res.Message);
      }
    },
    addMsg() {
      this.$router.push("/msgMannage/add");
    },
    getMsgType(msgType) {
      let matchList = this._.where(this.msgTypeList, { value: msgType });
      return matchList.length > 0 ? matchList[0].label : "未知";
    },
    //查看已推送人员
    async viewPushPeople(record) {
      let self = this;
      let res = await self.$Http.ViewMsgUsers({ msgId: record.ID });
      this.publishedPeople = res.Data;
      this.visible = true;
    },
  },
  created() {
  },
  mounted() {
    this.getPermission();
    this.getList(1);
  },
};
</script>
