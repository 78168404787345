<template>
  <a-card class="card">
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 6 }"
    >
      <a-form-model-item label="小区名称" prop="areaName">
        <a-input v-model="form.areaName" />
      </a-form-model-item>
      <a-form-model-item label="大区名称" prop="partId">
        <a-select v-model="form.partId">
          <a-select-option
            v-for="(item, i) in partList"
            :key="i"
            :value="item.PartId"
          >
            {{ item.PartName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="sort">
        <a-input-number v-model="form.sort" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" @click="save"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: {
        areaId: "",
        areaName: "",
        sort: null,
        partId: "",
      },
      partList: [],
      rules: {
        areaName: [
          {
            required: true,
            message: "请输入小区名称",
            trigger: ["blur", "change"],
          },
        ],
        partId: [
          {
            required: true,
            message: "请选择大区名称",
            trigger: ["blur", "change"],
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async init() {
      let self = this;
      //编辑
      if (self.$route.path == "/areaMannage/edit") {
        //获取当前数据
        let res = await self.$Http.GetAreaInfoById({
          areaId: self.$route.query.areaId,
        });
        if (res.Data.length > 0) {
          self.form.areaId = res.Data[0].AreaId;
          self.form.areaName = res.Data[0].AreaName;
          self.form.sort = res.Data[0].Sort;
          self.form.partId = res.Data[0].PartId;
        }
      }
      //新增
      else {
        //判断是否有partId
        if (typeof self.$route.query.partId != "undefined") {
          self.form.partId = self.$route.query.partId;
        }
        //获取排序号
        let res = await self.$Http.GetAreaSortIndex();
        self.form.sort = res.Data;
      }
    },
    async save() {
      let self = this;
      self.$refs.form.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res =
            self.$route.path == "/areaMannage/edit"
              ? await self.$Http.EditArea(this.form)
              : await self.$Http.InsertArea(this.form);
          if (res.Success) {
            this.$message.success(res.Data);
            this.$router.push("/areaMannage/list");
          } else {
            this.$message.error(res.Message);
          }
        }
      });
    },
    //获取大区列表
    async getPartList() {
      let self = this;
      let res = await self.$Http.GetPart();
      self.partList = res.Data;
    },
  },
  mounted() {
    this.init();
    this.getPartList();
  },
};
</script>