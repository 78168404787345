<template>
  <a-row>
    <a-col v-if="isCloseA">
      <a-anchor :offsetTop="45" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          <a-col :span="20">
            <switch-setting :api-instance.sync="params.Http"/>
            时间维度：
            <a-month-picker @change="handleReportChange" placeholder="请选择报告月份" v-model="ReportDateMoment"
                style=" margin-right: 4px;width:110px" :allowClear="false"
            />
            <span style="padding-left: 36px">报告范围：</span>
            <a-select @change="dealerChange" v-model="params.PartName" style="margin-left: 1vw; width: 150px" placeholder="请选择大区">
              <a-select-option v-for="item in partList" :key="item.PartId" :value="item.PartName">
                {{ item.PartName }}
              </a-select-option>
            </a-select>
            <a-button type="primary" style="margin-left: 10px" @click="selectFinch">生成图表</a-button>
          </a-col>
          <a-col style="float: right">
            <!--                        <a-tag color="#24619e">运营分析</a-tag>
                                    <a-tag @click="$router.push('/profitReport')">盈利分析</a-tag>-->
            <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false"><a-icon type="close"/></span>
            <a-tooltip placement="top" :title="`点击生成《${title.ReportDate + title.PartName }运营分析报告》`">
              <a-button type="primary" @click="generateImages()">生成长图</a-button>
            </a-tooltip>
          </a-col>
        </div>
      </a-anchor>
    </a-col>
    <a-col :span="24" style="min-width: 1280px">
      <div class="" ref="capture">
        <div class="report_bg">
          {{ title.ReportDate + title.PartName }}运营分析报告
        </div>
        <one-operation :data-params="params" :all-data="allData"/>
        <two-operation :data-params="params" :all-data="allData"/>
        <there-operation :data-params="params" :all-data="allData"/>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { disabledDate } from "@/until/dayFormat";
import moment from "moment";
import oneOperation from "@/views/operationReport/operationChild/oneOperation";
import twoOperation from "@/views/operationReport/operationChild/twoOperation";
import thereOperation from "@/views/operationReport/operationChild/thereOperation";
import {
  lastMonthFilterCodes,
  opCurrentFilterCodes,
  opPushFilterCodes,
} from "@/views/profitReport/untilProfit";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  name: "operationReport",
  components: { oneOperation, twoOperation, thereOperation },
  data() {
    return {
      disabledDate,
      isCloseA: true,
      params: {
        ReportDate: "",
        DealerCode: "",
        PartName: "",
        PartId: "",
        AreaList: [], // 区域列表
        Http: {},
      },
      title: {
        ReportDate: "",
        PartName: "",
      },
      allData: {},
      partList: [],
    };
  },
  async mounted() {
    this.getParams();
    await this.getDealersByRole();
    await this.selectFinch();
  },
  methods: {
    getParams() {
      //js判断是否为空对象
      if(Object.keys(this.$route.query).length > 0) {
        this.params = this.$route.query;
      } else {
        //若没有参数（从菜单点击），则自动获取上个月报告
        this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
      }
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, `${this.title.ReportDate + this.title.PartName}运营分析报告.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${this.title.ReportDate + this.title.PartName}运营分析报告.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
    async handleReportChange(date, dateString) {
      this.params.ReportDate = dateString + "-01";
      //日期修改后同步数据给子组件
    },
    async dealerChange(value, option) {
      this.params.PartName = value;
      this.params.PartId = option.data.key;
    },
    async getData() {
      let res = await this.params.Http.Analyze({
        pushFilterCodes: opPushFilterCodes,
        lastMonthFilterCodes,
        currentFilterCodes: opCurrentFilterCodes,
        partId: this.params.PartId,
        reportDate: this.params.ReportDate,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return false;
      } else {
        if(Object.keys(res.Data).length < 1) {
          this.$message.error("无数据,请重新选择有数据的月份!");
        }
        this.allData = res.Data;
      }
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.partList = res.Data;
      this.partList.forEach(d => {
        let arr = {};
        arr["PartId"] = d.PartId;
        arr["PartName"] = d.PartName.replace("营销中心", "");
        this.params.AreaList.push(arr);
      });
      this.params.AreaList.push({
        PartId: "全国",
        PartName: "均值",
      });
      if(!this.params.PartName && this.partList.length > 0) {
        this.params.PartName = res.Data[0].PartName;
        this.params.PartId = res.Data[0].PartId;
      }
    },
    async selectFinch() {
      this.title.ReportDate = moment(this.params.ReportDate).format("YYYY年M月");
      this.title.PartName = this.params.PartName;
      this.title.PartId = this.params.PartId;
      await this.getData();
    },
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
};
</script>

<style lang="less" scoped>
.ant-tag {
  cursor: pointer;
  padding: 4px 6px;
  }
</style>
