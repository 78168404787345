import {
  yAxisRightUnit, legend, smooth, yAxisDefaultUnit, yAxisDefault, yAxisTenThousandYuan,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { tenThousandYuan, ThousandYuan } from "@/until/option";
import { yAxisUnit } from "@/views/monthReport/shopAnalysis/component/kpiCode";
const colors =[];
const barMaxWidth = "35%";
const xAxis =[
  {
    type: "category",
    axisLabel:{
      interval:0,
    },
    axisLine:{
      show:true,
      lineStyle:{ type:"sold",width:1.3 },
    },
  },
];
let storeReportAfterSales = {
    tableData_3_1_1: {
        table: [{
            name: "售后来厂台次",
            kpiCode: "B0302001",
        }, {
            name: "售后业务毛利",
            kpiCode: "B0105113",
        }, {
            name: "其中：机电维修毛利",
            kpiCode: "B0104166",
        }, {
            name: "钣喷维修毛利",
            kpiCode: "B0104177",
            indentation: 3,
        }, {
            name: "售后水平事业毛利",
            kpiCode: "B0104187",
            indentation: 3,
        }, {
            name: "售后业务毛利率",
            kpiCode: "B0106026",
            indentation: 3,
        }, {
            name: "其中：机电维修毛利率",
            kpiCode: "B0105087",
        }, {
            name: "钣喷维修毛利率",
            kpiCode: "B0105098",
            indentation: 3,
        }, {
            name: "售后水平事业毛利率",
            kpiCode: "B0105108",
            indentation: 3,
        }, {
            name: "售后单车收入",
            kpiCode: "B0105114",
        }, {
            name: "售后单车毛利",
            kpiCode: "B0106027",
        }],
        data: [
            {
                kpiCode: "",
                name: "",
                indentation: 0, // 缩进多少 EM
                Unit: "",
                NationwideStandard: "",
                RegionalStandard: "",
                Current: "",
                LastMonth: "",
                LastYear: "",
                CurrentYear: "",
            },
        ],
    },
    tableData_3_2_1: {
        table: [{
            name: "机电维修收入占比",
            kpiCode: "B0105139",
        }, {
            name: "钣喷维修收入占比",
            kpiCode: "B0105150",
        }, {
            name: "售后水平事业收入占比",
            kpiCode: "B0105112",
        }],
        data: [],
    },
    tableData_3_3_1: {
        table: [{
            name: "AB类客户占有效客户数比",
            kpiCode: "B0303051",
        }, {
            name: "客户流失率",
            kpiCode: "B0304014",
        }, {
            name: "客户维系率",
            kpiCode: "B0303024",
        }, {
            name: "首保实施率",
            kpiCode: "B0303025",
        }, {
            name: "首次付费保养实施率",
            kpiCode: "B0303026",
        }, {
            name: "首保招揽成功率",
            kpiCode: "B0303071",
        }, {
            name: "定保招揽成功率",
            kpiCode: "B0303072",
        }, {
            name: "次新车续保率",
            kpiCode: "B0303052",
        }],
        data: [],
    },
    tableData_3_4_1: {
        table: [{
            name: "机电工位日均周转次数",
            kpiCode: "B0304001",
        }, {
            name: "维修技师人均生产力(售后维修毛利)",
            kpiCode: "B0306002",
        }, {
            name: "机电技师人均生产力(工时毛利)",
            kpiCode: "B0104256",
        }, {
            name: "钣喷技师人均生产力(工时毛利)",
            kpiCode: "B0104283",
        }, {
            name: "零件部人员人均生产力(毛利)",
            kpiCode: "B0105133",
        }],
        data: [],
    },
    tableData_3_5_1: {
        table: [{
            name: "零部件库存周转率",
            kpiCode: "B0404002",
        }, {
            name: "0~90天期末零部件库存金额占比",
            kpiCode: "B0403003",
        }, {
            name: "91~180天期末零部件库存金额占比",
            kpiCode: "B0403004",
        }, {
            name: "181~365天期末零部件库存金额占比",
            kpiCode: "B0403005",
        }, {
            name: ">365天期末零部件库存金额占比",
            kpiCode: "B0403006",
        }],
        data: [],
    },
    tableData_3_6_1: {
        table: [{
            name: "售后水平事业毛利率",
            kpiCode: "B0105108",
            indentation: 0,

        }, {
            name: "售后养护品毛利率",
            kpiCode: "B0104189",
        }, {
            name: "售后续保毛利率",
            kpiCode: "B0104193",
        }, {
            name: "售后延保毛利率",
            kpiCode: "B0104191",
        }],
        data: [],
    },
    tableData_3_6_2: {
        table: [{
            name: "平均单车售后水平事业毛利",
            kpiCode: "B0105134",
        }, {
            name: "平均单车售后养护品毛利",
            kpiCode: "B0104229",
        }, {
            name: "平均单车售后续保毛利",
            kpiCode: "B0104233",
        }, {
            name: "平均单车售后延保毛利",
            kpiCode: "B0104231",
        }],
        data: [],
    },
    tableData_3_6_3: {
        table: [{
            name: "售后养护品渗透率",
            kpiCode: "B0303006",
        }, {
            name: "售后续保渗透率",
            kpiCode: "B0304003",
        }, {
            name: "售后延保渗透率",
            kpiCode: "B0303008",
        }],
        data: [],
    },
    tableData_3_6_4: {
        table: [{
            name: "售后毛利未覆盖费用",
            kpiCode: "C0207031",
        }, {
            name: "零服吸收率",
            kpiCode: "B0107002",
        }],
        data: [],
    },
    chart_3_1_1_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // columns: ["类目", "售后来厂台次", "售后单车收入", "售后单车毛利"],
            // showLine: ["售后单车收入", "售后单车毛利"],
            // yAxisName: ["台", "元"],
            // yAxisType: ["0.[00]", "0.[00]"],
            // axisSite: {
            //     right: ["售后单车收入", "售后单车毛利"],
            // },
        },
        kpiCodes: [
            {
                code: "B0302001",
                name: "售后来厂台次",
            }, {
                code: "B0105114",
                name: "售后单车收入",
            }, {
                code: "B0106027",
                name: "售后单车毛利",
            },
        ],
        rows: [],
        options: {
            legend:{
              data: [],
              textStyle: {
                fontSize: 12
              }
            },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后来厂台次: " + Number(params[0].value).toFixed(0) + "台",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后单车收入： " + (params[1].value / 10000).toFixed(2) + "万元",
                        val3 = "<span style=\"background-color:" + params[2].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后单车毛利： " + (params[2].value / 10000).toFixed(2) + "万元";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2} <br />${val3}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('台'),
                {
                    name: "万元",
                    type: "value",
                    axisLine: {
                        //y轴
                        show: true,

                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: (val) => {
                            return `${tenThousandYuan(val ?? 0)}`;
                        },
                    },
                },
            ],
            series: [{
                name: "售后来厂台次",
                code: "B0302001",
                type: "bar", barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {

                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return ThousandYuan(params.value ?? 0);
                        },
                    },
                },
            }, {
                name: "售后单车收入",
                code: "B0105114",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return tenThousandYuan(params.value ?? 0);
                        },
                    },
                },
            }, {
                name: "售后单车毛利",
                code: "B0106027",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {

                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return tenThousandYuan(params.value);
                        },
                    },
                },
            }],
        },
    },
    chart_3_2_1_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
        },
        kpiCodes: [
            {
                code: "B0105139",
                name: "机电维修收入占比",
            }, {
                code: "B0105150",
                name: "钣喷维修收入占比",
            }, {
                code: "B0105112",
                name: "售后水平事业收入占比",
            },
        ],
        rows: [],
        options: {
            tooltip: {
                show: true,
                trigger: "axis",
                /*todo 循环显示图表的鼠标经过效果*/
                formatter: function (params) {
                    let arr = [];
                    params.forEach((d) => {
                        arr.push({
                            val: `<span style='background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;'></span>${d.seriesName}:${Number(d.value).toFixed(1)}%`,
                        });
                    });
                    return `${params[0].axisValue} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}`;
                },
            },
            legend,
            calculable: false,
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [
                {
                    name: "机电维修收入占比",
                    code: "B0105139",
                    type: "bar", barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: "钣喷维修收入占比",
                    code: "B0105150",
                    type: "bar",barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: "售后水平事业收入占比",
                    code: "B0105112",
                    type: "bar",barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
            ],
        },
    },
    chart_3_3_1_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["AB类客户占有效客户数比"],
            // yAxisType: ["0.[00]", "0.[00]%"],
            // yAxisName: ["个", "%"],
            // axisSite: {
            //     right: ["AB类客户占有效客户数比"],
            // },
        },
        kpiCodes: [
            {
                code: "B0302037",
                name: "有效保有客户数",
            }, {
                code: "B0303051",
                name: "AB类客户占有效客户数比",
            }],
        columns: ["类目", "有效保有客户数", "AB类客户占有效客户数比"],
        rows: [],
        options: {
            legend,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>有效保有客户数: " + Number(params[0].value).toFixed(0),
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>AB类客户占有效客户数比： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [
               yAxisDefaultUnit('个'), yAxisRightUnit('%'),
            ],
            series: [{
                name: "有效保有客户数",
                code: "B0302037",
                type: "bar",  barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(0);
                        },
                    },
                },
            }, {
                name: "AB类客户占有效客户数比",
                code: "B0303051",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },
    },
    chart_3_3_2_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["首保实施率", "首次付费保养进厂率"],
            // yAxisType: ["0.[00]%"],
            // yAxisName: ["%"],
        },
        kpiCodes: [
            {
                code: "B0303025",
                name: "首保实施率",
            }, {
                code: "B0303026",
                name: "首次付费保养进厂率",
            }],
        rows: [],
        options: {
            legend,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>首保实施率: " + Number(params[0].value).toFixed(1) + "%",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>首次付费保养进厂率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [{
                name: "首保实施率",
                code: "B0303025",
                type: "line",smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }, {
                name: "首次付费保养进厂率",
                code: "B0303026",
                type: "line",smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },
    },
    chart_3_4_1_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["机电工位日均周转次数", "维修技师人均生产力(售后维修毛利)"],
            // yAxisName: ["次", "元"],
            // yAxisType: ["0.[00]", "0.[00]"],
            // axisSite: {
            //     right: ["维修技师人均生产力(售后维修毛利)"],
            // },
            // label: {
            //     show: true,
            //     formatter: function (params) {
            //         return params.data.toFixed(2);
            //     },
            // },
        },
        kpiCodes: [
            {
                code: "B0304001",
                name: "机电工位日均周转次数",
            }, {
                code: "B0306002",
                name: "维修技师人均生产力(售后维修毛利)",
            }],
        rows: [],
        options: {
            legend,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>机电工位日均周转次数: " + (params[0].value).toFixed(2) + "次",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>维修技师人均生产力(售后维修毛利)： " + (params[1].value / 10000).toFixed(2) + "万元";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('次'), {
                    name: "万元",
                    type: "value",
                    position: "right",
                    axisLine: {
                        show: true,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: (val) => {
                            return `${(val / 10000).toFixed(2)}`;
                        },
                    },
                },
            ],
            series: [{
                name: "机电工位日均周转次数",
                code: "B0304001",
                type: "line",smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(2);
                        },
                    },
                },
            }, {
                name: "维修技师人均生产力(售后维修毛利)",
                code: "B0306002",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value / 10000).toFixed(2);
                        },
                    },
                },
            }],
        },
    },
    chart_3_5_1_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // yAxisName: ["次"],
            // yAxisType: ["0.[00]"],
            // label: {
            //     show: true,
            //     formatter: function (params) {
            //         return params.data.toFixed(2);
            //     },
            // },
        },
        kpiCodes: [
            {
                code: "B0404002",
                name: "零部件库存周转率",
            }],
        rows: [],
        options: {
            legend,
            tooltip: {
              show: true,
              trigger: "item",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function (params) {
                let val = "<span style=\"background-color:" + params.color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + Number(params.value).toFixed(2) + "次";
                return `${params.name}:<br /> ${val}`;
              },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('次') ],
            series: [
              {
                name: "零部件库存周转率",
                code: "B0404002",
                type: "bar",barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(2) ;
                        },
                    },
                },
                markLine: {
                  symbol: "none", //去掉警戒线最后面的箭头
                  data: [{
                    silent: false,             //鼠标悬停事件  true没有，false有
                    name: "区域标杆",
                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                      //   type:"solid",
                      color: "#5470C6",
                      width: 2,
                    },
                    label: {
                      show: false,
                      textStyle: {
                        color: "black",
                      },
                      formatter: function (params) {
                        return "区域标杆(" + Number(params.value).toFixed(1) + "%)";
                      },
                      position: "start",          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                    },
                    yAxis: null,           // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                    code: "B0404002",
                    key: "RegionalStandard",
                  },
                    {
                      silent: true,             //鼠标悬停事件  true没有，false有
                      name: "全国标杆",
                      lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                 //    type:"solid",
                        color: "#71BE95",
                        width: 2,
                      },
                      label: {
                        position: "middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                        show: false,
                        textStyle: {
                          color: "black",
                        },
                        formatter: function (params) {
                          return "全国标杆(" + Number(params.value).toFixed(1) + "%)";
                        },
                      },
                      yAxis: null,        // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      code: "B0404002",
                      key: "NationwideStandard",
                    },
                  ],
                },
            }],
        },
    },
    chart_3_5_2_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
        },
        kpiCodes: [
            {
                code: "B0403003",
                name: "0~90天期末零部件库存金额占比",
            }, {
                code: "B0403004",
                name: "91~180天期末零部件库存金额占比",
            }, {
                code: "B0403005",
                name: "181~365天期末零部件库存金额占比",
            }, {
                code: "B0403006",
                name: ">365天期末零部件库存金额占比",
            }],
        rows: [],
        options: {

            tooltip: {
                show: true,
                trigger: "axis",
                /*todo 循环显示图表的鼠标经过效果*/
                formatter: function (params) {
                    let arr = [];
                    params.forEach((d) => {
                        arr.push({
                            val: `<span style='background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;'></span>${d.seriesName}:${Number(d.value).toFixed(1)}%`,
                        });
                    });
                    return `${params[0].axisValue} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}<br />${arr[3].val}`;
                },
            },
            legend,
            calculable: false,
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [
                {
                    name: "0~90天期末零部件库存金额占比",
                    code: "B0403003",
                    type: "bar", barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return  Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: "91~180天期末零部件库存金额占比",
                    code: "B0403004",
                    type: "bar",barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: "181~365天期末零部件库存金额占比",
                    code: "B0403005",
                    type: "bar",barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: ">365天期末零部件库存金额占比",
                    code: "B0403006",
                    type: "bar",barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
            ],
        },
    },
    chart_3_6_1_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["售后水平事业毛利率"],
            // yAxisName: ["元", "%"],
            // yAxisType: ["0.[00]", "0.[00]%"],
            // axisSite: {
            //     right: ["售后水平事业毛利率"],
            // },
        },
        kpiCodes: [
            {
                code: "B0105134",
                name: "平均单车售后水平毛利",
            }, {
                code: "B0105108",
                name: "售后水平事业毛利率",
            }],
        rows: [],
        options: {
            legend,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>平均单车售后水平毛利: " + (params[0].value).toFixed(2),
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后水平事业毛利率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisTenThousandYuan,yAxisRightUnit('%')],
            series: [{
                name: "平均单车售后水平毛利",
                code: "B0105134",
                type: "bar",   barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(2);
                        },
                    },
                },
            }, {
                name: "售后水平事业毛利率",
                code: "B0105108",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },
    },
    chart_3_6_2_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["售后养护品渗透率"],
            // yAxisName: ["元", "%"],
            // yAxisType: ["0.[00]", "0.[00]%"],
            // axisSite: {
            //     right: ["售后养护品渗透率"],
            // },
        },
        kpiCodes: [
            {
                code: "B0104229",
                name: "平均单车售后养护品毛利",
            }, {
                code: "B0303006",
                name: "售后养护品渗透率",
            }],
        rows: [],
        options: {
            legend,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>平均单车售后养护品毛利: " + Number(params[0].value).toFixed(0) + "元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后养护品渗透率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('元'), yAxisRightUnit('%')
            ],
            series: [{
                name: "平均单车售后养护品毛利",
                code: "B0104229",
                type: "bar", barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(0) + "元";
                        },
                    },
                },
            }, {
                name: "售后养护品渗透率",
                code: "B0303006",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },

    },
    chart_3_6_3_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["售后续保渗透率"],
            // yAxisName: ["元", "%"],
            // yAxisType: ["0.[00]", "0.[00]%"],
            // axisSite: {
            //     right: ["售后续保渗透率"],
            // },
        },
        kpiCodes: [
            {
                code: "B0104233",
                name: "平均单车售后续保毛利",
            }, {
                code: "B0304003",
                name: "售后续保渗透率",
            }],
        rows: [],
        options: {
          legend: {
            data: [],
            textStyle: {
              fontSize: 12
            }
          },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>平均单车售后续保毛利: " + Number(params[0].value).toFixed(0) + "元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后续保渗透率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('元'), yAxisRightUnit('%')],
            series: [{
                name: "平均单车售后续保毛利",
                code: "B0104233",
                type: "bar", barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(0);
                        },
                    },
                },
            }, {
                name: "售后续保渗透率",
                code: "B0304003",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {

                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },

    },
    chart_3_6_4_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["服务满足率"],
            // yAxisName: ["元", "%"],
            // yAxisType: ["0.[00]", "0.[00]%"],
            // axisSite: {
            //     right: ["服务满足率"],
            // },
        },
        kpiCodes: [
            {
                code: "C0207031",
                name: "售后毛利未覆盖费用",
            }, {
                code: "B0107002",
                name: "零服吸收率",
            }],
        rows: [],
        options: {
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后毛利未覆盖费用: " + (params[0].value / 10000).toFixed(2) + "万元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>零服吸收率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisTenThousandYuan, yAxisRightUnit('%')],
            data: [],
            legend,
            series: [{
                name: "售后毛利未覆盖费用",
                code: "C0207031",
                type: "bar", barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value / 10000).toFixed(2);
                        },
                    },
                },
            }, {
                name: "零服吸收率",
                code: "B0107002",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1)  +"%";
                        },
                    },
                },
            }],
        },

    },
};

export {storeReportAfterSales};
