<template>
  <section>
    <a-row style="padding-bottom: 15px;" class="card-width " v-if="isCloseA">
      <a-col :span="24">
        <a-anchor :offsetTop="45" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
          <div class="fix_border">
            报告月份：
            <a-month-picker @change="handleReportChange" placeholder="请选择报告月份" v-model="ReportDateMoment"
                            :disabledDate="disabledDate" style=" margin-right: 4px;width:110px" :allowClear="false"
            />
            <span style="padding-left: 36px">区域/销售店选择：</span>
            <a-cascader style="width: 240px" :options="dealers" :allowClear="false"
                        placeholder="请选择/输入销售店" v-model="choseDealer" @change="dealerChange"
                        :show-search="true"
            />
            <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false"><a-icon type="close"/></span>
          </div>
        </a-anchor>
      </a-col>
    </a-row>
    <div class="card-sales">
      <afterSales :after-sale-data="AfterSaleData" :kpi-code-data="allData"/>
    </div>
    <div class="card-width" style="bottom: 30px">
      <a-row :gutter="[24,24]">
        <a-col :span="16">
          <a-card>
            <div class="title">
              <span>数据上传  </span>
              <a class="float-right" @click="downTmp('BDM系统基础数据填报模板.xlsx')" target="_blank">下载填报模板</a>
              <p style="padding-left: 92px;"> 所属销售店：<b style="font-size: 18px;color: #24619e">{{
                  params.DealerName }}</b>
              </p>
            </div>
            <!--去掉开始按钮 直接上传 ：before-upload="beforeUpload"-->

            <a-upload-dragger class="uploadStyle" name="file" :showUploadList="false" :customRequest="handleUpload">
              <p>将文件拖拽至此上传</p>
              <div class="">请上传Excel格式文件，需要支持HTML</div>
            </a-upload-dragger>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card style="min-height: 140px;">
            <div class="title"><span>上传结果</span></div>
            <div style="border: 1px solid #DDDDDD;min-height: 90px;padding: 10px;">
              <div v-for="(item,index) in fileList" :key="index">
                {{ item.name }}
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import afterSales from "@/views/mainComponent/mainComponets/aftersales";
import { disabledDate } from "@/until/dayFormat";
import moment from "moment";
import { ListKpiCode } from "@/views/mainComponent/Code";

export default {
  name: "AfterSaleDashboard",
  components: { afterSales },
  data() {
    return {
      disabledDate,
      isCloseA: true,
      params: {
        ReportDate: "",
        DealerCode: "",
        DealerName: "",
      },
      search: {
        reportDate: moment().add(-1, "M"),
        choseDealer: [],
        upToStandard: null,
      },
      dealers: [],
      dealerMap: {},
      choseDealer: [],
      allData: [],
      AfterSaleData: { // 12个月的售后来厂台次数据
       AfterSaleData1: [],
       AfterSaleData2: [],
      },
      // 文件上传
      fileList: [{
        name: "",
      }],
      isFile: false,
      errorData: [],
    };
  },

  methods: {
    getParams() {
      //js判断是否为空对象
      if (Object.keys(this.$route.query).length > 0) {
        this.params = this.$route.query;
      } else {
        //若没有参数（从菜单点击），则自动获取上个月报告
        this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
      }
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      let dealerCount = 0;
      this.dealers = res.Data.chain().map(p => {
        dealerCount += p.Dealers.length;
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          this.dealerMap[dealer.value] = [part.value, dealer.value];
          return dealer;
        }).value();
        return part;
      }).value();
      this.hasMultipleDealer = dealerCount > 1;
    },
    async dealerChange(value) {
      this.params.DealerCode = value[1];
      await this.getDealerInfo();
    },
    // 获取当前选择的经销商信息
    async getDealerInfo() {
      /* if (this.params.DealerCode === "333") {
         this.params.DealerCode = "";
       }*/
      let res = await this.$Http.getCurrDealerInfo({
        DealerCode: this.params.DealerCode,
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.choseDealer = this.dealerMap[res.Data.DealerCode];
      if (this.choseDealer == null) {
        this.params.DealerCode = this.dealerMap[Object.keys(this.dealerMap)[0]][1];
        if (this.params.DealerCode != null) {
          await this.getDealerInfo();
        }
        return;
      }

      this.dealerInfo = res.Data;
      this.params.DealerCode = res.Data.DealerCode;
      this.params.DealerName = res.Data.DealerName;
      await this.getData();
    },
    async getData() {
      let res = await this.$Http.DealerDashBord({
        ListKpiCode,
        DealerCode: this.params.DealerCode,
        YearMonth: this.params.ReportDate,
      });
      if (res.Data.length < 1) {
        this.$message.error("无数据,请重新选择有数据的月份!");
        //   return false;
      }
      this.allData = res.Data;
      await this.GetMiniChart();
    },
    async handleReportChange(date, dateString) {
      this.params.ReportDate = dateString + "-01";
      await this.getDealerInfo();
    },
    /*售后来厂台次 miniChart*/
    async GetMiniChart() {
      let res = await this.$Http["GetYearKpiCode"]({
        KpiCode: "B0103097", // 售后来厂台次 固定CODE不用修改
        YearMonth: this.params.ReportDate,
        DealerCode: this.params.DealerCode,
      });
      this.AfterSaleData.AfterSaleData1 = res.Data;
      await this.GetMiniChart2();
    },
   /*售后来厂台次 miniChart*/
    async GetMiniChart2() {
      let res = await this.$Http["GetYearKpiCode"]({
        KpiCode: "B0302001", // 售后来厂台次 固定CODE不用修改
        YearMonth: this.params.ReportDate,
        DealerCode: this.params.DealerCode,
      });
      this.AfterSaleData.AfterSaleData2 = res.Data;
    },
    // 文件上传
    async handleUpload(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      let res = await this.$Http.uploadFile(formData);
      if (res.Success) {
        this.$message.success(res.Data);
        setTimeout(() => {
          window.open("ddataList", "_top");
        }, 1000);
      } else {
        if (res.Message === "object") {
          this.$message.success("上传文件有错误，请查看！");
          this.isFile = true;
          this.errorData = res.Data;
          setTimeout(() => {
            this.fileList.push({
              name: file.file.name,
            });
          }, 1000);
        } else {
          this.$message.error(res.Message);
        }
      }
    },
    async downTmp(val) {
      let res = await this.$Http.FileShareLink({
        objectkey: val,
      });
      if (res.Success) {
        window.open(res.Data);
      }
    },
  },
  async created() {
    this.getParams();
    await this.getDealersByRole();
    await this.getDealerInfo();
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import "common";
section {
  margin-bottom: 20px;
  }

.card-width {
  clear: both; width: 99.2%;position: relative;z-index: 1;
  }

.card-sales {
  clear: both;position: relative;bottom: 73px;z-index: 0;
  }

.fix_border {
  padding: 20px 10px 20px 30px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  margin-left: -2px;
  }

.space {
  margin-left: 3%;
  }

::v-deep {
  .ant-anchor-link {
    padding: 10px 20px;
    border: 1px solid #eaeaea;
    }

  .ant-anchor-link-active {
    background: #24619e;

    .ant-anchor-link-title {
      color: #ffffff;
      }
    }

  .ant-anchor-ink-ball {
    left: -10px
    }
  }

/*文件上传*/
.title {
  height: 40px;

  span {
    border-left: 4px solid #24619e;
    display: block;
    line-height: 18px;
    padding-left: 4px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    float: left;
    }

  a {
    display: block;
    float: right;
    background: #24619e;
    padding: 5px 15px;
    color: #ffffff;
    }
  }

.uploadStyle {
  div {
    border: 0 !important;
    }

  p {
    font-size: 24px;
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    }

  div:first-child {
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    }

  }

</style>
