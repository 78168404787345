<template>
  <div class="chart-border">
    <ve-chart
      :init-options="{renderer: 'svg'}"
      :data="chartData"
      :settings="chartData.settings"
      :legend-position="legendPosition"
      :extend="chartExtend"
      :height="chartHeight?chartHeight:'400px'"
      :colors="chartColors"
      :after-set-option="afterSetOption"
      :yAxis="chartyAxis"
    ></ve-chart>
  </div>
</template>

<script>
/*
*  & chartData  数据
*  & chartSettings 设置 类似 type 必须传
* colors 默认颜色
* height 高度默认400PX
* */
export default {
  name: "chartComponent",
  props: {
    chartData: {},
    chartExtend: { series: {} },
    chartHeight: String,
    legendPosition: String,
  },
  data() {
    return {
      chartColors: ["#2b821d", "#1890FF", "#FACC14", "#856aff", "#FF604A", "#8543E0", "#13C2C2", "#3436C7", "#F04864"],
      //所有图乘以100处理  堆叠图
      chartextend2: {
        series: {
          label: {
            show: true,
            formatter: function(params) {
              return Number(Number(Number(params.data) * 100).toFixed(3)).toFixed(1) + "%";
            },
          },
        },
      },

      //所有图保留两位处理
      chartextend3: {
        series: {
          label: {
            show: true,
            formatter: function(params) {
              return params.data.toFixed(2);
            },
          },
        },
      },
      chartyAxis: [],
    };
  },
  methods: {
    afterSetOption(chartObj) {
      if (this.chartData.options != null) {
        chartObj.setOption(this.chartData.options);
      }
    },
    getAxisLabel(val, axisIndex) {
      if (axisIndex == 0) {
        if (this.chartData.settings.yAxisType.length > 0) {
          let formatType = this.chartData.settings.yAxisType[0];
          if (formatType == "0.[00]") {
            return val.toFixed(2);
          } else if (formatType == "0.[0]%") {
            return (val * 100).toFixed(1) + "%";
          } else {
            return val;
          }
        } else {
          return val;
        }
      } else {
        if (this.chartData.settings.yAxisType.length > 1) {
          let formatType = this.chartData.settings.yAxisType[1];
          if (formatType == "0.[00]") {
            return val.toFixed(2);
          } else if (formatType == "0.[0]%") {
            return (val * 100).toFixed(1) + "%";
          } else {
            return val;
          }
        } else {
          return val;
        }
      }
    },
  },
  created() {
    if (this.chartData.settings) {
      if (this.chartData.settings.label === undefined && this.chartData.settings.type === "histogram") {
        // eslint-disable-next-line vue/no-mutating-props
        this.chartData.settings["label"] = {
          show: true,
          position: "inside",
          formatter: function(params) {
            if (params.componentSubType == "line") {
              return (params.data * 100).toFixed(1) + "%";
            } else {
              return params.data.toFixed(2);
            }
          },
        };
      }

      if (this.chartData.settings.type === "histogram") {
        if (this.chartData.settings.yAxisType.length == 1) {
          this.chartyAxis = [
            {
              show: true,
              axisLine: {
                //y轴
                show: true,
              },
              axisLabel: {},
              splitLine: { show: false },
            },
          ];
          this.chartyAxis[0].axisLabel = {
            formatter: (val) => {
              return this.getAxisLabel(val, 0);
            },
          };
        }

        if (this.chartData.settings.yAxisType.length == 2) {
          this.chartyAxis = [
            {
              show: true,
              axisLine: {
                //y轴
                show: true,
              },
              axisLabel: {},
              splitLine: { show: false },
            },
            {
              show: true,
              axisLine: {
                //y轴
                show: true,
              },
              axisLabel: {},
              splitLine: { show: false },
            },
          ];

          this.chartyAxis[0].axisLabel = {
            formatter: (val) => {
              return this.getAxisLabel(val, 0);
            },
          };
          this.chartyAxis[1].axisLabel = {
            formatter: (val) => {
              return this.getAxisLabel(val, 1);
            },
          };
        }
      }
    }
    // eslint-disable-next-line vue/no-mutating-props
    /*  this.chartData.settings["label"] = {
        show: true,
        position: "inside",
        formatter: function (params) {
          if (params.componentSubType == "line") {
            return (params.data * 100).toFixed(2) + "%";
          } else {
            return params.data.toFixed(2);
          }
        },
      };*/

    /* 图表封装示例*/
    /*
    <chartComponpent :chart-height="'500px'"  :chart-data="chartData1" :chart-settings="chartSettings"/>

      this.chartSettings = {
        type:'histogram' // 图表类型
        showLine: ["税前利润率"],
        yAxisType: ["", "0.[00]%"],
        yAxisName: ["元", "%"],
        axisSite: {
          right: ["税前利润率"],
        },
      };
      this.chartData1 = {
        columns: ["类目", "税前利润", "税前利润率"],
        rows: [
          {
            类目: "全国标杆",
            税前利润: "2194035",
            税前利润率: "5.23423",
          }, {
            类目: "区域标杆",
            税前利润: "2194035",
            税前利润率: "5.23423",
          }, {
            类目: "当月值",
            税前利润: "1194035",
            税前利润率: "5.23423",
          }, {
            类目: "上月值",
            税前利润: "2794035",
            税前利润率: "5.23423",
          },
        ],
      },*/
  },
};
</script>

<style lang="less" scoped>

</style>
