<template>
  <section>
    <a-card title="添加权限范围">
      <div class="scopeOfAuthority">
        <span>权限范围名称： <b>*</b></span>
        <a-input
            type="text"
            name="txtPermission"
            v-model.trim="txtPermission"
            placeholder="请输入权限范围名称（必填）"
        />
      </div>
      <div class="title"><span>1</span> 请先选择管辖范围</div>
      <a-tabs :activeKey="tabKey" @change="area($event)">
        <a-tab-pane key="1" tab="按片区选择">
          <div class="">
            <a-checkbox
                :checked="m1checkAll"
                name="m1checkAll"
                @change="M1CheckAllChange"
            >
              全部
            </a-checkbox>
            <a-checkbox-group
                v-model="m1defaultChecked"
                :options="m1Data"
                @change="M1onChange"
            />
          </div>
          <div v-show="m2ShowcheckAll" class="ppd">
            <a-checkbox
                :checked="m2checkAll"
                name="'m2checkAll'"
                @change="M2CheckAllChange"
            >
              全部
            </a-checkbox>
            <a-checkbox-group
                v-model="m2defaultChecked"
                :options="m2Data"
                @change="M2onChange"
            />
          </div>
          <div v-show="m3ShowcheckAll" class="ppd">
            <a-checkbox
                :checked="m3checkAll"
                name="'m3checkAll'"
                @change="M3CheckAllChange"
            >
              全部
            </a-checkbox>
            <a-checkbox-group
                v-model="m3defaultChecked"
                :options="m3Data"
                @change="M3onChange"
            />
          </div>
        </a-tab-pane>
        <!--按经销商选择-->
        <a-tab-pane class="dealer" key="2" tab="按经销商选择" @change="dealer">
          <span>大区：</span>
          <a-select
              :v-model="m1DealerData"
              label-in-value
              :defaultValue="m1DealerData[0]"
              placeholder="请选择大区"
              style="width: 120px"
              @change="m1handleChange"
          >
            <a-select-option
                :value="item.value"
                v-for="(item, index) in m1DealerData"
                :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <span>小区：</span>
          <a-select
              :v-model="m2DealerData"
              label-in-value
              placeholder="请选择小区"
              style="width: 120px"
              :defaultValue="m2DealerData[0]"
              @change="m2handleChange"
          >
            <a-select-option
                :value="item.value"
                v-for="(item, index) in m2DealerData"
                :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <span>城市：</span>
          <a-select
              :v-model="m3DealerData"
              label-in-value
              placeholder="请选择城市"
              style="width: 120px"
              :defaultValue="m3DealerData[0]"
              @change="m3handleChange"
          >
            <a-select-option
                :value="item.value"
                v-for="(item, index) in m3DealerData"
                :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input
              class="input"
              :v-model="keyCodes"
              placeholder="输入名称直接搜索"
          />
          <a-button type="primary" @click="ExpandAll(true)">搜索</a-button>
          <a-button type="primary" @click="ExpandAll(false)">展开全部</a-button>
          <div style="padding-top: 20px">
            <a-table
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                :row-key="(row, index) => index"
                :columns="dealerColumnsData"
                :data-source="dealertableData"
            >
              <a slot="name" slot-scope="text">{{ text }}</a>
            </a-table>
          </div>
        </a-tab-pane>
        <!--按经销商选择-->
      </a-tabs>
    </a-card>
    <a-card class="Acard">
      <div class="title"><span>2</span> 选择部门和岗位</div>
      <div class="ppd">
        <h2>选择部门</h2>
        <a-checkbox
            :checked="m4checkAll"
            name="'m4checkAll'"
            @change="M4CheckAllChange"
        >
          全部
        </a-checkbox>
        <a-checkbox-group
            v-model="m4defaultChecked"
            :options="m4Data"
            @change="M4onChange"
        />
      </div>
      <div class="ppd">
        <h2>选择岗位</h2>
        <a-checkbox
            :checked="m5checkAll"
            name="'m5checkAll'"
            @change="M5CheckAllChange"
        >
          全部
        </a-checkbox>
        <a-checkbox-group
            v-model="m5defaultChecked"
            :options="m5Data"
            @change="M5onChange"
        />
      </div>
    </a-card>
    <a-card>
      <a-button type="primary" @click="saveForm">保存</a-button>
      <a-button type="" @click="$router.push('/Permissions')">返回</a-button>
    </a-card>
  </section>
</template>
<script>

export default {
  data () {
    return {
      keyCodes: "",
      txtPermission: "",
      m1Data: [],
      m2Data: [],
      m3Data: [],
      m4Data: [],
      m5Data: [],
      m1DealerData: [],
      m2DealerData: [],
      m3DealerData: [],
      m1defaultChecked: [],
      m2defaultChecked: [],
      m3defaultChecked: [],
      m4defaultChecked: [],
      m5defaultChecked: [],
      companyformData: [], // 经销商
      companyformData1: [], // 经销商不选择的数据
      m1checkAll: true,
      m2checkAll: true,
      m3checkAll: true,
      m4checkAll: true,
      m5checkAll: true,
      m2ShowcheckAll: false,
      m3ShowcheckAll: false,
      m1formData: [],
      m2formData: [],
      m3formData: [],
      m4formData: [],
      m5formData: [],
      dealertableData: [],
      tabKey: "",
      selectedRowKeys: [], // 表格默认选中值
      dealerColumnsData: [
        {
          title: "经销商名称",
          dataIndex: "CompanyNameCN",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "经销商代码",
          dataIndex: "CompanyCode",
        },
        {
          title: "经销商类型",
          dataIndex: "CompanyType",
        },
        {
          title: "大区名称",
          dataIndex: "PartName",
        },
        {
          title: "小区名称",
          dataIndex: "AreaName",
        },
        {
          title: "城市",
          dataIndex: "CityName",
        },
        {
          title: "地址",
          dataIndex: "Address",
        },
      ],
    };
  },
  created () {
    this.getRegionList();
    this.department();
    this.jobList();

    if (this.$route.query.edit) {
      /*是编辑在执行*/
      setTimeout(() => {
        this.getEditInfo();
      }, 300);
    }
  },
  computed: {
    rowSelection () {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          selectedRows.forEach((item) => {
            this.companyformData.push(item.CompanyCode.toString());
          });
        },
        /*getCheckboxProps: (record) => ({
           props: {
             //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
             //   defaultChecked: record.CompanyCode === this.companyformData[0],
             // disabled: record["SimpleCode"] == "C000011",
           },
         }),*/
      };
    },
  },
  methods: {
    /*默认选中时，让其他复选也第一次*/
    onSelectChange (selectedRowKeys, selectedRows) {
      this.companyformData = [];
      //  console.log('selectedRowKeys changed: ', selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      selectedRows.forEach((item) => {
        this.companyformData.push(item.CompanyCode.toString());
      });

      // console.log(this.companyformData);
    },
    /*获得编辑权限所胡数据*/
    async getEditInfo () {
      let self = this;
      let res = await self.$Http.GetEditPermissionInfo({
        PermissionCode: self.$route.query.PermissionCode,
      });

      let data = res.Data[0];
      Object.keys(data).filter(key => key.length <= 3 && key.startsWith("M")).forEach(key => {
        let value = data[key];
        if (typeof (value) === "string") {
          data[key] = value.split(",");
        }
      });

      this.txtPermission = res.Data[0].PermissionName;
      this.m1defaultChecked = res.Data[0].M1;

      if (this.m1defaultChecked[0] !== "MATCH_ALL") {
        this.m1checkAll = false;
        this.m2ShowcheckAll = true;
        this.GetAreaData(this.m1defaultChecked);
      }
      this.m2defaultChecked = res.Data[0].M2;
      if (this.m2defaultChecked[0] !== "MATCH_ALL") {
        this.m2checkAll = false;
        this.m3ShowcheckAll = true;
        this.GetCityByAreaData(this.m2defaultChecked);
      }
      this.m3defaultChecked = res.Data[0].M3;
      this.m4defaultChecked = res.Data[0].M8;
      if (this.m4defaultChecked[0] !== "MATCH_ALL") {
        this.m4checkAll = false;
      }
      this.m5defaultChecked = res.Data[0].M5;
      if (this.m5defaultChecked[0] !== "MATCH_ALL") {
        this.m5checkAll = false;
      }
      /*判断是否选中经销商*/
      self.companyformData = res.Data[0].M4;
      self.companyformData1 = res.Data[0].M4;

      if (self.companyformData !== null) {
        if (self.companyformData[0] !== "MATCH_ALL" ) {
          self.tabKey = "2";
          self.ExpandAll(false);
          setTimeout(() => {
            //  console.log(self.companyformData);
            let companyformDataList = self.companyformData;
            companyformDataList.forEach((e) => {
              let tmpListIndex = self._.where(self.dealertableData, {
                CompanyCode: e,
              });

              tmpListIndex.forEach((element) => {
                self.selectedRowKeys.push(
                    self._.indexOf(self.dealertableData, element),
                );
              });

              this.companyformData = this.selectedRowKeys;
              // console.log(this.companyformData);
            });
          }, 500);
        }
      }
    },
//获取 大区 列表
    async getRegionList () {

      let self = this;
      self.tabKey = "1";
      let page = {
        pageSize: 10,
        pageIndex: 1,
      };
      /* 大区获取*/
      let res = await self.$Http.GetPartListByPage(page);
      self.m1formData = [];

      res.Data.forEach((d) => {
        self.m1Data.push({
          label: d.PartName,
          value: d.SimpleCode,
        });
      });

      let rcs = await self.$Http.GetPart_All();
      //  self.m1DealerData=[]
      rcs.Data.forEach((d) => {
        self.m1DealerData.push({
          label: d.ItemName,
          value: d.SimpleCode,
        });
      });

      let rfs = await this.$Http.GetAreaByPart_All({
        partCode: self.m1DealerData[0].value,
      });
      if (rfs.Data.length > 0) {
        rfs.Data.forEach((e) => {
          this.m2DealerData.push({
            label: e.ItemName,
            value: e.SimpleCode,
          });
        });
        //   console.log(this.m2Data)
      }

      let rgs = await this.$Http.GetAreaByPart_All({
        partCode: self.m2DealerData[0].value,
      });
      if (rgs.Data.length > 0) {
        rgs.Data.forEach((e) => {
          this.m3DealerData.push({
            label: e.ItemName,
            value: e.SimpleCode,
          });
        });
        //   console.log(this.m2Data)
      }
    }
    ,
// 获取部门列表
    async department () {
      let res = await this.$Http.GetDeparmentNo();
      if (res.Data.length > 0) {
        res.Data = res.Data.slice(1);

        res.Data.forEach((d) => {
          this.m4Data.push({
            label: d.ItemName,
            value: d.SimpleCode,
          });
        });
      }
    }
    ,
// 获取岗位列表
    async jobList () {
      let res = await this.$Http.GetPositionNo();
      if (res.Data.length > 0) {
        res.Data.forEach((d) => {
          res.Data = res.Data.slice(1);

          this.m5Data.push({
            label: d.ItemName,
            value: d.SimpleCode,
          });
        });
      }
    }
    ,
    async M1onChange (checkedValues) {
      this.m1checkAll = checkedValues.length === 0;
      this.m2ShowcheckAll = !checkedValues.length === 0;
      this.m3ShowcheckAll = !checkedValues.length === 0;
      this.m1formData = [];
      this.m1formData = checkedValues;

      this.m2Data = [];
      let partCodeData = [];
      this.m1Data
          .filter((f) => checkedValues.indexOf(f.value) !== -1)
          .forEach((item) => {
            //  this.m2Data = []
            this.m2ShowcheckAll = true; // 显示第二级
            this.m2checkAll = !checkedValues.length === 0; // 第二级全部按钮
            this.m3checkAll = !checkedValues.length === 0; // 第二级全部按钮
            //   this.$message.success(item.value)
            partCodeData.push(item.value);
          });
      /*
       * 根据大区 partCode 获得 小区列表
       * */
      this.GetAreaData(partCodeData.toString());
    }
    ,
    /*
     * 根据大区 partCode 获得 小区列表 .join(",")
     * */
    async GetAreaData (partCodeData) {
      let res = await this.$Http.GetAreaByPart_All({ partCode: partCodeData.toString() });
      if (res.Data.length > 0) {
        res.Data = res.Data.slice(1);
        res.Data.forEach((d) => {
          this.m2Data.push({
            label: d.ItemName,
            value: d.SimpleCode,
          });
        });
      }
    }
    ,
    M1CheckAllChange (e) {
      Object.assign(this, {
        m1defaultChecked: [],
        m1checkAll: e.target.checked,
      });
      this.m2checkAll = true;
      this.m2ShowcheckAll = false;
      this.m3checkAll = true;
      this.m3ShowcheckAll = false;
    }
    ,
    async M2onChange (checkedValues) {
      this.m2checkAll = checkedValues.length === 0;
      this.m3ShowcheckAll = !checkedValues.length === 0;
      this.m2formData = [];
      this.m2formData = checkedValues;

      this.m3Data = [];
      let partCodeData = [];
      this.m2Data
          .filter((f) => checkedValues.indexOf(f.value) !== -1)
          .forEach((item) => {
            //  this.m2Data = []
            this.m3checkAll = !checkedValues.length === 0; // 第三级全部按钮
            this.m3ShowcheckAll = true; // 显示第二级
            //   this.$message.success(item.value)
            partCodeData.push(item.value);
          });

      /*
       * 根据小区 areaCode 获得 城市列表
       * */
      this.GetCityByAreaData(partCodeData.toString());
    }
    ,
    async GetCityByAreaData (partCodeDataValue) {
      let res = await this.$Http.GetCityByArea_All({
        areaCode: partCodeDataValue.toString(),
      });
      if (res.Data.length > 0) {
        res.Data = res.Data.slice(1);

        res.Data.forEach((d) => {
          this.m3Data.push({
            label: d.ItemName,
            value: d.SimpleCode,
          });
        });
        //   console.log(this.m2Data)
      }
    }
    ,
    async M3onChange (checkedValues) {
      this.m3checkAll = checkedValues.length === 0;
      this.m3formData = [];
      this.m3formData = checkedValues;
    }
    ,
    M2CheckAllChange (e) {
      Object.assign(this, {
        m2defaultChecked: [],
        m2checkAll: e.target.checked,
      });

      this.m3checkAll = true;
      this.m3ShowcheckAll = false;
      //  this.m2checkAll = false
    }
    ,
    M3CheckAllChange (e) {
      Object.assign(this, {
        m3defaultChecked: [],
        m3checkAll: e.target.checked,
      });
      //  this.m2checkAll = false
    }
    ,
    M4CheckAllChange (e) {
      Object.assign(this, {
        m4defaultChecked: [],
        m4checkAll: e.target.checked,
      });
      //  this.m2checkAll = false
    }
    ,
    M5CheckAllChange (e) {
      Object.assign(this, {
        m5defaultChecked: [],
        m5checkAll: e.target.checked,
      });
      //  this.m2checkAll = false
    }
    ,
    M4onChange (checkedValues) {
      this.m4checkAll = checkedValues.length === 0;
      this.m4formData = [];
      this.m4formData = checkedValues;
    }
    ,
    M5onChange (checkedValues) {
      this.m5checkAll = checkedValues.length === 0;
      this.m5formData = [];
      this.m5formData = checkedValues;
    }
    ,
    /*经销商大区选择*/
    async m1handleChange (value) {
      // console.log(value)
      this.m2DealerData = [];
      this.m1formData = value.key;
      let partCodeData = value.key;
      let res = await this.$Http.GetAreaByPart_All({
        partCode: partCodeData.toString(),
      });
      if (res.Data.length > 0) {
        //   res.Data = res.Data.slice(1)
        res.Data.forEach((d) => {
          this.m2DealerData.push({
            label: d.ItemName,
            value: d.SimpleCode,
          });
        });
      }
    }
    ,
    /*经销商小区选择*/
    async m2handleChange (value) {
      // console.log(value)
      this.m3DealerData = [];
      this.m2formData = value.key;
      let partCodeData = value.key;

      let res = await this.$Http.GetCityByArea_All({
        areaCode: partCodeData.toString(),
      });
      if (res.Data.length > 0) {
        //   res.Data = res.Data.slice(1)
        res.Data.forEach((d) => {
          this.m3DealerData.push({
            label: d.ItemName,
            value: d.SimpleCode,
          });
        });
      }
    }
    ,
    /*经销商城市选择*/
    async m3handleChange (value) {
      this.m3formData = value.key;
      //    console.log(value)
      //  this.m3Data = []
    }
    ,
    async ExpandAll (value) {
      this.dealertableData = [];
      let formData = {
        pageSize: 10,
        PageIndex: 1,
        partCode: this.m1formData,
        areaCode: this.m2formData,
        cityCode: this.m3formData,
        keyCodes: this.keyCodes,
      };
      formData = !value
          ? (formData = {
            pageSize: 10,
            PageIndex: 1,
          })
          : formData;
      /*  dealertableData: [{
          CompanyId: '1',
          Address: '地址',
          AreaName: '皖南',
          CityName: '安庆',
          CompanyNameCN: 'whrh',
          CompanyType: '销售店',
          CompanyCode: 'New York No. 1 Lake Park',
          PartName: '华东区',
        }]*/
      let res = await this.$Http.GetCompanyListByPage(formData);
      if (res.Data.length > 0) {
        res.Data.forEach((item) => {
          this.dealertableData.push({
            CompanyId: item.CompanyId,
            Address: item.Address,
            AreaName: item.AreaName,
            CityName: item.CityName,
            CompanyNameCN: item.CompanyNameCN,
            CompanyType: item.CompanyType,
            CompanyCode: item.CompanyCode,
            PartName: item.PartName,
            SimpleCode: item.SimpleCode,
          });
        });
      }
    }
    ,
    area (e) {
      this.tabKey = e;
    }
    ,
    /*经销商选择*/
    dealer () {
    }
    ,
    async saveForm () {
      let that = this;

      if (that.m1formData === [] || that.m1formData.length === 0) {
        //  console.log(this.All)
        that.m1formData = ["MATCH_ALL"];
      }
      if (that.m2formData.length === 0) {
        //  console.log(this.All)
        that.m2formData = ["MATCH_ALL"];
      }
      if (that.m3formData.length === 0) {
        //  console.log(this.All)
        that.m3formData = ["MATCH_ALL"];
      }
      if (that.m4formData.length === 0) {
        //  console.log(this.All)
        that.m4formData = ["MATCH_ALL"];
      }
      if (that.m5formData.length === 0) {
        //  console.log(this.All)
        that.m5formData = ["MATCH_ALL"];
      }
      if (that.companyformData.length === 0) {
        //  console.log(this.All)
        that.companyformData = ["MATCH_ALL"];
      }

      if (that.companyformData.indexOf(0) > 0) {
        that.companyformData = that.companyformData1;
      }

      /*   console.log(that.txtPermission); // 名称
         console.log(that.m1formData);
         console.log(that.m2formData);
         console.log(that.m3formData);
         console.log(that.companyformData);
         console.log(that.m4formData);
         console.log(that.m5formData);
    */
      if (this.txtPermission === "") {
        this.$message.error("权限名称，忘填写啦！");
        return;
      }

      let formData = {
        PermissionId: that.$route.query.PermissionId,
        permissionName: that.txtPermission,
        partCode: that.m1formData.toString(),
        areaCode: that.m2formData.toString(),
        cityCode: that.m3formData.toString(),
        companyCode: that.companyformData.toString(),
        positionCode: that.m4formData.toString(),
        departmentCode: that.m5formData.toString(),
      };

      // console.log(formData);

      if (that.$route.query.edit) {
        let rcs = await that.$Http.EditPermission(formData);
        if (rcs.Success) {
          that.$message.success("编辑成功，即将返回列表页面");
        } else {
          that.$message.error("编辑失败，请重新编辑，或者联系管理员!");
        }

      } else {
        let res = await that.$Http.AddPermission(formData);

        if (res.Success) {
          that.$message.success("添加成功，即将返回列表页面");
        } else {
          that.$message.error("添加失败，请重新添加，或者联系管理员!");
        }
      }
      setTimeout(() => that.$router.push("/Permissions"), 1000);
    }
    ,
  },
  filters: {}
  ,
}
;
</script>
<style lang="less" scoped>
.scopeOfAuthority {
  span {
    display: block;
    float: left;
    line-height: 32px;
    width: 117px;

    b {
      color: @primary-color;
    }
  }

  input {
    float: left;
    width: 240px;
  }
}

.title {
  line-height: 60px;
  clear: both;

  span {
    background: #d7002e;
    padding: 1px 2px;
    font-size: 16px;
    color: #fff;
    border-radius: 25%;
    margin-right: 8px;
  }
}

.ppd {
  padding-top: 10px;
}

.Acard {
  h2 {
    font-size: 18px;
    text-indent: 1em;
  }
}

.dealer {
  span {
    padding: 0 15px;
  }

  .input {
    width: 300px;
    margin-left: 20px;
  }
}

.ant-select-selection-selected-value {
  line-height: 40px;
}
</style>
