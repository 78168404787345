
const UPLOAD_API = {
  // 登陆
  uploadFile: {
    method: 'post',
    url: '/DealerKpi/KpiData/ExcelKpiUpload'
  },
  // 查询当前登录人的店
  GetThisCompany: {
    method: 'get',
    url: '/DealerKpi/KpiData/GetThisCompany'
  },

  // excel下载 、模板下载
  FileShareLink: {
    method: 'get',
    url: '/DealerKpi/KpiData/FileShareLink'
  },
  // excel下载 、填报模板下载新接口
  GetDataWriteExcel: {
    method: 'get',
    url: '/DealerKpi/KpiData/GetDataWriteExcel'
  },

}
export default UPLOAD_API
