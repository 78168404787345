const Data_List_API = {
    // 店端上传数据展示列表:
    GetExcelUpListByPage: {
        method: "get",
        url: "/DealerKpi/KpiData/GetKpiDataListByPage",
    },
    // 厂端上传数据展示列表:
    GetFactoryKpiDataListByPage: {
        method: "get",
        url: "/DealerKpi/KpiData/GetFactoryKpiDataListByPage",
    },
    //厂端校核显示店校核数量
    GetDealerUploadInfo: {
        method: "get",
        url: "/DealerKpi/KpiData/GetDealerUploadInfo",
    },
    // 获取填报通过率:
    GetPassPercent: {
        method: "get",
        url: "/DealerKpi/KpiData/GetPassPercent",
    },
    // 厂端校核列表下载EXCEL
    DownDealerUploadInfo: {
        method: "get",
        url: "/DealerKpi/KpiData/DownDealerUploadInfo",
    },
    // 2021-01-09
    // 查看错误指标:
    GetErrorLogs: {
        method: "get",
        url: "/DealerKpi/KpiData/GetErrorLogs",
    },
    // 校核:
    ValidateKpi: {
        method: "post",
        url: "/DealerKpi/KpiData/ValidateKpi",
    },
    // 点击特殊校核带出错误信息
    GetErrorKpiDetail: {
        method: "get",
        url: "/DealerKpi/KpiData/GetErrorKpiDetail",
    },
    // 特殊校核
    SpecialValidateKpi: {
        method: "post",
        url: "/DealerKpi/KpiData/SpecialValidateKpi",

    },
    // 特殊校核 选择忽略原因
    GetSpecialCheckList: {
        method: "post",
        url: "/DealerKpi/KpiData/GetSpecialCheckList",

    },
    // 获取自己权限角色名称
    GetOwnerPermissionName: {
        method: "get",
        url: "/DealerKpi/KpiData/GetOwnerPermissionName",
    },
    // 厂端 填报销量统计
    GetAllPassPercentListPage: {
        method: "get",
        url: "/DealerKpi/KpiData/GetAllPassPercentListPage",
    },

    // 查看特殊校核原因
    GetSpecialMemo: {
        method: "get",
        url: "/DealerKpi/KpiData/GetSpecialMemo",
    },
    //数据解析视频
    GetVedioKpi: {
        method: "get",
        url: "/DealerKpi/SecondBDM/GetVedioKpi",
    },
    //审核操作（店端经理 & 厂端）
    AuditkPI: {
        method: "get",
        url: "/DealerKpi/KpiData/AuditkPI",
    },
    // 厂端填报管理 - 查询驳回原因
    GetRejectDetail: {
        method: "post",
        url: "/DealerKpi/KpiData/GetRejectDetail",
    },


};
export default Data_List_API;


// 填报效率统计reportEfficiencyStatistics
