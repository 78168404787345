<template>
  <div class="main" style="margin-top: 35px;clear: both">
    <h2 style="grid-area: title1">整体概览 (店均)</h2>
    <div style="grid-area: a1" class="card_border">
      <img src="../../../assets/T3.png" alt="">
      <span class="card-title1">总收入</span>
      <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("C0406004")) }}万元</p>
    </div>
    <div style="grid-area: a2" class="card_border">
      <a-tooltip placement="topLeft" title="含返利" arrow-point-at-center>
        <img src="../../../assets/T4.png" alt="">
        <span class="card-title1">总成本 <a-icon type="question-circle-o"/></span>
        <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("C0406012")) }}万元</p>
      </a-tooltip>
    </div>
    <div style="grid-area: a3" class="card_border">
      <img src="../../../assets/T5.png" alt="">
      <span class="card-title1">总毛利</span>
      <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("C0407001")) }}万元</p>
    </div>
    <div style="grid-area: a4" class="card_border">
      <img src="../../../assets/T6.png" alt="">
      <span class="card-title1">总费用</span>
      <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("C0206001")) }}万元</p>
    </div>
    <div style="grid-area: a5" class="one-con card_border">
      <a-tooltip placement="topLeft" title="含主营利润以及其他利润等" arrow-point-at-center>
        <img src="../../../assets/T1.png" alt="">
        <span class="card-title1">税前利润 <a-icon type="question-circle-o"/></span>
        <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("C0408004")) }}万元</p>
      </a-tooltip>
    </div>
    <div class="card_border" style="grid-area: a6;">
      <img src="../../../assets/T2.png" alt="">
      <span class="card-title1">资产回报率</span>
      <p class="title-num2">{{ percentage(GetKpiCodeData("C0408020")) }}</p>
    </div>
    <div style="grid-area: b1" class="card_border">
      <span class="card-title2">总收入</span>
      <div>
        <PieChart :pie-data="pieData.pieData0" :isNeedDeal="false"/>
      </div>
    </div>
    <div style="grid-area: b2" class="card_border">
      <span class="card-title2">总毛利</span>
      <div>
        <PieChart :pie-data="pieData.pieData1" :isNeedDeal="false"/>
      </div>
    </div>
    <div style="grid-area: b3" class="card_border">
      <span class="card-title2">总费用</span>
      <div>
        <PieChart :pie-data="pieData.pieData2" :isNeedDeal="false"/>
      </div>
    </div>
    <div style="grid-area: c1" class="card_border">
      <span class="card-title2">盈亏平衡点经营模型</span>
      <a-row class="card-content">
        <a-col span="14">
          <img src="../../../assets/monthReportChart.png" style="padding-top: 20px; width: 82%;padding-left: 10%;max-height: 300px" alt="盈亏平衡点经营模型">
        </a-col>
        <a-col span="10">
          <p>盈亏保本台次 <span>{{ ThousandYuan(GetKpiCodeData("C0208001")) }}台</span></p>
          <p>新车总销量 <span>{{ ThousandYuan(GetKpiCodeData("A0302001")) }}台</span></p>
          <p>盈亏平衡点收入 <span>{{ tenThousandYuan(GetKpiCodeData("C0409001")) }}万元</span></p>
          <p>当月销售总收入 <span>{{ tenThousandYuan(GetKpiCodeData("A0104090")) }}万元</span></p>
          <p>盈利平衡经营天数 <span>{{ ThousandYuan(GetKpiCodeData("C0409002")) }}天</span></p>
          <div>
            <p>* 盈亏平衡点销量、天数出现负数，为以下两种情况导致:</p>
            <p>1、该店售后业务毛利已经覆盖总费用； </p>
            <p>2、该店销售业务毛利无法覆盖销售变动费用，不具参考意义。</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="grid-area: c2" class="card_border">
      <div>
        <GaugeChart chart-height="420" :max-value="sysValue(GetKpiCodeData('B0107002'))" :chart-value="PercentageCode(GetKpiCodeData('B0107002'))"/>
      </div>
      <div style="position: relative;bottom: 115px;text-align: center;font-size: 14px">零服吸收率</div>
    </div>
    <div style="grid-area:d2" class="card_border">
      <Rate-component :rate-date="rateDate[0]"/>
    </div>
    <div style="grid-area:e2" class="card_border">
      <Rate-component :rate-date="rateDate[1]"/>
    </div>
    <div style="grid-area:f2" class="card_border">
      <div style="display: flex;justify-content: space-between;">
        <div>
          <span>{{ rateDate[2].title }}</span>
          <div class="title-num2">{{ percentage(rateDate[2].cont) }}</div>
        </div>
        <div>
          <a-progress type="circle" :width="70" strokeColor="#FF786A" :percent="Number(PercentageCode(rateDate[2].cont))" :format="percent=> GetTF(percent)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainChart from "./componets/mainChart";
import RateComponent from "./componets/rateComponent";
import GaugeChart from "./componets/gaugeChart";
import PieChart from "./componets/pieChart";
import { tenThousandYuan, ThousandYuan, percentage } from "@/until/optionPrint";
import { GetGauge, PercentageCode, GetThreeGauge } from "@/views/mainComponent/Code";

export default {
  name: "overall",
  components: { PieChart, RateComponent, MainChart, GaugeChart },
  props: {
    kpiCodeData: {
      type: Array,
    },
    salesRatioData: {
      type: Array,
    },
  },
  data() {
    return {
      tenThousandYuan, ThousandYuan, percentage, GetGauge, PercentageCode, GetThreeGauge,
      pieData: {
        pieData0: [
          { value: 0, name: "售后维修收入", kpiCode: "B0103097" },
          { value: 0, name: "水平事业收入", kpiCode: "C0404001" },
          { value: 0, name: "新车销售收入", kpiCode: "A0103001" },
        ],
        pieData1: [
          { value: 0, name: "售后维修毛利", kpiCode: "B0104282" },
          { value: 0, name: "水平事业毛利", kpiCode: "C0405004" },
          { value: 0, name: "新车销售毛利", kpiCode: "A0106001" },
        ],
        pieData2: [
          { value: 0, name: "变动费用", kpiCode: "C0205005" },
          { value: 0, name: "人力费用", kpiCode: "C0203020" },
          { value: 0, name: "半固定费用", kpiCode: "C0205010" },
          { value: 0, name: "固定费用", kpiCode: "C0205006" },
          { value: 0, name: "财务费用", kpiCode: "C0205021" },
        ],
      },
      rateDate: [
        {
          Id: "d2", title: "流动资产周转率", cont: "%", percent: "0",
          kpiCode: "C0106006",
        }, {
          Id: "e2", title: "速动比率", cont: "%", percent: "0",
          kpiCode: "C0107002",
        }, {
          Id: "f2", title: "资产负债率", cont: "%", percent: "0",
          kpiCode: "C0107003",
        }, {
          Id: "g2", title: "总资产周转率", cont: "%", percent: "0",
          kpiCode: "C0107006",
        },
      ],
    };
  },
  created() {
    this.GetChartData();
  },
  watch: {
    kpiCodeData: {
      handler: function() {
        this.GetChartData();
      },
      deep: true,
    },
    salesRatioData: {
      handler: function() {
        this.GetChartData();
      },
      deep: true,
    },
  },
  methods: {
    GetTF(data){
      if (data>80){
        return "×"
      }else{
        return data + '%'
      }
    },
    GetKpiCodeData(KpiCode) {
      if(this.kpiCodeData) {
        if(this.kpiCodeData.length < 1) return null;
        let data = this.kpiCodeData.filter(d => d.KpiCode === KpiCode);
        if(data.length > 0) {
          return data[0].NumValue;
        } else {
          return null;
        }
      }
    },
    GetChartData() {
      this._.values(this.pieData).forEach((d) => {
            d.forEach(v => v.value = this.tenThousandYuan(this.GetKpiCodeData(v.kpiCode)));
          },
      );
      this.rateDate.forEach(d => {
        if(d.title === "资产负债率") {
          d.cont = this.GetThreeGauge(this.GetKpiCodeData(d.kpiCode));
          d.percent = this.GetThreeGauge(this.GetKpiCodeData(d.kpiCode));
        } else {
          d.cont = this.GetGauge(this.GetKpiCodeData(d.kpiCode));
          d.percent = this.GetGauge(this.GetKpiCodeData(d.kpiCode));
        }
      });
    },
    sysValue(val) {
      val = (val * 100).toFixed(0);
      return ((Math.floor(val / 100) + 1) * 100).toString();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../common";

.main {
  display: grid;
  grid-template-columns:repeat(6, 15.8%);
  grid-template-rows:25px 110px 380px 360px 100px;
  grid-column-gap: 13px;
  grid-row-gap: 15px;
  grid-template-areas:   'title1 title1 title1 title1 title1 title1'
                           'a1 a2 a3 a4 a5 a6'
                           'b1 b1 b2 b2 b3 b3'
                           'c1 c1 c1 c1 c2 c2'
                            'e2 e2 f2 f2 d2 d2';
  }
</style>
