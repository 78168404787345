<template>
  <section>
    <a-card class="card">
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        查询类型：
        <a-select style="width:60px" v-model="type" default-value="年" @change="handleChange">
          <a-select-option value="年">
            年
          </a-select-option>
          <a-select-option value="月">
            月
          </a-select-option>
        </a-select>
        <div style="margin-left: 10px">
          日期：
        </div>
        <a-date-picker v-if="type==='月'" v-model="form.timeTag" mode="month" format="YYYY-MM"
            @panelChange="panelChange" style="width: 110px;"
            :allowClear="false"
        />
        <!--                            <a-input placeholder="城市编号" v-model="form.cityCode"/>-->
        <a-date-picker v-else mode="year" v-model="form.timeTag" format="YYYY"
            @panelChange="panelChange" style="width: 110px;"
            :allowClear="false"
        />
        <div style="margin-left: 10px">
          销售店：
        </div>
        <el-cascader
            :options="dealers" style="width: 330px;"
            :props="choseDealer" placeholder="请选择销售店"
            collapse-tags @change="dealerChange"
            clearable filterable
        ></el-cascader>
        <div style="margin-left: 10px">
          状态：
        </div>
        <a-select v-model="form.status" style="width: 120px" :allowClear="true" defaultValue="全部">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="未填报">
            未填报
          </a-select-option>
          <a-select-option value="已提交">
            已提交
          </a-select-option>
          <a-select-option value="已保存未提交">
            已保存未提交
          </a-select-option>
        </a-select>
        <div style="margin-left: 10px">
        </div>
        <a-button type="primary" @click="getStatistics()"> 查询</a-button>
        <a-button type="primary" @click="downLoad()"> 下载</a-button>
        <a-button type="primary" @click="doReset()">重置单店填写状态</a-button>
        <a-button type="primary" @click="doExport()">导出盈利分析填报明细</a-button>
      </div>
    </a-card>
    <a-card class="card" style="margin-top: 18px;">
            <span style="margin-right: 20px" v-for="(item,index) in statisticsData" :key="index">
                {{ item.Status }}
                <span style="color: #24619e">{{ item["Count"] }}</span>
            </span>
    </a-card>
    <a-card class="card" style="margin-top: 18px;">
      <a-table
          rowKey="CityId"
          :columns="columns"
          :data-source="tableData"
          :pagination="paginationParam"
          @change="tableChange"
      >
      </a-table>
    </a-card>
    <div class="foot-pad30"></div>
    <a-modal
        v-model="visible2"
        title="重置填写状态"
        @ok="handleOk2"
        @cancel="handleCancel2"
    >
      <a-form-model
          ref="modelForm2"
          :model="modelForm2"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 10 }"
      >

        <a-form-model-item label="标识类型">
          <a-select v-model="type" default-value="年" @change="modelTypeChange">
            <a-select-option value="年">
              年
            </a-select-option>
            <a-select-option value="月">
              月
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="时间标识" prop="timeTag">
          <a-date-picker
              v-model="modelForm2.timeTag"
              @panelChange="panelChange3"
              :mode="selectModel"
              :format="formatter"
              :allowClear="false"
          />

        </a-form-model-item>
        <a-form-model-item label="销售店" prop="chooseDealer">
          <a-cascader style="width: 300px;margin-right: 20px" :options="dealers" :allowClear="false"
              change-on-select
              placeholder="请选择/输入销售店" @change="dealerChange2" v-model="modelForm2.chooseDealer"
              :show-search="true"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </section>
</template>

<script>
import moment from "moment";
import axios from "axios";

const columns = [
  {
    title: "序号",
    width: "60px",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "区域名称",
    dataIndex: "区域名称",
    key: "区域名称",
  },
  {
    title: "销售店名称",
    dataIndex: "销售店名称",
    key: "销售店名称",
  },
  {
    title: "销售店代码",
    dataIndex: "销售店代码",
    key: "销售店代码",
  },
  {
    title: "销售店状态",
    dataIndex: "销售店状态",
    key: "销售店状态",
  },
  {
    title: "最后更新人",
    dataIndex: "最后更新人",
    key: "最后更新人",
  },
  {
    title: "最后更新时间",
    dataIndex: "最后更新时间",
    key: "最后更新时间",
  },
  {
    title: "状态",
    dataIndex: "状态",
    key: "状态",
  },
];

export default {
  data() {
    return {
      type: "年",
      form: {
        timeTag: "",
        status: "",
        dealerCode: "",
      },
      selectModel: "year",
      formatter: "yyyy",
      //表格数据
      tableData: [],
      statisticsData: [],
      columns,
      dealers: [],
      choseDealer: { multiple: true, expandTrigger: "hover" },
      dealerMap: {},
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      modelForm2: {
        dealerCode: "",
        timeTag: "",
        chooseDealer: [],
      },
      visible2: false,
    };
  },
  methods: {
    async doExport() {
      let timeTag = this.form.timeTag;
      let response = await this.$Http.ExportDetails({
        timeTag: this.form.timeTag,
        status: this.form.status,
        ranges: this.form.dealerCode,
      });
      let arr = timeTag.split("-");
      let title = arr.length === 1 ? arr[0] + "年度" : `${arr[0]}年${arr[1]}月`;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = title + `盈利分析填报明细.xlsx`;
      link.click();
    },
    modelTypeChange(val) {
      this.modelForm2.timeTag = "";
      if(val === "年") {
        this.formatter = "yyyy";
        this.selectModel = "year";
      } else {
        this.formatter = "yyyy-MM";
        this.selectModel = "month";
      }
    },
    async handleOk2() {
      let self = this;
      //新增or编辑
      let res = await self.$Http.ResetStatus(self.modelForm2);
      if(res["Success"]) {
        self.$message.success("重置成功");
        self.visible2 = false;
        this.$refs["modelForm2"].resetFields();
      } else {
        self.$message.error(res.Message);
      }
    },
    handleCancel2() {
      this.$refs["modelForm2"].resetFields();
    },
    dealerChange2(select) {
      this.modelForm2.dealerCode = select[1];
    },
    panelChange3(value) {
      this.modelForm2.timeTag = value.format(this.formatter);
    },

    doReset() {
      this.visible2 = true;
      this.modelTypeChange(this.type);
      this.getDealersByRole();
    },
    async downLoad() {
      if(this.form.timeTag === "") {
        this.$message.warning("请选择时间");
        return;
      }
      axios({
        url: "/apis/XProfitAnalyze/StatisticsDetailDownload",
        method: "post",
        data: {
          timeTag: this.form.timeTag,
          status: this.form.status,
          ranges: this.form.dealerCode,
        },
        responseType: "blob",
      }).then(response => {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.form.timeTag + `盈利分析填报情况总览.xls`;
        link.click();
      }, error => {
        this.$message.error("导出excel出错");
      });
    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      // this.getList(this.paginationParam.current);
    },
    handleChange() {
      this.form.timeTag = "";
    },
    panelChange(val) {
      if(this.type === "月") {
        this.form.timeTag = val.format("YYYY-MM");
      } else {
        this.form.timeTag = val.format("YYYY");
      }
    },
    //获取列表
    async getStatistics() {
      if(this.form.timeTag === "") {
        this.$message.warning("请选择时间");
        return;
      }
      let self = this;
      //将分页参数传入form对象
      let res = await self.$Http.Statistics({
        timeTag: this.form.timeTag,
        status: this.form.status,
        ranges: this.form.dealerCode,
      });
      self.statisticsData = res.Data;
      let res2 = await self.$Http.StatisticsDetail({
        timeTag: this.form.timeTag,
        status: this.form.status,
        ranges: this.form.dealerCode,
      });
      self.tableData = res2.Data;
    },
    async dealerChange(value) {
      let arr = [];
      value.forEach(code => {
        arr.push(code[1]);
      });
      this.form.dealerCode = [null, null, null, [...arr]];
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      let dealerCount = 0;
      this.dealers = res.Data.chain().map(p => {
        dealerCount += p.Dealers.length;
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          this.dealerMap[dealer.value] = [part.value, dealer.value];
          return dealer;
        }).value();
        return part;
      }).value();
      this.hasMultipleDealer = dealerCount > 1;
    },

  },
  created() {
  },
  mounted() {
    this.form.timeTag = moment().format("YYYY");
    this.getStatistics();
    // this.getList(1);
    this.getDealersByRole();

  },
};
</script>
<style scoped>
::v-deep .el-cascader__search-input {
    min-width: 0 !important;height: 0 !important;
    }
</style>
