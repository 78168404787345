<template>
  <section>
    <div class="top">
      <span>店总数 <b>{{ tips.TotalCount }}</b>次</span>
      <span>达标店数 <b>{{ tips.UpToStandardCount }}</b>次</span>
      <span>未达标店数 <b>{{ tips.TotalCount - tips.UpToStandardCount }}</b></span>
      <span>达标率 <b>{{
          tips.TotalCount === 0 ? 0 : (tips.UpToStandardCount / tips.TotalCount * 100).toFixed(2)
        }}%</b></span>
    </div>
    <a-card>
      <div class="sea">
        <span>报表月份：</span>
        <a-month-picker v-model="search.reportDate" placeholder="请选择报表月份"/>
        <span style="padding-left: 36px">指标选择：</span>
        <a-select
            show-search placeholder="请选择指标" option-filter-prop="children"
            style="width: 200px" v-model="search.kpiCode"
            :filter-option="(input, option)=>option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0">
          <a-select-option v-for="kpi in kpis" :key="kpi.code">
            {{ kpi.name }}
          </a-select-option>
        </a-select>
        <span style="padding-left: 36px">区域/销售店选择：</span>
        <a-cascader style="width: 240px;" :options="dealers" :allowClear="false" change-on-select
                    placeholder="请选择/输入销售店" v-model="search.choseDealer"
                    :show-search="true"/>
        <span style="padding-left: 36px">是否达标：</span>
        <a-select v-model="search.upToStandard" style="width: 120px" placeholder="全部">
          <a-select-option :value="null">全部</a-select-option>
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
        <a-button type="primary" style="margin-right: 20px;margin-left: 50px;" @click="loadData">
          搜索
        </a-button>
      </div>
    </a-card>
    <a-card style="margin-top: 8px;">
      <a-table :columns="tableColumns" :data-source="tableData" :row-key="(record, index) => ++index"></a-table>
    </a-card>
  </section>
</template>

<script>
import moment from "moment";
import {GetFilterCodes} from "@/views/IndustryBenchmark/data";

export default {
  data() {
    return {
      search: {
        reportDate: moment().add(-1, "M"),
        kpiCode: undefined,
        choseDealer: [],
        upToStandard: null
      },
      dealers: [],
      tips: {
        TotalCount: 0,
        UpToStandardCount: 0,
      },
      tableColumns: [
        {
          title: "区域",
          dataIndex: "PartName",
          key: "PartName",
          scopedSlots: {customRender: "PartName"},
        },
        {
          title: "销售店名称",
          dataIndex: "DealerName",
          key: "DealerName",
        },
        {
          title: "销售店代码",
          dataIndex: "DealerCode",
          key: "DealerCode",
        },
        {
          title: "当月值",
          dataIndex: "NumValue",
          key: "NumValue",
          customRender: (text) => {
            return text.toFixed(4);
          },
        },
        {
          title: "行业标杆值",
          dataIndex: "StandardValue",
          key: "StandardValue",
        },
        {
          title: "是否达标",
          dataIndex: "IsUpToStandard",
          key: "IsUpToStandard",
          customRender: (text) => {
            return text ? "是" : <span style="color:red">否</span>;
          },
        },
      ],
      tableData: [],
    };
  },
  async created() {
    await this.getDealersByRole();
    await this.getParams();
  },
  computed: {
    kpis: function () {
      let codes = GetFilterCodes();
      return Object.keys(codes).chain().map(code => {
        let item = {};
        item.code = code;
        item.name = codes[code];
        return item;
      }).value();
    },
  },
  methods: {
    async getParams() {
      let params = this.$route.params;
      if (Object.keys(params).length > 0) {
        this.search.reportDate = params.reportDate;
        this.search.kpiCode = params.kpiCode;
        await this.loadData();
      }
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      let dealers = [{value: "", label: "全国"}];
      res.Data.chain().each(p => {
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          return dealer;
        }).value();
        dealers.push(part);
      });
      this.dealers = dealers;
      this.search.choseDealer = [""];
    },
    async loadData() {
      let res = await this.$Http.IndustryStandardReportDetail({
        reportDate: this.search.reportDate.format("YYYY-MM-01"),
        kpiCode: this.search.kpiCode,
        partId: this.search.choseDealer[0],
        dealerCode: this.search.choseDealer.length < 2 ? "" : this.search.choseDealer[1],
        upToStandard: this.search.upToStandard
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.tableData = res.Data.Details;
      this.tips = res.Data.DealerInfo;
    }
  },
};
</script>

<style lang="less" scoped>
.top {
  margin-bottom: 8px;
  background: #E6F7FF;
  height: 38px;
  line-height: 38px;
  padding: 0 24px;
  border: 1px solid #BAE7FF;

  span {
    padding-right: 36px;
    color: #444444;

    b {
      color: #24619e;
      font-weight: 500;
      text-align: center;
    }
  }

  .sea {
    span {
      padding-left: 36px;
      color: red;
    }
  }
}
</style>
