<template>
  <div>
    <a-card>
      选择时间：
      <a-date-picker mode="month" v-model="selectDate" format='YYYY-MM' @panelChange="panelChange"
                     :allowClear="false"/>
      选择销售店：
      <a-tree-select @change="onAreaChange" show-search
                     v-model="areaValue"
                     style="width: 240px;margin-left:1vw;"
                     :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                     :tree-data="treeData"
                     :treeDefaultExpandedKeys="DefaultTreeData"
                     searchPlaceholder="搜索"
                     tree-node-filter-prop="title"
      />
      <span style="padding-left: 2rem">填报状态：</span>
      <span style="margin-right: 20px" v-for="(item,index) in statisticsData" :key="index">
                {{ item.Status }}
                <span style="color: #24619e">{{ item.Count }}</span>
            </span>
      <a-tooltip placement="topLeft" :title="`点击生成《${moment(selectDate).format('YYYY年M月')}${areaValue}盈利驱动事业计划》`">
        <!--<a-button style="float: right;" type="primary" @click="generateImages()">生成长图</a-button>-->
      </a-tooltip>
    </a-card>
    <div class="" ref="capture">
      <a-card>
        <div style="text-align: center">
                    <span style=" font-weight: bold;font-size: 40px">
                        {{ moment(selectDate).format('YYYY年M月') + areaValue }}<span v-if="dealerCode.length !== 'DSXA040'.length">（店均）</span>盈利驱动事业计划
              </span>
        </div>
      </a-card>
      <profitForecast :selectedDate="selectDate" :dealerCode="this.dealerCode" :is-show="isShow"/>
    </div>
  </div>
</template>

<script>
import profitForecast from "@/views/incomeAnalysis/monthCompont/profitForecast";
import moment from "moment";
import {message} from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  name: "monthAnalysis",
  components: {
    profitForecast
  },
  data() {
    return {
      unReported: 0,
      unSubmbited: 0,
      submited: 0,
      selectDate: null, moment,
      isShow: true,
      selectYear: null,
      selectMonth: null,
      dealerCode: "",
      areaValue: "",
      statisticsData:[],
      DefaultTreeData: [],
      treeData: [
        {
          title: "全国",
          value: "全国",
          key: "全国",
          disabled: false,
          children: [],
        }],
    }
  },
  methods: {
    // 获取填报信息
    async getStatistics() {
      let paramsObj = {timeTag: this.selectDate};
      if (this.dealerCode.length === "94AA0AD2-A667-421A-AEAD-72EC300F2696".length) {
        Object.assign(paramsObj, {partId: this.dealerCode})
      }
      //选择销售店
      if (this.dealerCode.length === "DSXA040".length) {
        Object.assign(paramsObj, {dealerCode: this.dealerCode})
      }
      let res = await this.$Http.Statistics(paramsObj)
      this.statisticsData = res.Data
    },
    onAreaChange(value, selectedOptions) {
      this.dealerCode = value
      this.areaValue = selectedOptions[0]
      this.getStatistics();
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      this.isShow = false; // 隐藏填报说明
      setTimeout(() => {
        html2canvas(this.$refs.capture, {dpi: 300}).then(canvas => {
          this.img = canvas.toDataURL();
          if (window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, moment(this.selectDate).format("YYYY年M月") + this.areaValue + "盈利驱动事业计划" + "." + "png");
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", moment(this.selectDate).format("YYYY年M月") + this.areaValue + "盈利驱动事业计划");
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000)
    },
    async getTreeData() {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Data) {
        this.$message.error(res.Message);
        return;
      }
      this.treeData[0].children = res.Data.chain().map(p => {
        let obj = {};
        obj.value = p.PartId;
        obj.key = p.PartId;
        obj.title = p.PartName;
        obj.disabled = false
        obj.children = p.Dealers.chain().map(d => {
          let childObj = {};
          childObj.value = d.DealerCode;
          childObj.key = d.DealerCode;
          childObj.title = d.DealerName;
          return childObj;
        }).value();
        return obj;
      }).value();
      this.DefaultTreeData.push(
          this.treeData[0].key,
      );
    },
    panelChange(value) {
      this.selectDate = value.format("YYYY-MM")
      this.selectYear = value.format("YYYY")
      this.selectMonth = value.format("MM")
      this.getStatistics();
    },
    async getUserDealerInfo() {
      let res = await this.$Http.getCurrDealerInfo()
      if (res.Success) {
        this.dealerCode = res.Data.DealerCode;
        this.areaValue = res.Data.DealerName
      }
    }
  },
  async created() {
    await this.getTreeData();
    // 获取当前用户销售店信息
    await this.getUserDealerInfo();
    let date = moment().add(0,"month"); // 当月
    this.selectDate = date.format("YYYY-MM")
    this.selectYear = date.format("YYYY")
    this.selectMonth = date.format("MM")
    await this.getStatistics();
  }
}
</script>

<style lang="less" scoped>

</style>
