<template>
  <div class="main" style="margin-top: 35px">
    <h2 style="grid-area: title2">销售业务 (店均)</h2>
    <div style="grid-area: d1;" class="card_border">
      <span class="card-title2">新车总销量</span>
      <span class="title-num">{{ ThousandYuan(GetKpiCodeData("A0302001")) }}台</span>
      <div style="margin-top: 10px;">各车型销量情况</div>
      <main-chart :chart-data="chartData4_1"/>
    </div>
    <div style="grid-area: h1" class="card_border">
      <div class="title-num-list">
        <div>
          <p>展厅新车总销量</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0302021")) }}台</p>
        </div>
        <div>
          <p>iDCC新车总销量</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0302022")) }}台</p>
        </div>
        <div>
          <p>二网新车总销量</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0302023")) }}台</p>
        </div>
        <div>
          <p>大客户总销量</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0302024")) }}台</p>
        </div>
      </div>
      <div>
        <a-col span="8" class="color333">各通道新车销量占比</a-col>
        <!--        <a-col :span="4" :offset="12">
                  <a-select
                    label-in-value
                    :default-value="{ key: '1' }"
                    style="width: 120px"
                    @change="handleChange"
                  >
                    <a-select-option value="1">全通道</a-select-option>
                  </a-select>
                </a-col>-->
      </div>
      <div style="clear: both">
        <main-chart :chart-data="chartData"/>
      </div>
    </div>
    <div style="grid-area: h2" class="card_border">
      <div class="title-num-list">
        <div>
          <p>新车期末总库存</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0402006")) }}台</p>
        </div>
        <div>
          <p>1月以内库存</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0402007")) }}台</p>
        </div>
        <div>
          <p>1-3个月库存</p>
          <p class="title-num2">{{
              Number(ThousandYuan(GetKpiCodeData("A0402008"))) + Number(ThousandYuan(GetKpiCodeData("A0402009")))
            }}台</p>
        </div>
        <div>
          <p>3个月以上库存</p>
          <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0402010")) }}台</p>
        </div>
      </div>
      <div>
        <a-col span="24" class="color333">各车型库存数</a-col>
      </div>
      <div style="clear: both">
        <main-chart :chart-data="chartData2"/>
      </div>
    </div>
    <div style="grid-area: i1" class="card_border">
      <a-col span="24" class="color333" style="font-size: 16px;font-weight: 600">平均单车销售业务综合毛利</a-col>
      <div class="title-num-list2" style="clear: both;padding-left: 10%">

        <div>
          <span>平均单车销售业务综合毛利</span>
          <span class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0107004")) }}元</span>
        </div>
        <div >
          <span>( 平均单车销售裸车毛利(一级)</span>
          <span class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0106026")) }}元</span>
        </div>
        <div >
          <span>平均单车销售返利毛利(二级)</span>
          <span class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0105071")) }}元</span>
        </div>
        <div>
          <span>平均单车销售水平事业毛利(三级)</span>
          <span class="title-num2">{{ ThousandYuan(GetKpiCodeData("A0105072")) }}元</span>)
        </div>
      </div>
      <div>
        <div style="margin-top: 20px;">
          <main-chart :chart-data="chartData3"/>
        </div>
      </div>
    </div>
    <div style="grid-area: j1" class="card_border">
      <div class="title-num-list2">
        <div class="flexCenter">
          <span>iDCC线索邀约到店率</span>
          <span class="title-num2">{{ PercentageCode(GetKpiCodeData("A1303024")) }}%</span>
        </div>
        <div class="flexCenter">
          <span>iDCC邀约到店签单率</span>
          <span class="title-num2">{{ PercentageCode(GetKpiCodeData("A1303028")) }}%</span>
        </div>
        <div class="flexCenter">
          <span>iDCC线索转化率</span>
          <span class="title-num2">{{ PercentageCode(GetKpiCodeData("A1303032")) }}%</span>
        </div>
      </div>
      <div style="position: relative;top:-50px;">
        <main-chart :chart-data="chartFunnelData"></main-chart>
      </div>
    </div>
    <div style="grid-area: j2" class="card-title1 card_border">
      <h2>展厅首次客流数</h2>
      <p>{{ ThousandYuan((GetKpiCodeData("A1302012"))) }}个</p>
    </div>
    <div style="grid-area: k2" class="card-title1 card_border">
      <h2>展厅首次客流成交率</h2>
      <p>{{ percentage(GetKpiCodeData("A1303081")) }}</p>
    </div>
    <div style="grid-area: l2" class="card-title1 card_border">
      <h2>展厅再次客流成交率</h2>
      <p>{{ percentage(GetKpiCodeData("A1303082")) }}</p>
    </div>
    <div style="grid-area: m1" class="card-title1 card_border">
      <h2>新车用品渗透率</h2>
      <p>{{ percentage(GetKpiCodeData("A0303271")) }}</p>
    </div>
    <div style="grid-area: m2" class="card-title1 card_border">
      <h2>金融按揭渗透率</h2>
      <p>{{ percentage(GetKpiCodeData("A0303273")) }}</p>
    </div>
    <div style="grid-area: m3" class="card-title1 card_border">
      <h2>新保渗透率</h2>
      <p>{{ percentage(GetKpiCodeData("A0303272")) }}</p>
    </div>
    <div style="grid-area: m4" class="card-title1 card_border">
      <h2>上牌验车渗透率</h2>
      <p>{{ percentage(GetKpiCodeData("A0303274")) }}</p>
    </div>
    <div style="grid-area: m5" class="card-title1 card_border">
      <h2>新车延保渗透率</h2>
      <p>{{ percentage(GetKpiCodeData("A0303276")) }}</p>
    </div>
    <div style="grid-area: m6" class="card-title1 card_border">
      <h2>二手车置换率</h2>
      <p>{{ percentage(GetKpiCodeData("A0303275")) }}</p>
    </div>
  </div>
</template>

<script>
import mainChart from "./componets/mainChart";
import {
  xAxis, yAxisDefault, yAxisRight, seriesBar, seriesLine, smooth,
} from "./componets/echartConstSeting.ts";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";
import { GetGauge, PercentageCode, GetThreeGauge } from "../Code";

export default {
  name: "sales",
  components: { mainChart },
  props: {
    kpiCodeData: {
      type: Array,
    },
    salesRatioData: {
      type: Array,
    },
  },
  data() {
    return {
      chartData4_1: {
        //   chartHeight: "400px",
        columns: ["车型", "展厅新车销量", "IDCC新车销量", "二网新车销量", "大客户新车销量", "当月销量占比", "销量环比", "销量同比"],
        rows: [
          {
            "车型": "GS3 \n  Power",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302141", "A0302161", "A0302181", "A0302201", "A0304005"],
          },
          {
            "车型": "GS4",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302142", "A0302162", "A0302182", "A0302202", "A0304006"],
          },
          {
            "车型": "GS4 \n PLUS",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302145", "A0302165", "A0302185", "A0302205", "A0304009"],
          },
          {
            "车型": "GS8",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302147", "A0302167", "A0302187", "A0302207", "A0304011"],
          },
          {
            "车型": "M6",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302149", "A0302169", "A0302189", "A0302209", "A0304013"],
          },
          {
            "车型": "M8",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302150", "A0302170", "A0302190", "A0302210", "A0304014"],
          },
          {
            "车型": "GA4",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302151", "A0302171", "A0302191", "A0302211", "A0304015"],
          },
          {
            "车型": "GA6",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302152", "A0302172", "A0302192", "A0302212", "A0304016"],
          },
          {
            "车型": "GA8",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302153", "A0302173", "A0302193", "A0302213", "A0304017"],
          },
          {
            "车型": "影豹",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302154", "A0302174", "A0302194", "A0302214", "A0304018"],
          },
          {
            "车型": "其他",
            "展厅新车销量": 0,
            "IDCC新车销量": 0,
            "二网新车销量": 0,
            "大客户新车销量": 0,
            "当月销量占比": null,
            "销量环比": null,
            "销量同比": null,
            codes: ["A0302155", "A0302175", "A0302195", "A0302215", "A0304019"],
          },
        ],
        setting: {
          type: "histogram",
          stack: { car: ["展厅新车销量", "IDCC新车销量", "二网新车销量", "大客户新车销量"] },
          showLine: ["当月销量占比", "销量环比", "销量同比"],
          yAxisType: ["0.[0]", "0.[0]%"],
          axisSite: { right: ["当月销量占比", "销量环比", "销量同比"] },
          yAxisName: ["台", "%"],
          label: {
            show: true, // 显示数字
            position: "inside",
            textStyle: {
              color: "black",
            },
            formatter: function(params) {
              if(params.componentSubType === "line") {
                return Number(Number((params.data ?? 0) * 100).toFixed(2)).toFixed(1) + "%";
              } else {
                return Number(params.data ?? 0).toFixed(0);
              }
            },
          },
        },
        options: {
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              let arr = {};
              params.forEach((d, i) => {
                if(d.componentSubType === "bar") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
                }
                if(d.componentSubType === "line") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                }
              });
              let re = params[0].name + "<br />";
              Object.values(arr).forEach(a => {
                re += a + "<br />";
              });
              return re;
            },
          },
          grid: {
            right: "1%",
          },
          xAxis,
          yAxis: [yAxisDefault, yAxisRight],
          series: [seriesBar, seriesBar, seriesBar, seriesBar,
            {
              lineStyle: {
                width: 3,
              },
              symbolSize: 5,
              itemStyle: {
                borderWidth: 1,
              },
              smooth: false,
              label: {
                show: true, // 显示数字
                position: "right",
                textStyle: {
                  fontSize: 12, color: "black",
                },
                formatter: function(params) {
                  return Number(Number((params.data ?? 0) * 100).toFixed(2)).toFixed(1) + "%";
                },
              },
            }, {
              lineStyle: {
                width: 3,
              },
              symbolSize: 5,
              itemStyle: {
                borderWidth: 1,
              },
              smooth: false,
              label: {
                show: true, // 显示数字
                position: "right",
                textStyle: {
                  fontSize: 12, color: "black",
                },
                formatter: function(params) {
                  return Number(Number((params.data ?? 0) * 100).toFixed(2)).toFixed(1) + "%";
                },
              },
            }, {
              lineStyle: {
                width: 3,
              },
              symbolSize: 5,
              itemStyle: {
                borderWidth: 1,
              },
              smooth: false,
              label: {
                show: true, // 显示数字
                position: "right",
                textStyle: {
                  fontSize: 12, color: "black",
                },
                formatter: function(params) {
                  return Number(Number((params.data ?? 0) * 100).toFixed(2)).toFixed(1) + "%";
                },
              },
            }],
        },
      },
      tenThousandYuan, ThousandYuan, GetGauge, percentage, PercentageCode, GetThreeGauge,
      chartData: {
        chartHeight: "340px",
        columns: ["车型", "展厅新车销量占比", "IDCC新车销量占比", "二网新车销量占比", "大客户销量占比"],
        rows: [
          {
            "车型": "GS3 \n Power", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303101", "A0303121", "A0303141", "A0303161"],
          },
          {
            "车型": "GS4", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303102", "A0303122", "A0303142", "A0303162"],
          },
          {
            "车型": "GS4 \n PLUS", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303105", "A0303125", "A0303145", "A0303165"],
          },
          {
            "车型": "GS8", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303107", "A0303127", "A0303147", "A0303167"],
          },
          {
            "车型": "M6", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303109", "A0303129", "A0303149", "A0303169"],
          },
          {
            "车型": "M8", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303110", "A0303130", "A0303150", "A0303170"],
          },
          {
            "车型": "GA4", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303111", "A0303131", "A0303151", "A0303171"],
          },
          {
            "车型": "GA6", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303112", "A0303132", "A0303152", "A0303172"],
          },
          {
            "车型": "GA8", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303113", "A0303133", "A0303153", "A0303173"],
          },
          {
            "车型": "影豹", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303114", "A0303134", "A0303154", "A0303174"],
          },
          {
            "车型": "其他", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303115", "A0303135", "A0303155", "A0303175"],
          },
        ],
        setting: {
          type: "histogram",
          stack: { "占比": ["展厅新车销量占比", "IDCC新车销量占比", "二网新车销量占比", "大客户销量占比"] },
          yAxisType: ["0.[0]"],
          yAxisName: ["%"],
          label: {
            show: true, // 显示数字
            position: "inside",
            textStyle: {
              color: "black",
            },
            formatter: function(params) {
              return params.data + "%";
            },
          },
        },
        options: {
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              let arr = {};
              params.forEach((d, i) => {
                if(d.componentSubType === "bar") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
                }
              });
              let re = params[0].name + "<br />";
              Object.values(arr).forEach(a => {
                re += a + "<br />";
              });
              return re;
            },
          },
          xAxis,
          yAxis: [yAxisDefault, yAxisRight],
          series: [seriesBar, seriesBar, seriesBar, seriesBar],
        },
      },
      chartData2: {
        chartHeight: "340px",
        columns: ["车型", "1月以内库存", "1-3个月库存", "3个月以上库存"],
        rows: [
          {
            "车型": "GS3 \n Power", "展厅新车销量占比": null, "IDCC新车销量占比": null, "二网新车销量占比": null, "大客户销量占比": null,
            codes: ["A0303101", "A0303121", "A0303141", "A0303161"],
          },
          {
            "车型": "GS4", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402025", "A0402026", "A0402027", "A0402028"],
          },
          {
            "车型": "GS4 \n PLUS", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402043", "A0402044", "A0402045", "A0402046"],
          },
          {
            "车型": "GS8", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402055", "A0402056", "A0402057", "A0402058"],
          },
          {
            "车型": "M6", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402116", "A0402117", "A0402118", "A0402119"],
          },
          {
            "车型": "M8", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402122", "A0402123", "A0402124", "A0402125"],
          },
          {
            "车型": "GA4", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402128", "A0402129", "A0402130", "A0402131"],
          },
          {
            "车型": "GA6", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402134", "A0402135", "A0402136", "A0402137"],
          },
          {
            "车型": "GA8", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["A0402148", "A0402149", "A0402150", "A0402171"],
          },
          {
            "车型": "影豹", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["", "A0402173", "A0402174+A0402175", "A0402176"],
          },
          {
            "车型": "其他", "1月以内库存": 0, "1-3个月库存": 0, "3个月以上库存": 0,
            codes: ["", "A0402179", "A0402180+A0402181", "A0402182"],
          },
        ],
        setting: {
          type: "histogram",
          stack: { "车型": ["1月以内库存", "1-3个月库存", "3个月以上库存"] },
          yAxisType: ["0"],
          yAxisName: ["台"],
          label: {
            show: true, // 显示数字
            position: "inside",
            textStyle: {
              color: "black",
            },
            formatter: function(params) {
              return Number(params.data ?? null).toFixed(0);
            },
          },
        },
        options: {
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              let arr = {};
              params.forEach((d, i) => {
                if(d.componentSubType === "bar") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}`;
                }
                if(d.componentSubType === "line") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                }
              });
              let re = params[0].name + "<br />";
              Object.values(arr).forEach(a => {
                re += a + "<br />";
              });
              return re;
            },
          },
          xAxis,
          yAxis: [yAxisDefault],
          series: [seriesBar, seriesBar, seriesBar],
        },
      },
      chartData3: {
        chartHeight: "340px",
        columns: ["车型", "平均单车销售裸车毛利(一级)", "平均单车销售返利毛利(二级)", "平均单车销售水平事业毛利(三级)"],
        rows: [
          {
            "车型": "GS3 \n Power", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null,"平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105052", "A0104352","A0105141"],
          },
          {
            "车型": "GS4", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105053", "A0104353", "A0105142"],
          },
          {
            "车型": "GS4 \n PLUS", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105056", "A0104356", "A0105143"],
          },
          {
            "车型": "GS8", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105058", "A0104358", "A0105144"],
          },
          {
            "车型": "M6", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105060", "A0104360", "A0105145"],
          },
          {
            "车型": "M8", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105061", "A0104361", "A0105146"],
          },
          {
            "车型": "GA4", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105062", "A0104362", "A0105147"],
          },
          {
            "车型": "GA6", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105063", "A0104363", "A0105148"],
          },
          {
            "车型": "GA8", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105064", "A0104364", "A0105149"],
          },
          {
            "车型": "影豹", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105065", "A0104365", "A0105150"],
          },
          {
            "车型": "其他", "平均单车销售裸车毛利(一级)": null, "平均单车销售返利毛利(二级)": null, "平均单车销售水平事业毛利(三级)": null,
            codes: ["A0105066", "A0104366", "A0105151"],
          },
        ],
        setting: {
          type: "histogram",
          stack: { car: ["平均单车销售裸车毛利(一级)", "平均单车销售返利毛利(二级)", "平均单车销售水平事业毛利(三级)"] },
          yAxisType: ["0"],
          yAxisName: ["元"],
          label: {
            show: true, // 显示数字
            position: "inside",
            textStyle: {
              color: "black",
            },
            formatter: function(params) {
              return Number(params.data ?? null).toFixed(0);
            },
          },
        },
        options: {
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              let arr = {};
              params.forEach((d, i) => {
                if(d.componentSubType === "bar") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}`;
                }
              });
              let re = params[0].name + "<br />";
              Object.values(arr).forEach(a => {
                re += a + "<br />";
              });
              return re;
            },
          },
          xAxis,
          yAxis: [yAxisDefault],
          series: [seriesBar, seriesBar],
        },
      },
      chartFunnelData: {
        //  chartHeight: "400px",
        columns: ["状态", "数值"],
        rows: [
          { "状态": "iDCC线索总数", "数值": 0, kpiCode: "A1302115" },
          { "状态": "iDCC有效线索总数", "数值": 0, kpiCode: "A1302019" },
          { "状态": "iDCC邀约到店数", "数值": 0, kpiCode: "A1302028" },
          { "状态": "iDCC新增订单总数", "数值": 0, kpiCode: "A1302032" },
          { "状态": "iDCC新车销量", "数值": 0, kpiCode: "A0302022" },
        ],
        setting: {
          type: "funnel",
          label: {
            show: true,
            position: "inside",
            color: "#000",
            formatter: function(params) {
              return `${params.name}: ${params.data.realValue}`;
              // return `${params.data.realValue}`;
            },
          },
        },
        options: {
          grid: {
            bottom: 460,
          },
          legend: {
            orient: "vertical",
            left: "left",
            bottom: "15% ",
          },
          xAxis,
          series: [seriesBar],
        },
      },
    };
  },
  watch: {
    kpiCodeData: {
      handler: function() {
        this.GetChartData();
      },
      deep: true,
    },
    salesRatioData: {
      handler: function() {
        this.GetChartData();
      },
      deep: true,
    },
  },
  created() {
    this.GetChartData();
  },
  methods: {
    GetKpiCodeData(KpiCode) {
      if(this.kpiCodeData) {
        if(this.kpiCodeData.length < 1) return false;
        let data = this.kpiCodeData.filter(d => d.KpiCode === KpiCode);
        if(data.length > 0) {
          return data[0].NumValue;
        } else {
          return null;
        }
      }
    },
    GetChartData() {
      this.chartData.rows.forEach(d => {
        d["展厅新车销量占比"] = this.PercentageCode(this.GetKpiCodeData(d.codes[0]));
        d["IDCC新车销量占比"] = this.PercentageCode(this.GetKpiCodeData(d.codes[1]));
        d["二网新车销量占比"] = this.PercentageCode(this.GetKpiCodeData(d.codes[2]));
        d["大客户销量占比"] = this.PercentageCode(this.GetKpiCodeData(d.codes[3]));

        d["展厅新车销量占比"] = d["展厅新车销量占比"] === 0 ? null : d["展厅新车销量占比"];
        d["IDCC新车销量占比"] = d["IDCC新车销量占比"] === 0 ? null : d["IDCC新车销量占比"];
        d["二网新车销量占比"] = d["二网新车销量占比"] === 0 ? null : d["二网新车销量占比"];
        d["大客户销量占比"] = d["大客户销量占比"] === 0 ? null : d["大客户销量占比"];
      });

      this.chartData2.rows.forEach(d => {
        if(this.GetKpiCodeData(d.codes[0]) === false) {
          d["1月以内库存"] = 0;
        } else {
          d["1月以内库存"] = this.ThousandYuan((this.GetKpiCodeData(d.codes[0]) !== undefined) ? this.GetKpiCodeData(d.codes[0]) : 0);
        }
        if(this.GetKpiCodeData(d.codes[1]) === false || this.GetKpiCodeData(d.codes[2]) === false) {
          d["1-3个月库存"] = 0;
        } else {
          d["1-3个月库存"] = this.ThousandYuan((this.GetKpiCodeData(d.codes[1]) !== undefined) ? this.GetKpiCodeData(d.codes[1]) : 0 + (this.GetKpiCodeData(d.codes[2]) !== undefined) ? this.GetKpiCodeData(d.codes[2]) : 0);
        }
        d["1-3个月库存"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[1]) + this.ThousandYuan(this.GetKpiCodeData(d.codes[2])));
        if(this.GetKpiCodeData(d.codes[3]) === false) {
          d["3个月以上库存"] = 0;
        } else {
          d["3个月以上库存"] = this.ThousandYuan((this.GetKpiCodeData(d.codes[3]) !== undefined) ? this.GetKpiCodeData(d.codes[3]) : 0);
        }
        d["1月以内库存"] = d["1月以内库存"] === 0 ? null : d["1月以内库存"];
        d["1-3个月库存"] = d["1-3个月库存"] === 0 ? null : d["1-3个月库存"];
        d["3个月以上库存"] = d["3个月以上库存"] === 0 ? null : d["3个月以上库存"];
      });

      this.chartData3.rows.forEach(d => {
        d["平均单车销售裸车毛利(一级)"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[0])) === 0 ? null : this.ThousandYuan(this.GetKpiCodeData(d.codes[0]));
        d["平均单车销售返利毛利(二级)"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[1])) === 0 ? null : this.ThousandYuan(this.GetKpiCodeData(d.codes[1]));
        d["平均单车销售水平事业毛利(三级)"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[2])) === 0 ? null : this.ThousandYuan(this.GetKpiCodeData(d.codes[2]));
      });
      /*
      *     "车型": "GS3", "展厅新车销量": 0,"IDCC新车销量": 0,"二网新车销量": 0,"大客户新车销量": 0, "当月销量占比": null, "销量环比": null, "销量同比": null,
            codes: ["A0302140","A0302160","A0302180","A0302200", "A0304004"],
      *  */
      this.chartData4_1.rows.forEach((d, i) => {
        let hb, tb = 0;
        if(this.salesRatioData.length > 0) {
          if(this.salesRatioData[i]) {
            hb = this.salesRatioData[i]["HB"] ?? 0;
            tb = this.salesRatioData[i]["TB"] ?? 0;
          }
        }
        if(hb !== undefined) PercentageCode(hb);
        if(tb !== undefined) PercentageCode(tb);

        d["展厅新车销量"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[0]));
        d["IDCC新车销量"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[1]));
        d["二网新车销量"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[2]));
        d["大客户新车销量"] = this.ThousandYuan(this.GetKpiCodeData(d.codes[3]));
        d["当月销量占比"] = this.GetKpiCodeData(d.codes[4]);
        d["销量环比"] = hb === undefined || hb === 0 ? null : hb;
        d["销量同比"] = tb === undefined || tb === 0 ? null : tb;

        d["展厅新车销量"] = d["展厅新车销量"] === 0 ? null : d["展厅新车销量"];
        d["IDCC新车销量"] = d["IDCC新车销量"] === 0 ? null : d["IDCC新车销量"];
        d["二网新车销量"] = d["二网新车销量"] === 0 ? null : d["二网新车销量"];
        d["大客户新车销量"] = d["大客户新车销量"] === 0 ? null : d["大客户新车销量"];
        d["当月销量占比"] = d["当月销量占比"] === 0 ? null : d["当月销量占比"];
        d["销量环比"] = d["销量环比"] === 0 ? null : d["销量环比"];
        d["销量同比"] = d["销量同比"] === 0 ? null : d["销量同比"];
      });
      this.chartFunnelData.rows.forEach(d => d["数值"] = this.ThousandYuan(this.GetKpiCodeData(d.kpiCode)));
    },
  },
};
</script>

<style lang="less" scoped>
@import "../common";

.main {
  display: grid;
  grid-template-columns:repeat(6, 15.8%);
  grid-template-rows:25px 440px 400px 380px repeat(3, 110px);
  grid-column-gap: 13px;
  grid-row-gap: 15px;
  grid-template-areas:
                            'title2 title2 title2 title2 title2 title2'
                            'd1 d1 d1 d1 d1 d1'
                           'h1 h1 h1 h2 h2 h2'
                           'i1 i1 i1 i1 i1 i1'
                           'j1 j1 j1 j1 j1 j2'
                           'j1 j1 j1 j1 j1 k2'
                           'j1 j1 j1 j1 j1 l2'
                           'm1 m2 m3 m4 m5 m6';

  }

.title-num2 {
  font-weight: 600;font-size: 24px;margin-top: 0;
  }
</style>
