<template>
  <a-row :guter="[28,0]">
    <!--item1   提车总数 -->
    <a-col :span="24">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>提车总数 【提车年累计: <span>8242台</span>】</h2>
          </a-col>
          <a-col :span="2" :offset="17" class="right">
            <!--            <a-tooltip>
                          <template slot="title">
                            只能选择2年的数据
                          </template>
                          <a-tree-select
                            v-model="selectValue.Value1.yearValue" @change="onYearChange"
                            style="width:220px;"
                            :tree-data="yearTreeData.data"
                            tree-checkable
                            :show-checked-strategy="SHOW_PARENT"
                            search-placeholder="请选择"
                          />
                        </a-tooltip>
                        <a-tooltip>
                          <template slot="title">
                            选择/搜索 所要生成的区域一
                          </template>
                          <a-tree-select show-search @change="area1Change"
                                         v-model="selectValue.Value1.area1" allow-clear
                                         style="width: 200px;margin-left: 15px;"
                                         :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                         :tree-data="treeData" expandTrigger="hover"
                                         placeholder="请选择"
                                         :treeDefaultExpandedKeys="DefaultTreeData"
                                         searchPlaceholder="搜索"
                                         tree-node-filter-prop="title"
                          />
                        </a-tooltip>
                        <a-tooltip>
                          <template slot="title">
                            选择/搜索 所要生成的区域二
                          </template>
                          <a-tree-select show-search @change="area2Change"
                                         v-model="selectValue.Value1.area2" allow-clear
                                         style="width: 200px;margin-left: 15px;"
                                         :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                         :tree-data="treeData" expandTrigger="hover"
                                         placeholder="请选择"
                                         :treeDefaultExpandedKeys="DefaultTreeData"
                                         searchPlaceholder="搜索"
                                         tree-node-filter-prop="title"
                          />
                        </a-tooltip>
                        <a-tooltip>
                          <template slot="title">
                            选择车型
                          </template>
                          <a-tree-select
                            v-model="selectValue.Value1.model"
                            style="width:240px;"
                            :tree-data="checkData.data"
                            tree-checkable
                            :treeDefaultExpandedKeys="checkData.default"
                            :show-checked-strategy="SHOW_PARENT"
                            search-placeholder="请选择"
                          />
                        </a-tooltip>
                        <span></span>-->
            <a-button type="primary" @click="showModal">
              选择条件
            </a-button>
          </a-col>

        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData1"/>
        </div>
      </a-card>
    </a-col>
    <!--item2   提车目标完成率 -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>提车目标完成率</h2>
          </a-col>
          <a-col :span="12" :offset="7" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value2.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart2area1Change"
                             v-model="selectValue.Value2.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart2area2Change"
                             v-model="selectValue.Value2.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart2Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData2"/>
        </div>
      </a-card>
    </a-col>
    <!--item3   提车车型占比  -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>提车车型占比 </h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value3.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart3area1Change"
                             v-model="selectValue.Value3.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart3area2Change"
                             v-model="selectValue.Value3.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value3.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart3Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData3"/>
        </div>
      </a-card>
    </a-col>
    <!--item4   订单总数  -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>订单总数 【订单年累计: <span>10000</span>】</h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value4.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart4area1Change"
                             v-model="selectValue.Value4.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart4area2Change"
                             v-model="selectValue.Value4.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value4.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart4Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData4"/>
        </div>
      </a-card>
    </a-col>
    <!--item5   订单车型占比 与提车车型占比相似 -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>订单车型占比 </h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value5.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart5area1Change"
                             v-model="selectValue.Value5.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart5area2Change"
                             v-model="selectValue.Value5.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value5.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart5Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData5"/>
        </div>
      </a-card>
    </a-col>
    <!--item6  终端总数  与订单总数相似  -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>终端总数 【终端年累计: <span>10000</span>】</h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value6.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart6area1Change"
                             v-model="selectValue.Value6.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart6area2Change"
                             v-model="selectValue.Value6.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value6.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart6Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData6"/>
        </div>
      </a-card>
    </a-col>
    <!--item7   终端车型占比 与提车车型占比相似 -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>终端车型占比 </h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value7.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart7area1Change"
                             v-model="selectValue.Value7.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart7area2Change"
                             v-model="selectValue.Value7.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value7.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart7Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData7"/>
        </div>
      </a-card>
    </a-col>
    <!--item8  库存总数  与订单总数相似  -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>库存总数 【库存年累计: <span>10000</span>】</h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value8.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart8area1Change"
                             v-model="selectValue.Value8.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart8area2Change"
                             v-model="selectValue.Value8.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value8.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart8Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData8"/>
        </div>
      </a-card>
    </a-col>
    <!--item9   库存车型占比 与提车车型占比相似 -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart">
          <a-col :span="5">
            <h2>库存车型占比 </h2>
          </a-col>
          <a-col :span="16" :offset="3" class="right">
            <a-tooltip>
              <template slot="title">
                只能选择2年的数据
              </template>
              <a-tree-select
                v-model="selectValue.Value9.yearValue" @change="onYearChange"
                style="width:220px;"
                :tree-data="yearTreeData.data"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域一
              </template>
              <a-tree-select show-search @change="chart9area1Change"
                             v-model="selectValue.Value9.area1" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择/搜索 所要生成的区域二
              </template>
              <a-tree-select show-search @change="chart9area2Change"
                             v-model="selectValue.Value9.area2" allow-clear
                             style="width: 200px;margin-left: 15px;"
                             :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                             :tree-data="treeData" expandTrigger="hover"
                             placeholder="请选择"
                             :treeDefaultExpandedKeys="DefaultTreeData"
                             searchPlaceholder="搜索"
                             tree-node-filter-prop="title"
              />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value9.model"
                style="width:240px;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
            <span></span>
            <a-button type="primary" @click="chart9Change">
              生成图表
            </a-button>
          </a-col>
        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData9"/>
        </div>
      </a-card>
    </a-col>
    <!--table 库存明细    -->
    <a-col :span="24" class="carding">
      <a-card>
        <div class="bt_chart"><h2>库存明细</h2></div>
        <table class="table_style">
          <thead>
          <th>指标名称</th>
          <th>库存量</th>
          <th>库销比</th>
          <th>0-30天期末新车库存台次</th>
          <th>61-90天期末新车库存台次</th>
          <th>>90天期末新车库存台次</th>
          </thead>
          <tr v-for="(item,i) in tableData" :key="i++">
            <td>{{ item.carName }}</td>
            <td>{{ item.one }}</td>
            <td>{{ item.two }}</td>
            <td>{{ item.there }}</td>
            <td>{{ item.four }}</td>
            <td>{{ item.five }}</td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <a-modal :width="600" v-model="isView" title="条件筛选" :footer="null">
      <table class="modal-style">
        <tr>
          <td>年份:</td>
          <td>
            <a-checkbox-group @change="onCheckChange"
                              v-model="selectValue.Value1.yearValue"
                              name="yearValue"
            >
              <a-row>
                <a-col :span="6" v-for="(item,i) in yearTreeData.data" :key="i++">
                  <a-checkbox :value="item.title">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </td>
        </tr>
        <tr>
          <td>区域:</td>
          <td>
            <a-row>
              <a-col :span="12">
                <a-tooltip>
                  <template slot="title">
                    选择/搜索 所要生成的区域一
                  </template>
                  <a-tree-select show-search @change="area1Change"
                                 v-model="selectValue.Value1.area1" allow-clear
                                 style="width: 240px;"
                                 :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                 :tree-data="treeData" expandTrigger="hover"
                                 placeholder="请选择"
                                 :treeDefaultExpandedKeys="DefaultTreeData"
                                 searchPlaceholder="搜索"
                                 tree-node-filter-prop="title"
                  />
                </a-tooltip>
              </a-col>
              <a-col :span="12">
                <a-tooltip>
                  <template slot="title">
                    选择/搜索 所要生成的区域二
                  </template>
                  <a-tree-select show-search @change="area2Change"
                                 v-model="selectValue.Value1.area2" allow-clear
                                 style="width: 240px;margin-left:5px"
                                 :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                 :tree-data="treeData" expandTrigger="hover"
                                 placeholder="请选择"
                                 :treeDefaultExpandedKeys="DefaultTreeData"
                                 searchPlaceholder="搜索"
                                 tree-node-filter-prop="title"
                  />
                </a-tooltip>
              </a-col>
            </a-row>
          </td>
        </tr>
        <tr>
          <td>车型:</td>
          <td>
            <a-tooltip>
              <template slot="title">
                选择车型
              </template>
              <a-tree-select
                v-model="selectValue.Value1.model"
                style="width:100%;"
                :tree-data="checkData.data"
                tree-checkable
                :treeDefaultExpandedKeys="checkData.default"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="请选择"
              />
            </a-tooltip>
          </td>
        </tr>
      </table>
      <div style="text-align: center">
        <a-button type="primary" @click="chart1Change()">
          确定
        </a-button>
        <a-button type="primary" @click="isView=false">
          取消
        </a-button>
      </div>
    </a-modal>
  </a-row>
</template>

<script>
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import { lineStyle, grid, symbolSize, itemStyle } from "../chartConfig";
import { percentage } from "@/until/option";
import {
  axisLine,
  axisTick, barMaxWidth, color,
  legend,
  splitLine,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import moment from "moment";
import underscore from "underscore";
import { TreeSelect } from "ant-design-vue";

export default {
  name: "twoRegionalPerformance",
  props: {
    dataParams: {},
  },
  components: { mainChart },
  data() {
    return {
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      lineStyle, symbolSize, itemStyle, grid,
      isView: false,
      yearTreeData: {
        year: [],
        data: [],
      },
      treeData: [
        {
          title: "全国",
          value: "",
          key: "",
          children: [],
        },
      ],
      DefaultTreeData: [],
      selectValue: {
        Value1: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value2: {
          yearValue: [],
          area1: "全国",
          area2: "东北营销中心",
        },
        Value3: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value4: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value5: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value6: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value7: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value8: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
        Value9: {
          yearValue: [],
          area1: "西北营销中心",
          area2: "东北营销中心",
          model: ["GS3", "GS4"],
        },
      },
      parmId: "",
      checkData: {
        car: ["GS3", "GS3 Power", "GS4", "GS4 PHEV", "GS4 COUPE", "GS4 PLUS", "GS7", "GS8", "GS8S", "M6", "M8", "GA4", "GA6", "GA8"],
        data: [
          {
            title: "所有车型",
            value: "所有车型",
            key: "所有车型",
            children: [],
          },
        ],
        default: ["所有车型"],
      },
      chartData: {
        chartData1: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0", "0.[0]%"],
            yAxisName: ["台", "%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              }, {
                show: true,
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              },
              {
                barMaxWidth,
              },
            ],
          },
        },
        chartData2: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0", "0.[0]%"],
            yAxisName: ["台", "%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              },
            ],
          },
        },
        chartData3: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0.[0]%"],
            yAxisName: ["%"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
        chartData4: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0"],
            yAxisName: ["台"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
        chartData5: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0.[0]%"],
            yAxisName: ["%"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
        chartData6: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0"],
            yAxisName: ["台"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
        chartData7: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0.[0]%"],
            yAxisName: ["%"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
        chartData8: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0"],
            yAxisName: ["台"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
        chartData9: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [], vau: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0.[0]%"],
            yAxisName: ["%"],
            label: {
              barMaxWidth,
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if (params.componentSubType == "line") {
                  return percentage(params.data);
                  //   return (params.data * 10000).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend, grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}%`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              }, {
                barMaxWidth,
              },
            ],
          },
        },
      },
      tableData: [],
    };
  },
  created() {
    this.SysCheckYearData();
    this.getTreeData();
    this.getChartData1();
    this.getChartData2();
    this.getChartData3();
    this.getChartData4();
    this.getChartData5();
    this.getChartData6();
    this.getChartData7();
    this.getChartData8();
    this.getChartData9();
    this.getTableData();
  },
  methods: {
    showModal() {
      this.isView = true;
    },
    onYearChange(value) {
      if (value.length > 2) {
        let tem = value.slice(-1)[0];
        value.splice(1, value.length - 1, tem);
      }
    },
    onCheckChange(value) {
      if (value.length > 2) {
        this.selectValue.Value1.yearValue.shift();
      }
    },
    chart1Change() {
      if (this.selectValue.Value1.yearValue.length > 2) {
        this.$message.error("年份只能选择2年!");
        return false;
      }
      this.getChartData1();
      this.isView = false;
    },
    /*chartData1*/
    async getChartData1() {
      /* let res = await this.$Http.CountryInsure(
         {
           yearMonth: this.dataParams.ReportDate,
           parmId: this.parmId,
         },
       );
       if (!res.Data) {
         return false;
       }*/
      let chart = this.chartData.chartData1;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value1.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      let vl = {
        a: "完成率",
        b: "总数同比",
        c: "总数环比",
      };
      let area1 = this.selectValue.Value1.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value1.area2.replace("营销中心", "");
      this.selectValue.Value1.model.map(car => {
        chart.columns.push(car + area1);
        chart.setting.stack.val.push(car + area1);// 堆积图
        chart.columns.push(car + area2);
        chart.setting.stack.vau.push(car + area2);// 堆积图
      });
      Object.values(vl).map(l => {
        chart.columns.push(area1 + l);
        chart.columns.push(area2 + l);
        chart.setting.showLine.push(area1 + l);
        chart.setting.showLine.push(area2 + l);
        chart.setting.axisSite.right.push(area1 + l);
        chart.setting.axisSite.right.push(area2 + l);
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart2Change() {
      this.getChartData2();
    },
    async getChartData2() {
      /* let res = await this.$Http.CountryInsure(
      {
        yearMonth: this.dataParams.ReportDate,
        parmId: this.parmId,
      },
    );
    if (!res.Data) {
      return false;
    }*/
      let chart = this.chartData.chartData2;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value2.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      chart.setting.showLine = [];
      chart.setting.axisSite.right = [];
      let vl = {
        b: "提车总数环比",
        c: "提车总数同比",
        a: "目标完成率",
      };
      let area1 = this.selectValue.Value2.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value2.area2.replace("营销中心", "");
      Object.values(vl).map(l => {
        chart.columns.push(area2 + l);
        chart.columns.push(area1 + l);
        if (l !== "目标完成率") {
          chart.setting.showLine.push(area1 + l);
          chart.setting.showLine.push(area2 + l);
          chart.setting.axisSite.right.push(area1 + l);
          chart.setting.axisSite.right.push(area2 + l);
        } else {
          chart.setting.stack.val.push(area1 + l);
          chart.setting.stack.val.push(area2 + l);
        }
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart3Change() {
      this.getChartData3();
    },
    async getChartData3() {
      /* let res = await this.$Http.CountryInsure(
           {
             yearMonth: this.dataParams.ReportDate,
             parmId: this.parmId,
           },
         );
         if (!res.Data) {
           return false;
         }*/
      let chart = this.chartData.chartData3;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value3.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      let area1 = this.selectValue.Value3.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value3.area2.replace("营销中心", "");
      let carModel = this.selectValue.Value3.model;
      carModel.forEach(d => {
        chart.columns.push(d + area1);
        chart.columns.push(d + area2);
        chart.setting.stack.val.push(d + area1);
        chart.setting.stack.vau.push(d + area2);
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart4Change() {
      this.getChartData4();
    },
    async getChartData4() {
      /* let res = await this.$Http.CountryInsure(
         {
           yearMonth: this.dataParams.ReportDate,
           parmId: this.parmId,
         },
       );
       if (!res.Data) {
         return false;
       }*/
      let chart = this.chartData.chartData4;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value4.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      chart.setting.showLine = [];
      chart.setting.axisSite.right = [];
      let vl = {
        a: "订单总数",
        b: "目标达成率",
        c: "总数环比",
        d: "总数同比",
      };
      let area1 = this.selectValue.Value4.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value4.area2.replace("营销中心", "");

      Object.values(vl).map(l => {
        if (l === "订单总数") {
          this.selectValue.Value4.model.map(car => {
            chart.columns.push(car + area1 + l);
            chart.setting.stack.val.push(car + area1 + l);// 堆积图
            chart.columns.push(car + area2 + l);
            chart.setting.stack.vau.push(car + area2 + l);// 堆积图
          });
        } else {
          chart.columns.push(area1 + l);
          chart.columns.push(area2 + l);
          chart.setting.showLine.push(area1 + l);
          chart.setting.showLine.push(area2 + l);
          chart.setting.axisSite.right.push(area1 + l);
          chart.setting.axisSite.right.push(area2 + l);
        }
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart5Change() {
      this.getChartData5();
    },
    async getChartData5() {
      /* let res = await this.$Http.CountryInsure(
           {
             yearMonth: this.dataParams.ReportDate,
             parmId: this.parmId,
           },
         );
         if (!res.Data) {
           return false;
         }*/
      let chart = this.chartData.chartData5;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value5.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      let area1 = this.selectValue.Value5.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value5.area2.replace("营销中心", "");
      let carModel = this.selectValue.Value5.model;
      carModel.forEach(d => {
        chart.columns.push(d + area1);
        chart.columns.push(d + area2);
        chart.setting.stack.val.push(d + area1);
        chart.setting.stack.vau.push(d + area2);
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart6Change() {
      this.getChartData6();
    },
    async getChartData6() {
      /* let res = await this.$Http.CountryInsure(
         {
           yearMonth: this.dataParams.ReportDate,
           parmId: this.parmId,
         },
       );
       if (!res.Data) {
         return false;
       }*/
      let chart = this.chartData.chartData6;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value6.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      chart.setting.showLine = [];
      chart.setting.axisSite.right = [];
      let vl = {
        a: "终端总数",
        b: "目标达成率",
        c: "总数环比",
        d: "总数同比",
      };
      let area1 = this.selectValue.Value6.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value6.area2.replace("营销中心", "");

      Object.values(vl).map(l => {
        if (l === "终端总数") {
          this.selectValue.Value6.model.map(car => {
            chart.columns.push(car + area1 + l);
            chart.setting.stack.val.push(car + area1 + l);// 堆积图
            chart.columns.push(car + area2 + l);
            chart.setting.stack.vau.push(car + area2 + l);// 堆积图
          });
        } else {
          chart.columns.push(area1 + l);
          chart.columns.push(area2 + l);
          chart.setting.showLine.push(area1 + l);
          chart.setting.showLine.push(area2 + l);
          chart.setting.axisSite.right.push(area1 + l);
          chart.setting.axisSite.right.push(area2 + l);
        }
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart7Change() {
      this.getChartData7();
    },
    async getChartData7() {
      /* let res = await this.$Http.CountryInsure(
           {
             yearMonth: this.dataParams.ReportDate,
             parmId: this.parmId,
           },
         );
         if (!res.Data) {
           return false;
         }*/
      let chart = this.chartData.chartData7;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value7.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      let area1 = this.selectValue.Value7.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value7.area2.replace("营销中心", "");
      let carModel = this.selectValue.Value7.model;
      carModel.forEach(d => {
        chart.columns.push(d + area1);
        chart.columns.push(d + area2);
        chart.setting.stack.val.push(d + area1);
        chart.setting.stack.vau.push(d + area2);
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart8Change() {
      this.getChartData8();
    },
    async getChartData8() {
      /* let res = await this.$Http.CountryInsure(
         {
           yearMonth: this.dataParams.ReportDate,
           parmId: this.parmId,
         },
       );
       if (!res.Data) {
         return false;
       }*/
      let chart = this.chartData.chartData8;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value8.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      chart.setting.showLine = [];
      chart.setting.axisSite.right = [];
      let vl = {
        a: "库存总数",
        b: "目标达成率",
        c: "总数环比",
        d: "总数同比",
      };
      let area1 = this.selectValue.Value8.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value8.area2.replace("营销中心", "");

      Object.values(vl).map(l => {
        if (l === "库存总数") {
          this.selectValue.Value8.model.map(car => {
            chart.columns.push(car + area1 + l);
            chart.setting.stack.val.push(car + area1 + l);// 堆积图
            chart.columns.push(car + area2 + l);
            chart.setting.stack.vau.push(car + area2 + l);// 堆积图
          });
        } else {
          chart.columns.push(area1 + l);
          chart.columns.push(area2 + l);
          chart.setting.showLine.push(area1 + l);
          chart.setting.showLine.push(area2 + l);
          chart.setting.axisSite.right.push(area1 + l);
          chart.setting.axisSite.right.push(area2 + l);
        }
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    chart9Change() {
      this.getChartData9();
    },
    async getChartData9() {
      /* let res = await this.$Http.CountryInsure(
           {
             yearMonth: this.dataParams.ReportDate,
             parmId: this.parmId,
           },
         );
         if (!res.Data) {
           return false;
         }*/
      let chart = this.chartData.chartData9;
      let months = [];
      let yearV = this._.sortBy(this.selectValue.Value9.yearValue, function(num) {
        return num;
      });
      yearV.forEach(d => {
        for (let i = 1; i < 13; i++) {
          months.push(d + "-" + i.toString());
        }
      });
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.stack.vau = [];
      let area1 = this.selectValue.Value9.area1.replace("营销中心", "");
      let area2 = this.selectValue.Value9.area2.replace("营销中心", "");
      let carModel = this.selectValue.Value9.model;
      carModel.forEach(d => {
        chart.columns.push(d + area1);
        chart.columns.push(d + area2);
        chart.setting.stack.val.push(d + area1);
        chart.setting.stack.vau.push(d + area2);
      });
      let x = [];
      months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();
    },
    getTableData() {
      this.tableData = [];
      let car = [...this.checkData.car];
      car.push("合计");
      this.tableData = car.chain().map(d => {
        let arr = {};
        arr["carName"] = d;
        arr["one"] = this.randomData() + "台";
        arr["two"] = this.randomData() + "台";
        arr["there"] = this.randomData() + "台";
        arr["four"] = this.randomData() + "台";
        arr["five"] = this.randomData() + "台";
        return arr;
      }).value();
    },
    async getTreeData() {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Data) {
        this.$message.error(res.Message);
        return;
      }
      this.treeData[0].children = res.Data.chain().map(p => {
        let obj = {};
        obj.value = p.PartId;
        obj.key = p.PartId;
        obj.title = p.PartName;
        obj.children = p.Dealers.chain().map(d => {
          let childObj = {};
          childObj.value = d.DealerCode;
          childObj.key = d.DealerCode;
          childObj.title = d.DealerName;
          return childObj;
        }).value();
        return obj;
      }).value();
      this.DefaultTreeData.push(
        this.treeData[0].key,
      );
    },
    randomData() {
      return this._.random(0, 100);
    },
    SysCheckYearData() {
      let data1 = moment(this.dataParams.ReportDate).add(-4, "y");
      this.yearTreeData.year = underscore.range(4).map(() => data1.add(1, "y").format("y"));
      this.yearTreeData.year.reverse();
      Object.values(this.selectValue).forEach(d => {
        let data2 = moment(this.dataParams.ReportDate).add(-2, "y");
        d.yearValue = underscore.range(2).map(() => data2.add(1, "y").format("y"));
      });
      this.yearTreeData.data = this.yearTreeData.year.map(d => {
        let arr = {};
        arr.title = d;
        arr.value = d;
        arr.key = d;
        return arr;
      });
      this.checkData.data[0].children = this.checkData.car.map(d => {
        let arr = {};
        arr.title = d;
        arr.value = d;
        arr.key = d;
        return arr;
      });
    },
    area1Change(value, selectedOptions) {
      this.selectValue.Value1.area1 = selectedOptions.toString();
    },
    area2Change(value, selectedOptions) {
      this.selectValue.Value1.area2 = selectedOptions.toString();
    },
    chart2area1Change(value, selectedOptions) {
      this.selectValue.Value2.area1 = selectedOptions.toString();
    },
    chart2area2Change(value, selectedOptions) {
      this.selectValue.Value2.area2 = selectedOptions.toString();
    },
    chart3area1Change(value, selectedOptions) {
      this.selectValue.Value3.area1 = selectedOptions.toString();
    },
    chart3area2Change(value, selectedOptions) {
      this.selectValue.Value3.area2 = selectedOptions.toString();
    },
    chart4area1Change(value, selectedOptions) {
      this.selectValue.Value4.area1 = selectedOptions.toString();
    },
    chart4area2Change(value, selectedOptions) {
      this.selectValue.Value4.area2 = selectedOptions.toString();
    },
    chart5area1Change(value, selectedOptions) {
      this.selectValue.Value5.area1 = selectedOptions.toString();
    },
    chart5area2Change(value, selectedOptions) {
      this.selectValue.Value5.area2 = selectedOptions.toString();
    },
    chart6area1Change(value, selectedOptions) {
      this.selectValue.Value6.area1 = selectedOptions.toString();
    },
    chart6area2Change(value, selectedOptions) {
      this.selectValue.Value6.area2 = selectedOptions.toString();
    },
    chart7area1Change(value, selectedOptions) {
      this.selectValue.Value7.area1 = selectedOptions.toString();
    },
    chart7area2Change(value, selectedOptions) {
      this.selectValue.Value7.area2 = selectedOptions.toString();
    },
    chart8area1Change(value, selectedOptions) {
      this.selectValue.Value8.area1 = selectedOptions.toString();
    },
    chart8area2Change(value, selectedOptions) {
      this.selectValue.Value8.area2 = selectedOptions.toString();
    },
    chart9area1Change(value, selectedOptions) {
      this.selectValue.Value9.area1 = selectedOptions.toString();
    },
    chart9area2Change(value, selectedOptions) {
      this.selectValue.Value9.area2 = selectedOptions.toString();
    },
  },
  dataParams: {
    handler: function() {

    },
    deep: true,
  },
};
</script>

<style lang="less" scoped>
h2 {
  span {
    color: red;font-weight: 600;
    }
  }

.right {
  span {
    margin-left: 10px;
    }
  }

.bt_chart {
  padding-bottom: 20px;overflow: hidden;
  }

.carding {
  margin-top: 10px;
  }

.table_style {
  width: 100%;padding: 0;margin: 0;

  th {
    padding: 5px 0;background: #9B3D44;color: #fff;text-align: right;width: 16.6%;

    &:first-child {
      text-align: left;padding-left: 8px;
      }

    &:last-child {
      padding-right: 8px;
      }
    }

  tr {
    background: #fff;height: 30px;line-height: 30px;padding: 0;

    td {
      text-align: right;


      &:first-child {
        text-align: left;padding-left: 8px;
        }

      &:last-child {
        padding-right: 8px;
        }
      }

    &:nth-child(odd) {
      background: #FFEFF0;
      }

    &:hover {
      border-bottom: 1px solid #FFEFF0;background: #d4b8ba;
      box-shadow: 2px 4px 2px 2px rgba(78, 78, 78, 0.7);
      }
    }


  }

.modal-style {
  tr {
    padding: 5px 0;

    td {
      padding: 10px 0;

      &:first-child {
        width: 40px;
        }
      }


    }
  }

</style>
