<template>
    <section>
        <!--        <a-card>-->
        <!--            <a-form-->
        <!--                :form="form"-->
        <!--                layout="horizontal"-->
        <!--                :labelCol="{ span: 10 }"-->
        <!--                :wrapperCol="{ span: 14 }"-->
        <!--            >-->
        <!--                <a-row :gutter="24">-->
        <!--                    <a-col :span="6">-->
        <!--                        <a-form-item label="关键词">-->
        <!--                            <a-input placeholder="关键词" v-model="form.keyWords"/>-->
        <!--                        </a-form-item>-->
        <!--                    </a-col>-->
        <!--                    <a-col :span="4">-->
        <!--                        <a-form-item label="">-->
        <!--                            <a-button type="primary" @click="getList(1)"> 查询</a-button>-->
        <!--                        </a-form-item>-->
        <!--                    </a-col>-->
        <!--                </a-row>-->
        <!--            </a-form>-->
        <!--        </a-card>-->
        <a-card style="margin-top: 18px;">
            <a-table
                rowKey="Id"
                :columns="columns"
                :data-source="tableData"
            >
                <span slot="action" slot-scope="record">
                     <a-button size="small" type="primary" @click="playVideo(record)"
                     >播放</a-button
                     >
                </span>
            </a-table>
        </a-card>
        <a-modal v-model="visible" :title="modalTitle" :footer="null" :width="900" @cancel="handleCancel">
            <div>
                <video style="width: 100%" :src="videoURL" autoplay controls/>
            </div>
        </a-modal>
        <div class="foot-pad30"></div>
    </section>
</template>

<script>

const columns = [
    // {
    //     title: "序号",
    //     width: "60px",
    //     customRender: (text, record, index) => `${index + 1}`,
    // },
    {
        title: "视频名称",
        dataIndex: "VedioName",
        key: "VedioName",
    },
    {
        title: "创建时间",
        key: "CreateTime",
        dataIndex: "CreateTime",
    },
    {
        title: "操作",
        scopedSlots: {customRender: "action"},
        key: "action",
    },
];


export default {
    data() {
        return {
            visible:false,
            form: {
                //关键字
                keyWords: "",
                pageSize: 10,
                pageIndex: 1
            },
            //分页数据
            pageData: {},
            //表格数据
            tableData: [],
            columns,
            //分页参数
            paginationParam: {
                current: 1,
                total: 0,
                pageSize: 10,
            },
            videoURL: '',
            modalTitle:''

        };
    },
    methods: {
        handleCancel(){
            this.modalTitle = '';
            this.videoURL = ''
        },
        //获取列表
        async getList(current) {
            let self = this;
            let res = await self.$Http.GetVedioKpi();
            if (res.Success) {
                self.tableData = res.Data;
            } else {
                self.$message.error(res.Message);
            }
        },
        async playVideo(record) {
            let res = await this.$Http.FileShareLink({objectkey: record.ObjectKey});
            if (res.Success) {
                this.videoURL = res.Data;
                this.modalTitle = record.VedioName;
                this.visible = true;
            } else {
                self.$message.error(res.Message);
            }
        }
    },
    created() {
    },
    mounted() {
        this.getList(1);
    },
};
</script>
