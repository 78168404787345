<template>
    <section>
        <div class="sec" v-for="item in 3">
            <h1 class="title">改善行动计划执行-第{{ item }}个月</h1>
            <div class="part">
                <div class="table-title common-title">
                    <div>弱项KPI业务模块</div>
                    <div>弱项KPI名称</div>
                    <div>弱项KPI改善目标值</div>
                    <div>小组标杆值</div>
                    <div>弱项KPI第一个月值</div>
                </div>
                <div class="table-content common-content">
                    <div>弱项KPI业务模块</div>
                    <div>弱项KPI名称</div>
                    <div>弱项KPI改善目标值</div>
                    <div>小组标杆值</div>
                    <div>弱项KPI第一个月值</div>
                </div>
            </div>
            <div class="part">
                <div class="common-title part-title">
                    弱项KPI执行改善后现状描述
                </div>
                <div class="part-content common-content">
                    与上月相比有提升，但与目标相比差距仍较大
                </div>
            </div>
            <div class="part">
                <div class="part-title common-title">
                    实际已执行事项的细节概述？(本月具体干了什么)
                </div>
                <div class="part-content common-content">
                    1.针对各车型新车主开展车主座谈会，深入挖掘车主声音
                    <br>
                    2.加强e客服对新车主关怀，N+7、N+30对车主进行关怀，促进客户回厂
                    <br>
                    3.5K10K计入当月业绩考核，对明显服务客诉SA及技师进行处罚
                </div>
            </div>
            <div class="part">
                <div class="part-title common-title">
                    若存在未完成事项，请指出并说明原因？
                </div>
                <div class="part-content common-content">
                    无
                </div>
            </div>
            <div class="part">
                <div class="part-title common-title">
                    是否产生新课题？新课题的内容简述？
                </div>
                <div class="part-content common-content">
                    无
                </div>
            </div>
            <div class="part">
                <div class="part-title common-title">
                    改善文件上传
                </div>
                <div class="part-content common-content">
                    <a-upload-dragger class="uploadStyle" name="file" :showUploadList="false"
                                      :customRequest="handleUpload">
                        <p>将文件拖拽至此上传</p>
                        <div class="">请上传Excel格式文件</div>
                    </a-upload-dragger>
                </div>
            </div>
        </div>
        <div style="display: flex;justify-content: center;padding-bottom: 5vh">
            <a-button>
                取消
            </a-button>
            <a-button type="primary">
                保存
            </a-button>
            <a-button @click="$router.push('/improveplan/list')">
                返回列表
            </a-button>
        </div>
    </section>
</template>

<script>
export default {
    name: "form",
    data() {
        return {
            fileList:[]
        }
    },
    methods: {
        async handleUpload(file) {
            const formData = new FormData();
            formData.append("file", file.file);
            let res = await this.$Http.uploadFile(formData);
            if (res.Success) {
                this.$message.success(res.Data);
                setTimeout(() => {
                    window.open("ddataList", "_top");
                }, 1000);
            } else {
                if (res.Message === "object") {
                    this.$message.success("上传文件有错误，请查看！");
                    this.errorData = res.Data;
                    setTimeout(() => {
                        this.fileList.push({
                            name: file.file.name,
                        });
                    }, 1000);
                } else {
                    this.$message.error(res.Message);
                }
            }
        },
    }
}


</script>

<style lang="less" scoped>
.title {
    text-align: center;
    font-weight: bold;
}

.part {
    margin: 20px auto;
}

.common-title {
    background: #DDDDDD;
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
}

.common-content {
    border: #e8e8e8 2px solid;
    border-top: none;
}

.table-title {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.table-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
}

.part-title {
    padding-left: 30px;
}

.part-content {
    padding: 30px;
    display: flex;
    align-items: center;
}


.uploadStyle {
    width: 100%;
    div {
        border: 0 !important;
    }

    p {
        font-size: 24px;
        font-weight: 500;
        text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    }

    div:first-child {
        font-weight: 500;
        font-size: 14px;
        color: #999999;
        text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    }
}



</style>