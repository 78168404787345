<template>
  <a-row :gutter="[0,4]">
    <a-col :span="24" v-if="isCloseA">
      <a-anchor :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          <a-col :span="20">
            月份:
            <a-month-picker @change="handleReportChange" placeholder="请选择报告月份" v-model="ReportDateMoment"
                :disabledDate="disabledDate" style=" margin-right: 4px;width:110px" :allowClear="false"
            />
            区域:
            <a-select show-search @change="handleAreaChange" v-model="params.PartName" placeholder="请选择大区"
                style="width:130px;"
            >
              <a-select-option v-for="item in partList" :key="item.PartId" :value="item.PartName">
                {{ item.PartName }}
              </a-select-option>
            </a-select>
            <a-tooltip style="margin-left: 5px;" placement="top" title="是否去除新店或异常店?">
              异常店
              <a-icon type="info-circle"/>
              :
              <a-select @change="handleReportChange" v-model="params.copyType" style="width:50px;">
                <a-select-option :value="0">
                  否
                </a-select-option>
                <a-select-option :value="1">
                  是
                </a-select-option>
              </a-select>
            </a-tooltip>
          </a-col>
          <a-col style="float: right;">
            <span style="position: relative;float: right; top: -12px;margin-left:1vw" @click="isCloseA=false"><a-icon type="close"/></span>
            <a-tooltip placement="top" :title="`点击生成《${ moment(params.ReportDate).format('yyyy年MM月') }全国经营业绩分析报告-${views[activeKey].name}》`">
              <!--<a-button type="primary" @click="generateImages()">生成长图</a-button>-->
            </a-tooltip>
            <!--<a-button type="primary" @click="doPrint" style="float: right;">生成PDF</a-button>-->
          </a-col>
        </div>
      </a-anchor>
    </a-col>
    <a-col :span="24" style="min-width: 1080px">
      <div class="" ref="capture">
        <a-card>
          <div class="report_bg">
            BDM业绩分析报告 ({{ getNewName(views[activeKey].name) }}) <br>
            <span style="font-size: 22px;font-weight: 500"> 全国 {{ moment(params.ReportDate).format("yyyy年MM月") }}</span>
          </div>
          <a-descriptions bordered :column="3" style="padding-top: 30px">
            <a-descriptions-item label="销售店总数">{{ areaData.Total }}</a-descriptions-item>
            <a-descriptions-item label="数据使用店数">{{ areaData["DataSuccess"] }}</a-descriptions-item>
            <a-descriptions-item label="新店数量">{{ areaData["New"] }}</a-descriptions-item>
            <a-descriptions-item label="数据收集店数">{{ areaData["Used"] }}</a-descriptions-item>
            <a-descriptions-item label="数据未通过店数">{{ areaData["DataFail"] }}</a-descriptions-item>
            <a-descriptions-item label="异常店数量">{{ areaData["Exception"] }}</a-descriptions-item>
          </a-descriptions>
        </a-card>
        <div style="margin-top: 8px;">
          <Overview v-if="activeKey==='d082cc8d1'" :report-params="params" :area-data="areaData"></Overview>
          <variable v-else-if="activeKey==='8480fd2a2'" :report-params="params" :area-data="areaData"></variable>
          <regularize v-else-if="activeKey==='509aa1bd3'" :report-params="params" :area-data="areaData"></regularize>
          <property v-else-if="activeKey==='54b6730f4'" :report-params="params" :area-data="areaData"></property>
          <cost v-else-if="activeKey==='4f6acd6e5'" :report-params="params" :area-data="areaData"></cost>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import moment from "moment";
import Overview from "@/views/countryMonthReport/analysisReport/childComponent/Overview";
import variable from "@/views/countryMonthReport/analysisReport/childComponent/variable";
import regularize from "@/views/countryMonthReport/analysisReport/childComponent/regularize";
import property from "@/views/countryMonthReport/analysisReport/childComponent/property";
import cost from "@/views/countryMonthReport/analysisReport/childComponent/cost";
import chartComponent from "@/components/chartComponent";
import { disabledDate } from "@/until/dayFormat";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  components: { Overview, variable, regularize, property, cost, chartComponent },
  data() {
    return {
      moment,
      activeKey: "NONE",
      views: {
        "d082cc8d1": {
          name: "整体概览",
        },
        "8480fd2a2": {
          name: "销售业务板块",
        },
        "509aa1bd3": {
          name: "售后业务板块",
        },
        "54b6730f4": {
          name: "资产风险",
        },
        "4f6acd6e5": {
          name: "费用管控",
        },
      },
      chartColors: ["#2b821d", "#1890FF", "#FACC14", "#856aff", "#223273", "#8543E0", "#13C2C2", "#3436C7", "#F04864"],
      params: {
        ReportDate: "",
        PartName: "",
        copyType: 0,
        isClose: true,
      },
      partList: [],
      dealerList: [],
      isCloseA: true,
      chartData: {
        setting: {
          type: "histogram",
          legend: {
            show: false,
          },
          series: {
            //  type: 'bar',
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function(v) {
                  // let val = v.data;
                  return v.data + "台";
                },
                color: "#000",
              },
            },
          },

          // x轴的文字倾斜
          "xAxis.0.axisLabel.rotate": 45,
          yAxis: {
            name: "单位(台)",
            nameTextStyle: {
              padding: [0, 0, 0, 65],
            },
            /*   axisLabel: {
                 formatter: "{value}台",
               },*/
            //  show:false,
            axisTick: {       //y轴刻度线
              "show": true,
            },
            splitLine: {     //网格线
              "show": true,
            },
          },

          //数据过多时出现横向滚动条
          /* dataZoom: [
             {
               show: true,
               realtime: true,
               start: 0,
               end: 50,
             },
             {
               type: "inside",
               realtime: true,
               start: 0,
               end: 50,
             },
           ],*/

        },
        data: {
          columns: ["日期", "访问用户"],
          rows: [
            { 日期: "本月(N)值", 访问用户: 243.8 },
            { 日期: "(N-1)月值", 访问用户: 250.4 },
            { 日期: "(N-2)月值", 访问用户: 192.1 },
            { 日期: "(N-3)月值", 访问用户: 248.6 },
          ],
        },
      },
      extend: {
        legend: {
          show: false,
        },
        // x轴的文字倾斜
        "xAxis.0.axisLabel.rotate": 45,
        yAxis: {
          //是否显示y轴线条
          axisLine: {
            show: false,
          },
          // 纵坐标网格线设置，同理横坐标
          splitLine: {
            show: false,
          },

        },
        xAxis: {
          show: false,
        },
        series: {

          //  type: 'bar',
          label: {

            normal: {
              show: true,
              position: "right",
              formatter: function(v) {
                // let val = v.data;
                return v.data + "万元";
              },
              color: "#000",
            },
          },
        },

      },
      areaData: {},
    };
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
  watch: {
    "$route"(to, from) {
      if(this.$route.query.key) {
        this.activeKey = this.$route.query.key;
      }
    },
  },
  created() {
    let key = this.$route.query.key;
    let view = this.views[key];
    if(!view) {
      this.$message.error("请求参数有误，请联系管理员处理");
      return;
    }
    this.activeKey = key;
    this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
    this.getPart();
    this.GetDealerInputStatistics();
  },
  methods: {
    getNewName(val) {
      if(val === "销售业务板块") {
        return "销售-业务板块";
      }else if(val === "售后业务板块"){
        return "售后-业务板块";
      }else {
        return val;
      }
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      this.params.isClose = false;
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, `${moment(this.params.ReportDate).format("yyyy年MM月")}全国经营业绩分析报告-${this.getTitle(this.activeKey)}.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${moment(this.params.ReportDate).format("yyyy年MM月")}全国经营业绩分析报告-${this.getTitle(this.activeKey)}.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
    async handleReportChange() {
      await this.GetDealerInputStatistics();
    },
    handleAreaChange() {
      this.$router.push(`/RegionReport?key=${this.activeKey}&ReportDate=${this.params.ReportDate}&PartName=${this.params.PartName}`);
    },
    async getPart() {
      let self = this;
      let res = await self.$Http.GetPartListByPage({
        pageSize: 100,
        pageIndex: 1,
      });
      if(res.Data.length > 0) {
        self.partList = res.Data;
        //添加一个全国的选项
        self.partList.unshift({ PartId: "", PartName: "全国" });
        self.params.PartName = self.partList[0].PartName;
      }
    },
    async GetDealerInputStatistics() {
      let self = this;
      let res = await self.$Http.GetDealerInputStatistics(this.params);
      if(!res) {
        return;
      }
      self.areaData = res.Data;
    },
    // 点击打印双预览
    async doPrint() {
      let res = await this.$Http.PdfGenerateByUrl({
        Url: `${window.location.origin}/countryMonthReport/print2?ReportDate=${this.params.ReportDate}&PartName=${this.params.PartName}`,
        FileName: `${this.ReportDateMoment.format("YYYY年MM月")}${this.params.PartName}经营分析报告`,
        PageWidth: 16.54,
        PageHeight: 11.7,
        Cookie: document.cookie,
      });
      if(res.isError) {
        this.$message.error(res.errMsg);
        return;
      }
      let a = document.createElement("a");
      a.href = res.data.pdfUrl;
      a.target = "_blank";
      a.click();
    },
    setActionKey(key) {
      this.activeKey = key;
    },
    disabledDate,
  },
};
</script>

<style lang="less" scoped>
.fix_border {
  padding: 20px 10px 20px 10px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  margin-left: -2px;
  }

.report_bg {
  height: 136px;padding-top: 42px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  }

.info {
  margin: 12px auto;
  background: #E6F7FF;
  padding: 24px 22px;
  border: 1px solid #BAE7FF;
  }

.pai-ming {
  padding-top: 20px;
  margin-right: 40px;
  color: #24619e;
  font-size: 30px;

  em {
    font-size: 50px;
    }

  }

.zhi-bio {
  ul {
    li {
      float: left;
      text-align: center;

      > div {
        border: 1px solid #dddddd;
        height: 88px;
        padding: 14px;
        background: rgba(242, 245, 248, 0.6);

        div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 4px solid rgba(67, 167, 16, 0.3);

          span {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #43A710;
            }
          }
        }

      > p {
        height: 25px;
        line-height: 25px;
        background: #e8e8e8;
        border: 1px solid #dddddd;
        }

      &:nth-child(2) {
        > div {
          div {
            border: 4px solid rgba(88, 163, 247, 0.3);

            span {
              background: #58A3F7;
              }
            }
          }
        }

      &:nth-child(3) {
        > div {
          div {
            border: 4px solid rgba(255, 197, 66, 0.3);

            span {
              background: #FFC542;
              }
            }
          }
        }

      &:nth-child(4) {
        > div {
          div {
            border: 4px solid rgba(253, 89, 90, 0.3);

            span {
              background: #FD595A;
              }
            }
          }
        }

      /*选中状态*/

      &:nth-child(1).hover {
        > div {
          border: 1px solid #43A710;
          }

        > p {
          background: #43A710;
          color: #ffffff;
          border: 1px solid #43A710;
          }
        }

      &:nth-child(2).hover {
        > div {
          border: 1px solid #58A3F7;
          }

        > p {
          background: #58A3F7;
          color: #ffffff;
          border: 1px solid #58A3F7;
          }
        }

      &:nth-child(3).hover {
        > div {
          border: 1px solid #FFC542;
          }

        > p {
          background: #FFC542;
          color: #ffffff;
          border: 1px solid #FFC542;
          }
        }

      &:nth-child(4).hover {
        > div {
          border: 1px solid #FD595A;
          }

        > p {
          background: #FD595A;
          color: #ffffff;
          border: 1px solid #FD595A;
          }
        }
      }
    }
  }

.space {
  margin-left: 20px;

  .ant-tag {
    cursor: pointer;
    padding: 4px 6px;
    }
  }

::v-deep {
  .ant-card-body {
    padding: 5px 25px 15px 25px;
    }

  .ant-tabs-nav-scroll {
    background: #ffffff;
    padding-bottom: 5px;
    }

  .ant-tabs-bar {
    margin-bottom: 1px !important;
    }

  .ant-card-bordered {
    border-top: 0;
    }

  .ant-anchor-ink {
    height: 0;
    }

  /* .ant-anchor-wrapper{
     margin-left: 0;
     padding-left: 0;
   }*/

  }

.gutter-row {
  //  text-align: center;

  > dl {
    padding-left: 10px;

    img {
      display: block;
      float: left;
      width: 18px;
      height: 18px;
      }

    span {
      float: left;
      display: block;
      line-height: 18px;
      padding-left: 5px;
      font-weight: 500;
      color: #666666;
      }
    }

  p {
    text-indent: 2em;
    clear: both;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    padding-top: 9px;
    }

  &-title {

    font-size: 16px;
    font-weight: 500;
    color: #333333;

    &-two {
      padding-top: 4px;
      padding-bottom: 34px;
      font-weight: 400;
      color: #999999;
      font-size: 14px;
      }

    &-blue {
      padding-top: 6px;
      font-weight: 500;
      color: #58a3f7;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      span {
        font-size: 36px;
        }
      }

    &-chart {

      text-align: center;

      .chart-dashed {
        div {
          float: left;

          &:first-child {
            width: 100px;
            }

          &:last-child {
            width: 350px;
            }

          }

        }
      }
    }


  }

::v-deep {

  .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #b9d0d0 !important;
    }

  .ant-descriptions-bordered .ant-descriptions-view {
    border-top: 1px solid #b9d0d0 !important;
    border-left: 1px solid #b9d0d0 !important;
    }

  .ant-descriptions-bordered .ant-descriptions-item-label {
    background: #E6ECEC !important;
    }

  .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: 1px solid #b9d0d0 !important;
    }
  }
</style>
