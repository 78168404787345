const DIAGNOSISREPORT_API = {
    //获取单店分数
    GetDiagnoseScore: {
        method: 'get',
        url: '/DealerKpi/KpiData/GetDiagnoseScore'
    },

    //分数和指标数据
    GetDiagnoseData: {
        method: 'get',
        url: '/DealerKpi/KpiData/GetDiagnoseData'
    },

    //诊断报告标杆值数据
    GetDiagnoseBG: {
        method: 'get',
        url: '/DealerKpi/KpiData/GetDiagnoseBG'
    },

    //获取小组列表
    // GetDealerGroup: {
    //     method: 'get',
    //     url: '/DealerKpi/KpiData/GetDealerGroup'
    // },

    //小组ID获取店
    // GetDealerByGroup: {
    //     method: 'get',
    //     url: '/DealerKpi/KpiData/GetDealerByGroup'
    // },

    //获取大区和销售店
    GetPartList: {
        method: 'get',
        url: '/DealerKpi/KpiData/GetPartList'
    },

    //获取销售店基础信息
    GetDealerInfo: {
        method: 'get',
        url: '/DealerKpi/KpiData/GetDealerInfo'
    },

    //根据指标查询区域全国排名
    RankByCodes: {
        method: 'post',
        url: '/DealerKpi/SecondBDM/RankByCodes'
    },


}
export default DIAGNOSISREPORT_API
