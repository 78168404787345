import {
    barMaxWidth,
    settingsLabel,
     yAxis,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import {
  xAxis,grid,
  seriesBar,
  seriesLine,
  yAxisDefault, yAxisRight, yAxisTenThousandYuan, yAxisRightUnit,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";

function toolFun(params, unit) {
  let arr = {};
  params.forEach((d, i) => {
    if(d.componentSubType === "bar") {
      if(unit === "万元") {
        arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${unit}`;
      } else if(unit === "%") {
        arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
      } else {
        arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${unit}`;
      }
    }
    if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
    }
  });
  let re = params[0].name + "<br />";
  Object.values(arr).forEach(a => {
    re += a + "<br />";
  });
  return re;
}

function labelFormat(params, unit) {
  if(params["componentSubType"] === "line") {
    return percentage(params.data);
  } else {
    if(unit === "万元") return tenThousandYuan(params.data);
    if(unit === "%") return percentage(params.data);
    return ThousandYuan(params.data);
  }
}

const oneData = {
    tableData2: {
        table: [
            { name: "总毛利率", kpiCode: "C0407004", IndexRange: "3.5%~8.0%" },
            { name: "销售业务综合毛利率", kpiCode: "A0107002", IndexRange: "3.0%~8.0%" },
            { name: "单车销售业务综合毛利", kpiCode: "A0107004", indentation: 1, IndexRange: "2500~6500" },
            { name: "售后业务综合毛利率", kpiCode: "B0106026", IndexRange: "35.0%~55.0%" },
            { name: "单车售后业务综合毛利", kpiCode: "B0106027", indentation: 1, IndexRange: "350~800" },
            { name: "总费用率", kpiCode: "C0207022", IndexRange: "3.6%~5.6%" },
            { name: "人力费用率", kpiCode: "C0207028", indentation: 1, IndexRange: "-" },
            { name: "净利润率", kpiCode: "C0408019", IndexRange: "0.0%~3.0%" },
        ],
        columns: [],
        data: [],
    },
    tableData3: {
        table: [
            { name: "税前利润", kpiCode: "C0408004", ut: "1", IndexRange: "-" },
            { name: "净资产收益率（年化）", kpiCode: "C0408024", IndexRange: "12.0%~30.0%" },
            { name: "总资产周转率（年化）", kpiCode: "C0107006", IndexRange: ">2.0" },
            { name: "新车库存周转率（年化）", kpiCode: "A0404002", IndexRange: ">12.0" },
            { name: "新车库存周转天数", kpiCode: "A0404001", IndexRange: "接近30" },
            { name: "资产负债率", kpiCode: "C0107003", IndexRange: "40.0%~70.0%" },
            { name: "流动比率", kpiCode: "C0106003", IndexRange: ">2.0" },
            { name: "速动比率", kpiCode: "C0107002", IndexRange: ">1.0" },
        ],
        columns: [
            {
                key: "iFiction",
                title: "指标分类",
                customRender: (value, row, index) => {
                    let obj = {
                        children: "",
                        attrs: {},
                    };
                    switch (index) {
                        case 0:
                            obj = {
                                children: "盈利能力",
                                attrs: { rowSpan: 2 },
                            };
                            break;
                        case 2:
                            obj = {
                                children: "运营能力",
                                attrs: { rowSpan: 3 },
                            };
                            break;
                        case 5:
                            obj = {
                                children: "偿债能力",
                                attrs: { rowSpan: 3 },
                            };
                            break;
                        default:
                            obj.attrs.colSpan = 0;

                    }
                    return obj;
                },
            },
            {
                key: "name", title: "指标名称", dataIndex: "name",
                customRender: (text, record) => {
                    if (record.indentation > 0) {
                        return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
                    }
                    return text;
                },
            },
            { key: "Unit", title: "单位", dataIndex: "Unit" },
            {
                key: "Current",
                title: "当月值",
                dataIndex: "Current",
                customRender: (text, record) => {
                    let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
                    if (record.PercentileSort === "DESC") flag = !flag;
                    if (flag) {
                        return <div style="color:red;">{text} ↓ </div>;
                    } else {
                        return <div style="color:green;">{text} ↑</div>;
                    }
                },
            },
            { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
            { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
            { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
            { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
            { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
            { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
        ],
        data: [],
    },
    tableData4: {
        table: [
            { name: "人力费用", kpiCode: "C0203020", ut: "1" },
            { name: "固定费用", kpiCode: "C0205006", ut: "1" },
            { name: "其中—租金", kpiCode: "C0202025", ut: "1", indentation: 2 },
            { name: "其中—折旧与摊销", kpiCode: "C0202026", ut: "1", indentation: 2 },
            { name: "半固定费用", kpiCode: "C0205010", ut: "1" },
            { name: "变动费用", kpiCode: "C0205005", ut: "1" },
            { name: "财务费用", kpiCode: "C0205021", ut: "1" },
            { name: "销售部门财务费用", kpiCode: "A0204008", ut: "1" },
            { name: "其中-厂家融资贴息", kpiCode: "A0202039", ut: "1", indentation: 2 },
            { name: "单车财务费用", kpiCode: "A0205035" },
            { name: "销售业务广宣活动费用", kpiCode: "A0202007", ut: "1" },
            { name: "其中-厂家营销活动返利", kpiCode: "A0202043", ut: "1", indentation: 2 },
            { name: "单车广宣活动费", kpiCode: "A0205034" },
            { name: "垂直网站费用", kpiCode: "C0202262", ut: "1" },
            { name: "其中—厂家垂直网站支援金", kpiCode: "C0202263", ut: "1", indentation: 2 },
            { name: "销售顾问工资奖金", kpiCode: "A0202024", ut: "1" },
            { name: "单车销售顾问工资奖金", kpiCode: "A0204032" },
        ],
    },
    chartData: {
        chartData1: {
            columns: ["日期", "税前利润", "税前利润率"],
            kpiCode: [
                { Code: "C0408004", Name: "税前利润" },
                //     { Code: "C0408004", Name: "主营业务税前利润全国标杆" },
                { Code: "C0408021", Name: "税前利润率" },
                //    { Code: "C0408021", Name: "主营业务税前利润率全国标杆" },
            ],
            rows: [
                /*  { "日期": "1月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "2月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "3月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "4月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "5月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "6月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "7月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "8月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "9月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "10月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "11月", "主营业务税前利润": "0", "主营业务税前利润率": null },
                  { "日期": "12月", "主营业务税前利润": "0", "主营业务税前利润率": null },*/
            ],
            settings: {
                type: "histogram",
                // stack: { all: ["访问用户", "下单用户"] },
                showLine: ["税前利润率"],
                axisSite: { right: ["税前利润率"] },
                yAxisType: ["0.[00]", "0.[0]%"],
                yAxisName:["万元","%"],
              label: {
                show: true, // 显示数字
                position: "inside",
                textStyle: {
                  color: "black",
                },
                formatter: function(params) {
                  return labelFormat(params, "万元");
                },
              },
            },
            options: {
                tooltip: {
                    show: true,
                    trigger: "axis",
                    formatter: function(params) {
                        if (params[0].value === undefined || params[1].value === undefined) {
                            return `${params[0]["axisValue"]} <br /> ${params[0]["seriesName"]}: - <br /> ${params[1]["seriesName"]}: -`;
                        }
                        let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>主营业务税前利润: " + (params[0].value / 10000).toFixed(2) + "万元",
                            val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>主营业务税前利润率： " + (params[1].value * 100).toFixed(1) + "%";
                        return `${params[0]["axisValue"]} <br /> ${val1} <br />${val2}`;
                    },
                },
                yAxis:[yAxisTenThousandYuan,yAxisRightUnit('%')],
                xAxis,
                series: [seriesBar,seriesLine],
            },
        },
        chartData3: {
            columns: ["日期", "前后端产值比", "前后端产值比全国标杆", "零服吸收率", "零服吸收率全国标杆"],
            kpiCode: [
                { Code: "C0305011", Name: "前后端产值比" },
                { Code: "C0305011", Name: "前后端产值比全国标杆" },
                { Code: "B0107002", Name: "零服吸收率" },
                { Code: "B0107002", Name: "零服吸收率全国标杆" },
            ],
            rows: [],
        },
        chartData4: {
            columns: ["日期", "总费用", "总费用率"],
            kpiCode: [
                { Code: "C0206001", Name: "总费用" },
                { Code: "C0207022", Name: "总费用率" },
            ],
            rows: [],
        },
        chartData5: {
            columns: ["日期", "人力费用", "人力费用率"],
            kpiCode: [
                { Code: "C0203020", Name: "人力费用" },
                { Code: "C0207028", Name: "人力费用率" },
            ],
            rows: [],
        },
        chartData6: {
            columns: ["日期", "人力费用", "人力费用率"],
            kpiCode: [
                { Code: "A0202025", Name: "人力费用" },
                { Code: "C0207029", Name: "人力费用率" },
            ],
            rows: [],
        },
        chartData7: {
            columns: ["日期", "人均销量", "人均生产力"],
            kpiCode: [
                { Code: "A0304034", Name: "人均销量" },
                { Code: "A0107014", Name: "人均生产力" },
            ],
            rows: [],
        },
    },
    /*销售业务*/
    salesTable: {
        tableData1: {
            table: [
                { name: "销售业务综合收入", kpiCode: "A0104090", ut: "1" },
                { name: "新车销售裸车收入", kpiCode: "A0103001", ut: "1", indentation: 1 },
                { name: "商务政策返利", kpiCode: "A0104044", ut: "1", indentation: 1 },
                { name: "新车销售水平事业收入", kpiCode: "A0103024", ut: "1", indentation: 1 },
                { name: "销售业务综合毛利", kpiCode: "A0106024", ut: "1" },
                { name: "新车销售裸车毛利", kpiCode: "A0105001", ut: "1", indentation: 1 },
                { name: "新车销售返利后毛利", kpiCode: "A0106001", ut: "1", indentation: 1 },
                { name: "销售水平事业毛利", kpiCode: "A0104067", ut: "1", indentation: 1 },
                { name: "单车销售综合毛利", kpiCode: "A0107004", IndexRange: "3000~8000" },
                { name: "销售综合毛利率", kpiCode: "A0107002", IndexRange: "3.0%~8.0%" },
                { name: "其中-祼车毛利率", kpiCode: "A0106023", IndexRange: "-4.0%~2.0%", indentation: 1 },
                { name: "其中-返利后毛利率", kpiCode: "A0107001", IndexRange: "0.0%~6.0%", indentation: 1 },
            ],
        },
        tableData2: {
            table: [
                { name: "展厅终端占比", kpiCode: "A0304024" },
                { name: "展厅单人效率", kpiCode: "A0303200", indentation: 2 },
                { name: "IDCC终端占比", kpiCode: "A0304025" },
                { name: "IDCC单人效率", kpiCode: "A0303201", indentation: 2 },
                { name: "二网终端占比", kpiCode: "A0304026" },
                { name: "大客户终端占比", kpiCode: "A0304027" },
                { name: "大车占比", kpiCode: "A0304151" },
                { name: "保客相关销量占比", kpiCode: "A0304030" },
            ],
        },
        chartData: {
            chartData1: {
                columns: ["日期", "单车裸车毛利", "单车商政返利", "单车水平事业毛利", "销售综合毛利率"],
                kpiCode: [
                    { Code: "A0106026", Name: "单车裸车毛利" },
                    { Code: "A0105071", Name: "单车商政返利" },
                    { Code: "A0105072", Name: "单车水平事业毛利" },
                    { Code: "A0107002", Name: "销售综合毛利率" },
                ],
            },
            chartData3: {
                columns: ["日期", "二网销量", "二网销量占比"],
                kpiCode: [
                    { Code: "A0302023", Name: "二网销量" },
                    { Code: "A0304026", Name: "二网销量占比" },
                ],
            },
            chartData4: {
                columns: ["日期", "人均销量", "人均生产力"],
                kpiCode: [
                    { Code: "A0304034", Name: "人均销量" },
                    { Code: "A0107014", Name: "人均生产力" },
                ],
            },
            chartData5: {
                columns: ["日期", "单车新车裸车毛利", "单车商政返利", "单车水平事业毛利"],
                kpiCode: [
                    { Code: "A0106026", Name: "单车新车裸车毛利" },
                    { Code: "A0105071", Name: "单车商政返利" },
                    { Code: "A0105072", Name: "单车水平事业毛利" },
                ],
            },
            chartData6: {
                columns: ["日期", "库销比"],
                kpiCode: [
                    { Code: "A0404045", Name: "库销比" },
                ],
            },
        },
        chartCarData: {
            chartData_1: {
                columns: ["car", "各车型终端销量", "各车型销量占比"],
                rows: [
                    {
                        car: "GS3系列",
                        各车型终端销量: 0,
                        各车型销量占比: 0,
                        kpiCode: ["A0303311", "A0303312"],
                        percentageCode: ["A0304004", "A0304005"],
                    },
                    {
                        car: "GS4系列",
                        各车型终端销量: 0,
                        各车型销量占比: 0,
                        kpiCode: ["A0303313", "A0303314", "A0303315"],
                        percentageCode: ["A0304006", "A0304007", "A0304008"],
                    },
                    { car: "GS4PLUS", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303316"], percentageCode: ["A0304009"] },
                    { car: "影豹系列", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303325"], percentageCode: ["A0304018"] },
                    {
                        car: "GS8系列",
                        各车型终端销量: 0,
                        各车型销量占比: 0,
                        kpiCode: ["A0303318", "A0303319"],
                        percentageCode: ["A0304011", "A0304012"],
                    },
                    { car: "M6系列", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303320"], percentageCode: ["A0304013"] },
                    { car: "M8系列", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303321"], percentageCode: ["A0304014"] },
                    { car: "GA4系列", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303322"], percentageCode: ["A0304015"] },
                    { car: "GA6系列", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303323"], percentageCode: ["A0304016"] },
                    { car: "GA8系列", 各车型终端销量: 0, 各车型销量占比: 0, kpiCode: ["A0303324"], percentageCode: ["A0304017"] },
                ],
            },
            chartData_2: {
                columns: ["car", "0~30天期末新车库存", "31~60天期末新车库存", "61~90天期末新车库存", ">90天期末新车库存", "期末库存台次占期末新车库存台次比"],
                rows: [
                    {
                        car: "GS3系列", "0~30天期末新车库存": 0,
                        "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402013", "A0402019"],
                        percentageCode: ["A0402014", "A0402020"],
                        sixCode: ["A0402015", "A0402021"],
                        nineCode: ["A0402016", "A0402022"],
                        expirationCode: ["A0404061", "A0404062"],
                    },
                    {
                        car: "GS4系列",
                        "0~30天期末新车库存": 0,
                        "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402025", "A0402031", "A0402037"],
                        percentageCode: ["A0402026", "A0402032", "A0402038"],
                        sixCode: ["A0402027", "A0402033", "A0402039"],
                        nineCode: ["A0402028", "A0402034", "A0402040"],
                        expirationCode: ["A0404063", "A0404064", "A0404065"],
                    },
                    {
                        car: "GS4 PLUS",
                        "0~30天期末新车库存": 0,
                        "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402043"],
                        percentageCode: ["A0402044"],
                        sixCode: ["A0402045"],
                        nineCode: ["A0402046"],
                        expirationCode: ["A0404066"],
                    },
                    {
                        car: "影豹系列", "0~30天期末新车库存": 0, "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: [""],
                        percentageCode: [""],
                        sixCode: [""],
                        nineCode: [""],
                        expirationCode: [""],
                    },// todo  无CODE
                    {
                        car: "GS8系列",
                        "0~30天期末新车库存": 0,
                        "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402055", "A0402061"],
                        percentageCode: ["A0402056", "A0402062"],
                        sixCode: ["A0402057", "A0402063"],
                        nineCode: ["A0402058", "A0402064"],
                        expirationCode: ["A0404068", "A0404069"],
                    },
                    {
                        car: "M6系列", "0~30天期末新车库存": 0, "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402116"],
                        percentageCode: ["A0402117"],
                        sixCode: ["A0402118"],
                        nineCode: ["A0402119"],
                        expirationCode: ["A0404070"],
                    },
                    {
                        car: "M8系列", "0~30天期末新车库存": 0, "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402122"],
                        percentageCode: ["A0402123"],
                        sixCode: ["A0402124"],
                        nineCode: ["A0402125"],
                        expirationCode: ["A0404071"],
                    },
                    {
                        car: "GA4系列", "0~30天期末新车库存": 0, "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402128"],
                        percentageCode: ["A0402129"],
                        sixCode: ["A0402130"],
                        nineCode: ["A0402131"],
                        expirationCode: ["A0404072"],
                    },
                    {
                        car: "GA6系列", "0~30天期末新车库存": 0, "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402134"],
                        percentageCode: ["A0402135"],
                        sixCode: ["A0402136"],
                        nineCode: ["A0402137"],
                        expirationCode: ["A0404073"],
                    },
                    {
                        car: "GA8系列", "0~30天期末新车库存": 0, "31~60天期末新车库存": 0,
                        "61~90天期末新车库存": 0,
                        ">90天期末新车库存": 0,
                        "期末库存台次占期末新车库存台次比": 0,
                        kpiCode: ["A0402148"],
                        percentageCode: ["A0402149"],
                        sixCode: ["A0402150"],
                        nineCode: ["A0402171"],
                        expirationCode: ["A0404074"],
                    },
                ],
            },
        },
    },
    afterData: {
        chartData: {
            chartData3: {
                columns: ["日期", "售后水平事业收入", "售后水平事业毛利", "售后水平事业毛利率"],
                kpiCode: [
                    { Code: "B0103099", Name: "售后水平事业收入" },
                    { Code: "B0104187", Name: "售后水平事业毛利" },
                    { Code: "B0105108", Name: "售后水平事业毛利率" },
                ],
            },
            chartData4: {
                columns: ["日期", "单车售后产值", "单车零部件毛利", "单车工时毛利", "售后维修业务毛利率"],
                kpiCode: [
                    { Code: "B0104226", Name: "单车售后产值" },
                    { Code: "B0105132", Name: "单车零部件毛利" },
                    { Code: "B0105161", Name: "单车工时毛利" },
                    { Code: "B0105181", Name: "售后维修业务毛利率" },
                ],
            },
            chartData5: {
                columns: ["日期", "A类客户数", "B类客户数", "C类客户数", "新销售未回厂数"],
                kpiCode: [
                    { Code: "B0302034", Name: "A类客户数" },
                    { Code: "B0302035", Name: "B类客户数" },
                    { Code: "B0302036", Name: "C类客户数" },
                    { Code: "B0302039", Name: "新销售未回厂数" },
                ],
            },
            chartData6: {
                columns: ["日期", "连续两年都来店客户数", "客户维系率"],
                kpiCode: [
                    { Code: "B0302047", Name: "连续两年都来店客户数" },
                    { Code: "B0303024", Name: "客户维系率" },
                ],
            },
        },
    },

};

const fontSize = 22;
const lineStyle = {
    width: 8,
};
const symbolSize = 20;
const itemStyle = {
    borderWidth: 1,
    // backgroundColor: "#000",
    //  borderColor: "#000",
    // color: "#000",
};
const textStyle = {
    fontSize, color: "black",
};
const PrintyAxis = [
    {
        type: "value",
        name: "万元",
        nameTextStyle: {
            fontSize,
        },
        splitLine: {
            show: true,
            lineStyle: {
                type: "dashed",
            },
        },
        axisLabel: {
            fontSize,
            formatter: (d) => {
                return Number(d / 10000).toFixed(0);
            },
        },
    },
    {
        type: "value",
        name: "%",
        nameTextStyle: {
            fontSize,
        },
        axisLabel: {
            fontSize,
            formatter: (d) => {
                return Number(d * 100).toFixed(0);
            },
        },
    },
];

const PrintXAxis = {
    axisLabel: {
        textStyle: {
            fontSize, color: "black",
        },
        interval: 0,
    },
    axisLine: {
        show: true,
    },
    // X轴刻度
    /* axisTick: {
        show:true
    }*/
};
export {
    oneData, fontSize, lineStyle, textStyle, symbolSize, itemStyle, PrintyAxis, PrintXAxis,
};
