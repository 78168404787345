<template>
    <a-row :gutter="[18,18]" class="mHeight">
        <a-col :span="8">
            <div class="con_title">一、利润表</div>
            <a-row :gutter="[18,0]">
                <a-col class="incomeStatement" :span="6" v-for="(item,i) in descriptionsItem" :key="i">
                    <div>{{ item.title }}: <span>{{ item.value }}</span></div>
                </a-col>
                <a-col :span="24" class="margin10">
                        <a-my-table :columns-data="tableData1.columns" :table-data="tableData1" class="borderQ" style="overflow:hidden;height: 664px;"></a-my-table>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">二、销售业务盈利情况</div>
                    <chartComponent class=" borderF" :chart-data="chartData.chartData1" :chart-height="'570px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">三、四大通道效率</div>
                    <a-my-table :columns-data="tableData2.columns" :table-data="tableData2" class="borderQ" style="overflow:hidden;height: 660px;"></a-my-table>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">各渠道终端实绩</div>
                    <chartComponent class="borderF" :chart-data="chartData.chartData2" :chart-height="'574px'"></chartComponent>
                </a-col>
            </a-row>
        </a-col>
        <a-col :span="8">
            <div class="con_title">四、展厅与iDCC成交率分析</div>
            <a-row :gutter="[18,0]">
                <a-col :span="24">
                        <a-my-table :columns-data="tableData3.columns" :table-data="tableData3" style="position: relative;top: -1px;height: 755px;" class="borderQ"></a-my-table>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="borderQ" style="overflow:hidden;">
                        <a-col :span="12">
                            <h2>展厅漏斗</h2>
                            <chartComponent :chart-data="funnelData.funnelData1" :chart-height="'548px'"/>
                        </a-col>
                        <a-col :span="12">
                            <h2>IDCC漏斗</h2>
                            <chartComponent :chart-data="funnelData.funnelData2" :chart-height="'548px'"/>
                        </a-col>
                    </div>
                </a-col>
                <a-col :span="24">
                    <div class="con_title margin10">五、二网效率</div>
                    <div class=" borderF" style="overflow: hidden;padding-top: 0;height: 663px">
                        <div class="descriptionsItem5">
                            <ul>
                                <li v-for="(item,i) of descriptionsItem1" :key="i++"><p>{{ item.title }} <span>{{
                                        item.value }}</span>
                                </p></li>
                            </ul>
                        </div>
                        <h2 style="font-size: 22px;text-indent: 1em;">二网销量及销量占比</h2>
                        <chartComponent :chart-height="'560px'" :chart-data="chartData.chartData3"></chartComponent>
                    </div>
                </a-col>
                <a-col :span="24">
                    <div class="con_title margin10">六、人员效率</div>
                    <div class=" borderF" style="overflow: hidden;padding-top: 0;height: 610px">
                        <a-row :gutter="[18,0]">
                            <a-col class="incomeStatement" :span="8" v-for="(item,i) in descriptionsItem2" :key="i">
                                <div>{{ item.title }}: <span>{{ item.value }}</span></div>
                            </a-col>
                        </a-row>
                        <h2 style="font-size: 22px;margin-top:10px;text-indent: 1em;">销售顾问人均生产力及人均效率</h2>
                        <chartComponent :chart-height="'510px'" :chart-data="chartData.chartData4"></chartComponent>
                    </div>
                </a-col>
            </a-row>
        </a-col>
        <a-col :span="8">
            <a-row :gutter="[18,0]">
                <a-col :span="24">
                    <div class="con_title">七、、各车型销售结构占比</div>
                    <chartComponent class=" borderF" :chart-data="chartCarData.chartData_1" :chart-height="'716px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">八、各车型毛利分析</div>
                    <chartComponent class=" borderF" :chart-data="chartData.chartData5" :chart-height="'572px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">九、库销比</div>
                    <chartComponent class=" borderF" :chart-data="chartData.chartData6" :chart-height="'625px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">十、各车型库存结构占比</div>
                    <chartComponent class=" borderF" :chart-data="chartCarData.chartData_2" :chart-height="'572px'"></chartComponent>
                </a-col>

            </a-row>
        </a-col>
    </a-row>
</template>

<script>
import {
  oneData,
  fontSize,
  lineStyle,
  symbolSize, textStyle,
  itemStyle, PrintyAxis, PrintXAxis,
} from "@/views/monthReport/shopAnalysis/component/myData";
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import aCustomTable from "@/views/monthReport/shopAnalysis/component/aCustomTable";
import chartComponent from "@/components/chartComponent";
import {
  barMaxWidth, funnelLabel, funnelTooltip, monthGetData, mouth12Chart, settingsLabel,
  tableGetData,
  tableIndex,
  xAxis,
  yAxisUnit,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import { percentage } from "@/until/option";
import echarts from "echarts";

export default {
  name: "twoSell",
  components: { aMyTable, aCustomTable, chartComponent },
  props: {
    allData: { type: Object },
    dataParams: {},
  },
  data() {
    return {
      params: {},
      monthRank: {},
      monthPush: {},
      rich: {},
      monthStandardPush: {},
      yearRank: {},
      descriptionsItem: [
        { title: "本月盈亏保本台次", value: "0台", kpiCode: "C0208001" },
        { title: "终端实绩全国标杆值", value: "0台", kpiCode: "C0208001" },
        { title: "本月终端实绩", value: "0台", kpiCode: "A0302001" },
        { title: "提车实绩全国标杆值", value: "0台", kpiCode: "A0302001" },
      ],
      descriptionsItem1: [
        { title: "星级站店数", value: "0台", kpiCode: "C0302121" },
        { title: "星级站销量", value: "0台", kpiCode: "A0302281" },
        { title: "星级站店均销量", value: "0台", kpiCode: "A0303351" },
        { title: "认证二网店数", value: "0台", kpiCode: "C0302122" },
        { title: "认证二网销量", value: "0台", kpiCode: "A0302282" },
        // { title: "认证二网店均销量", value: "0台", kpiCode: "A0303352" },
      ],
      descriptionsItem2: [
        { title: "本月销售顾问人数", value: "0台", kpiCode: "C0303030" },
        { title: "本月展厅销售顾问人数", value: "0台", kpiCode: "C0302314" },
        { title: "本月IDCC直销人数", value: "0台", kpiCode: "C0302315" },
      ],
      columnsC: [
        {
          key: "name", title: "指标名称", dataIndex: "name", width: 260,
          customRender: (text, record) => {
            if(record.indentation > 0) {
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            }
            return text;
          },
        },
        { key: "Unit", title: "单位", dataIndex: "Unit" },
        {
          key: "Current", title: "当月值", dataIndex: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
            if(record.PercentileSort === "DESC") flag = !flag;
            if(flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑</div>;
            }
          },
        },
        { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
        { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
        { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
        { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
        { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
        { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
      ],
      tableData1: {
        table: oneData.salesTable.tableData1.table,
        columns: [],
        data: [],
      },
      tableData2: {
        table: oneData.salesTable.tableData2.table,
        columns: [],
        data: [],
      },
      tableData3: {
        table: [
          { name: "展厅首次客流", kpiCode: "A1302012" },
          { name: "展厅再次客流", kpiCode: "A1302013" },
          { name: "首次成交率", kpiCode: "A1303081" },
          { name: "再次成交率", kpiCode: "A1303082" },
          { name: "IDCC订单", kpiCode: "A1302032" },
          { name: "线索有效率", kpiCode: "A1303036" },
          { name: "邀约到店率", kpiCode: "A1303024" },
          { name: "到店成交率", kpiCode: "A1303028" },
        ],
        columns: [],
        data: [],
        columnsI: 14,
      },
      chartData: {
        chartData1: {
          columns: oneData.salesTable.chartData.chartData1.columns,
          kpiCode: oneData.salesTable.chartData.chartData1.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["单车裸车毛利", "单车商政返利", "单车水平事业毛利"] },
            showLine: ["销售综合毛利率"],
            axisSite: { right: ["销售综合毛利率"] },
            yAxisType: ["0", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "元",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);

                  },
                },
              },
              {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d * 100).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "inside",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData2: {
          columns: ["日期", "展厅终端实绩", "IDCC终端实绩", "二网终端实绩", "大客户终端实绩"],
          kpiCode: [
            { Name: "展厅终端实绩", Code: "A0302021" },
            { Name: "IDCC终端实绩", Code: "A0302022" },
            { Name: "二网终端实绩", Code: "A0302023" },
            { Name: "大客户终端实绩", Code: "A0302024" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["展厅终端实绩", "IDCC终端实绩", "二网终端实绩", "大客户终端实绩"] },
            yAxisType: ["0"],
            label: {},
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: {
              name: "台",
              type: "value",
              nameTextStyle: {
                fontSize,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
              axisLabel: {
                fontSize,
                formatter: (d) => {
                  return Number(d).toFixed(0);
                },
              },
            },
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "left",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                label: {
                  show: true, // 显示数字
                  position: "right",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                label: {
                  show: true, // 显示数字
                  position: "left",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                label: {
                  show: true, // 显示数字
                  position: "right",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
            ],

          },
        },
        chartData3: {
          columns: oneData.salesTable.chartData.chartData3.columns,
          kpiCode: oneData.salesTable.chartData.chartData3.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["二网销量占比"],
            axisSite: { right: ["二网销量占比"] },
            yAxisType: ["0", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
              {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d * 100).toFixed(1);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideLeft",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData4: {
          columns: oneData.salesTable.chartData.chartData4.columns,
          kpiCode: oneData.salesTable.chartData.chartData4.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人均生产力"],
            axisSite: { right: ["人均生产力"] },
            yAxisType: ["0", "0"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              }, {
                type: "value",
                name: "元",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              }],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle: {
                  //   color: '#5470C6',
                  width: 3,
                  //   type: 'dashed'
                },
                symbolSize: 12,
                label: {
                  show: true, // 显示数字
                  position: "insideLeft",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
            ],
          },
        },
        chartData5: {
          columns: oneData.salesTable.chartData.chartData5.columns,
          kpiCode: oneData.salesTable.chartData.chartData5.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["单车新车裸车毛利", "单车商政返利", "单车水平事业毛利"] },
            yAxisType: ["0"],
            label: {
              show: true, // 显示数字
              textStyle: {
                fontSize, color: "black",
              },
              formatter: function(params) {
                return (params.value).toFixed(0);
              },
            },
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: {
              type: "value",
              name: "元",
              nameTextStyle: {
                fontSize,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
              axisLabel: {
                fontSize,
                formatter: (d) => {
                  return Number(d).toFixed(0);
                },
              },
            },
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,

              },
            ],
          },
        },
        chartData6: {
          columns: oneData.salesTable.chartData.chartData6.columns,
          kpiCode: oneData.salesTable.chartData.chartData6.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["库销比"],
            yAxisType: ["0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "insideRight",
              textStyle: {
                fontSize, color: "black",
              },
              formatter: function(params) {
                return Number((Number(params.data) * 100).toFixed(2)).toFixed(1) + "%";
              },
            },
          },
          options: {
            legend: {
              textStyle,
            },
            xAxis: PrintXAxis,
            yAxis: {
              type: "value",
              name: "%",
              nameTextStyle: {
                fontSize,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
              axisLabel: {
                fontSize,
                formatter: (d) => {
                  return Number(d * 100).toFixed(0);
                },
              },
            },
            series: [
              {
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgba(43,230,129,1)",
                      }, {
                        offset: 0.5,
                        color: "rgba(43,230,129,0.5)",
                      }, {
                        offset: 1,
                        color: "rgba(255,255,255,0.3)",
                      },
                    ]),
                  },
                },
                data: [],
              },
            ],
          },
        },
      },
      funnelData: {
        funnelData1: {
          settings: {
            type: "funnel",
          },
          options: {
            tooltip: funnelTooltip,
            legend: {
              //  data: ["展厅客流总数", "展厅新增订单数", "首次客户订单", "再次客户订单", "终端"],
              data: ["展厅客流总数", "展厅新增订单数", "终端"],
            },
            series: [
              {
                name: "展厅漏斗",
                label: funnelLabel,
                // sort: "none",
                data: [
                  { value: 0, name: "展厅客流总数", kpiCode: "A1303043" },
                  { value: 0, name: "展厅新增订单数", kpiCode: "A1303044" },
                  /* { value: 0, name: "首次客户订单", kpiCode: "A1302014" },
                   { value: 0, name: "再次客户订单", kpiCode: "A1302111" },*/
                  { value: 0, name: "终端", kpiCode: "A0302021" },
                ],
              },
            ],
          },
        },
        funnelData2: {
          settings: {
            type: "funnel",
          },
          options: {
            tooltip: funnelTooltip,
            legend: {
              data: ["线索总数", "有效线索数", "邀约到店数", "订单数", "终端"],
            },
            series: [
              {
                name: "IDCC漏斗",
                label: funnelLabel,
                // sort: "none",
                data: [
                  { value: 0, name: "线索总数", kpiCode: "A1302115" },
                  { value: 0, name: "有效线索数", kpiCode: "A1302019" },
                  { value: 0, name: "邀约到店数", kpiCode: "A1302028" },
                  { value: 0, name: "订单数", kpiCode: "A1302032" },
                  { value: 0, name: "终端", kpiCode: "A0302022" },
                ],
              },
            ],
          },
        },
      },
      chartCarData: {
        chartData_1: {
          columns: oneData.salesTable.chartCarData.chartData_1.columns,
          rows: oneData.salesTable.chartCarData.chartData_1.rows,
          settings: {
            type: "histogram",
            showLine: ["各车型销量占比"],
            axisSite: { right: ["各车型销量占比"] },
            yAxisType: ["0", "0.[0]"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              }, {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              }],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return Number(params.data ?? 0).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "top",
                  textStyle: {
                    color: "black", fontSize,
                  },
                  formatter: function(params) {
                    return Number(params.data).toFixed(1) + "%";
                  },
                },
              },
            ],
          },
        },
        chartData_2: {
          columns: oneData.salesTable.chartCarData.chartData_2.columns,
          rows: oneData.salesTable.chartCarData.chartData_2.rows,
          settings: {
            type: "histogram",
            stack: { all: ["0~30天期末新车库存", "31~60天期末新车库存", "61~90天期末新车库存", ">90天期末新车库存"] },
            showLine: ["期末库存台次占期末新车库存台次比"],
            axisSite: { right: ["期末库存台次占期末新车库存台次比"] },
            yAxisType: ["0", "0.[0]"],
            label: {
              show: true, // 显示数字
              //   position: "top",
              textStyle: {
                color: "black", fontSize,
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return Number(params.data ?? 0).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            legend: {
              textStyle: {
                fontSize: 20,
              },
            },
            yAxis: [
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              }, {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              { barMaxWidth },
              {},
              {},
              {}, {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideLeft",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return params.data;
                  },
                },
              },
            ],
          },
        },
      },
    };
  },
  created() {
    if(this.allData) {
      this.getDescriptionsItem();
      this.getChartData();
      this.getTableData();
    }
  },
  methods: {
    getDescriptionsItem() {
      if(!this.rich) {
        return false;
      }
      this.descriptionsItem.forEach((d, i) => {
        if(!this.rich[d.kpiCode]) {
          return false;
        }
        if(i === 1 || i === 3) {
          d.value = Number(this.rich[d.kpiCode].Current ?? 0).toFixed(0) + this.rich[d.kpiCode].Unit;
        } else {
          d.value = Number(this.rich[d.kpiCode].Current ?? 0).toFixed(0) + this.rich[d.kpiCode].Unit;
        }
      });
      this.descriptionsItem1.forEach(d => {
        if(!this.rich[d.kpiCode]) {
          return false;
        }
        d.value = Number(this.rich[d.kpiCode].Current ?? 0).toFixed(0) + this.rich[d.kpiCode].Unit;
      });
      this.descriptionsItem2.forEach(d => {
        if(!this.rich[d.kpiCode]) {
          return false;
        }
        d.value = Number(this.rich[d.kpiCode].Current ?? 0).toFixed(0) + this.rich[d.kpiCode].Unit;
      });
    },
    getTableData() {
      tableGetData(this.tableData1, this.rich);
      tableGetData(this.tableData2, this.rich);
      monthGetData(this.tableData3, this.monthPush, this.dataParams.ReportDate, 14);
    },
    getChartData() {
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], this.monthPush, this.monthStandardPush, this.dataParams.ReportDate);
      });
      Object.keys(this.funnelData).forEach(f => {
        this.funnelData[f].options.series[0].data.forEach(d => {
          if(this.rich[d.kpiCode]) {
            d.value = this.rich[d.kpiCode].Current ?? 0;
          }
        });
      });
      setTimeout(() => {
        let arr = [];
        this.chartData.chartData6.rows.forEach(d => {
          arr.push(d["库销比"] ?? 0);
        });
        this.chartData.chartData6.options.series[0].data = arr;
      });
      /*charCarData*/
      this.chartCarData.chartData_1.rows.forEach(d => {
        d["各车型终端销量"] = this.carDataCallback(d, d.kpiCode, this.rich);
        d["各车型销量占比"] = this.carDataCallbackP(d, d.percentageCode, this.rich);
      });
      this.chartCarData.chartData_2.rows.forEach(d => {
        d["0~30天期末新车库存"] = this.carDataCallback(d, d.kpiCode, this.rich);
        d["31~60天期末新车库存"] = this.carDataCallback(d, d.percentageCode, this.rich);
        d["61~90天期末新车库存"] = this.carDataCallback(d, d.sixCode, this.rich);
        d[">90天期末新车库存"] = this.carDataCallback(d, d.nineCode, this.rich);
        d["期末库存台次占期末新车库存台次比"] = this.carDataCallbackP(d, d.expirationCode, this.rich);
      });
    },
    carDataCallback(d, kpi, monthRank) {
      let kData = 0;
      kpi.forEach(k => {
        if(!monthRank[k]) {
          return false;
        }
        let kd = 0;
        kd = Number(monthRank[k].Current ?? 0);
        kData = Number(kd) + Number(kData);
        if(d.car === "影豹系列") kData = null;
      });
      return kData;
    },
    carDataCallbackP(d, kpi, monthRank) {
      let kData = 0;
      kpi.forEach(k => {
        if(!monthRank[k]) {
          return false;
        }
        let kd = 0;
        kd = Number(monthRank[k].Current ?? 0);
        kData = Number((Number(kd) + Number(kData)));
        if(d.car === "影豹系列") kData = null;
      });
      return Number(kData * 100).toFixed(1);
    },
  },
  watch: {
    allData: {
      handler: function() {
        this.monthRank = this.allData["MonthRank"];
        this.monthPush = this.allData["MonthPush"];
        this.rich = this.allData["Rich"];
        this.monthStandardPush = this.allData["MonthStandardPush"];
        this.yearRank = this.allData["YearRank"];
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "monthReport.less";

.descriptionsItem5 {
  ul { padding: 0;margin: 0;

    li {
      float: left;width: 20%;

      p {
        width: 98%;background: #33554A;color: #ffffff;padding: 17px 0;font-size: 22px;text-align: center;

        span {
          color: #FACC14;font-size: 28px;
          }
        }

      &:last-child p {
        width: 100%;
        }
      }
    }
  }
</style>
