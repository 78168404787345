<template>
  <section>
    <a-card>
      指标编码：
      <a-input v-model="search.code" style="width: 200px;margin-right: 20px;" placeholder="请输入指标编码"
               @pressEnter="getIndexInfo(1)"/>
      指标名称：
      <a-input v-model="search.name" style="width: 200px;margin-right: 20px;" placeholder="请输入指标名称"
               @pressEnter="getIndexInfo(1)"/>
      <a-button type="primary" @click="getIndexInfo(1)">搜索</a-button>
    </a-card>
    <!--表格-->
    <a-card style="margin-top: 8px;">
      <a-table rowKey="Id" :columns="columns" :data-source="data" :pagination="false">
      <span slot="action" slot-scope="text, record">
        <a-switch checked-children="启用" un-checked-children="禁用" :checked="!record.IsDelete"
                  style="margin-right: 10px" @change="modifyIndexDataStatus(record)"/>
      <a-button v-if="isAdmin" type="primary" @click="loadIndexData(record)">修改</a-button>
      </span>
      </a-table>
      <!--分页-->
      <a-pagination class="page"
                    size="small" v-model="pageIndex" :total="page.totalRow" :pageSize="pageSize"
                    :show-total="(total, range) => `${range[0]} 到 ${range[1]} (共 ${total} 条)`"
                    @change="getIndexInfo" @showSizeChange="getIndexInfo"
                    show-size-changer show-quick-jumper/>
      <!--信息窗口-->
    </a-card>
    <div class="foot-pad30"></div>
    <div>
      <a-modal v-model="modifyForm.visible" :title="modifyForm.title" okText="保存" @ok="saveIndexData">
        <a-form-model ref="modifyForm" :model="modifyForm.data" :label-col="{span:6}" :wrapper-col="{span:12}"
                      :rules="modifyForm.rules">
          <a-form-model-item label="科目名称" prop="SubjCComb">
            <a-input v-model="modifyForm.data.SubjCComb" :disabled="modifyForm.data.Id>0"/>
          </a-form-model-item>
          <a-form-model-item label="指标编码" prop="KpiCode">
            <a-input v-model="modifyForm.data.KpiCode" :disabled="modifyForm.data.Id>0"/>
          </a-form-model-item>
          <a-form-model-item label="指标名称" prop="KpiName">
            <a-input v-model="modifyForm.data.KpiName"/>
          </a-form-model-item>
          <a-form-model-item label="单位" prop="Unit">
            <a-input v-model="modifyForm.data.Unit"/>
          </a-form-model-item>
          <a-form-model-item label="公式描述" prop="FormulaDesc">
            <a-input v-model="modifyForm.data.FormulaDesc"/>
          </a-form-model-item>
          <a-form-model-item label="公式代码" prop="FormulaCode">
            <a-input v-model="modifyForm.data.FormulaCode"/>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="Remark">
            <a-input v-model="modifyForm.data.Remark"/>
          </a-form-model-item>
          <a-form-model-item label="非空" prop="IsNulls">
            <a-input v-model="modifyForm.data.IsNulls"/>
          </a-form-model-item>
          <a-form-model-item label="固定值" prop="FixedValue">
            <a-input v-model="modifyForm.data.FixedValue"/>
          </a-form-model-item>
          <a-form-model-item label="大于" prop="GreaterThan">
            <a-input v-model="modifyForm.data.GreaterThan"/>
          </a-form-model-item>
          <a-form-model-item label="小于" prop="LessThan">
            <a-input v-model="modifyForm.data.LessThan"/>
          </a-form-model-item>
          <a-form-model-item label="不等于" prop="NotEqual">
            <a-input v-model="modifyForm.data.NotEqual"/>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (text, record, index) => ++index
        },
        {
          title: '科目名称',
          dataIndex: 'SubjCComb',
          key: 'SubjCComb',
        },
        {
          title: '指标编码',
          dataIndex: 'KpiCode',
          key: 'KpiCode',
        },
        {
          title: '指标名称',
          dataIndex: 'KpiName',
          key: 'KpiName',
        },
        {
          title: '公式描述',
          dataIndex: 'FormulaDesc',
          key: 'FormulaDesc',
        },
        {
          title: '公式代码',
          dataIndex: 'FormulaCode',
          key: 'FormulaCode',
          scopedSlots: {customRender: 'Percentage'},
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime'
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ],
      data: [],
      page: {},
      pageIndex: 1,
      pageSize: 10,
      search: {
        code: "",
        name: ""
      },
      isAdmin: false,
      modifyForm: {
        visible: false,
        title: '类别',
        rules: {
          SubjCComb: {required: true, message: '请输入科目名称', trigger: 'blur', whitespace: true},
          KpiCode: {required: true, message: '请输入指标编码', trigger: 'blur', whitespace: true},
          KpiName: {required: true, message: '请输入指标名称', trigger: 'blur', whitespace: true},
          FormulaDesc: {required: true, message: '请输入公式描述', trigger: 'blur', whitespace: true},
          FormulaCode: {required: true, message: '请输入公式代码', trigger: 'blur', whitespace: true},
        },
        data: {},
      }
    };
  },
  created() {
    this.getAdminInfo();
    this.getIndexInfo();
  },
  methods: {
    async getAdminInfo() {
      let res = await this.$Http.isAdmin();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.isAdmin = res.Data;
    },
    async getIndexInfo(pageIndex, pageSize) {
      if (pageIndex != null) {
        this.pageIndex = pageIndex;
      }
      if (pageSize != null) {
        this.pageSize = pageSize;
      }
      let res = await this.$Http.commonApi({
        API: "Stark_GetKpiFormula",
        sorts: "CreateTime ASC",
        pagesize: this.pageSize,
        page: this.pageIndex,
        KpiCode: this.search.code,
        KpiName: this.search.name
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.data = res.ApiParamObj[0];
      this.page = res.ApiParamObj[1][0];
    },
    async loadIndexData(record) {
      if (this.$refs.modifyForm !== undefined)
        this.$refs.modifyForm.resetFields();

      let res = await this.$Http.commonApi({
        API: "Stark_GetKpiExcelContentById",
        Id: record.Id
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      record = res.ApiParamObj;
      Object.keys(record).forEach(key => {
        if (record[key] === "NULL") {
          record[key] = "";
        }
      })

      this.modifyForm.title = "修改指标";
      this.modifyForm.visible = true;
      this.modifyForm.data = record;
    },
    async saveIndexData() {
      let valid = await this.$refs.modifyForm.validate();
      if (!valid)
        return;

      let record = this.modifyForm.data;
      let res = await this.$Http.modifyIndexData({
        ApiParamObj: record
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }

      await this.getIndexInfo();
      this.modifyForm.visible = false;
    },
    async modifyIndexDataStatus(record) {
      let targetStatus = !record.IsDelete;
      let res = await this.$Http.modifyIndexDataStatus({
        Id: record.Id,
        Type: targetStatus ? 0 : 1
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      record.IsDelete = targetStatus;
    }
  },
};
</script>
