<template>
  <section >
    <a-row :gutter="[24,24]">
      <a-col :span="24">
        <a-card style="padding-top: 12px">
          <a-descriptions bordered :column="3" style="margin-bottom: 20px;">
            <a-descriptions-item label="店均费用率">{{ GetIndexValue("店均费用率", "%") }}</a-descriptions-item>
            <a-descriptions-item label="店均平均单车广宣费用">{{ GetIndexValue("店均平均单车广宣费用", "元") }}</a-descriptions-item>
            <a-descriptions-item label="店均平均单车财务费用">{{ GetIndexValue("店均平均单车财务费用", "元") }}</a-descriptions-item>
          </a-descriptions>
          <div class="info">
            <p>
              1、整体费用管控：固定费用
              <span v-html='GetDesc(data,"C0205006")'></span>
              、财务费用
              <span v-html='GetDesc(data,"C0205021")'></span>
              ，全店费用
              <span v-html='GetDesc(data,"C0206001",["上升至","降低至"])'></span>
              ，由于收入
              <span v-html='GetDesc(data,"C0406004",["上升","降低"],true)'></span>
              ，费用率
              <span v-html='GetDesc(data,"C0207022",["上升至","降低至"])'></span>
              （合理范围3.6%～5.6%）。
            </p>
            <p>
              2、单车费用管控：平均单车销售顾问工资
              <span v-html='GetDesc(data,"A0204032",["上升至","降低至"])'></span>
              ；单车广宣费用
              <span v-html='GetDesc(data,"A0205034",["上升至","降低至"])'></span>
              ；单车财务费用
              <span v-html='GetDesc(data,"A0205035",["上升至","降低至"])'></span>
              （其中厂家融资贴息
              <span v-html='GetDesc(data,"A0202039")'></span>
              ）。
            </p>
          </div>
        </a-card>
      </a-col>
      <a-col :span="24">
        <a-card title="1、整体费用管控">
          <a-col :span="isWin">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_5_1_1"
            >
            </monthReportTableComponent>
          </a-col>
          <a-col :span="isWin" class="ppTop30">
            <chartComponent :chart-data="data.chartData_5_1_1"/>
          </a-col>
          <a-col :span="24" >
            <chartComponent :chart-data="data.chartData_5_1_2"/>
          </a-col>
        </a-card>
      </a-col>
      <a-col :span="24">
        <a-card title="2、单车费用管控">
          <a-col :span="isWin">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_5_2_1"
            >
            </monthReportTableComponent>
          </a-col>
          <a-col :span="isWin" class="ppTop30">
            <chartComponent :chart-data="data.chartData_5_2_1"/>
          </a-col>
        </a-card>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import monthReportTableComponent from "@/components/monthReportTableComponent";
import chartComponent from "@/components/chartComponent";
import {GetDesc, getEmptyTemplate, numFormat} from "@/until/reportCalculate";
import {columnsImport, costData} from "../data.js";
import {GetWindows} from "@/until/dayFormat";

export default {
  props: {
    reportParams: {},
    reportDate: String,
    areaData: {},
    isRegionReport: Boolean, // 是否地区报告,true就是地区
  },
  data() {
    return {
      isWin: 24,
      emptyTemplate: getEmptyTemplate(),
      columnsData: columnsImport.columnsImportDataPC,
      data: costData,
    };
  },
  created() {
    if (this.isRegionReport) {
      this.columnsData = columnsImport.columnsImportDataPcRegionReport;
    }
    this.isWin = GetWindows();
    columnsImport.loadData(this, this.data, this.reportParams);
  },
  methods: {
    GetIndexValue(key, unit) {
      let value = 0;
      if (this.areaData && this.areaData.IndexValues) {
        value = this.areaData.IndexValues[key];
      }
      return numFormat(key, value, unit);
    },
    GetDesc,
  },
  components: {
    monthReportTableComponent, chartComponent,
  },
  watch: {
    reportParams: {
      handler: function () {
        columnsImport.loadData(this, this.data, this.reportParams);
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";
</style>
