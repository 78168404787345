<template>
    <a-row :guter="[28,0]">
        <a-col :span="24">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>{{dataParams.choseDealer}}上险总数</h2>
                    </a-col>
                    <!--          <a-col :offset="16" :span="4" class="right">
                                <a-tree-select @change="onOneChange" show-search
                                               v-model="value.one" allow-clear
                                               style="width: 240px"
                                               :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                               :tree-data="treeData" expandTrigger="hover"
                                               placeholder="请选择"
                                               :treeDefaultExpandedKeys="DefaultTreeData"
                                               searchPlaceholder="搜索"
                                               tree-node-filter-prop="title"
                                />
                              </a-col>-->
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData1"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24" class="carding">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>各车型级别市占率</h2>
                    </a-col>
                    <!--          <a-col :offset="16" :span="4" class="right">
                                <a-tree-select @change="onTwoChange" show-search
                                               v-model="value.two" allow-clear
                                               style="width: 240px"
                                               :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                               :tree-data="treeData" expandTrigger="hover"
                                               placeholder="请选择"
                                               :treeDefaultExpandedKeys="DefaultTreeData"
                                               searchPlaceholder="搜索"
                                               tree-node-filter-prop="title"
                                />
                              </a-col>-->
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData2"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24" class="carding">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>分车型级别市占率</h2>
                    </a-col>
                    <a-col :offset="12" :span="8" class="right">
                        筛选车型级别:
                        <a-select
                                label-in-value
                                :default-value="selectChart3DefaultValue"
                                style="width: 120px;margin-left: 10px"
                                @change="selecChartData3tChange"
                        >
                            <a-select-option :value="item" v-for="(item,i) in checkData.checkData3" :key="i++">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                        <!--            <a-tree-select @change="onThereChange" show-search
                                                   v-model="value.there" allow-clear
                                                   style="width: 240px;margin-left: 15px;"
                                                   :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                                   :tree-data="treeData" expandTrigger="hover"
                                                   placeholder="请选择"
                                                   :treeDefaultExpandedKeys="DefaultTreeData"
                                                   searchPlaceholder="搜索"
                                                   tree-node-filter-prop="title"
                                    />-->
                    </a-col>
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData3"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24" class="carding">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>分车系市占率</h2>
                    </a-col>
                    <!--          <a-col :offset="16" :span="4" class="right">
                                <a-tree-select @change="onFourChange" show-search
                                               v-model="value.four" allow-clear
                                               style="width: 240px"
                                               :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                               :tree-data="treeData" expandTrigger="hover"
                                               placeholder="请选择"
                                               :treeDefaultExpandedKeys="DefaultTreeData"
                                               searchPlaceholder="搜索"
                                               tree-node-filter-prop="title"
                                />
                              </a-col>-->
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData4"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24" class="carding">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>各自主品牌市占率</h2>
                    </a-col>
                    <!--          <a-col :offset="16" :span="4" class="right">
                                <a-tree-select @change="onFiveChange" show-search
                                               v-model="value.five" allow-clear
                                               style="width: 240px"
                                               :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                               :tree-data="treeData" expandTrigger="hover"
                                               placeholder="请选择"
                                               :treeDefaultExpandedKeys="DefaultTreeData"
                                               searchPlaceholder="搜索"
                                               tree-node-filter-prop="title"
                                />
                              </a-col>-->
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData5"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24" class="carding">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>传祺各车型上险数</h2>
                    </a-col>
                    <!--          <a-col :offset="16" :span="4" class="right">
                                <a-tree-select @change="onSixChange" show-search
                                               v-model="value.six" allow-clear
                                               style="width: 240px"
                                               :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                               :tree-data="treeData" expandTrigger="hover"
                                               placeholder="请选择"
                                               :treeDefaultExpandedKeys="DefaultTreeData"
                                               searchPlaceholder="搜索"
                                               tree-node-filter-prop="title"
                                />
                              </a-col>-->
                </div>
                <div class="clear-both">
                    <div style="text-align: center">
                        <a-checkbox-group v-model="defaultCheckedData.defaultCheckedData6"
                                :options="checkData.checkData6"
                                @change="onCheckBoxChange"
                        ></a-checkbox-group>
                    </div>
                    <main-chart :chart-data="chartData.chartData6"/>
                    <div style="padding-top: 10px;padding-left: 20px">
                        备注：GS4 （含GS4 PLUS 、GS4 PHEV、GS4 COUPE)、GS8(含GS8S)、M6（含M6 PRO）、M8（含M8 大师版）
                    </div>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24" class="carding">
            <a-card>
                <div style="padding-bottom: 20px;overflow:hidden;">
                    <a-col :span="4">
                        <h2>车型竞品分析</h2>
                    </a-col>
                    <a-col :offset="12" :span="8" class="right">
                        <!--            <a-tree-select @change="onSevenChange" show-search
                                                   v-model="value.seven" allow-clear
                                                   style="width: 240px"
                                                   :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                                   :tree-data="treeData" expandTrigger="hover"
                                                   placeholder="请选择"
                                                   searchPlaceholder="搜索"
                                                   tree-node-filter-prop="title"
                                    />-->
                        竞品车型选择:
                        <a-select
                                label-in-value
                                :default-value="selectCompetitorDefaultValue"
                                style="width: 120px;margin-left: 10px"
                                @change="selectChange"
                        >
                            <a-select-option :value="item" v-for="(item,i) in checkData.checkData6" :key="i++">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </div>
                <div class="clear-both">
                    <div style="text-align: center">
                        <a-checkbox-group v-model="defaultCheckedData.defaultCheckedData7.data"
                                :options="checkData.checkData7.data"
                                @change="onCheckChangeCompetitor"
                        ></a-checkbox-group>
                    </div>
                    <main-chart :chart-data="chartData.chartData7"/>
                    <div style="padding-top: 10px;padding-left: 20px">
                        备注：GS4 （含GS4 PLUS 、GS4 PHEV、GS4 COUPE)、GS8(含GS8S)、M6（含M6 PRO）、M8（含M8 大师版）
                    </div>
                </div>
            </a-card>
        </a-col>
    </a-row>
</template>

<script>
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import {
  legend,
  seriesBar,
  seriesLine,
  xAxis,
  yAxisDefault,
  yAxisRight,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { percentage, ThousandYuan } from "@/until/option";
import moment from "moment";
import underscore from "underscore";
import { grid, itemStyle, lineStyle, symbolSize } from "../chartConfig";

export default {
 name: "oneMarketShare",
 props: {
  dataParams: {},
 },
 components: { mainChart },
 data() {
  return {
   lineStyle, symbolSize, itemStyle, grid, seriesLine,
   treeData: [
    {
     title: "全国",
     value: "",
     key: "",
     children: [],
    },
   ],
   DefaultTreeData: [],
   value: {
    one: "全国",
    two: "全国",
    there: "全国",
    four: "全国",
    five: "全国",
    six: "全国",
    seven: "全国",
   },
   chartData: {
    chartData1: {
     columns: ["日期", "上险总数", "同比", "环比"],
     rows: [],
     setting: {
      type: "histogram",
      showLine: ["同比", "环比"],
      axisSite: { right: ["同比", "环比"] },
      yAxisType: ["0", "0%"],
      yAxisName: ["上险总数(个)", "同比/环比(%)"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType == "line") {
         return percentage(params.data);
         //   return (params.data * 10000).toFixed(1) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
       grid: {
       bottom: "15px",
       right: "22px",
       containLabel: true,
      },
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value)}`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine, seriesLine],
     },
    },
    chartData2: {
     columns: ["日期", "MPV上险台次", "SUV上险台次", "轿车上险台次", "MPV市占率", "SUV市占率", "轿车市占率"],
     rows: [],
     setting: {
      type: "histogram",
      stack: { val: ["MPV上险台次", "SUV上险台次", "轿车上险台次"] },
      showLine: ["MPV市占率", "SUV市占率", "轿车市占率"],
      axisSite: { right: ["MPV市占率", "SUV市占率", "轿车市占率"] },
      yAxisType: ["0", "0%"],
      yAxisName: ["上险台次(台)", "市占率(%)"],
      label: {
       show: false, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType == "line") {
         return percentage(params.data);
         //   return (params.data * 10000).toFixed(1) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
       grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesLine, seriesLine, seriesLine],
     },
    },
    chartData3: {
     columns: ["日期", "MPV上险台次", "MPV上险占比"],
     rows: [],
     setting: {
      type: "histogram",
      showLine: [],
      axisSite: { right: ["MPV上险占比"] },
      yAxisType: ["0", "0%"],
      yAxisName: ["分车型上险台次(台)", "占比(%)"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType == "line") {
         return percentage(params.data);
         //   return (params.data * 10000).toFixed(1) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData4: {
     columns: ["日期", "自主品牌", "美系", "德系", "韩系", "日系", "意法系"],
     rows: [],
     setting: {
      type: "histogram",
      showLine: ["自主品牌", "美系", "德系", "韩系", "日系", "意法系"],
      yAxisType: ["0%"],
      yAxisName: ["市占率(%)"],
      label: {
       show: false, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType == "line") {
         return percentage(params.data);
         //   return (params.data * 10000).toFixed(1) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
       grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesLine, seriesLine, seriesLine, seriesLine, seriesLine, seriesLine],
     },
    },
    chartData5: {
     columns: ["日期", "广汽传祺", "长安汽车", "长城汽车哈弗", "比亚迪汽车", "上汽乘用车荣威", "奇瑞汽车", "吉利汽车", "一汽红旗", "吉利汽车领克"],
     rows: [],
     setting: {
      type: "histogram",
      showLine: ["广汽传祺", "长安汽车", "长城汽车哈弗", "比亚迪汽车", "上汽乘用车荣威", "奇瑞汽车", "吉利汽车", "一汽红旗", "吉利汽车领克"],
      yAxisType: ["0%"],
      yAxisName: ["市占率(%)"],
      label: {
       show: false, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType == "line") {
         return percentage(params.data);
         //   return (params.data * 10000).toFixed(1) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
       grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault],
      series: [seriesLine, seriesLine, seriesLine, seriesLine, seriesLine, seriesLine, seriesLine, seriesLine, seriesLine],
     },
    },
    chartData6: {
     columns: [],
     rows: [
     ],
     setting: {
      type: "histogram",
      stack: { val: [] },
      yAxisType: ["0"],
      yAxisName: ["上险台次(台)"],
      label: {
       show: false, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return ThousandYuan(params.data);
       },
      },
     },
     options: {
       grid,
      xAxis,
      yAxis: [yAxisDefault],
      series: [seriesBar,seriesBar,seriesLine,seriesLine],
     },
    },
    chartData7: {
     columns: [],
     rows: [],
     setting: {
      type: "histogram",
      stack: { val: [] },
      showLine: ["GS4市占率"],
      axisSite: { right: ["GS4市占率"] },
      yAxisType: ["0", "0.0%"],
      yAxisName: ["上险台次(台)", "市占率(%)"],
      label: {
       show: false, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data * 100).toFixed(1) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
     // legend,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      grid,
      xAxis,
      yAxis: [yAxisDefault,yAxisRight],
      series: [seriesBar,seriesBar,seriesLine,seriesLine],
     },
    },
   },
      CompeteCarBakData:{},
   checkData: {
    checkData3: ["MPV", "SUV", "轿车"],
       checkData6:[],
    checkData7: {
     data: [],
    },
   },
   defaultCheckedData: {
    defaultCheckedData6: [],
    defaultCheckedData7: {
     data: [],
    },
   },
   selectCompetitorDefaultValue: { key: "GS3 Power" },
   selectChart3DefaultValue: { key: "MPV" },
   months: [],
  };
 },
 async created() {
   await this.GetCompeteCar();
   await this.getSelectData();

   await this.load();
 },
 methods: {
    async load(){
      await Promise.all([this.getChartData1(), this.getChartData2(), this.getChartData3(),
        this.getChartData4(), this.getChartData5(), this.onCheckBoxChange(), this.onCheckChangeCompetitor()]);
    },
     async GetCompeteCar(){
         let res = await this.$Http.GetCompeteCar();
         if (res["Success"]){
             this.CompeteCarBakData = res.Data;
             // this.CompeteCarBakData[this.selectCompetitorDefaultValue.key].
             this._.findWhere(this.CompeteCarBakData,{ShowCarTypeName:"GS3 Power"}).CompeteList.forEach(i =>{
                 // this.checkData.checkData7.data.forEach(ar => {
                 //     arr.push(ar);
                 // });
                 this.checkData.checkData7.data.push({label:i.CompeteCar,value:i.CompeteCarName})
                 if (i.IsDefault){
                     this.defaultCheckedData.defaultCheckedData7.data.push(i.CompeteCarName)
                 }
             })

             this.checkData.checkData6 =  this._.pluck(this.CompeteCarBakData,"ShowCarTypeName");
            await this.onCheckChangeCompetitor()
         }
     },
  onOneChange(value, selectedOptions) {
   this.getChartData1();
  },
  /*chartData1*/
  async getChartData1() {
   this.chartData.chartData1.columns=["日期", this.dataParams.choseDealer + "上险总数", "同比", "环比"]
   let data = moment(this.dataParams.ReportDate).add(-13, "M");
   this.months = underscore.range(13).map(() => data.add(1, "M").format("Y-M"));
   let res = await this.$Http.CountryInsure(
    {
     yearMonth: this.dataParams.ReportDate,
     parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
    },
   );
   let chart = this.chartData.chartData1;
   if(!res.Data) {
    chart.rows = [];
    return false;
   }
   chart.rows = [];
   chart.rows = this.months.map(m => {
    let row = {};
    row["日期"] = m;
    Object.keys(res.Data["SaleNum"]).forEach((k) => {
     if(m === moment(k).format("Y-M")) {
      row[this.chartData.chartData1.columns[1]] = res.Data["SaleNum"][k];
     }
    });
    Object.keys(res.Data["TB"]).forEach((k) => {
     if(m === moment(k).format("Y-M")) {
      row["同比"] = res.Data["TB"][k];
     }
    });
    Object.keys(res.Data["HB"]).forEach((k) => {
     if(m === moment(k).format("Y-M")) {
      row["环比"] = res.Data["HB"][k];
     }
    });
    return row;
   });

  },
  onTwoChange(value, selectedOptions) {
   this.getChartData2();
  },
  async getChartData2() {
   let res = await this.$Http.CarTypeMarketShare({
    yearMonth: this.dataParams.ReportDate,
    parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
   });
   let chart = this.chartData.chartData2;
   if(!res.Data) {
    chart.rows = [];
    return false;
   }
   let lv = res.Data["LV"];
   let SaleNum = res.Data["SaleNum"];
   chart.rows = this.months.map(m => {
    let row = {};
    row["日期"] = m;
    let arr = ["MPV上险台次", "SUV上险台次", "轿车上险台次", "MPV市占率", "SUV市占率", "轿车市占率"];
    arr.forEach((k, i) => {
     switch (i) {
     case 0:
      Object.keys(SaleNum["MPV"]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = SaleNum["MPV"][v] ?? 0;
       }
      });
      break;
     case 1:
      Object.keys(SaleNum["SUV"]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = SaleNum["SUV"][v];
       }
      });
      break;
     case 2:
      Object.keys(SaleNum["Car"]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = SaleNum["Car"][v];
       }
      });
      break;
     case 3:
      Object.keys(lv["MPV"]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = lv["MPV"][v];
       }
      });
      break;
     case 4:
      Object.keys(lv["SUV"]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = lv["SUV"][v];
       }
      });
      break;
     default:
      Object.keys(lv["Car"]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = lv["Car"][v];
       }
      });
     }

    });
    return row;
   });
  },
  onThereChange(value, selectedOptions) {
   this.getChartData3();
  },
  selecChartData3tChange(value) {
   this.selectChart3DefaultValue = value;
   this.getChartData3();
  },
  async getChartData3() {
   let res = await this.$Http.CarTypeMarketShare({
    yearMonth: this.dataParams.ReportDate,
    parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
    // parmId: this.value.there === "全国" ? "" : this.value.there,
   });

   let chart = this.chartData.chartData3;
   if(!res.Data) {
    chart.columns = [];
    return false;
   }
   chart.columns = [];
   let a = "上险台次";
   let b = "市占率";
   let sel = this.selectChart3DefaultValue.key;
   let mySel = sel === "轿车" ? "Car" : sel;
   let data1 = res.Data["SaleNum"][mySel];
   let data2 = res.Data["LV"][mySel];
   chart.columns.push(sel + b);
   chart.columns.push(sel + a);
   chart.rows = this.months.map(m => {
    let row = {};
    row["日期"] = m;
    Object.keys(data1).map(v => {
     if(m === moment(v).format("Y-M")) {
      row[sel + a] = data1[v];
      row[sel + b] = data2[v];
     }
    });
    return row;
   });
   chart.columns.push("日期");
   chart.columns.reverse();
   chart.setting.showLine = [sel + b];
   chart.setting.axisSite.right = [sel + b];
  },
  onFourChange(value, selectedOptions) {
   this.getChartData4();
  },
  async getChartData4() {
   let res = await this.$Http.CarSeriesZB({
    yearMonth: this.dataParams.ReportDate,
    parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
    //parmId: this.value.four === "全国" ? "" : this.value.four,
   });
   let chart = this.chartData.chartData4;
   if(!res.Data) {
    chart.rows = [];
    return false;
   }
   chart.rows = [];
   let myData = res.Data["LV"];
   chart.rows = this.months.map(m => {
    let row = {};
    row["日期"] = m;
    chart.setting.showLine.forEach(k => {
     if(k === "自主品牌") k = "自主";
     Object.keys(myData[k]).map(v => {
      if(m === moment(v).format("Y-M")) {
       if(k === "自主") {
        row["自主品牌"] = myData[k][v];
       } else {
        row[k] = myData[k][v];
       }
      }
     });

    });
    return row;
   });
  },
  onFiveChange(value, selectedOptions) {
   this.getChartData5();
  },
  async getChartData5() {
   let chart = this.chartData.chartData5;
   let res = await this.$Http.CarBrandZB({
    yearMonth: this.dataParams.ReportDate,
    parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
    // parmId: this.value.five === "全国" ? "" : this.value.five,
    brandInfo: chart.setting.showLine,
   });
   let carData = res.Data["LV"];
   if(!res.Data) {
    chart.rows = [];
    return false;
   }
   chart.rows = [];
   let date = moment(this.dataParams.ReportDate).add(-13, "M");
   let months = underscore.range(13).map(() => date.add(1, "M").format("Y-M"));
   chart.rows = months.map(m => {
    let row = {};
    row["日期"] = m;
    chart.setting.showLine.forEach(k => {
     if(carData[k]) {
      Object.keys(carData[k]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = carData[k][v] ?? 0;
       }
      });
     }
    });
    return row;
   });
  },
  onSixChange(value, selectedOptions) {
   this.onCheckBoxChange();
  },
  /*车型上险数复选*/
  async onCheckBoxChange(value) {
   let arr = [];
   // this.checkData.checkData6.forEach(d => {
   //  arr.push(cq + d);
   // });
      if (value){
          value.forEach(item=>{
              arr.push(this._.findWhere(this.CompeteCarBakData,{"ShowCarTypeName":item})["CarTypeName"])
          })
      }

   let res = await this.$Http.CarCQCartypeZB({
    yearMonth: this.dataParams.ReportDate,
    parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
    //  parmId: this.value.six === "全国" ? "" : this.value.six,
    carType: arr,
   });
   if(!res.Data) {
    return false;
   }
   let carData = res.Data["SaleNum"];
   let chart = this.chartData.chartData6;
   chart.columns = []; // 重置
   let date = moment(this.dataParams.ReportDate).add(-13, "M");
   let months = underscore.range(13).map(() => date.add(1, "M").format("Y-M"));
   // this.defaultCheckedData.defaultCheckedData6 = this._.keys(this.CompeteCarBakData)
    if(this.defaultCheckedData.defaultCheckedData6.length===0 ) this.defaultCheckedData.defaultCheckedData6 = this.checkData.checkData6
   chart.columns = [...this.defaultCheckedData.defaultCheckedData6].reverse();
   chart.columns.push("日期");
   chart.columns.reverse();
   chart.setting.stack.val = [...this.defaultCheckedData.defaultCheckedData6];
   chart.rows = months.map(m => {
    let row = {};
    row["日期"] = m;
    this.defaultCheckedData.defaultCheckedData6.forEach(item=> {
        let k = this._.findWhere(this.CompeteCarBakData,{"ShowCarTypeName":item})["CarTypeName"];
        let carName = this._.findWhere(this.CompeteCarBakData,{"ShowCarTypeName":item})["ShowCarTypeName"];
      if(!carData[k]) {
       row[k] = 0;
      } else {
       Object.keys(carData[k]).map(v => {
        if(m === moment(v).format("Y-M")) {
         row[carName] = carData[k][v];
        }
       });
      }
     },
    );
    // todo 对像属性名称修改
     return Object.keys(row).reduce((acc, key) => {
       const data = row[key]
       if(key === "传祺GS4,GS4 PHEV,GS4 COUPE,GS4 PLUS") key = "GS4"
       key = key.replace("传祺", "");
       acc[key] = data;
       return acc;
     }, {});
   });
  },
  /* 车型competitor分析 */
  onSevenChange(value, selectedOptions) {
   this.onCheckChangeCompetitor();
  },
  async onCheckChangeCompetitor() {
     let  sel = this.selectCompetitorDefaultValue.key;
      let showCarTypeName = this._.findWhere(this.CompeteCarBakData,{"ShowCarTypeName":sel})["CarTypeName"]
      let carKey = "";
      Object.keys(this.CompeteCarBakData).forEach(key=>{
          if (this.CompeteCarBakData[key].ShowCarTypeName === sel){
              carKey = key
          }
      })
   let arr = [];
    if(carKey==="GS3") {
      carKey = "GS3 Power"
    }
   arr.push({label:carKey,value:showCarTypeName});
   this.checkData.checkData7.data.forEach(ar => {
    arr.push(ar);
   });
   let res = await this.$Http.CarCQCartypeZB({
    yearMonth: this.dataParams.ReportDate,
    parmId: this.dataParams.AreaData === "全国" ? "" : this.dataParams.AreaData,
    //  parmId: this.value.six === "全国" ? "" : this.value.six,
    carType: arr.map(item=>item.value),
   });
   let data1 = res.Data["SaleNum"];
   let data2 = res.Data["LV"];
   let chart = this.chartData.chartData7;
   let a = "上险台次";
   let b = "市占率";
   chart.columns = []; // 重置
   this.defaultCheckedData.defaultCheckedData7.data.forEach(d => {
     if (d === "长安CS35")
       d = "CS35 PLUS"

     chart.columns.push(d + b);
   });

   chart.columns.push(carKey + b);
   this.defaultCheckedData.defaultCheckedData7.data.forEach(d => {
     if (d === "长安CS35")
       d = "CS35 PLUS"

       chart.columns.push(d + a);
   });
   chart.columns.push(carKey + a);
   chart.rows = this.months.map(m => {
    let row = {};
    row["日期"] = m;
    chart.columns.forEach(k => {
     let data = [];
     let rp = "";
     if(k.indexOf(a) > 0) {
      data = data1;
      rp = a;
     } else {
      data = data2;
      rp = b;
     }
     let r = k.replace(rp, "");
      if(r==="GS3 Power") {
        r = "GS3"
      }
        if (this.CompeteCarBakData[r]){
            r = this.CompeteCarBakData[r]["CarTypeName"]
        }
      if (r === "CS35 PLUS")
        r = "长安CS35";
      else if (r === "GS3 Power")
        r = "传祺GS3";

    if(!data[r]) {
      row[k] = 0;
     } else {
      Object.keys(data[r]).map(v => {
       if(m === moment(v).format("Y-M")) {
        row[k] = data[r][v];
       }
      });
     }

    });
    return row;
   });
   chart.columns.push("日期");
   chart.columns.reverse();
   chart.setting.showLine = [carKey + b];
   chart.setting.axisSite.right = [carKey + b];
   chart.setting.stack.val = [carKey + a];
   chart.options.series=[seriesBar];
   this.defaultCheckedData.defaultCheckedData7.data.forEach(d => {
    chart.options.series.push(seriesBar)
    chart.setting.showLine.push(d + b);
    chart.setting.axisSite.right.push(d + b);
    chart.setting.stack.val.push(d + a);
   });
    chart.options.series.push(seriesLine)
    this.defaultCheckedData.defaultCheckedData7.data.forEach(d => {
    chart.options.series.push(seriesLine)
   });
  },
  async getTreeData() {
   let res = await this.$Http.GetDealersByRole();
   if(!res.Data) {
    this.$message.error(res.Message);
    return;
   }
   this.treeData[0].children = res.Data.chain().map(p => {
    let obj = {};
    obj.value = p.PartId;
    obj.key = p.PartId;
    obj.title = p.PartName;
    obj.children = p.Dealers.chain().map(d => {
     let childObj = {};
     childObj.value = d.DealerCode;
     childObj.key = d.DealerCode;
     childObj.title = d.DealerName;
     return childObj;
    }).value();
    return obj;
   }).value();
   this.DefaultTreeData.push(
    this.treeData[0].key,
   );
  },
  async getSelectData() {
   let res = await this.$Http.GetAnalysisPartSelect();
   let a = this._.groupBy(res.Data, "PartId");
   this.treeData[0].children = Object.values(a).chain().map((d) => {
    let obj = {};
    obj.value = d[0].PartId;
    obj.key = d[0].PartId;
    obj.title = d[0].PartName;
    let b = this._.groupBy(d, "ProvinceId");
    obj.children = Object.values(b).chain().map(p => {
     let childObj = {};
     childObj.value = p[0]["ProvinceId"];
     childObj.key = p[0]["ProvinceId"];
     childObj.title = p[0]["ProvinceName"];
     childObj.children = p.chain().map(m => {
      let child2Obj = {};
      child2Obj.value = m["CityId"];
      child2Obj.key = m["CityId"];
      child2Obj.title = m["CityName"];
      return child2Obj;
     }).value();
     return childObj;
    }).value();
    return obj;
   }).value();
   this.DefaultTreeData.push(
    this.treeData[0].key,
   );
  },
  randomData() {
   return this._.random(0, 100);
  },
  async selectChange(value) {
   // let res = await this.$Http.GetCarCompete({
   //  carType: value.key,
   // });
   // if(!res.Data) {
   //  return false;
   // }
   //    let data = this.CompeteCarBakData[value.key].CompeteList;
      let data = this._.findWhere(this.CompeteCarBakData,{ShowCarTypeName:value.key}).CompeteList
      // 用于显示的列表

      let arr = [];
    let arrD = [];
      data.map((m, i) => {
          arr.push(
              {
                  label:m["CompeteCar"],
                  value:m["CompeteCarName"]
              }
          );
    // if(i < 3) {
    //  arrD.push(m["CompeteCar"]);
    // }
          if (m["IsDefault"]){
              arrD.push(

                  m["CompeteCarName"]
              )
          }
   });
   this.selectCompetitorDefaultValue = value;
   this.checkData.checkData7.data = arr;
   this.defaultCheckedData.defaultCheckedData7.data = arrD;
   this.onCheckChangeCompetitor();
  },
 },
 watch: {
  dataParams: {
   handler: async function() {
     await this.load();
   },
   deep: true,
  },
 },
};
</script>

<style scoped>
h2 {
    color: #333;font-size: 16px;font-weight: bold;
    }

.right {
    text-align: right;
    }

::v-deep .ve-chart {
    padding-top: 20px;
    }

.carding {
    margin-top: 10px;
    }
</style>
