import ta from "element-ui/src/locale/lang/ta";
import { percentage, tenThousandYuan, ThousandYuan, YtenThousandYuan } from "@/until/option";

export async function customTableGet(ctx, param, customTableData) {
    let IsAvg = "";
    for (const i in customTableData) {
        if (ctx._.findIndex(customTableData[i].table, "useAvg") !== undefined) {
            IsAvg = "_avg";
        }
        param.filterCodes = [];
        customTableData[i].table.forEach(d => {
            param.filterCodes.push(
                d.kpiCode + IsAvg,
            );
        });

        let res = await ctx.$Http.GetAreaDealerKpiReportWithMonth(param);
        let tableValues = res.Data.NormalValue;
        customTableData[i].table.forEach(d => {
            let arr = [];
            let ut = "";
            if (d.unit !== undefined) {
                ut = d.unit;
            } else {
                if (customTableData[i].unitD !== undefined) {
                    ut = customTableData[i].unitD;
                }
            }
            tableValues[d.kpiCode + IsAvg].forEach(val => {
                switch (ut) {
                    case "%":
                        val = percentage(val);
                        break;
                    case "万元":
                        val = tenThousandYuan(val);
                        break;
                    default:
                        val = ThousandYuan(val);
                }

                arr.push(val);
            });
            customTableData[i].data.push(
                { name: d.name, arr, indentation: d.indentation === undefined ? 0 : d.indentation },
            );
        });
    }
}
