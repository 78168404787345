<template>
    <a-row :gutter="[0,8]" class="mHeight">
        <a-col :span="24">
            <a-card style="text-align: center;">
                <a-descriptions bordered :column="2" style="padding-top: 10px">
                    <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem" :key="i">
                        {{ item.value }}
                    </a-descriptions-item>
                </a-descriptions>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h2>分析车型分析</h2>
                <a-my-table :columns-data="tableMouthData.tableMouth1.columns" :table-data="tableMouthData.tableMouth1"></a-my-table>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h3>各车型销售结构占比</h3>
                <chartComponent :chart-data="chartCarData.chartData_1"></chartComponent>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h3>各车型毛利分析</h3>
                <chartComponent :chart-data="chartData.chartData1"></chartComponent>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h3>新车库存及长库龄占比</h3>
                <chartComponent :chart-data="chartData.chartData2"></chartComponent>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h3>库销比</h3>
                <chartComponent :chart-data="chartData.chartData3"></chartComponent>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h3>各车型库存结构占比</h3>`
                <chartComponent :chart-data="chartCarData.chartData_2"></chartComponent>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <h3>人员效率</h3>
                <a-descriptions bordered :column="3" style="padding-top: 10px">
                    <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem1" :key="i">
                        {{ item.value }}
                    </a-descriptions-item>
                </a-descriptions>
            </a-card>
        </a-col>
<!--            <a-col :span="24">
              <a-card>
                <h3>销售顾问人均生产力及人均效率</h3>
                <chartComponent :chart-data="chartData.chartData4"></chartComponent>
              </a-card>
            </a-col>- -->
    </a-row>
</template>

<script>
import {
  barMaxWidth, monthGetData,
  mouth12Chart,
  settingsLabel, tableGetData,
  yAxisUnit,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import chartComponent from "@/components/chartComponent";
import echarts from "echarts";
import { oneData } from "@/views/monthReport/shopAnalysis/component/myData";
import {
  seriesBar,
  seriesLine,xAxis,
  yAxisDefaultUnit,
  yAxisRightUnit,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";

function toolFun(params, unit) {
  let arr = {};
  params.forEach((d, i) => {
    if(d.componentSubType === "bar") {
      if(unit === "万元") {
        arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${unit}`;
      } else if(unit === "%") {
        arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
      } else {
        arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${unit}`;
      }
    }
    if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
    }
  });
  let re = params[0].name + "<br />";
  Object.values(arr).forEach(a => {
    re += a + "<br />";
  });
  return re;
}

function labelFormat(params, unit) {
  if(params["componentSubType"] === "line") {
    return percentage(params.data);
  } else {
    if(unit === "万元") return tenThousandYuan(params.data);
    if(unit === "%") return percentage(params.data);
    return ThousandYuan(params.data);
  }
}
export default {
  name: "thereModel",
  components: { aMyTable, chartComponent },
  props: {
    allData: { type: Object },
    dataParams: {},
  },
  data() {
    return {
      descriptionsItem: [
        { title: "本月盈亏保本台次", value: "0台", kpiCode: "C0208001" },
        { title: "终端实绩全国标杆值", value: "0台", kpiCode: "C0208001" },
        { title: "本月终端实绩", value: "0台", kpiCode: "A0302001" },
        { title: "提车实绩全国标杆值", value: "0台", kpiCode: "A0302001" },
      ],
      descriptionsItem1: [
        { title: "本月销售顾问人数", value: "0台", kpiCode: "C0303030" },
        { title: "本月展厅销售顾问人数", value: "0台", kpiCode: "C0302314" },
        { title: "本月IDCC直销人数", value: "0台", kpiCode: "C0302315" },
      ],
      tableData: {
        tableData1: {
          table: [
            { name: "销售业务综合收入", kpiCode: "A0104090", ut: "1" },
            { name: "新车销售裸车收入", kpiCode: "A0103001", ut: "1" },
            { name: "商务政策返利", kpiCode: "A0104044", ut: "1" },
            { name: "新车销售水平事业收入", kpiCode: "A0103024", ut: "1" },
            { name: "销售业务综合毛利", kpiCode: "A0106024", ut: "1" },
            { name: "新车销售裸车毛利", kpiCode: "A0105001", ut: "1" },
            { name: "新车销售返利后毛利", kpiCode: "A0106001", ut: "1" },
            { name: "销售水平事业毛利", kpiCode: "A0104067", ut: "1" },
          ],
          columns: [],
          data: [],
        },
        tableData2: {
          table: [
            { name: "展厅终端占比", kpiCode: "A0304024" },
            { name: "展厅单人效率", kpiCode: "A0303200" },
            { name: "IDCC终端占比", kpiCode: "A0304025" },
            { name: "IDCC单人效率", kpiCode: "A0303201" },
            { name: "二网终端占比", kpiCode: "A0304026" },
            { name: "大客户终端占比", kpiCode: "A0304027" },
            { name: "大车占比", kpiCode: "A0304151" },
            { name: "保客相关销量占比", kpiCode: "A0304030" },
          ],
          columns: [],
          data: [],
        },
      },
      tableMouthData: {
        tableMouth1: {
          table: [
            { name: "GS3系列终端销量", kpiCode: ["A0303311", "A0303312"] },
            { name: "GS3系列单车毛利", kpiCode: ["A0106027", "A0106028"] },
            { name: "GS4系列终端销量", kpiCode: ["A0303313", "A0303314", "A0303315"] },
            { name: "GS4系列单车毛利", kpiCode: ["A0106029", "A0106030", "A0106031"] },
            { name: "GS4PLUS系列终端销量", kpiCode: "A0303316" },
            { name: "GS4PLUS系列单车毛利", kpiCode: "A0106032" },
            { name: "影豹系列终端销量", kpiCode: "A0303325" },
            { name: "影豹系列单车毛利", kpiCode: "A0106041" },
            { name: "GS8系列终端销量", kpiCode: ["A0303318", "A0303319"] },
            { name: "GS8系列单车毛利", kpiCode: ["A0106034", "A0106035"] },
            { name: "M6系列终端销量", kpiCode: "A0303320" },
            { name: "M6系列单车毛利", kpiCode: "A0106036" },
            { name: "M8系列终端销量", kpiCode: "A0303321" },
            { name: "M8系列单车毛利", kpiCode: "A0106037" },
            { name: "GA4系列终端销量", kpiCode: "A0303322" },
            { name: "GA4系列单车毛利", kpiCode: "A0106038" },
            { name: "GA6系列终端销量", kpiCode: "A0303323" },
            { name: "GA6系列单车毛利", kpiCode: "A0106039" },
            { name: "GA8系列终端销量", kpiCode: "A0303324" },
            { name: "GA8系列单车毛利", kpiCode: "A0106040" },
          ],
          columns: [],
          data: [],
        },
        tableMouth2: {
          table: [
            { name: "展厅客流", kpiCode: "A1303043" },
            { name: "展厅首次客流", kpiCode: "A1302012" },
            { name: "展厅再次客流", kpiCode: "A1302013" },
            { name: "展厅订单", kpiCode: "A1303080" },
            { name: "首次客户订单", kpiCode: "A1302014" },
            { name: "再次客户订单", kpiCode: "A1302111" },
            { name: "展厅销量", kpiCode: "A0302021" },
            { name: "首次成交率", kpiCode: "A1303081" },
            { name: "再次成交率", kpiCode: "A1303082" },
          ],
          columns: [],
          data: [],
          columnsI: 14,
        },
      },
      chartData: {
        chartData1: {
          columns: ["日期", "单车新车裸车毛利", "单车商政返利", "单车水平事业毛利"],
          kpiCode: [
            { Code: "A0106026", Name: "单车新车裸车毛利" },
            { Code: "A0105071", Name: "单车商政返利" },
            { Code: "A0105072", Name: "单车水平事业毛利" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["单车新车裸车毛利", "单车商政返利", "单车水平事业毛利"] },
            //  showLine: ["销售综合毛利率"],
            //  axisSite: { right: ["销售综合毛利率"] },
            yAxisType: ["0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "元");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "元");
              },
            },
            yAxis: [yAxisDefaultUnit('元')],
            xAxis,
            series: [seriesBar,seriesBar,seriesBar],
          },
        },
        chartData2: {
          columns: ["日期", "新车库存", "长库龄占比"],
          kpiCode: [
            { Code: "A0402006", Name: "新车库存" },
            { Code: "A0404111", Name: "长库龄占比" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["新车库存"] },
            showLine: ["长库龄占比"],
            axisSite: { right: ["长库龄占比"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "台");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "台");
              },
            },
            yAxis: [yAxisDefaultUnit('台'),yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesLine],
          },
        },
        chartData3: {
          columns: ["日期", "库销比"],
          kpiCode: [
            { Code: "A0404045", Name: "库销比" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["库销比"],
            yAxisType: ["0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "%");
              },
            },
          },
          options: {
            xAxis,
            yAxis:[yAxisDefaultUnit('%')],
            series: [
              {
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgba(43,230,129,1)",
                      }, {
                        offset: 0.5,
                        color: "rgba(43,230,129,0.5)",
                      }, {
                        offset: 1,
                        color: "rgba(255,255,255,0.3)",
                      },
                    ]),
                  },
                },
                data: [],
              },
            ],
          },
        },
        chartData4: {
          columns: ["日期", "人均销量", "人均销量全国标杆", "人均生产力", "人均生产力全国标杆"],
          kpiCode: [
            { Code: "A0304034", Name: "人均销量" },
            { Code: "A0304034", Name: "人均销量全国标杆" },
            { Code: "A0107014", Name: "人均生产力" },
            { Code: "A0107014", Name: "人均生产力全国标杆" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人均生产力", "人均生产力全国标杆"],
            axisSite: { right: ["人均生产力", "人均生产力全国标杆"] },
            yAxisType: ["0", "0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return ThousandYuan(params.data);
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}台`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}元`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            yAxis: [yAxisDefaultUnit('台'), yAxisRightUnit('元')],
            xAxis,
            series: [seriesBar,seriesBar,seriesLine,seriesLine],
          },
        },
      },

      chartCarData: {
        chartData_1: {
          columns: oneData.salesTable.chartCarData.chartData_1.columns,
          rows: oneData.salesTable.chartCarData.chartData_1.rows,
          settings: {
            type: "histogram",
            showLine: ["各车型销量占比"],
            axisSite: { right: ["各车型销量占比"] },
            yAxisType: ["0", "0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return Number(params.data ?? 0).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                if(params[0] && params[1]) {
                  let value1 = params[0].value ?? 0;
                  let value2 = params[1].value ?? 0;
                  let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params[0]["seriesName"] + ": " + (Number(value1).toFixed(0)) + "台",
                    val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params[1]["seriesName"] + "： " + Number(value2).toFixed(1) + "%";
                  return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                } else {
                  return "";
                }
              },
            },
            yAxis: [yAxisDefaultUnit("台"), yAxisRightUnit("%")],
            xAxis,
            series: [seriesBar, seriesLine],
          },
        },
        chartData_2: {
          columns: oneData.salesTable.chartCarData.chartData_2.columns,
          rows: oneData.salesTable.chartCarData.chartData_2.rows,
          settings: {
            type: "histogram",
            stack: { all: ["0~30天期末新车库存", "31~60天期末新车库存", "61~90天期末新车库存", ">90天期末新车库存"] },
            showLine: ["期末库存台次占期末新车库存台次比"],
            axisSite: { right: ["期末库存台次占期末新车库存台次比"] },
            yAxisType: ["0", "0"],
            label: {
              show: true, // 显示数字
              //   position: "top",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return Number(params.data ?? 0).toFixed(1) + "%";
                } else {
                  return Number(params.data ?? 0).toFixed(0);
                }
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'

              },
              formatter: function(params) {
                let arr = [];
                params.forEach((d, i) => {
                  let minvalue = 0;
                  if(d.value === undefined) {
                    minvalue = "-";
                  } else {
                    if(i === 4) {
                      minvalue = Number(d.value).toFixed(1) + "%";
                    } else {
                      minvalue = Number(d.value).toFixed(0) + "台";
                    }
                  }
                  arr.push({
                    val: `<span style="background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;"></span>${d["seriesName"]}: ${minvalue}`,
                  });
                });
                return `${params[0]["axisValue"]} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}<br />${arr[3].val}<br />${arr[4].val}`;
              },
            },
            yAxis: [yAxisDefaultUnit('台'),yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesBar,seriesBar,seriesLine],
          },
        },
      },
    };
  },
  created() {
    this.getDescriptionsItem();
    this.getTableData();
    this.getChartData();
  },
  methods: {
    getDescriptionsItem() {
      const rich = this.allData["Rich"];
      if(!rich) {
        return false;
      }
      this.descriptionsItem.forEach((d, i) => {
        if(!rich[d.kpiCode]) {
          d.value = 0;
        } else {
          if(i === 1 || i === 3) {
            d.value = Number(rich[d.kpiCode].NationwideStandard ?? 0).toFixed(0) + rich[d.kpiCode].Unit;
          } else {
            d.value = Number(rich[d.kpiCode].Current ?? 0).toFixed(0) + rich[d.kpiCode].Unit;
          }
        }
      });
      this.descriptionsItem1.forEach((d) => {
        if(!rich[d.kpiCode]) {
          d.value = 0;
        } else {
          d.value = Number(rich[d.kpiCode].Current ?? 0) + rich[d.kpiCode].Unit;
        }
      });
    },
    getTableData() {
      const monthPush = this.allData["MonthPush"];
      const rich = this.allData["Rich"];
      /*tableMouthData*/
     // setTimeout(()=>{
        monthGetData(this.tableMouthData.tableMouth1, monthPush, this.dataParams.ReportDate, 15);
    //  },1000)

      /*  Object.keys(this.tableMouthData).forEach((d, val) => {
          monthGetData(this.tableMouthData[d], monthPush, this.dataParams.ReportDate, d.columnsI);
        });*/

      Object.keys(this.tableData).forEach(d => {
        tableGetData(this.tableData[d], rich);
      });
      setTimeout(() => {
        let arr = [];
        this.chartData.chartData3.rows.forEach(d => {
          arr.push(d["库销比"] ?? 0);
        });
        this.chartData.chartData3.options.series[0].data = arr;
      });

    },
    getChartData() {
      const monthPush = this.allData["MonthPush"];
      const monthRank = this.allData["Rich"];
      const monthStandardPush = this.allData["MonthStandardPush"];
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], monthPush, monthStandardPush, this.dataParams.ReportDate);
      });
      /*charCarData*/
      this.chartCarData.chartData_1.rows.forEach(d => {
        d["各车型终端销量"] = this.carDataCallback(d, d.kpiCode, monthRank);
        d["各车型销量占比"] = this.carDataCallbackP(d, d.percentageCode, monthRank);
      });
      this.chartCarData.chartData_2.rows.forEach(d => {
        d["0~30天期末新车库存"] = this.carDataCallback(d, d.kpiCode, monthRank);
        d["31~60天期末新车库存"] = this.carDataCallback(d, d.percentageCode, monthRank);
        d["61~90天期末新车库存"] = this.carDataCallback(d, d.sixCode, monthRank);
        d[">90天期末新车库存"] = this.carDataCallback(d, d.nineCode, monthRank);
        d["期末库存台次占期末新车库存台次比"] = this.carDataCallbackP(d, d.expirationCode, monthRank);
      });

    },
    carDataCallback(d, kpi, monthRank) {
      let kData = 0;
      kpi.forEach(k => {
        if(!monthRank[k]) {
          return false;
        }
        let kd = 0;
        /*   if (k === "A0402025") console.log(k, monthRank[k].Current);
           if (k === "A0402031") console.log(k, monthRank[k].Current);
           if (k === "A0303315") console.log(k, monthRank[k].Current);*/
        kd = Number(monthRank[k].Current ?? 0);
        if(k === "") {
          kd = 0
        }
        kData = Number(kd) + Number(kData);
      });
      return kData;
    },
    carDataCallbackP(d, kpi, monthRank) {
      let kData = 0;
      kpi.forEach(k => {
        if(!monthRank[k]) {
          return false;
        }
        let kd = 0;
        kd = Number(monthRank[k].Current ?? 0);
        kData = Number((Number(kd) + Number(kData)));
        if(k === "") {
          kd = 0
          kData = 0
        }
      });
      return Number(kData * 100).toFixed(1);
    },
  },
  watch: {
    allData: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
@import "../component/shopAnalysis.less";
</style>
