<template>
  <a-row :gutter="[0,8]">
    <a-col :span="24" v-if="isCloseA">
      <a-anchor :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          <a-col :span="20">
            月份：
            <a-month-picker style="width: 130px;" placeholder="请选择报告月份" @change="dateChange"
                :disabledDate="disabledDate" v-model="ReportDateMoment" :allowClear="false"
            />
            销售店：
            <a-cascader style="width: 240px" :options="dealers" :allowClear="false"
                placeholder="请选择/输入销售店" v-model="choseDealer" @change="dealerChange"
                :show-search="true"
            />
          </a-col>
          <a-col style="float: right;text-align: right">
                    <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
            <a-tooltip placement="top" :title="`点击生成《${ moment(this.ReportDateMoment).format('yyyy年MM月')+this.params.DealerName }店端经营业绩分析报告-${this.getTitle(activeKey)}》`">
              <a-button type="primary" @click="generateImages()">生成长图</a-button>
            </a-tooltip>
            <a-button type="primary" @click="doPrint" style="float: right;">生成PDF</a-button>
          </a-col>
        </div>
      </a-anchor>
    </a-col>
    <a-col span="24">
      <div class="" ref="capture">
        <div class="report_bg1">
          BDM业绩分析报告 ({{ views[activeKey].name }})
          <br><span style="font-size: 22px;font-weight: 500">{{
            params.DealerName  + moment(ReportDateMoment).format("  yyyy年M月") }}</span></div>
        <one-operating :all-data="allData" :data-params="params" v-if="activeKey==='07c6717e1'"/>
        <two-sales :all-data="allData" :data-params="params" v-else-if="activeKey==='0df8904e2'"/>
        <there-model :all-data="allData" :data-params="params" v-else-if="activeKey==='df39c36c3'"/>
        <four-after :all-data="allData" :data-params="params" v-else-if="activeKey==='c0142d9a4'"/>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import moment from "moment";
import { disabledDate } from "@/until/dayFormat";
import twoSales from "@/views/monthReport/shopAnalysis/childComponent/twoSales";
import oneOperating from "@/views/monthReport/shopAnalysis/childComponent/oneOperating";
import thereModel from "@/views/monthReport/shopAnalysis/childComponent/thereModel";
import fourAfter from "@/views/monthReport/shopAnalysis/childComponent/fourAfter";
import { kpiCode } from "@/views/monthReport/shopAnalysis/component/kpiCode";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  components: { oneOperating, twoSales, thereModel, fourAfter },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
  watch: {
    "$route"(to, from) {
      if(this.$route.query.key) {
        this.activeKey = this.$route.query.key;
      }
    },
  },
  async created() {
    this.params.ReportDate = this.$route.query.ReportDate ?? moment().add(-1, "M").format("YYYY-MM-01");
    this.params.DealerCode = this.$route.query.DealerCode ?? "";
    this.params.DealerName = this.$route.query.DealerName ?? "";

    let key = this.$route.query.key;
    let view = this.views[key];
    if(!view) {
      this.$message.error("请求参数有误，请联系管理员处理");
      return;
    }
    this.activeKey = key;

    await this.getDealersByRole();
    await this.getDealerInfo();
  },
  data() {
    return {
      moment,
      isCloseA: true,
      activeKey: "NONE",
      views: {
        "07c6717e1": {
          name: "经营概览",
        },
        "0df8904e2": {
          name: "销售业务-分通道",
        },
        "df39c36c3": {
          name: "销售业务-分车型",
        },
        "c0142d9a4": {
          name: "售后业务",
        },
      },
      params: {
        ReportDate: "",
        DealerCode: "",
        DealerName: "",
      },
      dealerInfo: {},
      dealerMap: {},
      dealers: [],
      choseDealer: [],
      allData: {},
      chartData: {},
    };
  },
  methods: {
    // 日期框点击方法
    dateChange(date, dateString) {
      this.params.ReportDate = dateString + "-01";
      //日期修改后同步数据给子组件
      this.getDealerInfo();
      //  this.getData();
    },
    async dealerChange(value, val) {
      this.params.DealerCode = val[1].value;
      this.params.DealerName = val[1].label;
      await this.getData();
    },
    disabledDate,
    // 点击打印双预览
    async doPrint() {
      let res = await this.$Http.PdfGenerateByUrl({
        Url: `${window.location.origin}/monthReport/print2?ReportDate=${this.params.ReportDate}&DealerCode=${this.params.DealerCode}&DealerName=${this.params.DealerName}`,
        FileName: `${this.ReportDateMoment.format("YYYY年MM月")}${this.params.DealerName}经营分析报告`,
        PageWidth: 16.54,
        PageHeight: 11.7,
        Cookie: document.cookie,
      });
      if(res.isError) {
        this.$message.error(res["errMsg"]);
        return;
      }
      let a = document.createElement("a");
      a.href = res.data["pdfUrl"];
      a.target = "_blank";
      a.click();
    },
    formatTime(time, format) {
      return moment(time).format(format);
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      this.params.isClose = false;
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, `${moment(this.ReportDateMoment).format("yyyy年M月") + this.params.DealerName}店端经营业绩分析报告-${this.getTitle(this.activeKey)}.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${moment(this.ReportDateMoment).format("yyyy年M月") + this.params.DealerName}店端经营业绩分析报告-${this.getTitle(this.activeKey)}.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
    async getData() {
      this.params["monthRankCodes"] = this._.uniq(kpiCode.monthRankCodes);
      this.params["yearRankCodes"] = this._.uniq(kpiCode.monthRankCodes);
      this.params["monthPushCodes"] = this._.uniq(kpiCode.monthPushCodes);
      this.params["richCodes"] = kpiCode.richCodes;
      this.params["monthStandardPushCodes"] = kpiCode.monthStandardPush;
      let res = await this.$Http["DealerBusinessAnalysisReport"](this.params);
      if(res.Data)
        this.allData = res.Data;
    },
    // 获取当前选择的经销商信息
    async getDealerInfo() {
      let res = await this.$Http.getCurrDealerInfo({
        DealerCode: this.params.DealerCode,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      // this.choseDealer = this.dealerMap[this.params.DealerCode];
      this.choseDealer = this.dealerMap[res.Data.DealerCode];
      if(this.choseDealer == null) {

        this.params.DealerCode = this.dealerMap[Object.keys(this.dealerMap)[0]][1];
        if(this.params.DealerCode != null) {
          await this.getDealerInfo();
        }
        return;
      }

      this.dealerInfo = res.Data;
      this.params.DealerCode = res.Data.DealerCode;
      this.params.DealerName = res.Data.DealerName;

      await this.getData();
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      let dealerCount = 0;
      this.dealers = res.Data.chain().map(p => {
        dealerCount += p.Dealers.length;
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          this.dealerMap[dealer.value] = [part.value, dealer.value];
          return dealer;
        }).value();
        return part;
      }).value();
      this.hasMultipleDealer = dealerCount > 1;
    },
    getTitle(activeKey) {
      switch (activeKey) {
      case "2":
        return "销售业务-分通道";
      case "3":
        return "销售业务-分车型";
      case "4":
        return "售后业务";
      default:
        return "经营概览";
      }
    },
  },
  name: "shopAnalysis",
};
</script>
<style lang="less" scoped>
.fix_border {
  padding: 20px 10px 20px 10px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  margin-left: -2px;
  }

.space {
  margin-left: 20px;

  .ant-tag {
    cursor: pointer;
    padding: 4px 13px;
    }
  }

.border {
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  }

.report_bg1 {
  height: 136px;padding-top: 30px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  }

.info {
  clear: both;
  margin: 12px auto;
  background: #E6F7FF;
  padding: 24px 22px;
  border: 1px solid #BAE7FF;
  }

.pai-ming {
  padding-top: 20px;
  margin-right: 40px;
  color: #24619e;
  font-size: 30px;

  em {
    font-size: 50px;
    }

  }

.zhi-bio {
  ul {
    li {
      float: left;
      text-align: center;

      > div {
        border: 1px solid #dddddd;
        height: 88px;
        padding: 14px;
        background: rgba(242, 245, 248, 0.6);

        div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 4px solid rgba(67, 167, 16, 0.3);

          span {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #43A710;
            }
          }
        }

      > p {
        height: 25px;
        line-height: 25px;
        background: #e8e8e8;
        border: 1px solid #dddddd;
        }

      &:nth-child(2) {
        > div {
          div {
            border: 4px solid rgba(88, 163, 247, 0.3);

            span {
              background: #58A3F7;
              }
            }
          }
        }

      &:nth-child(3) {
        > div {
          div {
            border: 4px solid rgba(255, 197, 66, 0.3);

            span {
              background: #FFC542;
              }
            }
          }
        }

      &:nth-child(4) {
        > div {
          div {
            border: 4px solid rgba(253, 89, 90, 0.3);

            span {
              background: #FD595A;
              }
            }
          }
        }

      /*选中状态*/

      &:nth-child(1).hover {
        > div {
          border: 1px solid #43A710;
          }

        > p {
          background: #43A710;
          color: #ffffff;
          border: 1px solid #43A710;
          }
        }

      &:nth-child(2).hover {
        > div {
          border: 1px solid #58A3F7;
          }

        > p {
          background: #58A3F7;
          color: #ffffff;
          border: 1px solid #58A3F7;
          }
        }

      &:nth-child(3).hover {
        > div {
          border: 1px solid #FFC542;
          }

        > p {
          background: #FFC542;
          color: #ffffff;
          border: 1px solid #FFC542;
          }
        }

      &:nth-child(4).hover {
        > div {
          border: 1px solid #FD595A;
          }

        > p {
          background: #FD595A;
          color: #ffffff;
          border: 1px solid #FD595A;
          }
        }
      }
    }
  }

@import "component/shopAnalysis.less";
</style>
