let dataStruct = {
    "A0302001": {
        name: "终端销量",
        children: {
            "各渠道新车销量": {
                "A1302012+A1302013": {name: "展厅客流数量"},
                "A1303083": {name: "展厅订单成交率"},
                "A1302115": {name: "IDCC线索总数"},
                "A1303032": {name: "IDCC线索转化率"},
                "A0302021": {name: "展厅新车销量"},
                "A0303006": {name: "二网平均单店销量"},
                "C0303031": {name: "二网店总数量"},
                "A0302023": {name: "二网新车销量"},
                "A0302024": {name: "大客户新车销量"},
            },
            "各库龄新车销量占比": {
                "A0403024": {name: "0~30天新车台次占比"},
                "A0403025": {name: "31~60天新车台次占比"},
                "A0403026": {name: "61~90天新车台次占比"},
                "A0403027": {name: ">90天新车台次占比"},
            },
            "销售人均销量": {
                "A0304034": {name: "销售顾问人均销量"}
            },
        },
    },
    "x": null,
    "A0107004": {
        name: "平均单车销售业务毛利",
        children: {
            "新车平均单车毛利": {
                "A0106026": {name: "平均单车新车裸车毛利(一级)"},
                "A0105071": {name: "平均单车新车销售返利(二级)"},
                "A0105072": {name: "平均单车销售水平事业毛利(三级)"},
            },
            "各渠道新车销量占比": {
                "A0304024": {name: "展厅销量占新车总销量比"},
                "A0304025": {name: "IDCC销量占新车总销量比"},
                "A0304026": {name: "二网销量占新车总销量比"},
                "A0304027": {name: "大客户销量占新车总销量比"},
            },
            "新车水平事业渗透": {
                "A0303271": {name: "新车用品渗透率"},
                "A0303272": {name: "新车保险渗透率"},
                "A0303273": {name: "金融按揭渗透率"},
                "A0303274": {name: "上牌验车渗透率"},
                "A0303275": {name: "二手车置换率"},
                "A0303276": {name: "新车延保渗透率"},
                "A0303277": {name: "销售自主服务项目渗透率"},
                "A0303278": {name: "特装车渗透率"},
            },
            "新车毛利率": {
                "A0106023": {name: "裸车毛利率"},
                "A0107001": {name: "销售返利后毛利率"},
                "A0105023": {name: "销售水平业务毛利率"},
                "A0107002": {name: "销售业务毛利率"},
            },
            "平均单车销售水平事业毛利": {
                "A0104115": {name: "单车新车精品毛利"},
                "A0104118": {name: "单车上牌服务毛利"},
                "A0104116": {name: "单车新车保险毛利"},
                "A0104117": {name: "单车金融按揭毛利"},
                "A0104119": {name: "单车二手车毛利"},
            },
            "销售人均毛利": {
                "A0107014": {name: "销售顾问人均毛利(生产力)"},
            }
        },
    },
    "+": null,
    "B0302001": {
        name: "售后来厂台次",
        children: {
            "客户招揽": {
                "B0303071": {name: "首保招揽成功率"},
                "B0303072": {name: "定保招揽成功率"},
                "B0303033": {name: "出险留修率"},
                "B0303052": {name: "次新车续保率"},
            },
            "售后工位周转": {
                "B0304001": {name: "机电工位日均周转次数"},
            }
        },
    },
    "x ": null,
    "B0106027": {
        name: "平均单车售后业务毛利",
        children: {
            "售后单车毛利": {
                "B0105160": {name: "平均单车售后维修毛利(工时+零部件+返利)"},
                "B0105134": {name: "平均单车售后水平事业总毛利(对进厂台次)"},
            },
            "售后水平事业渗透": {
                "B0303005": {name: "售后用品渗透率"},
                "B0303006": {name: "售后养护品渗透率"},
                "B0303007": {name: "售后美容渗透率"},
                "B0304003": {name: "售后续保渗透率"},
                "B0303008": {name: "非新车延保渗透率"},
                "B0303039": {name: "售后自主服务项目渗透率"},
            },
            "售后单车收入": {
                "B0105114": {name: "售后单车收入"},
            },
            "售后业务毛利率": {
                "B0106026": {name: "售后业务毛利率"},
                "B0105108": {name: "售后水平业务毛利率"},
                "B0105044": {name: "工时毛利率"},
                "B0105043": {name: "零部件毛利率"},
                "B0105087": {name: "机电毛利率"},
                "B0105098": {name: "钣喷毛利率"},
            },
            "各库龄零部件库存占比": {
                "B0404001": {name: "零部件库存周转天数"},
                "B0403003": {name: "0~90天金额占比"},
                "B0403004": {name: "91~180天金额占比"},
                "B0403005": {name: "181~365天金额占比"},
                "B0403006": {name: ">365天金额占比"},
            },
            "售后人均生产力": {
                "B0306002": {name: "维修技师人均生产力（毛利）"},
            },
        },
    },
    "-": null,
    "C0206001": {
        name: "总费用",
        children: {
            "销售部费用": {
                "A0205001": {name: "销售部门总费用"},
                "A0206011": {name: "平均单车费用(销售)"},
                "A0205034": {name: "单车广宣费用"},
                "A0205035": {name: "单车财务费用"},
            },
            "售后部费用": {
                "B0205011": {name: "售后部门总费用"},
                "B0205011/B0302001": {name: "售后单车费用"},
            },
            "费用占收入比": {
                "C0207022": {name: "总费用占总收入比"},
                "C0207028": {name: "人力费用占总收入比"},
                "C0207029": {name: "销售部人力费用占总收入比"},
                "C0207030": {name: "售后部人力费用占总收入比"},
            },
            "费用占毛利比": {
                "C0408001": {name: "总费用占总毛利比"},
            },
            "零服吸收率": {
                "B0107002": {name: "零服吸收率"},
            },
            "单车费用": {
                "C0207009": {name: "单车总费用"},
            },
            "毛利占费用比": {
                "C0408013": {name: "售后及水平事业毛利占总费用比"},
            },
        },
    },
    "=": null,
    "C0407005": {
        name: "营业利润",
        children: {
            "税前利润率": {
                "C0408021": {name: "税前利润率"},
            },
        },
    },
}

export function appendFilterCode(filterCodes, data) {
    Object.keys(data).forEach(key => {
        let dataObj = data[key];
        if (dataObj == null)
            return;

        filterCodes[key] = dataObj.name;
        if (dataObj.children) {
            Object.values(dataObj.children).forEach(values => appendFilterCode(filterCodes, values));
        }
    });
}

export function GetFilterCodes() {
    let filterCodes = {};
    appendFilterCode(filterCodes, dataStruct);
    return filterCodes;
}

export {dataStruct}
