<!--
*  @author zyzhan 20210301
-->

<template>
  <a-table :columns="columnsData" :data-source="tableData.data" bordered :pagination="false"
           :row-key="(record, index) => ++index">
    <div slot="name" slot-scope="text,record" :style="record.indentation>0 ? 'paddingLeft:'+record.indentation+'em':''">
      {{ record.name }}
    </div>
  </a-table>
</template>

<script>
export default {
  name: "monthReportTableComponent",
  props: {
    pageIndex: Number,
    tableData: {
      data: [],
    },
    columnsData: {},
  },
  data() {
    return {
      /*  columns: [
          {
            title: "指标名称",
            //   dataIndex: "name",
            key: "name",
            scopedSlots: { customRender: "name" },
          },
          {
            title: "单位",
            dataIndex: "Unit",
            key: "Unit",
          },
          {
            title: "全国标杆值",
            dataIndex: "NationwideStandard",
            key: "NationwideStandard",
          },
          {
            title: "区域标杆值",
            dataIndex: "RegionalStandard",
            key: "RegionalStandard",
          },
          {
            title: "当月值",
            dataIndex: "Current",
            key: "Current",
          },
          {
            title: "上月值",
            dataIndex: "LastMonth",
            key: "LastMonth",
          },
          {
            title: "去年同期",
            dataIndex: "LastYear",
            key: "LastYear",
          },
          {
            title: "年度累计",
            dataIndex: "CurrentYear",
            key: "CurrentYear",
          },
        ],*/
    };
  },
  created() {
  },
};
</script>

<style lang="less" scoped>

/*控制表格*/
::v-deep {

  .ant-table-thead > tr > th {
    padding: 5px 5px 5px 7px;
    width: 10%;
    border-right: 0 solid #ffffff !important;
    border-left: 0 solid #ffffff !important;
    font-size: 12px;
    background: #33554A;
    color: #fff;
    text-align: right;
    &:first-child{
      text-align: left;
      width: 15%;
    }
  }

  .ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table{
   // border-top: 2px solid #86A3A1;
    border-bottom: 1px solid #86A3A1;
    border-left:0 ;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
    background: none;
  }

  .ant-table-tbody .ant-table-row {
    &:nth-child(even) {
        background: #E6ECEC !important;
    }

  &:hover{
   td{
     color: #405E5C !important;
   }
    background: #adcbca !important;
    box-shadow: 1px 2px 5px 1px #768e86
  }

    td {
      font-size: 12px;
      padding: 5px 5px;
      text-align: right;
      border-right: 0 solid #fff;
      border-bottom: 0 solid #fff;
      color: #3E5E5C;

      &:first-child {
        text-align: left;
      }

    }
  }
}
</style>
