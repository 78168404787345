<template>
    <section>
        <a-card>
            <div class="title-part">
                <div class="left">
                    <div class="item">
                        一级指标：
                        <a-select show-search style="width: 200px" v-model="queryForm.FirstCategory"
                                  :allowClear="true" @change="firstKpiCodeChange" :filterOption="selectorFilter">
                            <a-select-option v-for="(item,i) in FirstKpiList" :key="i" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="item">
                        二级指标：
                        <a-select show-search style="width: 200px" v-model="queryForm.KpiCode"
                                  :allowClear="true" :filterOption="selectorFilter">
                            <a-select-option v-for="(item,i) in KpiList" :key="i" :value="item.KpiCode"  :disabled="item.disabled">
                                {{ item.KpiName }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="item">
                        文件名：
                        <a-input style="width: 200px" v-model="queryForm.FileUploadName"></a-input>
                    </div>
                    <div class="item">
                        <a-button type="primary" @click="getList(1)">搜索</a-button>
                    </div>
                </div>
                <div class="right">
                    <div>
                        <a-button type="primary" @click="dialogShow=true" v-if="permissionName !== '财务经理填报'">上传案例集
                        </a-button>
                    </div>
                    <div>
                        <a-button type="primary" ghost @click="$router.go(-1)">
                            返回
                        </a-button>
                    </div>
                </div>
            </div>
        </a-card>
        <a-card>
            <a-table :columns="columns" :data-source="tabledata"
                     @change="tableChange"
                     :pagination="paginationParam"
                     rowKey="Id"
            >
                <span slot="action" slot-scope="text, record">
<!--                  <a @click="$router.push('/improvePlan/viewImproveView?StartTime='+record.PlanStartTime+'&PlanId='+record.PlanId)">查看详情</a>-->
                    <a-button type="primary" size="small" @click="downLoad(record)">下载</a-button>
                    <a-button type="primary" size="small" @click="deletedata(record)">删除</a-button>
                </span>
            </a-table>
        </a-card>
        <a-modal v-model="dialogShow" title="上传案例集" @ok="handleOk" @cancel="handleCancel" okText="提交"
        >
            <a-form-model
                :rules="rules"
                ref="dialogForm"
                :model="dialogForm"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 20 }">
                <a-form-model-item label="一级指标" prop="FkpiName">
                    <a-select show-search style="width: 200px"
                              :allowClear="true" @change="dialogFirstKpiCodeChange" :filterOption="selectorFilter"
                              v-model="dialogForm.FkpiName">
                        <a-select-option v-for="(item,i) in FirstKpiList" :key="i" :value="item">
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="二级指标" prop="KpiCode">
                    <a-select show-search style="width: 200px"
                              :allowClear="true" :filterOption="selectorFilter"
                              v-model="dialogForm.KpiCode">
                        <a-select-option v-for="(item,i) in diaLogKpiList" :key="i" :value="item.KpiCode" :disabled="item.disabled">
                            {{ item.KpiName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="上传文件">
                    <a-upload
                        name="file"
                        action="/apis/DealerKpi/SecondBDM/UploadFile?DealerName=厂端上传"
                        @change="uploadFileChange"
                        :fileList="fileList"
                    >
                        <a-button v-if="fileList.length < 1">
                            <a-icon type="upload"/>
                            点击上传
                        </a-button>
                    </a-upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </section>
</template>

<script>
import moment from "moment";

const columns = [
    {
        title: "文件名",
        dataIndex: "FileUploadName",
        key: "FileUploadName",
    },
    {
        title: "一级指标名称",
        dataIndex: "FirstCategory",
        key: "FirstCategory",
    },
    {
        title: "二级指标名称",
        key: "KpiName",
        dataIndex: "KpiName",
    },

    {
        title: "创建时间",
        key: "CreateTime",
        dataIndex: "CreateTime",
    },
    {
        title: "创建人",
        key: "Creator",
        dataIndex: "Creator",
    },

    {
        title: "操作",
        key: "action",
        scopedSlots: {customRender: "action"},
    },

];

export default {
    name: "list",
    data() {
        return {
            //已上传文件列表
            fileList: [],
            tabledata: [],
            columns,
            dialogShow: false,
            //查询对象
            queryForm: {
                pageSize: 10,
                pageIndex: 1,
                FirstCategory: '',
                KpiCode: '',
                FileUploadName: ""
            },
            //分页参数
            paginationParam: {
                current: 1,
                total: 0,
                pageSize: 10,
            },
            //一级指标列表
            FirstKpiList: [],
            //二级指标列表
            KpiList: [],
            dialogForm: {
                // 一级指标名称
                FkpiName: '',
                // 二级指标
                KpiCode: '',
                // 文件上传名
                FileUploadName: '',
                // 文件对象值
                ObjectKey: ''
            },
            diaLogKpiList: [],
            upLoadStatus: false,
            permissionName: '',
            rules:
                {
                    KpiCode: [
                        {
                            required: true,
                            message: '请选择指标',
                            trigger: ["blur", "change"]
                        },
                    ]
                }
        };
    },
    mounted() {
        this.getList(1);
        this.getFirstKpiList();
        this.getPermisson();
    },
    methods: {
        async getPermisson() {
            let res = await this.$Http.GetOwnerPermissionName();
            if (res.Success) {
                if (res.Data.length > 0) {
                    this.permissionName = res.Data[0].PermissionName;
                }
            } else {
                this.$message.error(res.Message);
            }
        },
        async handleOk() {
            this.$refs.dialogForm.validate(async (valid) => {
                if (valid) {
                    if (this.fileList.length < 1) {
                        this.$message.error("请上传改善文件");
                        return false;
                    }
                    if (!this.upLoadStatus) {
                        this.$message.error("文件上传中，请等待上传完成");
                        return false;
                    }
                    let res = await this.$Http.InsertBookCase(this.dialogForm);
                    if (res.Success) {
                        this.dialogShow = false;
                        this.handleCancel();
                        this.getList(1);
                    } else {
                        this.$message.error(res.Message);
                    }
                }
            });

        },
        //取消时,清空form
        handleCancel() {
            this.$refs["dialogForm"].resetFields();
            this.dialogForm.FileUploadName = '';
            this.dialogForm.ObjectKey = '';
            this.fileList = [];
        },
        uploadFileChange(info) {
            this.fileList = info.fileList;
            //上传成功的回调
            if (info.file.status === "done") {
                this.upLoadStatus = true;
                //获取objectKey
                let res = info.file.response
                if (res.Success) {
                    this.dialogForm.ObjectKey = res.Data.objectKey;
                    this.dialogForm.FileUploadName = res.Data.fileName;
                } else {
                    this.$message.error(res.Message)
                }
            }
        },
        async downLoad(record) {
            //下载
            let res = await this.$Http.FileShareLink({objectkey: record.ObjectKey});
            if (res.Success) {
                window.open(res.Data)
            } else {
                this.$message.error(res.Message)
            }
        },
      async deletedata(record) {
        //删除数据行
        let res = await this.$Http.DeleteBookCase({Rowid: record.Id});
        if (res.Success) {
          await this.getList(this.paginationParam.current);
        } else {
          this.$message.error(res.Message)
        }
      },
        tableChange(pagination, filters, sorter, {currentDataSource}) {
            this.paginationParam.current = pagination.current;
            this.getList(this.paginationParam.current);
        },
        selectorFilter(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async firstKpiCodeChange() {
            this.queryForm.KpiCode = '';
            let res = await this.$Http.GetWeakCodeByFirst({FirstCategory: this.queryForm.FirstCategory})
            if (res.Success) {
              if(res.Data.length>50) {
                this.KpiList =[...res.Data.filter((_k,i)=>i<100),{KpiCode:"tip",KpiName:"（仅展示前100条）",disabled:true}];
              }else {
                this.KpiList  = res.Data;
              }
            } else {
                this.$message.error(res.Message);
            }
        },
        async getFirstKpiList() {
            let res = await this.$Http.GetWeakCodeList();
            if (res.Success) {
                this.FirstKpiList = this._.pluck(res.Data, "FirstCategory");
            } else {
                this.$message.error(res.Message);
            }
        },
        async getList(pageIndex) {
            try {
                let self = this;
                //将分页参数传入form对象
                self.queryForm.pageSize = self.paginationParam.pageSize;
                self.queryForm.pageIndex = self.paginationParam.current = pageIndex;
                let res = await self.$Http.GetCaseBookListPage(self.queryForm)
                if (res.Success) {
                    self.tabledata = res.Data;
                    self.paginationParam.total = res.TotalCount;
                } else {
                    self.$message.error(res.Message);
                }
            } catch (e) {
                this.$message.error(e)
            }
        },
        // 对话框一级指标改变
        async dialogFirstKpiCodeChange(data) {
            this.dialogForm.KpiCode = '';
            let res = await this.$Http.GetWeakCodeByFirst({FirstCategory: data});
            if (res.Success) {
              if(res.Data.length>50) {
                this.diaLogKpiList=[...res.Data.filter((_k,i)=>i<50),{KpiCode:"tip",KpiName:"（仅展示前50条）",disabled:true}];
              }else {
                this.diaLogKpiList = res.Data;
              }
            } else {
                this.$message.error(res.Message);
            }
        }
    },
    filters: {
        FormatDate: function (value) {
            return moment(value).format("YYYY-MM-DD")
        },
    }
};
</script>

<style scoped>
.title-part {
    display: flex;
    justify-content: space-between;
}

.title-part > .left {
    display: flex;
}

.title-part > .right {
    display: flex;
}

.title-part > .left > .item {
    padding-left: 10px;
}
</style>
