<template>
  <a-card class="card">
    <a-form-model
      :rules="rules"
      :model="form"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 6 }"
    >
      <a-form-model-item label="部门名称" prop="departmentName">
        <a-input v-model="form.departmentName" />
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="sort">
        <a-input-number v-model="form.sort" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" @click="save"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: {
        departmentName: "",
        sort: null,
        departmentId: "",
      },
      rules: {
        departmentName: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: ["blur", "change"],
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async init() {
      let self = this;
      //编辑
      if (self.$route.path == "/departmentMannage/edit") {
        //获取当前数据
        let res = await self.$Http.GetDepartmentInfoById({
          departmentId: self.$route.query.departmentId,
        });
        if (res.Data.length > 0) {
          self.form.departmentName = res.Data[0].DepartmentName;
          self.form.sort = res.Data[0].Sort;
          self.form.departmentId = res.Data[0].DepartmentId;
        }
      } else {
        this.getSort();
      }
    },
    async save() {
      let self = this;
      self.$refs.form.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res =
            self.$route.path == "/departmentMannage/edit"
              ? await self.$Http.EditDepartment(self.form)
              : await self.$Http.InsertDepartment(self.form);
          if (res.Success) {
            self.$message.success(res.Data);
            self.$router.push("/departmentMannage/list");
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
    //获取排序号
    async getSort() {
      let self = this;
      let res = await self.$Http.GetDepartmentSortIndex();
      self.form.sort = res.Data;
    },
  },
  mounted() {
    this.init();
  },
};
</script>