<template>
  <div class="login-bg">
    <div class="login-logo">
      <img src="../../assets/login_logo.png" alt="BDM业绩管理系统">
      <!--      <span>BDM业绩管理系统</span>-->
    </div>
    <div class="login">
      <p class="login-title">
        登录
      </p>
      <a-form>
        <a-form-item>
          <a-tooltip v-model="isAccount" placement="topLeft">
            <template slot="title">
              请输入正确的帐号！
            </template>
          </a-tooltip>
          <a-input size="large" v-model="form.account" class="section-input"
              placeholder="请输入您的账号" @change="CheckLoginAccount"
          >
            <a-icon slot="prefix" type="user" :style="{ color: '#6d6d6d' }"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-tooltip v-model="isPassword" placement="topLeft">
            <template slot="title">
              请输入密码！
            </template>
          </a-tooltip>
          <a-input-password size="large" class="section-input" placeholder="请输入您的密码" v-model="form.password"
              @change="CheckLoginPassword"
          >
            <a-icon slot="prefix" type="lock" :style="{ color: '#6d6d6d' }"/>
          </a-input-password>
        </a-form-item>
        <a-form-item v-if="!isMobile()">
          <div class="drag" ref="dragDiv">
            <div class="drag_bg"></div>
            <div class="drag_text">{{ confirmWords }}</div>
            <div ref="moveDiv" @mousedown="mousedownFn($event)" :class="{'handler_ok_bg':confirmSuccess}" class="handler handler_bg" style="position: absolute;top: 0;left: 0;"></div>
          </div>
        </a-form-item>
        <a-form-item>
          <a-button html-type="submit" @click="login" class="btn">登录</a-button>
        </a-form-item>
      </a-form>
      <!--      <p class="login-text">版权所有</p>-->

    </div>
  </div>
</template>

<script>
import store from "../../store/index";

export default {
  name: "login",
  data() {
    return {
      isAccount: false,
      isPassword: false,
      logs: [],
      form: {
        account: "",
        password: "",
      },
      beginClientX: 0,           /*距离屏幕左端距离*/
      mouseMoveStata: false,     /*触发拖动状态  判断*/
      maxwidth: "",               /*拖动最大宽度，依据滑块宽度算出来的*/
      confirmWords: "拖动滑块验证",   /*滑块文字*/
      //TODO:这里正式上线改为false
      confirmSuccess: false,           /*验证成功判断*/
    };
  },
  created() {
    this.CookieClear();
  },
  methods: {
    CookieClear() {
      window.localStorage.removeItem("permission");
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("CJ_LMS_SSO_UID");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    CheckLoginAccount() {
      this.isAccount = this.form.account.length < 1;
    },
    CheckLoginPassword() {
      this.isPassword = this.form.password.length < 1;
    },
    async login() {
      const that = this;
      if(that.confirmSuccess === false) {
        that.$message.error("请完成验证！");
        return false;
      }

      if(!that.form.account && !that.form.password) {
        this.$message.error("请填写帐号或者密码！");
        return false;
      }
      let res = await that.$Http.getLogin({
        account: that.form.account,
        password: that.form.password,
      });

      if(res["Success"] === true) {
        await this.$store.commit("setData", "我的名字");
        setTimeout(() => {
          that.$router.push("/main");
        }, 500);
      } else {
        this.$message.error(res.Message);
      }
    },
    mousedownFn: function(e) {
      if(!this.confirmSuccess) {
        e.preventDefault && e.preventDefault();   //阻止文字选中等 浏览器默认事件
        this.mouseMoveStata = true;
        this.beginClientX = e.clientX;
      }
    },
    successFunction() {
      this.confirmSuccess = true;
      this.confirmWords = "验证通过";
      if(window.addEventListener) {
        document.getElementsByTagName("html")[0].removeEventListener("mousemove", this.mouseMoveFn);
        document.getElementsByTagName("html")[0].removeEventListener("mouseup", this.moseUpFn);
      } else {
        document.getElementsByTagName("html")[0].removeEventListener("mouseup", () => {
        });
      }
      document.getElementsByClassName("drag_text")[0].style.color = "#fff";
      document.getElementsByClassName("handler")[0].style.left = this.maxwidth + "px";
      document.getElementsByClassName("drag_bg")[0].style.width = this.maxwidth + "px";
    },                //验证成功函数
    mouseMoveFn(e) {
      if(this.mouseMoveStata) {
        let width = e.clientX - this.beginClientX;
        if(width > 0 && width <= this.maxwidth) {
          document.getElementsByClassName("handler")[0].style.left = width + "px";
          document.getElementsByClassName("drag_bg")[0].style.width = width + "px";
        } else if(width > this.maxwidth) {
          this.successFunction();
        }
      }
    },                   //mousemove事件
    moseUpFn(e) {
      this.mouseMoveStata = false;
      const width = e.clientX - this.beginClientX;
      if(width < this.maxwidth) {
        document.getElementsByClassName("handler")[0].style.left = 0 + "px";
        document.getElementsByClassName("drag_bg")[0].style.width = 0 + "px";
      }
    },
    //mouseup事件

    // 获取是否是手机端
    isMobile() {
      let userAgentInfo = navigator.userAgent;
      let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      let flag = false;
      for (let v = 0; v < Agents.length; v++) {
        if(userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true;
          this.confirmSuccess = true;
          break;
        }
      }
      return flag;
    },
  },
  mounted() {
    this.maxwidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
    document.getElementsByTagName("html")[0].addEventListener("mousemove", this.mouseMoveFn);
    document.getElementsByTagName("html")[0].addEventListener("mouseup", this.moseUpFn);

  },
};
</script>

<style lang="less" scoped>
.login-bg {
  background: url("../../assets/login_bg.png") no-repeat center;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-width: 1080px;
  }

.login-logo {
  padding-top: 27px;
  padding-left: 34px;


  span {
    color: #E7E7E7;
    font-size: 18px;
    font-weight: 500;
    padding-left: 16px;
    }
  }


.login {
  position: relative;
  top: 18%;
  left: 8%;
  padding: 30px 30px;
  width: 400px;
  height: 420px;
  //  border: 1px dashed rgba(255,255,255,.3);
  background: rgba(255, 255, 255, .3);
  border-radius: 4px;
  box-shadow: 3px 3px 20px rgba(8, 20, 41, .5);

  &-title {
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    }

  .btn {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #24619e;
    color: #ffffff;
    border: 0;
    }


  }


.drag {
  position: relative;
  background-color: #e8e8e8;
  width: 100%;
  height: 34px;
  line-height: 34px;
  text-align: center;
  }

.handler {
  width: 40px;
  height: 32px;
  border: 1px solid #ccc;
  cursor: move;
  }

.handler_bg {
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==") no-repeat center;
  }

.handler_ok_bg {
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==") no-repeat center;
  }

.drag_bg {
  background-color: #7ac23c;
  height: 34px;
  width: 0;
  }

.drag_text {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  }


</style>
