const DEPARTMENT_MANNAGE_API = {
    //部门列表
    GetDepartmentListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetDepartmentListByPage'
    },

    //获取序号
    GetDepartmentSortIndex: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetDepartmentSortIndex'
    },

    //新增部门
    InsertDepartment: {
        method: 'get',
        url: '/BasicSetting/BasicSet/InsertDepartment'
    },

    //获取详情
    GetDepartmentInfoById: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetDepartmentInfoById'
    },

    //编辑部门保存
    EditDepartment: {
        method: 'get',
        url: '/BasicSetting/BasicSet/EditDepartment'
    },

    //删除部门
    DeleteDepartment: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeleteDepartment'
    }
}
export default DEPARTMENT_MANNAGE_API
