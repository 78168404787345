<template>
  <section>
    <a-tabs :default-active-key="activeKey" @change="tabChange">
      <a-tab-pane :key="item" :tab="item" class="sec" v-for="item in tabList">
        <h1 class="title">改善行动计划执行-{{ item }}</h1>
        <div class="part">
          <table>
            <tr>
              <th>弱项KPI上层名称</th>
              <th>弱项KPI名称</th>
              <th>弱项KPI改善目标值</th>
              <th>弱项KPI改善前值</th>
              <th>弱项KPI当前值</th>
            </tr>
            <tr>
              <td>{{ improveDetail.FirstCategory }}</td>
              <td>{{ improveDetail.KpiName }}</td>
              <td>{{ improveDetail.TargetValue }}</td>
              <td>{{ improveDetail.FrontValue }}</td>
              <td>{{ improveDetail.NumValue }}</td>
            </tr>
          </table>
          <img
            v-if="isShowGood"
            src="../../assets/已达标.png"
            alt=""
            class="seal"
          />
          <img
            v-if="isShowBad"
            src="../../assets/未达标.png"
            alt=""
            class="seal"
          />
        </div>
        <div class="part">
          <div class="common-title part-title">弱项KPI改善前问题描述</div>
          <div class="part-content common-content">
            {{ improveDetail.ProblemDesc }}
          </div>
        </div>
        <div class="part">
          <div class="common-title part-title">计划的行动</div>
          <div class="part-content common-content">
            {{ improveDetail.PlanDesc }}
          </div>
        </div>
        <div class="part">
          <div class="common-title part-title">
            本月弱项KPI执行改善后现状描述
          </div>
          <div class="part-content common-content">
            <a-textarea
              :disabled="!isEditable"
              v-model="form.AfterImproveDes"
              :auto-size="{ minRows: 8, maxRows: 15 }"
            >
            </a-textarea>
          </div>
        </div>
        <div class="part">
          <div class="common-title part-title">
            实际已执行事项的细节概述？(本月具体干了什么)
          </div>
          <div class="part-content common-content">
            <a-textarea
              :disabled="!isEditable"
              v-model="form.RealityActionDes"
              :auto-size="{ minRows: 8, maxRows: 15 }"
            >
            </a-textarea>
          </div>
        </div>
        <div class="part">
          <div class="part-title common-title">
            若存在未完成事项，请指出并说明原因？
          </div>
          <div class="part-content common-content">
            <a-textarea
              :disabled="!isEditable"
              v-model="form.IncompleteDes"
              :auto-size="{ minRows: 8, maxRows: 15 }"
            >
            </a-textarea>
          </div>
        </div>
        <div class="part">
          <div class="part-title common-title">
            是否产生新课题？新课题的内容简述？
          </div>
          <div class="part-content common-content">
            <a-textarea
              :disabled="!isEditable"
              v-model="form.NewSubject"
              :auto-size="{ minRows: 8, maxRows: 15 }"
            >
            </a-textarea>
          </div>
        </div>
        <div class="part">
          <div class="part-title common-title">改善文件</div>
          <div class="part-content common-content">
            <el-upload
              class="upload-demo"
              :action="
                '/apis/DealerKpi/SecondBDM/UploadFile?DealerName=' + DealerName
              "
              :on-preview="handlePreview"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :limit="5"
              :file-list="fileList"
              :on-exceed="handleExceed"
              multiple
              :disabled="!isEditable"
            >
              <el-button v-if="isEditable" size="small" type="primary"
                >点击上传</el-button
              >
              <div v-if="isEditable" slot="tip" class="el-upload__tip">
                最多可上传5个文件
              </div>
            </el-upload>
          </div>
        </div>
        <div class="bottom">
          <a-button type="primary" @click="submitForm" v-if="isEditable"
            >提交</a-button
          >
          <a-button @click="$router.go(-1)">返回</a-button>
        </div>
      </a-tab-pane>
    </a-tabs>
  </section>
</template>

<script>
import moment from "moment";

export default {
  name: "form",
  props: {
    //是否是查看视图
    isView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //活跃标签
      activeKey: "",
      isShowBad: false,
      isShowGood: false,
      improveDetail: {
        FirstCategory: "",
        KpiName: "",
        ProblemDesc: "",
        PlanDesc: "",
        TargetValue: "",
        FrontValue: 0,
      },
      tabList: [],
      form: {
        PlanId: 0,
        YearMonth: "",
        AfterImproveDes: "",
        RealityActionDes: "",
        IncompleteDes: "",
        NewSubject: "",
        FilePath: "",
        FileUpLoadName: "",
      },
      DealerName: "",
      fileList: [],
      isEditable: true,
    };
  },
  methods: {
    async submitForm() {
      if (this.form.AfterImproveDes === "") {
        this.$message.error("请填写【本月弱项KPI执行改善后现状描述】");
        return;
      }
      if (this.form.RealityActionDes === "") {
        this.$message.error(
          "请填写【实际已执行事项的细节概述？(本月具体干了什么) 】"
        );
        return;
      }
      if (this.form.IncompleteDes === "") {
        this.$message.error("请填写【若存在未完成事项，请指出并说明原因？】");
        return;
      }
      if (this.form.NewSubject === "") {
        this.$message.error("请填写【是否产生新课题？新课题的内容简述？】");
        return;
      }
      this.form.YearMonth = this.activeKey + "-01";
      this.form.FilePath = this.fileList
        .chain()
        .where((path) => path.status === "success")
        .map((path) => path.response.Data.objectKey)
        .value()
        .join("|");
      this.form.FileUpLoadName = this.fileList
        .chain()
        .where((path) => path.status === "success")
        .map((path) => path.response.Data.fileName)
        .value()
        .join("|");
      let res = await this.$Http.InsertPlanDetail(this.form);
      if (res.Success) {
        this.$message.success(res.Data);
        this.getData();
      } else {
        this.$message.error(res.Message);
      }
    },
    async getDealerName() {
      let res = await this.$Http.GetThisCompany();
      if (res.Success) {
        this.DealerName = res.Data[0].CompanyNameCN;
      } else {
        this.$message.error(res.Message);
      }
    },

    //点击已上传文件的钩子
    async handlePreview(file) {
      //下载
      let res = await this.$Http.FileShareLink({
        objectkey: file.response.Data,
      });
      if (res.Success) {
        window.open(res.Data);
      } else {
        this.$message.error(res.Message);
      }
    },
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    getTabList() {
      if (Object.keys(this.$route.query).length !== 0) {
        //按照当前月份计算需要生成几个月
        let startTime = this.$route.query.StartTime;
        let startMoment = moment(startTime);
        //计划开始时间与当前时间差几个月
        this.activeKey = moment(startMoment)
          .subtract(0, "months")
          .format("YYYY-MM");
        for (let i = 0; i < 3; i++) {
          let thereMonth = moment(startMoment)
            .add(i, "months")
            .format("YYYY-MM");
          this.tabList.push(thereMonth);
        }

        // let durationMonths = moment(moment().format("YYYY-MM-01")).diff(startMoment, "months");
        //
        //   if (durationMonths >= 0) {
        //       //计划最多维持三个月
        //       if (durationMonths > 2) {
        //           durationMonths = 2;
        //       }
        //       for (let i = 0; i < durationMonths + 1; i++) {
        //           this.tabList.push(moment(startTime).add(i, 'month').format('YYYY-MM'))
        //       }
        //       this.activeKey = this.tabList[0]
        //
        //   }
      }
    },
    async getData() {
      let res = await this.$Http.GetImproveDetail({
        PlanId: this.form.PlanId,
        YearMonth: this.activeKey + "-01",
      });
      if (res.Success) {
        if (res.Data.plan.length > 0) this.improveDetail = res.Data.plan[0];
        //已填写过改善行动
        if (res.Data.detail.length > 0) {
          this.isEditable = false;
          let detail = res.Data.detail[0];
          if (detail.DetailStatus === 0) {
            this.isShowBad = true;
          } else {
            this.isShowGood = true;
          }
          this.form.AfterImproveDes = detail.AfterImproveDes;
          this.form.RealityActionDes = detail.RealityActionDes;
          this.form.IncompleteDes = detail.IncompleteDes;
          this.form.NewSubject = detail.NewSubject;
          let fileNameList = detail.FileUpLoadName.split("|");
          this.fileList = detail.FilePath.split("|")
            .chain()
            .filter((path) => path.length > 0)
            .map((path, i) => {
              let item = {};
              item.status = "success";
              item.name = fileNameList[i];
              item.response = { Data: path };
              return item;
            })
            .value();
        }
        //未填写过改善行动
        else {
          this.isEditable = !this.isView;
          this.isShowBad = false;
          this.isShowGood = false;
          this.form.AfterImproveDes = "";
          this.form.RealityActionDes = "";
          this.form.IncompleteDes = "";
          this.form.NewSubject = "";
          this.fileList = [];
        }
      } else {
        this.$message.error(res.Message);
      }
    },
    tabChange(key) {
      this.activeKey = key;
      this.getData();
    },
  },
  mounted() {
    this.getTabList();
    this.form.PlanId = this.$route.query.PlanId;
    this.getData();
    this.getDealerName();
    //如果是查看视图，一律不准编辑
    if (this.isView) {
      this.isEditable = false;
    }
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
  text-align: center;

  th {
    height: 50px;
    background: #dddddd;
    font-weight: bold;
  }

  td {
    height: 50px;
    border-bottom: #e8e8e8 2px solid;

    &:first-child {
      border-left: #e8e8e8 2px solid;
    }

    &:last-child {
      border-right: #e8e8e8 2px solid;
    }
  }
}

.title {
  text-align: center;
  font-weight: bold;
}

.part {
  margin: 20px auto;
  position: relative;
}

.common-title {
  background: #dddddd;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
}

.common-content {
  border: #e8e8e8 2px solid;
  border-top: none;
}

.table-title {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.table-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
}

.part-title {
  padding-left: 30px;
}

.part-content {
  padding: 30px;
  display: flex;
  align-items: center;
}

.seal {
  position: absolute;
  top: -15px;
  right: 10%;
}

.bottom {
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
}
</style>
