const POSITION_MANNAGE_API = {
    //岗位列表
    GetPositionListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPositionListByPage'
    },

    //获取序号
    GetPositionSortIndex: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPositionSortIndex'
    },

    //新增岗位
    InsertPosition: {
        method: 'get',
        url: '/BasicSetting/BasicSet/InsertPosition'
    },

    //获取详情
    GetPositionInfoById: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPositionInfoById'
    },

    //编辑岗位
    EditPosition: {
        method: 'get',
        url: '/BasicSetting/BasicSet/EditPosition'
    },

    //删除职位
    DeletePostion: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeletePostion'
    }
}
export default POSITION_MANNAGE_API
