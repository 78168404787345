import Main from "@/views/mainComponent/main";
import NationalHomepage from "@/views/mainComponent/nationalHomepage";
import NationalHomepageImg from "@/views/mainComponent/nationalHomepageImg";
import AreaHomepage from "@/views/mainComponent/areaHomepage";
import AreaHomepageImg from "@/views/mainComponent/areaHomepageImg";
import AllDashboard from "@/views/mainComponent/allDashboard";
import AllDashboardImg from "@/views/mainComponent/allDashboardImg";
import SaleDashboard from "@/views/mainComponent/saleDashboard";
import AfterSaleDashboard from "@/views/mainComponent/afterSaleDashboard";

let mainRouter = [
    {
        path: "/main",
        component: Main,
    },
    {
        path: '/nationalHomepage',
        component: NationalHomepage,
    },
    {
        path: '/nationalHomepageImg',
        component: NationalHomepageImg,
    },
    {
        path: '/areaHomepage',
        component: AreaHomepage,
    },
    {
        path: '/areaHomepageImg',
        component: AreaHomepageImg,
    },
    {
        path: '/AllDashboard',
        component: AllDashboard,
    },
    {
        path: '/AllDashboardImg',
        component: AllDashboardImg,
    },
    {
        path: '/SaleDashboard',
        component: SaleDashboard,
    },
    {
        path: '/AfterSaleDashboard',
        component: AfterSaleDashboard,
    }
];

export default mainRouter
