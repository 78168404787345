<template>
    <section>
        <improveView :isView="true"></improveView>
    </section>
</template>

<script>
import improveView from "@/views/ImprovePlan/improveView";
export default {
    name: "viewImproveView.vue",
    components:{
        improveView
    }
}
</script>

<style scoped>

</style>