<template>
  <section>
    <a-card class="card">
      <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          layout="horizontal"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 20 }"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="姓名" prop="Name">
              <a-input v-model="form.Name"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="账号" prop="UserName">
              <a-input :disabled="!isAddForm" v-model="form.UserName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="电子邮箱" prop="Email">
              <a-input v-model="form.Email"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="手机号" prop="MobilePhone">
              <a-input v-model="form.MobilePhone"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="出生日期" prop="Birthday">
              <a-date-picker v-model="form.Birthday"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="入职日期" prop="EntryTime">
              <a-date-picker v-model="form.EntryTime"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="经销商" prop="CompanyId">
              <a-select
                  show-search
                  :default-active-first-option="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="companyHandleSearch"
                  @change="companyHandleChange"
                  v-model="form.CompanyId"
                  :disabled="!SuffixEditable"
              >
                <a-select-option
                    v-for="(d, i) in companyList"
                    :key="i"
                    :value="d.CompanyId"
                >
                  {{ d.CompanyNameCN }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="所属部门" prop="DepartmentId">
              <a-select
                  show-search
                  option-filter-prop="children"
                  v-model="form.DepartmentId"
              >
                <a-select-option
                    v-for="(item, i) in departmentList"
                    :key="i"
                    :value="item.ItemValue"
                >
                  {{ item.ItemName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
          <a-form-model-item label="所属小组">
            <a-select show-search option-filter-prop="children">
              <a-select-option value="jack"> Jack </a-select-option>
              <a-select-option value="lucy"> Lucy </a-select-option>
              <a-select-option value="tom"> Tom </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col> -->
          <a-col :span="12">
            <a-form-model-item label="岗位" prop="PositionId">
              <a-select
                  show-search
                  :default-active-first-option="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="positionHandleSearch"
                  @change="positionHandleChange"
                  v-model="form.PositionId"
                  :disabled="!SuffixEditable"
              >
                <a-select-option
                    v-for="(d, i) in positionList"
                    :key="i"
                    :value="d.ItemValue"
                >
                  {{ d.ItemName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="管理权限" prop="PermissionCode">
              <a-select
                  :disabled="isPersonCenter||!SuffixEditable"
                  show-search
                  :default-active-first-option="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="permissionHandleSearch"
                  @change="permissionHandleChange"
                  v-model="form.PermissionCode"
              >
                <a-select-option
                    v-for="(d, i) in permissionList"
                    :key="i"
                    :value="d.ItemValue"
                >
                  {{ d.ItemName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="性别" prop="Sex">
              <a-radio-group v-model="form.Sex">
                <a-radio :value="1"> 男</a-radio>
                <a-radio :value="0"> 女</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="在职状态" prop="IsOnJob">
              <a-radio-group v-model="form.IsOnJob">
                <a-radio :value="1"> 在职</a-radio>
                <a-radio :value="0"> 离职</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <a-row>
        <a-col :span="2" :offset="20">
          <a-button type="primary" @click="save"> 确认</a-button>
        </a-col>
        <a-col :span="2">
          <a-button @click="$router.back()">
            取消
          </a-button>
        </a-col>
      </a-row>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>
<script>
import Http from "@/api/http";

let timeout1;
let timeout2;
let timeout3;

//经销商
function Companyfetch(value, callback) {
  if(timeout1) {
    clearTimeout(timeout1);
    timeout1 = null;
  }

  async function fake() {
    let res = await Http.getCompany({ keyCodes: value });
    callback(res);
  }

  timeout1 = setTimeout(fake, 300);
}

//岗位
function Positionfetch(value, callback) {
  if(timeout2) {
    clearTimeout(timeout2);
    timeout2 = null;
  }

  async function fake() {
    let res = await Http.getPostionInfo({ keyCodes: value });
    callback(res);
  }

  timeout2 = setTimeout(fake, 300);
}

//管理权限
function Permissionfetch(value, callback) {
  if(timeout3) {
    clearTimeout(timeout3);
    timeout3 = null;
  }

  async function fake() {
    let res = await Http.getPermissionInfo({ keyCodes: value });
    callback(res);
  }

  timeout3 = setTimeout(fake, 300);
}

export default {
  data() {
    return {
      userID: "",
      isAddForm: false,
      isPersonCenter: false,
      form: {
        //姓名
        Name: "",
        //账号
        UserName: "",
        // 生日
        Birthday: null,
        //入职日期
        EntryTime: null,
        //选择的销售店ID
        CompanyId: "",
        // 选择的部门ID
        DepartmentId: "",
        //选择的岗位ID
        PositionId: "",
        //选择的权限code
        PermissionCode: "",
        //性别
        Sex: 0,
        //是否在职
        IsOnJob: 1,

      },
      //部分后缀结尾的账号不可编辑，此字段控制
      SuffixEditable: true,
      //经销商搜索列表
      companyList: [],
      //岗位搜索列表
      positionList: [],
      //管理权限搜索列表
      permissionList: [],
      //部门列表
      departmentList: [],

      //验证规则
      rules: {
        Name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur", "change"],
          },
        ],
        UserName: [
          {
            required: true,
            message: "请输入账户",
            trigger: ["blur", "change"],
          },
        ],
        Email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: ["blur", "change"],
          },
        ],
        MobilePhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
          },
        ],
        Birthday: [
          {
            required: true,
            message: "请输入出生日期",
            trigger: ["blur", "change"],
          },
        ],
        EntryTime: [
          {
            required: true,
            message: "请输入入职日期",
            trigger: ["blur", "change"],
          },
        ],
        CompanyId: [
          {
            required: true,
            message: "请输入经销商",
            trigger: ["blur", "change"],
          },
        ],
        DepartmentId: [
          {
            required: true,
            message: "请输入所属部门",
            trigger: ["blur", "change"],
          },
        ],
        PositionId: [
          {
            required: true,
            message: "请输入岗位",
            trigger: ["blur", "change"],
          },
        ],
        PermissionCode: [
          {
            required: true,
            message: "请输入管理权限",
            trigger: ["blur", "change"],
          },
        ],
        Sex: [
          {
            required: true,
            message: "请输入性别",
            trigger: ["blur", "change"],
          },
        ],
        IsOnJob: [
          {
            required: true,
            message: "请输入在职状态",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
  },
  async mounted() {
    if(this.$route.path !== "/humanMannage/add") {
      //获取编辑用户ID
      if(this.$route.path === "/humanMannage/form") {
        this.userID = this.$route.query.userID;
      }
      //如果是修改密码，则获取当前登录用户的Cookies
      if(this.$route.path === "/personalCenter") {
        this.isPersonCenter = true;
        let cookieList = document.cookie.split(";");
        if(cookieList.length > 0) {
          cookieList.forEach((element) => {
            let cookieSplit = element.split("=");
            if(cookieSplit[0].indexOf("CJ_LMS_SSO_UID") > -1) {
              this.userID = cookieSplit[1];
            }
          });
        }
      }
      //获取详情
      await this.getUserInfo(this.userID);
      //
      await this.getAccountSuffix();
    } else {
      this.isAddForm = true;
    }

    //获取部门
    await this.getDepartment();
  },
  computed: {},
  methods: {
    async getAccountSuffix() {
      let res = await this.$Http.GetAccountSuffix();
      if(res.Success) {
        res.Data.forEach(item => {
          if(this.form.UserName.endsWith(item)) {
            this.SuffixEditable = false;
          }
        });
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getUserInfo(userID) {
      let res = await this.$Http.getUserInfo({ uid: userID });
      if(res.Data.length > 0) {
        this.form = res.Data[0];
        //展示远程搜索名字而非code
        this.companyList.push({
          CompanyId: this.form.CompanyId,
          CompanyNameCN: this.form.CompanyNameCN,
        });
        this.positionList.push({
          ItemValue: this.form.PositionId,
          ItemName: this.form.PositionNameCN,
        });
        this.permissionList.push({
          ItemValue: this.form.PermissionCode,
          ItemName: this.form.PermissionName,
        });
      }
    },
    //经销商搜索
    companyHandleSearch(value) {
      Companyfetch(value, (data) => (this.companyList = data.Data));
    },
    //经销商改变
    companyHandleChange(value) {
      Companyfetch(value, (data) => (this.companyList = data.Data));
    },

    //岗位搜索
    positionHandleSearch(value) {
      Positionfetch(value, (data) => (this.positionList = data.Data));
    },
    //岗位改变
    positionHandleChange(value) {
      Positionfetch(value, (data) => (this.positionList = data.Data));
    },

    //管理权限搜索
    permissionHandleSearch(value) {
      Permissionfetch(value, (data) => (this.permissionList = data.Data));
    },
    //管理权限改变
    permissionHandleChange(value) {
      Permissionfetch(value, (data) => (this.permissionList = data.Data));
    },

    //获取部门
    async getDepartment() {
      let res = await this.$Http.getDepartmentInfo();
      this.departmentList = res.Data;

      let ras = await Http.getPostionInfo();
      this.positionList = ras.Data;

      let rbs = await Http.getPermissionInfo();
      this.permissionList = rbs.Data;

      let rcs = await Http.getCompany();
      this.companyList = rcs.Data;

    },
    //保存用户
    save() {
      let self = this;
      //moment转换为string
      if(typeof self.form.Birthday != "string") {
        self.form.Birthday = self.form.Birthday.format("YYYY-MM-DD");
      }
      if(typeof self.form.EntryTime != "string") {
        self.form.EntryTime = self.form.EntryTime.format("YYYY-MM-DD");
      }

      self.$refs.form.validate(async(valid) => {
        if(valid) {
          let res;
          if(this.isAddForm) {
            res = await this.$Http.addUser(self.form);
          } else {
            res = await this.$Http.editUsers(self.form);
          }
          if(res.Success) {
            this.$message.success(res.Data);
            if(!this.isPersonCenter) {
              this.$router.back();
            }
          } else {
            this.$message.error(res.Message);
          }
        }
      });
    },
  },
  filters: {},
};
</script>
<style scoped>
</style>
