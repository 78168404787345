<template>
    <table>
        <thead>
        <th v-for="(item,k) in columnsData" :key="k++">
            {{ item }}
        </th>
        </thead>
        <tr v-for="(item,i) in tableData.data" :key="i++">
            <td v-for="(_,k) in columnsData" :key="k">{{ item[k] }}</td>
        </tr>
    </table>
</template>

<script>
export default {
 name: "profitTable",
 props: {
  columnsData: Array,
  tableData: {
   data: Array,
  },
 },
 data() {
  return {};
 },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;border: 1px solid #ddd;

  thead {
    text-align: center;color: #fff;
    background: #33554A;height: 40px;line-height: 40px;

    th {
      border-right: 1px solid #DDDDDD;
      text-align: center;
      &:first-child {
        min-width: 180px;
        }
      &:last-child {
        border-right: 0;min-width: 100px;
        }
      }
    }

  tr {height: 40px;line-height: 40px;


    td {
      width: 6%;text-align: right;
      border-right: 1px solid #ddd;padding-right: 4px;

      &:first-child {
        text-align: center;padding-left: 1vw;
        }

      &:nth-child(2) {
        text-align: center;
        }

      &:last-child {
        border-right: 0;
        }

      }

    &:nth-child(even) {
      background: #E6ECEC;
      border: 1px solid #DDDDDD;
      }
    }

  }
</style>
