import { UnitProcessing } from "@/until/option";

// 取出车型
function getCarChild(tableData, data) {
  tableData.colData.forEach(c => {
    c.children = [];
    if(data[c.resName]) {
      Object.keys(data[c.resName]).map(d => {
        c.children.push(d);
      });
      if(tableData.isSum) c.children.push("合计");
      c.rowspan = c.children.length;
    }
  });
}

export function getTableData(table, choseDealer, YearFive, carData) {
  let data = carData[table.resHttp];
  table.columnsData = [choseDealer, table.columnName ?? "车型级别", ...YearFive];
  if(data) getCarChild(table, data);// 表格4里有合计,无法从后台取出
  // 加载表格数据
  table.data = [];
  table.colData.forEach(t => {
    t.children.forEach((d, i) => {
      let arr = {};
      arr.val = [];
      arr["title"] = d;
      arr["rowspan"] = 1;
      if(i === 0) {
        arr["title"] = `${t.title}(${t.unit})`;
        arr["rowspan"] = parseInt(t.rowspan);
      }
      Object.keys(data[t.resName]).forEach((name) => {
        if(name === d) {
          arr.val = [d];
          let years = YearFive.chain().map(year => {
            return year.split("(")[0];
          }).value();
          years.forEach(d => {
            let val = 0;
            if(data[t.resName][name][d] !== undefined) {
              val = UnitProcessing(t.unit, data[t.resName][name][d] ?? 0); // 单位处理
            }
            arr.val.push(val);
          });
        }
      });
      table.data.push(arr);
    });
  });
}

export function getTableData5(table, choseDealer, YearFive, carData) {
  let data = carData[table.resHttp];
  table.columnsData = [choseDealer, "车型级别", ...YearFive];
// if(data) getCarChild(table, data);
  table.colData.forEach(c => {
    c.children = [];
    Object.keys(data).forEach(car => {
      c.children.push(car);
    });
  });

  // 加载表格数据
  table.data = [];
  table.colData.forEach((t, j) => {
    if(j === 0) {
      t.children.forEach((d, i) => {
        let arr = {};
        arr.val = [];
        arr["title"] = t.title;
        if(i === 0) {
          arr["title"] = `级别占比(${t.unit})`;
          arr["rowspan"] = parseInt(t.rowspan);
        }
        Object.keys(data).forEach((name, m) => {
          if(name === d) {
            let arrData = data[name];
            if(d === "Car") d = "轿车";
            arr.val = [d];
            let years = YearFive.chain().map(year => {
              return year.split("(")[0];
            }).value();
            years.forEach(d => {
              let val = 0;
              if(arrData[d] !== undefined) {
                val = UnitProcessing(t.unit, arrData[d] ?? 0); // 单位处理
              }
              arr.val.push(val);
            });
          }
        });
        table.data.push(arr);
      });
    }
  });
}
