<template>
  <a-config-provider :locale="locale">
    <a-row type="flex" justify="start" style="height: 100vh;width: 100%;">
      <Header_1></Header_1>
      <!--左侧-->
      <div :style="{width:!collapsed?'208px':'80px',height:'100vh'}" class="">
        <div class="left_menu_fix bg_left" >
          <div :style="{paddingLeft:!collapsed?'56px':'14px'}" style="height: 55px">&nbsp;</div>
          <!--左侧菜单栏-->
          <a-menu @click="menu=>changNav(menu.key)" :selectedKeys="selectedKeys" :openKeys.sync="openKeys"
              mode="inline" theme="dark" :inline-collapsed="collapsed" :style="{width:!collapsed?'208px':'80px',marginBottom:'100px'}"
          >
            <template v-for="menu in menus">
              <a-menu-item v-if="menu.Child.length===0" :key="menu.MenuId">
                <span><a-icon :type="menu.Icon" :alt="menu.MenuNameCN"/>{{ menu.MenuNameCN }}</span>
              </a-menu-item>
              <sub-menu v-else :key="menu.MenuId" :menu="menu" :collapsed="collapsed"/>
            </template>
          </a-menu>
          <!--收缩/展开菜单-->
          <div class="left_menu" :style="{width:!collapsed?'208px':'80px'}">
            <a type="" style="margin-bottom: 16px;color: #727171" @click="toggleCollapsed">
              <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'"/>
            </a>
          </div>
        </div>
      </div>
      <!--右侧      -->
      <div class="right_padding" :style="{paddingLeft:!collapsed?'208px':'96px'}">
        <a-breadcrumb class="breadcrumb">
          <a-breadcrumb-item>
            <router-link to="/main">
              <a-icon type="home"/>
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item :key="menuId" v-for="menuId in breadcrumbKeys">
            {{ menuMap[menuId].Name }}
          </a-breadcrumb-item>
        </a-breadcrumb>
        <transition name="fade-slide">
          <router-view class="routerView"></router-view>
        </transition>
      </div>
    </a-row>
    <div>
      <a-back-top>
        <div class="back-top">
          <a-icon class="back-top-icon" type="vertical-align-top"/>
        </div>
      </a-back-top>
    </div>
  </a-config-provider>
</template>

<script>
import Header_1 from "./header";
import Footer_1 from "./Footer";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import SubMenu from "./SubMenu";

export default {
  name: "index",
  components: { SubMenu, Footer_1, Header_1 },
  data() {
    return {
      collapsed: false,
      locale: zhCN,
      menus: [],
      menuMap: {},
      menuUrlMap: {},
      selectedKeys: [],
      openKeys: [],
      breadcrumbKeys: [],
      Url: "",
    };
  },
  async created() {
    await this.loadMenu();
    this.updateSelectMenu();
    // 路由加载完成后，更新菜单
    let self = this;
    this.$router.afterEach((to, from) => {
      self.updateSelectMenu();
    });
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.updateOpenMenu();
    },
    async loadMenu() {
      let res = await this.$Http.commonApi({
        API: "Minghua_GetMenuByPermissionCode",
      });
      if(!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.menus = res.ApiParamObj;
      this.loadMenuKeys(this.menus);
      this.menuUrlMap = Object.values(this.menuMap).chain()
          .groupBy(menu => menu.Url.split("?")[0].toLowerCase())
          .value();
    },
    loadMenuKeys(menus, menuUrlKeyMap, menuFullKey) {
      let menuArr = []; // 权限管理数组,存在本在
      menus.forEach(menu => {
        let myMenuFullKey = Array.from(menuFullKey ?? []);
        myMenuFullKey.push(menu.MenuId);
        if(menu.MenuNameCN === "站内信管理" || menu.MenuNameCN === "人员管理" ) {
          menuArr.push(menu.MenuNameCN);
          window.localStorage.setItem("permission", menuArr.toString());
        }
        this.menuMap[menu.MenuId] = { Url: menu.Url, Name: menu.MenuNameCN, FullKey: myMenuFullKey };
        if(menu.Child.length > 0) {
          this.loadMenuKeys(menu.Child, menuUrlKeyMap, myMenuFullKey);
        }
      });
    },
    updateSelectMenu() {
      // 展开菜单
      let currUrl = this.$route.path.toLowerCase();
      let menus = this.menuUrlMap[currUrl];
      if (!menus) {
        return;
      }
      if (menus.length > 1) {
        currUrl = this.$route.fullPath.toLowerCase();
        menus = menus
            .filter(menu => menu.Url && currUrl.startsWith(menu.Url.toLowerCase()))
            .sort(menu => -menu.Url.length);
      }
      let menu = menus[0];
      this.selectedKeys = menu.FullKey;
      this.breadcrumbKeys = menu.FullKey;
      this.updateOpenMenu();
    },
    updateOpenMenu() {
      if(this.collapsed) {
        this.openKeys = [];
      } else {
        this.openKeys = this.selectedKeys;
      }
    },
    changNav(menuId) {
      let menu = this.menuMap[menuId];
      if(menu == null || menu.Url == null || menu.Url.length <= 0) {
        this.$message.warn("菜单不存在");
        return;
      }
      let currUrl = this.$route.path.toLowerCase();
      if(currUrl === menu.Url.toLowerCase()) {
        this.selectedKeys = menu.FullKey;
        this.breadcrumbKeys = menu.FullKey;
        return;
      }
      let routeMatch = this.$router.match({ path: menu.Url });
      if(routeMatch.fullPath !== "/login" && routeMatch.matched.length === 1) {
        // 不继承菜单页面路由则默认打开新窗口
        window.open(routeMatch.fullPath);
      } else {
        this.$router.push({ path: menu.Url });
        this.selectedKeys = menu.FullKey;
        this.breadcrumbKeys = menu.FullKey;
      }
      this.updateOpenMenu();
    },
  },
};
</script>

<style lang="less" scoped>
.bg_left {
  position: absolute;
  top: 0;
  background: #000c17;
  z-index: 1;
  }

.right_padding {
  background: #F2F5F8;
  position: absolute;overflow: hidden;
  top: 47px;
  width: 100%;
  // min-width: 1400px;
  left: 0;
  }

.breadcrumb {
  background: #ffffff;
  padding-left: 24px;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  }

.routerView {
  padding-left: 24px;
  padding-right: 20px;
  padding-top: 16px;
  }

.left_menu {
  position: fixed;
  width: 208px;
  top: 94.7%;
  text-align: center;
  background: #282828;
  height: 50px;
  line-height: 50px;
  }

.ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.03px !important;
  }

.left_menu_fix {
  position: fixed;height: 100vh;overflow: auto;

  img {margin-top: 10px;margin-bottom: 8px;}

  }

/* fade-slide 过渡动画*/
.fade-slide-leave-active,
.fade-slide-enter-active {
  transition: all 0.3s;
  }

.fade-slide-enter-from {
  opacity: 0;
  transform: translateX(-30px);
  }

.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
  }
</style>
