<template>
  <div class="main" style="margin-top: 35px">
    <h2 style="grid-area: title3">售后业务 (店均)</h2>
    <div style="grid-area: a1" class="card_border">
      <div style="display: flex;justify-content: space-between;">
        <div>
          <span>售后总产值(万元)</span>
          <div>
            <span class="title-num2">{{ tenThousandYuan(GetKpiCodeData("B0103097")) }}</span>
          </div>
        </div>
        <div style="width: 300px;">
          <MainChart :chart-data="miniChart"/>
        </div>
      </div>
    </div>
    <div style="grid-area: a2" class="card_border">
      <img src="../../../assets/S1.png" alt="">
      <span class="card-title1">零部件产值</span>
      <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("B0103007")) }}万元</p>
    </div>
    <div style="grid-area: a3" class="card_border">
      <img src="../../../assets/S2.png" alt="">
      <span class="card-title1">工时产值</span>
      <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("B0103047")) }}万元</p>
    </div>
    <div style="grid-area: a4" class="card_border">
      <img src="../../../assets/S3.png" alt="">
      <span class="card-title1">售后水平事业产值</span>
      <p class="title-num2">{{ tenThousandYuan(GetKpiCodeData("B0103099")) }}万元</p>
    </div>
    <div style="grid-area: a5" class="card_border">
      <img src="../../../assets/S4.png" alt="">
      <span class="card-title1">平均单车毛利</span>
      <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("B0106027")) }}元</p>
    </div>
    <div style="grid-area: b1" class="card_border">
      <div style="display: flex;justify-content: space-between;">
        <div>
          <span>售后来厂台次(台)</span>
          <div>
            <span class="title-num2">{{
                GetKpiCodeData("B0302001") ? ThousandYuan(GetKpiCodeData("B0302001")) : 0 }}</span>
          </div>
        </div>
        <div style="width: 300px;">
          <MainChart :chart-data="miniChart2"/>
        </div>
      </div>
    </div>
    <div style="grid-area: b2" class="card_border">
      <img src="../../../assets/S5.png" alt="">
      <span class="card-title1">单车产值</span>
      <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("B0104226")) }}元</p>
    </div>
    <div style="grid-area: b5" class="card_border">
      <img src="../../../assets/S6.png" alt="">
      <span class="card-title1">单车售后水平事业产值</span>
      <p class="title-num2">
        <!--        {{ ThousandYuan(Number(GetKpiCodeData("B0103081")) / Number(GetKpiCodeData("B0302004")) + Number(GetKpiCodeData("B0103082")) / Number(GetKpiCodeData("B0302005")) ) }}元-->
        {{ ThousandYuan(GetKpiCodeData("B0104227")) }}元
      </p>
    </div>
    <div style="grid-area: b3" class="card_border">
      <img src="../../../assets/S7.png" alt="">
      <span class="card-title1">机电单车产值</span>
      <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("B0104204")) }}元</p>
    </div>
    <div style="grid-area: b4" class="card_border">
      <img src="../../../assets/S8.png" alt="">
      <span class="card-title1">钣喷单车产值</span>
      <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("B0104215")) }}元</p>
    </div>
    <div style="grid-area: c1" class="card_border">
      <span class="card-title2">售后产值</span>
      <div style="margin-top: 25px">
        <PieChart :pie-data="pieData" :isNeedDeal="false"/>
      </div>
    </div>
    <div style="grid-area: c2" class="card_border">
      <img src="../../../assets/S9.png" alt="">
      <span class="card-title1">有效保有客户总量</span>
      <p class="title-num2">{{ ThousandYuan(GetKpiCodeData("B0302037")) }}个</p>
    </div>
    <div style="grid-area: c3" class="card_border">
      <img src="../../../assets/s10.png" alt="">
      <span class="card-title1">A类客户占比</span>
      <p class="title-num2">{{ percentage(GetKpiCodeData("B0303019")) }}</p>
    </div>
    <div style="grid-area: c4" class="card_border">
      <img src="../../../assets/s11.png" alt="">
      <span class="card-title1">B类客户占比</span>
      <p class="title-num2">{{ percentage(GetKpiCodeData("B0302035") / GetKpiCodeData("B0302037")) }}</p>
    </div>
    <div style="grid-area: c5" class="card_border">
      <img src="../../../assets/s12.png" alt="">
      <span class="card-title1">C类客户占比</span>
      <p class="title-num2">{{
          percentage(Number(GetKpiCodeData("B0302036")) / Number(GetKpiCodeData("B0302037"))) }}</p>
    </div>
    <div style="grid-area: d2" class="card_border">
      <Rate-component :rate-date="rateDate.rateDate1"/>
    </div>
    <div style="grid-area: d3" class="card_border">
      <Rate-component :rate-date="rateDate.rateDate2"/>
    </div>
    <div style="grid-area: e2" class="card_border">
      <Rate-component :rate-date="rateDate.rateDate3"/>
    </div>
    <div style="grid-area: e3" class="card_border">
      <Rate-component :rate-date="rateDate.rateDate4"/>
    </div>
  </div>
</template>

<script>
import MainChart from "./componets/mainChart";
import { color, barMaxWidth } from "./componets/echartConstSeting.ts";
import RateComponent from "./componets/rateComponent";
import GaugeChart from "./componets/gaugeChart";
import PieChart from "./componets/pieChart";
import { GetGauge, PercentageCode } from "../Code";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/optionPrint";
import moment from "moment";

export default {
  name: "afterSales",
  components: { MainChart, RateComponent, GaugeChart, PieChart },
  props: {
    kpiCodeData: {
      type: Array,
    },
    // 12个月的售后来厂台次数据
    afterSaleData: {
      type: Object,
    },
  },
  data() {
    return {
      tenThousandYuan, ThousandYuan, GetGauge, PercentageCode, percentage,
      pieData: [
        { value: 0, name: "一般维修", kpiCode: "B0103083" },
        { value: 0, name: "首保产值", kpiCode: "B0103081" },
        { value: 0, name: "其他产值", kpiCode: "B0104321" },
        { value: 0, name: "钣喷产值", kpiCode: "B0103087" },
        { value: 0, name: "定保产值", kpiCode: "B0103082" },
      ],
      miniChart: {
        chartHeight: "180px",
        columns: ["月份", "售后总产值"],
        rows: [],
        setting: {
          type: "histogram",
          yAxisType: [""],
          yAxisName: [""],
        },
        options: {
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              let arr = {};
              params.forEach((d, i) => {
                if(d.componentSubType === "bar") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0)}万元`;
                }
              });
              let re = params[0].name + "<br />";
              Object.values(arr).forEach(a => {
                re += a + "<br />";
              });
              return re;
            },
          },
          grid: {
            top: "20px",
            left: "-10px",
            right: "5px",
            bottom: "100px",
          },
          legend: {
            show: false,
          },
          xAxis: {
            show: true,
          },
          yAxis: [{
            show: false,
          }],
          series: [
            {
              barMaxWidth,
              lineStyle: {
                color,
              },
            },
          ],

        },
      },
      miniChart2: {
        chartHeight: "180px",
        columns: ["月份", "售后来厂台次"],
        rows: [],
        setting: {
          type: "histogram",
          yAxisType: [""],
          yAxisName: ["台"],
        },
        options: {
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              let arr = {};
              params.forEach((d, i) => {
                if(d.componentSubType === "bar") {
                  arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
                }
              });
              let re = params[0].name + "<br />";
              Object.values(arr).forEach(a => {
                re += a + "<br />";
              });
              return re;
            },
          },
          grid: {
            top: "20px",
            left: "-10px",
            right: "5px",
            bottom: "100px",
          },
          legend: {
            show: false,
          },
          xAxis: {
            show: true,
          },
          yAxis: [{
            show: false,
          }],
          series: [
            {
              barMaxWidth,
              lineStyle: {
                color,
              },
            },
          ],
        },
      },
      rateDate: {
        rateDate1: {
          title: "AB类客户数",
          cont: "486",
          percent: "0", kpiCode: "B0303050",
        },
        rateDate2: {
          title: "有效保有客户数占档案客户数比",
          cont: "486",
          percent: "0", kpiCode: "B0304012",
        },
        rateDate3: {
          title: "客户维系率",
          cont: "486",
          percent: "0", kpiCode: "B0303024",
        },
        rateDate4: {
          title: "客户流失率",
          cont: "0",
          percent: "0", kpiCode: "B0304014",
        },
      },
    };
  },
  created() {
    this.GetChartData();
  },
  watch: {
    kpiCodeData: {
      handler: function() {
        this.GetChartData();
      },
      deep: true,
    },
    afterSaleData: {
      handler: function() {
        this.GetChartData();
      },
      deep: true,
    },
  },
  methods: {
    GetKpiCodeData(KpiCode) {
      if(this.kpiCodeData.length < 1) return false;
      let data = this.kpiCodeData.filter(d => d.KpiCode === KpiCode);
      if(data.length > 0) {
        return data[0].NumValue;
      } else {
        return 0;
      }
    },
    async GetChartData() {
      this.pieData.forEach(d => d.value = tenThousandYuan(this.GetKpiCodeData(d.kpiCode)));
      this._.values(this.rateDate).forEach(d => {
        if(d.title === "AB类客户数") {
          //AB类客户数的图Code和数字Code不一样
          d.percent = Number(this.PercentageCode(this.GetKpiCodeData("B0303051")));
        } else {
          d.percent = Number(this.PercentageCode(this.GetKpiCodeData(d.kpiCode)));
        }

        if(d.title === "有效保有客户数占档案客户数比" || d.title === "客户维系率" || d.title === "客户流失率") {
          d.cont = this.PercentageCode(this.GetKpiCodeData(d.kpiCode)) + "%";
        } else {
          d.cont = this.ThousandYuan(this.GetKpiCodeData(d.kpiCode));
        }

      });
      if(!this.afterSaleData) {
        return false;
      }
      const myData = this.afterSaleData.AfterSaleData1;
      let varDate = [];
      myData.forEach(d => {
        let date = moment(d.YearMonth).format("yyyy-MM-DD");
        varDate.push(date);
      });
      varDate.sort();
      this.miniChart.rows = varDate.chain().map((d, i) => {
        let arr = {};
        arr["月份"] = moment(d).format("M");
        myData.forEach(value => {
          if(d===value.YearMonth) arr["售后总产值"] = tenThousandYuan(value.NumValue ?? 0);
        })
        return arr;
      }).value();

      const myData2 = this.afterSaleData.AfterSaleData2;
      let varDate2 = [];
      myData2.forEach(d => {
        let date = moment(d.YearMonth).format("yyyy-MM-DD");
        varDate2.push(date);
      });
      varDate2.sort();
      this.miniChart2.rows = varDate2.chain().map((d,i) => {
        let arr = {};
        arr["月份"] = moment(d).format("M");
        myData2.forEach(value => {
          if(d===value.YearMonth) arr["售后来厂台次"] = ThousandYuan(value.NumValue ?? 0);
        })
        return arr;
      }).value();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../common";

.main {
  display: grid;
  grid-template-columns:repeat(6, 15.8%);
  grid-template-rows:25px repeat(5, 110px);
  grid-column-gap: 13px;
  grid-row-gap: 15px;
  grid-template-areas:
                            'title3 title3 title3 title3 title3 title3'
                            'a1 a1 a2 a3 a4 a5'
                            'b1 b1 b2 b3 b4 b5'
                            'c1 c1 c2 c3 c4 c5'
                            'c1 c1 d2 d2 d3 d3'
                            'c1 c1 e2 e2 e3 e3';
  }

.pie_con {
  position: relative;
  bottom: 80px;
  text-align: center;
  }
</style>
