import moment from "moment";
import underscore from "underscore";
import { UnitChartProcessing, UnitProcessing } from "@/until/option";

export function getColumnsData(ReportDate) {
 const year = moment(ReportDate).format("Y");
 const date = moment(ReportDate).add(-13, "M");
 return ["指标", "单位", ...underscore.range(13).map(() => date.add(1, "M").format("M") + "月"), year + "店均", year + "标杆"];
}

export function getMyTableData(tableData, columnsData, allData) {
 Object.keys(tableData).map(table => {
  getChangeTableData(tableData[table], columnsData, allData);
 });
}

export function getChangeTableData(tableData, columnsData, allData) {
 tableData.data = [];
 tableData.kpiCodes.map(k => {
  let arr = {};
  let j = 12;
  columnsData.forEach((d, i) => {
   let attribute = "";
   if(i < 2) {
    if(d === "指标") arr[i] = k.title;
    if(d === "单位") arr[i] = k.unit;
   } else if(i > 1 && i < 15) {
    if(k.code === "") {
     //  arr[i] = randomData();
     arr[i] = "-";
    } else {
     attribute = "N-" + (j--).toString();
     arr[i] = sysData(allData, attribute, k.code);
    }
   } else {
    if(k.code === "") {
     arr[i] = "-";
    } else {
     if(d === "年度累计" || d.indexOf("店均") > 0) arr[i] = sysData(allData, "YearAvg", k.code);
     if(d.indexOf("标杆") > 0) {
      arr[i] = sysData(allData, "YearStandard", k.code);
      if(k.title === "盈利面" || k.title === "总店数" || k.title === "盈利店数") arr[i] = "-";
     }
    }
   }
   // 单位处理
   if(typeof arr[i] === "number") {
    arr[i] = UnitProcessing(k.unit, arr[i]);
   }
  });
  tableData.data.push(arr);
 });
}

export function getMyChartData(allData, tableData, chartAreaData) {
 Object.keys(tableData).map(table => {
 // tableData[table].setting.label.show = false; // 隐藏所有的柱图上面显示的文字
  getChangeChartData(allData, tableData[table], chartAreaData);
 });
}

export function getChangeChartData(allData, tableData, chartAreaData) {
 tableData.rows = [];
 chartAreaData.forEach((c) => {
  let arr = {};
  tableData.columns.map((d, j) => {
   arr[d] = randomData();
   if(j === 0) {
    arr[d] = c.PartName; // X轴区域
    if(tableData.columns[1] === "盈利店数" && c.PartName === "均值") arr[d] = "年度累计";
   } else {
    if(allData[c["PartId"]] !== undefined) {
     if(tableData.kpiCodes[j - 1].code === "") {
      arr[d] = 0;
     } else {
      arr[d] = sysData(allData[c["PartId"]], "N-0", tableData.kpiCodes[j - 1].code);
     }
    } else {
     arr[d] = 0;
    }
    arr[d] = UnitChartProcessing(tableData.kpiCodes[j - 1].unit, arr[d]);
   }
  });
  tableData.rows.push(arr);
 });
}

export function randomData() {
 return underscore.random(0, 100);
}

export function sysData(allData, attribute, code) {
 try {
  return allData[attribute][code] ?? 0;
 } catch (error) {
  return 0;
 }
}

export const pushFilterCodes = [
 "C0408004", "A0106024", "B0105113", "C0206001", "C0408004", "C0408021", "A0106024", "A0107002", "B0105113", "B0106026",
 "C0206001", "C0207022", "C0203020", "C0207028", "C0107003", "C0107002", "C0106003", "C0107002", "C0106003",
];

export const opPushFilterCodes = ["A0302001", "A1303043", "A1303080",
 "A1302115", "E1202091+E1202092+E1202093+E1202094", "A1303036", "A1302019", "A1303024", "A1302028", "A1303032", "A1302032","A0302022",
 "C0303031", "A0302023", "A0107004", "A0106026", "A0107003", "A0105072", "C0302233", "A0304034", "A0107014", "C0302314", "A0303200",
 "C0302315", "A0303201", "A0402006", "C0207021", "B0302001", "B0104226", "B0105131", "B0105161", "B0105181", "B0303062","B0107002",
 "B0302037", "B0304012", "A0104115", "A0104116", "A0104117", "A0104118", "A0104119", "A0104120", "A0104133", "C0302208+C0302314+C0302315","A0105071"
];

export const opCurrentFilterCodes = [
 "A1302115", "A1303045", "A1303071", "A1303083", "A1402135", "A1402155", "A0302001", "A0402006", "A1402235", "A1403021",
 "A0106026", "A0304024", "A0304025", "A0304026", "A0304027", "A1303043", "A1303083", "A1302012", "A1302013", "A1303007",
 "E1202091", "E1202092", "E1202093", "E1202094", "E1202095", "E1202099", "E1202096+E1202097+E1202098", "A1303036", "A1302028",
 "A1303024", "A1302032", "A1303028", "A1303032", "C0302121", "C0302122", "C0302123", "A0303006", "A0106026", "A0107003","A0105071",
 "A0105072", "A0107002", "A0106026", "A1403021", "C0302314", "A0303200", "C0302315", "A0303201", "A0403024", "A0403025",
 "A0403026", "A0403027", "C0207021", "B0107002",
 "A0402013/A0402012", "A0402014/A0402012", "A0402015/A0402012", "A0402016/A0402012", "A0402019/A0402018", "A0402020/A0402018",
 "A0402021/A0402018", "A0402022/A0402018", "A0402025/A0402024", "A0402026/A0402024", "A0402027/A0402024", "A0402028/A0402024",
 "A0402031/A0402030", "A0402032/A0402030", "A0402033/A0402030", "A0402034/A0402030", "A0402037/A0402036", "A0402038/A0402036",
 "A0402039/A0402036", "A0402040/A0402036", "A0402043/A0402042", "A0402044/A0402042", "A0402045/A0402042", "A0402046/A0402042",
 "A0402049/A0402048", "A0402050/A0402048", "A0402051/A0402048", "A0402052/A0402048", "A0402055/A0402054", "A0402056/A0402054",
 "A0402057/A0402054", "A0402058/A0402054", "A0402061/A0402060", "A0402062/A0402060", "A0402063/A0402060", "A0402064/A0402060",
 "A0402116/A0402115", "A0402117/A0402115", "A0402118/A0402115", "A0402119/A0402115", "A0402122/A0402121", "A0402123/A0402121",
 "A0402124/A0402121", "A0402125/A0402121", "A0402128/A0402127", "A0402129/A0402127", "A0402130/A0402127", "A0402131/A0402127",
 "A0402134/A0402133", "A0402135/A0402133", "A0402136/A0402133", "A0402137/A0402133", "A0402148/A0402147", "A0402149/A0402147",
 "A0402150/A0402147", "A0402171/A0402147", "A0402173/A0402172", "A0402174/A0402172", "A0402175/A0402172", "A0402176/A0402172",
 "A0402179/A0402178", "A0402180/A0402178", "A0402181/A0402178", "A0402182/A0402178", "A0402006", "A0404111", "A0404181",
 "A0402012", "A0402018", "A0402024", "A0402030", "A0402036", "A0402042", "A0402048", "A0402054", "A0402060", "A0402115",
 "A0402121", "A0402127", "A0402133", "A0402147", "A0402172", "A0402178", "A0404112", "A0404113", "A0404114", "A0404115",
 "A0404116", "A0404117", "A0404118", "A0404119", "A0404120", "A0404121", "A0404122", "A0404123", "A0404124", "A0404125",
 "A0404141", "A0404142", "A0404143", "A0404144", "A0404145", "A0404146", "A0404147", "A0404148", "A0404149", "A0404150",
 "A0404151", "A0404152", "A0404153", "A0404154", "A0404001", "B0302001", "B0104226", "B0105131", "B0105161", "B0105181",
 "B0303019", "B0302035/B0302037", "B0302036/B0302037", "B0304012", "B0303024", "B0304014", "A0105072", "A0105023", "A0102085",
 "A0102086", "A0102087", "A0102088", "A0102089", "A0102091", "A0102261", "A0303271", "A0303272", "A0303273", "A0303274",
 "A0303275", "A0303276", "A0303277", "A0404126", "A0404155", "A0404127", "A0404156", "A0105051", "A0105052", "A0105053", "A0105054",
 "A0105055", "A0105056", "A0105057", "A0105058", "A0105059", "A0105060", "A0105061", "A0105062", "A0105063", "A0105064", "A0105065",
 "A0105066", "A1403022", "A1403023", "A1403024", "A1403025", "A1403026", "A1403027", "A1403028", "A1403029", "A1403030", "A1403031",
 "A1403032", "A1403033", "A1403034", "A1403035", "A1403036", "A1403037",

];
export const lastMonthFilterCodes = ["A1402185", "A1402205"];
