import StoreDataList from "../views/dataValidation/storeThe/StoreDataList";
import StoreDataListNew from "../views/dataValidation/storeThe/StoreDataListNew";
import FactoryDataList from "../views/dataValidation/factorySide/factoryDataList";
import ReportEfficiencyStatistics from "../views/dataValidation/factorySide/reportEfficiencyStatistics";
import CheckFail from "../views/dataValidation/storeThe/checkFail";
import dataexplain from "@/views/dataExplain/list";

let dataReportRouter = [
  // 店端数据校验报告
  {
    path: "/ddataList",
    component: StoreDataList,
  }, // 店端数据校验报告new
  {
    path: "/dndataList",
    component: StoreDataListNew,
  },
  // 厂端数据校验报告
  {
    path: "/fdataList",
    component: FactoryDataList,
  },
  // 厂端填报效率统计
  {
    path: "/reportEfficiencyStatistics",
    component: ReportEfficiencyStatistics,
  },
  // 在线填报测试
  {
    path: "/check",
    component: CheckFail,
  },
    // 指标解读视频
    {
        path: "/dataexplain",
        component: dataexplain,
    }
];

export default dataReportRouter
