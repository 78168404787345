<template>
  <section>
    <a-card>
      <a-button type="primary" @click="load(null)" style="margin-bottom: 10px">
        <a-icon type="file-add"/>
        添加小组
      </a-button>
      <!--表格-->
      <a-tabs v-model="tabActiveKey" @change="getGroupInfo">
        <a-tab-pane key="private" tab="仅我可见">
          <a-table :columns="columns" :data-source="privateData" :pagination="true" rowKey="GroupId">
            <span slot="action" slot-scope="text, record">
              <a-button type="default" style="margin-right: 10px" @click="loadDetail(record)">详细</a-button>
              <a-button type="primary" style="margin-right: 10px" @click="load(record)">修改</a-button>
              <a-button type="default" style="margin-right: 10px" @click="load(record,true)">复制</a-button>
              <a-popconfirm title="确定删除该对标小组？" ok-text="确定" cancel-text="取消" @confirm="deleteRecord(record)">
                <a-button type="primary" style="margin-right: 10px">删除</a-button>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="common" tab="通用" force-render>
          <a-table :columns="columns" :data-source="commonData" :pagination="true" rowKey="GroupId">
            <span slot="action" slot-scope="text, record">
              <a-button type="default" style="margin-right: 10px" @click="loadDetail(record)">详细</a-button>
              <a-button type="primary" style="margin-right: 10px" @click="load(record)">修改</a-button>
              <a-button type="default" style="margin-right: 10px" @click="load(record,true)">复制</a-button>
              <a-popconfirm title="确定删除该对标小组？" ok-text="确定" cancel-text="取消" @confirm="deleteRecord(record)">
                <a-button type="primary" style="margin-right: 10px">删除</a-button>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <!--信息窗口-->
      <a-modal v-model="modifyForm.visible" :title="modifyForm.title" okText="保存" @ok="save">
        <a-switch style="margin-bottom: 15px" checked-children="仅我可见" un-checked-children="通用"
                  v-model="recordIsPrivate"/>
        <div>
          <div style="margin-bottom: 5px">小组名称：</div>
          <a-input placeholder="请输入小组名称" v-model="modifyForm.data.GroupName"/>
        </div>
        <br/>
        <div>
          <div style="margin-bottom: 5px">备注：</div>
          <a-input v-model="modifyForm.data.GroupRemark" placeholder="请输入备注"/>
        </div>
      </a-modal>
      <!--详细窗口-->
      <a-modal width="70%" v-model="detailForm.visible" title="对标小组维护" okText="保存" @ok="saveDetail">
        <div style="margin-bottom: 10px">
          <a-tag color="#24619e" v-for="item in this.detailForm.data.filter(d=>d.Included)"
                 :key="item.DealerName" :closable="true" @close="()=> item.Included = false">
            {{ item.DealerName }}
          </a-tag>
        </div>
        <div style="height: 600px;overflow: auto">
          <!--表格-->
          <a-table :columns="detailForm.columns" :data-source="detailForm.data" rowKey="DealerCode"
                   :row-selection="dealerSelection">
            <!--下拉框过滤-->
            <div slot="selectFilter"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                 style="padding: 8px">
              <a-button type="primary" @click="()=>confirm()">确定</a-button>
              <a-button @click="()=> {detailForm.filter[column.dataIndex]=[];clearFilters()}">重置</a-button>
              <br/>
              <a-select mode="multiple" style="margin-top: 5px;width: 400px;" :placeholder="'请选择 '+ column.title"
                        allowClear
                        v-model="detailForm.filter[column.dataIndex]"
                        @change="value => setSelectedKeys(value)">
                <a-select-option v-for="value in detailForm.filterValues[column.dataIndex]" :key="value">
                  {{ value === '' ? '(空白)' : value }}
                </a-select-option>
              </a-select>
            </div>
            <!--数值范围-->
            <div slot="numRangeFilter"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                 style="padding: 8px">
              <a-button type="primary"
                        @click="()=>{ setSelectedKeys([[...detailForm.filter[column.dataIndex]]]); confirm()}">
                确定
              </a-button>
              <a-button @click="()=> {detailForm.filter[column.dataIndex]=[];clearFilters()}">重置</a-button>
              <br/>
              <a-input-group compact style="margin-top: 5px">
                <a-input style=" width: 100px; text-align: center" placeholder="最少" @change="e=> {
                  detailForm.filter[column.dataIndex][0] = Number(e.target.value);
                }"/>
                <a-input style=" width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                         placeholder="~"
                         disabled/>
                <a-input style="width: 100px; text-align: center; border-left: 0" placeholder="最多" @change="e=> {
                  detailForm.filter[column.dataIndex][1] = Number(e.target.value);
                }"/>
              </a-input-group>
            </div>
            <!--时间范围-->
            <div slot="dateRangeFilter"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                 style="padding: 8px">
              <a-button type="primary" @click="()=>confirm()">确定</a-button>
              <a-button @click="()=> {detailForm.filter[column.dataIndex]=[];clearFilters()}">重置</a-button>
              <br/>
              <a-range-picker style="margin-top: 5px;width: 400px;"
                              v-model="detailForm.filter[column.dataIndex]"
                              @change="(date,dateStr) => setSelectedKeys([dateStr])"/>
            </div>
            <div slot="Status" slot-scope="Status">
              {{ Status === "退网" ? "退网" : "" }}
            </div>
          </a-table>
        </div>
      </a-modal>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 60,
          customRender: (text, record, index) => ++index,
        },
        {
          title: "小组名称",
          dataIndex: "GroupName",
          key: "GroupName",
        },
        {
          title: "备注",
          dataIndex: "GroupRemark",
          key: "GroupRemark",
        },
        {
          title: "创建时间",
          dataIndex: "GroupCreateTime",
          key: "GroupCreateTime",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: {customRender: "action"},
        },
      ],
      tabActiveKey: "private",
      privateData: [],
      commonData: [],
      modifyForm: {
        visible: false,
        title: "",
        data: {},
      },
      detailForm: {
        visible: false,
        columns: [
          {
            title: "大区",
            dataIndex: "PartName",
            key: "PartName",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "战区",
            dataIndex: "ZoneName",
            key: "ZoneName",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "省份",
            dataIndex: "AreaName",
            key: "AreaName",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "城市",
            dataIndex: "CityName",
            key: "CityName",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "经销商名称",
            dataIndex: "DealerName",
            key: "DealerName",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "经销商编号",
            dataIndex: "DealerCode",
            key: "DealerCode",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "年度提车任务",
            dataIndex: "SalesTarget",
            key: "SalesTarget",
            scopedSlots: {
              filterDropdown: 'numRangeFilter',
            },
          },
          {
            title: "开业时间",
            dataIndex: "OpenTime",
            key: "OpenTime",
            scopedSlots: {
              filterDropdown: 'dateRangeFilter',
            },
          },
          {
            title: "城市级别",
            dataIndex: "CityLevel",
            key: "CityLevel",
            scopedSlots: {
              filterDropdown: 'selectFilter',
            },
          },
          {
            title: "状态",
            dataIndex: "Status",
            key: "Status",
            scopedSlots: {
              // customRender: 'Status',
              filterDropdown: 'selectFilter',
            },
          },
        ],
        filter: {},
        filterValues: {},
        data: [],
        groupId: 0
      },
    };
  },
  created() {
    this.getGroupInfo();
    this.loadFilters();
  },
  computed: {
    selectedDealerCodes: {
      get() {
        return this.detailForm.data.filter(f => f.Included).map(d => d.DealerCode);
      },
      set(selectDealerCodes) {
        this.detailForm.data.forEach(d => d.Included = selectDealerCodes.includes(d.DealerCode));
      }
    },
    dealerSelection() {
      let self = this;
      return {
        selectedRowKeys: this.selectedDealerCodes,
        onChange: function (selectDealerCodes) {
          self.selectedDealerCodes = selectDealerCodes;
        },
      };
    },
    isPrivate: function () {
      return this.tabActiveKey === "private";
    },
    recordIsPrivate: {
      get() {
        return this.modifyForm.data.IsPrivate != null && this.modifyForm.data.IsPrivate;
      },
      set(value) {
        this.modifyForm.data.IsPrivate = value;
      }
    }
  },
  methods: {
    async getGroupInfo() {
      let res = await this.$Http.queryDealerGroup({
        isPrivate: this.isPrivate
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this[this.tabActiveKey + "Data"] = res.Data;
    },
    load(record, copy) {
      if (copy) {
        record = {
          GroupId: 0,
          GroupName: "",
          GroupRemark: "",
          CreateUserId: record.CreateUserId,
          IsPrivate: record.CreateUserId.length > 0,
          sourceGroupId: record.GroupId
        };
        this.modifyForm.title = "复制小组";
      } else if (record == null) {
        record = {GroupId: 0, GroupName: "", GroupRemark: "", IsPrivate: this.isPrivate};
        this.modifyForm.title = "添加小组";
      } else {
        record = Object.assign({}, record);
        record.IsPrivate = record.CreateUserId.length > 0;
        this.modifyForm.title = "修改小组";
      }
      this.modifyForm.data = record;
      this.modifyForm.visible = true;
    },
    async save() {
      let record = this.modifyForm.data;
      record.GroupName = record.GroupName.trim();
      if (record.GroupName.length <= 0) {
        this.$message.warn("请输入名称");
        return;
      }
      record.GroupRemark = record.GroupRemark.trim();

      let res;
      if (record.sourceGroupId > 0) {
        // 复制
        res = await this.$Http.CopyDealerGroup({
          name: record.GroupName,
          remark: record.GroupRemark,
          createUserId: record.IsPrivate ? "private" : record.CreateUserId,
          sourceGroupId: record.sourceGroupId,
        });
      } else {
        res = await this.$Http.saveDealerGroup(record);
      }
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      if (this.recordIsPrivate) {
        this.tabActiveKey = "private";
      } else {
        this.tabActiveKey = "common";
      }
      await this.getGroupInfo();
      this.modifyForm.visible = false;
    },
    async loadDetail(record) {
      let res = await this.$Http.getDealerGroupDetail({
        groupId: record.GroupId,
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }

      this.detailForm.groupId = record.GroupId;
      this.detailForm.data = res.Data;
      this.detailForm.visible = true;
    },
    loadFilters() {
      this.detailForm.columns.forEach(column => {
        if (!column.scopedSlots || !column.scopedSlots.filterDropdown)
          return;

        let type = column.scopedSlots.filterDropdown;
        if (type.startsWith("select")) {
          column.onFilterDropdownVisibleChange = visible => this.onFilterDropdownVisibleChange(visible, column.dataIndex);
          column.ignoreFilter = function (filter) {
            return filter.length <= 0;
          };
          column.onFilter = (value, record) => {
            return value.includes(record[column.dataIndex])
          };
        } else if (type.startsWith("date")) {
          column.ignoreFilter = function (filter) {
            return filter.length !== 2
          };
          column.onFilter = (value, record) => {
            let data = record[column.dataIndex];
            return value.length === 2 && data >= value[0] && data <= value[1];
          }
        } else if (type.startsWith("num")) {
          this.detailForm.filter[column.dataIndex] = [NaN, NaN];
          column.ignoreFilter = function (filter) {
            if (filter.length !== 2)
              return true;
            return Number.isNaN(filter[0]) && Number.isNaN(filter[0])
          };
          column.onFilter = (value, record) => {
            if (value.length !== 2)
              return true;

            let data = record[column.dataIndex];
            return (Number.isNaN(value[0]) || value[0] <= data) && (Number.isNaN(value[1]) || value[1] >= data);
          }
        }
      });
    },
    onFilterDropdownVisibleChange(visible, dataIndex) {
      if (!visible) {
        return;
      }
      // 生成过滤条件
      let filters = [];
      this.detailForm.columns.forEach(column => {
        // 忽略当前列
        if (column.dataIndex === dataIndex) {
          return;
        }
        let filter = this.detailForm.filter[column.dataIndex];
        if (filter == null || column.ignoreFilter == null || column.ignoreFilter(filter)) {
          return;
        }
        let filterInfo = {};
        filterInfo.filter = filter;
        filterInfo.onFilter = column.onFilter;
        filters.push(filterInfo);
      });

      let keys = [];
      this.detailForm.data.forEach(dealer => {
        // 排除已被筛选掉的记录
        if (filters.length > 0 && filters.chain()
            .any(f => !f.onFilter(f.filter, dealer))
            .value()) {
          return;
        }

        let value = dealer[dataIndex];
        if (value == null) {
          dealer[dataIndex] = value = "";
        }
        keys[value] = null;
      });
      this.detailForm.filterValues[dataIndex] = Object.keys(keys);
    },
    async saveDetail() {
      let res = await this.$Http.modifyDealerGroupDetail({
        ApiParamObj: {
          GroupId: this.detailForm.groupId,
          DealerCodeList: this.selectedDealerCodes.join(","),
        },
      });
      if (!res.Success) {
        this.$message.error(res.Message);
      }
      this.detailForm.visible = false;
    },
    async deleteRecord(record) {
      let res = await this.$Http.DeleteDealerGroup({
        groupId: record.GroupId
      });
      if (!res.Success) {
        this.$message.error(res.Message);
      }
      await this.getGroupInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.group {
  margin-bottom: 30px;
}

.first-checkbox-align > label {
  margin-left: 0;
}

.ant-checkbox-wrapper {
  min-width: 240px;
  margin-bottom: 5px;
}

.ant-tag {
  margin-bottom: 20px;
}
</style>
