/**
 * Created by ZHANGJINGWU on 2021-07-21 11:05.
 */
const carTypeCode = {
    来厂实绩: "B0302001",
    总产值: "B0103097",
    店均产值: "B0103097_avg",
    单车产值: "B0104226",
    毛利率: "B0105181",
    满意度调查成绩: "B0603054",
    售后万台投诉率: "B0603003",

    chart1: {
        来厂实绩: "B0302001",
    },
    chart2: {
        总来厂台次: "B0302001",
    },
    chart3: {
        首保: "B0302004",
        定保: "B0302005",
        一般维修: "B0302006",
        改装美容: "B0302071",
        市场活动: "B0302008",
        保修: "B0302009",
        钣喷: "B0302010",
    },
    chart4: {
        产值实绩: "B0103097"
    },
    chart5: {
        店均产值: "B0103097"
    },
    chart6: {
        单车产值: "B0104226"
    },
    chart7: {
        工时毛利: "B0104063",
        零部件毛利: "B0104044",
        毛利率: "B0105181",
    },
    chart8: {
        首保: "B0103081",
        定保: "B0103082",
        一般维修: "B0103083",
        改装美容: "B0103093",
        市场活动: "B0103085",
        保修: "B0103086",
        钣喷: "B0103087",
    },
    chart9: {
        保有客户数: "B0302037",
        保有率: "B0304012",
    },

    chart10: {
        A类客户占比: "B0303019",
        B类客户占比: "B0302035/B0302037",
        C类客户占比: "B0302036/B0302037",
        ABC类客户: "B0302034+B0302035+B0302036",
    },

    chart11: {
        首保招揽成功率: "B0303071",
        定保招揽成功率: "B0303072",
    },
}

export {
    carTypeCode
};
