<template>
    <a-row :gutter="[0,10]">
        <!-- 盈利能力(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">盈利能力({{ Area }})</div>
                <profit-table :columnsData="columnsDataYear" :tableData="tableData.table_1"/>
            </div>
        </a-col>
        <!--   盈利店分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">盈利店分析</div>
                <main-chart :chart-data="chartData.chartData1"/>
            </div>
        </a-col>
        <!-- 盈利能力   -->
        <a-col :span="24">
            <div class="card-border">
                <profit-table :columnsData="columnsData" :tableData="tableData.table_2"/>
            </div>
        </a-col>
        <!--   税前利润分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">税前利润分析</div>
                <main-chart :chart-data="chartData.chartData2"/>
            </div>
        </a-col>
        <!--   销售综合毛利分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">销售综合毛利分析</div>
                <main-chart :chart-data="chartData.chartData3"/>
            </div>
        </a-col>
        <!--   售后综合毛利分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">售后综合毛利分析</div>
                <main-chart :chart-data="chartData.chartData4"/>
            </div>
        </a-col>
        <!--   总费用分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">总费用分析</div>
                <main-chart :chart-data="chartData.chartData5"/>
            </div>
        </a-col>
        <!-- table3   -->
        <a-col :span="24">
            <div class="card-border">
                <profit-table :columnsData="columnsData" :tableData="tableData.table_3"/>
            </div>
        </a-col>
        <!--   人力费用分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">人力费用分析</div>
                <main-chart :chart-data="chartData.chartData6"/>
            </div>
        </a-col>
        <!-- 偿债能力   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">偿债能力({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_4"/>
            </div>
        </a-col>
        <!--   资产负债率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">资产负债率</div>
                <main-chart :chart-data="chartData.chartData7"/>
            </div>
        </a-col>
        <!--   速动比率及流动比率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">速动比率及流动比率</div>
                <main-chart :chart-data="chartData.chartData8"/>
            </div>
        </a-col>
    </a-row>
</template>

<script>
import {
 getChangeTableData,
 getColumnsData,
 getMyChartData,
 getMyTableData,
} from "@/views/profitReport/untilProfit";
import profitTable from "@/components/profitTable";
import {
 legend, grid, xAxis, yAxisDefault, yAxisRight, seriesLine, seriesBar,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import moment from "moment";

export default {
 name: "oneProfit",
 components: { profitTable, mainChart },
 props: {
  dataParams: {},
  allData: {},
 },
 data() {
  return {
   columnsData: getColumnsData(this.dataParams.ReportDate),
   columnsDataYear: [],
   tableData: {
    table_1: {
     data: [],
     kpiCodes: [
      {
       title: "盈利面",
       unit: "%",
       code: "盈利面",
      },
      {
       title: "总店数",
       unit: "个",
       code: "总店数",
      },
      {
       title: "盈利店数",
       unit: "个",
       code: "盈利店数",
      },
     ],
    },
    table_2: {
     data: [],
     kpiCodes: [
      {
       title: "店均税前利润",
       unit: "万元",
       code: "C0408004",
      },
      {
       title: "店均销售综合毛利",
       unit: "万元",
       code: "A0106024",
      },
      {
       title: "店均售后综合毛利",
       unit: "万元",
       code: "B0105113",
      },
      {
       title: "店均总费用",
       unit: "万元",
       code: "C0206001",
      },
     ],
    },
    table_3: {
     data: [],
     kpiCodes: [
      {
       title: "店均人力费用",
       unit: "万元",
       code: "C0203020",
      },
     ],
    },
    table_4: {
     data: [],
     kpiCodes: [
      {
       title: "资产负债率",
       unit: "%",
       code: "C0107003",
      },
      {
       title: "速动比率",
       unit: "%",
       code: "C0107002",
      },
      {
       title: "流动比率",
       unit: "%",
       code: "C0106003",
      },
     ],
    },
   },
   chartData: {
    chartData1: {
     columns: ["区域", "盈利店数", "亏损店数", "盈利面"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "盈利店数" },
      { unit: "个", code: "亏损店数" },
      { unit: "%", code: "盈利面" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["盈利店数", "亏损店数"] },
      showLine: ["盈利面"],
      axisSite: { right: ["盈利面"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [
       seriesBar, seriesLine, seriesLine,
      ],
     },
    },
    chartData2: {
     columns: ["区域", "店均税前利润", "税前利润率"],
     rows: [],
     kpiCodes: [
      { unit: "万元", code: "C0408004" },
      { unit: "%", code: "C0408021" },
     ],
     setting: {
      type: "histogram",
      showLine: ["税前利润率"],
      axisSite: { right: ["税前利润率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["万元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(2);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(2)}万元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis, yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData3: {
     columns: ["区域", "店均销售综合毛利", "销售综合毛利率"],
     rows: [],
     kpiCodes: [
      { unit: "万元", code: "A0106024" },
      { unit: "%", code: "A0107002" },
     ],
     setting: {
      type: "histogram",
      showLine: ["销售综合毛利率"],
      axisSite: { right: ["销售综合毛利率"] },
      yAxisType: ["0.[00]", "0.[0]"],
      yAxisName: ["万元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(2);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(2)}万元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData4: {
     columns: ["区域", "店均售后综合毛利", "售后综合毛利率"],
     rows: [],
     kpiCodes: [
      { unit: "万元", code: "B0105113" },
      { unit: "%", code: "B0106026" },
     ],
     setting: {
      type: "histogram",
      showLine: ["售后综合毛利率"],
      axisSite: { right: ["售后综合毛利率"] },
      yAxisType: ["0.[00]", "0.[0]"],
      yAxisName: ["万元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(2);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(2)}万元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData5: {
     columns: ["区域", "店均总费用", "费用率"],
     rows: [],
     kpiCodes: [
      { unit: "万元", code: "C0206001" },
      { unit: "%", code: "C0207022" },
     ],
     setting: {
      type: "histogram",
      showLine: ["费用率"],
      axisSite: { right: ["费用率"] },
      yAxisType: ["0.[00]", "0.[0]"],
      yAxisName: ["万元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(2);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(2)}万元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData6: {
     columns: ["区域", "店均人力费用", "人力费用率"],
     rows: [],
     kpiCodes: [
      { unit: "万元", code: "C0203020" },
      { unit: "%", code: "C0207028" },
     ],
     setting: {
      type: "histogram",
      showLine: ["人力费用率"],
      axisSite: { right: ["人力费用率"] },
      yAxisType: ["0.[00]", "0.[0]"],
      yAxisName: ["万元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(2);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(2)}万元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData7: {
     columns: ["区域", "<40%的店占比", "40~70%的店占比", ">70%的店占比", "资产负债率"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "<40%的店占比" },
      { unit: "%", code: "40~70%的店占比" },
      { unit: "%", code: ">70%的店占比" },
      { unit: "%", code: "C0107003" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["<40%的店占比", "40~70%的店占比", ">70%的店占比"] },
      showLine: ["资产负债率"],
      axisSite: { right: ["资产负债率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["%", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return (params.data) + "%";
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesLine],
     },
    },
    chartData8: {
     columns: ["区域", "速动比率", "流动比率"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "C0107002" },
      { unit: "%", code: "C0106003" },
     ],
     setting: {
      type: "histogram",
      showLine: ["速动比率", "流动比率"],
      yAxisType: ["0.[0]"],
      yAxisName: ["%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return (params.data) + "%";
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesLine, seriesLine],
     },
    },
   },
   Area: this.dataParams.PartName.replace("营销中心", ""),
  };
 },
 methods: {
  getArea() {
   this.Area = this.dataParams.PartName.replace("营销中心", "");
  },
  getTableData() {
   // 年度表格
   this.columnsDataYear = getColumnsData(this.dataParams.ReportDate);
   this.columnsDataYear[this.columnsDataYear.length - 2] = moment(this.dataParams.ReportDate).format("Y") + "累计";
   // 月均表格数据
   this.columnsData = getColumnsData(this.dataParams.ReportDate);
   getMyTableData(this.tableData, this.columnsData, this.allData[this.dataParams.PartId]);

  },
  getChartData() {
   getMyChartData(this.allData, this.chartData, this.dataParams.AreaList);
  },
 },
 created() {
  this.getTableData();
  this.getChartData();
  this.getArea();
 },
 watch: {
  allData: {
   handler: function() {
    this.getTableData();
    this.getChartData();
    this.getArea();
   },
   deep: true,
  },
 },
};
</script>

<style lang="less" scoped>
@import "./profit";
</style>
