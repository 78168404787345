<template>
  <a-row :gutter="[0,8]">
    <a-col :span="24">
      <a-card style="text-align: center;">
        <!--        <a-descriptions bordered :column="2" style="padding-top: 10px">
                  <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem" :key="i">
                    {{ item.value }}
                  </a-descriptions-item>
                </a-descriptions>-->
        <table class="custom-table">
          <thead>
          <tr>
            <td v-for="item in descriptionsColumns" :key="item.key">{{ item.title }}</td>
          </tr>
          </thead>
          <tr v-for="(item,i) in descriptionsData.data" :key="i++">
            <td v-for="data in descriptionsColumns" :key="data.key">{{ item[data.dataIndex] }}</td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h2>一、利润表</h2>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData1"></a-my-table>
      </a-card>
    </a-col>
<!--    <a-col :span="24">
      <a-card>
        <h3>单车综合毛利</h3>
        <chartComponent :chart-data="chartData.chartData1"></chartComponent>
      </a-card>
    </a-col>-->
    <a-col :span="24">
      <a-card>
        <h2>二、四大通道效率</h2>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData2"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>终端实绩</h3>
        <chartComponent :chart-data="chartData.chartData2"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <a-my-table :columns-data="tableMouthData.tableMouth1.columns" :table-data="tableMouthData.tableMouth1"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <a-my-table :columns-data="tableMouthData.tableMouth2.columns" :table-data="tableMouthData.tableMouth2"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>展厅漏斗</h3>
        <chartComponent :chart-data="funnelData.funnelData1"/>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>IDCC漏斗</h3>
        <chartComponent :chart-data="funnelData.funnelData2"/>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>汽车之家本月漏斗</h3>
        <chartComponent :chart-data="funnelData.funnelData3"/>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>懂车帝本月漏斗</h3>
        <chartComponent :chart-data="funnelData.funnelData3"/>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>二网效率</h3>
        <a-descriptions bordered :column="3" style="padding-top: 10px">
          <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem1" :key="i">
            {{ item.value }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>二网销量及销量占比</h3>
        <chartComponent :chart-data="chartData.chartData3"></chartComponent>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import chartComponent from "@/components/chartComponent";
import {
  barMaxWidth,
  settingsLabel,
  mouth12Chart,
  yAxisUnit,
  funnelLabel,
  funnelTooltip, monthGetData, tableIndex,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import { oneData } from "@/views/monthReport/shopAnalysis/component/myData";
import Tooltip from "ant-design-vue/lib/tooltip/Tooltip";
import {
  xAxis,
  seriesBar,
  seriesLine,
  yAxisDefaultUnit, yAxisRightUnit,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";

function toolFun(params, unit) {
  let arr = {};
  params.forEach((d, i) => {
    if(d.componentSubType === "bar") {
      if(unit === "万元") {
        arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${unit}`;
      } else if(unit === "%") {
        arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
      } else {
        arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${unit}`;
      }
    }
    if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
    }
  });
  let re = params[0].name + "<br />";
  Object.values(arr).forEach(a => {
    re += a + "<br />";
  });
  return re;
}

function labelFormat(params, unit) {
  if(params["componentSubType"] === "line") {
    return percentage(params.data);
  } else {
    if(unit === "万元") return tenThousandYuan(params.data);
    if(unit === "%") return percentage(params.data);
    return ThousandYuan(params.data);
  }
}
export default {
  name: "twoSales",
  components: { aMyTable, chartComponent },
  props: {
    allData: { type: Object },
    dataParams: {},
  },
  data() {
    return {
      descriptionsItem: [
        { title: "本月盈亏保本台次", value: "0台", kpiCode: "C0208001" },
        { title: "终端实绩全国标杆值", value: "0台", kpiCode: "C0208001" },
        { title: "本月终端实绩", value: "0台", kpiCode: "A0302001" },
        { title: "提车实绩全国标杆值", value: "0台", kpiCode: "A0302001" },
      ],
      descriptionsItem1: [
        { title: "星级站店数", value: "0台", kpiCode: "C0302121" },
        { title: "星级站销量", value: "0台", kpiCode: "A0302281" },
        { title: "星级站店均销量", value: "0台", kpiCode: "A0303351" },
        { title: "认证二网店数", value: "0台", kpiCode: "C0302122" },
        { title: "认证二网销量", value: "0台", kpiCode: "A0302282" },
        { title: "认证二网店均销量", value: "0台", kpiCode: "A0303352" },
      ],
      descriptionsColumns: [
        {
          title: " ",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "提车实绩",
          dataIndex: "actualResults",
          key: "A1402155",
        },
        {
          title: "提车达成率",
          dataIndex: "accomplish",
          key: "A0403001",
        },
        {
          title: "终端实绩",
          dataIndex: "terminal",
          key: "A0302001",
        },
        {
          title: "终端达成率",
          dataIndex: "achievement",
          key: "A0403002",
        },
        {
          title: "单车销售综合毛利",
          dataIndex: "achievementRate",
          key: "A0107004",
        },
        {
          title: "盈亏保本台次",
          dataIndex: "homotopy",
          key: "C0208001",
        },
      ],
      descriptionsData: {
        table: [
          { name: "当月值", kpiCode: "Current" },
          { name: "区域标杆", kpiCode: "RegionalStandard" },
          { name: "全国标杆", kpiCode: "NationwideStandard" },
        ],
        data: [],
      },
      columnsC: [
        {
          key: "name", title: "指标名称", dataIndex: "name", width: 150,
          customRender: (text, record) => {
            if (record.indentation > 0) {
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            }
            return text;
          },
        },
        { key: "Unit", title: "单位", dataIndex: "Unit" },
        {
          key: "Current", title: <div>当月值&nbsp;
            <Tooltip placement="bottom" title="备注:当月值与全国标杆值对比。">
              <a-icon type="info-circle"/>
            </Tooltip>
          </div>, dataIndex: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
            if (record.PercentileSort === "DESC") flag = !flag;
            if (flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑ </div>;
            }
          },
        },
        { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
        { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
        { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
        { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
        { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
        { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
      ],
      tableData: {
        tableData1: {
          table: oneData.salesTable.tableData1.table,
          columns: [],
          data: [],
        },
        tableData2: {
          table: [
            { name: "展厅终端占比", kpiCode: "A0304024" },
            { name: "展厅单人效率", kpiCode: "A0303200", indentation: 2 },
            { name: "IDCC终端占比", kpiCode: "A0304025" },
            { name: "IDCC单人效率", kpiCode: "A0303201", indentation: 2 },
            { name: "二网终端占比", kpiCode: "A0304026" },
            { name: "大客户终端占比", kpiCode: "A0304027" },
            { name: "大车占比", kpiCode: "A0304151" },
            { name: "保客相关销量占比", kpiCode: "A0304030" },
          ],
          columns: [],
          data: [],
        },
      },
      tableMouthData: {
        tableMouth1: {
          table: [
            { name: "展厅销量", kpiCode: "A0302021" },
            { name: "IDCC销量", kpiCode: "A0302022" },
            { name: "二网销量", kpiCode: "A0302023" },
            { name: "大客户销量", kpiCode: "A0302024" },
          ],
          columns: [],
          data: [],
        },
        tableMouth2: {
          table: [
            { name: "展厅客流", kpiCode: "A1303043" },
            { name: "展厅首次客流", kpiCode: "A1302012", indentation: 2 },
            { name: "展厅再次客流", kpiCode: "A1302013", indentation: 2 },
            { name: "展厅再次进店率", kpiCode: "A1303007", indentation: 2 },
            { name: "展厅订单", kpiCode: "A1303080" },
            { name: "首次客户订单", kpiCode: "A1302014", indentation: 2 },
            { name: "再次客户订单", kpiCode: "A1302111", indentation: 2 },
            { name: "展厅订单成交率", kpiCode: "A1303083" },
            { name: "首次客流成交率", kpiCode: "A1303081", indentation: 2 },
            { name: "再次客流成交率", kpiCode: "A1303082", indentation: 2 },
            { name: "展厅销量", kpiCode: "A0302021" },
          ],
          columns: [],
          data: [],
          columnsI: 14,
        },
      },
      chartData: {
        chartData1: {
          columns: oneData.salesTable.chartData.chartData1.columns,
          kpiCode: oneData.salesTable.chartData.chartData1.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["单车裸车毛利", "单车商政返利", "单车水平事业毛利"] },
            showLine: ["销售综合毛利率"],
            axisSite: { right: ["销售综合毛利率"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "元");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "元");
              },
            },
            yAxis: [yAxisDefaultUnit('元'),yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesBar,seriesBar,seriesLine],
          },
        },
        chartData2: {
          columns: ["日期", "终端实绩"],
          kpiCode: [
            { Code: "A0302001", Name: "终端实绩" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            yAxisType: ["0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "台");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "台");
              },
            },
            yAxis: [yAxisDefaultUnit('台')],
            xAxis,
            series:[seriesBar]
          },
        },
        chartData3: {
          columns: oneData.salesTable.chartData.chartData3.columns,
          kpiCode: oneData.salesTable.chartData.chartData3.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["二网销量占比"],
            axisSite: { right: ["二网销量占比"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "台");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "台");
              },
            },
            yAxis:[yAxisDefaultUnit('台')],
            xAxis,
            series: [seriesBar,seriesLine],
          },
        },
      },
      funnelData: {
        funnelData1: {
          settings: {
            type: "funnel",
          },
          options: {
            tooltip: funnelTooltip,
            legend: {
              //  data: ["展厅客流总数", "展厅新增订单数", "首次客户订单", "再次客户订单", "终端"],
              data: ["展厅客流总数", "展厅新增订单数", "终端"],
            },
            series: [
              {
                name: "展厅漏斗",
                label: funnelLabel,
                // sort: "none",
                data: [
                  { value: 0, name: "展厅客流总数", kpiCode: "A1303043" },
                  { value: 0, name: "展厅新增订单数", kpiCode: "A1303044" },
                  /* { value: 0, name: "首次客户订单", kpiCode: "A1302014" },
                   { value: 0, name: "再次客户订单", kpiCode: "A1302111" },*/
                  { value: 0, name: "终端", kpiCode: "A0302021" },
                ],
              },
            ],
          },
        },
        funnelData2: {
          settings: {
            type: "funnel",
          },
          options: {
            tooltip: funnelTooltip,
            legend: {
              data: ["线索总数", "有效线索数", "邀约到店数", "订单数", "终端"],
            },
            series: [
              {
                name: "IDCC漏斗",
                label: funnelLabel,
                // sort: "none",
                data: [
                  { value: 0, name: "线索总数", kpiCode: "A1302115" },
                  { value: 0, name: "有效线索数", kpiCode: "A1302019" },
                  { value: 0, name: "邀约到店数", kpiCode: "A1302028" },
                  { value: 0, name: "订单数", kpiCode: "A1302032" },
                  { value: 0, name: "终端", kpiCode: "A0302022" },
                ],
              },
            ],
          },
        },
        funnelData3: {
          settings: {
            type: "funnel",
          },
          options: {
            tooltip: funnelTooltip,
            legend: {
              data: ["线索总数", "有效线索数", "邀约到店数", "订单数", "终端"],
            },
            series: [
              {
                name: "汽车之家本月漏斗",
                label: funnelLabel,
                // sort: "none",
                data: [
                  { value: 0, name: "线索总数", kpiCode: "E1202093" },
                  { value: 0, name: "有效线索数", kpiCode: "E1202113" },
                  { value: 0, name: "邀约到店数", kpiCode: "E1202153" },
                  { value: 0, name: "订单数", kpiCode: "E1202173" },
                  { value: 0, name: "终端", kpiCode: "E0302162" },
                ],
              },
            ],
          },
        },
        funnelData4: {
          settings: {
            type: "funnel",
          },
          options: {
            tooltip: funnelTooltip,
            legend: {
              data: ["星级站数量1", "星级站数量2", "星级站数量3", "认证二网店数", "认证二网销量", "认证二网店均销量"],
            },
            series: [
              {
                name: "懂车帝本月漏斗",
                label: funnelLabel,
                // sort: "none",
                data: [
                  { value: 0, name: "星级站数量1", kpiCode: "C0302121" },
                  { value: 0, name: "星级站数量2", kpiCode: "A0302281" },
                  { value: 0, name: "星级站数量3", kpiCode: "A0303351" },
                  { value: 0, name: "认证二网店数", kpiCode: "C0302122" },
                  { value: 0, name: "认证二网销量", kpiCode: "A0302282" },
                  { value: 0, name: "认证二网店均销量", kpiCode: "A0303352" },
                ],
              },
            ],
          },
        },
      },
    };
  },
  created() {
    this.getDescriptionsItem();
    this.getTableData();
    this.getChartData();
  },
  methods: {
    getDescriptionsItem() {
      const rich = this.allData["Rich"];
      if (!rich) {
        return false;
      }
      this.descriptionsItem.forEach((d, i) => {
        if (!rich[d.kpiCode]) {
          d.value = 0;
        } else {
          if (i === 1 || i === 3) {
            d.value = Number(rich[d.kpiCode].Current ?? 0).toFixed(0) + rich[d.kpiCode].Unit;
          } else {
            d.value = Number(rich[d.kpiCode].Current ?? 0).toFixed(0) + rich[d.kpiCode].Unit;
          }
        }
      });
      this.descriptionsItem1.forEach((d, i) => {
        if (!rich[d.kpiCode]) {
          d.value = 0;
        } else {
          d.value = Number(rich[d.kpiCode].Current ?? 0).toFixed(0) + rich[d.kpiCode].Unit;
        }
      });
      this.descriptionsData.data = [];
      this.descriptionsData.table.forEach(e => {
        let arr = {};
        this.descriptionsColumns.forEach((d, i) => {
          arr.name = e.name;
          if (!rich[d.key]) {
            arr[d.dataIndex] = "-";
          } else {
            arr[d.dataIndex] = Number(rich[d.key][e.kpiCode] ?? 0).toFixed(0) + rich[d.key].Unit;
          }
        });
        this.descriptionsData.data.push(arr);
      });

    },
    getTableData() {
      const monthPush = this.allData["MonthPush"];
      const rich = this.allData["Rich"];

      /*tableMouthData*/
      for (let [k, v] of Object.entries(this.tableMouthData)) {
        monthGetData(this.tableMouthData[k], monthPush, this.dataParams.ReportDate, v.columnsI ?? 15);
      }
      Object.keys(this.tableData).forEach(d => {
        tableIndex(this.tableData[d], rich);
      });
    },
    getChartData() {
      const monthPush = this.allData["MonthPush"];
      const rich = this.allData["Rich"];
      const monthStandardPush = this.allData["MonthStandardPush"];
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], monthPush, monthStandardPush, this.dataParams.ReportDate);
      });
      Object.keys(this.funnelData).forEach(f => {
        this.funnelData[f].options.series[0].data.forEach(d => {
          if (rich[d.kpiCode]) {
            d.value = rich[d.kpiCode].Current ?? 0;
          }
        });
      });
    },
  },
  watch: {
    allData: {
      handler: function() {
        this.getTableData();
        this.getDescriptionsItem();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "../component/shopAnalysis.less";
</style>
