<template>
    <a-row :gutter="[0,10]">
        <!--   单车祼车毛利及终端让价  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">单车祼车毛利及终端让价
                    <a-select @change="chartData1Change('chartData1',$event)" v-model="carType.chart1" style="float: right; width: 130px;margin-right:1vw;">
                        <a-select-option v-for="(item,i) in cars" :key="i" :value="item">
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </div>
                <main-chart :chart-data="chartData.chartData1"/>
            </div>
        </a-col>
        <!-- 人员效率(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">人员效率({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_1"/>
            </div>
        </a-col>
        <!--   店均展厅顾问人数及人均销量     -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">店均展厅顾问人数及人均销量</div>
                <main-chart :chart-data="chartData.chartData2"/>
            </div>
        </a-col>
        <a-col :span="24">
            <div class="card-border">
                <profit-table :columnsData="columnsData" :tableData="tableData.table_2"/>
            </div>
        </a-col>
        <!--   店均IDCC顾问人数及人均销量     -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">店均IDCC顾问人数及人均销量</div>
                <main-chart :chart-data="chartData.chartData3"/>
            </div>
        </a-col>
        <!-- 库存周转(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">库存周转({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_3"/>
            </div>
        </a-col>
        <!--   各库存天数占比     -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">各库存天数占比
                    <a-select @change="chartData1Change('chartData4',$event)" v-model="carType.chart4" style="float: right; width: 130px;margin-right:1vw;">
                        <a-select-option v-for="(item,i) in cars" :key="i" :value="item">
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </div>
                <main-chart :chart-data="chartData.chartData4"/>
            </div>
        </a-col>
        <!--   长库存及现金车占比     -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">长库存及现金车占比
                    <a-select @change="chartData1Change('chartData5',$event)" v-model="carType.chart5" style="float: right; width: 130px;margin-right:1vw;">
                        <a-select-option v-for="(item,i) in cars" :key="i" :value="item">
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </div>
                <main-chart :chart-data="chartData.chartData5"/>
            </div>
        </a-col>
        <!--   新车库存周转天数     -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">新车库存周转天数
                    <a-select @change="chartData1Change('chartData6',$event)" v-model="carType.chart6" style="float: right; width: 130px;margin-right:1vw;">
                        <a-select-option v-for="(item,i) in cars" :key="i" :value="item">
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </div>
                <main-chart :chart-data="chartData.chartData6"/>
            </div>
        </a-col>
    </a-row>
</template>

<script>
import profitTable from "@/components/profitTable";
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import { getColumnsData, getMyChartData, getMyTableData, getChangeChartData } from "@/views/profitReport/untilProfit";
import { percentage } from "@/until/option";
import {
 legend, grid, xAxis, yAxisDefault, yAxisRight, seriesLine, seriesBar,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";

export default {
 name: "twoOperation",
 components: { profitTable, mainChart },
 props: {
  dataParams: {},
  allData: {},
 },
 data() {
  return {
   Area: this.dataParams.PartName.replace("营销中心", ""),
   cars: ["所有车型", "GS3", "GS3POWER", "GS4", "GS4PHEV", "GS4COUPE", "GS4PLUS", "GS7", "GS8", "GS8S", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
   carType: {
    chart1: "所有车型",
    chart4: "所有车型",
    chart5: "所有车型",
    chart6: "所有车型",
   },
   columnsData: getColumnsData(this.dataParams.ReportDate),
   tableData: {
    table_1: {
     data: [],
     kpiCodes: [
      {
       title: "销售顾问人数",
       unit: "个",
       code: "C0302208+C0302314+C0302315",
      },
      {
       title: "销售顾问人均销量",
       unit: "台",
       code: "A0304034",
      },
      {
       title: "销售顾问人均生产力",
       unit: "元",
       code: "A0107014",
      },
      {
       title: "展厅销售顾问人数",
       unit: "人",
       code: "C0302314",
      },
      {
       title: "展厅销售顾问人均销量",
       unit: "台",
       code: "A0303200",
      },
      {
       title: "展厅销售顾问人均生产力",
       unit: "元",
       code: "",
      },
     ],
    },
    table_2: {
     data: [],
     kpiCodes: [
      {
       title: "IDCC销售顾问人数",
       unit: "人",
       code: "C0302315",
      },
      {
       title: "IDCC销售顾问人均销量",
       unit: "台",
       code: "A0303201",
      },
      {
       title: "IDCC销售顾问人均生产力",
       unit: "元",
       code: "",
      },
     ],
    },
    table_3: {
     data: [],
     kpiCodes: [
      {
       title: "总库存",
       unit: "台",
       code: "A0402006",
      },
     ],
    },
   },
   chartData: {
    chartData1: {
     columns: ["区域", "单车祼车毛利", "终端让价"],
     defaultColumns: ["单车祼车毛利", "终端让价"],
     rows: [],
     kpiCodes: [
      { unit: "元", code: "A0106026" },
      { unit: "元", code: "A1403021" },
     ],
     selectCodes: {
      all: ["A0106026", "A1403021"],
      GS3: ["A0105051", "A1403022"],
      GS3POWER: ["A0105052", "A1403023"],
      GS4: ["A0105053", "A1403024"],
      GS4PHEV: ["A0105054", "A1403025"],
      GS4COUPE: ["A0105055", "A1403026"],
      GS4PLUS: ["A0105056", "A1403027"],
      GS7: ["A0105057", "A1403028"],
      GS8: ["A0105058", "A1403029"],
      GS8S: ["A0105059", "A1403030"],
      M6: ["A0105060", "A1403031"],
      M8: ["A0105061", "A1403032"],
      GA4: ["A0105062", "A1403033"],
      GA6: ["A0105063", "A1403034"],
      GA8: ["A0105064", "A1403035"],
      影豹: ["A0105065", "A1403036"],
      其他: ["A0105066", "A1403037"],
     },
     setting: {
      type: "histogram",
      stack: {
       val: [],
       defaultVal: [],
      },
      showLine: ["单车祼车毛利", "终端让价"],
      defaultShowLine: ["单车祼车毛利", "终端让价"],
      axisSite: { right: [], defaultRight: [] },
      yAxisType: ["0"],
      yAxisName: ["元"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}元`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesLine, seriesLine],
     },
    },
    chartData2: {
     columns: ["区域", "店均展厅销售顾问人数", "人均销量"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "C0302314" },
      { unit: "台", code: "A0303200" },
     ],
     setting: {
      type: "histogram",
      showLine: ["人均销量"],
      axisSite: { right: ["人均销量"] },
      yAxisType: ["0", "0"],
      yAxisName: ["个", "台"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData3: {
     columns: ["区域", "店均IDCC顾问人数", "人均销量"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "C0302315" },
      { unit: "台", code: "A0303201" },
     ],
     setting: {
      type: "histogram",
      showLine: ["人均销量"],
      axisSite: { right: ["人均销量"] },
      yAxisType: ["0", "0"],
      yAxisName: ["个", "台"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [
       seriesBar, seriesLine,
      ],
     },
    },
    chartData4: {
     columns: ["区域", "库存30天以下", "库存30-60天", "库存60-90天", "库存>90天"],
     defaultColumns: ["库存30天以下", "库存30-60天", "库存60-90天", "库存>90天"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "A0403024" },
      { unit: "%", code: "A0403025" },
      { unit: "%", code: "A0403026" },
      { unit: "%", code: "A0403027" },
     ],
     selectCodes: {
      all: ["A0403024", "A0403025", "A0403026", "A0403027"],
      GS3: ["A0402013/A0402012", "A0402014/A0402012", "A0402015/A0402012", "A0402016/A0402012"],
      GS3POWER: ["A0402019/A0402018", "A0402020/A0402018", "A0402021/A0402018", "A0402022/A0402018"],
      GS4: ["A0402025/A0402024", "A0402026/A0402024", "A0402027/A0402024", "A0402028/A0402024"],
      GS4PHEV: ["A0402031/A0402030", "A0402032/A0402030", "A0402033/A0402030", "A0402034/A0402030"],
      GS4COUPE: ["A0402037/A0402036", "A0402038/A0402036", "A0402039/A0402036", "A0402040/A0402036"],
      GS4PLUS: ["A0402043/A0402042", "A0402044/A0402042", "A0402045/A0402042", "A0402046/A0402042"],
      GS7: ["A0402049/A0402048", "A0402050/A0402048", "A0402051/A0402048", "A0402052/A0402048"],
      GS8: ["A0402055/A0402054", "A0402056/A0402054", "A0402057/A0402054", "A0402058/A0402054"],
      GS8S: ["A0402061/A0402060", "A0402062/A0402060", "A0402063/A0402060", "A0402064/A0402060"],
      M6: ["A0402116/A0402115", "A0402117/A0402115", "A0402118/A0402115", "A0402119/A0402115"],
      M8: ["A0402122/A0402121", "A0402123/A0402121", "A0402124/A0402121", "A0402125/A0402121"],
      GA4: ["A0402128/A0402127", "A0402129/A0402127", "A0402130/A0402127", "A0402131/A0402127"],
      GA6: ["A0402134/A0402133", "A0402135/A0402133", "A0402136/A0402133", "A0402137/A0402133"],
      GA8: ["A0402148/A0402147", "A0402149/A0402147", "A0402150/A0402147", "A0402171/A0402147"],
      影豹: ["A0402173/A0402172", "A0402174/A0402172", "A0402175/A0402172", "A0402176/A0402172"],
      其他: ["A0402179/A0402178", "A0402180/A0402178", "A0402181/A0402178", "A0402182/A0402178"],
     },
     setting: {
      type: "histogram",
      stack: {
       val: ["库存30天以下", "库存30-60天", "库存60-90天", "库存>90天"],
       defaultVal: ["库存30天以下", "库存30-60天", "库存60-90天", "库存>90天"],
      },
      ShowLine: [],
      defaultShowLine: [],
      axisSite: { right: [], defaultRight: [] },
      yAxisType: ["0.[0]"],
      yAxisName: ["%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return (params.data) + "%";
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number(d.value ?? 0))}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesBar],
     },
    },
    chartData5: {
     columns: ["区域", "库存台次", "长库存占比", "现金车占比"],
     defaultColumns: ["库存台次", "长库存占比", "现金车占比"],
     rows: [],
     kpiCodes: [
      { unit: "台", code: "A0402006" },
      { unit: "%", code: "A0404111" },
      { unit: "%", code: "A0404181" },
     ],
     selectCodes: {
      all: ["A0402006", "A0404111", "A0404181"],
      GS3: ["A0402012", "A0404112", "A0404141"],
      GS3POWER: ["A0402018", "A0404113", "A0404142"],
      GS4: ["A0402024", "A0404114", "A0404143"],
      GS4PHEV: ["A0402030", "A0404115", "A0404144"],
      GS4COUPE: ["A0402036", "A0404116", "A0404145"],
      GS4PLUS: ["A0402042", "A0404117", "A0404146"],
      GS7: ["A0402048", "A0404118", "A0404147"],
      GS8: ["A0402054", "A0404119", "A0404148"],
      GS8S: ["A0402060", "A0404120", "A0404149"],
      M6: ["A0402115", "A0404121", "A0404150"],
      M8: ["A0402121", "A0404122", "A0404151"],
      GA4: ["A0402127", "A0404123", "A0404152"],
      GA6: ["A0402133", "A0404124", "A0404153"],
      GA8: ["A0402147", "A0404125", "A0404154"],
      影豹: ["A0402172", "A0404126", "A0404155"],
      其他: ["A0402178", "A0404127", "A0404156"],
     },
     setting: {
      type: "histogram",
      stack: { val: ["库存台次"], defaultVal: ["库存台次"] },
      showLine: ["长库存占比", "现金车占比"],
      defaultShowLine: ["长库存占比", "现金车占比"],
      axisSite: { right: ["长库存占比", "现金车占比"], defaultRight: ["长库存占比", "现金车占比"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["台", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine, seriesLine],
     },
    },
    chartData6: {
     columns: ["区域", "新车库存周转天数"],
     defaultColumns: ["新车库存周转天数"],
     rows: [],
     kpiCodes: [
      { unit: "天", code: "A0404001" },
     ],
     selectCodes: {
      all: ["A0402012"],
      GS3: [""],
      GS3POWER: [""],
      GS4: [""],
      GS4PHEV: [""],
      GS4COUPE: [""],
      GS4PLUS: [""],
      GS7: [""],
      GS8: [""],
      GS8S: [""],
      M6: [""],
      M8: [""],
      GA4: [""],
      GA6: [""],
      GA8: [""],
      影豹: [""],
      其他: [""],
     },
     setting: {
      type: "histogram",
      stack: { val: [], defaultVal: [] },
      showLine: [],
      defaultShowLine: [],
      axisSite: { right: [], defaultRight: [] },
      yAxisType: ["0.[0]"],
      yAxisName: ["天"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}天`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar],
     },
    },
   },
  };
 },
 methods: {
  getArea() {
   this.Area = this.dataParams.PartName.replace("营销中心", "");
  },
  getTableData() {
   this.columnsData = getColumnsData(this.dataParams.ReportDate);
   getMyTableData(this.tableData, this.columnsData, this.allData[this.dataParams.PartId]);
  },
  getChartData() {
   getMyChartData(this.allData, this.chartData, this.dataParams.AreaList);
  },
  chartData1Change(chart, val) {
   if(val === "所有车型") val = "";
   const tableData = this.chartData[chart];
   let arr = [];
   tableData.defaultColumns.forEach(cl => {
    cl = val + cl;
    arr.push(cl);
   });
   tableData.columns = ["区域", ...arr];

   let stackArr = [];
   tableData.setting.stack.defaultVal.forEach(sl => {
    sl = val + sl;
    stackArr.push(sl);
   });
   tableData.setting.stack.val = stackArr;

   let showLineArr = [];
   tableData.setting.defaultShowLine.forEach(sl => {
    sl = val + sl;
    showLineArr.push(sl);
   });
   tableData.setting.showLine = showLineArr;

   let axisSiteArr = [];
   tableData.setting.axisSite.defaultRight.forEach(sl => {
    sl = val + sl;
    axisSiteArr.push(sl);
   });
   tableData.setting.axisSite.right = axisSiteArr;
   /*下拉切换CODE*/
   if(val === "") {
    tableData.kpiCodes.forEach((k, i) => {
     k.code = tableData.selectCodes["all"][i];
    });
   } else {
    Object.keys(tableData.selectCodes).map(car => {
     if(car === val) {
      tableData.kpiCodes.forEach((k, i) => {
       k.code = tableData.selectCodes[car][i];
      });
     }
    });
   }
   getChangeChartData(this.allData, tableData, this.dataParams.AreaList);
  },
 },
 created() {
  this.getTableData();
  this.getChartData();
  this.getArea();
 },
 watch: {
  allData: {
   handler: function() {
    this.getTableData();
    this.getChartData();
    this.getArea();
   },
   deep: true,
  },
 },
};
</script>

<style lang="less" scoped>
@import "../../profitReport/profit";
</style>
