<template>
  <section>
    <a-card class="card">
      <a-input-search class="onSearch"
                      placeholder="用户名称"
                      enter-button="查询"
                      size="large"
                      v-model.trim="keyword"
                      @search="onSearch"
      />
      <a-button style="height: 40px;line-height: 40px" @click="$router.push('/Permissions')"
      >返回权限列表
      </a-button>
    </a-card>

    <div style="height: 10px">&nbsp;</div>
    <a-card>
      <div style="line-height: 40px;text-indent: 1em;">用户列表</div>
      <a-table :rowKey="(row,index)=> index" :columns="columnss" :data-source="viewData"  :pagination="paginationParam"  @change="tableChange"></a-table>
    </a-card>
  </section>
</template>
<script>
const columnss = [
  {
    title: '名字',
    dataIndex: 'Name',
    key: 'Name',
  },
  {
    title: '区域名称',
    dataIndex: 'PartName',
    key: 'PartName',
  },
  {
    title: '地区名称',
    dataIndex: 'AreaName',
    key: 'AreaName',
  },
  {
    title: '公司名称',
    dataIndex: 'CompanyNameCN',
    key: 'CompanyNameCN',
  },
  {
    title: '职位名称',
    dataIndex: 'PositionNameCN',
    key: 'PositionNameCN',
  },
]
export default {
  data () {
    return {
      viewData: [],
      keyword: '',
      columnss,
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
    }
  },
  created () {
    this.onSearch()
  },
  methods: {
    async onSearch () {
      let formData={
        PageIndex: this.paginationParam.current,
        pageSize: this.paginationParam.pageSize,
        pCode: this.$route.query.PermissionCode,
      }
      let res = await this.$Http.ViewPermissionUsers(formData)
      if(res){
        this.viewData = res.Data
        this.paginationParam.total = res.TotalCount;
      }
    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.onSearch(pagination.current);
    },

  },
  filters: {},
}
</script>
<style lang="less" scoped>
.card {
  min-height: 100px;

  .onSearch {
    width: 400px;
  }

}
</style>
