<template>
  <section>
    <a-card class="card">
      <a-form
          :form="form"
          layout="horizontal"
          :labelCol="{ span: 6 }"
          :wrapperCol="{ span: 14 }">
        <a-row :gutter="24">
          <a-row>
            <a-col :span="6">
              <a-form-item label="时间标识">
                <a-date-picker
                    v-model="form.timeTag"
                    @panelChange="panelChange"
                    :mode="selectModel"
                    :format='formatter'
                    :allowClear="false"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="类型">
                <a-select v-model="form.type">
                  <a-select-option value="YEAR">
                    年
                  </a-select-option>
                  <a-select-option value="YEAR_MONTH">
                    月
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="备注">
                <a-input placeholder="" v-model="form.remark"/>
              </a-form-item>
            </a-col>

            <a-col :span="2">
              <a-form-item label="">
                <a-button type="primary" @click="getList(1)"> 查询</a-button>
              </a-form-item>
            </a-col>
            <a-col :span="2">
              <a-form-item label="">
                <a-button type="primary" @click="()=>{this.modelForm.Type=this.form.type;this.visible=true;}"> 新增规则</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-row>
      </a-form>
    </a-card>
    <a-card class="card" style="margin-top: 18px;">
      <a-table
          rowKey="PartId"
          :columns="columns"
          :data-source="tableData"
          :pagination="paginationParam"
          @change="tableChange"
      >
                 <span slot="Status" slot-scope="Status">
                  {{ Status === "ENABLE" ? "启用" : "禁用" }}
                </span>
        <span slot="Action" slot-scope="record">
                    <a-button size="small" type="primary" @click="doEdit(record)">编辑</a-button>
                    <a-popconfirm title="删除后此规则将失效且不可见，是否继续？" ok-text="是" cancel-text="否"
                                  @confirm="deleteRecord(record)">
                      <a-button size="small" type="primary">删除</a-button>
                    </a-popconfirm>
                </span>
      </a-table>
    </a-card>
    <div class="foot-pad30"></div>
    <a-modal
        v-model="visible"
        title="保存规则"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form-model
          :rules="rules"
          ref="modelForm"
          :model="modelForm"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 10 }"
      >
        <a-form-model-item label="类型" prop="Type">
          <a-select v-model="modelForm.Type" :disabled="true">
            <a-select-option value="YEAR">
              年
            </a-select-option>
            <a-select-option value="YEAR_MONTH">
              月
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="时间标识" prop="TimeTag">
          <a-date-picker
              v-model="modelForm.TimeTag"
              @panelChange="panelChange2"
              :mode="selectModel2"
              :format='formatter2'
              :allowClear="false"/>
        </a-form-model-item>
        <a-form-model-item label="时间段">
          <a-range-picker v-model="dateRange" @change="onchange"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="Remark">
          <a-input v-model="modelForm.Remark"/>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="Status">
          <a-select v-model="modelForm.Status">
            <a-select-option value="NONE">
              禁用
            </a-select-option>
            <a-select-option value="ENABLE">
              启用
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </section>
</template>

<script>
import moment from "moment";

const columns = [
  {
    title: "序号",
    width: "60px",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "时间标识",
    dataIndex: "TimeTag",
    key: "TimeTag",
  }, {
    title: "开始时间",
    dataIndex: "StartTime",
    key: "StartTime",
  }, {
    title: "结束时间",
    dataIndex: "EndTime",
    key: "EndTime",
  },
  {
    title: "备注",
    dataIndex: "Remark",
    key: "Remark",
  },
  {
    title: '状态',
    dataIndex: "Status",
    key: "Status",
    scopedSlots: {customRender: 'Status'},
  },
  {
    title: "操作",
    key: "Action",
    scopedSlots: {customRender: "Action"},
  },
];

export default {
  watch: {
    "form.type": function (nVal) {
      this.form.timeTag = "";
      if (nVal === "YEAR") {
        this.selectModel = 'year';
        this.formatter = "yyyy";
      } else {
        this.selectModel = 'month';
        this.formatter = "yyyy-MM";
      }
    },
    'modelForm.Type': function (nval) {
      if (nval === "YEAR") {
        this.selectModel2 = 'year';
        this.formatter2 = "yyyy";
      } else {
        this.selectModel2 = 'month';
        this.formatter2 = "yyyy-MM";
      }
      if (this.modelForm.TimeTag.length > 0) {
        this.modelForm.TimeTag = moment(this.modelForm.TimeTag).format(this.formatter2);
      }
    }
  },
  data() {
    return {
      dealers: [],
      selectModel: 'year',
      selectModel2: 'year',
      formatter: 'yyyy',
      formatter2: 'yyyy',
      dateRange: ["", ""],
      visible: false,
      form: {
        timeTag: "",
        type: "YEAR",
        //关键字
        partCode: "",
      },
      modelForm: {
        Type: '',
        TimeTag: '',
        StartTime: '',
        EndTime: '',
        Remark: '',
        Status: ''
      },
      //分页数据
      pageData: {},
      //表格数据
      tableData: [],
      columns,
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      rules: {
        Type: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["blur", "change"],
          },
        ],
        Remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: ["blur", "change"],
          },
        ],
        Status: [
          {
            required: true,
            message: "请选择状态",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      let dealers = [];
      res.Data.chain().each(p => {
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          return dealer;
        }).value();
        dealers.push(part);
      });
      this.dealers = dealers;
    },
    panelChange(value) {
      this.form.timeTag = value.format(this.formatter)
    },
    panelChange2(value) {
      this.modelForm.TimeTag = value.format(this.formatter)
    },
    doEdit(record) {
      this.visible = true;
      this.modelForm = JSON.parse(JSON.stringify(record));
      this.dateRange = [record.StartTime, record.EndTime]
    },
    async deleteRecord(record) {
      record = Object.assign({}, record);
      record.Status = "DELETE";
      let res = await this.$Http.SaveInputRule(record);
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      await this.getList();
    },
    onchange(date, dateString) {
      this.modelForm.StartTime = dateString[0]
      this.modelForm.EndTime = dateString[1]
    },
    handleCancel() {
      this.dateRange = ["", ""];
      this.modelForm.Remark = "";
      this.modelForm.TimeTag = "";
    },
    handleOk() {
      let self = this;
      if (self.modelForm.StartTime === "" || self.modelForm.EndTime === "") {
        self.$message.error("请选择开始时间和结束时间")
        return
      }
      self.$refs.modelForm.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res = await self.$Http.SaveInputRule(self.modelForm);
          if (res.Success) {
            self.$message.success("保存成功");
            self.visible = false;
            self.dateRange = ["", ""];
            this.$refs["modelForm"].resetFields();
            self.getList();
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    },
    tableChange(pagination, filters, sorter, {currentDataSource}) {
      this.paginationParam.current = pagination.current;
      this.getList(this.paginationParam.current);
    },
    //获取列表
    async getList(pageIndex) {
      let self = this;
      //将分页参数传入form对象
      self.form.pageSize = self.paginationParam.pageSize;
      self.form.pageIndex = self.paginationParam.current = pageIndex;
      let res = await self.$Http.QueryInputRule(self.form);
      self.paginationParam.total = res.TotalCount;
      self.tableData = res.Data;
    },

    //获取详情
    async getInfo(id) {
      let self = this;
      //获取当前数据
      let res = await self.$Http.GetPartInfoById({
        partId: id,
      });
      if (res.Data.length > 0) {
        self.modelForm.partName = res.Data[0].PartName;
        self.modelForm.sort = res.Data[0].Sort;
        self.modelForm.partId = res.Data[0].PartId;
      }
    },

  },
  created() {
  },
  mounted() {
    this.getList(1);
  },
};
</script>
