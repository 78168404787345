<template>
  <a-row :gutter="[0,8]">
    <a-col :span="24" v-if="isCloseA">
      <a-anchor style="min-width: 1440px" :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          选择月份：
          <a-month-picker style="margin-right: 1vw;" placeholder="请选择报告月份" @change="dateChange"
                          :disabledDate="disabledDate" v-model="ReportDateMoment" :allowClear="false"
          />

          <span v-if="hasMultipleDealer">销售店选择：</span>
          <a-cascader v-if="hasMultipleDealer" style="width: 240px" :options="dealers" :allowClear="false"
                      placeholder="请选择/输入销售店" v-model="choseDealer" @change="dealerChange"
                      :show-search="true"
          />

          <a-space size="small" class="space">
            <a-tag :color="activeKey==='1'?'#24619e':''" @click="setActionKey('1')">一、整体概览</a-tag>
            <a-tag :color="activeKey==='2'?'#24619e':''" @click="setActionKey('2')">二、销售业务</a-tag>
            <a-tag :color="activeKey==='3'?'#24619e':''" @click="setActionKey('3')">三、售后业务</a-tag>
          </a-space>
          <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
         <!-- <a-button type="primary" @click="doPrint" style="float: right;">生成PDF</a-button>-->
        </div>
      </a-anchor>
    </a-col>
    <a-col :span="24" style="min-width: 1440px">
      <a-card class="border">
        <div class="report_bg">
          广汽传祺销售店月度经营报告——业绩汇总分析
        </div>
        <a-descriptions bordered :column="5" style="padding-top: 0px">
<!--          <a-descriptions-item :label="dealerInfo.DealerName" span="5">
            <div class="float-left pai-ming"> 区域排名 <em>{{ rank }}</em></div>

            <div class="float-left zhi-bio">
              <ul>
                <li :class="grade > 80 ? 'hover' : ''">
                  <div>
                    <div><span>&nbsp;</span></div>
                    &lt;!&ndash;                  <p>&gt;80</p>&ndash;&gt;
                  </div>
                  <p>优秀</p>
                </li>
                <li :class=" grade <= 80 && grade >= 70? 'hover' : ''">
                  <div>
                    <div><span>&nbsp;</span></div>
                    &lt;!&ndash;                  <p>70-80</p>&ndash;&gt;
                  </div>
                  <p>健康</p>
                </li>
                <li :class=" grade <= 69 && grade >= 60 ? 'hover' : ''">
                  <div>
                    <div><span>&nbsp;</span></div>
                    &lt;!&ndash;                  <p>50-69</p>&ndash;&gt;
                  </div>
                  <p>成长</p>
                </li>
                <li :class="grade < 60 ? 'hover' : ''">
                  <div>
                    <div><span>&nbsp;</span></div>
                    &lt;!&ndash;                  <p> &lt;50 </p>&ndash;&gt;
                  </div>
                  <p>预警</p>
                </li>
              </ul>
            </div>
          </a-descriptions-item>-->
          <a-descriptions-item label="销售店名称">
            {{ dealerInfo.DealerName }}
          </a-descriptions-item>
          <a-descriptions-item label="区域">
            {{ dealerInfo.PartName }}
          </a-descriptions-item>
          <a-descriptions-item label="开业时间">
            {{ formatTime(dealerInfo.OpenTime, "YYYY年MM月DD日") }}
          </a-descriptions-item>
          <a-descriptions-item label="集团名称">
            {{ dealerInfo.DealerName }}
          </a-descriptions-item>
          <a-descriptions-item label="销售店代码">
            {{ dealerInfo.DealerCode }}
          </a-descriptions-item>
          <a-descriptions-item label="省份">
            {{ dealerInfo.AreaName }}
          </a-descriptions-item>
          <a-descriptions-item label="总面积">
            {{
              data.basic.C0302001.Unit === "未知" ? "未知" : data.basic.C0302001.Current + data.basic.C0302001.Unit
            }}
          </a-descriptions-item>
          <a-descriptions-item label="报告所属期">
            {{ formatTime(params.ReportDate, "YYYY年MM月") }}
          </a-descriptions-item>
          <a-descriptions-item label="城市">
            {{ dealerInfo.CityName }}
          </a-descriptions-item>
          <a-descriptions-item label="总人数">
            {{
              data.basic.C0302191.Unit === "未知" ? "未知" : data.basic.C0302191.Current + data.basic.C0302191.Unit
            }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-col>
    <div class="info">
      <p>
        单店报告从销售店整体运营及风险情况、销售业务、售后业务三大板块关键指标综合分析销售店的经营质量。
      </p>
      <p>
        Ø概览：涵盖盈利平衡、盈利能力、业务结构占比、主营业务抗风险能力、财务风险等维度指标；
      </p>
      <p>
        Ø销售：涵盖体量、盈利、三大业务毛利贡献、渠道占比、销售运营效率、库存营运效率、销售增值业务渗透率等维度指标；
      </p>
      <p>
        Ø售后：涵盖进厂台次、盈利、业务结构占比、客户维系、售后营运效率、零部件营运效率、售后增值业务渗透率等维度指标（36项）。
      </p>
    </div>
    <div style="margin-top: 8px;">
      <div key="1">
        <overallOverview v-if="activeKey==='1'" :report-date="params.ReportDate" :report-params="params"></overallOverview>
      </div>
      <div key="2">
        <salesComponent v-if="activeKey==='2'" :report-params="params" :area-data="areaData"></salesComponent>
      </div>
      <div key="3">
        <afterSalesComponent v-if="activeKey==='3'" :report-params="params" :area-data="areaData"></afterSalesComponent>
      </div>
    </div>
    <!--    <a-back-top>
          <div class="back-top">
            <a-icon class="back-top-icon" type="vertical-align-top"/>
          </div>
        </a-back-top>-->
  </a-row>
</template>

<script>

import overallOverview from "./childComponent/overallOverview";
import chartComponent from "../../../components/chartComponent";
import moment from "moment";
import { getEmptyTemplate, loadReportData } from "@/until/reportCalculate";
import salesComponent from "@/views/monthReport/analysisReport/childComponent/salesComponent";
import afterSalesComponent from "@/views/monthReport/analysisReport/childComponent/afterSalesComponent";
import { disabledDate } from "@/until/dayFormat";

export default {
  name: "StoreManagement",
  components: {
    overallOverview, chartComponent, salesComponent, afterSalesComponent,
  },
  data () {
    return {
      activeKey: "1",
      isCloseA: true,
      params: {
        ReportDate: "",
        DealerCode: "",
      },
      dealerInfo: {},
      rank: 0,
      grade: 0,
      data: {
        basic: {
          "C0302001": getEmptyTemplate(),
          "C0302191": getEmptyTemplate(),
        },
      },
      hasMultipleDealer: false,
      dealers: [],
      dealerMap: {},
      choseDealer: [],
    };
  },
  computed: {
    ReportDateMoment: {
      get () {
        return moment(this.params.ReportDate);
      },
      set (newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
  async created () {
    this.getParams();
    await this.getDealersByRole();
    await this.getDealerInfo();
  },
  methods: {
    disabledDate,
    async getDealersByRole () {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      let dealerCount = 0;
      this.dealers = res.Data.chain().map(p => {
        dealerCount += p.Dealers.length;
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          this.dealerMap[dealer.value] = [part.value, dealer.value];
          return dealer;
        }).value();
        return part;
      }).value();
      this.hasMultipleDealer = dealerCount > 1;
    },
    async dealerChange (value) {
      this.params.DealerCode = value[1];
      await this.getDealerInfo();
    },
    // 获取当前选择的经销商信息
    async getDealerInfo () {
      let res = await this.$Http.getCurrDealerInfo({
        DealerCode: this.params.DealerCode,
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
     // this.choseDealer = this.dealerMap[this.params.DealerCode];
      this.choseDealer = this.dealerMap[res.Data.DealerCode];
      if (this.choseDealer == null) {
        this.params.DealerCode = this.dealerMap[Object.keys(this.dealerMap)[0]][1];
        if (this.params.DealerCode != null) {
          await this.getDealerInfo();
        }
        return;
      }

      this.dealerInfo = res.Data;
      this.params.DealerCode = res.Data.DealerCode;
      await this.getData();
    },
    // 获取当月数据
    async getData () {
      await loadReportData(this, this.data, null, this.params);
    },
    //获取参数
    getParams () {
      //js判断是否为空对象
      if (Object.keys(this.$route.query).length > 0) {
        this.params = this.$route.query;
      } else {
        //若没有参数（从菜单点击），则自动获取上个月报告
        this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
      }
    },
    // 日期框点击方法
    dateChange (date, dateString) {
      this.params.ReportDate = dateString + "-01";
      //日期修改后同步数据给子组件
      this.getDealerInfo();
      this.getData();
    },
    // 点击打印双预览
    async doPrint () {
      let res = await this.$Http.PdfGenerateByUrl({
        Url: `${window.location.origin}/monthReport/print2?ReportDate=${this.params.ReportDate}&DealerCode=${this.params.DealerCode}&DealerName=${this.dealerInfo.DealerName}`,
        FileName: `${this.ReportDateMoment.format("YYYY年MM月")}${this.dealerInfo.DealerName}经营分析报告`,
        PageWidth: 16.54,
        PageHeight: 11.7,
        Cookie: document.cookie,
      });
      if (res.isError) {
        this.$message.error(res.errMsg);
        return;
      }
      let a = document.createElement("a");
      a.href = res.data.pdfUrl;
      a.target = "_blank";
      a.click();
    },
    formatTime (time, format) {
      return moment(time).format(format);
    },
    setActionKey (key) {
      this.activeKey = key;
    },
  },
};
</script>

<style lang="less" scoped>
.fix_border {
  padding: 20px 10px 20px 30px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  margin-left: -2px;
  }

.space {
  margin-left: 30px;

  .ant-tag {
    cursor: pointer;
    padding: 4px 13px;
    }
  }

.border {
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  }

.report_bg {
  //  background: url("../../../assets/report_bg.png") top center no-repeat;
  height: 136px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 136px;
  }

.info {
  clear: both;
  margin: 12px auto;
  background: #E6F7FF;
  padding: 24px 22px;
  border: 1px solid #BAE7FF;
  }

.pai-ming {
  padding-top: 20px;
  margin-right: 40px;
  color: #24619e;
  font-size: 30px;

  em {
    font-size: 50px;
    }

  }

.zhi-bio {
  ul {
    li {
      float: left;
      text-align: center;

      > div {
        border: 1px solid #dddddd;
        height: 88px;
        padding: 14px;
        background: rgba(242, 245, 248, 0.6);

        div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 4px solid rgba(67, 167, 16, 0.3);

          span {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #43A710;
            }
          }
        }

      > p {
        height: 25px;
        line-height: 25px;
        background: #e8e8e8;
        border: 1px solid #dddddd;
        }

      &:nth-child(2) {
        > div {
          div {
            border: 4px solid rgba(88, 163, 247, 0.3);

            span {
              background: #58A3F7;
              }
            }
          }
        }

      &:nth-child(3) {
        > div {
          div {
            border: 4px solid rgba(255, 197, 66, 0.3);

            span {
              background: #FFC542;
              }
            }
          }
        }

      &:nth-child(4) {
        > div {
          div {
            border: 4px solid rgba(253, 89, 90, 0.3);

            span {
              background: #FD595A;
              }
            }
          }
        }

      /*选中状态*/

      &:nth-child(1).hover {
        > div {
          border: 1px solid #43A710;
          }

        > p {
          background: #43A710;
          color: #ffffff;
          border: 1px solid #43A710;
          }
        }

      &:nth-child(2).hover {
        > div {
          border: 1px solid #58A3F7;
          }

        > p {
          background: #58A3F7;
          color: #ffffff;
          border: 1px solid #58A3F7;
          }
        }

      &:nth-child(3).hover {
        > div {
          border: 1px solid #FFC542;
          }

        > p {
          background: #FFC542;
          color: #ffffff;
          border: 1px solid #FFC542;
          }
        }

      &:nth-child(4).hover {
        > div {
          border: 1px solid #FD595A;
          }

        > p {
          background: #FD595A;
          color: #ffffff;
          border: 1px solid #FD595A;
          }
        }
      }
    }
  }

::v-deep {
  .ant-card-body {
    padding: 5px;
    }

  .ant-tabs-nav-scroll {
    background: #ffffff;
    padding-bottom: 5px;
    }

  .ant-tabs-bar {
    margin-bottom: 1px !important;
    }

  .ant-card-bordered {
    border-top: 0;
    }

  .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #b9d0d0 !important;
    }

  .ant-descriptions-bordered .ant-descriptions-view {
    border-top: 1px solid #b9d0d0 !important;
    border-left: 1px solid #b9d0d0 !important;
    }

  .ant-descriptions-bordered .ant-descriptions-item-label {
    background: #E6ECEC !important;
    }

  .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: 1px solid #b9d0d0 !important;
    }
  }
</style>
