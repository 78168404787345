<template>
  <section>
    <a-card>
      <a-button type="primary" @click="loadTypeName(null)" style="margin-bottom: 10px">
        <a-icon type="file-add"/>
        添加类别
      </a-button>
      <!--表格-->
      <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="ID">
    <span slot="action" slot-scope="text, record">
      <a-switch checked-children="启用" un-checked-children="禁用" :checked="record.IsDelete===0"
                @change="statusChange(record)"
                style="margin-right: 10px"
      />
      <a-button type="primary" @click="loadTypeName(record)">
        修改
      </a-button>
    </span>
      </a-table>
      <!--分页-->
      <a-pagination class="page"
                    size="small" v-model="pageIndex" :total="page.totalRow" :pageSize="pageSize"
                    :show-total="(total, range) => `${range[0]} 到 ${range[1]} (共 ${total} 条)`"
                    @change="getTypeNameInfo" @showSizeChange="getTypeNameInfo"
                    show-size-changer show-quick-jumper
      />
      <!--信息窗口-->
      <a-modal v-model="modifyForm.visible" :title="modifyForm.title" okText="保存" @ok="saveTypeName">
        <a-form-model ref="ruleForm" :model="modifyForm.data" :label-col="{span:6}" :wrapper-col="{span:12}"
                      :rules="modifyForm.rules"
        >
          <a-form-model-item label="名称" prop="Name">
            <a-input v-model="modifyForm.data.Name" placeholder="请输入类别名称"/>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="Sort">
            <a-input-number v-model="modifyForm.data.Sort" placeholder="请输入序号(1~9999)"
                            :min="1" :max="9999" :precision="0"
            />
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-input v-model="modifyForm.data.Comment" placeholder="请输入备注"/>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width:60,
          key: "index",
          customRender: (text, record, index) => ++index,
        },
        {
          title: "类别分类",
          dataIndex: "TypeClassName",
          key: "type",
        },
        {
          title: "名称",
          dataIndex: "Name",
          key: "name",
        },
        {
          title: "排序",
          dataIndex: "Sort",
          key: "sort",
        },
        {
          title: "备注",
          dataIndex: "Comment",
          key: "memo",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      data: [],
      page: {},
      pageIndex: 1,
      pageSize: 10,
      modifyForm: {
        visible: false,
        title: "类别",
        rules: {
          Name: [
            { required: true, message: "请输入类别名称", trigger: "blur", whitespace: true },
            { min: 1, max: 20, message: "类别名称长度应该在 1 到 20 字之间", trigger: ["blur", "change"] },
          ],
          Sort: [
            { required: true, message: "请输入序号(1~9999)", trigger: "blur" },
            { type: "integer", min: 1, max: 9999, message: "排序值该在 1 到 9999 之间", trigger: ["blur", "change"] },
          ],
        },
        data: {},
      },
    };
  },
  created () {
    this.getTypeNameInfo();
  },
  methods: {
    async getTypeNameInfo (pageIndex, pageSize) {
      if (pageIndex != null && pageSize != null) {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
      }
      let res = await this.$Http.commonApi({
        API: "TypeNameListGet_robin",
        pagesize: this.pageSize,
        page: this.pageIndex,
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.data = res.ApiParamObj[0];
      this.page = res.ApiParamObj[1][0];
    },
    async statusChange (record) {
      let isDelete = record.IsDelete === 0 ? 1 : 0;
      let res = await this.$Http.commonApi({
        API: "TypeNameWH_robin",
        BusFlg: isDelete,
        TypeID: record.ID,
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      record.IsDelete = isDelete;
    },
    loadTypeName (record) {
      if (this.$refs.ruleForm !== undefined)
        this.$refs.ruleForm.resetFields();

      if (record == null) {
        let sort = this.page.totalRow > 0 ? (this.page.totalRow + 1) : 1;
        record = { ID: 0, Sort: sort, Name: "", Comment: "" };
        this.modifyForm.title = "添加类别";
      } else {
        record = Object.assign({}, record);
        this.modifyForm.title = "修改类别";
      }
      this.modifyForm.visible = true;
      this.modifyForm.data = record;
    },
    async saveTypeName () {
      let valid = await this.$refs.ruleForm.validate();
      if (!valid)
        return;

      let record = this.modifyForm.data;
      let params = {
        TypeClass: 1,
        TypeName: record.Name.trim(),
        Sort: record.Sort,
        Comment: record.Comment.trim(),
      };
      if (record.ID > 0) {
        // 更新
        params.API = "TypeNameUpdate_robin";
        params.TypeID = record.ID;
      } else {
        // 添加
        params.API = "typeNameAdd_robin";
      }

      let res = await this.$Http.commonApi(params);
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }

      await this.getTypeNameInfo();
      this.modifyForm.visible = false;
    },
  },
};
</script>
