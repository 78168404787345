<template>
  <section>
    <a-row :gutter="[24,24]">
      <a-col :span="24">
        <a-card style="padding-top: 12px">
          <a-descriptions bordered :column="3" style="margin-bottom: 20px;">
            <a-descriptions-item label="店均售后来厂台次">{{ GetIndexValue("店均售后来厂台次","取整") }}</a-descriptions-item>
            <a-descriptions-item label="店均平均单车售后综合收入">{{ GetIndexValue("店均平均单车售后综合收入", "元") }}</a-descriptions-item>
            <a-descriptions-item label="零服吸收率大于100%店数">{{ GetIndexValue("零服吸收率大于100%店数") }}</a-descriptions-item>
          </a-descriptions>
          <div class="info">
            <p>
              1、售后来厂及保有客户维系：店均有效保有客户数
              <span v-html='GetDesc(data,"B0302037")'></span>
              ，店均回厂台次总量
              <span v-html='GetDesc(data,"B0302001")'></span>
              ，较上月
              <span v-html='GetDesc(data,"B0302001", ["上升", "下降"], true)'></span>
              ；AB类客户占比
              <span v-html='GetDesc(data,"B0303051")'></span>
              。
            </p>
            <p>
              2、店均售后维修业务盈利能力：单车售后维修业务产值
              <span v-html='GetDesc(data,"B0104226")'></span>
              ，其中单车零部件毛利
              <span v-html='GetDesc(data,"B0105131")'></span>
              ，单车工时毛利
              <span v-html='GetDesc(data,"B0105161")'></span>
              ，售后维修业务毛利率
              <span v-html='GetDesc(data,"B0105086", ["上升至", "下降至"])'></span>
              。
            </p>
            <p>
              3、店均售后水平事业：售后水平事业毛利为
              <span v-html='GetDesc(data,"B0104187")'></span>
              ；售后水平事业毛利率
              <span v-html='GetDesc(data,"B0105108")'></span>
              ，较上月
              <span v-html='GetDesc(data,"B0105108", ["增长", "减少"], false,true)'></span>
              。
            </p>
            <p>
              4、店均零服吸收率：零服吸收率大于100%的店数
              <span style="font-weight: bold">{{ GetIndexValue("零服吸收率大于100%店数") }}</span>
              ，零服吸收率为
              <span v-html='GetDesc(data,"B0107002")'></span>
              ，较上月
              <span v-html='GetDesc(data,"B0107002", ["增长", "减少"], false,true)'></span>
              。
            </p>
          </div>
        </a-card>
      </a-col>
      <a-col :span="24">
        <div id="one_3"></div>
        <a-card title="1、售后来厂及保有客户维系">
          <a-col :span="isWin">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_3_1_1"
            >
            </monthReportTableComponent>
          </a-col>
          <a-col :span="isWin" class="ppTop30">
            <chartComponent :chart-data="data.chartData_3_1_1"/>
          </a-col>
          <a-col :span="24">
            <chartComponent :chart-data="data.chartData_3_1_2"/>
          </a-col>
        </a-card>
      </a-col>
      <a-col :span="24">
        <div id="two_3"></div>
        <a-card title="2、店均售后维修业务盈利能力">
          <a-col :span="isWin">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_3_2_1"
            >
            </monthReportTableComponent>
          </a-col>
          <a-col :span="isWin" class="ppTop30">
            <chartComponent :chart-data="data.chartData_3_2_1"/>
          </a-col>
          <a-col :span="isWin">
            <chartComponent :chart-data="data.chartData_3_2_3"/>
          </a-col>
          <a-col :span="isWin" style="clear: both;margin-top: 30px">
            <chartComponent :chart-data="data.chartData_3_2_2"/>
          </a-col>
        </a-card>
      </a-col>
      <a-col :span="24">
        <div id="there_3"></div>
        <a-card title="3、店均售后水平事业">
          <a-col :span="24" style="clear: both;margin-top: 30px">
            <chartComponent :chart-data="data.chartData_3_3_1"/>
          </a-col>
        </a-card>
      </a-col>
      <a-col :span="24">
        <div id="four_3"></div>
        <a-card title="4、店均零服吸收率">
          <a-col :span="24" style="clear: both;margin-top: 30px">
            <chartComponent :chart-data="data.specialChartData_3_4_1"/>
          </a-col>
        </a-card>
      </a-col>
    </a-row>
    <a-anchor v-if="reportParams.isClose">
      <div class="close" @click="closeAnchor">
        <a-icon type="close"/>
        关闭
      </div>
      <a-anchor-link href="#one_3" title="1、售后来厂及保有客户维系"/>
      <a-anchor-link href="#two_3" title="2、店均售后维修业务盈利能力"/>
      <a-anchor-link href="#there_3" title="3、店均售后水平事业"/>
      <a-anchor-link href="#four_3" title="4、店均零服吸收率"/>
    </a-anchor>
  </section>
</template>

<script>
import monthReportTableComponent from "@/components/monthReportTableComponent";
import chartComponent from "@/components/chartComponent";
import {GetDesc, getEmptyTemplate, numFormat} from "@/until/reportCalculate";
import {columnsImport, regularizeData} from "../data.js";
import {GetWindows} from "@/until/dayFormat";

export default {
  props: {
    reportParams: {isClose:true,},
    reportDate: String,
    areaData: {},
    isRegionReport: Boolean, // 是否地区报告,true就是地区
  },
  data() {
    return {
      isClose: true,
      isWin: 24,
      emptyTemplate: getEmptyTemplate(),
      columnsData: columnsImport.columnsImportDataPC,
      data: regularizeData,
    };
  },
  created() {
    if (this.isRegionReport) {
      this.columnsData = columnsImport.columnsImportDataPcRegionReport;
    }
    this.isWin = GetWindows();
    columnsImport.loadData(this, this.data, this.reportParams);
  },
  methods: {
    closeAnchor() {
      // eslint-disable-next-line vue/no-mutating-props
      this.reportParams.isClose = false;
    },
    GetIndexValue(key, unit) {
      let value = 0;
      if (this.areaData && this.areaData.IndexValues) {
        value = this.areaData.IndexValues[key];
      }
      return numFormat(key, value, unit);
    },
    GetDesc,
  },
  components: {
    monthReportTableComponent, chartComponent,
  },
  watch: {
    reportParams: {
      handler: function () {
        columnsImport.loadData(this, this.data, this.reportParams);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";
</style>
