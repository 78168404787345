const INCOMEANALYSIS_API = {
    // 盈利分析获取年度数据
    QueryAnnual: {
        method: 'post',
        url: '/XProfitAnalyze/QueryAnnual'
    },

    // 盈利分析保存年度数据
    SaveAnnual: {
        method: 'post',
        url: '/XProfitAnalyze/SaveAnnual'
    },
    //获取盈利分析 - 月度
    QueryMonthly: {
        method: 'post',
        url: '/XProfitAnalyze/QueryMonthly'
    },
    //保存盈利分析 - 月度
    SaveMonthly: {
        method: 'post',
        url: '/XProfitAnalyze/SaveMonthly'
    },
    //车型分析 - 月度推移
    MonthPush: {
        method: 'post',
        url: '/XVehicleTypeAnalyze/MonthPush'
    },
    //车型分析 - 年推移
    YearPush: {
        method: 'post',
        url: '/XVehicleTypeAnalyze/YearPush'
    },
    //车型分析 - 日推移
    DayPush: {
        method: 'post',
        url: '/XVehicleTypeAnalyze/DayPush'
    },
    //获取日指标
    DayKpiValue: {
        method: 'post',
        url: '/XVehicleTypeAnalyze/DayKpiValue'
    },
    //获取盈利分析填写规则
    QueryInputRule: {
        method: 'post',
        url: '/XProfitAnalyze/QueryInputRule'
    },
    //保存盈利分析填写规则
    SaveInputRule: {
        method: 'post',
        url: '/XProfitAnalyze/SaveInputRule'
    },
    //重置盈利分析填写状态
    ResetStatus: {
        method: 'post',
        url: '/XProfitAnalyze/ResetStatus'
    },

    // 获取盈利分析填报情况
    Statistics: {
        method: 'post',
        url: '/XProfitAnalyze/Statistics'
    },

    //获取盈利分析填报情况明细
    StatisticsDetail: {
        method: 'post',
        url: '/XProfitAnalyze/StatisticsDetail'
    },

    //下载盈利分析填报情况明细
    StatisticsDetailDownload: {
        method: 'get',
        url: '/XProfitAnalyze/StatisticsDetailDownload'
    },

    // 下载盈利分析填报情况明细
    ExportDetails: {
        method: 'post',
        url: '/XProfitAnalyze/ExportDetails',
        responseType: "blob",
    }
}
export default INCOMEANALYSIS_API
