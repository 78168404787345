<template>
  <ve-chart :data="chartData" :height="chartData.chartHeight?chartData.chartHeight:'400px'"
            :colors="chartColors" :settings="chartData.setting" :init-options="{renderer: 'svg'}" :after-set-option="afterSetOption" :extend="chartData.extends"
  ></ve-chart>
</template>

<script>
export default {
  name: "mainChart",
  props: {
    chartData: {},
  },
  data() {
    return {
      chartColors: ["#2B821D", "#FFC542", "#856AFF", "#5ADCA6", "#FF4040", "#ED729C", "#BD94F3", "#6E6FE4", "#6C9AE4",
          "#85E0E5","#3AC9CD", "#839BEC", "#6CB487", "#E9AA70","#EE1289","#C1FFC1"],
    };
  },
  methods: {
    afterSetOption(chartObj) {
      if (this.chartData.options != null) {
        chartObj.setOption(this.chartData.options);
      }
    },
  },
};
</script>

<style scoped>

</style>
