import { simpleFixed } from "@/until/simpleFixed.ts";

const axisLine = { show: true };
const colors = [];
const barMaxWidth = 50;
const xAxis = [{
 axisLabel: {
  textStyle: {
   fontSize: 14, color: "black",
  },
  interval: 0,
 }, axisLine,
 // X轴刻度
 /* axisTick: {
     show:true
 }*/
}];

/*一个柱图bar万元,一个LINE百分比*/
function myOptionTwo(fontSize) {
 let textStyle = {
  fontSize, color: "black",
 };
 return {
  legend: {
   textStyle,
  },
  tooltip: {
   show: true,
   trigger: "axis",
   axisPointer: {            // 坐标轴指示器，坐标轴触发有效
    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
   },
   formatter: function(params) {
    if(params[0] && params[1]) {
     if(params[0].value === undefined || params[1].value === undefined) {
      return `${params[0]["axisValue"]} <br /> ${params[0]["seriesName"]}: - <br /> ${params[1]["seriesName"]}: -`;
     }
     let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params[0]["seriesName"] + ": " + (params[0].value / 10000).toFixed(2) + "万元",
      val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params[1]["seriesName"] + "： " + Number(params[1].value * 100).toFixed(1) + "%";
     return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
    } else {
     return "";
    }
   },
  },
  xAxis,
  yAxis: [
   {
    name: "万元",
    nameTextStyle: {
     fontSize,
    },
    type: "value",
    axisLine: {
     //y轴
     show: true,

    },
    axisTick: {
     show: false,
    },
    splitLine: {
     show: false,
    },
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return `${val / 10000}`;
     },
    },
   }, {
    axisLabel: {
     textStyle,
     formatter: (val => {
      return percentage(val);
     }),
    },
   },
  ],
  series: [
   {
    barMaxWidth,
    itemStyle: {
     normal: {
      color: (params) => {
       return colors[params.dataIndex];
      },
     },
    },
    label: {
     normal: {
      show: true,
      //   position:'insideRight',
      textStyle,
      formatter: function(params) {
       return (params.value / 10000).toFixed(2);
      },
     },

    },
   }, {
    label: {
     normal: {
      show: true,
      //   position:'insideRight',
      textStyle,
      formatter: function(params) {
       return (params.value * 100).toFixed(1) + "%";
      },
     },
    },
   },
  ],
 };
}

/*一个柱图自传单位,一个LINE百分比*/
function myOptionOne(fontSize, unit) {
 let textStyle = {
  fontSize, color: "black",
 };
 return {
  legend: {
   textStyle,
  },
  tooltip: {
   show: true,
   trigger: "axis",
   axisPointer: {            // 坐标轴指示器，坐标轴触发有效
    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
   },
   formatter: function(params) {
    if(params[0] && params[1]) {

     if(params[0].value === undefined || params[1].value === undefined) {
      return `${params[0]["axisValue"]} <br /> ${params[0]["seriesName"]}: - <br /> ${params[1]["seriesName"]}: -`;
     }
     let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params[0].seriesName + ": " + (params[0].value).toFixed(0),
      val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params[1].seriesName + "： " + Number(params[1].value * 100).toFixed(1) + "%";
     return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
    } else {
     return "";
    }
   },
  },
  xAxis,
  yAxis: [
   {
    name: unit,
    type: "value",
    axisLine: {
     //y轴
     show: true,

    },
    axisTick: {
     show: false,
    },
    splitLine: {
     show: false,
    },
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return `${val}`;
     },
    },
   }, {
    axisLabel: {
     textStyle,
     formatter: (val => {
      return percentage(val);
     }),
    },
   },
  ],
  series: [
   {
    barMaxWidth,
    itemStyle: {
     normal: {
      color: (params) => {
       return colors[params.dataIndex];
      },
     },
    },
    label: {
     normal: {
      show: true,
      //   position:'insideRight',
      textStyle,
      formatter: function(params) {
       return (params.value).toFixed(0);
      },
     },

    },
   }, {
    label: {
     normal: {
      show: true,
      //   position:'insideRight',
      textStyle,
      formatter: function(params) {
       return (params.value * 100).toFixed(1) + "%";
      },
     },
    },
   },
  ],
 };
}

/*堆积图黑色 格式化成万元*/
function myOptionStack(fontSize) {
 let textStyle = {
  fontSize, color: "black",
 };
 return {
  legend: {
   textStyle,
  },
  tooltip: {
   show: true,
   trigger: "axis",
   formatter: function(params) {

    let arr = [];
    params.forEach((d) => {
     let minvalue = 0;
     if(d.value === undefined) {
      minvalue = "-";
     } else {
      minvalue = Number(d.value / 10000).toFixed(2);
     }
     arr.push({
      val: `<span style="background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;"></span>${d.seriesName}:${minvalue}万元`,
     });
    });

    return `${params[0].axisValue} <br /> ${arr[2].val} <br />${arr[1].val}<br />${arr[0].val}`;
   },
  },
  xAxis,
  yAxis: [
   {
    name: "万元",
    nameTextStyle: {
     fontSize,
    },
    type: "value",
    axisLine: {
     //y轴
     show: true,

    },
    axisTick: {
     show: false,
    },
    splitLine: {
     show: false,
    },
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return `${val / 10000}`;
     },
    },
   },
  ],
  series: [
   {
    barMaxWidth,
    label: {
     normal: {
      show: true,
      textStyle,
      formatter: function(params) {
       return (params.value / 10000).toFixed(2);
      },
     },
    },
   }, {
    label: {
     normal: {
      show: true,
      textStyle,
      formatter: function(params) {
       return (params.value / 10000).toFixed(2);
      },
     },
    },
   }, {
    label: {
     normal: {
      show: true,
      textStyle,
      formatter: function(params) {
       return (params.value / 10000).toFixed(2);
      },
     },
    },
   },
  ],
 };
}

/*不格式化,只改黑色 传几就几个
* */
function myOptionBlack(val, fontSize) {
 let ser = [];
 for (let i = 0; i < Number(val); i++) {
  ser.push({

   label: {
    normal: {
     show: true,
     //   position:'insideRight',
     textStyle: {
      fontSize,
      color: "black",
     },
    },
   },
  });
 }
 return {
  legend: {
   textStyle: {
    fontSize,
   },
  },

  series: ser,
 };
}

/*第一个柱图变色不格式化,后边LINE 只变色*/
function myOptionColor(fontSize, unit) {
 let textStyle = {
  fontSize, color: "black",
 };
 return {
  legend: {
   textStyle,
  },
  xAxis,
  yAxis: [
   {
    name: unit,
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return percentage(val);
     },
    },
   }, {
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return percentage(val);
     },
    },
   },
  ],
  series: [
   {
    barMaxWidth,
    itemStyle: {
     normal: {
      color: (params) => {
       return colors[params.dataIndex];
      },
     },
    },
    label: {
     normal: {
      show: true,
      textStyle,
     },
    },
   }, {
    label: {
     normal: {
      show: true,
      textStyle,
     },
    },
   },
  ],
 };
}

function myOptionColor2(fontSize, unit) {
 let textStyle = {
  fontSize, color: "black",
 };
 return {
  legend: {
   textStyle,
  },
  tooltip: {
   show: true,
   trigger: "axis",
   axisPointer: {            // 坐标轴指示器，坐标轴触发有效
    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
   },
   formatter: function(params) {
    let arr = {};
    params.forEach((d, i) => {
     if(d.componentSubType === "bar") {
      arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}${unit}`;
     }
     if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
     }
    });
    let re = params[0].name + "<br />";
    Object.values(arr).forEach(a => {
     re += a + "<br />";
    });
    return re;
   },
  },
  xAxis,
  yAxis: [
   {
    name: unit,
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return ThousandYuan(val);
     },
    },
   }, {
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return percentage(val);
     },
    },
   },
  ],
  series: [
   {
    barMaxWidth,
    itemStyle: {
     normal: {
      color: (params) => {
       return colors[params.dataIndex];
      },
     },
    },
    label: {
     normal: {
      show: true,
      textStyle,
     },
    },
   }, {
    label: {
     normal: {
      show: true,
      //   position:'insideRight',
      textStyle,
      formatter: function(params) {
       return (params.value * 100).toFixed(1) + "%";
      },
     },
    },
   },
  ],
 };
}

/*全是百分比*/
function myOptionPercentage(fontSize) {
 let textStyle = {
  fontSize, color: "black",
 };
 return {
  legend: {
   textStyle,
  },
  tooltip: {
   show: true,
   trigger: "axis",
   axisPointer: {            // 坐标轴指示器，坐标轴触发有效
    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
   },
   formatter: function(params) {
    let arr = {};
    params.forEach((d, i) => {
     if(d.componentSubType === "bar") {
      arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}`;
     }
     if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
     }
    });
    let re = params[0].name + "<br />";
    Object.values(arr).forEach(a => {
     re += a + "<br />";
    });
    return re;
   },
  },
  xAxis,
  yAxis: [
   {
    axisLabel: {
     textStyle,
     formatter: (val) => {
      return percentage(val);
     },
    },
   },
  ],
  series: [
   {
    label: {
     normal: {
      show: true,
      textStyle,
     },
    },
   }, {
    label: {
     normal: {
      show: true,
      textStyle,
     },
    },
   },
  ],
 };
}

/*调整图例的字体*/
function myOptionFont(fontSize, unit) {
 let axisLabel = {
  textStyle: {
   fontSize, color: "black",
  },
 };
 return {
  legend: {
   textStyle: {
    fontSize,
   },
  },

  xAxis,
  yAxis: [
   {
    name: unit,
    axisLabel: {
     textStyle: {
      fontSize, color: "black",
     },
     formatter: (val) => {
      return ThousandYuan(val);
     },
    },
   }, {
    axisLabel: {
     textStyle: {
      fontSize, color: "black",
     },
     formatter: (val) => {
      return percentage(val);
     },
    },
   },
  ],
  series: [
   {
    barMaxWidth,
   },
  ],
 };
}

/*调整图例的字体*/
function myOptionFontThousandYuan(fontSize) {
 let axisLabel = {
  textStyle: {
   fontSize, color: "black",
  },
 };
 return {
  legend: {
   textStyle: {
    fontSize,
   },
  },

  xAxis,
  yAxis: [
   {
    axisLabel: {
     textStyle: {
      fontSize, color: "black",
     },
     formatter: (val) => {
      return ThousandYuan(val);
     },
    },
   }, {
    axisLabel: {
     textStyle: {
      fontSize, color: "black",
     },
     formatter: (val) => {
      return percentage(val);
     },
    },
   },
  ],
 };
}

/*百分比*/
function percentage(val) {
 return simpleFixed(Number(val) * 100, 1) + "%";
 // return Number(Number(Number(val) * 100).toFixed(1)) + "%";
 // return fixed(fixed(val * 100, 2),1)+ "%";
}

/*无单位百分比*/
function percentageNo(val) {
 return simpleFixed(Number(val) * 100, 1);
 // return fixed(val * 100, 1);
}

/*万元*/
function tenThousandYuan(val) {
 return simpleFixed(Number(val) / 10000, 2);
 // return (val / 10000).toFixed(2);
}

/*Y轴万元*/
function YtenThousandYuan(val) {
 return `${Number(val ?? 0) / 10000}`;
}

/*元*/
function ThousandYuan(val) {
 return simpleFixed(val ?? 0, 0);
 // return fixed(val, 0);
}

function UnitProcessing(unit, val) {
 switch (unit) {
 case "万元":
  return tenThousandYuan(val);
 case "%":
  return percentage(val);
 default:
  return ThousandYuan(val);
 }
}

function UnitChartProcessing(unit, val) {
 switch (unit) {
 case "万元":
  if (val === 0) return null;
  return tenThousandYuan(val);
 case "%":
  if (val === 0) return null;
  return percentageNo(val);
 default:
  if (val === 0) return null;
  return ThousandYuan(val);
 }
}

export {
 xAxis,
 myOptionOne,
 myOptionTwo,
 myOptionStack,
 myOptionBlack,
 myOptionColor,
 myOptionColor2,
 myOptionFont,
 percentage,
 tenThousandYuan,
 YtenThousandYuan,
 ThousandYuan,
 myOptionFontThousandYuan,
 myOptionPercentage,
 UnitProcessing,
 UnitChartProcessing,
};

