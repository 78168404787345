const QUJIAN_API = {
    // 填报区间列表
    GetDuringListPage: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetDuringListPage'
    },
    // 新增区间
    InsertDuring: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/InsertDuring'
    },
    //区间修改
    UpdateDuring: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/UpdateDuring'
    },
    //删除区间
    DeleteDuring: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/DeleteDuring'
    },
    //启用禁用区间
    UpdateDuringStatus: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/UpdateDuringStatus'
    },
    //重新上传
    ResetUpload: {
        method: "get",
        url: '/DealerKpi/KpiData/ResetUpload'
    },
    // 厂端填报管理 - 导出驳回原因
  ExportRejectDetail: {
        method: "get",
        responseType: "blob",
        url: '/DealerKpi/KpiData/ExportRejectDetail'
    }


}
export default QUJIAN_API
