<template>
  <section>
    <a-card class="card">
      <a-form
        :form="form"
        layout="horizontal"
        :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="大区名称">
              <a-input placeholder="大区名称" v-model="form.partName">
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="大区编号">
              <a-input placeholder="大区编号" v-model="form.partCode"/>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="getList(1)"> 查询</a-button>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="add"> 新增大区</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card class="card" style="margin-top: 18px;">
      <a-table
        rowKey="PartId"
        :columns="columns"
        :data-source="tableData"
        :pagination="paginationParam"
        @change="tableChange"
      >
        <span slot="Action" slot-scope="record">
          <a-button size="small" type="primary" @click="doEdit(record.PartId)"
          >编辑</a-button
          >
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定删除吗？"
            @confirm="() => doDel(record.PartId)"
          >
            <a-button size="small" type="primary">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="visible"
      :title="modalTitle"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        :rules="rules"
        ref="modelForm"
        :model="modelForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 10 }"
      >
        <a-form-model-item label="名称" prop="partName">
          <a-input v-model="modelForm.partName"/>
        </a-form-model-item>
        <a-form-model-item label="排序号" prop="sort">
          <a-input-number v-model="modelForm.sort"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "60px",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "大区名称",
    dataIndex: "PartName",
    key: "PartName",
  },
  {
    title: "排序号",
    dataIndex: "Sort",
    key: "Sort",
  },
  {
    title: "操作",
    key: "Action",
    scopedSlots: { customRender: "Action" },
  },
];

export default {
  data () {
    return {
      visible: false,
      modalTitle: "",
      //是否新增
      isAdd: null,
      form: {
        //经销商代码
        partName: "",
        //关键字
        partCode: "",
      },
      modelForm: {
        partName: "",
        sort: null,
        partId: "",
      },
      //分页数据
      pageData: {},
      //表格数据
      tableData: [],
      columns,
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      rules: {
        partName: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序号",
            trigger: ["blur", "change"],
          },0
        ],
      },
    };
  },
  methods: {
    add () {
      this.visible = true;
      this.modalTitle = "新增大区";
      this.isAdd = true;
      this.getSort();
    },
    async getSort () {
      let self = this;
      //获取排序号
      let res = await self.$Http.GetPartSortIndex();
      self.modelForm.sort = res.Data;
    },
    handleOk () {
      let self = this;
      self.$refs.modelForm.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res = self.isAdd
            ? await self.$Http.InsertPart(self.modelForm)
            : await self.$Http.EditPart(self.modelForm);
          if (res.Success) {
            self.$message.success(res.Data);
            this.$refs["modelForm"].resetFields();
            self.visible = false;
            self.getList();
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
    handleCancel () {
      this.modalTitle = "";
      this.$refs["modelForm"].resetFields();
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    tableChange (pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.getList(this.paginationParam.current);
    },
    //获取列表
    async getList (pageIndex) {
      let self = this;
      //将分页参数传入form对象
      self.form.pageSize = self.paginationParam.pageSize;
      self.form.pageIndex = self.paginationParam.current = pageIndex;
      let res = await self.$Http.GetPartListByPage(self.form);
      self.paginationParam.total = res.TotalCount;
      self.tableData = res.Data;
    },
    //编辑
    doEdit (id) {
      this.visible = true;
      this.modalTitle = "编辑大区";
      this.isAdd = false;
      this.getInfo(id);
      // this.$router.push("/bgMannage/edit?partId=" + id);
    },
    //获取详情
    async getInfo (id) {
      let self = this;
      //获取当前数据
      let res = await self.$Http.GetPartInfoById({
        partId: id,
      });
      if (res.Data.length > 0) {
        self.modelForm.partName = res.Data[0].PartName;
        self.modelForm.sort = res.Data[0].Sort;
        self.modelForm.partId = res.Data[0].PartId;
      }
    },
    //删除
    async doDel (id) {
      let self = this;
      let res = await self.$Http.DeletePart({ partId: id });
      if (res.Success) {
        self.$message.success(res.Data);
        self.getList(1);
      } else {
        self.$message.error(res.Message);
      }
    },
  },
  created () {
  },
  mounted () {
    this.getList(1);
  },
};
</script>
