<template>
  <a-row class="carding">
    <!--  展厅漏斗      -->
    <a-col :span="12" style="padding-right: 5px">
      <div class="card-border" style="height: 566px">
        <div class="title">展厅漏斗</div>
        <div v-for="(item,j) in funnelData.funnelData_1" :key="j++" :class="'progress-'+item.type">
          <p>{{ item.title }}</p>
          <div class="">
                       <span :style="item.type==='green'? 'width:'+(item.val >100 ? 100:item.val)+'%':''">
                           {{ item.val }}
                       </span>
          </div>
        </div>
      </div>
    </a-col>
    <!--  IDCC漏斗      -->
    <a-col :span="12" style="padding-left: 5px;">
      <div class="card-border" style="height: 566px">
        <div class="title">IDCC漏斗</div>
        <div v-for="(item,i) in funnelData.funnelData_2" :key="i++" :class="'progress-'+item.type">
          <p>{{ item.title }}</p>
          <div class="">
                       <span :style="item.type==='green'? 'width:'+(item.val >100 ? 100:item.val)+'%':''">
                           {{ item.val }}
                       </span>
          </div>
        </div>
      </div>
    </a-col>
    <!--   IDCC平台效率     -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">IDCC平台效率</div>
        <table>
          <thead>
          <th v-for="item in tableData.table_1.columnsData" :key="item">
            {{ item }}
          </th>
          </thead>
          <tr v-for="item in tableData.table_1.data" :key="item.title">
            <td :style="'text-indent:'+item.indentation+'em;width:10%' ">
              {{ item["title"] }}
            </td>
            <td style="text-align: center;width: 4%">{{ item["unit"] }}</td>
            <td> {{ item["0"] }}</td>
            <td> {{ item["1"] }}</td>
            <td> {{ item["2"] }}</td>
            <td> {{ item["3"] }}</td>
            <td> {{ item["4"] }}</td>
            <td> {{ item["5"] }}</td>
            <td> {{ item["6"] }}</td>
            <td> {{ item["7"] }}</td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <!-- 水平事业渗透率       -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">水平事业渗透率</div>
        <table>
          <thead>
          <th v-for="item in tableData.table_2.columnsData" :key="item">
            {{ item }}
          </th>
          </thead>
          <tbody>
          <tr v-for="item in tableData.table_2.data" :key="item.title">
            <td :style="'text-indent:'+item.indentation+'em' ">
              {{ item["title"] }}
            </td>
            <td style="text-align: center;width: 4%">{{ item["unit"] }}</td>
            <td> {{ item["0"] }}</td>
            <td> {{ item["1"] }}</td>
            <td> {{ item["2"] }}</td>
            <td> {{ item["3"] }}</td>
          </tr>
          </tbody>
        </table>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { percentage, ThousandYuan } from "@/until/option";

export default {
  name: "twoRealTime",
  props: {
    dataParams: {
      data: {},
      date: {},
    },
  },
  data() {
    return {
      funnelData: {
        funnelData_1: [
          {
            title: "客流",
            val: "100",
            type: "green",
            kpiCode: "A1303043",
          },
          {
            title: "首次客流",
            val: "80",
            type: "gray",
            kpiCode: "A1302012",
          },
          {
            title: "再次客流",
            val: "20",
            type: "gray",
            kpiCode: "A1302013",
          },
          {
            title: "再次客流占比",
            val: "20%",
            type: "gray",
            kpiCode: "",
          },
          {
            title: "试乘试驾数",
            val: "50",
            type: "green",
            kpiCode: "A1202271",
          },
          {
            title: "试乘试驾率",
            val: "50%",
            type: "gray", unit: "%",
            kpiCode: "A1203401",
          },
          {
            title: "订单数",
            val: "20",
            type: "green",
            kpiCode: "A1303080",
          },
          {
            title: "首次客流订单",
            val: "10",
            type: "gray",
            kpiCode: "A1302014",
          },
          {
            title: "再次客流订单",
            val: "10",
            type: "gray",
            kpiCode: "A1302111",
          },
          {
            title: "首次客流成交率",
            val: "13%",
            type: "gray", unit: "%",
            kpiCode: "A1303081_avg",
          },
          {
            title: "再次客流成交率",
            val: "50%",
            type: "gray", unit: "%",
            kpiCode: "A1303082_avg",
          },
          {
            title: "展厅成交率",
            val: "20%",
            type: "gray", unit: "%",
            kpiCode: "A1303083_avg",
          },
          {
            title: "终端数",
            val: "3",
            type: "green",
            kpiCode: "A0302021",
          },
        ],
        funnelData_2: [
          {
            title: "总线索",
            val: "100",
            type: "green",
            kpiCode: "A1302115",
          },
          {
            title: "有效线索",
            val: "60",
            type: "green",
            kpiCode: "A1302019",
          },
          {
            title: "线索有效率",
            val: "80%",
            type: "gray", unit: "%",
            kpiCode: "A1303036",
          },
          {
            title: "总客流",
            val: "20",
            type: "green",
            kpiCode: "A1302028",
          },
          {
            title: "邀约到店率",
            val: "70%",
            type: "gray", unit: "%",
            kpiCode: "A1303024_avg",
          },
          {
            title: "试乘试驾数",
            val: "50",
            type: "green",
            kpiCode: "A1202291",
          },
          {
            title: "试乘试驾率",
            val: "50%",
            type: "gray", unit: "%",
            kpiCode: "A1203421_avg",
          },
          {
            title: "订单数",
            val: "20",
            type: "green",
            kpiCode: "A1302032",
          },
          {
            title: "到店成交率",
            val: "40%",
            type: "gray", unit: "%",
            kpiCode: "A1303028_avg",
          },
          {
            title: "总线索转化率",
            val: "13%",
            type: "gray", unit: "%",
            kpiCode: "A1303032_avg",
          },
          {
            title: "终端数",
            val: "18",
            type: "green",
            kpiCode: "A0302022",
          },
        ],
      },
      tableData: {
        table_1: {
          columnsData: ["指标名称", "单位", "汽车之家", "易车网", "懂车帝", "太平洋", "广宣", "自店", "其他", "合计"],
          bodyData: [
            {
              title: "总线索", unit: "个",
              kpiCodes: ["E1202091", "E1202092", "E1202093", "E1202094", "E1202095", "E1202099", "E1202096+E1202097+E1202098", "A1302115"],
            },
            {
              title: "有效线索", unit: "个",
              kpiCodes: ["E1202111", "E1202112", "E1202113", "E1202114", "E1202115", "E1202119", "E1202116+E1202117+E1202118", "A1302019"],
            },
            {
              title: "线索有效率", unit: "%",
              indentation: 2,
              kpiCodes: ["E1303331_avg", "E1303332_avg", "E1303333_avg", "E1303334_avg", "E1303335_avg", "E1303339_avg", "", "A1303036_avg"],
            },
            {
              title: "总客流", unit: "个",
              kpiCodes: ["E1202151", "E1202152", "E1202153", "E1202154", "E1202155", "E1202159", "E1202156+E1202157+E1202158", "A1302028"],
            },
            {
              title: "邀约到店率", unit: "%",
              indentation: 2,
              kpiCodes: ["E1303231_avg", "E1303232_avg", "E1303233_avg", "E1303234_avg", "E1303235_avg", "E1303239_avg", "", "A1303024_avg"],
            },
            {
              title: "订单数", unit: "台",
              kpiCodes: ["E1202171", "E1202172", "E1202173", "E1202174", "E1202175", "E1202179", "E1202176+E1202177+E1202178", "A1302032"],
            },
            {
              title: "到店成交率", unit: "%",
              indentation: 2,
              kpiCodes: ["E1303251_avg", "E1303252_avg", "E1303253_avg", "E1303254_avg", "E1303255_avg", "E1303259_avg", "", "A1303028_avg"],
            },
            {
              title: "总线索转化率", unit: "%",
              indentation: 2,
              kpiCodes: ["E1303361_avg", "E1303362_avg", "E1303363_avg", "E1303364_avg", "E1303365_avg", "E1303369_avg", "", "A1303032_avg"],
            },
            {
              title: "终端数", unit: "台",
              kpiCodes: ["E0302160", "E0302161", "E0302162", "E0302163", "E0302164", "E0302168", "E0302165+E0302166+E0302167", "A0302022"],
            },
          ],
          data: [],
        },
        table_2: {
          columnsData: ["项目", "单位", "新保台次", "金融按揭台次", "二手车台次", "新车延保台次"],
          bodyData: [
            {
              title: "台次", unit: "台",
              kpiCodes: ["A0302027", "A0302028", "A0302030", "A0302033"],
            }, {
              title: "渗透率(置换率）", unit: "%",
              kpiCodes: ["A0303272_avg", "A0303273_avg", "A0303275_avg", "A0303276_avg"],
            },
          ],
          data: [],
        },
      },
    };
  },
  created() {
    this.getTableData();
    this.getFunnelData();
  },
  methods: {
    getTableData() {
      let CurrentValues = this.dataParams.data["CurrentValues"];
      Object.keys(this.tableData).map(table => {
        this.tableData[table].data = [];
        this.tableData[table].bodyData.forEach((d) => {
          let arr = {};
          arr["title"] = d.title;
          arr["unit"] = d.unit;
          arr["indentation"] = d.indentation ?? 0;
          d.kpiCodes.forEach((k, i) => {
            if(k === "") {
              arr[i] = "-";
            } else {
              if(d.unit === "%") {
                if(CurrentValues) {
                  arr[i] = percentage(CurrentValues[k] ?? 0);
                }
              } else {
                if(CurrentValues) {
                  arr[i] = ThousandYuan(CurrentValues[k] ?? 0);
                }
              }
            }
          });
          this.tableData[table].data.push(arr);
        });
      });
    },
    getFunnelData() {
      let CurrentValues = this.dataParams.data["CurrentValues"];
      Object.keys(this.funnelData).map(funnel => {
        this.funnelData[funnel].forEach(d => {
          if(d.kpiCode === "") {
            d.val = "-";
          } else {
            if(d.unit === "%") {
              if(CurrentValues) {
                d.val = percentage(CurrentValues[d.kpiCode] ?? 0);
              }
            } else {
              if(CurrentValues) {
              d.val = ThousandYuan(CurrentValues[d.kpiCode] ?? 0);
            }
            }
          }
        });
      });

    },
    randomData() {
      return this._.random(100, 300);
    },
  },
  watch: {
    dataParams: {
      handler: function() {
        this.getTableData();
        this.getFunnelData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "realTime";

.col-border {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #DDDDDD;
  }

.progress-green {
  clear: both; height: 24px;line-height: 24px;

  p {
    display: block;float: left;width: 12%;background: #E8E8E8;color: #333333;text-indent: 5px;

    }

  > div {
    float: left;width: 87%;margin-left: 1%;background: #E8E8E8;
    border-radius: 0 100px 100px 0;

    span {
      display: block;text-align: right;
      background: #2B821D;border-radius: 0 100px 100px 0;color: #fff;padding-right: 10px;
      }
    }
  }

.progress-gray {
  clear: both; height: 24px;line-height: 24px;background: #F2F5F8;
  border-radius: 0 100px 100px 0;

  p {
    text-indent: 16px;font-size: 14px;color: #666;float: left;width: 20%;
    }

  span {
    display: block;text-align: right;padding-right: 10px;
    }
  }
.card-border{
  background:#fff;padding: 15px 17px 15px 17px;border: 1px solid #DDDDDD;box-shadow: 0 2px 4px 0 rgba(0 0 0,.1);border-radius: 4px
  }
</style>
