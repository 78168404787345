<template>
  <a-col :span="24" id="head">
    <a-row style="height: 50px">
      <a-col :span="17" class="left_logo" @click="$router.push('/main')">
        <img src="../../assets/logo.png" alt=""/>
        <span style="font-style: italic;font-weight: 500"> BM业绩管理系统</span>
      </a-col>
      <a-col :span="3" style="text-align: right">
        <a-dropdown>
          <a style="font-size: 14px" class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            帮助及反馈
            <a-icon type="down"/>
          </a>
          <a-menu
              slot="overlay"
              style="background: #24619e; color: #ffffff; top: 8px;left: -70px"
          >
            <a-menu-item>
              <a style="font-size: 14px" @click="downTmp('BDM系统操作指南.pdf')" class="color-white">填报指南下载
                <a-icon style="font-size: 20px" type="vertical-align-bottom"/>
              </a>
            </a-menu-item>
            <a-menu-item>
              <a style="font-size: 14px" @click="downTmp('广汽传祺BDM系统常见使用问题QA.pdf')" class="color-white">常见使用问题QA下载
                <a-icon style="font-size: 20px" type="vertical-align-bottom"/>
              </a>
            </a-menu-item>
            <a-menu-item>
              <a style="font-size: 14px" @click="downTmp('广汽传祺BDM系统基础数据填报QA.pdf')" class="color-white">基础数据填报QA下载
                <a-icon style="font-size: 20px" type="vertical-align-bottom"/>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>


      </a-col>

      <a-col :span="2" style="text-align: center;cursor: pointer">
        <!-- <a-dropdown
          placement="bottomCenter"
          @visibleChange="visibleChange"
          :trigger="['click']"
        >
          <a-badge class="notice-badge" :count="1">
            <a-icon type="bell" style="font-size: 22px" />
          </a-badge>
          <a-menu slot="overlay" style="top: 10px; width: 200px">
            <a-menu-item key="item1">
              <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="Tab 1">
                  Content of Tab Pane 1
                </a-tab-pane>
                <a-tab-pane key="2" tab="Tab 2">
                  Content of Tab Pane 2
                </a-tab-pane>
              </a-tabs>
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
        <el-dropdown :hide-on-click="false" placement="bottom">
          <span class="el-dropdown-link">
            <a-badge class="notice-badge" :count="msgCount">
              <a-icon type="bell" style="font-size: 22px"/>
            </a-badge>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item style="top: -10px; width: 300px;min-height: 300px">
              <a-tabs :default-active-key="msgTypeEnum.systemMsg">
                <a-tab-pane :key="msgTypeEnum.systemMsg" tab="系统消息">
                  <ul v-if="systemMsg.length > 0">
                    <li class="msgTitle"
                        @click="msgClick(item)"
                        v-for="item in systemMsg"
                        :key="item.ID"
                    >
                      <span :style="item.IsRead!==1?'color:red':''">•</span> {{ item.MsgTitle }}
                    </li>
                  </ul>
                  <div v-else>暂无数据</div>
                </a-tab-pane>
                <a-tab-pane :key="msgTypeEnum.reportMsg" tab="填报消息">
                  <ul
                      v-if="reportMsg.length > 0"
                      style="list-style: none; padding: 0; margin: 0"
                  >
                    <li
                        class="msgTitle"
                        @click="msgClick(item)"
                        v-for="item in reportMsg"
                        :key="item.ID"
                    >
                      <span :style="item.IsRead!==1?'color:red':''">•</span> {{ item.MsgTitle }}
                    </li>
                  </ul>
                  <div v-else>暂无数据</div>
                </a-tab-pane>
              </a-tabs>
            </el-dropdown-item>
            <div class="dropdown-bottom">
              <div class="setting" @click="$router.push('/msgMannage/list')">
                <span v-if="isPermission"><a-icon type="setting"/>
                设置</span>
              </div>
              <div class="viewAllMsg" @click="$router.push('/msgCenter')">
                查看所有消息
                <a-icon type="right"/>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </a-col>
      <a-col :span="2">
        <a-dropdown>
          <a style="font-size: 14px" class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <!--            <a-avatar
                            icon="user"
                            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                        />-->
            {{ userName }}
            <a-icon type="down"/>
          </a>
          <a-menu
              slot="overlay"
              style="background: #24619e; color: #ffffff; top: 8px"
          >
            <a-menu-item>
              <a style="color: #fff" href="/personalCenter">个人中心</a>
            </a-menu-item>
            <a-menu-item>
              <a style="color: #fff" href="/changePwd">修改密码</a>
            </a-menu-item>
            <a-menu-item>
              <a class="btn" @click="LoginOut">登出</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-col>
</template>

<script>
import { msgTypeEnum } from "@/enum/enum.js";

export default {
  name: "header_r",
  data() {
    return {
      dropDownVisible: true,
      isChangeTab: false,
      msgCount: 0,
      //系统消息
      systemMsg: [],
      //填报消息
      reportMsg: [],
      userName: "",
      msgTypeEnum: msgTypeEnum,
      isPermission: false,
    };
  },
  mounted() {
    //获取未读消息
    this.getPermission();
    this.GetMsgNoRead();
    //获取系统消息
    this.GetSystemMsg();
    //获取填报消息
    this.GetReportMsg();
    this.GetUser();
  },
  methods: {
    /*读取COOKEIS*/
    getPermission() {
      const Permission = window.localStorage.getItem("permission");
      if(Permission) {
        if(Permission.indexOf("站内信管理") > 0) this.isPermission = true;
      }
    },
    msgClick(item) {
      let self = this;
      self.$info({
        title: item.MsgTitle,
        content: item.MsgContent,
        async onOk() {
          //设置已读
          if(item.IsRead !== 1) {

            let res = await self.$Http.UpdateReadMsg({
              MsgUserId: item.MsgUserId,
            });
            if(!res) {
              return;
            }
            if(res.Success) {
              //更新未读数量，系统消息和填报消息列表
              //获取未读消息
              self.GetMsgNoRead();
              //获取系统消息
              self.GetSystemMsg();
              //获取填报消息
              self.GetReportMsg();
            } else {
              self.$message.error(res.Message);
            }
          }

        },
      });
    },
    async GetMsgNoRead() {
      let self = this;
      let res = await self.$Http.GetMsgNoRead();
      self.msgCount = res.Data[0].MsgCount;
    },
    // visibleChange(visible) {
    // },
    async LoginOut() {
      let res = await this.$Http.LoginOut();
      this.delCookie();
      this.$message.success("退出成功！");
      setTimeout(() => {
        this.$router.push("/login");
      }, 500);
    },
    delCookie() {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    async GetSystemMsg() {
      let self = this;
      let res = await self.$Http.GetMsgByUserId({
        MsgType: msgTypeEnum.systemMsg,
        IsRead: "",
      });
      if(res.Success) {
        self.systemMsg = [];
        res.Data.forEach((item, i) => {
          if(i < 8) {
            self.systemMsg.push({
              id: item.ID,
              MsgTitle: item.MsgTitle,
              MsgContent: item.MsgContent,
              MsgUserId: item.MsgUserId,
              IsRead: item.IsRead,
              MsgType: item.MsgType,
            });
          }
        });
      }
    },
    async GetReportMsg() {
      let self = this;
      let res = await self.$Http.GetMsgByUserId({
        MsgType: msgTypeEnum.reportMsg,
        IsRead: "",
      });
      if(res.Success) {
        self.reportMsg = [];
        res.Data.forEach((item, i) => {
          if(i < 8) {
            self.reportMsg.push({
              id: item.ID,
              MsgTitle: item.MsgTitle,
              MsgContent: item.MsgContent,
              MsgUserId: item.MsgUserId,
              IsRead: item.IsRead,
              MsgType: item.MsgType,
            });
          }
        });
      }
    },
    // 获取当前登陆用户
    async GetUser() {
      let cookieList = document.cookie.split(";");
      let cookieUserID;
      if(cookieList.length > 0) {
        cookieList.forEach((element) => {
          let cookieSplit = element.split("=");
          if(cookieSplit[0].indexOf("CJ_LMS_SSO_UID") > -1) {
            cookieUserID = cookieSplit[1];
          }
        });
      }
      let res = await this.$Http.getUserInfo({ uid: cookieUserID });
      this.userName = res.Data[0].Name;
    },
    async downTmp(val) {
      // window.location.href("http://47.111.183.248:9002/upload/mould/BDM系统基础数据填报模板.xlsx");
      let res = await this.$Http.FileShareLink({
        objectkey: val,
      });
      if(res.Success) {
        window.open(res.Data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.msgTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  }

.msgTitle:hover {
  background: none;
  }

.ant-tabs-bar {
  margin-bottom: 10px;
  }

#head {
  position: fixed;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  height: 50px;
  line-height: 47px;
  background: #24619e;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  z-index: 999;
  }

.ant-dropdown-link {
  color: #ffffff;
  }

li {
  a {
    color: #ffffff;
    padding: 10px 30px;
    }
  }

li:hover {
  background-color: #3d3938;

  a {
    color: #ffffff;
    }
  }

.ant-dropdown-menu {
  padding: 0 0;
  }

.left_logo {
  padding-left: 30px;cursor: pointer;

  img:nth-child(1) {
    width: 100px;
    border-right: 1px solid #fff;
    padding-right: 5px;
    }
  }

.color-white {
  margin-left: 6px;

  &:hover {
    color: #fff;
    }
  }

.notice-badge {
  color: #ffffff;

  .header-notice-icon {
    font-size: 12px;
    padding-top: 2px;
    }
  }

.dropdown-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: 12px;
  cursor: pointer;
  border-top: 1px solid #f2f2f2;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px;
  }

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #ffffff;
  }

.ant-tabs {
  padding-bottom: 15px;
  }
</style>
