<template>
    <a-row :gutter="[0,8]">
        <a-col :span="24" v-if="isCloseA">
            <a-anchor style="min-width: 1440px" :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
                <div class="fix_border">
                    <a-col :span="20">
                        <a-month-picker style="margin-right: 1vw;" placeholder="请选择报告月份" @change="dateChange"
                                :disabledDate="disabledDate" v-model="ReportDateMoment" :allowClear="false"
                        />
                        <!--            <a-tree-select v-if="activeKey==='1'" @change="onAreaChange" show-search
                                                   v-model="params.AreaData"
                                                   style="width: 240px"
                                                   :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                                   :tree-data="treeData"
                                                   :treeDefaultExpandedKeys="DefaultTreeData"
                                                   searchPlaceholder="搜索"
                                                   tree-node-filter-prop="title"
                                    />-->
                        <a-cascader style="width: 380px;margin-right: 20px" :options="dealers" :allowClear="false" :change-on-select="true"
                                placeholder="全国" @change="onAreaChange" expandTrigger="hover"
                                :show-search="true"
                        />
                    </a-col>

                    <a-col  style="float: right">
                        <!--            <a-space size="small" class="space">
                                      <a-tag :color="activeKey==='1'?'#24619e':''" @click="setActionKey('1')">市占率</a-tag>
                                      <a-tag :color="activeKey==='2'?'#24619e':''" @click="setActionKey('2')">区域业绩</a-tag>
                                      <a-tag :color="activeKey==='3'?'#24619e':''" @click="setActionKey('3')">销售过程</a-tag>
                                    </a-space>-->
                        <span style="float: right;position: relative;top: -20px;margin-right: 4px" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
                      <a-tooltip placement="top" :title="`点击生成《${moment(ReportDateMoment).format('yyyy年M月')+params.choseDealer}车型分析实时报告》`">
                       <!--<a-button type="primary" @click="generateImages()">生成长图</a-button>-->
                      </a-tooltip>
                    </a-col>
                </div>
            </a-anchor>
        </a-col>
        <a-col :span="24">
          <div class="" ref="capture">
            <div class="report_bg">
                {{ moment(ReportDateMoment).format("yyyy年M月") }} {{ params.choseDealer }} 市占率分析看板
            </div>
            <oneMarketShare v-if="activeKey==='1'" :data-params="params"></oneMarketShare>
            <twoRegionalPerformance v-if="activeKey==='2'" :data-params="params"></twoRegionalPerformance>
            <thereSalesProcess v-if="activeKey==='3'" :data-params="params"></thereSalesProcess>
          </div>
        </a-col>
    </a-row>
</template>

<script>
import moment from "moment";
import oneMarketShare from "@/views/ModelAnalysis/childComponent/oneMarketShare";
import twoRegionalPerformance from "@/views/ModelAnalysis/childComponent/twoRegionalPerformance";
import thereSalesProcess from "@/views/ModelAnalysis/childComponent/thereSalesProcess";
import { disabledDate } from "@/until/dayFormat";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
 name: "modelAnalysis",
 components: { oneMarketShare, twoRegionalPerformance, thereSalesProcess },
 data() {
  return {
   disabledDate, moment,
   activeKey: "1",
   isCloseA: true,
   treeData: [
    {
     title: "全国",
     value: "",
     key: "",
     children: [],
    },
   ],
   DefaultTreeData: [],
   dealers: [],  // 新的区域
   params: {
    ReportDate: "",
    DealerCode: "",
    DealerName: "",
    choseDealer: "全国",   // 新的区域
    AreaData: "",
   },
  };
 },
 created() {
  this.getTreeData();
  this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
 },
 methods: {
  generateImages() {
     message.loading("生成中,请稍后....", 4);
     this.isShow = false; // 隐藏填报说明
     setTimeout(() => {
       html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
         this.img = canvas.toDataURL();
         if(window.navigator.msSaveOrOpenBlob) {
           const atob1 = atob(this.img.split(",")[1]);
           let n = atob1.length;
           const u8arr = new Uint8Array(n);
           while (n--) {
             u8arr[n] = atob1.charCodeAt(n);
           }
           const blob = new Blob([u8arr]);
           window.navigator.msSaveOrOpenBlob(blob, moment(this.ReportDateMoment).format("yyyy年M月") + this.params.choseDealer + "市占率分析看板" + "." + "png");
         } else {
           const a = document.createElement("a");
           a.href = this.img;
           a.setAttribute("download", moment(this.ReportDateMoment).format("yyyy年M月") + this.params.choseDealer + "市占率分析看板" + "." + "png");
           a.click();
         }
       }).catch((error) => {
         this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
       });
     }, 1000);
   },
  onAreaChange(value, selectedOptions) {
   this.params.AreaData = value[value.length - 1];
   this.params.choseDealer = selectedOptions[selectedOptions.length - 1].label;
  },
  // 日期框点击方法
  dateChange(date, dateString) {
   this.params.ReportDate = dateString + "-01";
   //  this.getDealerInfo();
  },
  setActionKey(key) {
   this.activeKey = key;
  },
  async getTreeData() {
   /*  let res = await this.$Http.GetDealersByRole();
     if (!res.Data) {
       this.$message.error(res.Message);
       return;
     }
     this.treeData[0].children = res.Data.chain().map(p => {
       let obj = {};
       obj.value = p.PartId;
       obj.key = p.PartId;
       obj.title = p.PartName;
       obj.children = p.Dealers.chain().map(d => {
         let childObj = {};
         childObj.value = d.DealerCode;
         childObj.key = d.DealerCode;
         childObj.title = d.DealerName;
         return childObj;
       }).value();
       return obj;
     }).value();
     this.DefaultTreeData.push(
       this.treeData[0].key,
     );*/

   let ras = await this.$Http.GetDealersTreeByRole({
    level: 3, // 取出三级地区,不传取出全部地区
   });
   if(!ras.Data) {
    this.$message.error(ras.Message);
    return;
   }
   this.dealers = [{
    value: "",
    label: "全国",
    children: ras.Data,
   }];
  },
 },
 computed: {
  ReportDateMoment: {
   get() {
    return moment(this.params.ReportDate);
   },
   set(newValue) {
    this.params.ReportDate = newValue.format("YYYY-MM-01");
   },
  },
 },
};
</script>

<style lang="less" scoped>
</style>
