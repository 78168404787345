<template>
  <a-row :gutter="[0,8]">
    <a-col :span="24" v-if="isCloseA">
      <a-anchor style="min-width: 1440px" :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          <a-col :span="22">
            <switch-setting :api-instance.sync="Http"/>
            <span>时间维度：</span>
            <!--                        <a-select :defaultValue="selectValue.defaultYearValue" style="width: 100px" placeholder="请选择年份"
                                            @change="yearChange"
                                    >
                                        <a-select-option v-for="item in selectValue.yearValue" :key="item">
                                            {{ item }}
                                        </a-select-option>
                                    </a-select>
                                    <a-select defaultValue="全部" style="width: 100px;margin-left: 2px" placeholder="请选择月份"
                                            @change="mothChange"
                                    >
                                        <a-select-option v-for="(item,j) in selectValue.mothValue" :key="j++">
                                            {{ item }}
                                        </a-select-option>
                                    </a-select>-->
            <a-tree-select @change="onPickerChange"
                v-model="DefaultPickerData"
                style="width: 130px"
                :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                :tree-data="pickerData"
                tree-node-filter-prop="title"
            />
            <span style="margin-left:1vw;">数据：</span>
            <!--                        <a-tree-select @change="onAreaChange" show-search
                                            v-model="selectValue.AreaValue"
                                            style="width: 240px"
                                            :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
                                            :tree-data="treeData"
                                            :treeDefaultExpandedKeys="DefaultTreeData"
                                            searchPlaceholder="搜索"
                                            tree-node-filter-prop="title"
                                    />-->
            <a-cascader style="width: 420px;margin-right: 20px" :options="dealers" :allowClear="false" change-on-select=""
                :placeholder="selectValue.AreaValue" @change="onAreaChange"
                :show-search="true"
            />

            <a-button type="primary" style="margin-left: 10px" @click="search()">点击查询</a-button>

          </a-col>
          <a-col style="float: right" span="2">
                    <span style="float: right;position: relative;top: -20px;margin-right: 4px"
                        @click="isCloseA=false"
                    >
                        <a-icon type="close"/>
                    </span>
            <!-- todo 生成PDF示例    -->
            <!--            <a-tooltip placement="top" title="请先选择时间维度和数据,确认无误后,再生成报告图片!">
                            <a-button type="primary" @click="generateImages()">生成长图</a-button>
                        </a-tooltip>-->
            <a-tooltip placement="top" :title="`点击生成《${titleData.yearValue}年${titleData.mothValue === '全部' || Number(titleData.mothValue) === 0 ? '度' : parseInt(titleData.mothValue) + '月阶段' }${titleData.AreaValue }车型分析实时报告》`">
              <!--<a-button type="primary" @click="generateImages()">生成长图</a-button>-->
            </a-tooltip>
          </a-col>
        </div>
      </a-anchor>
    </a-col>
    <a-col :span="24">
      <div class="" ref="capture">
        <div class="report_bg">
          {{ titleData.yearValue }}年{{
            titleData.mothValue === "全部" || Number(titleData.mothValue) === 0 ? "度" : parseInt(titleData.mothValue) + "月阶段" }}{{
            titleData.AreaValue }}车型分析实时报告
        </div>
        <one-real-time-model :data-params="allData"/>
        <two-real-time-model :data-params="allData"/>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import moment from "moment";
import underscore from "underscore";
import { disabledDate } from "@/until/dayFormat";
import oneRealTimeModel from "@/views/realTimeModelAnalysis/childComponent/oneRealTimeModel";
import twoRealTimeModel from "@/views/realTimeModelAnalysis/childComponent/twoRealTimeModel";
import {
  currentFilterCodes,
  lastFilterCodes,
  rateFilterCodes,
} from "@/views/realTimeModelAnalysis/childComponent/kpiCode";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  name: "realTimeModelAnalysis",
  components: { oneRealTimeModel, twoRealTimeModel },
  data() {
    return {
      disabledDate, currentFilterCodes, lastFilterCodes,
      allData: {
        data: {},
        date: {},
      },
      isCloseA: true,
      Http: {},
      selectValue: {
        yearValue: "",
        defaultYearValue: "全部",
        defaultMothValue: "全部",
        mothValue: [],
        areaArr: [],
        AreaValue: "全国",
        areaCode: "",
      },
      titleData: {
        yearValue: "",
        mothValue: "",
        AreaValue: "全国",
      },
      treeData: [
        {
          title: "全国",
          value: "",
          key: "",
          children: [],
        }],
      dealers: [], // 新的区域
      pickerData: [],
      DefaultTreeData: [],
      DefaultPickerData: "",
      params: {
        yearValue: "",
        mothValue: "",
        AreaValue: "",
        areaCode: "",
      },
    };
  },
  mounted() {
    this.getPickerData();
    this.getTreeData();
  },
  methods: {
    getParams() {
      let params = this.$route.query;
      if(Object.keys(params).length > 0) {
        this.params = params;
        this.selectValue.defaultYearValue = Number(this.params.yearValue);
        this.selectValue.defaultMothValue = Number(this.params.mothValue);
        this.selectValue.AreaValue = this.params.AreaValue;
        this.selectValue.areaCode = [this.params.areaCode];
        if(this.params.mothValue === "0") {
          this.DefaultPickerData = this.params.yearValue;
        } else {
          this.DefaultPickerData = this.params.yearValue + "-" + this.params.mothValue;
        }
        let arr = this.params.AreaValue.split("/");
        this.titleData.AreaValue = arr[arr.length - 1];
      }
      this.search();
    },
    generateImages() {
      //   todo 生成PDF示例 this.$router.push("/realTimeModelAnalysisImg?yearValue=" + this.selectValue.defaultYearValue + "&mothValue=" + this.selectValue.defaultMothValue + "&AreaValue=" + this.titleData.AreaValue + "&areaArr=" + this.selectValue.areaArr);
      message.loading("生成中,请稍后....", 4);
      this.isShow = false; // 隐藏填报说明
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, moment(this.selectValue.yearValue).format("yyyy年") + moment(this.selectValue.mothValue).format("月") + this.titleData.AreaValue + "车型分析实时报告" + "." + "png");
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${this.titleData.yearValue}年${this.titleData.mothValue === "全部" || Number(this.titleData.mothValue) === 0 ? "度" : parseInt(this.titleData.mothValue) + "月阶段"}${this.titleData.AreaValue}车型分析实时报告.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
    async search() {
      /*标题*/
      this.titleData.yearValue = this.selectValue.defaultYearValue;
      this.titleData.mothValue = this.selectValue.defaultMothValue;
      this.titleData.AreaValue = this.selectValue.AreaValue;
      /*加载数据*/
      let mothValue = this.selectValue.defaultMothValue;
      if(mothValue === "全部" || this.selectValue.mothValue === "") mothValue = 0;
      let arr = {};
      arr["year"] = parseInt(this.selectValue.defaultYearValue);
      arr["month"] = parseInt(mothValue);
      let ranges = [];
      if(this.selectValue.areaArr.length > 1) {
        ranges = JSON.parse(JSON.stringify(this.selectValue.areaArr));
        ranges.shift();
      }
      arr["ranges"] = ranges;
      /*  if(this.selectValue.areaCode.length > 8 || this.selectValue.areaCode === "") {
          arr["partId"] = this.selectValue.areaCode;
        } else {
          arr["dealerCode"] = this.selectValue.areaCode;
        }*/
      arr["lastFilterCodes"] = lastFilterCodes;
      arr["currentFilterCodes"] = currentFilterCodes;
      arr["rateFilterCodes"] = rateFilterCodes;
      let res = await this.Http.Realtime(arr);
      if(res["Success"]) {
        this.allData.data = res.Data;
      } else {
        this.$message.error(res["Message"]);
      }
    },
    yearChange(val) {
      this.selectValue.mothValue = ["全部"];
      if(val === moment().format("y")) {
        let data2 = parseInt(moment().format("MM"));
        underscore.range(data2).map(d => {
          this.selectValue.mothValue.push(d + 1);
        });
      } else {
        underscore.range(12).map(d => {
          this.selectValue.mothValue.push(d + 1);
        });
      }
      this.selectValue.defaultYearValue = val;
    },
    mothChange(val) {
      this.selectValue.defaultMothValue = val;
    },
    onAreaChange(value, selectedOptions) {
      this.selectValue.areaCode = value[value.length - 1];
      this.selectValue.areaArr = value;
      this.selectValue.AreaValue = selectedOptions[selectedOptions.length - 1].label;
      this.params.AreaValue = "";
      selectedOptions.forEach((d, i) => {
        if(selectedOptions.length === i + 1) {
          this.params.AreaValue += d.label;
        } else {
          this.params.AreaValue += d.label + "/";
        }
      });
    },
    onPickerChange(val) {
      if(val.length > 4) {
        this.selectValue.defaultYearValue = moment(val).format("y");
        this.selectValue.defaultMothValue = moment(val).format("MM");
      } else {
        this.selectValue.defaultYearValue = val;
        this.selectValue.defaultMothValue = 0;
      }
    },
    getPickerData() {
      this.selectValue.defaultYearValue = moment().format("y");
      this.selectValue.defaultMothValue = moment().format("MM");
      let data1 = moment(this.selectValue.defaultYearValue).add(-4, "y");
      let yearData = underscore.range(4).map(() => data1.add(1, "y").format("y")).reverse();
      this.pickerData = yearData.chain().map(y => {
        let obj = {};
        obj.value = y;
        obj.key = y;
        obj.title = y;
        let mothData = [];
        if(y === moment().format("y")) {
          let data2 = parseInt(moment().format("MM"));
          underscore.range(data2).map(d => {
            mothData.push(d + 1);
          });
        } else {
          underscore.range(12).map(d => {
            mothData.push(d + 1);
          });
        }
        obj.children = mothData.chain().map(m => {
          let childObj = {};
          childObj.value = y + "-" + m;
          childObj.key = y + "-" + m;
          childObj.title = y + "-" + m;
          return childObj;
        }).value();
        return obj;
      }).value();
      this.DefaultPickerData = this.pickerData[0].children[this.pickerData[0].children.length - 1].value;
      this.getParams();
    },
    async getTreeData() {
      let ras = await this.$Http.GetDealersTreeByRole();
      if(!ras.Data) {
        this.$message.error(ras.Message);
        return;
      }
      this.dealers = [{
        value: "",
        label: "全国",
        children: ras.Data,
      }];

    },
  },
};
</script>

<style scoped>

</style>
