<template>
  <section style="margin-bottom: 20px;">
    <a-card>
      <div class="sea">
        <span>报表月份：</span>
        <a-month-picker v-model="ReportDate" placeholder="请选择报表月份" @change="loadData"/>
        <a-space style="padding-left: 30px">
          <a-button :type="activeKey.length===0?'':'danger'" @click="openUpPanel">展开</a-button>
          <a-button :type="activeKey.length===0?'danger':''" @click="closePanel">关闭</a-button>
        </a-space>
        <a-tooltip placement="top" :title="`点击生成《${moment(this.ReportDate).format('YYYY年M月') }销售店诊断报告》`">
          <!--<a-button type="danger" style="float: right;margin-right: 50px !important;" @click="generateImages()">生成长图</a-button>-->
        </a-tooltip>
      </div>
      <div class="" ref="capture">
        <div class="report_bg">{{ moment(this.ReportDate).format("YYYY年M月") }}销售店诊断报告</div>
        <div style="width: 95%;margin: 0 auto; height: 40px; line-height: 40px; background: rgb(255, 251, 230); border: 1px solid rgb(255, 229, 143); border-radius: 4px;">
          <p class="tips  sound">
            <a-icon type="info-circle" style="color: #ffc100;font-size: 20px;padding-left: 26px;" theme="filled"/>
            注：标杆报告内所有指标数据标杆达标率前5%以 <span>绿色</span> 显示, 后5%以 <span>红色</span> 显示（标杆值为行业标杆值，暂取前三个月均值）。
          </p>
        </div>
        <div class="row-list space">
          <div class="dt" v-for="structKey in Object.keys(dataStruct)">
            <industry-standard-tag v-if="dataStruct[structKey]!=null" :data="data[structKey]"/>
            <dt v-else style="border: none">{{ structKey }}</dt>
          </div>
        </div>
        <div style="padding-left: 25px">
          <div class="row-list-j">
            <dt v-for="structKey in Object.keys(dataStruct).chain().filter(structKey=> dataStruct[structKey]!=null).value()">
              <img src="../../assets/jian.png" alt="">
            </dt>
          </div>
          <div style="clear: both"></div>
          <div class="row-list-j">
            <dt v-for="structKey in Object.keys(dataStruct).chain().filter(structKey=> dataStruct[structKey]!=null).value()">
              <a-collapse v-model="activeKey" expandIconPosition="right">
                <a-collapse-panel v-for="panel in Object.keys(dataStruct[structKey].children)" :key="panel" :header="panel">
                  <industry-standard-tag :key="itemKey" v-for="itemKey in Object.keys(dataStruct[structKey].children[panel])"
                      :data="data[itemKey]"
                  />
                </a-collapse-panel>
              </a-collapse>
            </dt>
          </div>
        </div>
      </div>
    </a-card>
  </section>
</template>
<script>
import moment from "moment";
import IndustryStandardTag from "@/views/IndustryBenchmark/IndustryStandardTag";
import { dataStruct, GetFilterCodes } from "@/views/IndustryBenchmark/data";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  components: { IndustryStandardTag },
  data() {
    return {
      moment,
      ReportDate: moment().add(-1, "M"),
      dataStruct: dataStruct,
      activeKey: [], // 默认展开的面板
      data: {},
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    filterCodes: function() {
      return Object.keys(GetFilterCodes());
    },
  },
  methods: {
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      this.openUpPanel();
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, `${moment(this.ReportDate).format("YYYY年M月")}销售店诊断报告.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${moment(this.ReportDate).format("YYYY年M月")}销售店诊断报告.png`);
            a.click();
          }
          this.activeKey = [];
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },

    async loadData() {
      let res = await this.$Http.IndustryStandardReport({
        reportDate: this.ReportDate.format("YYYY-MM-01"),
        filterCodes: this.filterCodes,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.appendReportData(res.Data, this.dataStruct);
      this.data = res.Data;
    },
    appendReportData(reportData, data) {
      Object.keys(data).forEach(key => {
        let dataObj = data[key];
        if(dataObj == null)
          return;

        let item = reportData[key];
        item.KpiCode = key;
        item.KpiName = dataObj.name;
        item.ReportDate = this.ReportDate;
        if(dataObj.children) {
          item.HasChildren = true;
          Object.values(dataObj.children).forEach(values => this.appendReportData(reportData, values));
        }
      });
    },
    // 展开全部panel
    openUpPanel() {
      Object.values(this.dataStruct).forEach(kpi => {
        if(kpi) {
          Object.keys(kpi.children).map(d => {
            this.activeKey.push(d);
          });
        }
      });
    },
    closePanel() {
      if(this.activeKey.length > 0)
        this.activeKey = [];
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .ant-collapse {
    border: 0;
    border-radius: 8px;

    }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 15px 0 0 0;
    }

  .ant-collapse > .ant-collapse-item {
    clear: both;overflow: hidden;
    border-bottom: 10px;background: #E6ECEC;margin-bottom: 10px;
    }
  }

.sound {
  span:nth-child(2) {
    color: #00B478;
    }

  span:last-child {
    color: #24619e;
    }
  }

.space {
  padding-top: 20px;
  }

.row-list {
  width: 100%;
  overflow: hidden;

  .dt {
    float: left;
    cursor: pointer;
    width: 13%;

    &:nth-child(2n) {
      width: 2%;
      border: 0;
      text-align: center;
      padding-top: 40px;
      font-size: 24px;
      color: #286674;
      font-weight: 500;

      &:hover {
        border: none;
        box-shadow: 0 0 0 #fff;
        }
      }


    &.r-green {
      /*  ol {
          background: #86A3A1;
          }*/

      ul {
        background: #B2E8D6;

        span:nth-child(2) {
          color: #00B478;
          }
        }
      }

    &.r-red {
      ol {
        background: #86A3A1;
        }

      ul {
        background: #F9D1D1;

        span:nth-child(2) {
          color: #24619e;
          }
        }
      }
    }

  & > div:nth-child(1) {
    width: 13.4%;
    }

  & > div:nth-child(3) {
    width: 17.7%;
    }

  & > div:nth-child(5) {
    width: 13.6%;
    }

  & > div:nth-child(7) {
    width: 13.6%;
    }

  & > div:nth-child(9) {
    width: 13.5%;
    }
  }

.row-list-j {
  clear: both;display: flex;

  dt {
    cursor: pointer;
    width: 14%;
    text-align: center;
    color: #86A3A1;
    font-size: 30px;
    margin-right: 2%;


    &:last-child {
      margin-right: 0;
      }

    &:nth-child(2) {
      width: 18%;
      }
    }
  }

.row-list-col {
  font-size: 14px;
  text-align: left;
  border: 1px dashed #004540;
  border-radius: 8px;
  padding: 5px 10px;
  color: #777;
  font-weight: 500;
  margin-bottom: 12px;

  ol {
    color: #666
    }

  ul {
    padding-left: 8px
    }

  &:hover {
    border: 1px solid #004540;
    box-shadow: 2px 4px 8px 2px rgba(78, 78, 78, 0.7);
    }

  &.c-green {
    background: #B2E8D6;

    span:last-child {
      color: #00B478;
      }
    }

  &.c-red {
    background: #F9D1D1;

    span:last-child {
      color: #24619e;
      }
    }
  }
</style>
