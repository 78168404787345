<template>
<span style="margin-right: 15px">
  <a-tooltip placement="top" :title="defaultChecked ?  '系统取数':'手工取数'" arrow-point-at-center>
  <a-switch :default-checked="defaultChecked" checked-children="系统取数" un-checked-children="手工取数"
      @change="onChange"
  />
  </a-tooltip>
</span>
</template>

<script>
export default {
  name: "switchSetting",
  props: {
    apiInstance: {},
  },
  data() {
    return {
      defaultChecked: true,
    };
  },
  created() {
    this.onChange(this.defaultChecked);
  },
  methods: {
    onChange(checked) {
      this.defaultChecked = checked;
      let instance = checked ? this.$HttpStaging : this.$Http;
      this.$emit("update:apiInstance", instance);
    },
  },
};
</script>
