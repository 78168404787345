// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../src/assets/纹理.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".fix_border[data-v-79b8c2f0]{padding:20px 10px 20px 30px;background:#fff;border:1px solid #e8e8e8;box-shadow:0 2px 4px 0 rgba(32,0,0,.1);margin-left:-2px}.main[data-v-79b8c2f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% auto}.main-title[data-v-79b8c2f0]{text-align:center}.main-title h1[data-v-79b8c2f0]{font-weight:700;font-size:2.5rem}.main-title h3[data-v-79b8c2f0]{font-size:2rem;color:#666}.title[data-v-79b8c2f0]{padding-left:10px;height:40px;background-color:#e3e9e9;display:flex;align-items:center;justify-content:center;color:rgba(0,0,0,.65);font-weight:700}.main-content[data-v-79b8c2f0]{margin-top:60px}.chart-part[data-v-79b8c2f0]{height:500px}.chart-title[data-v-79b8c2f0]{margin-top:20px}.chart-title p[data-v-79b8c2f0]:first-child{font-weight:700;font-size:16px}.chart-title p[data-v-79b8c2f0]:nth-child(2){color:#666}.table[data-v-79b8c2f0]{text-align:center;border:1px solid #fff;width:100%;font-size:14px;font-weight:700;font-family:Microsoft YaHei}.table th[data-v-79b8c2f0]{background:#ddd}.greenTxt[data-v-79b8c2f0]{color:green}.redTxt[data-v-79b8c2f0]{color:red}", ""]);
// Exports
module.exports = exports;
