<template>
    <section>
        <a-card class="card">
            <a-form
                :form="form"
                layout="horizontal"
                :labelCol="{ span: 6 }"
                :wrapperCol="{ span: 14 }"
            >
                <a-row :gutter="5">
                    <a-col :span="6">
                        <a-form-item label="填报区间">
                            <a-input placeholder="输入填报区间" v-model="form.ExcelOpenName">
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-item label="月份">
                            <a-month-picker placeholder="选择月份" @change="selectMonthChange"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="2">
                        <a-form-item label="">
                            <a-button type="primary" @click="getList(1)"> 查询</a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :span="2">
                        <a-form-item label="">
                            <a-button type="primary" @click="doAdd"> 新增区间</a-button>
                        </a-form-item>
                    </a-col>

                </a-row>
            </a-form>
        </a-card>
        <a-card class="card" style="margin-top: 18px;">
            <a-table
                rowKey="PositionId"
                :columns="columns"
                :data-source="tableData"
                :pagination="paginationParam"
                @change="tableChange"
            >
                <span slot="YearMonth" slot-scope="YearMonth">
                        {{ YearMonth|FormatMonth }}
                </span>
                <span slot="StartDate" slot-scope="StartDate">
                        {{ StartDate|FormatTime }}
                </span>
                <span slot="EndDate" slot-scope="EndDate">
                        {{ EndDate|FormatTime }}
                </span>
                <span slot="CreateTime" slot-scope="CreateTime">
                        {{ CreateTime|FormatTime }}
                </span>
                <span slot="OpenStatus" slot-scope="record">
                    <a-switch checked-children="启用" un-checked-children="禁用"
                             :checked="record.OpenStatus === 0"
                              @change="openStatusChange(record)"></a-switch>
                </span>
                <span slot="Action" slot-scope="record">
                  <a-button
                      size="small"
                      type="primary"
                      @click="doEdit(record)"
                  >编辑</a-button
                  >
                  <a-divider type="vertical"/>
                  <a-popconfirm
                      title="确定删除吗？"
                      @confirm="() => doDel(record.Id)"
                  >
                    <a-button size="small" type="primary">删除</a-button>
                  </a-popconfirm>
                </span>
            </a-table>
        </a-card>
        <a-modal
            v-model="visible"
            :title="modalTitle"
            @ok="handleOk"
            @cancel="handleCancel"
        >
            <a-form-model
                :rules="rules"
                ref="modelForm"
                :model="modelForm"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 10 }"
            >
                <a-form-model-item label="区间名称" prop="ExcelOpenName">
                    <a-input placeholder="请输入区间名称" v-model="modelForm.ExcelOpenName"/>
                </a-form-model-item>
                <a-form-model-item label="月份" prop="YearMonth">
                    <a-month-picker placeholder="选择月份" v-model="modelForm.YearMonth"/>
                </a-form-model-item>
                <a-form-model-item label="开始时间" prop="StartDate">
                    <a-date-picker show-time placeholder="选择开始时间" v-model="modelForm.StartDate"/>
                </a-form-model-item>
                <a-form-model-item label="结束时间" prop="EndDate">
                    <a-date-picker show-time placeholder="选择结束时间" v-model="modelForm.EndDate"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <div class="foot-pad30"></div>
    </section>
</template>

<script>
import moment from "moment";

const columns = [
    {
        title: '序号',
        width: '60px',
        customRender: (text, record, index) => `${index + 1}`,
    },
    {
        title: "区间名称",
        dataIndex: "ExcelOpenName",
        key: "ExcelOpenName",
    },
    {
        title: "区间月份",
        dataIndex: "YearMonth",
        key: "YearMonth",
        scopedSlots: {customRender: 'YearMonth'},
    },
    {
        title: "开始时间",
        dataIndex: "StartDate",
        key: "StartDate",
        scopedSlots: {customRender: 'StartDate'},
    },
    {
        title: "结束时间",
        dataIndex: "EndDate",
        key: "EndDate",
        scopedSlots: {customRender: 'EndDate'},
    },
    {
        title: "创建时间",
        dataIndex: "CreateTime",
        key: "CreateTime",
        scopedSlots: {customRender: 'CreateTime'},
    },
    {
        title: "是否启用",
        key: "OpenStatus",
        scopedSlots: {customRender: 'OpenStatus'},
    },
    {
        title: "操作",
        key: "Action",
        scopedSlots: {customRender: "Action"},
    },
];

export default {
    data() {
        return {
            visible: false,
            modalTitle: "",
            //是否新增
            isAdd: null,
            form: {
                ExcelOpenName: "",
                YearMonth: ''
            },
            modelForm: {
                RowId: null,
                ExcelOpenName: "",
                YearMonth: "",
                StartDate: "",
                EndDate: "",
            },
            rules: {
                ExcelOpenName: [
                    {
                        required: true,
                        message: "请输入区间名称",
                        trigger: ["blur", "change"],
                    },
                ],
                YearMonth: [
                    {
                        required: true,
                        message: "请选择月份",
                        trigger: ["blur", "change"],
                    },
                ],
                StartDate: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: ["blur", "change"],
                    },
                ],
                EndDate: [
                    {
                        required: true,
                        message: "请选择结束时间",
                        trigger: ["blur", "change"],
                    },
                ],
            },

            //分页数据
            pageData: {},
            //表格数据
            tableData: [],
            columns,


            //分页参数
            paginationParam: {
                current: 1,
                total: 0,
                pageSize: 10,
            },
            dealers: [],
            dealerMap: {},
        };
    },
    methods: {

        //开放状态修改
        async openStatusChange(record) {
            let self = this;
            let res = await self.$Http.UpdateDuringStatus({
                Rowid: record.Id,
                // 状态，启用传0，禁用传1
                Status: record.OpenStatus == 0 ? 1 : 0
            })
            if (res.Success) {
                await self.getList(this.paginationParam.current);
                self.$message.success(res.Data);
            } else {
                self.$message.error(res.Message);
            }
        },
        selectMonthChange(date, dateStr) {
            if (date) {
                this.form.YearMonth = moment(date).format("YYYY-MM-01");
            } else {
                this.form.YearMonth = null;
            }
        },
        handleOk() {
            let self = this;
            //合法性校验
            if (self.modelForm.StartDate >= self.modelForm.EndDate) {
                self.$message.error("开始时间不能晚于结束时间");
                return
            }
            //数据格式转为后端所需格式
            let submitModel = JSON.parse(JSON.stringify(self.modelForm))
            submitModel.YearMonth = moment(submitModel.YearMonth).format("YYYY-MM-01");
            submitModel.StartDate = moment(submitModel.StartDate).format("YYYY-MM-DD HH:mm:ss");
            submitModel.EndDate = moment(submitModel.EndDate).format("YYYY-MM-DD HH:mm:ss");
            self.$refs.modelForm.validate(async (valid) => {
                if (valid) {
                    //新增or编辑
                    let res = self.isAdd
                        ? await self.$Http.InsertDuring(submitModel)
                        : await self.$Http.UpdateDuring(submitModel);
                    if (res.Success) {
                        self.$message.success(res.Data);
                        self.visible = false;
                        self.$refs["modelForm"].resetFields();
                        self.getList(1);
                    } else {
                        self.$message.error(res.Message);
                    }
                }
            });
        },
        handleCancel() {
            this.modalTitle = "";
            this.$refs["modelForm"].resetFields();
            this.modelForm.RowId = null;
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        tableChange(pagination, filters, sorter, {currentDataSource}) {
            this.paginationParam.current = pagination.current;
            this.getList(this.paginationParam.current);
        },

        //获取列表
        async getList(pageIndex) {
            let self = this;
            //将分页参数传入form对象
            self.form.pageSize = self.paginationParam.pageSize;
            self.form.pageIndex = self.paginationParam.current = pageIndex;
            let res = await self.$Http.GetDuringListPage(self.form);
            self.paginationParam.total = res.TotalCount;
            self.tableData = res.Data;
        },
        //编辑
        doEdit(record) {
            this.visible = true;
            this.modalTitle = "编辑区间";
            this.isAdd = false;
            // 编辑对象
            this.$nextTick(() => {
                this.modelForm.ExcelOpenName = record.ExcelOpenName;
                this.modelForm.YearMonth = moment(record.YearMonth);
                this.modelForm.StartDate = moment(record.StartDate);
                this.modelForm.EndDate = moment(record.EndDate);
                this.modelForm.RowId = record.Id
            })
        },
        //删除
        async doDel(id) {
            let self = this;
            let res = await self.$Http.DeleteDuring({RowId: id});
            if (res.Success) {
                self.$message.success(res.Data);
                self.getList(self.paginationParam.current);
            } else {
                self.$message.error(res.Message);
            }
        },
        doAdd() {
            this.visible = true;
            this.modalTitle = "新增区间";
            this.isAdd = true;
        },
        async getDealersByRole() {
            let res = await this.$Http.GetDealersByRole();
            if (!res.Success) {
                this.$message.error(res.Message);
                return;
            }
            let dealerCount = 0;
            this.dealers = res.Data.chain().map(p => {
                dealerCount += p.Dealers.length;
                let part = {};
                part.value = p.PartId;
                part.label = p.PartName;
                part.children = p.Dealers.chain().map(d => {
                    let dealer = {};
                    dealer.value = d.DealerCode;
                    dealer.label = d.DealerName;
                    this.dealerMap[dealer.value] = [part.value, dealer.value];
                    return dealer;
                }).value();
                return part;
            }).value();
        },
    },
    created() {
    },
    mounted() {
        this.getList(1);
    },
    filters: {
        FormatMonth: function (value) {
            return moment(value).format("YYYY-MM")
        },
        FormatDate: function (value) {
            return moment(value).format("YYYY-MM-DD")
        },
        FormatTime: function (value) {
            return moment(value).format("YYYY-MM-DD HH:mm:ss")
        }
    }
};
</script>
