<template>
  <a-row :gutter="[24,24]" id="one_3">
    <a-col :span="24">
      <a-card title="1、售后来厂及业务盈利">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_1_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_1_1_Data" chart-height="350px"/>
        </a-col>
      </a-card>
      <div id="two_3"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="2、业务收入占比">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_2_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_2_1_Data"/>
        </a-col>
      </a-card>
      <div id="there_3"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="3、售后客户维系">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_3_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_3_1_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_3_3_2_Data"/>
        </a-col>
      </a-card>
      <div id="four_3"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="4、售后维修效率">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_4_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_4_1_Data"/>
        </a-col>
      </a-card>
      <div id="five_3"></div>
    </a-col>

    <a-col :span="24">
      <a-card title="5、售后零部件库存">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_5_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_5_1_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_3_5_2_Data"/>
        </a-col>
      </a-card>
      <div id="six_3"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="6、售后水平事业">
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_6_1"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_6_1_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_3_6_2_Data"/>
        </a-col>
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_6_2"></monthReportTableComponent>
        </a-col>
        <a-col :span="12" style="clear: both">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_6_3"></monthReportTableComponent>
        </a-col>
        <a-col :span="12">
          <chartComponent :chart-data="data.chart_3_6_3_Data"/>
        </a-col>
        <a-col :span="12" style="clear: both">
          <chartComponent :chart-data="data.chart_3_6_4_Data"/>
        </a-col>
        <a-col :span="12">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_3_6_4"></monthReportTableComponent>
        </a-col>
      </a-card>
    </a-col>
    <a-anchor v-if="isClose">
      <div class="close" @click="isClose=false">
        <a-icon type="close"/>
        关闭
      </div>
      <a-anchor-link href="#one_3" title="1、售后来厂及业务盈利"/>
      <a-anchor-link href="#two_3" title="2、业务收入占比"/>
      <a-anchor-link href="#there_3" title="3、售后客户维系"/>
      <a-anchor-link href="#four_3" title="4、售后维修效率"/>
      <a-anchor-link href="#five_3" title="5、售后零部件库存"/>
      <a-anchor-link href="#six_3" title="6、售后水平事业"/>
    </a-anchor>
  </a-row>
</template>

<script>
import monthReportTableComponent from "../../../../components/monthReportTableComponent";
import chartComponent from "../../../../components/chartComponent";
import {loadReportData, getEmptyTemplate} from "@/until/reportCalculate";
import {storeReportAfterSales} from "../aftersales.js";

export default {
  name: "afterSalesComponent",
  props: {
    reportParams: {
      ReportDate: "",
      DealerCode: "",
      PartName: "",
    },
  },
  components: {
    monthReportTableComponent, chartComponent,
  },
  data() {
    return {
      isClose: true,
      emptyTemplate: getEmptyTemplate(),
      chartNameRelations: {
        "全国标杆": "NationwideStandard",
        "区域标杆": "RegionalStandard",
        "当月值": "Current",
        "上月值": "N-1",
      },
      columnsData: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
          customRender: (text, record) => {
            if (record.indentation > 0)
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            return text;
          },
        },
        {
          title: "单位",
          dataIndex: "Unit",
          key: "Unit",
        },
        {
          title: "全国标杆值",
          dataIndex: "NationwideStandardText",
          key: "NationwideStandard",
        },
        {
          title: "区域标杆值",
          dataIndex: "RegionalStandardText",
          key: "RegionalStandard",
        },
        {
          title: "当月值",
          dataIndex: "CurrentText",
          key: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.CurrentText) < parseFloat(record.NationwideStandardText);
            if (record.PercentileSort === "DESC") flag = !flag;
            if (flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑</div>;
            }
          },
        },
        {
          title: "上月值",
          dataIndex: "N-1Text",
          key: "N-1",
        },
        {
          title: "去年同期",
          dataIndex: "LastYearText",
          key: "LastYear",
        },
        {
          title: "年度累计",
          dataIndex: "CurrentYearText",
          key: "CurrentYear",
        }],
      data: storeReportAfterSales
    };
  },
  watch: {
    reportParams: {
      handler: function () {
        this.loadData()
      },
      deep: true
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await loadReportData(this, this.data, this.chartNameRelations, this.reportParams);
    },
  },

};
</script>
<style lang="less" scoped>
@import "./storeManagment.less";
</style>
