<template>
  <div>
    <a-card style="margin-bottom: 10px">
      <div style="margin-bottom: 20px">
        选择月份：
        <a-range-picker style="margin-right: 20px"
                        format="YYYY-MM" :placeholder="['开始月份', '结束月份']"
                        :value="search.dateRange" :mode="search.dataMode"
                        :allow-clear="false"
                        @change="dateHandleChange" @panelChange="dateHandlePanelChange"/>
        区域/销售店选择：
        <a-cascader style="width: 300px;margin-right: 20px" :options="dealers" :allowClear="false" change-on-select
                    placeholder="请选择/输入销售店" @change="dealerChange" v-model="search.choseDealer"
                    :show-search="true"/>
        <a-checkbox v-model="search.useAvg">试算均值</a-checkbox>
        <a-button type="primary" @click="ShowCalcStep" style="margin-left: 20px">试算</a-button>
        <a-button type="primary" @click="kpiReCalcAndSave" style="margin-left: 20px">重算</a-button>
      </div>
      <div>
        <a-textarea placeholder="请输入指标编码，多个按 , 分割" :rows="4" v-model="search.filterCodes"/>
      </div>
    </a-card>
    <a-card v-show="data!=null && data.length>0" style="margin-bottom: 10px">
      <a-tabs v-model="activeCode" @change="loadTree">
        <a-tab-pane v-for="code in data" :key="code.Code" :tab="code.Code">
          <a-descriptions bordered layout="vertical" :column="2">
            <a-descriptions-item label="指标编码">
              {{ code.Code }}
            </a-descriptions-item>
            <a-descriptions-item label="指标名称">
              {{ code.Name }}
            </a-descriptions-item>
            <a-descriptions-item label="指标公式描述">
              {{ code.FormulaDesc }}
            </a-descriptions-item>
          </a-descriptions>
        </a-tab-pane>
      </a-tabs>
      <div id="tree" style="width: 150vh;height: 600px"></div>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import ChartComponent from "@/components/chartComponent";
import * as echarts5 from 'echarts5/core';
import {
  TooltipComponent
} from 'echarts5/components';
import {
  TreeChart
} from 'echarts5/charts';
import {
  CanvasRenderer
} from 'echarts5/renderers';

echarts5.use(
    [TooltipComponent, TreeChart, CanvasRenderer]
);

export default {
  components: {ChartComponent},
  data() {
    return {
      search: {
        dateRange: [],
        dataMode: ["month", "month"],
        filterCodes: "",
        choseDealer: [],
        useAvg: false,
      },
      dealers: [],
      data: {},
      activeCode: ""
    };
  },
  created() {
    let preMonth = moment().add(-1, "M");
    this.search.dateRange = [preMonth, preMonth];
    this.getDealersByRole();
  },
  methods: {
    dateHandleChange(value) {
      this.search.dateRange = value;
    },
    dateHandlePanelChange(value, mode) {
      this.search.dateRange = value;
      this.search.dataMode = [mode[0] === "date" ? "month" : mode[0], mode[1] === "date" ? "month" : mode[1]];
    },
    getRequestParams() {
      return {
        partId: this.search.choseDealer[0],
        dealerCode: this.search.choseDealer.length < 2 ? "" : this.search.choseDealer[1],
        reportDateStart: this.search.dateRange[0].format("YYYY-MM-01"),
        reportDateEnd: this.search.dateRange[1].format("YYYY-MM-01"),
        filterCodes: this.search.filterCodes.split(',').chain().map(v => v.trim()).filter(v => v.length > 0).value(),
        useAvg: this.search.useAvg
      }
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      let dealers = [{value: "", label: "全国"}];
      res.Data.chain().each(p => {
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          return dealer;
        }).value();
        dealers.push(part);
      });
      this.dealers = dealers;
      this.search.choseDealer = [""];
    },
    dealerChange(select) {
      this.DealerCode = select[1];
    },
    async ShowCalcStep() {
      let res = await this.$Http.ShowCalcStep(this.getRequestParams());
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.data = res.Data;
      this.activeCode = res.Data[0].Code;
      this.loadTree();
    },
    async kpiReCalcAndSave() {
      let self = this;
      this.$confirm({
        title: '是否重新计算指标数据？',
        content: h => <div style="color:red;">仅在所选时间段内有校核记录的数据才会被计算，并新增或覆盖当月指标数据</div>,
        async onOk() {
          let res = await self.$Http.KpiReCalcAndSave(self.getRequestParams());
          if (!res.Success) {
            self.$message.error(res.Message);
            return;
          }
          self.$message.success("指标重算完成！已调整批次(经销商+月份)总数：" + res.Data);
        },
      });
    },
    loadTree() {
      let data = this.data.chain().find(d => d.Code === this.activeCode).value().FormulaTreeData;
      let tree = echarts5.init(document.getElementById("tree"));
      tree.clear();
      tree.setOption({
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: function (params) {
            return params.data.desc;
          },
        },
        series: [
          {
            type: 'tree',
            roam: true,
            data: [data],
            top: '10%',
            left: '30%',
            bottom: '10%',
            right: '30%',
            symbolSize: 7,
            label: {
              normal: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 15,
                rich: {
                  a: {
                    color: 'red',
                    lineHeight: 10
                  }
                }
              }
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            emphasis: {
              focus: 'descendant'
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      });
    },
  },
};
</script>
