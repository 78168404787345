<template>
  <section>
    <div class="top">
      <ul>
        <li>资产负债类科目按期末余额填列；损益类科目按本期发生额填列;</li>
        <li>金额保留两位小数；人数、个数、批次、台次、工单数取整；无数剧处请填写“0”，不可为空；</li>
        <li> 请对公式校核列（J列）未通过的数据进行自查，修正；</li>
        <li> 本页收集数据共120项。；</li>
      </ul>
    </div>
    <a-card>
      <a-row>
        <a-col :span="10">
          经销商名称：
        </a-col>
        <a-col :span="8">
          经销商代码：
        </a-col>
        <a-col :span="3">
          报表月份：
        </a-col>
      </a-row>
      <a-row>
                <a-table :columns="columns" :data-source="data" bordered>
                  <template slot="age" slot-scope="text, record, index">
                    <span @click="clickEdit(text)"> {{ text }}</span>
                  </template>
                  <template
                      slot="address"
                      slot-scope="text, record, index"
                  >
                    <div>
                      <a-input
                          style="margin: -5px 0"
                          :value="record.age"
                          @blur="e => handleChange(e.target.value, index)"
                      />

                    </div>
                  </template>
                </a-table>
      </a-row>
    </a-card>
    <a-modal v-model="isShow" :title="'编辑'+viewText">
      <a-input placeholder="请输入内容" :value="viewText"/>
    </a-modal>
  </section>
</template>


<script>
const columns = [
  {
    title: "name",
    dataIndex: "name",
    width: "25%",
  },
  {
    title: "age",
    dataIndex: "age",
    width: "15%",
    scopedSlots: { customRender: "age" },
  },
  {
    title: "address",
    dataIndex: "address",
    width: "40%",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "operation",
    dataIndex: "operation",
  },
];
const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
export default {
  props: {},
  data () {
    this.cacheData = data.map(item => ({ ...item }));
    return {
      data,
      columns,
      editingKey: "",
      isShow: false,
      viewText: "",
      testData: [],
    };
  },
  created () {
    // console.log('3');
    this.getData();
  },
  methods: {
    async getData () {
      let formData = {
        //   pageSize: 10,
        //   pageIndex: 1,
        DealerCode: "whcj",
        yearMonth: "2020-12-01",
        //  validatestatus: 0,
        //  auditStatus: 0,
      };
      let res = await this.$Http.GetErrorLogs(formData);
      this.testData = res.Data;
     // console.log(res.Data);
      this.$message.error(res.Success.toString());
    },
    handleChange (value, key) {
     // console.log(value + ":" + key);
    },
    /**/
    /*点击编辑*/
    clickEdit (text) {
      this.isShow = true;
      this.viewText = text;
    },
  },
};
</script>

<style scoped>
.top {
  background: #E6F7FF;
  line-height: 30px;
  padding: 5px 24px;
  border: 1px solid #BAE7FF;

}
</style>
