<template>
  <div class="bg">
    <a-row :gutter="[24,24]">
      <a-col :sm="24" :md="12" :xl="6">
        <div style="border-left:4px solid #5AAAFF; " class="bg-white">
          <MainParent title_b="新车总销量" title_s="上月新车总销量" :total="indexValue.A0302001"/>
        </div>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6">
        <div style="border-left:4px solid #FD595A; " class="bg-white">
          <MainParent title_b="新车库存周转率" title_s="上月新车库存周转率" :total="indexValue.A0404002"/>
        </div>
      </a-col>

      <a-col :sm="24" :md="12" :xl="6">
        <div style="border-left:4px solid #FFC440; " class="bg-white">
          <MainParent title_b="总毛利" title_s="上月总毛利" :total="indexValue.C0407001"/>
        </div>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6">
        <div style="border-left:4px solid #856AFF; " class="bg-white">
          <MainParent title_b="税前利润" title_s="上月税前利润" :total="indexValue.C0408004"/>
        </div>
      </a-col>
    </a-row>
    <div style="margin-top: 10px;margin-bottom: 10px;height: 40px;line-height: 40px; background: #FFFBE6;border: 1px solid #FFE58F;
border-radius:4px;"
    >
      <p class="tips">
        <a-icon type="info-circle" style="color: #ffc100;font-size: 20px;padding-left: 6px;" theme="filled"/>
        <span style="font-weight: bold;padding-left: 6px;">温馨提示:</span>
        数据收集表模版已更新，请点击下方“下载填报模板”获取最新模版进行数据上传，否则将导致上传失败！
      </p>
    </div>
    <!--    <a-row :gutter="[24,24]">
          <a-col :span="24">
            <a-card>
              <bottom-chart/>
            </a-card>
          </a-col>
        </a-row>-->
    <a-row :gutter="[24,24]">
      <a-col :span="16">
        <a-card>
          <div class="title">
            <span>数据上传  </span>
            <!-- <a class="float-right" @click="downTmp('BDM系统基础数据填报模板.xlsx')" target="_blank">下载填报模板</a>-->
            <!--  月份选择暂时只用在测试端           -->
<!--            <div class="float-right" style="margin-right: 10px">
              <a-month-picker v-model="planDate" placeholder="选择月份"/>
            </div>-->
            <p style="padding-left: 92px;"> 所属销售店：<b style="font-size: 18px;color: #24619e">{{
                GetThisCompanData.CompanyNameCN }}</b>
            </p>
          </div>
          <!--去掉开始按钮 直接上传 ：before-upload="beforeUpload"-->

          <a-upload-dragger class="uploadStyle" name="file" :showUploadList="false" :customRequest="handleUpload">
            <p>将文件拖拽至此上传</p>
            <div class="">请上传Excel格式文件，需要支持HTML</div>
          </a-upload-dragger>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card style="min-height: 140px;">
          <div class="title"><span>上传结果</span></div>
          <div style="border: 1px solid #DDDDDD;min-height: 90px;padding: 10px;">
            <div v-for="(item,index) in fileList" :key="index">
              {{ item.name }}
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-modal :width="800" v-model="isFile" title="错误列表" :footer="null" :maskClosable="true">
      <div style="height: 500px;overflow:auto;">
        <a-table :columns="errorColumns" :data-source="errorData" :row-key="(record)=>record"></a-table>
      </div>
      <p style="padding-top: 30px;text-align: center">
        <a-button type="primary" @click="FileClick">知道了</a-button>
      </p>
    </a-modal>
  </div>
</template>

<script>

import MainParent from "./card-pand/main-paent";
import MiniChartData from "./card-pand/miniChart";
import MapChart from "./card-pand/mapChart";
import BottomChart from "./card-pand/bottomChart";
import moment from "moment";
import axios from "axios";

export default {
  name: "main_n",
  components: { MainParent, MiniChartData, MapChart, BottomChart },

  data() {
    return {
      errorColumns: [
        {
          title: "序号",
          width: "60px",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "指标名称",
          dataIndex: "ErrVarName",
          key: "ErrVarName",
        }, {
          title: "错误详情",
          dataIndex: "ErrDesc",
          key: "ErrDesc",
        },
      ],
      fileList: [{
        name: "",
      }],
      isFile: false,
//      fileData: [],
      errorData: [],
      GetThisCompanData: {},
      indexValue: {
        C0407001: "",
        C0408004: "",
        A0302001: "",
        A0404002: "",
      },
      downModelData: "",
      planDate: null,
    };
  },
  created() {
    //  console.log(this.fileList);
    this.setIndexValue({});
    this.GetMainIndexValue();
    this.GetData();
  },
  mounted() {

  },
  methods: {
    setIndexValue(value) {
      this.indexValue = new Proxy(value, {
        get: function(obj, prop) {
          return prop in obj ? obj[prop] : "-";
        },
      });
    },
    async GetMainIndexValue() {
      let res = await this.$Http.GetMainIndexValue({ codes: ["A0302001", "A0404002", "C0407001", "C0408004"] });
      if(!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.setIndexValue(res.Data);
    },
    async GetData() {
      let res = await this.$Http.GetThisCompany();
      if(res.Success) {
        this.GetThisCompanData = res.Data[0];
      }
    },
    FileClick() {
      this.isFile = false;
      window.open("ddataList", "_top");
    },
    async handleUpload(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      let res = await this.$Http.uploadFile(formData);
      if(res.Success) {
        this.$message.success(res.Data);
        setTimeout(() => {
          window.open("ddataList", "_top");
        }, 1000);
      } else {
        if(res.Message === "object") {
          this.$message.success("上传文件有错误，请查看！");
          this.isFile = true;
          this.errorData = res.Data;
          setTimeout(() => {
            this.fileList.push({
              name: file.file.name,
            });
          }, 1000);
        } else {
          this.$message.error(res.Message);
        }
      }
    },
    async downTmp(val) {
       let res = await this.$Http.FileShareLink({
        objectkey: val,
      });
      if (res.Success) {
        window.open(res.Data);
      }
      //  以下方法目前只用在测试端
      /*if(this.planDate) {
        axios({
          url: "/apis/DealerKpi/KpiData/GetDataWriteExcel?dealerCode=" + this.GetThisCompanData.CompanyCode + "&yearMonth=" + moment(this.planDate).format("YYYY-MM-01").toString(),
          method: "get",
          //  data:{},
          responseType: "blob",
        }).then(response => {
          const blob = response.data;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.GetThisCompanData.CompanyNameCN + moment(this.planDate).format("YYYY-MM-01")}填报模板.xlsx`;
          link.click();
        }, error => {
          this.$message.error("导出excel出错");
        });
      } else {
        this.$message.error("月份必须选择!");
      }*/
    },

  },
};

</script>

<style lang="less" scoped>

.tips {
  color: #444;
  font-weight: 500;
  font-size: 14px;
  }

.title {
  height: 40px;

  span {
    border-left: 4px solid #24619e;
    display: block;
    line-height: 18px;
    padding-left: 4px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    float: left;
    }

  a {
    display: block;
    float: right;
    background: #24619e;
    padding: 5px 15px;
    color: #ffffff;
    }
  }

.checkbox {
  text-align: center;
  background: #fafafa;
  border-radius: 2px;
  padding-bottom: 10px;
  padding-top: 10px;
  }

.ant-upload-drag {
  border: 0 !important;
  }


.uploadStyle {
  div {
    border: 0 !important;
    }

  p {
    font-size: 24px;
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    }

  div:first-child {
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    }

  }

.card-padding {
  font-family: HelveticaNeue, sans-serif;
  padding: 20px 14px 5px 14px;
  border: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    border-color: rgba(0, 0, 0, .3);
    }

  div:first-child {
    color: rgba(0, 0, 0, .45);
    font-family: PingFangSC-Regular, PingFang SC, serif;

    span {
      float: right;
      }
    }

  div:nth-child(2) {
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    font-weight: 400;
    text-shadow: 0px 2px 4px rgba(32, 0, 0, 0.1);
    font-family: HelveticaNeue, sans-serif;
    }

  div:nth-child(3) {
    overflow: hidden;

    p {
      float: left;
      width: 50%;

      span {
        font-size: 14px;
        }

      span.D6000F {
        color: #24619e;
        }

      span.C7BD250 {
        color: #7BD250;
        }

      }
    }

  div:nth-child(4) {
    //  border-top: 1px solid #DDDDDD;
    color: rgba(0, 0, 0, 65);
    line-height: 32px;
    font-size: 14px;

    span {
      color: rgba(0, 0, 0, .85);
      }
    }
  }

.chart {
  width: 100%;
  height: 70px;
  }

</style>
