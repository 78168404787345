<template>
    <a-row :gutter="[0,10]">
        <!-- 进销存   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">进销存({{ Area }})</div>
                <table>
                    <thead>
                    <th>区间</th>
                    <th colspan="2">上月底</th>
                    <th colspan="11">当前值</th>
                    </thead>
                    <tr>
                        <th v-for="(item,i) in tableData1.columnsData" :key="i++">
                            <div>
                                <span>{{ item.title }}</span>
                                <span v-if="i>1">
                                   <a-icon :style="'color:'+ (item.up ? '#000;':'')" type="caret-up" @click="sortClickBy('up',i-1)"/>
                                   <a-icon :style="'color:'+ (item.down ? '#000;':'')" type="caret-down" @click="sortClickBy('down',i-1)"/>
                               </span>
                            </div>
                        </th>
                    </tr>
                    <tbody>
                    <tr>
                        <th style="background: #fff !important;" v-for="(item,i) in tableData1.columnsData" :key="i++">
                            {{ item.unit }}
                        </th>
                    </tr>
                    <tr v-for="(item,i) in tableData1.data" :key="i++">
                        <td>
                            {{ item["carName"] === "均值" ? "全国" : item["carName"] }}
                        </td>
                        <td> {{ ThousandYuan(item["0"]) }}</td>
                        <td> {{ ThousandYuan(item["1"]) }}</td>
                        <td> {{ ThousandYuan(item["2"]) }}</td>
                        <td> {{ ThousandYuan(item["3"]) }}</td>
                        <td> {{ ThousandYuan(item["4"]) }}</td>
                        <td> {{ percentage(item["5"]) }}</td>
                        <td> {{ ThousandYuan(item["6"]) }}</td>
                        <td> {{ ThousandYuan(item["7"]) }}</td>
                        <td> {{ ThousandYuan(item["8"]) }}</td>
                        <td> {{ ThousandYuan(item["9"]) }}</td>
                        <td style="width: 8%"> {{ percentage(item["10"]) }}</td>
                        <td> {{ ThousandYuan(item["11"]) }}</td>
                        <td style="width: 8%"> {{ ThousandYuan(item["12"]) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </a-col>
        <!-- 销售效率(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">销售效率({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_1"/>
            </div>
        </a-col>
        <!--   四大通道占比      -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">四大通道占比</div>
                <main-chart :chart-data="chartData.chartData1"/>
            </div>
        </a-col>
        <!-- 通道-展厅效率(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">通道-展厅效率({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_2"/>
            </div>
        </a-col>
        <!--   店均展厅客流及展厅成交率     -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">店均展厅客流及展厅成交率</div>
                <main-chart :chart-data="chartData.chartData2"/>
            </div>
        </a-col>
        <!--   展厅首次客流、展厅再次客流及二次进店率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">展厅首次客流、展厅再次客流及二次进店率</div>
                <main-chart :chart-data="chartData.chartData3"/>
            </div>
        </a-col>
        <!-- 通道-IDCC效率(东北)  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">通道-IDCC效率({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_3"/>
            </div>
        </a-col>
        <!--   线索分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">线索分析</div>
                <main-chart :chart-data="chartData.chartData4"/>
            </div>
        </a-col>
        <!--   店均IDCC客流及邀约到店率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">店均IDCC客流及邀约到店率</div>
                <main-chart :chart-data="chartData.chartData5"/>
            </div>
        </a-col>
        <!--   IDCC订单、到店成交率及转化率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">店均IDCC订单、到店成交率及转化率</div>
                <main-chart :chart-data="chartData.chartData6"/>
            </div>
        </a-col>
        <!-- 通道-二网效率(东北)  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">通道-二网效率({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_4"/>
            </div>
        </a-col>
        <!--   二网店数及销量  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">二网店数及销量</div>
                <main-chart :chart-data="chartData.chartData7"/>
            </div>
        </a-col>
        <!-- 毛利构成(东北)  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">毛利构成({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_5"/>
            </div>
        </a-col>
        <!--   单车毛利分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">单车毛利分析</div>
                <main-chart :chart-data="chartData.chartData8"/>
            </div>
        </a-col>
    </a-row>
</template>

<script>
import { ThousandYuan, percentage } from "@/until/option";
import underscore from "underscore";
import { getColumnsData, getMyChartData, getMyTableData, sysData } from "@/views/profitReport/untilProfit";
import profitTable from "@/components/profitTable";
import {
 legend, grid, xAxis, yAxisDefault, yAxisRight, seriesLine, seriesBar,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";

export default {
 name: "oneOperation",
 components: { profitTable, mainChart },
 props: {
  dataParams: {},
  allData: {},
 },
 data() {
  return {
   ThousandYuan, percentage,
   Area: this.dataParams.PartName.replace("营销中心", ""),
   columnsData: getColumnsData(this.dataParams.ReportDate),
   tableData1: { // 地区表格
    columnsData: [
     {
      title: "区域",
      unit: "单位",
     },
     {
      title: "库存",
      unit: "台",
      up: false,
      down: false,
      code: "A1402185",
     },
     {
      title: "剩余订单",
      unit: "个",
      up: false,
      down: false,
      code: "A1402205",
     },
     {
      title: "总线索",
      unit: "条",
      up: false,
      down: false,
      code: "A1302115",
     },
     {
      title: "总客流",
      unit: "个",
      up: false,
      down: false,
      code: "A1303045",
     },
     {
      title: "新增订单",
      unit: "个",
      up: false,
      down: false,
      code: "A1303071",
     },
     {
      title: "到店成交率",
      unit: "%",
      up: false,
      down: false,
      code: "A1303083",
     },
     {
      title: "退订数",
      unit: "个",
      up: false,
      down: false,
      code: "A1402135",
     },
     {
      title: "提车实绩",
      unit: "台",
      up: false,
      down: false,
      code: "A1402155",
     },
     {
      title: "终端实绩",
      unit: "台",
      up: false,
      down: false,
      code: "A0302001",
     },
     {
      title: "库存",
      unit: "台",
      up: false,
      down: false,
      code: "A0402006",
     },
     {
      title: "剩余订单匹配率",
      unit: "%",
      up: false,
      down: false,
      code: "A1402235",
     },
     {
      title: "终端让价",
      unit: "元",
      up: false,
      down: false,
      code: "A1403021",
     },
     {
      title: "单车裸车毛利",
      unit: "元",
      up: false,
      down: false,
      code: "A0106026",
     },
    ],
    data: [],
   },
   tableData: {
    table_1: {
     data: [],
     kpiCodes: [
      {
       title: "店均单店效率",
       unit: "台",
       code: "A0302001",
      },
     ],
    },
    table_2: {
     data: [],
     kpiCodes: [
      {
       title: "展厅客流",
       unit: "个",
       code: "A1303043",
      }, {
       title: "展厅订单",
       unit: "个",
       code: "A1303080",
      },
     ],
    },
    table_3: {
     data: [],
     kpiCodes: [
      {
       title: "总线索",
       unit: "条",
       code: "A1302115",
      }, {
       title: "垂媒线索",
       unit: "条",
       code: "E1202091+E1202092+E1202093+E1202094",
      }, {
       title: "线索有效率",
       unit: "%",
       code: "A1303036",
      }, {
       title: "有效线索",
       unit: "条",
       code: "A1302019",
      }, {
       title: "邀约到店率",
       unit: "%",
       code: "A1303024",
      }, {
       title: "IDCC客流",
       unit: "个",
       code: "A1302028",
      }, {
       title: "转化率",
       unit: "%",
       code: "A1303032",
      }, {
       title: "订单",
       unit: "个",
       code: "A1302032",
      }, {
       title: "终端",
       unit: "台",
       code: "A0302022",
      },
     ],
    },
    table_4: {
     data: [],
     kpiCodes: [
      {
       title: "二网店数",
       unit: "个",
       code: "C0303031",
      }, {
       title: "二网销量",
       unit: "台",
       code: "A0302023",
      },
     ],
    },
    table_5: {
     data: [],
     kpiCodes: [
      {
       title: "单车综合毛利",
       unit: "元",
       code: "A0107004",
      }, {
       title: "单车祼车毛利",
       unit: "元",
       code: "A0106026",
      }, {
       title: "单车销售返利",
       unit: "元",
       code: "A0105071",
      }, {
       title: "单车水平事业毛利",
       unit: "元",
       code: "A0105072",
      },
     ],
    },
   },
   chartData: {
    chartData1: {
     columns: ["区域", "展厅占比", "IDCC占比", "二网占比", "大客户占比"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "A0304024" },
      { unit: "%", code: "A0304025" },
      { unit: "%", code: "A0304026" },
      { unit: "%", code: "A0304027" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["展厅占比", "IDCC占比", "二网占比", "大客户占比"] },
      yAxisType: ["0.[0]"],
      yAxisName: ["%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return (params.data) + "%";
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault],
      series: [seriesBar, seriesBar, seriesBar, seriesBar],
     },
    },
    chartData2: {
     columns: ["区域", "店均展厅客流", "展厅成交率"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "A1303043" },
      { unit: "%", code: "A1303083" },
     ],
     setting: {
      type: "histogram",
      showLine: ["展厅成交率"],
      axisSite: { right: ["展厅成交率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData3: {
     columns: ["区域", "展厅首次客流", "展厅再次客流", "展厅二次进店率"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "A1302012" },
      { unit: "个", code: "A1302013" },
      { unit: "%", code: "A1303007" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["展厅首次客流", "展厅再次客流"] },
      showLine: ["展厅二次进店率"],
      axisSite: { right: ["展厅二次进店率"] },
      yAxisType: ["0", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesLine],
     },
    },
    chartData4: {
     columns: ["车型", "汽车之家", "易车", "懂车帝", "太平洋", "广宣线索", "自店", "其他", "线索有效率"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "E1202091" },
      { unit: "个", code: "E1202092" },
      { unit: "个", code: "E1202093" },
      { unit: "个", code: "E1202094" },
      { unit: "个", code: "E1202095" },
      { unit: "个", code: "E1202099" },
      { unit: "个", code: "E1202096+E1202097+E1202098" },
      { unit: "%", code: "A1303036" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["汽车之家", "易车", "懂车帝", "太平洋", "广宣线索", "自店", "其他"] },
      showLine: ["线索有效率"],
      axisSite: { right: ["线索有效率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [
       seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesLine,
      ],
     },
    },
    chartData5: {
     columns: ["区域", "店均IDCC客流", "邀约到店率"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "A1302028" },
      { unit: "%", code: "A1303024" },
     ],
     setting: {
      type: "histogram",
      showLine: ["邀约到店率"],
      axisSite: { right: ["邀约到店率"] },
      yAxisType: ["0.[00]", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData6: {
     columns: ["区域", "店均IDCC订单", "到店成交率", "总线转化率"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "A1302032" },
      { unit: "%", code: "A1303028" },
      { unit: "%", code: "A1303032" },
     ],
     setting: {
      type: "histogram",
      showLine: ["到店成交率", "总线转化率"],
      axisSite: { right: ["到店成交率", "总线转化率"] },
      yAxisType: ["0", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine, seriesLine],
     },
    },
    chartData7: {
     columns: ["区域", "店均专营店", "二网店数", "合作二网店数", "店均二网销量"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "C0302121" },
      { unit: "个", code: "C0302122" },
      { unit: "个", code: "C0302123" },
      { unit: "台", code: "A0303006" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["店均专营店", "二网店数", "合作二网店数"] },
      showLine: ["店均二网销量"],
      axisSite: { right: ["店均二网销量"] },
      yAxisType: ["0", "0"],
      yAxisName: ["个", "台"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesLine],
     },
    },
    chartData8: {
     columns: ["区域", "单车祼车毛利", "单车销售返利", "单车水平事业毛利", "销售业务毛利率"],
     rows: [],
     kpiCodes: [
      { unit: "元", code: "A0106026" },
      { unit: "元", code: "A0105071" },
      { unit: "元", code: "A0105072" },
      { unit: "%", code: "A0107002" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["单车祼车毛利", "单车销售返利", "单车水平事业毛利"] },
      showLine: ["销售业务毛利率"],
      axisSite: { right: ["销售业务毛利率"] },
      yAxisType: ["0", "0.[0]"],
      yAxisName: ["元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesLine],
     },
    },
   },
  };
 },
 methods: {
  getTableData() {
   /*table_1*/
   this.tableData1.data = [];
   this.tableData1.sumData = [];
   this.dataParams.AreaList.map((d, i) => {
    let arr = {};
    arr["carName"] = d.PartName;
    underscore.range(13).map(m => {
     arr[m] = 0;
     let ab = "全国";
     if(i < 14) ab = d.PartId;
     // 全国值
     if(m < 2) {
      // 上月值
      if(this.allData[d.PartId] !== undefined) {
       arr[m] = sysData(this.allData[ab], "N-1", this.tableData1.columnsData[m + 1].code);
      }
     } else {
      // 当月值
      if(this.allData[d.PartId] !== undefined) {
       arr[m] = sysData(this.allData[ab], "N-0", this.tableData1.columnsData[m + 1].code);
      }
     }
    });
    this.tableData1.data.push(arr);
   });
   /* tableData */
   this.columnsData = getColumnsData(this.dataParams.ReportDate);
   getMyTableData(this.tableData, this.columnsData, this.allData[this.dataParams.PartId]);
  },
  getChartData() {
   getMyChartData(this.allData, this.chartData, this.dataParams.AreaList);
  },
  sortClickBy(icon, sort) {
   this.tableData1.columnsData.forEach(d => {
    d.up = false;
    d.down = false;
   });
   this.tableData1.columnsData[sort][icon] = true;
   this.tableData1.data = underscore.sortBy(this.tableData1.data, sort - 1);
   if(icon === "down") this.tableData1.data.reverse();
  },
  getArea() {
   this.Area = this.dataParams.PartName.replace("营销中心", "");
  },
 },
 created() {
  this.getTableData();
  this.getChartData();
  this.getArea();
 },
 watch: {
  allData: {
   handler: function() {
    this.getTableData();
    this.getChartData();
    this.getArea();
   },
   deep: true,
  },
 },
};
</script>

<style lang="less" scoped>
@import "../../profitReport/profit";

table {
  width: 100%;border: 1px solid #ddd;

  thead {
    text-align: center;color: #fff;
    background: #33554A;height: 40px;line-height: 40px;

    th {
      border-right: 1px solid #DDDDDD;
      // text-align: right;
      /*   &:first-child{
           text-align: center;
           }*/

      &:last-child {
        border-right: 0;
        }
      }
    }

  tr {height: 40px;line-height: 40px;background: #fff;

    th {
      background: #e8e8e8;color: #333;font-weight: 500;
      text-align: center;position: relative;
      border-right: 1px solid #ddd;padding-right: 4px;

      &:first-child {
        text-align: left;padding-left: 1vw;
        }

      div {
        height: 28px;
        line-height: 28px;


        span {
          cursor: pointer;

          &:last-child {
            width: 10px;
            height: 20px;

            i {
              position: absolute;top: 6px;color: rgb(191, 191, 191);

              &:last-child {
                top: 15px;
                }
              }
            }
          }
        }
      }

    td {
      width: 6%;text-align: right;
      border-right: 1px solid #ddd;padding-right: 4px;

      &:first-child {
        text-align: left;padding-left: 1vw;
        }

      &:last-child {
        border-right: 0;
        }

      }

    &:nth-child(even) {
      background: #E6ECEC;
      border: 1px solid #DDDDDD;
      }
    }

  }
</style>
