<template>
  <section>
    <a-card class="card">
      <a-form
        :form="form"
        layout="horizontal"
        :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="小区名称">
              <a-input placeholder="小区名称" v-model="form.areaName">
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="小区编号">
              <a-input placeholder="小区编号" v-model="form.areaCode" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="getList(1)"> 查询 </a-button>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="add"> 新增小区 </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card class="card" style="margin-top: 18px;">
      <a-table
        rowKey="AreaId"
        :columns="columns"
        :data-source="tableData"
        :pagination="paginationParam"
        @change="tableChange"
      >
        <span slot="Action" slot-scope="record">
          <a-button size="small" type="primary" @click="doEdit(record.AreaId)"
            >编辑</a-button
          >
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除吗？"
            @confirm="() => doDel(record.AreaId)"
          >
            <a-button size="small" type="primary">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="visible"
      :title="modalTitle"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        :rules="rules"
        ref="modelForm"
        :model="modelForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 10 }"
      >
        <a-form-model-item label="小区名称" prop="areaName">
          <a-input v-model="modelForm.areaName" />
        </a-form-model-item>
        <a-form-model-item label="大区名称" prop="partId">
          <a-select v-model="modelForm.partId">
            <a-select-option
              v-for="(item, i) in partList"
              :key="i"
              :value="item.PartId"
            >
              {{ item.PartName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="排序号" prop="sort">
          <a-input-number v-model="modelForm.sort" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
const columns = [
  {
    title: '序号',
    width: '60px',
    customRender:(text,record,index)=>`${index+1}`,
  },
  {
    title: "小区名称",
    dataIndex: "AreaName",
    key: "AreaName",
  },
  {
    title: "大区名称",
    dataIndex: "PartName",
    key: "PartName",
  },
  {
    title: "排序号",
    dataIndex: "Sort",
    key: "Sort",
  },
  {
    title: "操作",
    key: "Action",
    scopedSlots: { customRender: "Action" },
  },
];

export default {
  data() {
    return {
      visible: false,
      modalTitle: "",
      //是否新增
      isAdd: null,
      form: {
        areaName: "",
        areaCode: "",
      },
      modelForm: {
        areaId: "",
        areaName: "",
        sort: null,
        partId: "",
      },
      partList: [],
      //分页数据
      pageData: {},
      //表格数据
      tableData: [],
      columns,
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      rules: {
        areaName: [
          {
            required: true,
            message: "请输入小区名称",
            trigger: ["blur", "change"],
          },
        ],
        partId: [
          {
            required: true,
            message: "请选择大区名称",
            trigger: ["blur", "change"],
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    add() {
      this.visible = true;
      this.modalTitle = "新增小区";
      this.isAdd = true;
      this.getSort();
    },
    async getSort() {
      let self = this;
      //获取排序号
      let res = await self.$Http.GetAreaSortIndex();
      self.modelForm.sort = res.Data;
    },
    handleOk() {
      let self = this;
      self.$refs.modelForm.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res = self.isAdd
            ? await self.$Http.InsertArea(self.modelForm)
            : await self.$Http.EditArea(self.modelForm);
          if (res.Success) {
            self.$message.success(res.Data);
            self.visible = false;
            this.$refs["modelForm"].resetFields();
            self.getList(1);
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
    handleCancel() {
      this.modalTitle = "";
      this.$refs["modelForm"].resetFields();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.getList(this.paginationParam.current);
    },

    //获取列表
    async getList(pageIndex) {
      let self = this;
      //将分页参数传入form对象
      self.form.pageSize = self.paginationParam.pageSize;
      self.form.pageIndex = pageIndex;
      self.paginationParam.current=pageIndex;
      let res = await self.$Http.GetAreaListByPage(self.form);
      self.paginationParam.total = res.TotalCount;
      self.tableData = res.Data;
    },
    //编辑
    doEdit(id) {
      this.visible = true;
      this.modalTitle = "编辑小区";
      this.isAdd = false;
      this.getInfo(id);
      // this.$router.push("/areaMannage/edit?areaId=" + id);
    },
    async getInfo(id) {
      let self = this;
      //获取当前数据
      let res = await self.$Http.GetAreaInfoById({
        areaId: id,
      });
      if (res.Data.length > 0) {
        self.modelForm.areaId = res.Data[0].AreaId;
        self.modelForm.areaName = res.Data[0].AreaName;
        self.modelForm.sort = res.Data[0].Sort;
        self.modelForm.partId = res.Data[0].PartId;
      }
    },
    //删除
    async doDel(id) {
      let self = this;
      let res = await self.$Http.DeleteArea({ areaId: id });
      if (res.Success) {
        self.$message.success(res.Data);
        self.getList();
      } else {
        self.$message.error(res.Message);
      }
    },
     //获取大区列表
    async getPartList() {
      let self = this;
      let res = await self.$Http.GetPart();
      self.partList = res.Data;
    },
  },
  created() {},
  mounted() {
    this.getList(1);
    this.getPartList();
  },
};
</script>
