<template>
    <table>
        <thead>
        <th v-for="(item,k) in tableData.columnsData" :key="k++">
            {{ item }}
        </th>
        </thead>
        <tbody>
        <tr v-for="(item,t) in tableData.data" :key="t++">
            <td v-show="item.rowspan > 1" :rowspan="item.rowspan" v-html="item.title">
            </td>
            <td v-for="(data,j) in item.val" :key="j++">
                {{ data }}
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
 name: "markerYearTable",
 props: {
  tableData: {
   columnsData: Array,
   data: Array,
  },
 },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;border: 1px solid #ddd;

  thead {
    text-align: center;color: #fff;
    background: #33554A;height: 40px;line-height: 40px;

    th {
      border-right: 1px solid #DDDDDD;
      text-align: center;

      &:first-child {
        min-width: 50px;
        max-width: 100px;
        }

      &:last-child {
        border-right: 0;
        // min-width: 100px;
        }
      }
    }


  tbody {
    tr {
      height: 40px;line-height: 40px;

      td {
        width: 6%;text-align: right;
        border-right: 1px solid #ddd;padding-right: 4px;

        &:first-child {
          text-align: center;padding-left: 1vw;background: #fff;border-bottom: 1px solid #ddd;
          }

        &:nth-child(2) {
          text-align: center;
          }

        &:last-child {
          border-right: 0;
          }

        }

      &:nth-child(even) {
        td{
          background: #E6ECEC;
          border: 1px solid #DDDDDD;

          }
        }
      }
    }
  }
</style>
