import {
  yAxisRightUnit, legend, smooth, yAxisDefaultUnit,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
const colors =[];
const barMaxWidth = "35%";
const xAxis =[
  {
    type: "category",
    axisLabel:{
      interval:0,
    },
    axisLine:{
      show:true,
      lineStyle:{ type:"sold",width:1.3 },
    },
  },
];
let storeReportSales = {
  tableData_2_1_1: {
    table: [{
      name: "新车销量",
      kpiCode: "A0303004",
    }, {
      name: "销售业务毛利率",
      kpiCode: "A0107002",
    }, {
      name: "其中：新车销售裸车毛利率",
      kpiCode: "A0106023",
    }, {
      name: "返利后毛利率",
      kpiCode: "A0107001",
      indentation: 3,
    }, {
      name: "销售水平事业毛利率",
      kpiCode: "A0105023",
      indentation: 3,
    }],
    data: [],
  },
  tableData_2_1_2: {
    table: [{
      name: "平均单车销售综合业务毛利",
      kpiCode: "A0107004",
    }, {
      name: "平均单车新车裸车毛利",
      kpiCode: "A0106026",
    }, {
      name: "平均单车销售返利",
      kpiCode: "A0105071",
    }, {
      name: "平均单车销售水平事业毛利",
      kpiCode: "A0105072",
    }],
    data: [],
  },
  tableData_2_3_1: {
    table: [{
      name: "当月新增潜客数占新车销量比",
      kpiCode: "A0304032",
    }, {
      name: "试乘试驾成交率",
      kpiCode: "A1303004",
    }, {
      name: "保有客户推介转化率",
      kpiCode: "A0304030",
    }, {
      name: "展厅首次客流成交率",
      kpiCode: "A1303081",
    }, {
      name: "展厅再次客流成交率",
      kpiCode: "A1303082",
    }, {
      name: "展厅首次客流建档率",
      kpiCode: "A1303006",
    }, {
      name: "展厅二次进店率",
      kpiCode: "A1303007",
    }, {
      name: "iDCC有效线索率",
      kpiCode: "A1303036",
    }, {
      name: "iDCC邀约到店率",
      kpiCode: "A1303024",
    }, {
      name: "iDCC邀约到店成交率",
      kpiCode: "A1303028",
    }, {
      name: "iDCC线索转化率",
      kpiCode: "A1303032",
    }],
    data: [],
  },
  tableData_2_4_1: {
    table: [{
      name: "GS3裸车毛利率",
      kpiCode: "A0105002",
    }, {
      name: "GS3返利后毛利率",
      kpiCode: "A0106047",
    }, {
      name: "GS3新车销量占新车总销量比",
      kpiCode: "A0304004",
    }, {
      name: "期末GS3库存台次占期末新车库存台次比",
      kpiCode: "A0404061",
    }],
    data: [],
  },
  tableData_2_4_2: {
    table: [{
      name: "GS3 Power裸车毛利率",
      kpiCode: "A0105003",
    }, {
      name: "GS3 Power返利后毛利率",
      kpiCode: "A0106048",
    }, {
      name: "GS3 Power新车销量占新车总销量比",
      kpiCode: "A0304005",
    }, {
      name: "期末GS3 Power库存台次占期末新车库存台次比",
      kpiCode: "A0404062",
    }],
    data: [],
  },
  tableData_2_4_3: {
    table: [{
      name: "GS4裸车毛利率",
      kpiCode: "A0105004",
    }, {
      name: "GS4返利后毛利率",
      kpiCode: "A0106049",
    }, {
      name: "GS4新车销量占新车总销量比",
      kpiCode: "A0304006",
    }, {
      name: "期末GS4库存台次占期末新车库存台次比",
      kpiCode: "A0404063",
    }],
    data: [],
  },
  tableData_2_4_4: {
    table: [{
      name: "GS4 PHEV裸车毛利率",
      kpiCode: "A0105005",
    }, {
      name: "GS4 PHEV返利后毛利率",
      kpiCode: "A0106050",
    }, {
      name: "GS4 PHEV新车销量占新车总销量比",
      kpiCode: "A0304007",
    }, {
      name: "期末GS4 PHEV库存台次占期末新车库存台次比",
      kpiCode: "A0404064",
    }],
    data: [],
  },
  tableData_2_4_5: {
    table: [{
      name: "GS4 COUPE裸车毛利率",
      kpiCode: "A0105006",
    }, {
      name: "GS4 COUPE返利后毛利率",
      kpiCode: "A0106051",
    }, {
      name: "GS4 COUPE新车销量占新车总销量比",
      kpiCode: "A0304008",
    }, {
      name: "期末GS4 COUPE库存台次占期末新车库存台次比",
      kpiCode: "A0404065",
    }],
    data: [],
  },
  tableData_2_4_6: {
    table: [{
      name: "GS4 PLUS裸车毛利率",
      kpiCode: "A0105007",
    }, {
      name: "GS4PLUS返利后毛利率",
      kpiCode: "A0106052",
    }, {
      name: "GS4 PLUS新车销量占新车总销量比",
      kpiCode: "A0304009",
    }, {
      name: "期末GS4PLUS库存台次占期末新车库存台次比",
      kpiCode: "A0404066",
    }],
    data: [],
  },
  tableData_2_4_7: {
    table: [{
      name: "GS7裸车毛利率",
      kpiCode: "E0105008",
    }, {
      name: "GS7返利后毛利率",
      kpiCode: "A0106053",
    }, {
      name: "GS7新车销量占新车总销量比",
      kpiCode: "A0304010",
    }, {
      name: "期末GS7库存台次占期末新车库存台次比",
      kpiCode: "A0404067",
    }],
    data: [],
  },
  tableData_2_4_8: {
    table: [{
      name: "GS8裸车毛利率",
      kpiCode: "A0105009",
    }, {
      name: "GS8返利后毛利率",
      kpiCode: "A0106054",
    }, {
      name: "GS8新车销量占新车总销量比",
      kpiCode: "A0304011",
    }, {
      name: "期末GS8库存台次占期末新车库存台次比",
      kpiCode: "A0404068",
    }],
    data: [],
  },
  tableData_2_4_9: {
    table: [{
      name: "GS8S裸车毛利率",
      kpiCode: "A0105010",
    }, {
      name: "GS8S返利后毛利率",
      kpiCode: "A0106055",
    }, {
      name: "GS8S新车销量占新车总销量比",
      kpiCode: "A0304012",
    }, {
      name: "期末GS8S库存台次占期末新车库存台次比",
      kpiCode: "A0404069",
    }],
    data: [],
  },
  tableData_2_4_10: {
    table: [{
      name: "M6裸车毛利率",
      kpiCode: "A0105011",
    }, {
      name: "M6返利后毛利率",
      kpiCode: "A0106056",
    }, {
      name: "M6新车销量占新车总销量比",
      kpiCode: "A0304013",
    }, {
      name: "期末M6库存台次占期末新车库存台次比",
      kpiCode: "A0404070",
    }],
    data: [],
  },
  tableData_2_4_11: {
    table: [{
      name: "M8裸车毛利率",
      kpiCode: "A0105012",
    }, {
      name: "M8返利后毛利率",
      kpiCode: "A0106057",
    }, {
      name: "M8新车销量占新车总销量比",
      kpiCode: "A0304014",
    }, {
      name: "期末M8库存台次占期末新车库存台次比",
      kpiCode: "A0404071",
    }],
    data: [],
  },
  tableData_2_4_12: {
    table: [{
      name: "GA4裸车毛利率",
      kpiCode: "A0105013",
    }, {
      name: "GA4返利后毛利率",
      kpiCode: "A0106058",
    }, {
      name: "GA4新车销量占新车总销量比",
      kpiCode: "A0304015",
    }, {
      name: "期末GA4库存台次占期末新车库存台次比",
      kpiCode: "A0404072",
    }],
    data: [],
  },
  tableData_2_4_13: {
    table: [{
      name: "GA6裸车毛利率",
      kpiCode: "A0105014",
    }, {
      name: "GA6返利后毛利率",
      kpiCode: "A0106060",
    }, {
      name: "GA6新车销量占新车总销量比",
      kpiCode: "A0304016",
    }, {
      name: "期末GA6库存台次占期末新车库存台次比",
      kpiCode: "A0404073",
    }],
    data: [],
  },
  tableData_2_4_14: {
    table: [{
      name: "GA8裸车毛利率",
      kpiCode: "A0105015",
    }, {
      name: "GA8返利后毛利率",
      kpiCode: "A0106060",
    }, {
      name: "GA8新车销量占新车总销量比",
      kpiCode: "A0304017",
    }, {
      name: "期末GA8库存台次占期末新车库存台次比",
      kpiCode: "A0404074",
    }],
    data: [],
  },
    tableData_2_4_15: {
    table: [{
      name: "影豹裸车毛利率",
      kpiCode: "A0105016",
    }, {
      name: "影豹返利后毛利率",
      kpiCode: "A0106061",
    }, {
      name: "影豹新车销量占新车总销量比",
      kpiCode: "A0304018",
    }, {
      name: "期末影豹库存台次占期末新车库存台次比",
      kpiCode: "A0404075",
    }],
    data: [],
  },
    tableData_2_4_16: {
    table: [{
      name: "其他裸车毛利率",
      kpiCode: "A0105017",
    }, {
      name: "其他返利后毛利率",
      kpiCode: "A0106062",
    }, {
      name: "其他新车销量占新车总销量比",
      kpiCode: "A0304019",
    }, {
      name: "期末其他库存台次占期末新车库存台次比",
      kpiCode: "A0404076",
    }],
    data: [],
  },

  tableData_2_5_1: {
    table: [{
      name: "新车库存周转率",
      kpiCode: "A0404002",
    }, {
      name: "0~30天期末新车库存占比",
      kpiCode: "A0403024",
    }, {
      name: "31~60天期末新车库存占比",
      kpiCode: "A0403025",
    }, {
      name: "61~90天期末新车库存占比",
      kpiCode: "A0403026",
    }, {
      name: ">90天期末新车库存占比",
      kpiCode: "A0403027",
    }],
    data: [],
  },
  tableData_2_6_1: {
    table: [{
      name: "销售水平事业毛利率",
      kpiCode: "A0105023",
      indentation: 0,
    }, {
      name: "新车用品毛利率",
      kpiCode: "A0104068",
    }, {
      name: "新车保险毛利率",
      kpiCode: "A0104069",
    }, {
      name: "金融按揭毛利率",
      kpiCode: "A0104070",
    }, {
      name: "上牌验车毛利率",
      kpiCode: "A0104071",
    }, {
      name: "二手车毛利率",
      kpiCode: "A0104072",
    }, {
      name: "新车延保毛利率",
      kpiCode: "A0104073",
    }],
    data: [],
  },
  tableData_2_6_2: {
    table: [{
      name: "平均单车销售水平事业毛利",
      kpiCode: "A0105072",
    }, {
      name: "平均单车新车用品毛利(用品台次)",
      kpiCode: "A0104115",
    }, {
      name: "平均单车新车保险毛利(新保台次)",
      kpiCode: "A0104116",
    }, {
      name: "平均单车金融按揭毛利(金融按揭台次)",
      kpiCode: "A0104117",
    }, {
      name: "平均单车上牌验车毛利(上牌验车台次)",
      kpiCode: "A0104118",
    }, {
      name: "平均单车二手车毛利(二手车台次)",
      kpiCode: "A0104119",
    }, {
      name: "平均单车新车延保毛利(延保台次)",
      kpiCode: "A0104120",
    }],
    data: [],
  },
  tableData_2_6_3: {
    table: [{
      name: "新车用品渗透率",
      kpiCode: "A0303271",
    }, {
      name: "新车保险渗透率",
      kpiCode: "A0303272",
    }, {
      name: "金融按揭渗透率",
      kpiCode: "A0303273",
    }, {
      name: "上牌验车渗透率",
      kpiCode: "A0303274",
    }, {
      name: "二手车渗透率",
      kpiCode: "A0303275",
    }, {
      name: "新车延保渗透率",
      kpiCode: "A0303276",
    }],
    data: [],
  },
  chart_2_1_1_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
    },
    kpiCodes: [
      {
        code: "A0303004",
        name: "新车销量",
      }, {
        code: "A0106023",
        name: "新车销售裸车毛利率",
      }, {
        code: "A0107001",
        name: "返利后毛利率",
      }],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function (params) {
          let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>新车销量: " + (params[0].value).toFixed(0) + "台",
            val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>新车销售裸车毛利率： " + Number(params[1].value).toFixed(1) + "%",
            val3 = "<span style=\"background-color:" + params[2].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>返利后毛利率： " + Number(params[2].value).toFixed(1) + "%";
          return `${params[0].axisValue} <br /> ${val1} <br />${val2} <br />${val3}`;
        },
      },
      legend: {
        data: [],
      },
      calculable: false,
      xAxis,
      yAxis: [yAxisDefaultUnit('台'),yAxisRightUnit('%')],
      series: [
        {
          name: "新车销量",
          code: "A0303004",
          type: "bar",barMaxWidth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return params.value;
              },
            },
          },
        },
        {
          name: "新车销售裸车毛利率",
          code: "A0106023",
          type: "line",smooth,
          stack: "total",
          yAxisIndex: 1,
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) +"%";
              },
            },
          },
        },
        {
          name: "返利后毛利率",
          code: "A0107001",
          type: "line",smooth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) +"%";
              },
            },
          },
        },
      ],
    },
  },
  chart_2_1_2_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
    },
    kpiCodes: [
      {
        code: "A0106026",
        name: "平均单车新车裸车毛利",
      }, {
        code: "A0105071",
        name: "平均单车销售返利",
      }, {
        code: "A0105072",
        name: "平均单车销售水平事业毛利",
      }],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        formatter: function (params) {
          let arr = [];
          params.forEach((d) => {
            arr.push({
              val: `<span style='background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;'></span>${d.seriesName}: ${Number(d.value).toFixed(0)}元`,
            });
          });
          return `${params[0].axisValue} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}`;
        },
      },
      legend,
      xAxis,
      yAxis: [yAxisDefaultUnit('元')],
      series: [
        {
        name: "平均单车新车裸车毛利",
        code: "A0106026",
        type: "bar",
        stack: "total",
        barMaxWidth,
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(0);
            },
          },
        },
      }, {
        name: "平均单车销售返利",
        code: "A0105071",
        type: "bar",
        stack: "total",
        barMaxWidth,
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",

            },
            formatter: function (params) {
              return Number(params.value).toFixed(0);
            },
          },
        },
      }, {
        name: "平均单车销售水平事业毛利",
        code: "A0105072",
        type: "bar",
        stack: "total",
        barMaxWidth,
        label: {
          normal: {
            show: true,
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(0);
            },
          },
        },
      }],
    },
  },
  chart_2_2_1_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
    },
    kpiCodes: [
      {
        code: "A0304024",
        name: "展厅终端销量占比",
      }, {
        code: "A0304025",
        name: "iDCC终端销量占比",
      }, {
        code: "A0304026",
        name: "二网终端销量占比",
      }, {
        code: "A0304027",
        name: "大客户终端销量占比",
      }],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        formatter: function (params) {
          let arr = [];
          params.forEach((d) => {
            arr.push({
              val: `<span style='background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;'></span>${d.seriesName}:${Number(d.value).toFixed(1)}%`,
            });
          });
          return `${params[0].axisValue} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}<br />${arr[3].val}`;
        },
      },
      legend,
      calculable: false,
      xAxis,
      yAxis: [yAxisDefaultUnit('%')],
      series: [
        {
          name: "展厅终端销量占比",
          code: "A0304024",
          type: "bar",
          barMaxWidth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
        {
          name: "iDCC终端销量占比",
          code: "A0304025",
          type: "bar",barMaxWidth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
        {
          name: "二网终端销量占比",
          code: "A0304026",
          type: "bar",barMaxWidth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) + "%";
              },
            },
          },

        },
        {
          name: "大客户终端销量占比",
          code: "A0304027",
          type: "bar",barMaxWidth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
      ],
    },
  },
  chart_2_3_1_Data: {

    /*设置*/
    settings: {
      type: "funnel",
    },
    kpiCodes: [
      {
        code: "A1302012",
        name: "展厅首次客流总数",
      }, {
        code: "A1302010",
        name: "展厅新增建档总数",
      }, {
        code: "A1302013",
        name: "展厅再次客流总数",
      }, {
        code: "A1303080",
        name: "展厅新增订单总数",
      }, {
        code: "A0302021",
        name: "展厅新车销量",
      }],
    rows: [],
    options: {
      title: {
        text: "当月值 上月值",
        left: "center",
        top: "bottom",
      },
      series: [
        {
          name: "当月值",
          type: "funnel",
          width: "45%",
          height: "80%",
          left: "0",
          top: "15%",
          sort: 'none',
          label: {
            show: false,
            position: "inside",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}",
          },
          data: [],
        },
        {
          name: "上月值",
          type: "funnel",
          width: "45%",
          height: "80%",
          sort: 'none',
          left: "55%",
          top: "15%",
          label: {
            show: false,
            position: "inside",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}",
          },
          data: [],
        },
      ],
      legend: {
        textStyle: {
          fontSize: 14,
        },
      },
    },

  },
  chart_2_3_2_Data: {
    /*设置*/
    settings: {
      type: "funnel",
    },
    kpiCodes: [
      {
        code: "A1302115",
        name: "IDCC线索总数",
      }, {
        code: "A1302019",
        name: "IDCC有效线索总数",
      }, {
        code: "A1302028",
        name: "IDCC邀约到店数",
      }, {
        code: "A1302032",
        name: "IDCC新增订单总数",
      }, {
        code: "A0302022",
        name: "IDCC新车销量",
      }],
    rows: [],
    options: {
      title: {
        text: "当月值 上月值",
        left: "center",
        top: "bottom",
      },
      series: [
        /*{
            name: '全国标杆',
            type: 'funnel',
            width: '40%',
            height: '45%',
            left: '0',
            top: '7%',
            label: {
                show: false,
                position: 'inside'
            },
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c}"
            },
            data: []
        },
        {
            name: '区域标杆',
            type: 'funnel',
            width: '45%',
            height: '45%',
            left: '55%',
            top: '7%',
            label: {
                show: false,
                position: 'inside'
            },
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c}"
            },
            data: []
        },*/
        {
          name: "当月值",
          type: "funnel",
          width: "45%",
          height: "80%",
          sort: 'none',
          left: "0",
          top: "15%",
          label: {
            show: false,
            position: "inside",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}",
          },
          data: [],
        },
        {
          name: "上月值",
          type: "funnel",
          width: "45%",
          height: "80%",
          sort:"none",
          left: "55%",
          top: "15%",
          label: {
            show: false,
            position: "inside",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}",
          },
          data: [],
        },
      ],
      legend: {
        textStyle: {
          fontSize: 14,
        },
      },
    },
  },
  chart_2_5_1_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
      // type: "histogram",
      // yAxisType: ["0.[00]"],
      // yAxisName: ["次"],
      // label: {
      //     show: true,
      //     formatter: function (params) {
      //         return params.data.toFixed(2);
      //     },
      // },
    },
    kpiCodes: [
      {
        code: "A0404002",
        name: "新车库存周转率",
      }],
    rows: [],
    options: {
      legend,
      tooltip: {
        show: true,
        trigger: "item",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function (params) {
          let val = "<span style=\"background-color:" + params.color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + Number(params.value).toFixed(2) + "%";
          return `${params.name}:<br /> ${val}`;
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit('%')],
      series: [{
        name: "新车库存周转率",
        code: "A0404002",
        type: "bar",
        barMaxWidth,
        itemStyle: {
          normal: {
            color: (params) => {
              return colors[params.dataIndex];
            },
          },
        },
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(2);
            },
          },
        },
        markLine: {
          symbol: "none", //去掉警戒线最后面的箭头
          data: [{
            silent: false,             //鼠标悬停事件  true没有，false有
            name: "区域标杆",
            lineStyle: {               //警戒线的样式  ，虚实  颜色
              //   type:"solid",
              color: "#5470C6",
              width: 2,
            },
            label: {
              show: false,
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return "区域标杆(" + Number(params.value).toFixed(1) + "%)";
              },
              position: "start",          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
            },
            yAxis: null,           // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
            code: "A0404002",
            key: "RegionalStandard",
          },
            {
              silent: true,             //鼠标悬停事件  true没有，false有
              name: "全国标杆",
              lineStyle: {               //警戒线的样式  ，虚实  颜色
                //    type:"solid",
                color: "#71BE95",
                width: 2,
              },
              label: {
                position: "middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                show: false,
                textStyle: {
                  color: "black",
                },
                formatter: function (params) {
                  return "全国标杆(" + Number(params.value).toFixed(1) + "%)";
                },
              },
              yAxis: null,        // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              code: "A0404002",
              key: "NationwideStandard",
            },
          ],
        },
      }],
    },
  },
  chart_2_5_2_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
    },
    kpiCodes: [
      {
        code: "A0403024",
        name: "0~30天期末新车库存占比",
      }, {
        code: "A0403025",
        name: "61~90天期末新车库存占比",
      }, {
        code: "A0403026",
        name: "31~60天期末新车库存占比",
      }, {
        code: "A0403027",
        name: ">90天期末新车库存占比",
      }],
    rows: [],
    options: {

      tooltip: {
        show: true,
        trigger: "axis",
        formatter: function (params) {
          let arr = [];
          params.forEach((d) => {
            arr.push({
              val: `<span style='background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;'></span>${d.seriesName}:${Number(d.value).toFixed(1)}%`,
            });
          });
          return `${params[0].axisValue} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}<br /> ${arr[3].val}`;
        },
      },
      legend,
      calculable: false,
      xAxis,
      yAxis: [yAxisDefaultUnit('%')],
      series: [
        {
          name: "0~30天期末新车库存占比",
          code: "A0403024",
          type: "bar",
          barMaxWidth,
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return  Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
        {
          name: "61~90天期末新车库存占比",
          code: "A0403025",
          type: "bar",
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return  Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
        {
          name: "31~60天期末新车库存占比",
          code: "A0403026",
          type: "bar",
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
        {
          name: ">90天期末新车库存占比",
          code: "A0403027",
          type: "bar",
          stack: "total",
          data: [],
          label: {
            normal: {
              show: true,
              //   position:'insideRight',
              textStyle: {
                color: "black",
              },
              formatter: function (params) {
                return Number(params.value).toFixed(1) + "%";
              },
            },
          },
        },
      ],
    },
  },
  chart_2_6_1_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
    },
    kpiCodes: [
      {
        code: "A0303271",
        name: "新车用品渗透率",
      }, {
        code: "A0303272",
        name: "新车保险渗透率",
      }],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function (params) {
          let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>新车用品渗透率: " + Number(params[0].value).toFixed(1) + "%",
            val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>新车保险渗透率： " + Number(params[1].value).toFixed(1) + "%";
          return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit('%')],
      series: [{
        name: "新车用品渗透率",
        code: "A0303271",
        type: "line",smooth,
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(1) + "%";
            },
          },
        },
      }, {
        name: "新车保险渗透率",
        code: "A0303272",
        type: "line",smooth,
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(1) + "%";
            },
          },
        },
      }],
      legend,
    },
  },
  chart_2_6_2_Data: {
    /*设置*/
    settings: {
      type: "funnel",
      monthChart: true,
    },
    kpiCodes: [
      {
        code: "A0303273",
        name: "金融按揭渗透率",
      }, {
        code: "A0303276",
        name: "新车延保渗透率",
      }],
    rows: [],
    options: {
      series: [{
        name: "金融按揭渗透率",
        code: "A0303273",
        type: "line",smooth,
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(1) + "%";
            },
          },
        },
      }, {
        name: "新车延保渗透率",
        code: "A0303276",
        type: "line",smooth,
        label: {
          normal: {
            show: true,
            //   position:'insideRight',
            textStyle: {
              color: "black",
            },
            formatter: function (params) {
              return Number(params.value).toFixed(1) + "%";
            },
          },
        },
      }],
      legend,
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function (params) {
          let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>金融按揭渗透率: " + Number(params[0].value).toFixed(1) + "%",
            val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>新车延保渗透率： " + Number(params[1].value).toFixed(1) + "%";
          return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit('%')],
    },
  },
};

export { storeReportSales };
