import echartsDemo from "@/components/echartsDemo";
import wangeditorDemo from "@/components/wangEditorDemo";

let testRoutes = [

      //echatsDemo
      {
        path: "/echarts",
        component: echartsDemo,
      },
      //wangeditorDemo
      {
        path: "/wangeditorDemo",
        component: wangeditorDemo,
      },

];

export default testRoutes;
