<template>
  <section id="one">
    <a-row :gutter="[24,24]" id="two">
      <a-col :span="12">
        <a-card title="1、盈亏平衡点经营模型">
          <div style="text-align: center">
            <img src="../../../../assets/monthReportChart.png" alt="">
          </div>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card title="2、盈利平衡点">
          <div class="balancePoint">
            <p><span>盈亏平衡点销量</span> {{ parseInt(data.profitBalancePoint["C0408025"].Current) + "台" }}</p>
            <p><span>新车总销量</span> {{ parseInt(data.profitBalancePoint["A0302001"].Current) + "台" }}</p>
            <p><span>盈亏平衡点收入</span> {{ Number(data.profitBalancePoint["C0409001"].Current / 10000).toFixed(2) + "万元" }}
            </p>
            <p><span>当月销售总收入</span> {{ Number(data.profitBalancePoint["A0104090"].Current / 10000).toFixed(2) + "万元" }}
            </p>
            <p><span>盈亏平衡经营天数</span> {{ Number(data.profitBalancePoint["C0409002"].Current).toFixed(1) + "天" }}
            </p>
          </div>
          <p style="padding-left: 18px; padding-top: 20px;line-height: 53px">
            ◆ 盈亏平衡点销量、天数出现负数，为以下两种情况导致: <br>
            1、该店售后业务毛利已经覆盖总费用；<br>
            2、该店销售业务毛利无法覆盖销售变动费用，不具参考意义
          </p>
        </a-card>
        <div id="there"></div>
      </a-col>
      <a-col :span="24" style="clear: both">
        <a-card title="3、整体盈利能力">
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_3_1"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12">
            <chartComponent :chart-data="data.chart_1_3_1_Data" chart-height="265px"/>
          </a-col>
          <a-col :span="12">
            <chartComponent :chart-data="data.chart_1_3_2_Data" chart-height="430px"/>
          </a-col>
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_3_2"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12" style="clear:both;">
            <chartComponent :chart-data="data.chart_1_3_3_Data"/>
          </a-col>
        </a-card>
        <div id="four"></div>
      </a-col>
      <a-col :span="24">
        <a-card title="4、总毛利及总毛利率">
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_4_1"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12">
            <chartComponent :chart-data="data.chart_1_4_1_Data"/>
          </a-col>
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_4_2"
            ></monthReportTableComponent>
          </a-col>
        </a-card>
        <div id="five"></div>
      </a-col>
      <a-col :span="24">
        <a-card title="5、三大业务收入、毛利贡献占比">
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_5_1"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_5_2"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12">
            <chartComponent :chart-data="data.chart_1_5_1_Data"/>
          </a-col>
        </a-card>
        <div id="six"></div>
      </a-col>
      <a-col :span="24">
        <a-card title="6、主营业务抗风险能力">
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_6_1"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12">
            <chartComponent :chart-data="data.chart_1_6_1_Data"/>
          </a-col>
        </a-card>
        <div id="seven"></div>
      </a-col>
      <a-col :span="24">
        <a-card title="7、财务风险管控">
          <a-col :span="12">
              <br>
              <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_7_1"
            ></monthReportTableComponent>
          </a-col>
          <a-col :span="12">
            <div class="legend">
              <!--              <span> <b></b>资产负债率</span>-->
              <span> <b>---</b>全国标杆</span>
              <span> <b>---</b>区域标杆</span>
            </div>
            <chartComponent :chart-data="data.chart_1_7_1_Data" />
          </a-col>
          <a-col :span="12" style="clear: both">
              <br>
            <chartComponent :chart-data="data.chart_1_7_2_Data"/>
          </a-col>
          <a-col :span="12">
            <div class="legend">
              <!--              <span> <b></b>长库龄新车库存台次占比</span>-->
              <span> <b>---</b>全国标杆</span>
              <span> <b>---</b>区域标杆</span>
            </div>
            <chartComponent :chart-data="data.chart_1_7_3_Data"/>
          </a-col>
        </a-card>
        <div id="eight"></div>
      </a-col>
      <a-col :span="24">
        <a-card title="8、员工内部管理">
          <a-col :span="12">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_1_8_1"
            ></monthReportTableComponent>
          </a-col>
        </a-card>
      </a-col>
      <a-anchor v-if="isClose">
        <div class="close" @click="isClose=false">
          <a-icon type="close"/>
          关闭
        </div>
        <a-anchor-link href="#one" title="1、盈亏平衡点经营模型"/>
        <a-anchor-link href="#two" title="2、盈利平衡点"/>
        <a-anchor-link href="#there" title="3、整体盈利能力"/>
        <a-anchor-link href="#four" title="4、总毛利及总毛利率"/>
        <a-anchor-link href="#five" title="5、三大业务收入、毛利贡献占比"/>
        <a-anchor-link href="#six" title="6、主营业务抗风险能力"/>
        <a-anchor-link href="#seven" title="7、财务风险管控"/>
        <a-anchor-link href="#eight" title="8、员工内部管理"/>
      </a-anchor>
    </a-row>

  </section>
</template>

<script>
import monthReportTableComponent from "../../../../components/monthReportTableComponent";
import chartComponent from "../../../../components/chartComponent";
import { loadReportData, getEmptyTemplate } from "@/until/reportCalculate";

import { storeReportOverAll } from "../overall.js";

export default {
  name: "overallOverview",
  props: {
    reportParams: {},
    reportDate: String,
  },
  components: {
    monthReportTableComponent, chartComponent,
    reportParams: function () {
      this.loadData();
    },
  },
  data () {
    return {
      isClose: true,
      emptyTemplate: getEmptyTemplate(),
      dataSource: [],
      columnsData: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
          customRender: (text, record) => {
            if (record.indentation > 0)
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            return text;
          },
        },
        {
          title: "单位",
          dataIndex: "Unit",
          key: "Unit",
        },
        {
          title: "全国标杆值",
          dataIndex: "NationwideStandardText",
          key: "NationwideStandard",
        },
        {
          title: "区域标杆值",
          dataIndex: "RegionalStandardText",
          key: "RegionalStandard",
        },
        {
          title: "当月值",
          dataIndex: "CurrentText",
          key: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.CurrentText) < parseFloat(record.NationwideStandardText);
            if (record.PercentileSort === "DESC") flag = !flag;
            if (flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑</div>;
            }
          },
        },
        {
          title: "上月值",
          dataIndex: "N-1Text",
          key: "N-1",
        },
        {
          title: "去年同期",
          dataIndex: "LastYearText",
          key: "LastYear",
        },
        {
          title: "年度累计",
          dataIndex: "CurrentYearText",
          key: "CurrentYear",
        }],
      chartNameRelations: {
        "全国标杆": "NationwideStandard",
        "区域标杆": "RegionalStandard",
        "当月值": "Current",
        "上月值": "LastMonth",
      },
      data: this._.extend({
        profitBalancePoint: {
          "C0408025": getEmptyTemplate(),
          "A0302001": getEmptyTemplate(),
          "C0409001": getEmptyTemplate(),
          "A0104090": getEmptyTemplate(),
          "C0409002": getEmptyTemplate(),
        },
      }, storeReportOverAll),
    };
  },
  watch: {
    reportParams: {
      handler: function (val, oldVal) {
        this.loadData();
      },
      // 深度观察
      deep: true,
    },
  },
  created () {
    this.loadData();
    //加载未封装组件数据源
    this.getDataSource();
  },
  methods: {
    getData (code) {
      return !this.dataSource[code] ? { Current: 0 } : this.dataSource[code];
    },

    async getDataSource () {
      let self = this;
      let res = await self.$Http.GetDealerKpiReportPost({
        ReportDate: self.reportParams.ReportDate,
        DealerCode: self.reportParams.DealerCode,
        FilterCodes: [
          "C0302001",
          "C0302191",
          "C0408025",
          "A0302001",
          "C0409001",
          "A0104090",
          "C0409002",
        ],
      });
      if (res.Success) {
        self.dataSource = res.Data;
      } else {
        self.$message.error(res.Message);
      }
    },
    async loadData () {
      await loadReportData(this, this.data, this.chartNameRelations, this.reportParams);
    },
  },
};
</script>
<style lang="less" scoped>
@import "./storeManagment.less";
</style>
