<template>
  <section>
    <a-card>
      指标编码：
      <a-input v-model="search.code" style="width: 200px;margin-right: 20px;" placeholder="请输入完整的指标编码"
               @pressEnter="getIndexInfo(1)"/>
      所属表：
      <a-select v-model="search.sheetId" style="width: 130px;margin-right: 20px;" @change="getIndexInfo(1)">
        <a-select-option :key="0">
          --请选择--
        </a-select-option>
        <a-select-option v-for="sheet in sheets" :key="sheet.ID">
          {{ sheet.Name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" @click="getIndexInfo(1)">搜索</a-button>
    </a-card>
    <a-card style="margin-top: 8px;margin-bottom: 18px">
    <!--表格-->
    <a-table rowKey="Id" :columns="columns" :data-source="data" :pagination="false">
      <span slot="Percentage" slot-scope="text, record">
        <a-switch checked-children="显示" un-checked-children="不显示" :checked="record.Percentage"
                  @change="statusChange(record,'Percentage',4)"/>
      </span>
      <span slot="BgColor" slot-scope="text, record">
        <a-switch checked-children="显示" un-checked-children="不显示" :checked="record.BgColor===1"
                  @change="statusChange(record,'BgColor',1)"/>
      </span>
      <span slot="Icon" slot-scope="text, record">
        <a-switch checked-children="显示" un-checked-children="不显示" :checked="record.Icon"
                  @change="statusChange(record,'Icon',2)"/>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-switch checked-children="启用" un-checked-children="禁用" :checked="!record.IsDelete"
                  @change="statusChange(record,'IsDelete',3)"/>
      </span>
    </a-table>
    <!--分页-->
    <a-pagination class="page"
                  size="small" v-model="pageIndex" :total="page.totalRow" :pageSize="pageSize"
                  :show-total="(total, range) => `${range[0]} 到 ${range[1]} (共 ${total} 条)`"
                  @change="getIndexInfo" @showSizeChange="getIndexInfo"
                  show-size-changer show-quick-jumper/>
  </a-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text, record, index) => ++index
        },
      /*  {
          title: '指标编码',
          dataIndex: 'KpiCode',
          key: 'KpiCode',
        },*/
        {
          title: '一级科目',
          dataIndex: 'FirstSubjectCode',
          key: 'FirstSubjectCode',
        },
        {
          title: '二级科目',
          dataIndex: 'SecondSubject',
          key: 'SecondSubject',
        },
        {
          title: '是否显示百分比',
          dataIndex: 'Percentage',
          key: 'Percentage',
          scopedSlots: {customRender: 'Percentage'},
        },
        {
          title: '是否显示背景色',
          dataIndex: 'BgColor',
          key: 'BgColor',
          scopedSlots: {customRender: 'BgColor'},
        },
        {
          title: '是否显示最大最小值',
          dataIndex: 'Icon',
          key: 'Icon',
          scopedSlots: {customRender: 'Icon'},
        },
        {
          title: '所属表',
          dataIndex: 'SubjectTypeName',
          key: 'SubjectTypeName'
        },
        {
          title: '排序',
          dataIndex: 'Sort',
          key: 'Sort'
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime'
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ],
      data: [],
      page: {},
      pageIndex: 1,
      pageSize: 10,
      sheets: [],
      search: {
        code: "",
        sheetId: 0
      }
    };
  },
  created() {
    this.getIndexInfo();
    this.getSheets();
  },
  methods: {
    async getSheets() {
      let res = await this.$Http.commonApi({
        API: "TypeNameListGet_common",
        TypeClass: 1
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.sheets = res.ApiParamObj;
    },
    async getIndexInfo(pageIndex, pageSize) {
      if (pageIndex != null) {
        this.pageIndex = pageIndex;
      }
      if (pageSize != null) {
        this.pageSize = pageSize;
      }
      let res = await this.$Http.commonApi({
        API: "DBKpiInfoListGet_robin",
        pagesize: this.pageSize,
        page: this.pageIndex,
        KpiCode: this.search.code,
        SheetID: this.search.sheetId
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.data = res.ApiParamObj[0];
      this.page = res.ApiParamObj[1][0];
    },
    async statusChange(record, attr, typeClass) {
      let newValue = (record[attr] - 0) === 1 ? 0 : 1;
      let res = await this.$Http.commonApi({
        API: "DealerGroupsBkMenuWH_robin",
        BusFlg: newValue,
        TypeClass: typeClass,
        DataID: record.Id
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      record[attr] = newValue;
    }
  },
};
</script>
