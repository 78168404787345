import {
  yAxisRightUnit,
  yAxisTenThousandYuan, legend, smooth, yAxisDefaultUnit,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
 const grid = {
  bottom:"40px",
  right:"22px",
  containLabel:true,
};

const xAxis =[
  {
    type: "category",
    axisLabel:{
      interval:0,
    },
    axisLine:{
      show:true,
      lineStyle:{ type:"sold",width:1.3 },
    },
  },
];
const colors = [];
const barMaxWidth = "35%";
let storeReportOverAll = {
    tableData_1_3_1: {
        table: [
            {
                name: "税前利润",
                kpiCode: "C0408004",
            }, {
                name: "其中：销售税前利润",
                kpiCode: "A0107013",
            }, {
                name: "售后税前利润",
                kpiCode: "B0106038",
                indentation: 3,
            }, {
                name: "税前利润率",
                kpiCode: "C0408021",
            }, {
                name: "其中：销售税前利润率",
                kpiCode: "A0108001",
            }, {
                name: "售后税前利润率",
                kpiCode: "B0107001",
                indentation: 3,
            }, {
                name: "资产回报率",
                kpiCode: "C0408020",
            }, {
                name: "净资产回报率",
                kpiCode: "C0408024",
            }],
        data: [],
    },
    tableData_1_3_2: {
        table: [
            {
                name: "总费用",
                kpiCode: "C0206001",
            }, {
                name: "其中：固定费用",
                kpiCode: "C0202020",
            }, {
                name: "半固定费用",
                kpiCode: "C0202124",
                indentation: 3,
            }, {
                name: "变动费用",
                kpiCode: "C0202001",
                indentation: 3,
            }, {
                name: "财务费用",
                kpiCode: "C0202238",
                indentation: 3,
            }, {
                name: "总费用占总毛利比",
                kpiCode: "C0408001",
            }, {
                name: "其中：固定费用占总毛利比",
                kpiCode: "C0408007",
            }, {
                name: "半固定费用占总毛利比",
                kpiCode: "C0408023",
                indentation: 3,
            }, {
                name: "变动费用占总毛利比",
                kpiCode: "C0408006",
                indentation: 3,
            }, {
                name: "财务费用占总毛利比",
                kpiCode: "C0408014",
                indentation: 3,
            }, {
                name: "费用率",
                kpiCode: "C0207022",
            }, {
                name: "人力费用率",
                kpiCode: "C0207028",
            }, {
                name: "其中：销售人力费用率",
                kpiCode: "C0207029",
            }, {
                name: "售后人力费用率",
                kpiCode: "C0207030",
                indentation: 3,
            },
        ],
        data: [],
    },
    tableData_1_4_1: {
        table: [{
            name: "总毛利",
            kpiCode: "C0407001",
        }, {
            name: "销售业务毛利",
            kpiCode: "A0106025",
        }, {
            name: "其中：销售水平事业毛利",
            kpiCode: "A0104067",
        }, {
            name: "售后业务毛利",
            kpiCode: "B0105113",
        }, {
            name: "其中：售后工时毛利",
            kpiCode: "B0104063",
        }, {
            name: "售后零部件毛利",
            kpiCode: "B0104043",
            indentation: 3,
        }, {
            name: "售后水平事业毛利",
            kpiCode: "B0104187",
            indentation: 3,
        },
        ],
        data: [],
    },
    tableData_1_4_2: {
        table: [{
            name: "总毛利率",
            kpiCode: "C0407004",
        }, {
            name: "销售业务毛利率",
            kpiCode: "A0107002",
        }, {
            name: "其中：销售水平事业毛利率",
            kpiCode: "A0105023",
        }, {
            name: "售后业务毛利率",
            kpiCode: "B0106026",
        }, {
            name: "其中：售后工时毛利率",
            kpiCode: "B0105044",
        }, {
            name: "售后零部件毛利率",
            kpiCode: "B0105043",
            indentation: 3,
        }, {
            name: "售后水平事业毛利率",
            kpiCode: "B0105108",
            indentation: 3,
        },
        ],
        data: [],
    },
    tableData_1_5_1: {
        table: [{
            name: "新车裸车收入占总收入比",
            kpiCode: "C0407006",
        }, {
            name: "售后维修收入占总收入比",
            kpiCode: "C0407007",
        }, {
            name: "水平事业收入占总收入比",
            kpiCode: "C0407008",
        },
        ],
        data: [],
    },
    tableData_1_5_2: {
        table: [{
            name: "新车销售返利后毛利占总毛利比",
            kpiCode: "C0408015",
        }, {
            name: "售后维修毛利(工时+零部件+返利)占总毛利比",
            kpiCode: "C0408016",
        }, {
            name: "水平事业毛利占总毛利比",
            kpiCode: "C0408010",
        },
        ],
        data: [],
    },
    tableData_1_6_1: {
        table: [{
            name: "零服吸收率",
            kpiCode: "B0107002",
        }, {
            name: "售后及水平事业毛利覆盖总费用",
            kpiCode: "C0408013",
        },
        ],
        data: [],
    },
    tableData_1_7_1: {
        table: [{
            name: "资产负债率",
            kpiCode: "C0107003",
        }, {
            name: "流动比率",
            kpiCode: "C0106003",
        }, {
            name: "总资产周转率",
            kpiCode: "C0107006",
        }, {
            name: "流动资产周转率",
            kpiCode: "C0106006",
        },
            //     {
            //     name: "经营活动产生的现金流量净额",
            //     kpiCode: "C0102088",
            // }, {
            //     name: "投资活动产生的现金流量净额",
            //     kpiCode: "C0102089",
            // }, {
            //     name: "筹资活动产生的现金流量净额",
            //     kpiCode: "C0102090",
            // }, {
            //     name: "期末余额",
            //     kpiCode: "C0102091",
            // },
            {
                name: "现金车金额占期末库存车金额比",
                kpiCode: "A0403091",
            }, {
                name: "平均单车财务费用",
                kpiCode: "A0205035",
            }, {
                name: "长库龄新车库存台次占比",
                kpiCode: "A0404111",
            },
        ],
        data: [],
    },
    tableData_1_8_1: {
        table: [{
            name: "员工流失率",
            kpiCode: "C0303111",
        }, {
            name: "管理岗位流失率",
            kpiCode: "C0303112",
        }, {
            name: "销售顾问流失率",
            kpiCode: "C0303153",
        }, {
            name: "售后服务顾问流失率",
            kpiCode: "C0303130",
        }, {
            name: "销售顾问新入职率",
            kpiCode: "C0303171",
        },
        ],
        data: [],
    },
    chart_1_3_1_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
        },
        kpiCodes: [
            {
                code: "C0408004",
                name: "税前利润",
            }, {
                code: "C0408021",
                name: "税前利润率",
            }],
        rows: [],
        options: {
            legend,
            grid,
            calculable: true,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>税前利润: " + (params[0].value / 10000).toFixed(2) + "万元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>税前利润率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisTenThousandYuan, yAxisRightUnit('%')],
            series: [
                {
                    name: "税前利润",
                    code: "C0408004",
                    type: "bar",
                  barMaxWidth,
                    stack: "total",
                    itemStyle: {
                        normal: {
                            color: (params) => {
                                return colors[params.dataIndex];
                            },
                        },
                    },
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return (params.value / 10000).toFixed(2);
                            },
                        },
                    },
                },
                {
                    name: "税前利润率",
                    code: "C0408021",
                    type: "line",smooth,
                    yAxisIndex: 1,
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
            ],
        },
    },
    chart_1_3_2_Data: {
        /*设置*/
        settings: {
            monthChart: true,
            type: "funnel",
        },
        kpiCodes: [
            {
                code: "C0206001",
                name: "总费用",
            }, {
                code: "C0408001",
                name: "总费用占总毛利比",
            }],
        rows: [],

        /*扩展属性*/
        options: {
            legend:{
              data:[],
            },
            grid,
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>总费用: " + (params[0].value / 10000).toFixed(2) + "万元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>总费用占总毛利比： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
          yAxis: [yAxisTenThousandYuan, yAxisRightUnit('%')],
          xAxis,
            series: [{
                name: "总费用",
                code: "C0206001",
                type: "bar",
              barMaxWidth,
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return (params.value / 10000).toFixed(2);
                        },
                    },
                },
            }, {
                name: "总费用占总毛利比",
                code: "C0408001",
                type: "line",
                yAxisIndex: 1,
              smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            },
            ],
        },

    },
    chart_1_3_3_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
        },
        kpiCodes: [
            {
                code: "C0207022",
                name: "费用率",
            }, {
                code: "C0207028",
                name: "人力费用率",
            }],
        options: {
            legend: {
                data: [],
            },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>费用率: " + Number(params[0].value).toFixed(1) + "%",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>人力费用率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [ yAxisDefaultUnit('%') ],
            series: [{
                name: "费用率",
                code: "C0207022",
                type: "line",smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }, {
                name: "人力费用率",
                code: "C0207028",
                type: "line",smooth,
                label: {

                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },

        rows: [],
    },
    chart_1_4_1_Data: {
        settings: {
            type: "funnel",
            monthChart: true,
        },
        /*扩展属性*/
        kpiCodes: [
            {
                code: "C0407001",
                name: "总毛利",
            }, {
                code: "C0407004",
                name: "总毛利率",
            }],
        rows: [],
        options: {
            tooltip: {
                show: true,
                trigger: "axis",
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>总 毛 利: " + (params[0].value / 10000).toFixed(2) + "万元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>总毛利率： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val2} <br />${val1}`;
                },
            },
            legend: {
                show: true,
                data: ["总毛利", "总毛利率"],
            },
            grid,
            calculable: true,
            xAxis,
            yAxis: [yAxisTenThousandYuan,yAxisRightUnit('%')],
            series: [
                {
                    name: "总毛利",
                    code: "C0407001",
                    type: "bar",
                  barMaxWidth,
                    // todo 不同柱不同颜色
                    itemStyle: {
                        normal: {
                            color: (params) => {
                                return colors[params.dataIndex];
                            },
                        },
                    },
                    /*直接在柱图中显示黑色文字*/
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return (params.value / 10000).toFixed(2);
                            },
                        },
                    },
                },
                {
                    name: "总毛利率",
                    code: "C0407004",
                    type: "line",smooth,
                    yAxisIndex: 1,
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
            ],
        },
    },
    chart_1_5_1_Data: {
        kpiCodes: [
            {
                code: "C0408015",
                name: "销售毛利贡献",
            }, {
                code: "C0408016",
                name: "售后毛利贡献",
            }, {
                code: "C0408010",
                name: "水平事业毛利贡献",
            }],
        settings: {
            type: "funnel",
            monthChart: true,
        },
        rows: [],
        options: {

            tooltip: {
                show: true,
                trigger: "axis",
                /*todo 循环显示图表的鼠标经过效果*/
                formatter: function (params) {
                    let arr = [];
                    params.forEach((d) => {
                        arr.push({
                            val: `<span style='background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;'></span>${d.seriesName}:${Number(d.value).toFixed(1)}%`,
                        });
                    });
                    return `${params[0].axisValue} <br /> ${arr[0].val} <br />${arr[1].val}<br />${arr[2].val}`;
                },
            },
            legend: {
                data: [],
            },
            calculable: false,
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [
                {
                    name: "销售毛利贡献",
                    code: "C0408015",
                    type: "bar",
                  barMaxWidth,
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: "售后毛利贡献",
                    code: "C0408016",
                    type: "bar",
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                },
                {
                    name: "水平事业毛利贡献",
                    code: "C0408010",
                    type: "bar",
                    stack: "total",
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },

                },
            ],
        },
    },
    chart_1_6_1_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["零服吸收率", "售后及水平事业毛利覆盖总费用"],
            // yAxisType: ["0.[00]%", "0.[00]%"],
            // yAxisName: ["%", "%	"],
            // axisSite: {
            //     right: ["售后及水平事业毛利覆盖总费用"],
            // },
        },
        kpiCodes: [
            {
                code: "B0107002",
                name: "零服吸收率",
            }, {
                code: "C0408013",
                name: "售后及水平事业毛利覆盖总费用",
            }],
        rows: [],
        /*扩展属性*/
        options: {
            legend: {
                data: [],
            },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>零服吸收率: " + Number(params[0].value).toFixed(1) + "%",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>售后及水平事业毛利覆盖总费用： " + Number(params[1].value).toFixed(1) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [{
                name: "零服吸收率",
                code: "B0107002",
                type: "line",smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }, {
                name: "售后及水平事业毛利覆盖总费用",
                code: "C0408013",
                type: "line",smooth,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },

    },
    chart_1_7_1_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
        },
        kpiCodes: [
            {
                code: "C0107003",
                name: "资产负债率",
            }],
        rows: [],
        options: {
            legend: {
                show: true,
                data: [],
            },
            calculable: false,
            tooltip: {
                show: true,
                trigger: "item",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params, v) {
                    let val = "<span style=\"background-color:" + params.color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + Number(params.value).toFixed(1) + "%";
                    return `${params.name}:<br /> ${val}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [
                {
                    name: "资产负债率",
                    code: "C0107003",
                    type: "bar",
                  barMaxWidth,
                    stack: "total",
                    // 不同柱不同颜色
                    itemStyle: {
                        normal: {
                            color: (params) => {
                                return colors[params.dataIndex];
                            },
                        },
                    },
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                    markLine: {
                        symbol: "none", //去掉警戒线最后面的箭头

                        data: [{
                            silent: false,             //鼠标悬停事件  true没有，false有
                            name: "区域标杆",
                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                //   type:"solid",
                                color: "#5470C6",
                                width: 2,
                            },
                            label: {
                                show: false,
                                textStyle: {
                                    color: "black",
                                },
                                formatter: function (params) {
                                    return "区域标杆(" + Number(params.value).toFixed(1) + "%)";
                                },
                                position: "start",          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                            },
                            yAxis: null,           // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                            code: "C0107003",
                            key: "RegionalStandard",
                        },
                            {
                                silent: true,             //鼠标悬停事件  true没有，false有
                                name: "全国标杆",
                                lineStyle: {               //警戒线的样式  ，虚实  颜色
                                    //    type:"solid",
                                    color: "#71BE95",
                                    width: 2,
                                },
                                label: {
                                    position: "middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                    show: false,
                                    textStyle: {
                                        color: "black",
                                    },
                                    formatter: function (params) {
                                        return "全国标杆(" + Number(params.value).toFixed(1) + "%)";
                                    },
                                },
                                yAxis: null,        // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                code: "C0107003",
                                key: "NationwideStandard",
                            },
                        ],
                    },
                },
            ],
        },
    },
    chart_1_7_2_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
            // type: "histogram",
            // showLine: ["现金车金额占期末库存车金额比"],
            // yAxisType: ["0.[00]", "0.[00]%"],
            // yAxisName: ["元", "%"],
            // axisSite: {
            //     right: ["现金车金额占期末库存车金额比"],
            // },
        },
        kpiCodes: [
            {
                code: "A0205035",
                name: "平均单车财务费用",
            }, {
                code: "A0403091",
                name: "现金车金额占期末库存车金额比",
            }],
        rows: [],
        options: {
            legend: {
                data: [],
            },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params) {
                    let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>平均单车财务费用: " + (params[0].value / 10000).toFixed(2) + "万元",
                        val2 = "<span style=\"background-color:" + params[1].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>现金车金额占期末库存车金额比： " + Number(params[1].value).toFixed(1
                        ) + "%";
                    return `${params[0].axisValue} <br /> ${val1} <br />${val2}`;
                },
            },
            xAxis,
            yAxis: [yAxisTenThousandYuan, yAxisRightUnit('%')],
            series: [{
                name: "平均单车财务费用",
                code: "A0205035",
                type: "bar",
              barMaxWidth,
                // 不同柱不同颜色
                itemStyle: {
                    normal: {
                        color: (params) => {
                            return colors[params.dataIndex];
                        },
                    },
                },
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return (params.value / 10000).toFixed(2);
                        },
                    },
                },
            }, {
                name: "现金车金额占期末库存车金额比",
                code: "A0403091",
                type: "line",smooth,
                yAxisIndex: 1,
                label: {
                    normal: {
                        show: true,
                        //   position:'insideRight',
                        textStyle: {
                            color: "black",
                        },
                        formatter: function (params) {
                            return Number(params.value).toFixed(1) + "%";
                        },
                    },
                },
            }],
        },
    },
    chart_1_7_3_Data: {
        /*设置*/
        settings: {
            type: "funnel",
            monthChart: true,
        },
        kpiCodes: [
            {
                code: "A0404111",
                name: "长库龄新车库存台次占比",
            }],
        rows: [],
        options: {
            legend: {
                show: true,
            },
            calculable: false,
            tooltip: {
                show: true,
                trigger: "item",
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                },
                formatter: function (params, v) {
                    let val = "<span style=\"background-color:" + params.color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + Number(params.value).toFixed(1) + "%";
                    return `${params.name}:<br /> ${val}`;
                },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('%')],
            series: [
                {
                    name: "长库龄新车库存台次占比",
                    code: "A0404111",
                    type: "bar",
                  barMaxWidth,
                    stack: "total",
                    // 不同柱不同颜色
                    itemStyle: {
                        normal: {
                            color: (params) => {
                                return colors[params.dataIndex];
                            },
                        },
                    },
                    data: [],
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (params) {
                                return Number(params.value).toFixed(1) + "%";
                            },
                        },
                    },
                    markLine: {
                        symbol: "none", //去掉警戒线最后面的箭头

                        data: [{
                            silent: false,             //鼠标悬停事件  true没有，false有
                            name: "区域标杆",
                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                //   type:"solid",
                                color: "#5470C6",
                                width: 2,
                            },
                            label: {
                                show: false,
                                textStyle: {
                                    color: "black",
                                },
                                formatter: function (params) {
                                    return "区域标杆(" + Number(params.value).toFixed(1) + "%)";
                                },
                                position: "middle",          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                            },
                            yAxis: null,           // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                            code: "A0404111",
                            key: "RegionalStandard",
                        },
                            {
                                silent: true,             //鼠标悬停事件  true没有，false有
                                name: "全国标杆",
                                lineStyle: {               //警戒线的样式  ，虚实  颜色
                                    //    type:"solid",
                                    color: "#71BE95",
                                    width: 2,
                                },
                                label: {
                                    position: "middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                    show: false,
                                    textStyle: {
                                        color: "black",
                                    },
                                    formatter: function (params) {
                                        return "全国标杆(" + Number(params.value).toFixed(1) + "%)";
                                    },
                                },
                                yAxis: null,        // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                code: "A0404111",
                                key: "NationwideStandard",

                            },
                        ],
                    },
                },
            ],
        },
    },
};

export {storeReportOverAll};
