<template>
    <section>
        <a-card class="card" style="margin-top: 18px;">
            <a-table
                rowKey="AccountName"
                :columns="columns"
                :data-source="tableData"
            >
                <span slot="Exists" slot-scope="text">{{ text === 1 ? "是" : "否" }}</span>
                <span slot="Action" slot-scope="record">
                      <a-popconfirm
                          title="确定创建账户吗？"
                          @confirm="() => doCreate(record)"
                      >
                   <a-button
                       v-if="record.Exists === 0"
                       size="small"
                       type="primary"
                   >创建账户</a-button
                   >
                  </a-popconfirm>
                   <a-button size="small" type="primary" v-if="record.Exists === 1" @click="doEdit(record.UserId)"
                   >编辑</a-button>
                  <a-popconfirm
                      title="确定重置密码吗？"
                      @confirm="() => doReset(record.UserId)"
                  >
                    <a-button size="small" type="primary" v-if="record.Exists === 1"
                    >重置密码</a-button>
                  </a-popconfirm>
                 <a-tooltip title="Aa@0123456" placement="top">
                  初始密码
                   </a-tooltip>
                </span>
            </a-table>
        </a-card>
        <div class="foot-pad30"></div>
    </section>
</template>

<script>
const columns = [
    {
        title: '序号',
        width: '60px',
        customRender: (text, record, index) => `${index + 1}`,
    },
    {
        title: "说明",
        dataIndex: "Remark",
        key: "Remark",
    },
    {
        title: "账号",
        dataIndex: "AccountName",
        key: "AccountName",
    },
    {
        title: "岗位",
        dataIndex: "PositionName",
        key: "PositionName",
    }, {
        title: "权限",
        dataIndex: "PermissionName",
        key: "PermissionName",
    },
    {
        title: "是否存在",
        dataIndex: "Exists",
        key: "Exists",
        scopedSlots: {customRender: 'Exists'},
    },
    {
        title: "操作",
        key: "Action",
        scopedSlots: {customRender: "Action"},
    },
];

export default {
    data() {
        return {
            //表格数据
            tableData: [],
            columns,
        };
    },
    methods: {
        doEdit(UserId){
            this.$router.push("/humanMannage/form?userID=" + UserId);
        },
        async doReset(userId) {
            let self = this;
            let res = await self.$Http.resetPwd({userId: userId});
            if (res.Success) {
                self.$message.success("密码重置成功，初始密码: Aa@0123456");
                self.getList()
            } else {
                self.$message.error(res.Message);
            }

        },
        async doCreate(record) {
            let self = this;
            if (record.Exists === 1) {
                this.$message.error("该账户已创建");
                return
            }
            let res = await self.$Http.CreateDealerAccountManage({configId: record.Id});
            if (res.Success) {
                self.$message.success("创建账户成功");
                self.getList()
            } else {
                self.$message.error(res.Message);
            }
        },
        //获取列表
        async getList() {
            let self = this;
            //将分页参数传入form对象
            let res = await self.$Http.QueryDealerAccountManage();
            self.tableData = res.Data;
        },


    },
    created() {
    },
    mounted() {
        this.getList();
    },
};
</script>
