import moment from "moment";

export function numFormat(key, value, unit) {
    if (value == null) {
        value = 0;
    }
    if (unit === "%") {
        return Number((value * 100).toFixed(1)).toLocaleString("zh-CN") + "%";
    } else if (unit === "元" && (key.indexOf("平均") === 0 || key.indexOf("店均") === 0)) {
        return Number((value).toFixed(0)).toLocaleString("zh-CN");
    } else if (unit === "万元") {
        return (value / 10000).toFixed(2);
    } else if (unit === "取整") {
        return value.toFixed(0).toLocaleString("zh-CN");
    } else {
        return Number(value.toFixed(2)).toLocaleString("zh-CN");
    }
}

export function descFormat(currentValue, beforeValue, unit, tips, onlyTip, calcDiff) {
    let tipIndex;
    let style;
    if (currentValue > beforeValue) {
        tipIndex = 0;
        style = "color:#1890FF;font-weight: bold";
    } else if (currentValue < beforeValue) {
        tipIndex = 1;
        style = "color:#24619e;font-weight: bold";
    } else {
        tipIndex = 2;
        if (tips && tips.length === 2)
            tips.push("持平");
        style = "font-weight:bold";
    }
    let tip = tips ? tips[tipIndex] ?? "" : "";
    if (onlyTip)
        return `<span style="${style}">${tip}</span>`;

    let value = calcDiff ? currentValue - beforeValue : currentValue;
    if (unit === "%") {
        value = Number((value * 100).toFixed(1));
    } else if (unit === "元") {
        if (Math.abs(value) > 1_0000) {
            unit = "万元";
            value = Number((value / 10000).toFixed(2));
        } else {
            value = Number((value).toFixed(0));
        }
    } else if (unit === "台" || unit === "个") {
        value = Number((value).toFixed(0));
    } else {
        value = Number(value.toFixed(2));
    }
    if (calcDiff && value === 0) {
        value = "";
    } else {
        value = value.toLocaleString("zh-CN") + " " + unit;
    }
    if (tips == null)
        style = "font-weight:bold";
    if (tip === "持平") {
        if (value) {
            return `<span style="${style}">${tip} (为 ${value})</span>`;
        } else {
            return `<span style="${style}">${tip}</span>`;
        }
    }
    return `<span style="${style}">${tip} ${value}</span>`;
}

const emptyTemplate = {
    Unit: "未知",
    NationwideStandard: 0,
    RegionalStandard: 0,
    Current: 0,
    LastMonth: 0,
    LastYear: 0,
    CurrentYear: 0,
    QOQ: 0,
    YOY: 0,
    Flag: "-",
    YearSum: 0,
};

export function getEmptyTemplate() {
    return emptyTemplate;
}

/*
* 2021年3月22日----ZHANGJINGWU更新规则
* 单位是%的，需保留一位小数
* 以”平均”开头的，“元”为单位的，保留整数
* 不以”平均”开头的，并且以“元”为单位的,单位修改为“万元”，并保留两位小数
* 其他数据保留两位即可
*/
export function formatDataNumber(data, columnName) {
    if (data == null)
        data = Object.assign({}, emptyTemplate);
    if (data.formated)
        return data;
    let func;
    //小数点后保留数量
    let fixedNum;
    data.SrcUnit = data.Unit;
    if (data.Unit === "%") {
        fixedNum = 1;
        func = value => Number((value * 100).toFixed(fixedNum)).toLocaleString("zh-CN");
    } else if (data.Unit === "元" && columnName.indexOf("平均") === 0) {
        fixedNum = 0;
        func = value => Number((value).toFixed(fixedNum)).toLocaleString("zh-CN");
    } else if (data.Unit === "台") {
        fixedNum = 0;
        func = value => Number((value).toFixed(fixedNum)).toLocaleString("zh-CN");
    } else if (data.Unit === "个") {
        fixedNum = 0;
        func = value => Number((value).toFixed(fixedNum)).toLocaleString("zh-CN");
    } else if (data.Unit === "次") {
        fixedNum = 1;
        func = value => Number((value).toFixed(fixedNum)).toLocaleString("zh-CN");
    } else if (data.Unit === "元" && columnName.indexOf("平均") !== 0) {
        fixedNum = 2;
        func = value => Number((value / 10000).toFixed(fixedNum)).toLocaleString("zh-CN");
        data.Unit = "万元";
    } else {
        func = value => Number(value.toFixed(2)).toLocaleString("zh-CN");
    }
    Object.keys(data).forEach(key => {
        let value = data[key];
        if (typeof value === "number") {
            //补全零，为什么呢，因为tofixed的方法完了之后又调用了Number方法会自动屏蔽最后几位的0，但是不转换又不行，toLocaleString方法是Number的方法而不是String的方法
            let tmpStrValue = func(value);
            //补0的字符串
            let fix0Str = "";
            //拆分小数点前后字符串
            if (fixedNum === 1 || fixedNum === 2) {
                let strValue = tmpStrValue.split(".");
                if (strValue.length === 2) {
                    //如果有小数点，则只有18.2这种需要补齐
                    if (strValue[1].length < fixedNum && strValue[1].indexOf("%") === -1) {
                        //生成所需0的个数
                        for (let i = 0; i < fixedNum - strValue[1].length; i++) {
                            fix0Str = fix0Str.concat("0");
                        }
                    }
                } else {
                    fix0Str = ".";
                    //生成所需0的个数
                    for (let i = 0; i < fixedNum; i++) {
                        fix0Str = fix0Str.concat("0");
                    }
                }
                if (data.Unit === "%") {
                    fix0Str = fix0Str.concat("%");
                }
            }
            tmpStrValue = tmpStrValue.concat(fix0Str);
            data[key + "Text"] = tmpStrValue;
        }
    });
    data.formated = true;
    return data;
}

function loadTableData(dataObj, data) {
    let rows = [];
    dataObj.table.forEach(column => {
        let kpi = data[column.kpiCode];
        /*20210629 把单位 换掉以保证不变格式化*/
        // if (column.kpiCode === "A0107004_avg" || column.kpiCode==="A0107014_avg") {
        //     kpi.Unit = "个";
        // }
        kpi = formatDataNumber(kpi, column.name);
        /*得到数据在把单位换回来*/
        // if (column.kpiCode === "A0107004_avg" || column.kpiCode==="A0107014_avg") {
        //     kpi.Unit = "元";
        // }
        Object.assign(kpi, column);
        rows.push(kpi);
    });
    dataObj.data = rows;
}

export async function loadReportData(root, data, chartNameRelations, reqPars) {
    if (reqPars == null || reqPars.DealerCode == null || reqPars.DealerCode.length <= 0)
        return;

    let dataKeys = Object.keys(data);
    let filterCodes = {};
    dataKeys.forEach(key => {
        let dataObj = data[key];
        if (key.startsWith("table")) {
            // 表格
            dataObj.table.forEach(column => filterCodes[column.kpiCode] = null);
        } else if (key.startsWith("chart")) {
            // 图表
            dataObj.kpiCodes.forEach(kpiCode => filterCodes[kpiCode.code] = null);
        } else {
            // 对象
            Object.keys(dataObj).forEach(kpiCode => filterCodes[kpiCode] = null);
        }
    });
    reqPars.filterCodes = Object.keys(filterCodes);
    let res = await root.$Http.GetDealerKpiReportPost(reqPars);
    if (!res.Success) {
        root.$message.error(res.Message);
        res.Data = {};
    }
    // 加载数据
    let reportDate = moment(reqPars.ReportDate);
    let months = root._.range(4).chain().map(i => {
        let month = {};
        month.index = i;
        month.name = reportDate.format("MM月");
        reportDate.add(-1, "month");
        return month;
    }).value().reverse();
    let monthNames = months.chain().map(m => m.name).value();

    dataKeys.forEach(key => {
            let dataObj = data[key];
            if (key.startsWith("table")) {
                // 表格
                loadTableData(dataObj, res.Data);
            } else if (key.startsWith("chart")) {
                // 图表
                if (dataObj.settings.type === "funnel") {
                    //Question:为什么部分柱状图数据处理逻辑写在这？
                    //Answer：因为vcharts只有funnel图表才会options完整覆盖
                    if (dataObj.settings.monthChart) {
                        //给图例赋值
                        dataObj.options.legend.data = dataObj.kpiCodes.chain().pluck("name").value();
                        //修改数据
                        dataObj.options.xAxis.forEach(x => x.data = monthNames);
                        dataObj.options.series.forEach(item => {
                            let currentData = [];
                            let kpiData = res.Data[item.code];
                            months.forEach(month => {
                                let value = 0;
                                if (kpiData != null) {
                                    value = month.index === 0 ? kpiData["Current"] : kpiData["N-" + month.index];
                                    if (kpiData.Unit === "%") {
                                        value = (value * 100).toFixed(2);
                                    }
                                }
                                currentData.push(value);
                            });
                            item.data = currentData;
                            //修改警戒线数据
                            if (item.markLine) {
                                item.markLine.data.forEach(i => {
                                    let value = 0;
                                    let kpiData = res.Data[i.code];
                                    if (kpiData != null) {
                                        value = kpiData[i.key];
                                        if (kpiData.Unit === "%") {
                                            value = (value * 100).toFixed(2);
                                        }
                                    }
                                    i.yAxis = value;
                                });
                            }
                        });
                        return;
                    }
                    // 多漏斗逻辑从此开始！！！！！！！！！！！！！！！！！！！！！！！！！！
                    let rows = [];
                    dataObj.kpiCodes.forEach(kpiCode => {
                        let kpi = res.Data[kpiCode.code] ?? emptyTemplate;
                        rows.push({
                            "状态": kpiCode.name,
                            "数值": kpi.Current,
                        });
                    });
                    dataObj.columns = ["状态", "数值"];
                    dataObj.rows = rows;
                    /*zyzhan 多漏斗*/
                    Object.keys(chartNameRelations).forEach((key) => {
                        /*  try {
                              console.log(dataObj.options.series);
                          }
                          catch(ex) {
                              console.log(key);
                          }*/
                        dataObj.options.series.forEach((s) => {
                            if (key === s.name) {
                                //  console.log(key); // 全国标杆
                                //   console.log(chartNameRelations[key]); // NationwideStandard
                                let optionsData = [];
                                dataObj.kpiCodes.forEach(kpiCode => {
                                    let kpi = res.Data[kpiCode.code] ?? emptyTemplate;
                                    optionsData.push({
                                        name: kpiCode.name,
                                        value: kpi[chartNameRelations[key]],
                                    });
                                });
                                s.data = optionsData;
                            }
                        });
                    });
                } else {
                    const keyName = "类目";
                    let columns = [keyName];
                    dataObj.kpiCodes.forEach(kpiCode => columns.push(kpiCode.name));
                    let rows = [];
                    let chart_XALIS = dataObj.chart_XALIS ?? chartNameRelations;
                    Object.keys(chart_XALIS).forEach(key => {
                        let row = {};
                        row[keyName] = key;
                        dataObj.kpiCodes.forEach(kpiCode => {
                            let kpi = res.Data[kpiCode.code] ?? emptyTemplate;
                            row[kpiCode.name] = kpi[chart_XALIS[key]];
                        });
                        rows.push(row);
                    });
                    dataObj.columns = columns;
                    dataObj.rows = rows;
                    // if (dataObj.options && dataObj.options.series.chain().any(d => d.markLine).value()) {
                    //     // 如果有警戒线配置项
                    //     dataObj.options.series[0]["markLine"].data.forEach(item => {
                    //         item.yAxis = res.Data[item.code][item.yAxis]
                    //         // if (res.Data[item.code]){
                    //         //     item.yAxis = res.Data[item.code][item.yAxis]
                    //         // }else {
                    //         //     item.yAxis = 0
                    //         // }
                    //     })
                    // }
                }
            } else {
                // 对象
                Object.keys(dataObj).forEach(kpiCode => {
                    let kpi = res.Data[kpiCode];
                    dataObj[kpiCode] = formatDataNumber(kpi, "");
                });
            }
        },
    );
}

const avg_suffix = "_avg";

export async function loadAreaReportData(root, data, reqPars) {
    if (reqPars == null)
        reqPars = {};

    let dataKeys = Object.keys(data);
    let tableFilterCodes = {};
    let chartFilterCodes = {};
    let tableKeys = [];
    let chartKeys = [];
    let specialChartKeys = [];
    dataKeys.forEach(key => {
        let dataObj = data[key];
        if (key.startsWith("table")) {
            // 全部计算均值 2021-04-15 按需调整
            if (!dataObj.avged) {
                dataObj.table.forEach(column => column.kpiCode += avg_suffix);
                dataObj.avged = true;
            }
            // 记录 code
            dataObj.table.forEach(column => tableFilterCodes[column.kpiCode] = null);
            tableKeys.push(key);
        } else if (key.startsWith("specialChart")) {
            chartFilterCodes[dataObj.chartField.Code] = null;
            specialChartKeys.push(key);
        } else if (key.startsWith("chart")) {
            dataObj.chartField.forEach(field => {
                // 全部计算均值 2021-04-15 按需调整
                if (!field.avged) {
                    if (field.formula)
                        field.includeCodes = field.includeCodes.chain().map(code => code + avg_suffix).value();
                    else {
                        field.Code += avg_suffix;
                    }
                    field.avged = true;
                }
                // 记录 code
                if (field.formula) {
                    field.includeCodes.forEach(code => chartFilterCodes[code] = null);
                } else {
                    chartFilterCodes[field.Code] = null;
                }
            });
            chartKeys.push(key);
        }
    });

    // 加载表格数据
    let getTableDataPars = Object.assign({}, reqPars);
    getTableDataPars.filterCodes = Object.keys(tableFilterCodes);
    let res = await root.$Http.GetAreaDealerKpiReport(getTableDataPars);
    let tableValues;
    if (!res.Success) {
        root.$message.error(res.Message);
        tableValues = {};
    } else {
        tableValues = res.Data;
    }
    Object.values(tableValues).forEach((v) => {
        if (v.Unit === "%") {
            //环比
            v.QOQ = v.Current - v.LastMonth;
            //同比
            v.YOY = v.Current - v.LastYear;
        } else {
            //环比
            v.QOQ = CalcRate(v.Current, v.LastMonth);
            //同比
            v.YOY = CalcRate(v.Current, v.LastYear);
        }
        //处理同比环比数据
        let dealData = (value) => {
            let tmpData = Number((value * 100).toFixed(1)).toLocaleString("zh-CN");
            if (tmpData.split(".").length === 1) {
                tmpData = tmpData.concat(".0");
            }
            return tmpData.concat("%");
        };
        v.QOQ = dealData(v.QOQ);
        v.YOY = dealData(v.YOY);
    });
    tableKeys.forEach(key => loadTableData(data[key], tableValues));

    // 加载图表数据
    let getChartDataPars = Object.assign({}, reqPars);
    getChartDataPars.filterCodes = Object.keys(chartFilterCodes);
    res = await root.$Http.GetAreaDealerKpiReportWithMonth(getChartDataPars);

    let chartValues;
    if (!res.Success) {
        root.$message.error(res.Message);
        chartValues = {NormalValue: {}, SpecialValue: {}};
    } else {
        chartValues = res.Data;
    }
    specialChartKeys.forEach(key => {
            let chart = data[key];
            let chartData = chartValues.SpecialValue[chart.chartField.Code];
            let rows = [];
            for (let month = 0; month <= 12; month++) {
                let date = month === 0 ? "月均" : month + "月";
                let row = {"日期": date};
                let monthValues = chartData && chartData.Values ? chartData.Values[month] : null;
                chart.chartField.Columns.forEach(column => {
                    if (typeof column == "string") {
                        let value = 0;
                        if (monthValues != null)
                            value = monthValues[column] ?? 0;
                        row[column] = value === 0 ? null : value;
                    } else {
                        let value = chartValues.NormalValue[column.Code][month];
                        row[column.Name] = value === 0 ? null : value;
                    }
                });
                rows.push(row);
            }
            chart.columns = ["日期", ...chart.chartField.Columns.chain().map(c => typeof c == "string" ? c : c.Name).value()];
            chart.rows = rows;
        },
    );
    chartKeys.forEach(key => {
        let chart = data[key];
        let columns = chart.chartField.chain().map(field => {
            // 是否需要计算指标
            if (field.formula && chartValues.NormalValue[field.Code] == null) {
                let values = [];
                for (let month = 0; month <= 12; month++) {
                    let pars = field.includeCodes.chain().map(includeCode => {
                        let value = 0;
                        let monthValues = chartValues.NormalValue[includeCode];
                        if (monthValues != null)
                            value = monthValues[month] ?? 0;
                        return value === 0 ? null : value;
                    }).value();
                    values[month] = field.formula(pars);
                }
                chartValues.NormalValue[field.Code] = values;
            }
            return field.Name;
        }).value();
        let rows = [];
        for (let month = 0; month <= 12; month++) {
            let date = month === 0 ? "月均" : month + "月";
            let row = {"日期": date};
            chart.chartField.forEach(column => {
                let value = 0;
                let monthValues = chartValues.NormalValue[column.Code];
                if (monthValues != null)
                    value = monthValues[month] ?? 0;
                row[column.Name] = value === 0 ? null : value;
            });
            rows.push(row);
        }
        chart.columns = ["日期", ...columns];
        chart.rows = rows;
    });
    data.resTable = tableValues;
    data.resChart = chartValues;
}

export function GetDesc(rootData, code, tips, onlyTip, calcDiff, values) {
    let data = rootData.resTable[code + avg_suffix];
    /*if(code === "B0107002") { // 零服吸收率
      console.log(505,data);
    }*/
    if (data == null)
        return "<b>-</b>";

    let currentValue, beforeValue;
    if (values && values.length === 2) {
        currentValue = typeof (values[0]) == "string" ? data[values[0]] : values[0];
        beforeValue = typeof (values[1]) == "string" ? data[values[1]] : values[1];
    } else {
        currentValue = data.Current;
        beforeValue = data.LastMonth;
    }
    return descFormat(currentValue, beforeValue, data.SrcUnit, tips, onlyTip, calcDiff);
}

export function CalcDiff(rootData, code1, code2) {
    // console.log(rootData.resTable)
    // console.log(code1 + avg_suffix)
    // console.log(code2)
    // console.log(rootData.resTable[code1 + avg_suffix])
    // console.log(rootData.resTable[code2 + avg_suffix])
    let data1 = rootData.resTable[code1 + avg_suffix];
    let data2 = rootData.resTable[code2 + avg_suffix];
    if (data1 != null && data2 != null)
        return descFormat(data1.Current, data2.Current, data1.SrcUnit, null, false, true);
    return "<b>-</b>";
}

export function CalcRate(currentValue, beforeValue) {
    return beforeValue === 0 ? 0 : (currentValue - beforeValue) / Math.abs(beforeValue);
}
