<template>
  <a-row :guter="[28,0]">
    <!--item1   各渠道线索占比及同比 -->
    <a-col :span="24">
      <a-card>
        <div class="bt_chart">
          <a-col :span="8">
            <h2>各渠道线索占比及同比 【IDCC线索总数: <span>500%</span>】</h2>
          </a-col>
          <!--          <a-col :span="18" class="right">
                      <a-tooltip>
                        <template slot="title">
                          选择渠道
                        </template>
                        <a-tree-select
                          v-model="checkData.checkData1.model"
                          style="width:550px;"
                          :tree-data="checkData.checkData1.data"
                          tree-checkable
                          :treeDefaultExpandedKeys="checkData.checkData1.default"
                          search-placeholder="请选择"
                        />
                      </a-tooltip>
                      <a-tooltip>
                        <template slot="title">
                          选择车型
                        </template>
                        <a-tree-select
                          v-model="checkData.checkCarData.model"
                          style="width:240px;"
                          :tree-data="checkData.checkCarData.data"
                          tree-checkable
                          :treeDefaultExpandedKeys="checkData.checkCarData.default"
                          search-placeholder="请选择"
                        />
                      </a-tooltip>
                      <span></span>
                      <a-button type="primary" @click="chart1Change">
                        生成图表
                      </a-button>
                    </a-col>-->
          <a-col style="float: right">
            <a-button type="primary" @click="showModal">
              选择条件
            </a-button>
          </a-col>

        </div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData1"/>
        </div>
      </a-card>
    </a-col>
    <!-- 选择条件面板 -->
    <a-modal :width="600" v-model="isView" title="条件筛选" :footer="null">
      <table class="modal-style">
        <tr>
          <td>渠道:</td>
          <td>
            <a-checkbox-group @change="onCheckChange"
                              v-model="checkData.checkData1.model"
                              name="channel"
            >
              <a-row>
                <a-col :span="6" v-for="(item,i) in checkData.checkData1.car" :key="i++">
                  <a-checkbox :value="item">
                    {{ item }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </td>
        </tr>
        <tr>
          <td>车型:</td>
          <td>
            <a-checkbox-group @change="onCheckChange"
                              v-model="checkData.checkCarData.model"
                              name="car"
            >
              <a-row>
                <a-col :span="6" v-for="(item,i) in checkData.checkCarData.car" :key="i++">
                  <a-checkbox :value="item">
                    {{ item }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </td>
        </tr>
      </table>
      <div style="text-align: center">
        <a-button type="primary" @click="chart1Change()">
          确定
        </a-button>
        <a-button type="primary" @click="isView=false">
          取消
        </a-button>
      </div>
    </a-modal>
  </a-row>
</template>

<script>
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import { grid, itemStyle, lineStyle, symbolSize } from "@/views/ModelAnalysis/chartConfig";
import { percentage } from "@/until/option";
import {
  axisLine,
  axisTick, barMaxWidth, color, fontSize,
  legend,
  splitLine,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import moment from "moment";
import underscore from "underscore";
import { TreeSelect } from "ant-design-vue";

export default {
  name: "thereSalesProcess",
  props: {
    dataParams: {},
  },
  components: { mainChart },
  data() {
    return {
      lineStyle, symbolSize, itemStyle, grid,
      isView: false,
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      checkData: {
        checkData1: {
          car: ["汽车之家", "易车", "懂车帝", "太平洋", "广宣线索", "自店", "其他"],
          data: [
            {
              title: "所有渠道",
              value: "所有渠道",
              key: "所有渠道",
              children: [],
            },
          ],
          default: ["所有渠道"],
          model: ["汽车之家", "易车", "懂车帝", "太平洋", "广宣线索", "自店", "其他"],
        },
        checkCarData: {
          car: ["GS3", "GS3 Power", "GS4", "GS4 PHEV", "GS4 COUPE", "GS4 PLUS", "GS7", "GS8", "GS8S", "M6", "M8", "GA4", "GA6", "GA8","影豹","其他"],
          data: [
            {
              title: "所有车型",
              value: "所有车型",
              key: "所有车型",
              children: [],
            },
          ],
          default: ["所有车型"],
          model: ["GS3"],
        },
      },
      chartData: {
        chartData1: {
          columns: [],
          rows: [],
          setting: {
            type: "histogram",
            stack: { val: [] },
            showLine: [],
            axisSite: { right: [] },
            yAxisType: ["0.[0]%"],
            yAxisName: ["%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return percentage(params.data);
              },
            },
          },
          options: {
            legend: {
              type: "scroll",
              /* orient: "vertical",
               x: "left",      //可设定图例在左、右、居中
               y: "center",     //可设定图例在上、下、居中*/
              itemHeight: 22,
              // itemWidth: 100,
              textStyle: {
                color: "black",
                fontSize: 12,
              },
            },
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if (d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
                  }
                  if (d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis: {
              axisLabel: {
                interval: 0,
                //   rotate: 90,
              },
              axisLine,
            },
            yAxis: [
              {
                splitLine,
                axisLine,
                axisTick,
              },
            ],
            series: [
              {
                barMaxWidth,
              },
            ],
          },
        },
      },
      months: [],
    };
  },
  created() {
    this.SysCheckData();
    this.getChartData1();
  },
  methods: {
    chart1Change() {
      this.getChartData1();
      this.isView = false;
    },
    onCheckChange() {
    },
    async getChartData1() {
      /* let res = await this.$Http.CountryInsure(
         {
           yearMonth: this.dataParams.ReportDate,
           parmId: this.parmId,
         },
       );
       if (!res.Data) {
         return false;
       }*/
      // console.log(this.checkData.checkData1.model);
      // console.log(this.checkData.checkCarData.model);
      let data = moment(this.dataParams.ReportDate).add(-13, "M");
      this.months = underscore.range(13).map(() => data.add(1, "M").format("Y-M"));
      let chart = this.chartData.chartData1;
      chart.columns = [];
      chart.setting.stack.val = [];
      chart.setting.showLine = [];
      chart.setting.axisSite.right = [];
      let vl = {
        a: "总线索占比",
        b: "总线索同比",
      };

      this.checkData.checkData1.model.map(d => {
        this.checkData.checkCarData.model.map(car => {
          chart.columns.push(car + d);
          chart.setting.stack.val.push(car + d);// 堆积图
        });
      });
      this.checkData.checkData1.model.map(d => {
        Object.values(vl).map(l => {
          chart.columns.push(d + l);
          chart.setting.showLine.push(d + l);
          chart.setting.axisSite.right.push(d + l);
        });
      });

      let x = [];
      this.months.map(m => {
        x.push(m);
      });
      chart.rows = x.map(m => {
        let row = {};
        row["日期"] = m;
        chart.columns.map(c => {
          row[c] = this.randomData();
        });
        return row;
      });
      chart.columns.reverse();
      chart.columns.push("日期");
      chart.columns.reverse();

    },
    SysCheckData() {
      Object.values(this.checkData).forEach(c => {
        c.data[0].children = c.car.map(d => {
          let arr = {};
          arr.title = d;
          arr.value = d;
          arr.key = d;
          return arr;
        });
      });
    },
    randomData() {
      return this._.random(0, 100);
    },
    showModal() {
      this.isView = true;
    },
  },
  watch: {
    dataParams: {
      handler: function() {
        this.getChartData1();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
h2 {
  span {
    color: red;font-weight: 600;
    }
  }

.right {
  span {
    margin-left: 10px;
    }
  }

.bt_chart {
  padding-bottom: 20px;overflow: hidden;
  }

.carding {
  margin-top: 10px;
  }


.modal-style {
  tr {
    padding: 5px 0;

    td {
      padding: 10px 0;

      &:first-child {
        width: 40px;
        }
      }


    }
  }
</style>
