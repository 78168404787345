<template>
  <section>
    <a-table :columns="columnsData"
             :data-source="tableData.data" bordered :pagination="false" :rowKey="(record,index)=> index++"
    >
      <div slot="name" slot-scope="text,record" :style="record.indentation>0 ? 'paddingLeft:'+record.indentation+'em':''">
        {{ record.name }}
      </div>
    </a-table>
    <div class="remark" v-if="remark" style="text-indent: 34em">备注:当月值与全国标杆值对比</div>
  </section>
</template>

<script>
export default {
  name: "aCustomTable",
  props: {
    tableData: {
      data: {
        type: Array,
      },
    },
    columnsData: {},
    remark: Boolean,
  },
};
</script>
<style lang="less" scoped>
/*控制表格*/
::v-deep {
  
  .ant-table-thead > tr > th {
    padding: 5px 5px 5px 7px;
    //  border-bottom: 2px solid #86A3A1 !important;
    border-right: 0 solid #ffffff !important;
    border-left: 0 solid #ffffff !important;
    font-size: 12px;
    background: #33554A;
    color: #fff;
    text-align: right;
    
    &:first-child {
      text-align: left;
      }
    
    &:nth-child(2) {
      text-align: left; // 只是这里不同,其他 和 ATable一样
      }
    }
  
  .ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table {
    // border-top: 2px solid #86A3A1;
    border-bottom: 1px solid #86A3A1;
    border-left: 0;
    }
  
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: none;
    }
  
  .ant-table-tbody .ant-table-row {
    &:nth-child(even) {
      background: #E6ECEC !important;
      }
    
    &:hover {
      td {
        color: #405E5C !important;
        background: #adcbca !important;
        //    box-shadow: 1px 2px 5px 1px #768e86
        }
        
      }
    
    td {
      font-size: 12px;
      padding: 5px 5px;
      text-align: right;
      border-right: 0 solid #fff;
      border-bottom: 0 solid #fff;
      color: #3E5E5C;
      
      &:first-child {
        text-align: left;
        }
      
      &[rowspan] {
        text-align: center;
        
        + td {
          text-align: left;
          }
        }
        
      }
    }
  }
</style>

