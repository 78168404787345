<template>
  <a-row :guter="[28,28]">
    <!-- 进销存 -->
    <a-col :span="24">
      <a-card>
        <div class="title">进销存</div>
        <table>
          <thead>
          <th>时间</th>
          <th colspan="2">{{ dataParams.date.month === 0 ? "上年底" : "上月底" }}</th>
          <th colspan="11">当前值</th>
          </thead>
          <tr>
            <th v-for="(item,i) in tableData.table_1.columnsData" :key="i++">
              {{ item.title }}
            </th>
          </tr>
          <tbody>
          <tr>
            <th v-for="(item,i) in tableData.table_1.columnsData" :key="i++" style="background: #fff !important;">
              {{ item.unit }}
            </th>
          </tr>
          <tr v-for="(item,i) in tableData.table_1.data" :key="i++">
            <td>
              {{ item["carName"] }}
            </td>
            <td> {{ ThousandYuan(item["0"]) }}</td>
            <td> {{ ThousandYuan(item["1"]) }}</td>
            <td> {{ ThousandYuan(item["2"]) }}</td>
            <td> {{ ThousandYuan(item["3"]) }}</td>
            <td> {{ ThousandYuan(item["4"]) }}</td>
            <td> {{ percentage(item["5"]) }}</td>
            <td> {{ ThousandYuan(item["6"]) }}</td>
            <td> {{ ThousandYuan(item["7"]) }}</td>
            <td> {{ ThousandYuan(item["8"]) }}</td>
            <td> {{ ThousandYuan(item["9"]) }}</td>
            <td style="width: 8%"> {{ percentage(item["10"]) }}</td>
            <td> {{ ThousandYuan(item["11"]) }}</td>
            <td style="width: 8%"> {{ Yuan(item["12"]) }}</td>
          </tr>
          <tr>
            <td>合计</td>
            <td v-for="(item,d) in tableData.table_1.sumData" :key="d++">
              {{ item }}
            </td>
          </tr>
          </tbody>
        </table>
      </a-card>
    </a-col>
    <!--  线索      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">线索</div>
        <table>
          <thead>
          <th v-for="(item,k) in tableData.table_2.columnsData" :style="'text-align: center;background:'+item.color" :key="k++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,i) in tableData.table_2.data" :key="i++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <!--   各车型线索总量分布      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型线索总量分布</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData1"/>
        </div>
      </a-card>
    </a-col>
    <!--客流        -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">客流</div>
        <table>
          <thead>
          <th v-for="(item,k) in tableData.table_3.columnsData" :style="'text-align: center;background:'+item.color" :key="k++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,j) in tableData.table_3.data" :key="j++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <!--   各车型客流分布      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型客流分布</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData7"/>
        </div>
      </a-card>
    </a-col>
    <!--订单        -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">订单</div>
        <table>
          <thead>
          <th v-for="(item,k) in tableData.table_8.columnsData" :style="'text-align: center;background:'+item.color" :key="k++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,j) in tableData.table_8.data" :key="j++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>

    <!--   各车型订单分析      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型订单分析</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData2"/>
        </div>
      </a-card>
    </a-col>

    <!--终端        -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">终端</div>
        <table>
          <thead>
          <th v-for="(item,j) in tableData.table_4.columnsData" :style="'text-align: center;background:'+item.color" :key="j++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,t) in tableData.table_4.data" :key="t++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>

    <!--   各车型终端分析      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型终端分析</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData3"/>
        </div>
      </a-card>
    </a-col>

    <!--提车         -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">提车</div>
        <table>
          <thead>
          <th v-for="(item,j) in tableData.table_5.columnsData" :style="'text-align: center;background:'+item.color" :key="j++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,t) in tableData.table_5.data" :key="t++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>

    <!--   各车型提车      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型提车</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData.chartData4"/>
        </div>
      </a-card>
    </a-col>

    <!--库存         -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">库存</div>
        <table>
          <thead>
          <th v-for="(item,i) in tableData.table_6.columnsData" :style="'text-align: center;background:'+item.color" :key="i++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,t) in tableData.table_6.data" :key="t++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <!--   各车型库存分析      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型库存分析</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData_two.chartData5"/>
        </div>
      </a-card>
    </a-col>
    <!--剩余订单         -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">剩余订单</div>
        <table>
          <thead>
          <th v-for="(item,j) in tableData.table_7.columnsData" :style="'text-align: center;background:'+item.color" :key="j++">
            {{ item.name }}
          </th>
          </thead>
          <tr>
            <td style="text-align: center" v-for="(item,b) in tableData.table_7.data" :key="b++">
              {{ item }}
            </td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <!--   各车型剩余订单      -->
    <a-col class="carding" :span="24">
      <a-card>
        <div class="title">各车型剩余订单</div>
        <div class="clear-both">
          <main-chart :chart-data="chartData_two.chartData6"/>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import underscore from "underscore";
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";
import { grid, itemStyle, lineStyle, symbolSize } from "@/views/ModelAnalysis/chartConfig";
import {
  axisLine, xAxis, legend,
  axisTick, yAxisDefault, yAxisRight,
  barMaxWidth, seriesBar, seriesLine,
  splitLine,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";

export default {
  name: "oneRealTime",
  components: { mainChart },
  props: {
    dataParams: {
      data: {},
      date: {},
    },
  },
  data() {
    return {
      lineStyle, symbolSize, itemStyle, grid, ThousandYuan, percentage,
      tableData: {
        table_1: {
          columnsData: [
            {
              title: "车型",
              unit: "单位",
            },
            {
              title: "库存",
              unit: "台",
            },
            {
              title: "剩余订单",
              unit: "台",
            },
            {
              title: "总线索",
              unit: "条",
            },
            {
              title: "总客流",
              unit: "个",
            },
            {
              title: "新增订单",
              unit: "个",
            },
            {
              title: "到店成交率",
              unit: "%",
            },
            {
              title: "退订数",
              unit: "个",
            },
            {
              title: "提车实绩",
              unit: "台",
            },
            {
              title: "终端实绩",
              unit: "台",
            },
            {
              title: "库存",
              unit: "台",
            },
            {
              title: "剩余订单匹配率",
              unit: "%",
            },
            {
              title: "终端让价",
              unit: "元",
            },
            {
              title: "单车裸车毛利",
              unit: "元",
            },
          ],
          kpiCodes: {
            0: ["A1402172", "A1402192", "A1202092", "A1303402", "A1303442+A1202172+A1202202+A1202232", "A1303292_avg", "A1402122", "A1402142", "A0303312", "A0402018", "A1402222_avg", "A1403023_avg", "A0105052_avg"],
            1: ["A1402173", "A1402193", "A1202093", "A1303403", "A1303443+A1202173+A1202203+A1202233", "A1303293_avg", "A1402123", "A1402143", "A0303313", "A0402024", "A1402223_avg", "A1403024_avg", "A0105053_avg"],
            2: ["A1402176", "A1402196", "A1202096", "A1303406", "A1303446+A1202176+A1202206+A1202236", "A1303296_avg", "A1402126", "A1402146", "A0303316", "A0402042", "A1402226_avg", "A1403027_avg", "A0105056_avg"],
            3: ["A1402178", "A1402198", "A1202098", "A1303408", "A1303448+A1202178+A1202208+A1202238", "A1303298_avg", "A1402128", "A1402148", "A0303318", "A0402054", "A1402228_avg", "A1403029_avg", "A0105058_avg"],
            4: ["A1402180", "A1402200", "A1202100", "A1303410", "A1303450+A1202180+A1202210+A1202240", "A1303300_avg", "A1402130", "A1402150", "A0303320", "A0402115", "A1402230_avg", "A1403031_avg", "A0105060_avg"],
            5: ["A1402181", "A1402201", "A1202101", "A1303411", "A1303451+A1202181+A1202211+A1202241", "A1303301_avg", "A1402131", "A1402151", "A0303321", "A0402121", "A1402231_avg", "A1403032_avg", "A0105061_avg"],
            6: ["A1402182", "A1402202", "A1202102", "A1303412", "A1303452+A1202182+A1202212+A1202242", "A1303302_avg", "A1402132", "A1402152", "A0303322", "A0402127", "A1402232_avg", "A1403033_avg", "A0105062_avg"],
            7: ["A1402183", "A1402203", "A1202103", "A1303413", "A1303453+A1202183+A1202213+A1202243", "A1303303_avg", "A1402133", "A1402153", "A0303323", "A0402133", "A1402233_avg", "A1403034_avg", "A0105063_avg"],
            8: ["A1402184", "A1402204", "A1202104", "A1303414", "A1303454+A1202184+A1202214+A1202244", "A1303304_avg", "A1402134", "A1402154", "A0303324", "A0402147", "A1402234_avg", "A1403035_avg", "A0105064_avg"],
            9: ["A1402186", "A1402206", "A1202106", "A1303416", "A1303455+A1202185+A1202215+A1202245", "A1303305_avg", "A1402136", "A1402156", "A0303325", "A0402172", "A1402236_avg", "A1403036_avg", "A0105065_avg"],
            10: ["A1402187", "A1402207", "A1202105", "A1303415", "A1303456+A1202186+A1202216+A1202246", "A1303306_avg", "A1402137", "A1402157", "A0303326", "A0402178", "A1402237_avg", "A1403037_avg", "A0105066_avg"],
          },
          totalCodes: ["A1402185", "A1402205", "A1302115", "A1303045", "A1303071", "A1303083", "A1402135", "A1402155", "A0302001", "A0402006", "A1402235_avg", "A1403021_avg", "A0106026_avg"],
          data: [],
          sumData: [],
        },
        table_2: {
          columnsData: [
            {
              name: "总线索",
              kpiCode: "A1302115",
              unit: "条",
            }, {
              name: "总垂媒线索",
              kpiCode: "E1202091+E1202092+E1202093+E1202094",
              unit: "条",
            }, {
              name: "汽车之家",
              kpiCode: "E1202091",
              unit: "条",
            }, {
              name: "易车",
              kpiCode: "E1202092",
              unit: "条",
            }, {
              name: "懂车帝",
              kpiCode: "E1202093",
              unit: "条",
            }, {
              name: "太平洋",
              kpiCode: "E1202094",
              unit: "条",
            }, {
              name: "广宣线索",
              kpiCode: "E1202095",
              unit: "条",
            }, {
              name: "自店",
              kpiCode: "E1202099",
              unit: "条",
            }, {
              name: "其他",
              kpiCode: "E1202096+E1202097+E1202098",
              unit: "条",
            }, {
              name: "垂媒线索占比",
              color: "#199A70",
              kpiCode: "",
              unit: "%",
            }, {
              name: "日均总线索",
              color: "#2B821D",
              kpiCode: "A1302115/30", // 总线索数/30天
              unit: "条",
            }, {
              name: "日均垂媒线索",
              color: "#2B821D",
              kpiCode: "(E1202091+E1202092+E1202093+E1202094)/30",
              unit: "条",
            },
          ],
          data: [],
        },
        table_3: {
          columnsData: [
            {
              name: "总客流",
              kpiCode: "A1303045",
              unit: "个",
            }, {
              name: "展厅客流",
              kpiCode: "A1303043",
              unit: "个",
            }, {
              name: "展厅首次客流",
              kpiCode: "A1302012",
              unit: "个",
            }, {
              name: "展厅再次客流",
              kpiCode: "A1302013",
              unit: "个",
            }, {
              name: "再次进店率",
              kpiCode: "A1303007_avg",
              unit: "%",
            }, {
              name: "IDCC到店客流总数",
              color: "#2B821D",
              kpiCode: "A1302028",
              unit: "个",
            },
          ],
          data: [],
        },
        table_4: {
          columnsData: [
            {
              name: "终端目标",
              kpiCode: "A0402003",
              unit: "台",
            }, {
              name: "终端实绩",
              kpiCode: "A0302001",
              unit: "台",
            }, {
              name: "展厅实绩",
              kpiCode: "A0302021",
              unit: "台",
            }, {
              name: "展厅占比",
              kpiCode: "A0304024_avg",
              unit: "%",
            },
            {
              name: "IDCC实绩",
              kpiCode: "A0302022",
              unit: "台",
            },
            {
              name: "IDCC占比",
              kpiCode: "A0304025_avg",
              unit: "%",
            }, {
              name: "二网实绩",
              kpiCode: "A0302023",
              unit: "台",
            }, {
              name: "二网占比",
              kpiCode: "A0304026_avg",
              unit: "%",
            }, {
              name: "大客户实绩",
              kpiCode: "A0302024",
              unit: "台",
            }, {
              name: "大客户占比",
              kpiCode: "A0304027_avg",
              unit: "%",
            }, {
              name: "终端达成率",
              color: "#199A70",
              kpiCode: "A0403002_avg",
              unit: "%",
            }, {
              name: "日均终端",
              color: "#2B821D",
              kpiCode: "A0302001/30",
              unit: "台",
            },
          ],
          data: [],
        },
        table_5: {
          columnsData: [
            {
              name: "提车目标",
              kpiCode: "A0402001",
              unit: "台",
            }, {
              name: "提车实绩",
              kpiCode: "A1402155",
              unit: "台",
            }, {
              name: "提车达成率",
              color: "#199A70",
              kpiCode: "A0403001_avg",
              unit: "%",
            }, {
              name: "日均提车",
              color: "#2B821D",
              kpiCode: "A1402155/30",
              unit: "台",
            },
          ],
          data: [],
        },
        table_6: {
          columnsData: [
            {
              name: "总库存",
              kpiCode: "A0402006",
              unit: "台",
            }, {
              name: "90天以下库存",
              kpiCode: "A0402007+A0402008+A0402009",
              unit: "台",
            }, {
              name: "长库龄库存",
              kpiCode: "A0403155",
              unit: "台",
            }, {
              name: "长库龄占比",
              kpiCode: "A0404111_avg",
              unit: "%",
            }, {
              name: "融资车台次",
              kpiCode: "",
              unit: "台",
            }, {
              name: "现金车台次",
              kpiCode: "A0403152",
              unit: "台",
            }, {
              name: "现金车占比",
              kpiCode: "A0404181_avg",
              unit: "%",
            }, {
              name: "库销比",
              kpiCode: "A0404045_avg",
              unit: "倍",
            },
          ],
          data: [],
        },
        table_7: {
          columnsData: [
            {
              name: "剩余订单",
              kpiCode: "",
              unit: "个",
            }, {
              name: "匹配库存的剩余订单",
              kpiCode: "",
              unit: "个",
            }, {
              name: "未能匹配库存的剩余订单",
              kpiCode: "",
              unit: "个",
            }, {
              name: "剩余订单匹配率",
              color: "#199A70",
              kpiCode: "A1402235_avg",
              unit: "%",
            },
          ],
          data: [],
        },
        table_8: {
          columnsData: [
            {
              name: "总订单",
              kpiCode: "A1303071",
              unit: "个",
            }, {
              name: "展厅订单",
              kpiCode: "A1303080",
              unit: "个",
            }, {
              name: "展厅首次客流订单",
              kpiCode: "A1302014",
              unit: "个",
            }, {
              name: "展厅再次客流订单",
              kpiCode: "A1302111",
              unit: "个",
            }, {
              name: "IDCC订单",
              kpiCode: "A1302032",
              unit: "个",
            }, {
              name: "二网订单",
              kpiCode: "A1302121",
              unit: "个",
            }, {
              name: "大客户订单",
              kpiCode: "A1302131",
              unit: "个",
            }, {
              name: "零售订单占比",
              kpiCode: "",
              unit: "%",
            }, {
              name: "日均订单",
              kpiCode: "A1303071/30",
              unit: "个",
            },
          ],
          data: [],
        },
      },
      car: ["GS3 Power", "GS4", "GS4 PLUS",  "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
      chartData: {
        chartData1: {
          columns: ["车型", "汽车之家", "易车", "懂车帝", "太平洋", "广宣线索", "自店", "其他", "总线索同比", "总线索环比"],
          rows: [],
          kpiCodes: {
            0: ["E1202282", "E1202302", "E1202322", "E1202342", "E1202362", "E1202442", "E1202382+E1202402+E1202422"],
            1: ["E1202283", "E1202303", "E1202323", "E1202343", "E1202363", "E1202443", "E1202383+E1202403+E1202423"],
            2: ["E1202286", "E1202306", "E1202326", "E1202346", "E1202366", "E1202446", "E1202386+E1202406+E1202426"],
            3: ["E1202288", "E1202308", "E1202328", "E1202348", "E1202368", "E1202448", "E1202388+E1202408+E1202428"],
            4: ["E1202290", "E1202310", "E1202330", "E1202350", "E1202370", "E1202450", "E1202390+E1202410+E1202430"],
            5: ["E1202291", "E1202311", "E1202331", "E1202351", "E1202371", "E1202451", "E1202391+E1202411+E1202431"], // m6
            6: ["E1202292", "E1202312", "E1202332", "E1202352", "E1202372", "E1202452", "E1202392+E1202412+E1202432"],
            7: ["E1202293", "E1202313", "E1202333", "E1202353", "E1202373", "E1202453", "E1202393+E1202413+E1202433"],
            8: ["E1202294", "E1202314", "E1202334", "E1202354", "E1202374", "E1202454", "E1202394+E1202414+E1202434"],
            9: ["E1202296", "E1202316", "E1202336", "E1202356", "E1202376", "E1202456", "E1202396+E1202416+E1202436"],
            10: ["E1202295", "E1202315", "E1202335", "E1202355", "E1202375", "E1202455", "E1202395+E1202415+E1202435"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["汽车之家", "易车", "懂车帝", "太平洋", "广宣线索", "自店", "其他"] },
            showLine: ["总线索同比", "总线索环比"],
            axisSite: { right: ["总线索同比", "总线索环比"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["条", "%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            legend,
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(1)}条`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number(((d.value ?? 0) * 100)).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesLine, seriesLine],
          },
        },
        chartData2: {
          columns: ["车型", "展厅首次客流订单", "展厅再次客流订单", "IDCC订单", "二网订单", "大客户订单", "总订单同比", "总订单环比"],
          rows: [],
          kpiCodes: {
            0: ["A1202042", "A1202062", "A1202172", "A1202202", "A1202232", "A1303442+A1202172+A1202202+A1202232"],
            1: ["A1202043", "A1202063", "A1202173", "A1202203", "A1202233", "A1303443+A1202173+A1202203+A1202233"],
            2: ["A1202046", "A1202066", "A1202176", "A1202206", "A1202236", "A1303446+A1202176+A1202206+A1202236"],
            3: ["A1202048", "A1202068", "A1202178", "A1202208", "A1202238", "A1303448+A1202178+A1202208+A1202238"],
            4: ["A1202050", "A1202070", "A1202180", "A1202210", "A1202240", "A1303450+A1202180+A1202210+A1202240"],
            5: ["A1202051", "A1202071", "A1202181", "A1202211", "A1202241", "A1303451+A1202181+A1202211+A1202241"],
            6: ["A1202052", "A1202072", "A1202182", "A1202212", "A1202242", "A1303452+A1202182+A1202212+A1202242"],
            7: ["A1202053", "A1202073", "A1202183", "A1202213", "A1202243", "A1303453+A1202183+A1202213+A1202243"],
            8: ["A1202054", "A1202074", "A1202184", "A1202214", "A1202244", "A1303454+A1202184+A1202214+A1202244"],
            9: ["A1202055", "A1202075", "A1202185", "A1202215", "A1202245", "A1303455+A1202185+A1202215+A1202245"],
            10: ["A1202056", "A1202076", "A1202186", "A1202216", "A1202246", "A1303456+A1202186+A1202216+A1202246"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["展厅首次客流订单", "展厅再次客流订单", "IDCC订单", "二网订单", "大客户订单"] },
            showLine: ["总订单同比", "总订单环比"],
            axisSite: { right: ["总订单同比", "总订单环比"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["个", "%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(1)}个`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number(((d.value ?? 0) * 100)).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesLine, seriesLine],
          },
        },
        chartData3: {
          columns: ["车型", "展厅", "IDCC", "二网", "大客户", "终端同比", "终端环比"],
          rows: [],
          kpiCodes: {
            0: ["A0302141", "A0302161", "A0302181", "A0302201"],
            1: ["A0302142", "A0302162", "A0302182", "A0302202"],
            2: ["A0302145", "A0302165", "A0302185", "A0302205"],
            3: ["A0302147", "A0302167", "A0302187", "A0302207"],
            4: ["A0302149", "A0302169", "A0302189", "A0302209"],
            5: ["A0302150", "A0302170", "A0302190", "A0302210"],
            6: ["A0302151", "A0302171", "A0302191", "A0302211"],
            7: ["A0302152", "A0302172", "A0302192", "A0302212"],
            8: ["A0302153", "A0302173", "A0302193", "A0302213"],
            9: ["A0302154", "A0302174", "A0302194", "A0302214"],
            10: ["A0302155", "A0302175", "A0302195", "A0302215"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["展厅", "IDCC", "二网", "大客户"] },
            showLine: ["终端同比", "终端环比"],
            axisSite: { right: ["终端同比", "终端环比"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["台", "%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            legend,
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(1)}台`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number(((d.value ?? 0) * 100)).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesBar, seriesBar, seriesLine, seriesLine, seriesLine],
          },
        },
        chartData4: {
          columns: ["车型", "提车台次", "提车同比", "提车环比"],
          rows: [],
          kpiCodes: {
            0: ["A1402142"],
            1: ["A1402143"],
            2: ["A1402146"],
            3: ["A1402148"],
            4: ["A1402150"],
            5: ["A1402151"],
            6: ["A1402152"],
            7: ["A1402153"],
            8: ["A1402154"],
            9: ["A1402156"],
            10: ["A1402157"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["提车台次"] },
            showLine: ["提车同比", "提车环比"],
            axisSite: { right: ["提车同比", "提车环比"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["台", "%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            legend,
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}台`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number(((d.value ?? 0) * 100)).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesLine, seriesLine],
          },
        },
        chartData7: {
          columns: ["车型", "首次客流", "再次客流", "IDCC到店客流", "总客流同比", "总客流环比"],
          rows: [],
          kpiCodes: {
            0: ["A1202002", "A1202022", "A1202152"],
            1: ["A1202003", "A1202023", "A1202153"],
            2: ["A1202006", "A1202026", "A1202156"],
            3: ["A1202008", "A1202028", "A1202158"],
            4: ["A1202010", "A1202030", "A1202160"],
            5: ["A1202011", "A1202031", "A1202161"],
            6: ["A1202012", "A1202032", "A1202162"],
            7: ["A1202013", "A1202033", "A1202163"],
            8: ["A1202014", "A1202034", "A1202164"],
            9: ["A1202016", "A1202036", "A1202166"],
            10: ["A1202015", "A1202035", "A1202165"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["首次客流", "再次客流", "IDCC到店客流"] },
            showLine: ["总客流同比", "总客流环比"],
            axisSite: { right: ["总客流同比", "总客流环比"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["个", "%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            legend: {
              type: "scroll",
              /* orient: "vertical",
               x: "left",      //可设定图例在左、右、居中
               y: "center",     //可设定图例在上、下、居中*/
              itemHeight: 22,
              // itemWidth: 100,
              textStyle: {
                color: "black",
                fontSize: 12,
              },
            },
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(1)}个`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number(((d.value ?? 0) * 100)).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesBar, seriesBar, seriesLine, seriesLine],
          },
        },
      },
      chartData_two: {
        chartData5: {
          columns: ["车型", "库存30天以下", "库存30~60天", "库存60~90天", "库存＞90天", "库存＞120天", "库销比", "长库龄占比"],
          rows: [],
          kpiCodes: {
            0: ["A0402019", "A0402020", "A0402021", "A0402022", "", "A0404092", "A0404113"],
            1: ["A0402025", "A0402026", "A0402027", "A0402028", "", "A0404093", "A0404114"],
            2: ["A0402043", "A0402044", "A0402045", "A0402046", "", "A0404096", "A0404117"],
            3: ["A0402055", "A0402056", "A0402057", "A0402058", "", "A0404098", "A0404119"],
            4: ["A0402116", "A0402117", "A0402118", "A0402119", "", "A0404100", "A0404121"],
            5: ["A0402122", "A0402123", "A0402124", "A0402125", "", "A0404101", "A0404122"],
            6: ["A0402128", "A0402129", "A0402130", "A0402131", "", "A0404102", "A0404123"],
            7: ["A0402134", "A0402135", "A0402136", "A0402137", "", "A0404103", "A0404124"],
            8: ["A0402148", "A0402149", "A0402150", "A0402171", "", "A0404104", "A0404125"],
            9: ["A0402173", "A0402174", "A0402175", "A0402176", "", "A0404105", "A0404126"],
            10: ["A0402179", "A0402180", "A0402181", "A0402182", "", "A0404106", "A0404127"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["库存30天以下", "库存30~60天", "库存60~90天", "库存＞90天", "库存＞120天"] },
            showLine: ["库销比", "长库龄占比"],
            axisSite: { right: ["库销比", "长库龄占比"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["台", "%"],
            label: {
              show: false, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(1)}台`;
                  }
                  if(d.componentSubType === "line") {
                    if(d.seriesName === "库销比") {
                      arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(3)).toFixed(2)}倍`;
                    } else {
                      arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                    }
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesLine, seriesLine],
          },
        },
        chartData6: {
          columns: ["车型", "匹配库存的剩余订单", "未能匹配库存的剩余订单", "剩余订单匹配率"],
          rows: [],
          kpiCodes: {
            0: ["", "", "A1402222_avg"],
            1: ["", "", "A1402223_avg"],
            2: ["", "", "A1402226_avg"],
            3: ["", "", "A1402228_avg"],
            4: ["", "", "A1402230_avg"],
            5: ["", "", "A1402231_avg"],
            6: ["", "", "A1402232_avg"],
            7: ["", "", "A1402233_avg"],
            8: ["", "", "A1402234_avg"],
            9: ["", "", "A1402236_avg"],
            10: ["", "", "A1402237_avg"],
          },
          setting: {
            type: "histogram",
            stack: { val: ["匹配库存的剩余订单", "未能匹配库存的剩余订单"] },
            showLine: ["剩余订单匹配率"],
            axisSite: { right: ["剩余订单匹配率"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            yAxisName: ["个", "%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                if(params.componentSubType === "line") {
                  return percentage(params.data);
                } else {
                  return ThousandYuan(params.data);
                }
              },
            },
          },
          options: {
            legend,
            grid,
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(1)}个`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0) * 100).toFixed(2)).toFixed(1)}%`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefault, yAxisRight],
            series: [seriesBar, seriesBar, seriesLine],
          },
        },
      },
    };
  },
  created() {
    this.getTableData();
    this.getChartData();
  },
  methods: {
    getTableData() {
      let LastValues = this.dataParams.data["LastValues"];
      let CurrentValues = this.dataParams.data["CurrentValues"];
      /*table_1*/
      this.tableData.table_1.data = [];
      this.car.map((d, i) => {
            let arr = {};
            arr["carName"] = d;
            underscore.range(13).map(m => {
              if(m < 2) {
                arr[m] = CurrentValues[this.tableData.table_1.kpiCodes[i][m]];
              } else {
                arr[m] = CurrentValues[this.tableData.table_1.kpiCodes[i][m]] === undefined ? "-" : CurrentValues[this.tableData.table_1.kpiCodes[i][m]];
              }
            });
            this.tableData.table_1.data.push(arr);
          },
      );
      /*table_1 合计*/
      this.tableData.table_1.sumData = [];
      this.tableData.table_1.sumData = this.tableData.table_1.totalCodes.chain().map(d => {
        if(d === "A1303083" || d === "A1402235_avg") {
          return percentage(CurrentValues[d]);
        } else {
          return this.Yuan(CurrentValues[d]);
        }
      }).value();

      /* 一行table的 统一取数 */
      let oneColumnsTable = ["table_2", "table_3", "table_4", "table_5", "table_6", "table_7", "table_8"];
      oneColumnsTable.forEach(table => {
        this.tableData[table].data = [];
        this.tableData[table].columnsData.forEach(d => {
          let vau = CurrentValues[d.kpiCode];
          if(d.unit === "%") {
            vau = percentage(vau);
          } else if(d.unit === "倍") {
            vau = Number(vau ?? 0).toFixed(2) + d.unit;
          } else {
            vau = ThousandYuan(vau) + d.unit;
          }
          if(d.kpiCode === "") {
            vau = "-";
          }
          this.tableData[table].data.push(vau);
        });
      });

    },
    getChartData() {
      let CurrentValues = this.dataParams.data["CurrentValues"];
      let RateValues = this.dataParams.data["RateValues"];
      Object.keys(this.chartData).forEach(chart => {
        this.chartData[chart].rows = [];
        this.car.map((c, i) => {
          let arr = {};
          this.chartData[chart].setting.stack.val.map((d, j) => {
            if(this.chartData[chart].kpiCodes[i][j] === "") {
              arr[d] = "-";
            } else {
              arr[d] = CurrentValues[this.chartData[chart].kpiCodes[i][j]] ?? null;
              if(arr[d] === 0) arr[d] = null;
            }
          });
          this.chartData[chart].setting.showLine.map((d, j) => {
            let ab = "RingRate";
            if(j === 1) ab = "YearOnYear";
            if(chart === "chartData2") {
              if(RateValues[this.chartData[chart].kpiCodes[i][this.chartData[chart].kpiCodes[0].length - 1]]) {
                arr[d] = RateValues[this.chartData[chart].kpiCodes[i][this.chartData[chart].kpiCodes[0].length - 1]][ab] ?? null;
              } else {
                arr[d] = 0;
              }
              if(arr[d] === 0) arr[d] = null;
            } else {
              if(this.chartData[chart].kpiCodes[i][0] === "") {
                arr[d] = "-";
              } else {
                if(RateValues[this.chartData[chart].kpiCodes[i][0]]) {
                  arr[d] = RateValues[this.chartData[chart].kpiCodes[i][0]];
                } else {
                  arr[d] = 0;
                }
                if(arr[d] === 0) arr[d] = null;
              }
            }

          });
          arr["车型"] = c;
          this.chartData[chart].rows.push(arr);
        });
      });

      Object.keys(this.chartData_two).forEach(chart => {
        this.chartData_two[chart].rows = [];
        this.car.map((c, i) => {
          let arr = {};
          let column = this.chartData_two[chart].setting.stack.val.concat(this.chartData_two[chart].setting.showLine);
          column.map((d, j) => {
            arr[d] = CurrentValues[this.chartData_two[chart].kpiCodes[i][j]] ?? null;
            if(arr[d] === 0) arr[d] = null;
          });
          arr["车型"] = c;
          this.chartData_two[chart].rows.push(arr);
        });
      });
    },
    randomData() {
      return this._.random(100, 300);
    },
    Yuan(val) {
      return Number(Number(val ?? 0).toFixed(1)).toFixed(0);
    },
  },
  watch: {
    dataParams: {
      handler: function() {
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "realTime";
</style>
