<template>
  <div :style="`height:${chartHeight?chartHeight:'420'}px;`" class="chart" ref="chart_gauge"></div>
</template>

<script>
import * as echarts5 from "echarts5";

export default {
  name: "gaugeChart",
  props: {
    chartHeight: String,
    chartValue: Number,
    maxValue: String,
    splitNumberValue: String,
  },
  data() {
    return {
      op_gauge: {
        series: [{
          type: "gauge",
          center: ["50%", "60%"],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          splitNumber: 12,
          itemStyle: {
            color: "#FFAB91",
          },
          progress: {
            show: true,
            width: 30,
          },

          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 30,
            },
          },
          axisTick: {
            distance: -45,
            splitNumber: 5,
            lineStyle: {
              width: 2,
              color: "#999",
            },
          },
          splitLine: {
            distance: -52,
            length: 14,
            lineStyle: {
              width: 3,
              color: "#999",
            },
          },
          axisLabel: {
            distance: -5,
            color: "#999",
            fontSize: 14,
          },
          anchor: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: "60%",
            lineHeight: 40,
            height: "15%",
            borderRadius: 8,
            offsetCenter: [0, "-15%"],
            fontSize: 20, // 显示文字的大小
            fontWeight: "bolder",
            formatter: "{value}%",
            color: "black",
          },
          data: [{
            value: 20,
          }],
        }, {
          type: "gauge",
          center: ["50%", "60%"],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          itemStyle: {
            color: "#FD7347",
          },
          progress: {
            show: true,
            width: 8,
          },

          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            show: false,
          },
          data: [{
            value: 20,
          }],

        },
        ],
      },
    };
  },
  methods: {
    Gauge() {
      /*使用 echarts5 */
      let myChartG = echarts5.init(this.$refs.chart_gauge);
      //   let random = (Math.random() * 60).toFixed(1) - 0;
      let chartValue = Number(this.chartValue).toFixed(1) || 0;
      this.op_gauge.series[0].data[0].value = chartValue;
      this.op_gauge.series[1].data[0].value = chartValue;
      this.op_gauge.series[0].max =  parseInt(this.maxValue) || 100;
      this.op_gauge.series[1].max =  parseInt(this.maxValue) || 100;
      this.op_gauge.series[0].splitNumber = parseInt(this.splitNumberValue) || 10;
      myChartG.setOption(this.op_gauge);
    },
  },
  watch: {
    chartValue: {
      handler: function() {
        this.Gauge();
      },
      deep: true,
    },
  },
  mounted() {
    this.Gauge();
  },
};
</script>

<style scoped>
.chart {
  width: auto;
  position: relative;
  top: -30px;
  }
</style>
