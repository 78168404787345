/**
 * Created by ZHANGJINGWU on 2021-07-21 11:05.
 */
const carTypeCode = {
    总线索: {
        所有车型: "A1302115",
        GS3: "A1202091",
        GS3POWER: "A1202092",
        GS4: "A1202093",
        GS4PHEV: "A1202094",
        GS4COUPE: "A1202095",
        GS4PLUS: "A1202096",
        GS7: "A1202097",
        GS8: "A1202098",
        GS8S: "A1202099",
        M6: "A1202100",
        M8: "A1202101",
        GA4: "A1202102",
        GA6: "A1202103",
        GA8: "A1202104",
        影豹: "A1202106",
        其他: "A1202105",
    },

    总客流: {
        所有车型: "A1303045",
        GS3: "A1303401",
        GS3POWER: "A1303402",
        GS4: "A1303403",
        GS4PHEV: "A1303404",
        GS4COUPE: "A1303405",
        GS4PLUS: "A1303406",
        GS7: "A1303407",
        GS8: "A1303408",
        GS8S: "A1303409",
        M6: "A1303410",
        M8: "A1303411",
        GA4: "A1303412",
        GA6: "A1303413",
        GA8: "A1303414",
        影豹: "A1303416",
        其他: "A1303415",
    },

    总订单: {
        所有车型: "A1303071",
        GS3: "A1303441+A1202171+A1202201+A1202231",
        GS3POWER: "A1303442+A1202172+A1202202+A1202232",
        GS4: "A1303443+A1202173+A1202203+A1202233",
        GS4PHEV: "A1303444+A1202174+A1202204+A1202234",
        GS4COUPE: "A1303445+A1202175+A1202205+A1202235",
        GS4PLUS: "A1303446+A1202176+A1202206+A1202236",
        GS7: "A1303447+A1202177+A1202207+A1202237",
        GS8: "A1303448+A1202178+A1202208+A1202238",
        GS8S: "A1303449+A1202179+A1202209+A1202239",
        M6: "A1303450+A1202180+A1202210+A1202240",
        M8: "A1303451+A1202181+A1202211+A1202241",
        GA4: "A1303452+A1202182+A1202212+A1202242",
        GA6: "A1303453+A1202183+A1202213+A1202243",
        GA8: "A1303454+A1202184+A1202214+A1202244",
        影豹: "A1303455+A1202185+A1202215+A1202245",
        其他: "A1303456+A1202186+A1202216+A1202246",
    },

    订单成交率: {
        所有车型: "A1303083_avg",
        GS3: "A1303291_avg",
        GS3POWER: "A1303292_avg",
        GS4: "A1303293_avg",
        GS4PHEV: "A1303294_avg",
        GS4COUPE: "A1303295_avg",
        GS4PLUS: "A1303296_avg",
        GS7: "A1303297_avg",
        GS8: "A1303298_avg",
        GS8S: "A1303299_avg",
        M6: "A1303300_avg",
        M8: "A1303301_avg",
        GA4: "A1303302_avg",
        GA6: "A1303303_avg",
        GA8: "A1303304_avg",
        影豹: "A1303305_avg",
        其他: "A1303306_avg",
    },

    终端实绩: {
        所有车型: "A0302001",
        GS3: "A0303311",
        GS3POWER: "A0303312",
        GS4: "A0303313",
        GS4PHEV: "A0303314",
        GS4COUPE: "A0303315",
        GS4PLUS: "A0303316",
        GS7: "A0303317",
        GS8: "A0303318",
        GS8S: "A0303319",
        M6: "A0303320",
        M8: "A0303321",
        GA4: "A0303322",
        GA6: "A0303323",
        GA8: "A0303324",
        影豹: "A0303325",
        其他: "A0303326",
    }
    ,
    终端达成率: {
        所有车型: "--",
        GS3: "--",
        GS3POWER: "--",
        GS4: "--",
        GS4PHEV: "--",
        GS4COUPE: "--",
        GS4PLUS: "--",
        GS7: "--",
        GS8: "--",
        GS8S: "--",
        M6: "--",
        M8: "--",
        GA4: "--",
        GA6: "--",
        GA8: "--",
        影豹: "--",
        其他: "--",
    }
    ,
    提车实绩: {
        所有车型: "A1402155",
        GS3: "A1402141",
        GS3POWER: "A1402142",
        GS4: "A1402143",
        GS4PHEV: "A1402144",
        GS4COUPE: "A1402145",
        GS4PLUS: "A1402146",
        GS7: "A1402147",
        GS8: "A1402148",
        GS8S: "A1402149",
        M6: "A1402150",
        M8: "A1402151",
        GA4: "A1402152",
        GA6: "A1402153",
        GA8: "A1402154",
        影豹: "A1402156",
        其他: "A1402157",
    }
    ,
    提车达成率: {
        所有车型: "--",
        GS3: "--",
        GS3POWER: "--",
        GS4: "--",
        GS4PHEV: "--",
        GS4COUPE: "--",
        GS4PLUS: "--",
        GS7: "--",
        GS8: "--",
        GS8S: "--",
        M6: "--",
        M8: "--",
        GA4: "--",
        GA6: "--",
        GA8: "--",
        影豹: "--",
        其他: "--",
    }
    ,
    库存: {
        所有车型: "A0402006",
        GS3: "A0402012",
        GS3POWER: "A0402018",
        GS4: "A0402024",
        GS4PHEV: "A0402030",
        GS4COUPE: "A0402036",
        GS4PLUS: "A0402042",
        GS7: "A0402048",
        GS8: "A0402054",
        GS8S: "A0402060",
        M6: "A0402115",
        M8: "A0402121",
        GA4: "A0402127",
        GA6: "A0402133",
        GA8: "A0402147",
        影豹: "A0402172",
        其他: "A0402178",
    }
    ,
    库销比: {
        所有车型: "A0404045_avg",
        GS3: "A0404091_avg",
        GS3POWER: "A0404092_avg",
        GS4: "A0404093_avg",
        GS4PHEV: "A0404094_avg",
        GS4COUPE: "A0404095_avg",
        GS4PLUS: "A0404096_avg",
        GS7: "A0404097_avg",
        GS8: "A0404098_avg",
        GS8S: "A0404099_avg",
        M6: "A0404100_avg",
        M8: "A0404101_avg",
        GA4: "A0404102_avg",
        GA6: "A0404103_avg",
        GA8: "A0404104_avg",
        影豹: "A0404105_avg",
        其他: "A0404106_avg",
    }
    ,
    长库龄占比: {
        所有车型: "--",
        GS3: "--",
        GS3POWER: "--",
        GS4: "--",
        GS4PHEV: "--",
        GS4COUPE: "--",
        GS4PLUS: "--",
        GS7: "--",
        GS8: "--",
        GS8S: "--",
        M6: "--",
        M8: "--",
        GA4: "--",
        GA6: "--",
        GA8: "--",
        影豹: "--",
        其他: "--",
    },

    现金车占比: {
        所有车型: "--",
        GS3: "--",
        GS3POWER: "--",
        GS4: "--",
        GS4PHEV: "--",
        GS4COUPE: "--",
        GS4PLUS: "--",
        GS7: "--",
        GS8: "--",
        GS8S: "--",
        M6: "--",
        M8: "--",
        GA4: "--",
        GA6: "--",
        GA8: "--",
        影豹: "--",
        其他: "--",
    }
    ,
    终端让价: {
        所有车型: "A1403021_avg",
        GS3: "A1403022_avg",
        GS3POWER: "A1403023_avg",
        GS4: "A1403024_avg",
        GS4PHEV: "A1403025_avg",
        GS4COUPE: "A1403026_avg",
        GS4PLUS: "A1403027_avg",
        GS7: "A1403028_avg",
        GS8: "A1403029_avg",
        GS8S: "A1403030_avg",
        M6: "A1403031_avg",
        M8: "A1403032_avg",
        GA4: "A1403033_avg",
        GA6: "A1403034_avg",
        GA8: "A1403035_avg",
        影豹: "A1403036_avg",
        其他: "A1403037_avg",
    },

    单车裸车毛利: {
        所有车型: "A0106026_avg",
        GS3: "A0105051_avg",
        GS3POWER: "A0105052_avg",
        GS4: "A0105053_avg",
        GS4PHEV: "A0105054_avg",
        GS4COUPE: "A0105055_avg",
        GS4PLUS: "A0105056_avg",
        GS7: "A0105057_avg",
        GS8: "A0105058_avg",
        GS8S: "A0105059_avg",
        M6: "A0105060_avg",
        M8: "A0105061_avg",
        GA4: "A0105062_avg",
        GA6: "A0105063_avg",
        GA8: "A0105064_avg",
        影豹: "A0105065_avg",
        其他: "A0105066_avg",
    },

    "垂媒线索": {
        所有车型: "E1202091+E1202092+E1202093+E1202094",
        GS3: "E1202281+E1202301+E1202321+E1202341",
        GS3POWER: "E1202282+E1202302+E1202322+E1202342",
        GS4: "E1202283+E1202303+E1202323+E1202343",
        GS4PHEV: "E1202284+E1202304+E1202324+E1202344",
        GS4COUPE: "E1202285+E1202305+E1202325+E1202345",
        GS4PLUS: "E1202286+E1202306+E1202326+E1202346",
        GS7: "E1202287+E1202307+E1202327+E1202347",
        GS8: "E1202288+E1202308+E1202328+E1202348",
        GS8S: "E1202289+E1202309+E1202329+E1202349",
        M6: "E1202290+E1202310+E1202330+E1202350",
        M8: "E1202291+E1202311+E1202331+E1202351",
        GA4: "E1202292+E1202312+E1202332+E1202352",
        GA6: "E1202293+E1202313+E1202333+E1202353",
        GA8: "E1202294+E1202314+E1202334+E1202354",
        影豹: "--",
        其他: "--",
    },

    "占比": {
        所有车型: "--",
        GS3: "--",
        GS3POWER: "--",
        GS4: "--",
        GS4PHEV: "--",
        GS4COUPE: "--",
        GS4PLUS: "--",
        GS7: "--",
        GS8: "--",
        GS8S: "--",
        M6: "--",
        M8: "--",
        GA4: "--",
        GA6: "--",
        GA8: "--",
        影豹: "--",
        其他: "--",
    },

    "客流总数": {
        所有车型: "A1303045",
        GS3: "A1303401",
        GS3POWER: "A1303402",
        GS4: "A1303403",
        GS4PHEV: "A1303404",
        GS4COUPE: "A1303405",
        GS4PLUS: "A1303406",
        GS7: "A1303407",
        GS8: "A1303408",
        GS8S: "A1303409",
        M6: "A1303410",
        M8: "A1303411",
        GA4: "A1303412",
        GA6: "A1303413",
        GA8: "A1303414",
        影豹: "A1303416",
        其他: "A1303415",
    },
    "基盘客户": {
        所有车型: "A1202191+A1202192+A1202193+A1202194",
        GS3: "A1202191+A1202192+A1202193+A1202194",
        GS3POWER: "A1202191+A1202192+A1202193+A1202194",
        GS4: "A1202191+A1202192+A1202193+A1202194",
        GS4PHEV: "A1202191+A1202192+A1202193+A1202194",
        GS4COUPE: "A1202191+A1202192+A1202193+A1202194",
        GS4PLUS: "A1202191+A1202192+A1202193+A1202194",
        GS7: "A1202191+A1202192+A1202193+A1202194",
        GS8: "A1202191+A1202192+A1202193+A1202194",
        GS8S: "A1202191+A1202192+A1202193+A1202194",
        M6: "A1202191+A1202192+A1202193+A1202194",
        M8: "A1202191+A1202192+A1202193+A1202194",
      //  GA4: "A1A1202191+A1202192+A1202193+A1202194303412",
        GA4: "A1202191+A1202192+A1202193+A1202194",
        GA6: "A1202191+A1202192+A1202193+A1202194",
        GA8: "A1202191+A1202192+A1202193+A1202194",
        影豹: "A1202191+A1202192+A1202193+A1202194",
        其他: "A1202191+A1202192+A1202193+A1202194",
    },
    "HAB客户": {
        所有车型: "A1202191+A1202192+A1202193",
        GS3: "A1202191+A1202192+A1202193",
        GS3POWER: "A1202191+A1202192+A1202193",
        GS4: "A1202191+A1202192+A1202193",
        GS4PHEV: "A1202191+A1202192+A1202193",
        GS4COUPE: "A1202191+A1202192+A1202193",
        GS4PLUS: "A1202191+A1202192+A1202193",
        GS7: "A1202191+A1202192+A1202193",
        GS8: "A1202191+A1202192+A1202193",
        GS8S: "A1202191+A1202192+A1202193",
        M6: "A1202191+A1202192+A1202193",
        M8: "A1202191+A1202192+A1202193",
        GA4: "A1202191+A1202192+A1202193",
        GA6: "A1202191+A1202192+A1202193",
        GA8: "A1202191+A1202192+A1202193",
        影豹: "A1202191+A1202192+A1202193",
        其他: "A1202191+A1202192+A1202193",
    },

    "订单总数": {
        所有车型: "A1303071",
        GS3: "A1303441+A1202171+A1202201+A1202231",
        GS3POWER: "A1303442+A1202172+A1202202+A1202232",
        GS4: "A1303443+A1202173+A1202203+A1202233",
        GS4PHEV: "A1303444+A1202174+A1202204+A1202234",
        GS4COUPE: "A1303445+A1202175+A1202205+A1202235",
        GS4PLUS: "A1303446+A1202176+A1202206+A1202236",
        GS7: "A1303447+A1202177+A1202207+A1202237",
        GS8: "A1303448+A1202178+A1202208+A1202238",
        GS8S: "A1303449+A1202179+A1202209+A1202239",
        M6: "A1303450+A1202180+A1202210+A1202240",
        M8: "A1303451+A1202181+A1202211+A1202241",
        GA4: "A1303452+A1202182+A1202212+A1202242",
        GA6: "A1303453+A1202183+A1202213+A1202243",
        GA8: "A1303454+A1202184+A1202214+A1202244",
        影豹: "A1303455+A1202185+A1202215+A1202245",
        其他: "A1303456+A1202186+A1202216+A1202246",
    },

    "单车终端让价": {
        所有车型: "A1403021_avg",
        GS3: "A1403022_avg",
        GS3POWER: "A1403023_avg",
        GS4: "A1403024_avg",
        GS4PHEV: "A1403025_avg",
        GS4COUPE: "A1403026_avg",
        GS4PLUS: "A1403027_avg",
        GS7: "A1403028_avg",
        GS8: "A1403029_avg",
        GS8S: "A1403030_avg",
        M6: "A1403031_avg",
        M8: "A1403032_avg",
        GA4: "A1403033_avg",
        GA6: "A1403034_avg",
        GA8: "A1403035_avg",
        影豹: "A1403036_avg",
        其他: "A1403037_avg",
    },

    库销比_table7: {
        GS3: "A0404091_avg",
        "GS3 Power": "A0404092_avg",
        GS4: "A0404093_avg",
        "GS4 PHEV": "A0404094_avg",
        "GS4 COUPE": "A0404095_avg",
        GS4PLUS: "A0404096_avg",
        GS7: "A0404097_avg",
        GS8: "A0404098_avg",
        GS8S: "A0404099_avg",
        M6: "A0404100_avg",
        M8: "A0404101_avg",
        GA4: "A0404102_avg",
        GA6: "A0404103_avg",
        GA8: "A0404104_avg",
        影豹: "A0404105_avg",
        其他: "A0404106_avg",
        合计: "A0404045_avg",
    },

    "0-30天库存_table7": {
        GS3: "A0402013",
        "GS3 Power": "A0402019",
        GS4: "A0402025",
        "GS4 PHEV": "A0402031",
        "GS4 COUPE": "A0402037",
        GS4PLUS: "A0402043",
        GS7: "A0402049",
        GS8: "A0402055",
        GS8S: "A0402061",
        M6: "A0402116",
        M8: "A0402122",
        GA4: "A0402128",
        GA6: "A0402134",
        GA8: "A0402148",
        影豹: "A0402173",
        其他: "A0402179",
        合计: "A0402007"

    },


    "31-60天库存_table7": {
        GS3: "A0402014",
        "GS3 Power": "A0402020",
        GS4: "A0402026",
        "GS4 PHEV": "A0402032",
        "GS4 COUPE": "A0402038",
        GS4PLUS: "A0402044",
        GS7: "A0402050",
        GS8: "A0402056",
        GS8S: "A0402062",
        M6: "A0402117",
        M8: "A0402123",
        GA4: "A0402129",
        GA6: "A0402135",
        GA8: "A0402149",
        影豹: "A0402174",
        其他: "A0402180",
        合计: "A0402008"
    },


    "61-90天库存_table7": {
        GS3: "A0402015",
        "GS3 Power": "A0402021",
        GS4: "A0402027",
        "GS4 PHEV": "A0402033",
        "GS4 COUPE": "A0402039",
        GS4PLUS: "A0402045",
        GS7: "A0402051",
        GS8: "A0402057",
        GS8S: "A0402063",
        M6: "A0402118",
        M8: "A0402124",
        GA4: "A0402130",
        GA6: "A0402136",
        GA8: "A0402150",
        影豹: "A0402175",
        其他: "A0402181",
        合计: "A0402009"
    },


    ">90天库存_table7": {
        GS3: "A0402016",
        "GS3 Power": "A0402022",
        GS4: "A0402028",
        "GS4 PHEV": "A0402034",
        "GS4 COUPE": "A0402040",
        GS4PLUS: "A0402046",
        GS7: "A0402052",
        GS8: "A0402058",
        GS8S: "A0402064",
        M6: "A0402119",
        M8: "A0402125",
        GA4: "A0402131",
        GA6: "A0402137",
        GA8: "A0402171",
        影豹: "A0402176",
        其他: "A0402182",
        合计: "A0402010"
    },


    "总库存_table7": {
        GS3: "A0402012",
        "GS3 Power": "A0402018",
        GS4: "A0402024",
        "GS4 PHEV": "A0402030",
        "GS4 COUPE": "A0402036",
        GS4PLUS: "A0402042",
        GS7: "A0402048",
        GS8: "A0402054",
        GS8S: "A0402060",
        M6: "A0402115",
        M8: "A0402121",
        GA4: "A0402127",
        GA6: "A0402133",
        GA8: "A0402147",
        影豹: "A0402172",
        其他: "A0402178",
        合计: "A0402006"

    },

    "其中-现金车台次_table7": {
        GS3: "--",
        "GS3 Power": "--",
        GS4: "--",
        "GS4 PHEV": "--",
        "GS4 COUPE": "--",
        GS4PLUS: "--",
        GS7: "--",
        GS8: "--",
        GS8S: "--",
        M6: "--",
        M8: "--",
        GA4: "--",
        GA6: "--",
        GA8: "--",
        影豹: "--",
        其他: "--",
        合计: "--",
    },

    //图表配置项
    chartData1: {
        汽车之家: {
            所有车型: "--",
            GS3: "E1202281",
            GS3POWER: "E1202282",
            GS4: "E1202283",
            GS4PHEV: "E1202284",
            GS4COUPE: "E1202285",
            GS4PLUS: "E1202286",
            GS7: "E1202287",
            GS8: "E1202288",
            GS8S: "E1202289",
            M6: "E1202290",
            M8: "E1202291",
            GA4: "E1202292",
            GA6: "E1202293",
            GA8: "E1202294",
            影豹: "E1202296",
            其他: "E1202295",
        },
        易车: {
            所有车型: "--",
            GS3: "E1202301",
            GS3POWER: "E1202302",
            GS4: "E1202303",
            GS4PHEV: "E1202304",
            GS4COUPE: "E1202305",
            GS4PLUS: "E1202306",
            GS7: "E1202307",
            GS8: "E1202308",
            GS8S: "E1202309",
            M6: "E1202310",
            M8: "E1202311",
            GA4: "E1202312",
            GA6: "E1202313",
            GA8: "E1202314",
            影豹: "E1202316",
            其他: "E1202315",

        },
        懂车帝: {
            所有车型: "--",
            GS3: "E1202321",
            GS3POWER: "E1202322",
            GS4: "E1202323",
            GS4PHEV: "E1202324",
            GS4COUPE: "E1202325",
            GS4PLUS: "E1202326",
            GS7: "E1202327",
            GS8: "E1202328",
            GS8S: "E1202329",
            M6: "E1202330",
            M8: "E1202331",
            GA4: "E1202332",
            GA6: "E1202333",
            GA8: "E1202334",
            影豹: "E1202336",
            其他: "E1202335",
        },
        太平洋: {
            所有车型: "--",
            GS3: "E1202341",
            GS3POWER: "E1202342",
            GS4: "E1202343",
            GS4PHEV: "E1202344",
            GS4COUPE: "E1202345",
            GS4PLUS: "E1202346",
            GS7: "E1202347",
            GS8: "E1202348",
            GS8S: "E1202349",
            M6: "E1202350",
            M8: "E1202351",
            GA4: "E1202352",
            GA6: "E1202353",
            GA8: "E1202354",
            影豹: "E1202356",
            其他: "E1202355",
        },
        广宣线索: {
            所有车型: "--",
            GS3: "E1202361",
            GS3POWER: "E1202362",
            GS4: "E1202363",
            GS4PHEV: "E1202364",
            GS4COUPE: "E1202365",
            GS4PLUS: "E1202366",
            GS7: "E1202367",
            GS8: "E1202368",
            GS8S: "E1202369",
            M6: "E1202370",
            M8: "E1202371",
            GA4: "E1202372",
            GA6: "E1202373",
            GA8: "E1202374",
            影豹: "E1202376",
            其他: "E1202375",
        },
        自店: {
            所有车型: "--",
            GS3: "E1202441",
            GS3POWER: "E1202442",
            GS4: "E1202443",
            GS4PHEV: "E1202444",
            GS4COUPE: "E1202445",
            GS4PLUS: "E1202446",
            GS7: "E1202447",
            GS8: "E1202448",
            GS8S: "E1202449",
            M6: "E1202450",
            M8: "E1202451",
            GA4: "E1202452",
            GA6: "E1202453",
            GA8: "E1202454",
            影豹: "E1202456",
            其他: "E1202455",
        },
        其他: {
            所有车型: "--",
            GS3: "E1202381+E1202401+E1202421",
            GS3POWER: "E1202382+E1202402+E1202422",
            GS4: "E1202383+E1202403+E1202423",
            GS4PHEV: "E1202384+E1202404+E1202424",
            GS4COUPE: "E1202385+E1202405+E1202425",
            GS4PLUS: "E1202386+E1202406+E1202426",
            GS7: "E1202387+E1202407+E1202427",
            GS8: "E1202388+E1202408+E1202428",
            GS8S: "E1202389+E1202409+E1202429",
            M6: "E1202390+E1202410+E1202430",
            M8: "E1202391+E1202411+E1202431",
            GA4: "E1202392+E1202412+E1202432",
            GA6: "E1202393+E1202413+E1202433",
            GA8: "E1202394+E1202414+E1202434",
            影豹: "E1202396+E1202416+E1202436",
            其他: "E1202395+E1202415+E1202435",
        },
        总线索同比: {
            所有车型: "A1302115_avg",
            GS3: "A1302115_avg",
            GS3POWER: "A1302115_avg",
            GS4: "A1302115_avg",
            GS4PHEV: "A1302115_avg",
            GS4COUPE: "A1302115_avg",
            GS4PLUS: "A1302115_avg",
            GS7: "A1302115_avg",
            GS8: "A1302115_avg",
            GS8S: "A1302115_avg",
            M6: "A1302115_avg",
            M8: "A1302115_avg",
            GA4: "A1302115_avg",
            GA6: "A1302115_avg",
            GA8: "A1302115_avg",
            影豹: "A1302115_avg",
            其他: "A1302115_avg",
        },
        总线索环比: {
            所有车型: "A1302115_avg",
            GS3: "A1302115_avg",
            GS3POWER: "A1302115_avg",
            GS4: "A1302115_avg",
            GS4PHEV: "A1302115_avg",
            GS4COUPE: "A1302115_avg",
            GS4PLUS: "A1302115_avg",
            GS7: "A1302115_avg",
            GS8: "A1302115_avg",
            GS8S: "A1302115_avg",
            M6: "A1302115_avg",
            M8: "A1302115_avg",
            GA4: "A1302115_avg",
            GA6: "A1302115_avg",
            GA8: "A1302115_avg",
            影豹: "A1302115_avg",
            其他: "A1302115_avg",
        },
    },

    chartData2: {
        展厅首次客流: {
            所有车型: "A1302012",
            GS3: "A1202001",
            GS3POWER: "A1202002",
            GS4: "A1202003",
            GS4PHEV: "A1202004",
            GS4COUPE: "A1202005",
            GS4PLUS: "A1202006",
            GS7: "A1202007",
            GS8: "A1202008",
            GS8S: "A1202009",
            M6: "A1202010",
            M8: "A1202011",
            GA4: "A1202012",
            GA6: "A1202013",
            GA8: "A1202014",
            影豹: "A1202016",
            其他: "A1202015",
        },

        展厅再次客流: {
            所有车型: "A1302013",
            GS3: "A1202021",
            GS3POWER: "A1202022",
            GS4: "A1202023",
            GS4PHEV: "A1202024",
            GS4COUPE: "A1202025",
            GS4PLUS: "A1202026",
            GS7: "A1202027",
            GS8: "A1202028",
            GS8S: "A1202029",
            M6: "A1202030",
            M8: "A1202031",
            GA4: "A1202032",
            GA6: "A1202033",
            GA8: "A1202034",
            影豹: "A1202036",
            其他: "A1202035",
        },

        IDCC客流: {
            所有车型: "A1302028",
            GS3: "A1202151",
            GS3POWER: "A1202152",
            GS4: "A1202153",
            GS4PHEV: "A1202154",
            GS4COUPE: "A1202155",
            GS4PLUS: "A1202156",
            GS7: "A1202157",
            GS8: "A1202158",
            GS8S: "A1202159",
            M6: "A1202160",
            M8: "A1202161",
            GA4: "A1202162",
            GA6: "A1202163",
            GA8: "A1202164",
            影豹: "A1202166",
            其他: "A1202165",
        },

        客流总数环比: {
            所有车型: "A1303045_avg",
            GS3: "A1303401_avg",
            GS3POWER: "A1303402_avg",
            GS4: "A1303403_avg",
            GS4PHEV: "A1303404_avg",
            GS4COUPE: "A1303405_avg",
            GS4PLUS: "A1303406_avg",
            GS7: "A1303407_avg",
            GS8: "A1303408_avg",
            GS8S: "A1303409_avg",
            M6: "A1303410_avg",
            M8: "A1303411_avg",
            GA4: "A1303412_avg",
            GA6: "A1303413_avg",
            GA8: "A1303414_avg",
            影豹: "A1303416_avg",
            其他: "A1303415_avg",
        },

        客流总数同比: {
            所有车型: "A1303045_avg",
            GS3: "A1303401_avg",
            GS3POWER: "A1303402_avg",
            GS4: "A1303403_avg",
            GS4PHEV: "A1303404_avg",
            GS4COUPE: "A1303405_avg",
            GS4PLUS: "A1303406_avg",
            GS7: "A1303407_avg",
            GS8: "A1303408_avg",
            GS8S: "A1303409_avg",
            M6: "A1303410_avg",
            M8: "A1303411_avg",
            GA4: "A1303412_avg",
            GA6: "A1303413_avg",
            GA8: "A1303414_avg",
            影豹: "A1303416_avg",
            其他: "A1303415_avg",
        }
    },

    chartData3: {
        H级客户数: {
            所有车型: "A1202191",
            GS3: "A1202191",
            GS3POWER: "A1202191",
            GS4: "A1202191",
            GS4PHEV: "A1202191",
            GS4COUPE: "A1202191",
            GS4PLUS: "A1202191",
            GS7: "A1202191",
            GS8: "A1202191",
            GS8S: "A1202191",
            M6: "A1202191",
            M8: "A1202191",
            GA4: "A1202191",
            GA6: "A1202191",
            GA8: "A1202191",
            影豹: "A1202191",
            其他: "A1202191",
        },

        A级客户数: {
            所有车型: "A1202192",
            GS3: "A1202192",
            GS3POWER: "A1202192",
            GS4: "A1202192",
            GS4PHEV: "A1202192",
            GS4COUPE: "A1202192",
            GS4PLUS: "A1202192",
            GS7: "A1202192",
            GS8: "A1202192",
            GS8S: "A1202192",
            M6: "A1202192",
            M8: "A1202192",
            GA4: "A1202192",
            GA6: "A1202192",
            GA8: "A1202192",
            影豹: "A1202192",
            其他: "A1202192",
        },

        B级客户数: {
            所有车型: "A1202193",
            GS3: "A1202193",
            GS3POWER: "A1202193",
            GS4: "A1202193",
            GS4PHEV: "A1202193",
            GS4COUPE: "A1202193",
            GS4PLUS: "A1202193",
            GS7: "A1202193",
            GS8: "A1202193",
            GS8S: "A1202193",
            M6: "A1202193",
            M8: "A1202193",
            GA4: "A1202193",
            GA6: "A1202193",
            GA8: "A1202193",
            影豹: "A1202193",
            其他: "A1202193",
        },

        C级客户数: {
            所有车型: "A1202194",
            GS3: "A1202194",
            GS3POWER: "A1202194",
            GS4: "A1202194",
            GS4PHEV: "A1202194",
            GS4COUPE: "A1202194",
            GS4PLUS: "A1202194",
            GS7: "A1202194",
            GS8: "A1202194",
            GS8S: "A1202194",
            M6: "A1202194",
            M8: "A1202194",
            GA4: "A1202194",
            GA6: "A1202194",
            GA8: "A1202194",
            影豹: "A1202194",
            其他: "A1202194",
        },

        H级客户占比: {
            所有车型: "--",
            GS3: "--",
            GS3POWER: "--",
            GS4: "--",
            GS4PHEV: "--",
            GS4COUPE: "--",
            GS4PLUS: "--",
            GS7: "--",
            GS8: "--",
            GS8S: "--",
            M6: "--",
            M8: "--",
            GA4: "--",
            GA6: "--",
            GA8: "--",
            影豹: "--",
            其他: "--",
        },

        A级客户占比: {
            所有车型: "--",
            GS3: "--",
            GS3POWER: "--",
            GS4: "--",
            GS4PHEV: "--",
            GS4COUPE: "--",
            GS4PLUS: "--",
            GS7: "--",
            GS8: "--",
            GS8S: "--",
            M6: "--",
            M8: "--",
            GA4: "--",
            GA6: "--",
            GA8: "--",
            影豹: "--",
            其他: "--",
        },

        B级客户占比: {
            所有车型: "--",
            GS3: "--",
            GS3POWER: "--",
            GS4: "--",
            GS4PHEV: "--",
            GS4COUPE: "--",
            GS4PLUS: "--",
            GS7: "--",
            GS8: "--",
            GS8S: "--",
            M6: "--",
            M8: "--",
            GA4: "--",
            GA6: "--",
            GA8: "--",
            影豹: "--",
            其他: "--",
        },

        C级客户占比: {
            所有车型: "--",
            GS3: "--",
            GS3POWER: "--",
            GS4: "--",
            GS4PHEV: "--",
            GS4COUPE: "--",
            GS4PLUS: "--",
            GS7: "--",
            GS8: "--",
            GS8S: "--",
            M6: "--",
            M8: "--",
            GA4: "--",
            GA6: "--",
            GA8: "--",
            影豹: "--",
            其他: "--",
        },

        基盘客户同比: {
            所有车型: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS3: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS3POWER: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4PHEV: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4COUPE: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4PLUS: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS7: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS8: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS8S: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            M6: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            M8: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GA4: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GA6: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GA8: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            影豹: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            其他: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
        },

        基盘客户环比: {
            所有车型: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS3: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS3POWER: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4PHEV: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4COUPE: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS4PLUS: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS7: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS8: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GS8S: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            M6: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            M8: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GA4: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GA6: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            GA8: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            影豹: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
            其他: "A1202191_avg+A1202192_avg+A1202193_avg+A1202194_avg",
        },


    },
    chartData4: {
        订单总数: {
            所有车型: "A1303071",
            GS3: "A1303441+A1202171+A1202201+A1202231",
            GS3POWER: "A1303442+A1202172+A1202202+A1202232",
            GS4: "A1303443+A1202173+A1202203+A1202233",
            GS4PHEV: "A1303444+A1202174+A1202204+A1202234",
            GS4COUPE: "A1303445+A1202175+A1202205+A1202235",
            GS4PLUS: "A1303446+A1202176+A1202206+A1202236",
            GS7: "A1303447+A1202177+A1202207+A1202237",
            GS8: "A1303448+A1202178+A1202208+A1202238",
            GS8S: "A1303449+A1202179+A1202209+A1202239",
            M6: "A1303450+A1202180+A1202210+A1202240",
            M8: "A1303451+A1202181+A1202211+A1202241",
            GA4: "A1303452+A1202182+A1202212+A1202242",
            GA6: "A1303453+A1202183+A1202213+A1202243",
            GA8: "A1303454+A1202184+A1202214+A1202244",
            影豹: "A1303455+A1202185+A1202215+A1202245",
            其他: "A1303456+A1202186+A1202216+A1202246",
        },

        订单总数环比: {
            所有车型: "A1303071_avg",
            GS3: "A1303441_avg+A1202171_avg+A1202201_avg+A1202231_avg",
            GS3POWER: "A1303442_avg+A1202172_avg+A1202202_avg+A1202232_avg",
            GS4: "A1303443_avg+A1202173_avg+A1202203_avg+A1202233_avg",
            GS4PHEV: "A1303444_avg+A1202174_avg+A1202204_avg+A1202234_avg",
            GS4COUPE: "A1303445_avg+A1202175_avg+A1202205_avg+A1202235_avg",
            GS4PLUS: "A1303446_avg+A1202176_avg+A1202206_avg+A1202236_avg",
            GS7: "A1303447_avg+A1202177_avg+A1202207_avg+A1202237_avg",
            GS8: "A1303448_avg+A1202178_avg+A1202208_avg+A1202238_avg",
            GS8S: "A1303449_avg+A1202179_avg+A1202209_avg+A1202239_avg",
            M6: "A1303450_avg+A1202180_avg+A1202210_avg+A1202240_avg",
            M8: "A1303451_avg+A1202181_avg+A1202211_avg+A1202241_avg",
            GA4: "A1303452_avg+A1202182_avg+A1202212_avg+A1202242_avg",
            GA6: "A1303453_avg+A1202183_avg+A1202213_avg+A1202243_avg",
            GA8: "A1303454_avg+A1202184_avg+A1202214_avg+A1202244_avg",
            影豹: "A1303455_avg+A1202185_avg+A1202215_avg+A1202245_avg",
            其他: "A1303456_avg+A1202186_avg+A1202216_avg+A1202246_avg",
        },

        订单总数同比: {
            所有车型: "A1303071_avg",
            GS3: "A1303441_avg+A1202171_avg+A1202201_avg+A1202231_avg",
            GS3POWER: "A1303442_avg+A1202172_avg+A1202202_avg+A1202232_avg",
            GS4: "A1303443_avg+A1202173_avg+A1202203_avg+A1202233_avg",
            GS4PHEV: "A1303444_avg+A1202174_avg+A1202204_avg+A1202234_avg",
            GS4COUPE: "A1303445_avg+A1202175_avg+A1202205_avg+A1202235_avg",
            GS4PLUS: "A1303446_avg+A1202176_avg+A1202206_avg+A1202236_avg",
            GS7: "A1303447_avg+A1202177_avg+A1202207_avg+A1202237_avg",
            GS8: "A1303448_avg+A1202178_avg+A1202208_avg+A1202238_avg",
            GS8S: "A1303449_avg+A1202179_avg+A1202209_avg+A1202239_avg",
            M6: "A1303450_avg+A1202180_avg+A1202210_avg+A1202240_avg",
            M8: "A1303451_avg+A1202181_avg+A1202211_avg+A1202241_avg",
            GA4: "A1303452_avg+A1202182_avg+A1202212_avg+A1202242_avg",
            GA6: "A1303453_avg+A1202183_avg+A1202213_avg+A1202243_avg",
            GA8: "A1303454_avg+A1202184_avg+A1202214_avg+A1202244_avg",
            影豹: "A1303455_avg+A1202185_avg+A1202215_avg+A1202245_avg",
            其他: "A1303456_avg+A1202186_avg+A1202216_avg+A1202246_avg",
        },
    },
    chartData7: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A1303441+A1202171+A1202201+A1202231",
            GS3: "A1303441+A1202171+A1202201+A1202231",
            GS3POWER: "A1303441+A1202171+A1202201+A1202231",
            GS4: "A1303441+A1202171+A1202201+A1202231",
            GS4PHEV: "A1303441+A1202171+A1202201+A1202231",
            GS4COUPE: "A1303441+A1202171+A1202201+A1202231",
            GS4PLUS: "A1303441+A1202171+A1202201+A1202231",
            GS7: "A1303441+A1202171+A1202201+A1202231",
            GS8: "A1303441+A1202171+A1202201+A1202231",
            GS8S: "A1303441+A1202171+A1202201+A1202231",
            M6: "A1303441+A1202171+A1202201+A1202231",
            M8: "A1303441+A1202171+A1202201+A1202231",
            GA4: "A1303441+A1202171+A1202201+A1202231",
            GA6: "A1303441+A1202171+A1202201+A1202231",
            GA8: "A1303441+A1202171+A1202201+A1202231",
            影豹: "A1303441+A1202171+A1202201+A1202231",
            其他: "A1303441+A1202171+A1202201+A1202231",
        },
        "GS3 POWER": {
            所有车型: "A1303442+A1202172+A1202202+A1202232",
            GS3: "A1303442+A1202172+A1202202+A1202232",
            GS3POWER: "A1303442+A1202172+A1202202+A1202232",
            GS4: "A1303442+A1202172+A1202202+A1202232",
            GS4PHEV: "A1303442+A1202172+A1202202+A1202232",
            GS4COUPE: "A1303442+A1202172+A1202202+A1202232",
            GS4PLUS: "A1303442+A1202172+A1202202+A1202232",
            GS7: "A1303442+A1202172+A1202202+A1202232",
            GS8: "A1303442+A1202172+A1202202+A1202232",
            GS8S: "A1303442+A1202172+A1202202+A1202232",
            M6: "A1303442+A1202172+A1202202+A1202232",
            M8: "A1303442+A1202172+A1202202+A1202232",
            GA4: "A1303442+A1202172+A1202202+A1202232",
            GA6: "A1303442+A1202172+A1202202+A1202232",
            GA8: "A1303442+A1202172+A1202202+A1202232",
            影豹: "A1303442+A1202172+A1202202+A1202232",
            其他: "A1303442+A1202172+A1202202+A1202232",
        },
        "GS4": {
            所有车型: "A1303443+A1202173+A1202203+A1202233",
            GS3: "A1303443+A1202173+A1202203+A1202233",
            GS3POWER: "A1303443+A1202173+A1202203+A1202233",
            GS4: "A1303443+A1202173+A1202203+A1202233",
            GS4PHEV: "A1303443+A1202173+A1202203+A1202233",
            GS4COUPE: "A1303443+A1202173+A1202203+A1202233",
            GS4PLUS: "A1303443+A1202173+A1202203+A1202233",
            GS7: "A1303443+A1202173+A1202203+A1202233",
            GS8: "A1303443+A1202173+A1202203+A1202233",
            GS8S: "A1303443+A1202173+A1202203+A1202233",
            M6: "A1303443+A1202173+A1202203+A1202233",
            M8: "A1303443+A1202173+A1202203+A1202233",
            GA4: "A1303443+A1202173+A1202203+A1202233",
            GA6: "A1303443+A1202173+A1202203+A1202233",
            GA8: "A1303443+A1202173+A1202203+A1202233",
            影豹: "A1303443+A1202173+A1202203+A1202233",
            其他: "A1303443+A1202173+A1202203+A1202233",
        },
        "GS4 PHEV": {
            所有车型: "A1303444+A1202174+A1202204+A1202234",
            GS3: "A1303444+A1202174+A1202204+A1202234",
            GS3POWER: "A1303444+A1202174+A1202204+A1202234",
            GS4: "A1303444+A1202174+A1202204+A1202234",
            GS4PHEV: "A1303444+A1202174+A1202204+A1202234",
            GS4COUPE: "A1303444+A1202174+A1202204+A1202234",
            GS4PLUS: "A1303444+A1202174+A1202204+A1202234",
            GS7: "A1303444+A1202174+A1202204+A1202234",
            GS8: "A1303444+A1202174+A1202204+A1202234",
            GS8S: "A1303444+A1202174+A1202204+A1202234",
            M6: "A1303444+A1202174+A1202204+A1202234",
            M8: "A1303444+A1202174+A1202204+A1202234",
            GA4: "A1303444+A1202174+A1202204+A1202234",
            GA6: "A1303444+A1202174+A1202204+A1202234",
            GA8: "A1303444+A1202174+A1202204+A1202234",
            影豹: "A1303444+A1202174+A1202204+A1202234",
            其他: "A1303444+A1202174+A1202204+A1202234",
        },
        "GS4 COUPE": {
            所有车型: "A1303445+A1202175+A1202205+A1202235",
            GS3: "A1303445+A1202175+A1202205+A1202235",
            GS3POWER: "A1303445+A1202175+A1202205+A1202235",
            GS4: "A1303445+A1202175+A1202205+A1202235",
            GS4PHEV: "A1303445+A1202175+A1202205+A1202235",
            GS4COUPE: "A1303445+A1202175+A1202205+A1202235",
            GS4PLUS: "A1303445+A1202175+A1202205+A1202235",
            GS7: "A1303445+A1202175+A1202205+A1202235",
            GS8: "A1303445+A1202175+A1202205+A1202235",
            GS8S: "A1303445+A1202175+A1202205+A1202235",
            M6: "A1303445+A1202175+A1202205+A1202235",
            M8: "A1303445+A1202175+A1202205+A1202235",
            GA4: "A1303445+A1202175+A1202205+A1202235",
            GA6: "A1303445+A1202175+A1202205+A1202235",
            GA8: "A1303445+A1202175+A1202205+A1202235",
            影豹: "A1303445+A1202175+A1202205+A1202235",
            其他: "A1303445+A1202175+A1202205+A1202235",
        },
        "GS4PLUS": {
            所有车型: "A1303446+A1202176+A1202206+A1202236",
            GS3: "A1303446+A1202176+A1202206+A1202236",
            GS3POWER: "A1303446+A1202176+A1202206+A1202236",
            GS4: "A1303446+A1202176+A1202206+A1202236",
            GS4PHEV: "A1303446+A1202176+A1202206+A1202236",
            GS4COUPE: "A1303446+A1202176+A1202206+A1202236",
            GS4PLUS: "A1303446+A1202176+A1202206+A1202236",
            GS7: "A1303446+A1202176+A1202206+A1202236",
            GS8: "A1303446+A1202176+A1202206+A1202236",
            GS8S: "A1303446+A1202176+A1202206+A1202236",
            M6: "A1303446+A1202176+A1202206+A1202236",
            M8: "A1303446+A1202176+A1202206+A1202236",
            GA4: "A1303446+A1202176+A1202206+A1202236",
            GA6: "A1303446+A1202176+A1202206+A1202236",
            GA8: "A1303446+A1202176+A1202206+A1202236",
            影豹: "A1303446+A1202176+A1202206+A1202236",
            其他: "A1303446+A1202176+A1202206+A1202236",
        },
        "GS7": {
            所有车型: "A1303447+A1202177+A1202207+A1202237",
            GS3: "A1303447+A1202177+A1202207+A1202237",
            GS3POWER: "A1303447+A1202177+A1202207+A1202237",
            GS4: "A1303447+A1202177+A1202207+A1202237",
            GS4PHEV: "A1303447+A1202177+A1202207+A1202237",
            GS4COUPE: "A1303447+A1202177+A1202207+A1202237",
            GS4PLUS: "A1303447+A1202177+A1202207+A1202237",
            GS7: "A1303447+A1202177+A1202207+A1202237",
            GS8: "A1303447+A1202177+A1202207+A1202237",
            GS8S: "A1303447+A1202177+A1202207+A1202237",
            M6: "A1303447+A1202177+A1202207+A1202237",
            M8: "A1303447+A1202177+A1202207+A1202237",
            GA4: "A1303447+A1202177+A1202207+A1202237",
            GA6: "A1303447+A1202177+A1202207+A1202237",
            GA8: "A1303447+A1202177+A1202207+A1202237",
            影豹: "A1303447+A1202177+A1202207+A1202237",
            其他: "A1303447+A1202177+A1202207+A1202237",
        },
        "GS8": {
            所有车型: "A1303448+A1202178+A1202208+A1202238",
            GS3: "A1303448+A1202178+A1202208+A1202238",
            GS3POWER: "A1303448+A1202178+A1202208+A1202238",
            GS4: "A1303448+A1202178+A1202208+A1202238",
            GS4PHEV: "A1303448+A1202178+A1202208+A1202238",
            GS4COUPE: "A1303448+A1202178+A1202208+A1202238",
            GS4PLUS: "A1303448+A1202178+A1202208+A1202238",
            GS7: "A1303448+A1202178+A1202208+A1202238",
            GS8: "A1303448+A1202178+A1202208+A1202238",
            GS8S: "A1303448+A1202178+A1202208+A1202238",
            M6: "A1303448+A1202178+A1202208+A1202238",
            M8: "A1303448+A1202178+A1202208+A1202238",
            GA4: "A1303448+A1202178+A1202208+A1202238",
            GA6: "A1303448+A1202178+A1202208+A1202238",
            GA8: "A1303448+A1202178+A1202208+A1202238",
            影豹: "A1303448+A1202178+A1202208+A1202238",
            其他: "A1303448+A1202178+A1202208+A1202238",
        },
        "GS8S": {
            所有车型: "A1303449+A1202179+A1202209+A1202239",
            GS3: "A1303449+A1202179+A1202209+A1202239",
            GS3POWER: "A1303449+A1202179+A1202209+A1202239",
            GS4: "A1303449+A1202179+A1202209+A1202239",
            GS4PHEV: "A1303449+A1202179+A1202209+A1202239",
            GS4COUPE: "A1303449+A1202179+A1202209+A1202239",
            GS4PLUS: "A1303449+A1202179+A1202209+A1202239",
            GS7: "A1303449+A1202179+A1202209+A1202239",
            GS8: "A1303449+A1202179+A1202209+A1202239",
            GS8S: "A1303449+A1202179+A1202209+A1202239",
            M6: "A1303449+A1202179+A1202209+A1202239",
            M8: "A1303449+A1202179+A1202209+A1202239",
            GA4: "A1303449+A1202179+A1202209+A1202239",
            GA6: "A1303449+A1202179+A1202209+A1202239",
            GA8: "A1303449+A1202179+A1202209+A1202239",
            影豹: "A1303449+A1202179+A1202209+A1202239",
            其他: "A1303449+A1202179+A1202209+A1202239",
        },
        "M6": {
            所有车型: "A1303450+A1202180+A1202210+A1202240",
            GS3: "A1303450+A1202180+A1202210+A1202240",
            GS3POWER: "A1303450+A1202180+A1202210+A1202240",
            GS4: "A1303450+A1202180+A1202210+A1202240",
            GS4PHEV: "A1303450+A1202180+A1202210+A1202240",
            GS4COUPE: "A1303450+A1202180+A1202210+A1202240",
            GS4PLUS: "A1303450+A1202180+A1202210+A1202240",
            GS7: "A1303450+A1202180+A1202210+A1202240",
            GS8: "A1303450+A1202180+A1202210+A1202240",
            GS8S: "A1303450+A1202180+A1202210+A1202240",
            M6: "A1303450+A1202180+A1202210+A1202240",
            M8: "A1303450+A1202180+A1202210+A1202240",
            GA4: "A1303450+A1202180+A1202210+A1202240",
            GA6: "A1303450+A1202180+A1202210+A1202240",
            GA8: "A1303450+A1202180+A1202210+A1202240",
            影豹: "A1303450+A1202180+A1202210+A1202240",
            其他: "A1303450+A1202180+A1202210+A1202240",
        },
        "M8": {
            所有车型: "A1303451+A1202181+A1202211+A1202241",
            GS3: "A1303451+A1202181+A1202211+A1202241",
            GS3POWER: "A1303451+A1202181+A1202211+A1202241",
            GS4: "A1303451+A1202181+A1202211+A1202241",
            GS4PHEV: "A1303451+A1202181+A1202211+A1202241",
            GS4COUPE: "A1303451+A1202181+A1202211+A1202241",
            GS4PLUS: "A1303451+A1202181+A1202211+A1202241",
            GS7: "A1303451+A1202181+A1202211+A1202241",
            GS8: "A1303451+A1202181+A1202211+A1202241",
            GS8S: "A1303451+A1202181+A1202211+A1202241",
            M6: "A1303451+A1202181+A1202211+A1202241",
            M8: "A1303451+A1202181+A1202211+A1202241",
            GA4: "A1303451+A1202181+A1202211+A1202241",
            GA6: "A1303451+A1202181+A1202211+A1202241",
            GA8: "A1303451+A1202181+A1202211+A1202241",
            影豹: "A1303451+A1202181+A1202211+A1202241",
            其他: "A1303451+A1202181+A1202211+A1202241",
        },
        "GA4": {
            所有车型: "A1303452+A1202182+A1202212+A1202242",
            GS3: "A1303452+A1202182+A1202212+A1202242",
            GS3POWER: "A1303452+A1202182+A1202212+A1202242",
            GS4: "A1303452+A1202182+A1202212+A1202242",
            GS4PHEV: "A1303452+A1202182+A1202212+A1202242",
            GS4COUPE: "A1303452+A1202182+A1202212+A1202242",
            GS4PLUS: "A1303452+A1202182+A1202212+A1202242",
            GS7: "A1303452+A1202182+A1202212+A1202242",
            GS8: "A1303452+A1202182+A1202212+A1202242",
            GS8S: "A1303452+A1202182+A1202212+A1202242",
            M6: "A1303452+A1202182+A1202212+A1202242",
            M8: "A1303452+A1202182+A1202212+A1202242",
            GA4: "A1303452+A1202182+A1202212+A1202242",
            GA6: "A1303452+A1202182+A1202212+A1202242",
            GA8: "A1303452+A1202182+A1202212+A1202242",
            影豹: "A1303452+A1202182+A1202212+A1202242",
            其他: "A1303452+A1202182+A1202212+A1202242",
        },
        "GA6": {
            所有车型: "A1303453+A1202183+A1202213+A1202243",
            GS3: "A1303453+A1202183+A1202213+A1202243",
            GS3POWER: "A1303453+A1202183+A1202213+A1202243",
            GS4: "A1303453+A1202183+A1202213+A1202243",
            GS4PHEV: "A1303453+A1202183+A1202213+A1202243",
            GS4COUPE: "A1303453+A1202183+A1202213+A1202243",
            GS4PLUS: "A1303453+A1202183+A1202213+A1202243",
            GS7: "A1303453+A1202183+A1202213+A1202243",
            GS8: "A1303453+A1202183+A1202213+A1202243",
            GS8S: "A1303453+A1202183+A1202213+A1202243",
            M6: "A1303453+A1202183+A1202213+A1202243",
            M8: "A1303453+A1202183+A1202213+A1202243",
            GA4: "A1303453+A1202183+A1202213+A1202243",
            GA6: "A1303453+A1202183+A1202213+A1202243",
            GA8: "A1303453+A1202183+A1202213+A1202243",
            影豹: "A1303453+A1202183+A1202213+A1202243",
            其他: "A1303453+A1202183+A1202213+A1202243",
        },
        "GA8": {
            所有车型: "A1303454+A1202184+A1202214+A1202244",
            GS3: "A1303454+A1202184+A1202214+A1202244",
            GS3POWER: "A1303454+A1202184+A1202214+A1202244",
            GS4: "A1303454+A1202184+A1202214+A1202244",
            GS4PHEV: "A1303454+A1202184+A1202214+A1202244",
            GS4COUPE: "A1303454+A1202184+A1202214+A1202244",
            GS4PLUS: "A1303454+A1202184+A1202214+A1202244",
            GS7: "A1303454+A1202184+A1202214+A1202244",
            GS8: "A1303454+A1202184+A1202214+A1202244",
            GS8S: "A1303454+A1202184+A1202214+A1202244",
            M6: "A1303454+A1202184+A1202214+A1202244",
            M8: "A1303454+A1202184+A1202214+A1202244",
            GA4: "A1303454+A1202184+A1202214+A1202244",
            GA6: "A1303454+A1202184+A1202214+A1202244",
            GA8: "A1303454+A1202184+A1202214+A1202244",
            影豹: "A1303454+A1202184+A1202214+A1202244",
            其他: "A1303454+A1202184+A1202214+A1202244",
        },
        "影豹": {
            所有车型: "A1303455+A1202185+A1202215+A1202245",
            GS3: "A1303455+A1202185+A1202215+A1202245",
            GS3POWER: "A1303455+A1202185+A1202215+A1202245",
            GS4: "A1303455+A1202185+A1202215+A1202245",
            GS4PHEV: "A1303455+A1202185+A1202215+A1202245",
            GS4COUPE: "A1303455+A1202185+A1202215+A1202245",
            GS4PLUS: "A1303455+A1202185+A1202215+A1202245",
            GS7: "A1303455+A1202185+A1202215+A1202245",
            GS8: "A1303455+A1202185+A1202215+A1202245",
            GS8S: "A1303455+A1202185+A1202215+A1202245",
            M6: "A1303455+A1202185+A1202215+A1202245",
            M8: "A1303455+A1202185+A1202215+A1202245",
            GA4: "A1303455+A1202185+A1202215+A1202245",
            GA6: "A1303455+A1202185+A1202215+A1202245",
            GA8: "A1303455+A1202185+A1202215+A1202245",
            影豹: "A1303455+A1202185+A1202215+A1202245",
            其他: "A1303455+A1202185+A1202215+A1202245",
        },
        "其他": {
            所有车型: "A1303456+A1202186+A1202216+A1202246",
            GS3: "A1303456+A1202186+A1202216+A1202246",
            GS3POWER: "A1303456+A1202186+A1202216+A1202246",
            GS4: "A1303456+A1202186+A1202216+A1202246",
            GS4PHEV: "A1303456+A1202186+A1202216+A1202246",
            GS4COUPE: "A1303456+A1202186+A1202216+A1202246",
            GS4PLUS: "A1303456+A1202186+A1202216+A1202246",
            GS7: "A1303456+A1202186+A1202216+A1202246",
            GS8: "A1303456+A1202186+A1202216+A1202246",
            GS8S: "A1303456+A1202186+A1202216+A1202246",
            M6: "A1303456+A1202186+A1202216+A1202246",
            M8: "A1303456+A1202186+A1202216+A1202246",
            GA4: "A1303456+A1202186+A1202216+A1202246",
            GA6: "A1303456+A1202186+A1202216+A1202246",
            GA8: "A1303456+A1202186+A1202216+A1202246",
            影豹: "A1303456+A1202186+A1202216+A1202246",
            其他: "A1303456+A1202186+A1202216+A1202246",
        },
    },
    chartData13: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A0303311",
            GS3: "A0303311",
            GS3POWER: "A0303311",
            GS4: "A0303311",
            GS4PHEV: "A0303311",
            GS4COUPE: "A0303311",
            GS4PLUS: "A0303311",
            GS7: "A0303311",
            GS8: "A0303311",
            GS8S: "A0303311",
            M6: "A0303311",
            M8: "A0303311",
            GA4: "A0303311",
            GA6: "A0303311",
            GA8: "A0303311",
            影豹: "A0303311",
            其他: "A0303311",
        },
        "GS3 POWER": {
            所有车型: "A0303312",
            GS3: "A0303312",
            GS3POWER: "A0303312",
            GS4: "A0303312",
            GS4PHEV: "A0303312",
            GS4COUPE: "A0303312",
            GS4PLUS: "A0303312",
            GS7: "A0303312",
            GS8: "A0303312",
            GS8S: "A0303312",
            M6: "A0303312",
            M8: "A0303312",
            GA4: "A0303312",
            GA6: "A0303312",
            GA8: "A0303312",
            影豹: "A0303312",
            其他: "A0303312",
        },
        "GS4": {
            所有车型: "A0303313",
            GS3: "A0303313",
            GS3POWER: "A0303313",
            GS4: "A0303313",
            GS4PHEV: "A0303313",
            GS4COUPE: "A0303313",
            GS4PLUS: "A0303313",
            GS7: "A0303313",
            GS8: "A0303313",
            GS8S: "A0303313",
            M6: "A0303313",
            M8: "A0303313",
            GA4: "A0303313",
            GA6: "A0303313",
            GA8: "A0303313",
            影豹: "A0303313",
            其他: "A0303313",
        },
        "GS4 PHEV": {
            所有车型: "A0303314",
            GS3: "A0303314",
            GS3POWER: "A0303314",
            GS4: "A0303314",
            GS4PHEV: "A0303314",
            GS4COUPE: "A0303314",
            GS4PLUS: "A0303314",
            GS7: "A0303314",
            GS8: "A0303314",
            GS8S: "A0303314",
            M6: "A0303314",
            M8: "A0303314",
            GA4: "A0303314",
            GA6: "A0303314",
            GA8: "A0303314",
            影豹: "A0303314",
            其他: "A0303314",
        },
        "GS4 COUPE": {
            所有车型: "A0303315",
            GS3: "A0303315",
            GS3POWER: "A0303315",
            GS4: "A0303315",
            GS4PHEV: "A0303315",
            GS4COUPE: "A0303315",
            GS4PLUS: "A0303315",
            GS7: "A0303315",
            GS8: "A0303315",
            GS8S: "A0303315",
            M6: "A0303315",
            M8: "A0303315",
            GA4: "A0303315",
            GA6: "A0303315",
            GA8: "A0303315",
            影豹: "A0303315",
            其他: "A0303315",
        },
        "GS4PLUS": {
            所有车型: "A0303316",
            GS3: "A0303316",
            GS3POWER: "A0303316",
            GS4: "A0303316",
            GS4PHEV: "A0303316",
            GS4COUPE: "A0303316",
            GS4PLUS: "A0303316",
            GS7: "A0303316",
            GS8: "A0303316",
            GS8S: "A0303316",
            M6: "A0303316",
            M8: "A0303316",
            GA4: "A0303316",
            GA6: "A0303316",
            GA8: "A0303316",
            影豹: "A0303316",
            其他: "A0303316",
        },
        "GS7": {
            所有车型: "A0303317",
            GS3: "A0303317",
            GS3POWER: "A0303317",
            GS4: "A0303317",
            GS4PHEV: "A0303317",
            GS4COUPE: "A0303317",
            GS4PLUS: "A0303317",
            GS7: "A0303317",
            GS8: "A0303317",
            GS8S: "A0303317",
            M6: "A0303317",
            M8: "A0303317",
            GA4: "A0303317",
            GA6: "A0303317",
            GA8: "A0303317",
            影豹: "A0303317",
            其他: "A0303317",
        },
        "GS8": {
            所有车型: "A0303318",
            GS3: "A0303318",
            GS3POWER: "A0303318",
            GS4: "A0303318",
            GS4PHEV: "A0303318",
            GS4COUPE: "A0303318",
            GS4PLUS: "A0303318",
            GS7: "A0303318",
            GS8: "A0303318",
            GS8S: "A0303318",
            M6: "A0303318",
            M8: "A0303318",
            GA4: "A0303318",
            GA6: "A0303318",
            GA8: "A0303318",
            影豹: "A0303318",
            其他: "A0303318",
        },
        "GS8S": {
            所有车型: "A0303319",
            GS3: "A0303319",
            GS3POWER: "A0303319",
            GS4: "A0303319",
            GS4PHEV: "A0303319",
            GS4COUPE: "A0303319",
            GS4PLUS: "A0303319",
            GS7: "A0303319",
            GS8: "A0303319",
            GS8S: "A0303319",
            M6: "A0303319",
            M8: "A0303319",
            GA4: "A0303319",
            GA6: "A0303319",
            GA8: "A0303319",
            影豹: "A0303319",
            其他: "A0303319",
        },
        "M6": {
            所有车型: "A0303320",
            GS3: "A0303320",
            GS3POWER: "A0303320",
            GS4: "A0303320",
            GS4PHEV: "A0303320",
            GS4COUPE: "A0303320",
            GS4PLUS: "A0303320",
            GS7: "A0303320",
            GS8: "A0303320",
            GS8S: "A0303320",
            M6: "A0303320",
            M8: "A0303320",
            GA4: "A0303320",
            GA6: "A0303320",
            GA8: "A0303320",
            影豹: "A0303320",
            其他: "A0303320",
        },
        "M8": {
            所有车型: "A0303321",
            GS3: "A0303321",
            GS3POWER: "A0303321",
            GS4: "A0303321",
            GS4PHEV: "A0303321",
            GS4COUPE: "A0303321",
            GS4PLUS: "A0303321",
            GS7: "A0303321",
            GS8: "A0303321",
            GS8S: "A0303321",
            M6: "A0303321",
            M8: "A0303321",
            GA4: "A0303321",
            GA6: "A0303321",
            GA8: "A0303321",
            影豹: "A0303321",
            其他: "A0303321",
        },
        "GA4": {
            所有车型: "A0303322",
            GS3: "A0303322",
            GS3POWER: "A0303322",
            GS4: "A0303322",
            GS4PHEV: "A0303322",
            GS4COUPE: "A0303322",
            GS4PLUS: "A0303322",
            GS7: "A0303322",
            GS8: "A0303322",
            GS8S: "A0303322",
            M6: "A0303322",
            M8: "A0303322",
            GA4: "A0303322",
            GA6: "A0303322",
            GA8: "A0303322",
            影豹: "A0303322",
            其他: "A0303322",
        },
        "GA6": {
            所有车型: "A0303323",
            GS3: "A0303323",
            GS3POWER: "A0303323",
            GS4: "A0303323",
            GS4PHEV: "A0303323",
            GS4COUPE: "A0303323",
            GS4PLUS: "A0303323",
            GS7: "A0303323",
            GS8: "A0303323",
            GS8S: "A0303323",
            M6: "A0303323",
            M8: "A0303323",
            GA4: "A0303323",
            GA6: "A0303323",
            GA8: "A0303323",
            影豹: "A0303323",
            其他: "A0303323",
        },
        "GA8": {
            所有车型: "A0303324",
            GS3: "A0303324",
            GS3POWER: "A0303324",
            GS4: "A0303324",
            GS4PHEV: "A0303324",
            GS4COUPE: "A0303324",
            GS4PLUS: "A0303324",
            GS7: "A0303324",
            GS8: "A0303324",
            GS8S: "A0303324",
            M6: "A0303324",
            M8: "A0303324",
            GA4: "A0303324",
            GA6: "A0303324",
            GA8: "A0303324",
            影豹: "A0303324",
            其他: "A0303324",
        },
        "影豹": {
            所有车型: "A0303325",
            GS3: "A0303325",
            GS3POWER: "A0303325",
            GS4: "A0303325",
            GS4PHEV: "A0303325",
            GS4COUPE: "A0303325",
            GS4PLUS: "A0303325",
            GS7: "A0303325",
            GS8: "A0303325",
            GS8S: "A0303325",
            M6: "A0303325",
            M8: "A0303325",
            GA4: "A0303325",
            GA6: "A0303325",
            GA8: "A0303325",
            影豹: "A0303325",
            其他: "A0303325",
        },
        "其他": {
            所有车型: "A0303326",
            GS3: "A0303326",
            GS3POWER: "A0303326",
            GS4: "A0303326",
            GS4PHEV: "A0303326",
            GS4COUPE: "A0303326",
            GS4PLUS: "A0303326",
            GS7: "A0303326",
            GS8: "A0303326",
            GS8S: "A0303326",
            M6: "A0303326",
            M8: "A0303326",
            GA4: "A0303326",
            GA6: "A0303326",
            GA8: "A0303326",
            影豹: "A0303326",
            其他: "A0303326",
        },
    },
    //分车型终端车型占比
    chartData14: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A0304004_avg",
            GS3: "A0304004_avg",
            GS3POWER: "A0304004_avg",
            GS4: "A0304004_avg",
            GS4PHEV: "A0304004_avg",
            GS4COUPE: "A0304004_avg",
            GS4PLUS: "A0304004_avg",
            GS7: "A0304004_avg",
            GS8: "A0304004_avg",
            GS8S: "A0304004_avg",
            M6: "A0304004_avg",
            M8: "A0304004_avg",
            GA4: "A0304004_avg",
            GA6: "A0304004_avg",
            GA8: "A0304004_avg",
            影豹: "A0304004_avg",
            其他: "A0304004_avg",
        },
        "GS3 POWER": {
            所有车型: "A0304005_avg",
            GS3: "A0304005_avg",
            GS3POWER: "A0304005_avg",
            GS4: "A0304005_avg",
            GS4PHEV: "A0304005_avg",
            GS4COUPE: "A0304005_avg",
            GS4PLUS: "A0304005_avg",
            GS7: "A0304005_avg",
            GS8: "A0304005_avg",
            GS8S: "A0304005_avg",
            M6: "A0304005_avg",
            M8: "A0304005_avg",
            GA4: "A0304005_avg",
            GA6: "A0304005_avg",
            GA8: "A0304005_avg",
            影豹: "A0304005_avg",
            其他: "A0304005_avg",
        },
        "GS4": {
            所有车型: "A0304006_avg",
            GS3: "A0304006_avg",
            GS3POWER: "A0304006_avg",
            GS4: "A0304006_avg",
            GS4PHEV: "A0304006_avg",
            GS4COUPE: "A0304006_avg",
            GS4PLUS: "A0304006_avg",
            GS7: "A0304006_avg",
            GS8: "A0304006_avg",
            GS8S: "A0304006_avg",
            M6: "A0304006_avg",
            M8: "A0304006_avg",
            GA4: "A0304006_avg",
            GA6: "A0304006_avg",
            GA8: "A0304006_avg",
            影豹: "A0304006_avg",
            其他: "A0304006_avg",
        },
        "GS4 PHEV": {
            所有车型: "A0304007_avg",
            GS3: "A0304007_avg",
            GS3POWER: "A0304007_avg",
            GS4: "A0304007_avg",
            GS4PHEV: "A0304007_avg",
            GS4COUPE: "A0304007_avg",
            GS4PLUS: "A0304007_avg",
            GS7: "A0304007_avg",
            GS8: "A0304007_avg",
            GS8S: "A0304007_avg",
            M6: "A0304007_avg",
            M8: "A0304007_avg",
            GA4: "A0304007_avg",
            GA6: "A0304007_avg",
            GA8: "A0304007_avg",
            影豹: "A0304007_avg",
            其他: "A0304007_avg",
        },
        "GS4 COUPE": {
            所有车型: "A0304008_avg",
            GS3: "A0304008_avg",
            GS3POWER: "A0304008_avg",
            GS4: "A0304008_avg",
            GS4PHEV: "A0304008_avg",
            GS4COUPE: "A0304008_avg",
            GS4PLUS: "A0304008_avg",
            GS7: "A0304008_avg",
            GS8: "A0304008_avg",
            GS8S: "A0304008_avg",
            M6: "A0304008_avg",
            M8: "A0304008_avg",
            GA4: "A0304008_avg",
            GA6: "A0304008_avg",
            GA8: "A0304008_avg",
            影豹: "A0304008_avg",
            其他: "A0304008_avg",
        },
        "GS4PLUS": {
            所有车型: "A0304009_avg",
            GS3: "A0304009_avg",
            GS3POWER: "A0304009_avg",
            GS4: "A0304009_avg",
            GS4PHEV: "A0304009_avg",
            GS4COUPE: "A0304009_avg",
            GS4PLUS: "A0304009_avg",
            GS7: "A0304009_avg",
            GS8: "A0304009_avg",
            GS8S: "A0304009_avg",
            M6: "A0304009_avg",
            M8: "A0304009_avg",
            GA4: "A0304009_avg",
            GA6: "A0304009_avg",
            GA8: "A0304009_avg",
            影豹: "A0304009_avg",
            其他: "A0304009_avg",
        },
        "GS7": {
            所有车型: "A0304010_avg",
            GS3: "A0304010_avg",
            GS3POWER: "A0304010_avg",
            GS4: "A0304010_avg",
            GS4PHEV: "A0304010_avg",
            GS4COUPE: "A0304010_avg",
            GS4PLUS: "A0304010_avg",
            GS7: "A0304010_avg",
            GS8: "A0304010_avg",
            GS8S: "A0304010_avg",
            M6: "A0304010_avg",
            M8: "A0304010_avg",
            GA4: "A0304010_avg",
            GA6: "A0304010_avg",
            GA8: "A0304010_avg",
            影豹: "A0304010_avg",
            其他: "A0304010_avg",
        },
        "GS8": {
            所有车型: "A0304011_avg",
            GS3: "A0304011_avg",
            GS3POWER: "A0304011_avg",
            GS4: "A0304011_avg",
            GS4PHEV: "A0304011_avg",
            GS4COUPE: "A0304011_avg",
            GS4PLUS: "A0304011_avg",
            GS7: "A0304011_avg",
            GS8: "A0304011_avg",
            GS8S: "A0304011_avg",
            M6: "A0304011_avg",
            M8: "A0304011_avg",
            GA4: "A0304011_avg",
            GA6: "A0304011_avg",
            GA8: "A0304011_avg",
            影豹: "A0304011_avg",
            其他: "A0304011_avg",
        },
        "GS8S": {
            所有车型: "A0304012_avg",
            GS3: "A0304012_avg",
            GS3POWER: "A0304012_avg",
            GS4: "A0304012_avg",
            GS4PHEV: "A0304012_avg",
            GS4COUPE: "A0304012_avg",
            GS4PLUS: "A0304012_avg",
            GS7: "A0304012_avg",
            GS8: "A0304012_avg",
            GS8S: "A0304012_avg",
            M6: "A0304012_avg",
            M8: "A0304012_avg",
            GA4: "A0304012_avg",
            GA6: "A0304012_avg",
            GA8: "A0304012_avg",
            影豹: "A0304012_avg",
            其他: "A0304012_avg",
        },
        "M6": {
            所有车型: "A0304013_avg",
            GS3: "A0304013_avg",
            GS3POWER: "A0304013_avg",
            GS4: "A0304013_avg",
            GS4PHEV: "A0304013_avg",
            GS4COUPE: "A0304013_avg",
            GS4PLUS: "A0304013_avg",
            GS7: "A0304013_avg",
            GS8: "A0304013_avg",
            GS8S: "A0304013_avg",
            M6: "A0304013_avg",
            M8: "A0304013_avg",
            GA4: "A0304013_avg",
            GA6: "A0304013_avg",
            GA8: "A0304013_avg",
            影豹: "A0304013_avg",
            其他: "A0304013_avg",
        },
        "M8": {
            所有车型: "A0304014_avg",
            GS3: "A0304014_avg",
            GS3POWER: "A0304014_avg",
            GS4: "A0304014_avg",
            GS4PHEV: "A0304014_avg",
            GS4COUPE: "A0304014_avg",
            GS4PLUS: "A0304014_avg",
            GS7: "A0304014_avg",
            GS8: "A0304014_avg",
            GS8S: "A0304014_avg",
            M6: "A0304014_avg",
            M8: "A0304014_avg",
            GA4: "A0304014_avg",
            GA6: "A0304014_avg",
            GA8: "A0304014_avg",
            影豹: "A0304014_avg",
            其他: "A0304014_avg",
        },
        "GA4": {
            所有车型: "A0304015_avg",
            GS3: "A0304015_avg",
            GS3POWER: "A0304015_avg",
            GS4: "A0304015_avg",
            GS4PHEV: "A0304015_avg",
            GS4COUPE: "A0304015_avg",
            GS4PLUS: "A0304015_avg",
            GS7: "A0304015_avg",
            GS8: "A0304015_avg",
            GS8S: "A0304015_avg",
            M6: "A0304015_avg",
            M8: "A0304015_avg",
            GA4: "A0304015_avg",
            GA6: "A0304015_avg",
            GA8: "A0304015_avg",
            影豹: "A0304015_avg",
            其他: "A0304015_avg",
        },
        "GA6": {
            所有车型: "A0304016_avg",
            GS3: "A0304016_avg",
            GS3POWER: "A0304016_avg",
            GS4: "A0304016_avg",
            GS4PHEV: "A0304016_avg",
            GS4COUPE: "A0304016_avg",
            GS4PLUS: "A0304016_avg",
            GS7: "A0304016_avg",
            GS8: "A0304016_avg",
            GS8S: "A0304016_avg",
            M6: "A0304016_avg",
            M8: "A0304016_avg",
            GA4: "A0304016_avg",
            GA6: "A0304016_avg",
            GA8: "A0304016_avg",
            影豹: "A0304016_avg",
            其他: "A0304016_avg",
        },
        "GA8": {
            所有车型: "A0304017_avg",
            GS3: "A0304017_avg",
            GS3POWER: "A0304017_avg",
            GS4: "A0304017_avg",
            GS4PHEV: "A0304017_avg",
            GS4COUPE: "A0304017_avg",
            GS4PLUS: "A0304017_avg",
            GS7: "A0304017_avg",
            GS8: "A0304017_avg",
            GS8S: "A0304017_avg",
            M6: "A0304017_avg",
            M8: "A0304017_avg",
            GA4: "A0304017_avg",
            GA6: "A0304017_avg",
            GA8: "A0304017_avg",
            影豹: "A0304017_avg",
            其他: "A0304017_avg",
        },
        影豹: {
            所有车型: "A0304018_avg",
            GS3: "A0304018_avg",
            GS3POWER: "A0304018_avg",
            GS4: "A0304018_avg",
            GS4PHEV: "A0304018_avg",
            GS4COUPE: "A0304018_avg",
            GS4PLUS: "A0304018_avg",
            GS7: "A0304018_avg",
            GS8: "A0304018_avg",
            GS8S: "A0304018_avg",
            M6: "A0304018_avg",
            M8: "A0304018_avg",
            GA4: "A0304018_avg",
            GA6: "A0304018_avg",
            GA8: "A0304018_avg",
            影豹: "A0304018_avg",
            其他: "A0304018_avg",
        },
        其他: {
            所有车型: "A0304019_avg",
            GS3: "A0304019_avg",
            GS3POWER: "A0304019_avg",
            GS4: "A0304019_avg",
            GS4PHEV: "A0304019_avg",
            GS4COUPE: "A0304019_avg",
            GS4PLUS: "A0304019_avg",
            GS7: "A0304019_avg",
            GS8: "A0304019_avg",
            GS8S: "A0304019_avg",
            M6: "A0304019_avg",
            M8: "A0304019_avg",
            GA4: "A0304019_avg",
            GA6: "A0304019_avg",
            GA8: "A0304019_avg",
            影豹: "A0304019_avg",
            其他: "A0304019_avg",
        },
    },
    //分车型库存实绩
    chartData15: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A0402012",
            GS3: "A0402012",
            GS3POWER: "A0402012",
            GS4: "A0402012",
            GS4PHEV: "A0402012",
            GS4COUPE: "A0402012",
            GS4PLUS: "A0402012",
            GS7: "A0402012",
            GS8: "A0402012",
            GS8S: "A0402012",
            M6: "A0402012",
            M8: "A0402012",
            GA4: "A0402012",
            GA6: "A0402012",
            GA8: "A0402012",
            影豹: "A0402012",
            其他: "A0402012",
        },
        "GS3 POWER": {
            所有车型: "A0402018",
            GS3: "A0402018",
            GS3POWER: "A0402018",
            GS4: "A0402018",
            GS4PHEV: "A0402018",
            GS4COUPE: "A0402018",
            GS4PLUS: "A0402018",
            GS7: "A0402018",
            GS8: "A0402018",
            GS8S: "A0402018",
            M6: "A0402018",
            M8: "A0402018",
            GA4: "A0402018",
            GA6: "A0402018",
            GA8: "A0402018",
            影豹: "A0402018",
            其他: "A0402018",
        },
        "GS4": {
            所有车型: "A0402024",
            GS3: "A0402024",
            GS3POWER: "A0402024",
            GS4: "A0402024",
            GS4PHEV: "A0402024",
            GS4COUPE: "A0402024",
            GS4PLUS: "A0402024",
            GS7: "A0402024",
            GS8: "A0402024",
            GS8S: "A0402024",
            M6: "A0402024",
            M8: "A0402024",
            GA4: "A0402024",
            GA6: "A0402024",
            GA8: "A0402024",
            影豹: "A0402024",
            其他: "A0402024",
        },
        "GS4 PHEV": {
            所有车型: "A0402030",
            GS3: "A0402030",
            GS3POWER: "A0402030",
            GS4: "A0402030",
            GS4PHEV: "A0402030",
            GS4COUPE: "A0402030",
            GS4PLUS: "A0402030",
            GS7: "A0402030",
            GS8: "A0402030",
            GS8S: "A0402030",
            M6: "A0402030",
            M8: "A0402030",
            GA4: "A0402030",
            GA6: "A0402030",
            GA8: "A0402030",
            影豹: "A0402030",
            其他: "A0402030",
        },
        "GS4 COUPE": {
            所有车型: "A0402036",
            GS3: "A0402036",
            GS3POWER: "A0402036",
            GS4: "A0402036",
            GS4PHEV: "A0402036",
            GS4COUPE: "A0402036",
            GS4PLUS: "A0402036",
            GS7: "A0402036",
            GS8: "A0402036",
            GS8S: "A0402036",
            M6: "A0402036",
            M8: "A0402036",
            GA4: "A0402036",
            GA6: "A0402036",
            GA8: "A0402036",
            影豹: "A0402036",
            其他: "A0402036",
        },
        "GS4PLUS": {
            所有车型: "A0402042",
            GS3: "A0402042",
            GS3POWER: "A0402042",
            GS4: "A0402042",
            GS4PHEV: "A0402042",
            GS4COUPE: "A0402042",
            GS4PLUS: "A0402042",
            GS7: "A0402042",
            GS8: "A0402042",
            GS8S: "A0402042",
            M6: "A0402042",
            M8: "A0402042",
            GA4: "A0402042",
            GA6: "A0402042",
            GA8: "A0402042",
            影豹: "A0402042",
            其他: "A0402042",
        },
        "GS7": {
            所有车型: "A0402048",
            GS3: "A0402048",
            GS3POWER: "A0402048",
            GS4: "A0402048",
            GS4PHEV: "A0402048",
            GS4COUPE: "A0402048",
            GS4PLUS: "A0402048",
            GS7: "A0402048",
            GS8: "A0402048",
            GS8S: "A0402048",
            M6: "A0402048",
            M8: "A0402048",
            GA4: "A0402048",
            GA6: "A0402048",
            GA8: "A0402048",
            影豹: "A0402048",
            其他: "A0402048",
        },
        "GS8": {
            所有车型: "A0402054",
            GS3: "A0402054",
            GS3POWER: "A0402054",
            GS4: "A0402054",
            GS4PHEV: "A0402054",
            GS4COUPE: "A0402054",
            GS4PLUS: "A0402054",
            GS7: "A0402054",
            GS8: "A0402054",
            GS8S: "A0402054",
            M6: "A0402054",
            M8: "A0402054",
            GA4: "A0402054",
            GA6: "A0402054",
            GA8: "A0402054",
            影豹: "A0402054",
            其他: "A0402054",
        },
        "GS8S": {
            所有车型: "A0402060",
            GS3: "A0402060",
            GS3POWER: "A0402060",
            GS4: "A0402060",
            GS4PHEV: "A0402060",
            GS4COUPE: "A0402060",
            GS4PLUS: "A0402060",
            GS7: "A0402060",
            GS8: "A0402060",
            GS8S: "A0402060",
            M6: "A0402060",
            M8: "A0402060",
            GA4: "A0402060",
            GA6: "A0402060",
            GA8: "A0402060",
            影豹: "A0402060",
            其他: "A0402060",
        },
        "M6": {
            所有车型: "A0402115",
            GS3: "A0402115",
            GS3POWER: "A0402115",
            GS4: "A0402115",
            GS4PHEV: "A0402115",
            GS4COUPE: "A0402115",
            GS4PLUS: "A0402115",
            GS7: "A0402115",
            GS8: "A0402115",
            GS8S: "A0402115",
            M6: "A0402115",
            M8: "A0402115",
            GA4: "A0402115",
            GA6: "A0402115",
            GA8: "A0402115",
            影豹: "A0402115",
            其他: "A0402115",
        },
        "M8": {
            所有车型: "A0402121",
            GS3: "A0402121",
            GS3POWER: "A0402121",
            GS4: "A0402121",
            GS4PHEV: "A0402121",
            GS4COUPE: "A0402121",
            GS4PLUS: "A0402121",
            GS7: "A0402121",
            GS8: "A0402121",
            GS8S: "A0402121",
            M6: "A0402121",
            M8: "A0402121",
            GA4: "A0402121",
            GA6: "A0402121",
            GA8: "A0402121",
            影豹: "A0402121",
            其他: "A0402121",
        },
        "GA4": {
            所有车型: "A0402127",
            GS3: "A0402127",
            GS3POWER: "A0402127",
            GS4: "A0402127",
            GS4PHEV: "A0402127",
            GS4COUPE: "A0402127",
            GS4PLUS: "A0402127",
            GS7: "A0402127",
            GS8: "A0402127",
            GS8S: "A0402127",
            M6: "A0402127",
            M8: "A0402127",
            GA4: "A0402127",
            GA6: "A0402127",
            GA8: "A0402127",
            影豹: "A0402127",
            其他: "A0402127",
        },
        "GA6": {
            所有车型: "A0402133",
            GS3: "A0402133",
            GS3POWER: "A0402133",
            GS4: "A0402133",
            GS4PHEV: "A0402133",
            GS4COUPE: "A0402133",
            GS4PLUS: "A0402133",
            GS7: "A0402133",
            GS8: "A0402133",
            GS8S: "A0402133",
            M6: "A0402133",
            M8: "A0402133",
            GA4: "A0402133",
            GA6: "A0402133",
            GA8: "A0402133",
            影豹: "A0402133",
            其他: "A0402133",
        },
        "GA8": {
            所有车型: "A0402147",
            GS3: "A0402147",
            GS3POWER: "A0402147",
            GS4: "A0402147",
            GS4PHEV: "A0402147",
            GS4COUPE: "A0402147",
            GS4PLUS: "A0402147",
            GS7: "A0402147",
            GS8: "A0402147",
            GS8S: "A0402147",
            M6: "A0402147",
            M8: "A0402147",
            GA4: "A0402147",
            GA6: "A0402147",
            GA8: "A0402147",
            影豹: "A0402147",
            其他: "A0402147",
        },
        "影豹": {
            所有车型: "A0402172",
            GS3: "A0402172",
            GS3POWER: "A0402172",
            GS4: "A0402172",
            GS4PHEV: "A0402172",
            GS4COUPE: "A0402172",
            GS4PLUS: "A0402172",
            GS7: "A0402172",
            GS8: "A0402172",
            GS8S: "A0402172",
            M6: "A0402172",
            M8: "A0402172",
            GA4: "A0402172",
            GA6: "A0402172",
            GA8: "A0402172",
            影豹: "A0402172",
            其他: "A0402172",
        },
        "其他": {
            所有车型: "A0402178",
            GS3: "A0402178",
            GS3POWER: "A0402178",
            GS4: "A0402178",
            GS4PHEV: "A0402178",
            GS4COUPE: "A0402178",
            GS4PLUS: "A0402178",
            GS7: "A0402178",
            GS8: "A0402178",
            GS8S: "A0402178",
            M6: "A0402178",
            M8: "A0402178",
            GA4: "A0402178",
            GA6: "A0402178",
            GA8: "A0402178",
            影豹: "A0402178",
            其他: "A0402178",
        },

    },
    //分车型库存占比
    chartData16: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A0404061_avg",
            GS3: "A0404061_avg",
            GS3POWER: "A0404061_avg",
            GS4: "A0404061_avg",
            GS4PHEV: "A0404061_avg",
            GS4COUPE: "A0404061_avg",
            GS4PLUS: "A0404061_avg",
            GS7: "A0404061_avg",
            GS8: "A0404061_avg",
            GS8S: "A0404061_avg",
            M6: "A0404061_avg",
            M8: "A0404061_avg",
            GA4: "A0404061_avg",
            GA6: "A0404061_avg",
            GA8: "A0404061_avg",
            影豹: "A0404061_avg",
            其他: "A0404061_avg",
        },
        "GS3 POWER": {
            所有车型: "A0404062_avg",
            GS3: "A0404062_avg",
            GS3POWER: "A0404062_avg",
            GS4: "A0404062_avg",
            GS4PHEV: "A0404062_avg",
            GS4COUPE: "A0404062_avg",
            GS4PLUS: "A0404062_avg",
            GS7: "A0404062_avg",
            GS8: "A0404062_avg",
            GS8S: "A0404062_avg",
            M6: "A0404062_avg",
            M8: "A0404062_avg",
            GA4: "A0404062_avg",
            GA6: "A0404062_avg",
            GA8: "A0404062_avg",
            影豹: "A0404062_avg",
            其他: "A0404062_avg",
        },
        "GS4": {
            所有车型: "A0404063_avg",
            GS3: "A0404063_avg",
            GS3POWER: "A0404063_avg",
            GS4: "A0404063_avg",
            GS4PHEV: "A0404063_avg",
            GS4COUPE: "A0404063_avg",
            GS4PLUS: "A0404063_avg",
            GS7: "A0404063_avg",
            GS8: "A0404063_avg",
            GS8S: "A0404063_avg",
            M6: "A0404063_avg",
            M8: "A0404063_avg",
            GA4: "A0404063_avg",
            GA6: "A0404063_avg",
            GA8: "A0404063_avg",
            影豹: "A0404063_avg",
            其他: "A0404063_avg",
        },
        "GS4 PHEV": {
            所有车型: "A0404064_avg",
            GS3: "A0404064_avg",
            GS3POWER: "A0404064_avg",
            GS4: "A0404064_avg",
            GS4PHEV: "A0404064_avg",
            GS4COUPE: "A0404064_avg",
            GS4PLUS: "A0404064_avg",
            GS7: "A0404064_avg",
            GS8: "A0404064_avg",
            GS8S: "A0404064_avg",
            M6: "A0404064_avg",
            M8: "A0404064_avg",
            GA4: "A0404064_avg",
            GA6: "A0404064_avg",
            GA8: "A0404064_avg",
            影豹: "A0404064_avg",
            其他: "A0404064_avg",
        },
        "GS4 COUPE": {
            所有车型: "A0404065_avg",
            GS3: "A0404065_avg",
            GS3POWER: "A0404065_avg",
            GS4: "A0404065_avg",
            GS4PHEV: "A0404065_avg",
            GS4COUPE: "A0404065_avg",
            GS4PLUS: "A0404065_avg",
            GS7: "A0404065_avg",
            GS8: "A0404065_avg",
            GS8S: "A0404065_avg",
            M6: "A0404065_avg",
            M8: "A0404065_avg",
            GA4: "A0404065_avg",
            GA6: "A0404065_avg",
            GA8: "A0404065_avg",
            影豹: "A0404065_avg",
            其他: "A0404065_avg",
        },
        "GS4PLUS": {
            所有车型: "A0404066_avg",
            GS3: "A0404066_avg",
            GS3POWER: "A0404066_avg",
            GS4: "A0404066_avg",
            GS4PHEV: "A0404066_avg",
            GS4COUPE: "A0404066_avg",
            GS4PLUS: "A0404066_avg",
            GS7: "A0404066_avg",
            GS8: "A0404066_avg",
            GS8S: "A0404066_avg",
            M6: "A0404066_avg",
            M8: "A0404066_avg",
            GA4: "A0404066_avg",
            GA6: "A0404066_avg",
            GA8: "A0404066_avg",
            影豹: "A0404066_avg",
            其他: "A0404066_avg",
        },
        "GS7": {
            所有车型: "A0404067_avg",
            GS3: "A0404067_avg",
            GS3POWER: "A0404067_avg",
            GS4: "A0404067_avg",
            GS4PHEV: "A0404067_avg",
            GS4COUPE: "A0404067_avg",
            GS4PLUS: "A0404067_avg",
            GS7: "A0404067_avg",
            GS8: "A0404067_avg",
            GS8S: "A0404067_avg",
            M6: "A0404067_avg",
            M8: "A0404067_avg",
            GA4: "A0404067_avg",
            GA6: "A0404067_avg",
            GA8: "A0404067_avg",
            影豹: "A0404067_avg",
            其他: "A0404067_avg",
        },
        "GS8": {
            所有车型: "A0404068_avg",
            GS3: "A0404068_avg",
            GS3POWER: "A0404068_avg",
            GS4: "A0404068_avg",
            GS4PHEV: "A0404068_avg",
            GS4COUPE: "A0404068_avg",
            GS4PLUS: "A0404068_avg",
            GS7: "A0404068_avg",
            GS8: "A0404068_avg",
            GS8S: "A0404068_avg",
            M6: "A0404068_avg",
            M8: "A0404068_avg",
            GA4: "A0404068_avg",
            GA6: "A0404068_avg",
            GA8: "A0404068_avg",
            影豹: "A0404068_avg",
            其他: "A0404068_avg",
        },
        "GS8S": {
            所有车型: "A0404069_avg",
            GS3: "A0404069_avg",
            GS3POWER: "A0404069_avg",
            GS4: "A0404069_avg",
            GS4PHEV: "A0404069_avg",
            GS4COUPE: "A0404069_avg",
            GS4PLUS: "A0404069_avg",
            GS7: "A0404069_avg",
            GS8: "A0404069_avg",
            GS8S: "A0404069_avg",
            M6: "A0404069_avg",
            M8: "A0404069_avg",
            GA4: "A0404069_avg",
            GA6: "A0404069_avg",
            GA8: "A0404069_avg",
            影豹: "A0404069_avg",
            其他: "A0404069_avg",
        },
        "M6": {
            所有车型: "A0404070_avg",
            GS3: "A0404070_avg",
            GS3POWER: "A0404070_avg",
            GS4: "A0404070_avg",
            GS4PHEV: "A0404070_avg",
            GS4COUPE: "A0404070_avg",
            GS4PLUS: "A0404070_avg",
            GS7: "A0404070_avg",
            GS8: "A0404070_avg",
            GS8S: "A0404070_avg",
            M6: "A0404070_avg",
            M8: "A0404070_avg",
            GA4: "A0404070_avg",
            GA6: "A0404070_avg",
            GA8: "A0404070_avg",
            影豹: "A0404070_avg",
            其他: "A0404070_avg",
        },
        "M8": {
            所有车型: "A0404071_avg",
            GS3: "A0404071_avg",
            GS3POWER: "A0404071_avg",
            GS4: "A0404071_avg",
            GS4PHEV: "A0404071_avg",
            GS4COUPE: "A0404071_avg",
            GS4PLUS: "A0404071_avg",
            GS7: "A0404071_avg",
            GS8: "A0404071_avg",
            GS8S: "A0404071_avg",
            M6: "A0404071_avg",
            M8: "A0404071_avg",
            GA4: "A0404071_avg",
            GA6: "A0404071_avg",
            GA8: "A0404071_avg",
            影豹: "A0404071_avg",
            其他: "A0404071_avg",
        },
        "GA4": {
            所有车型: "A0404072_avg",
            GS3: "A0404072_avg",
            GS3POWER: "A0404072_avg",
            GS4: "A0404072_avg",
            GS4PHEV: "A0404072_avg",
            GS4COUPE: "A0404072_avg",
            GS4PLUS: "A0404072_avg",
            GS7: "A0404072_avg",
            GS8: "A0404072_avg",
            GS8S: "A0404072_avg",
            M6: "A0404072_avg",
            M8: "A0404072_avg",
            GA4: "A0404072_avg",
            GA6: "A0404072_avg",
            GA8: "A0404072_avg",
            影豹: "A0404072_avg",
            其他: "A0404072_avg",
        },
        "GA6": {
            所有车型: "A0404073_avg",
            GS3: "A0404073_avg",
            GS3POWER: "A0404073_avg",
            GS4: "A0404073_avg",
            GS4PHEV: "A0404073_avg",
            GS4COUPE: "A0404073_avg",
            GS4PLUS: "A0404073_avg",
            GS7: "A0404073_avg",
            GS8: "A0404073_avg",
            GS8S: "A0404073_avg",
            M6: "A0404073_avg",
            M8: "A0404073_avg",
            GA4: "A0404073_avg",
            GA6: "A0404073_avg",
            GA8: "A0404073_avg",
            影豹: "A0404073_avg",
            其他: "A0404073_avg",
        },
        "GA8": {
            所有车型: "A0404074_avg",
            GS3: "A0404074_avg",
            GS3POWER: "A0404074_avg",
            GS4: "A0404074_avg",
            GS4PHEV: "A0404074_avg",
            GS4COUPE: "A0404074_avg",
            GS4PLUS: "A0404074_avg",
            GS7: "A0404074_avg",
            GS8: "A0404074_avg",
            GS8S: "A0404074_avg",
            M6: "A0404074_avg",
            M8: "A0404074_avg",
            GA4: "A0404074_avg",
            GA6: "A0404074_avg",
            GA8: "A0404074_avg",
            影豹: "A0404074_avg",
            其他: "A0404074_avg",
        },
        "影豹": {
            所有车型: "A0404075_avg",
            GS3: "A0404075_avg",
            GS3POWER: "A0404075_avg",
            GS4: "A0404075_avg",
            GS4PHEV: "A0404075_avg",
            GS4COUPE: "A0404075_avg",
            GS4PLUS: "A0404075_avg",
            GS7: "A0404075_avg",
            GS8: "A0404075_avg",
            GS8S: "A0404075_avg",
            M6: "A0404075_avg",
            M8: "A0404075_avg",
            GA4: "A0404075_avg",
            GA6: "A0404075_avg",
            GA8: "A0404075_avg",
            影豹: "A0404075_avg",
            其他: "A0404075_avg",
        },
        "其他": {
            所有车型: "A0404076_avg",
            GS3: "A0404076_avg",
            GS3POWER: "A0404076_avg",
            GS4: "A0404076_avg",
            GS4PHEV: "A0404076_avg",
            GS4COUPE: "A0404076_avg",
            GS4PLUS: "A0404076_avg",
            GS7: "A0404076_avg",
            GS8: "A0404076_avg",
            GS8S: "A0404076_avg",
            M6: "A0404076_avg",
            M8: "A0404076_avg",
            GA4: "A0404076_avg",
            GA6: "A0404076_avg",
            GA8: "A0404076_avg",
            影豹: "A0404076_avg",
            其他: "A0404076_avg",
        },
    },
    //分车型终端让价
    chartData17: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A1403022",
            GS3: "A1403022",
            GS3POWER: "A1403022",
            GS4: "A1403022",
            GS4PHEV: "A1403022",
            GS4COUPE: "A1403022",
            GS4PLUS: "A1403022",
            GS7: "A1403022",
            GS8: "A1403022",
            GS8S: "A1403022",
            M6: "A1403022",
            M8: "A1403022",
            GA4: "A1403022",
            GA6: "A1403022",
            GA8: "A1403022",
            影豹: "A1403022",
            其他: "A1403022",
        },
        "GS3 POWER": {
            所有车型: "A1403023",
            GS3: "A1403023",
            GS3POWER: "A1403023",
            GS4: "A1403023",
            GS4PHEV: "A1403023",
            GS4COUPE: "A1403023",
            GS4PLUS: "A1403023",
            GS7: "A1403023",
            GS8: "A1403023",
            GS8S: "A1403023",
            M6: "A1403023",
            M8: "A1403023",
            GA4: "A1403023",
            GA6: "A1403023",
            GA8: "A1403023",
            影豹: "A1403023",
            其他: "A1403023",
        },
        "GS4": {
            所有车型: "A1403024",
            GS3: "A1403024",
            GS3POWER: "A1403024",
            GS4: "A1403024",
            GS4PHEV: "A1403024",
            GS4COUPE: "A1403024",
            GS4PLUS: "A1403024",
            GS7: "A1403024",
            GS8: "A1403024",
            GS8S: "A1403024",
            M6: "A1403024",
            M8: "A1403024",
            GA4: "A1403024",
            GA6: "A1403024",
            GA8: "A1403024",
            影豹: "A1403024",
            其他: "A1403024",
        },
        "GS4 PHEV": {
            所有车型: "A1403025",
            GS3: "A1403025",
            GS3POWER: "A1403025",
            GS4: "A1403025",
            GS4PHEV: "A1403025",
            GS4COUPE: "A1403025",
            GS4PLUS: "A1403025",
            GS7: "A1403025",
            GS8: "A1403025",
            GS8S: "A1403025",
            M6: "A1403025",
            M8: "A1403025",
            GA4: "A1403025",
            GA6: "A1403025",
            GA8: "A1403025",
            影豹: "A1403025",
            其他: "A1403025",
        },
        "GS4 COUPE": {
            所有车型: "A1403026",
            GS3: "A1403026",
            GS3POWER: "A1403026",
            GS4: "A1403026",
            GS4PHEV: "A1403026",
            GS4COUPE: "A1403026",
            GS4PLUS: "A1403026",
            GS7: "A1403026",
            GS8: "A1403026",
            GS8S: "A1403026",
            M6: "A1403026",
            M8: "A1403026",
            GA4: "A1403026",
            GA6: "A1403026",
            GA8: "A1403026",
            影豹: "A1403026",
            其他: "A1403026",
        },
        "GS4PLUS": {
            所有车型: "A1403027",
            GS3: "A1403027",
            GS3POWER: "A1403027",
            GS4: "A1403027",
            GS4PHEV: "A1403027",
            GS4COUPE: "A1403027",
            GS4PLUS: "A1403027",
            GS7: "A1403027",
            GS8: "A1403027",
            GS8S: "A1403027",
            M6: "A1403027",
            M8: "A1403027",
            GA4: "A1403027",
            GA6: "A1403027",
            GA8: "A1403027",
            影豹: "A1403027",
            其他: "A1403027",
        },
        "GS7": {
            所有车型: "A1403028",
            GS3: "A1403028",
            GS3POWER: "A1403028",
            GS4: "A1403028",
            GS4PHEV: "A1403028",
            GS4COUPE: "A1403028",
            GS4PLUS: "A1403028",
            GS7: "A1403028",
            GS8: "A1403028",
            GS8S: "A1403028",
            M6: "A1403028",
            M8: "A1403028",
            GA4: "A1403028",
            GA6: "A1403028",
            GA8: "A1403028",
            影豹: "A1403028",
            其他: "A1403028",
        },
        "GS8": {
            所有车型: "A1403029",
            GS3: "A1403029",
            GS3POWER: "A1403029",
            GS4: "A1403029",
            GS4PHEV: "A1403029",
            GS4COUPE: "A1403029",
            GS4PLUS: "A1403029",
            GS7: "A1403029",
            GS8: "A1403029",
            GS8S: "A1403029",
            M6: "A1403029",
            M8: "A1403029",
            GA4: "A1403029",
            GA6: "A1403029",
            GA8: "A1403029",
            影豹: "A1403029",
            其他: "A1403029",
        },
        "GS8S": {
            所有车型: "A1403030",
            GS3: "A1403030",
            GS3POWER: "A1403030",
            GS4: "A1403030",
            GS4PHEV: "A1403030",
            GS4COUPE: "A1403030",
            GS4PLUS: "A1403030",
            GS7: "A1403030",
            GS8: "A1403030",
            GS8S: "A1403030",
            M6: "A1403030",
            M8: "A1403030",
            GA4: "A1403030",
            GA6: "A1403030",
            GA8: "A1403030",
            影豹: "A1403030",
            其他: "A1403030",
        },
        "M6": {
            所有车型: "A1403031",
            GS3: "A1403031",
            GS3POWER: "A1403031",
            GS4: "A1403031",
            GS4PHEV: "A1403031",
            GS4COUPE: "A1403031",
            GS4PLUS: "A1403031",
            GS7: "A1403031",
            GS8: "A1403031",
            GS8S: "A1403031",
            M6: "A1403031",
            M8: "A1403031",
            GA4: "A1403031",
            GA6: "A1403031",
            GA8: "A1403031",
            影豹: "A1403031",
            其他: "A1403031",
        },
        "M8": {
            所有车型: "A1403032",
            GS3: "A1403032",
            GS3POWER: "A1403032",
            GS4: "A1403032",
            GS4PHEV: "A1403032",
            GS4COUPE: "A1403032",
            GS4PLUS: "A1403032",
            GS7: "A1403032",
            GS8: "A1403032",
            GS8S: "A1403032",
            M6: "A1403032",
            M8: "A1403032",
            GA4: "A1403032",
            GA6: "A1403032",
            GA8: "A1403032",
            影豹: "A1403032",
            其他: "A1403032",
        },
        "GA4": {
            所有车型: "A1403033",
            GS3: "A1403033",
            GS3POWER: "A1403033",
            GS4: "A1403033",
            GS4PHEV: "A1403033",
            GS4COUPE: "A1403033",
            GS4PLUS: "A1403033",
            GS7: "A1403033",
            GS8: "A1403033",
            GS8S: "A1403033",
            M6: "A1403033",
            M8: "A1403033",
            GA4: "A1403033",
            GA6: "A1403033",
            GA8: "A1403033",
            影豹: "A1403033",
            其他: "A1403033",
        },
        "GA6": {
            所有车型: "A1403034",
            GS3: "A1403034",
            GS3POWER: "A1403034",
            GS4: "A1403034",
            GS4PHEV: "A1403034",
            GS4COUPE: "A1403034",
            GS4PLUS: "A1403034",
            GS7: "A1403034",
            GS8: "A1403034",
            GS8S: "A1403034",
            M6: "A1403034",
            M8: "A1403034",
            GA4: "A1403034",
            GA6: "A1403034",
            GA8: "A1403034",
            影豹: "A1403034",
            其他: "A1403034",
        },
        "GA8": {
            所有车型: "A1403035",
            GS3: "A1403035",
            GS3POWER: "A1403035",
            GS4: "A1403035",
            GS4PHEV: "A1403035",
            GS4COUPE: "A1403035",
            GS4PLUS: "A1403035",
            GS7: "A1403035",
            GS8: "A1403035",
            GS8S: "A1403035",
            M6: "A1403035",
            M8: "A1403035",
            GA4: "A1403035",
            GA6: "A1403035",
            GA8: "A1403035",
            影豹: "A1403035",
            其他: "A1403035",
        },
        "影豹": {
            所有车型: "A1403036",
            GS3: "A1403036",
            GS3POWER: "A1403036",
            GS4: "A1403036",
            GS4PHEV: "A1403036",
            GS4COUPE: "A1403036",
            GS4PLUS: "A1403036",
            GS7: "A1403036",
            GS8: "A1403036",
            GS8S: "A1403036",
            M6: "A1403036",
            M8: "A1403036",
            GA4: "A1403036",
            GA6: "A1403036",
            GA8: "A1403036",
            影豹: "A1403036",
            其他: "A1403036",
        },
        "其他": {
            所有车型: "A1403037",
            GS3: "A1403037",
            GS3POWER: "A1403037",
            GS4: "A1403037",
            GS4PHEV: "A1403037",
            GS4COUPE: "A1403037",
            GS4PLUS: "A1403037",
            GS7: "A1403037",
            GS8: "A1403037",
            GS8S: "A1403037",
            M6: "A1403037",
            M8: "A1403037",
            GA4: "A1403037",
            GA6: "A1403037",
            GA8: "A1403037",
            影豹: "A1403037",
            其他: "A1403037",
        },
    },
    //分车型裸车毛利
    chartData18: {
        //无论用户选哪个GS3都是这个指标确保不变
        "GS3": {
            所有车型: "A0105051",
            GS3: "A0105051",
            GS3POWER: "A0105051",
            GS4: "A0105051",
            GS4PHEV: "A0105051",
            GS4COUPE: "A0105051",
            GS4PLUS: "A0105051",
            GS7: "A0105051",
            GS8: "A0105051",
            GS8S: "A0105051",
            M6: "A0105051",
            M8: "A0105051",
            GA4: "A0105051",
            GA6: "A0105051",
            GA8: "A0105051",
            影豹: "A0105051",
            其他: "A0105051",
        },
        "GS3 POWER": {
            所有车型: "A0105052",
            GS3: "A0105052",
            GS3POWER: "A0105052",
            GS4: "A0105052",
            GS4PHEV: "A0105052",
            GS4COUPE: "A0105052",
            GS4PLUS: "A0105052",
            GS7: "A0105052",
            GS8: "A0105052",
            GS8S: "A0105052",
            M6: "A0105052",
            M8: "A0105052",
            GA4: "A0105052",
            GA6: "A0105052",
            GA8: "A0105052",
            影豹: "A0105052",
            其他: "A0105052",
        },
        "GS4": {
            所有车型: "A0105053",
            GS3: "A0105053",
            GS3POWER: "A0105053",
            GS4: "A0105053",
            GS4PHEV: "A0105053",
            GS4COUPE: "A0105053",
            GS4PLUS: "A0105053",
            GS7: "A0105053",
            GS8: "A0105053",
            GS8S: "A0105053",
            M6: "A0105053",
            M8: "A0105053",
            GA4: "A0105053",
            GA6: "A0105053",
            GA8: "A0105053",
            影豹: "A0105053",
            其他: "A0105053",
        },
        "GS4 PHEV": {
            所有车型: "A0105054",
            GS3: "A0105054",
            GS3POWER: "A0105054",
            GS4: "A0105054",
            GS4PHEV: "A0105054",
            GS4COUPE: "A0105054",
            GS4PLUS: "A0105054",
            GS7: "A0105054",
            GS8: "A0105054",
            GS8S: "A0105054",
            M6: "A0105054",
            M8: "A0105054",
            GA4: "A0105054",
            GA6: "A0105054",
            GA8: "A0105054",
            影豹: "A0105054",
            其他: "A0105054",
        },
        "GS4 COUPE": {
            所有车型: "A0105055",
            GS3: "A0105055",
            GS3POWER: "A0105055",
            GS4: "A0105055",
            GS4PHEV: "A0105055",
            GS4COUPE: "A0105055",
            GS4PLUS: "A0105055",
            GS7: "A0105055",
            GS8: "A0105055",
            GS8S: "A0105055",
            M6: "A0105055",
            M8: "A0105055",
            GA4: "A0105055",
            GA6: "A0105055",
            GA8: "A0105055",
            影豹: "A0105055",
            其他: "A0105055",
        },
        "GS4PLUS": {
            所有车型: "A0105056",
            GS3: "A0105056",
            GS3POWER: "A0105056",
            GS4: "A0105056",
            GS4PHEV: "A0105056",
            GS4COUPE: "A0105056",
            GS4PLUS: "A0105056",
            GS7: "A0105056",
            GS8: "A0105056",
            GS8S: "A0105056",
            M6: "A0105056",
            M8: "A0105056",
            GA4: "A0105056",
            GA6: "A0105056",
            GA8: "A0105056",
            影豹: "A0105056",
            其他: "A0105056",
        },
        "GS7": {
            所有车型: "A0105057",
            GS3: "A0105057",
            GS3POWER: "A0105057",
            GS4: "A0105057",
            GS4PHEV: "A0105057",
            GS4COUPE: "A0105057",
            GS4PLUS: "A0105057",
            GS7: "A0105057",
            GS8: "A0105057",
            GS8S: "A0105057",
            M6: "A0105057",
            M8: "A0105057",
            GA4: "A0105057",
            GA6: "A0105057",
            GA8: "A0105057",
            影豹: "A0105057",
            其他: "A0105057",
        },
        "GS8": {
            所有车型: "A0105058",
            GS3: "A0105058",
            GS3POWER: "A0105058",
            GS4: "A0105058",
            GS4PHEV: "A0105058",
            GS4COUPE: "A0105058",
            GS4PLUS: "A0105058",
            GS7: "A0105058",
            GS8: "A0105058",
            GS8S: "A0105058",
            M6: "A0105058",
            M8: "A0105058",
            GA4: "A0105058",
            GA6: "A0105058",
            GA8: "A0105058",
            影豹: "A0105058",
            其他: "A0105058",
        },
        "GS8S": {
            所有车型: "A0105059",
            GS3: "A0105059",
            GS3POWER: "A0105059",
            GS4: "A0105059",
            GS4PHEV: "A0105059",
            GS4COUPE: "A0105059",
            GS4PLUS: "A0105059",
            GS7: "A0105059",
            GS8: "A0105059",
            GS8S: "A0105059",
            M6: "A0105059",
            M8: "A0105059",
            GA4: "A0105059",
            GA6: "A0105059",
            GA8: "A0105059",
            影豹: "A0105059",
            其他: "A0105059",
        },
        "M6": {
            所有车型: "A0105060",
            GS3: "A0105060",
            GS3POWER: "A0105060",
            GS4: "A0105060",
            GS4PHEV: "A0105060",
            GS4COUPE: "A0105060",
            GS4PLUS: "A0105060",
            GS7: "A0105060",
            GS8: "A0105060",
            GS8S: "A0105060",
            M6: "A0105060",
            M8: "A0105060",
            GA4: "A0105060",
            GA6: "A0105060",
            GA8: "A0105060",
            影豹: "A0105060",
            其他: "A0105060",
        },
        "M8": {
            所有车型: "A0105061",
            GS3: "A0105061",
            GS3POWER: "A0105061",
            GS4: "A0105061",
            GS4PHEV: "A0105061",
            GS4COUPE: "A0105061",
            GS4PLUS: "A0105061",
            GS7: "A0105061",
            GS8: "A0105061",
            GS8S: "A0105061",
            M6: "A0105061",
            M8: "A0105061",
            GA4: "A0105061",
            GA6: "A0105061",
            GA8: "A0105061",
            影豹: "A0105061",
            其他: "A0105061",
        },
        "GA4": {
            所有车型: "A0105062",
            GS3: "A0105062",
            GS3POWER: "A0105062",
            GS4: "A0105062",
            GS4PHEV: "A0105062",
            GS4COUPE: "A0105062",
            GS4PLUS: "A0105062",
            GS7: "A0105062",
            GS8: "A0105062",
            GS8S: "A0105062",
            M6: "A0105062",
            M8: "A0105062",
            GA4: "A0105062",
            GA6: "A0105062",
            GA8: "A0105062",
            影豹: "A0105062",
            其他: "A0105062",
        },
        "GA6": {
            所有车型: "A0105063",
            GS3: "A0105063",
            GS3POWER: "A0105063",
            GS4: "A0105063",
            GS4PHEV: "A0105063",
            GS4COUPE: "A0105063",
            GS4PLUS: "A0105063",
            GS7: "A0105063",
            GS8: "A0105063",
            GS8S: "A0105063",
            M6: "A0105063",
            M8: "A0105063",
            GA4: "A0105063",
            GA6: "A0105063",
            GA8: "A0105063",
            影豹: "A0105063",
            其他: "A0105063",
        },
        "GA8": {
            所有车型: "A0105064",
            GS3: "A0105064",
            GS3POWER: "A0105064",
            GS4: "A0105064",
            GS4PHEV: "A0105064",
            GS4COUPE: "A0105064",
            GS4PLUS: "A0105064",
            GS7: "A0105064",
            GS8: "A0105064",
            GS8S: "A0105064",
            M6: "A0105064",
            M8: "A0105064",
            GA4: "A0105064",
            GA6: "A0105064",
            GA8: "A0105064",
            影豹: "A0105064",
            其他: "A0105064",
        },
        "影豹": {
            所有车型: "A0105065",
            GS3: "A0105065",
            GS3POWER: "A0105065",
            GS4: "A0105065",
            GS4PHEV: "A0105065",
            GS4COUPE: "A0105065",
            GS4PLUS: "A0105065",
            GS7: "A0105065",
            GS8: "A0105065",
            GS8S: "A0105065",
            M6: "A0105065",
            M8: "A0105065",
            GA4: "A0105065",
            GA6: "A0105065",
            GA8: "A0105065",
            影豹: "A0105065",
            其他: "A0105065",
        },
        "其他": {
            所有车型: "A0105066",
            GS3: "A0105066",
            GS3POWER: "A0105066",
            GS4: "A0105066",
            GS4PHEV: "A0105066",
            GS4COUPE: "A0105066",
            GS4PLUS: "A0105066",
            GS7: "A0105066",
            GS8: "A0105066",
            GS8S: "A0105066",
            M6: "A0105066",
            M8: "A0105066",
            GA4: "A0105066",
            GA6: "A0105066",
            GA8: "A0105066",
            影豹: "A0105066",
            其他: "A0105066",
        },
    },

    chartData8: {
        "终端总数": {
            所有车型: "A0302001",
            GS3: "A0303311",
            GS3POWER: "A0303312",
            GS4: "A0303313",
            GS4PHEV: "A0303314",
            GS4COUPE: "A0303315",
            GS4PLUS: "A0303316",
            GS7: "A0303317",
            GS8: "A0303318",
            GS8S: "A0303319",
            M6: "A0303320",
            M8: "A0303321",
            GA4: "A0303322",
            GA6: "A0303323",
            GA8: "A0303324",
            影豹: "A0303325",
            其他: "A0303326",
        },
        "终端总数环比": {
            所有车型: "A0302001_avg",
            GS3: "A0303311_avg",
            GS3POWER: "A0303312_avg",
            GS4: "A0303313_avg",
            GS4PHEV: "A0303314_avg",
            GS4COUPE: "A0303315_avg",
            GS4PLUS: "A0303316_avg",
            GS7: "A0303317_avg",
            GS8: "A0303318_avg",
            GS8S: "A0303319_avg",
            M6: "A0303320_avg",
            M8: "A0303321_avg",
            GA4: "A0303322_avg",
            GA6: "A0303323_avg",
            GA8: "A0303324_avg",
            影豹: "A0303325_avg",
            其他: "A0303326_avg",
        },
        "终端总数同比": {
            所有车型: "A0302001_avg",
            GS3: "A0303311_avg",
            GS3POWER: "A0303312_avg",
            GS4: "A0303313_avg",
            GS4PHEV: "A0303314_avg",
            GS4COUPE: "A0303315_avg",
            GS4PLUS: "A0303316_avg",
            GS7: "A0303317_avg",
            GS8: "A0303318_avg",
            GS8S: "A0303319_avg",
            M6: "A0303320_avg",
            M8: "A0303321_avg",
            GA4: "A0303322_avg",
            GA6: "A0303323_avg",
            GA8: "A0303324_avg",
            影豹: "A0303325_avg",
            其他: "A0303326_avg",
        },

    },
    chartData9: {
        "展厅终端销量": {
            所有车型: "A0302021",
            GS3: "A0302140",
            GS3POWER: "A0302141",
            GS4: "A0302142",
            GS4PHEV: "A0302143",
            GS4COUPE: "A0302144",
            GS4PLUS: "A0302145",
            GS7: "A0302146",
            GS8: "A0302147",
            GS8S: "A0302148",
            M6: "A0302149",
            M8: "A0302150",
            GA4: "A0302151",
            GA6: "A0302152",
            GA8: "A0302153",
            影豹: "A0302154",
            其他: "A0302155",
        },
        "IDCC终端销量": {
            所有车型: "A0302022",
            GS3: "A0302160",
            GS3POWER: "A0302161",
            GS4: "A0302162",
            GS4PHEV: "A0302163",
            GS4COUPE: "A0302164",
            GS4PLUS: "A0302165",
            GS7: "A0302166",
            GS8: "A0302167",
            GS8S: "A0302168",
            M6: "A0302169",
            M8: "A0302170",
            GA4: "A0302171",
            GA6: "A0302172",
            GA8: "A0302173",
            影豹: "A0302174",
            其他: "A0302175",
        },
        二网终端销量: {
            所有车型: "A0302023",
            GS3: "A0302180",
            GS3POWER: "A0302181",
            GS4: "A0302182",
            GS4PHEV: "A0302183",
            GS4COUPE: "A0302184",
            GS4PLUS: "A0302185",
            GS7: "A0302186",
            GS8: "A0302187",
            GS8S: "A0302188",
            M6: "A0302189",
            M8: "A0302190",
            GA4: "A0302191",
            GA6: "A0302192",
            GA8: "A0302193",
            影豹: "A0302194",
            其他: "A0302195",
        },
        大客户终端销量: {
            所有车型: "A0302024",
            GS3: "A0302200",
            GS3POWER: "A0302201",
            GS4: "A0302202",
            GS4PHEV: "A0302203",
            GS4COUPE: "A0302204",
            GS4PLUS: "A0302205",
            GS7: "A0302206",
            GS8: "A0302207",
            GS8S: "A0302208",
            M6: "A0302209",
            M8: "A0302210",
            GA4: "A0302211",
            GA6: "A0302212",
            GA8: "A0302213",
            影豹: "A0302214",
            其他: "A0302215",
        },
        展厅终端销量占比: {
            所有车型: "A0304024_avg",
            GS3: "A0303100_avg",
            GS3POWER: "A0303101_avg",
            GS4: "A0303102_avg",
            GS4PHEV: "A0303103_avg",
            GS4COUPE: "A0303104_avg",
            GS4PLUS: "A0303105_avg",
            GS7: "A0303106_avg",
            GS8: "A0303107_avg",
            GS8S: "A0303108_avg",
            M6: "A0303109_avg",
            M8: "A0303110_avg",
            GA4: "A0303111_avg",
            GA6: "A0303112_avg",
            GA8: "A0303113_avg",
            影豹: "A0303114_avg",
            其他: "A0303115_avg",
        },
        IDCC终端销量占比: {
            所有车型: "A0304025_avg",
            GS3: "A0303120_avg",
            GS3POWER: "A0303121_avg",
            GS4: "A0303122_avg",
            GS4PHEV: "A0303123_avg",
            GS4COUPE: "A0303124_avg",
            GS4PLUS: "A0303125_avg",
            GS7: "A0303126_avg",
            GS8: "A0303127_avg",
            GS8S: "A0303128_avg",
            M6: "A0303129_avg",
            M8: "A0303130_avg",
            GA4: "A0303131_avg",
            GA6: "A0303132_avg",
            GA8: "A0303133_avg",
            影豹: "A0303134_avg",
            其他: "A0303135_avg",
        },
        二网终端销量占比: {
            所有车型: "A0304026_avg",
            GS3: "A0303140_avg",
            GS3POWER: "A0303141_avg",
            GS4: "A0303142_avg",
            GS4PHEV: "A0303143_avg",
            GS4COUPE: "A0303144_avg",
            GS4PLUS: "A0303145_avg",
            GS7: "A0303146_avg",
            GS8: "A0303147_avg",
            GS8S: "A0303148_avg",
            M6: "A0303149_avg",
            M8: "A0303150_avg",
            GA4: "A0303151_avg",
            GA6: "A0303152_avg",
            GA8: "A0303153_avg",
            影豹: "A0303154_avg",
            其他: "A0303155_avg",
        },
        大客户终端销量占比: {
            所有车型: "A0304027_avg",
            GS3: "A0303160_avg",
            GS3POWER: "A0303161_avg",
            GS4: "A0303162_avg",
            GS4PHEV: "A0303163_avg",
            GS4COUPE: "A0303164_avg",
            GS4PLUS: "A0303165_avg",
            GS7: "A0303166_avg",
            GS8: "A0303167_avg",
            GS8S: "A0303168_avg",
            M6: "A0303169_avg",
            M8: "A0303170_avg",
            GA4: "A0303171_avg",
            GA6: "A0303172_avg",
            GA8: "A0303173_avg",
            影豹: "A0303174_avg",
            其他: "A0303175_avg",
        },
    },
    chartData10: {
        提车目标: {
            所有车型: "A0402001",
            GS3: "--",
            GS3POWER: "--",
            GS4: "--",
            GS4PHEV: "--",
            GS4COUPE: "--",
            GS4PLUS: "--",
            GS7: "--",
            GS8: "--",
            GS8S: "--",
            M6: "--",
            M8: "--",
            GA4: "--",
            GA6: "--",
            GA8: "--",
            影豹: "--",
            其他: "--",
        },

        提车总数: {
            所有车型: "A1402155",
            GS3: "A1402141",
            GS3POWER: "A1402142",
            GS4: "A1402143",
            GS4PHEV: "A1402144",
            GS4COUPE: "A1402145",
            GS4PLUS: "A1402146",
            GS7: "A1402147",
            GS8: "A1402148",
            GS8S: "A1402149",
            M6: "A1402150",
            M8: "A1402151",
            GA4: "A1402152",
            GA6: "A1402153",
            GA8: "A1402154",
            影豹: "A1402156",
            其他: "A1402157",
        },

        提车达成率: {
            所有车型: "A0403001",
            GS3: "--",
            GS3POWER: "--",
            GS4: "--",
            GS4PHEV: "--",
            GS4COUPE: "--",
            GS4PLUS: "--",
            GS7: "--",
            GS8: "--",
            GS8S: "--",
            M6: "--",
            M8: "--",
            GA4: "--",
            GA6: "--",
            GA8: "--",
            影豹: "--",
            其他: "--",
        },
        提车总数环比: {
            所有车型: "A1402155_avg",
            GS3: "A1402141_avg",
            GS3POWER: "A1402142_avg",
            GS4: "A1402143_avg",
            GS4PHEV: "A1402144_avg",
            GS4COUPE: "A1402145_avg",
            GS4PLUS: "A1402146_avg",
            GS7: "A1402147_avg",
            GS8: "A1402148_avg",
            GS8S: "A1402149_avg",
            M6: "A1402150_avg",
            M8: "A1402151_avg",
            GA4: "A1402152_avg",
            GA6: "A1402153_avg",
            GA8: "A1402154_avg",
            影豹: "A1402156_avg",
            其他: "A1402157_avg",
        },

        提车总数同比: {
            所有车型: "A1402155_avg",
            GS3: "A1402141_avg",
            GS3POWER: "A1402142_avg",
            GS4: "A1402143_avg",
            GS4PHEV: "A1402144_avg",
            GS4COUPE: "A1402145_avg",
            GS4PLUS: "A1402146_avg",
            GS7: "A1402147_avg",
            GS8: "A1402148_avg",
            GS8S: "A1402149_avg",
            M6: "A1402150_avg",
            M8: "A1402151_avg",
            GA4: "A1402152_avg",
            GA6: "A1402153_avg",
            GA8: "A1402154_avg",
            影豹: "A1402156_avg",
            其他: "A1402157_avg",
        },
    },
    chartData11: {
        库存总数: {
            所有车型: "A0402006",
            GS3: "A0402012",
            GS3POWER: "A0402018",
            GS4: "A0402024",
            GS4PHEV: "A0402030",
            GS4COUPE: "A0402036",
            GS4PLUS: "A0402042",
            GS7: "A0402048",
            GS8: "A0402054",
            GS8S: "A0402060",
            M6: "A0402115",
            M8: "A0402121",
            GA4: "A0402127",
            GA6: "A0402133",
            GA8: "A0402147",
            影豹: "A0402172",
            其他: "A0402178",
        },


        库存总数环比: {
            所有车型: "A0402006_avg",
            GS3: "A0402012_avg",
            GS3POWER: "A0402018_avg",
            GS4: "A0402024_avg",
            GS4PHEV: "A0402030_avg",
            GS4COUPE: "A0402036_avg",
            GS4PLUS: "A0402042_avg",
            GS7: "A0402048_avg",
            GS8: "A0402054_avg",
            GS8S: "A0402060_avg",
            M6: "A0402115_avg",
            M8: "A0402121_avg",
            GA4: "A0402127_avg",
            GA6: "A0402133_avg",
            GA8: "A0402147_avg",
            影豹: "A0402172_avg",
            其他: "A0402178_avg",
        },


        库存总数同比: {
            所有车型: "A0402006_avg",
            GS3: "A0402012_avg",
            GS3POWER: "A0402018_avg",
            GS4: "A0402024_avg",
            GS4PHEV: "A0402030_avg",
            GS4COUPE: "A0402036_avg",
            GS4PLUS: "A0402042_avg",
            GS7: "A0402048_avg",
            GS8: "A0402054_avg",
            GS8S: "A0402060_avg",
            M6: "A0402115_avg",
            M8: "A0402121_avg",
            GA4: "A0402127_avg",
            GA6: "A0402133_avg",
            GA8: "A0402147_avg",
            影豹: "A0402172_avg",
            其他: "A0402178_avg",
        },


        库销比: {
            所有车型: "A0404045_avg",
            GS3: "A0404091_avg",
            GS3POWER: "A0404092_avg",
            GS4: "A0404093_avg",
            GS4PHEV: "A0404094_avg",
            GS4COUPE: "A0404095_avg",
            GS4PLUS: "A0404096_avg",
            GS7: "A0404097_avg",
            GS8: "A0404098_avg",
            GS8S: "A0404099_avg",
            M6: "A0404100_avg",
            M8: "A0404101_avg",
            GA4: "A0404102_avg",
            GA6: "A0404103_avg",
            GA8: "A0404104_avg",
            影豹: "A0404105_avg",
            其他: "A0404106_avg",
        },


        长库龄占比: {
            所有车型: "A0404111",
            GS3: "A0404112",
            GS3POWER: "A0404113",
            GS4: "A0404114",
            GS4PHEV: "A0404115",
            GS4COUPE: "A0404116",
            GS4PLUS: "A0404117",
            GS7: "A0404118",
            GS8: "A0404119",
            GS8S: "A0404120",
            M6: "A0404121",
            M8: "A0404122",
            GA4: "A0404123",
            GA6: "A0404124",
            GA8: "A0404125",
            影豹: "A0404126",
            其他: "A0404127",
        },
    },
    chartData12: {
        新保台次: {
            所有车型: "A0302027",
            GS3: "A0302027",
            GS3POWER: "A0302027",
            GS4: "A0302027",
            GS4PHEV: "A0302027",
            GS4COUPE: "A0302027",
            GS4PLUS: "A0302027",
            GS7: "A0302027",
            GS8: "A0302027",
            GS8S: "A0302027",
            M6: "A0302027",
            M8: "A0302027",
            GA4: "A0302027",
            GA6: "A0302027",
            GA8: "A0302027",
            影豹: "A0302027",
            其他: "A0302027",
        },

        金融按揭台次: {
            所有车型: "A0302028",
            GS3: "A0302028",
            GS3POWER: "A0302028",
            GS4: "A0302028",
            GS4PHEV: "A0302028",
            GS4COUPE: "A0302028",
            GS4PLUS: "A0302028",
            GS7: "A0302028",
            GS8: "A0302028",
            GS8S: "A0302028",
            M6: "A0302028",
            M8: "A0302028",
            GA4: "A0302028",
            GA6: "A0302028",
            GA8: "A0302028",
            影豹: "A0302028",
            其他: "A0302028",
        },
        二手车台次: {
            所有车型: "A0302030",
            GS3: "A0302030",
            GS3POWER: "A0302030",
            GS4: "A0302030",
            GS4PHEV: "A0302030",
            GS4COUPE: "A0302030",
            GS4PLUS: "A0302030",
            GS7: "A0302030",
            GS8: "A0302030",
            GS8S: "A0302030",
            M6: "A0302030",
            M8: "A0302030",
            GA4: "A0302030",
            GA6: "A0302030",
            GA8: "A0302030",
            影豹: "A0302030",
            其他: "A0302030",
        },
        新车延保台次: {
            所有车型: "A0302033",
            GS3: "A0302033",
            GS3POWER: "A0302033",
            GS4: "A0302033",
            GS4PHEV: "A0302033",
            GS4COUPE: "A0302033",
            GS4PLUS: "A0302033",
            GS7: "A0302033",
            GS8: "A0302033",
            GS8S: "A0302033",
            M6: "A0302033",
            M8: "A0302033",
            GA4: "A0302033",
            GA6: "A0302033",
            GA8: "A0302033",
            影豹: "A0302033",
            其他: "A0302033",
        },
        新保渗透率: {
            所有车型: "A0303272",
            GS3: "A0303272",
            GS3POWER: "A0303272",
            GS4: "A0303272",
            GS4PHEV: "A0303272",
            GS4COUPE: "A0303272",
            GS4PLUS: "A0303272",
            GS7: "A0303272",
            GS8: "A0303272",
            GS8S: "A0303272",
            M6: "A0303272",
            M8: "A0303272",
            GA4: "A0303272",
            GA6: "A0303272",
            GA8: "A0303272",
            影豹: "A0303272",
            其他: "A0303272",
        },
        金融按揭渗透率: {
            所有车型: "A0303273",
            GS3: "A0303273",
            GS3POWER: "A0303273",
            GS4: "A0303273",
            GS4PHEV: "A0303273",
            GS4COUPE: "A0303273",
            GS4PLUS: "A0303273",
            GS7: "A0303273",
            GS8: "A0303273",
            GS8S: "A0303273",
            M6: "A0303273",
            M8: "A0303273",
            GA4: "A0303273",
            GA6: "A0303273",
            GA8: "A0303273",
            影豹: "A0303273",
            其他: "A0303273",
        },
        二手车置换率: {
            所有车型: "A0303275",
            GS3: "A0303275",
            GS3POWER: "A0303275",
            GS4: "A0303275",
            GS4PHEV: "A0303275",
            GS4COUPE: "A0303275",
            GS4PLUS: "A0303275",
            GS7: "A0303275",
            GS8: "A0303275",
            GS8S: "A0303275",
            M6: "A0303275",
            M8: "A0303275",
            GA4: "A0303275",
            GA6: "A0303275",
            GA8: "A0303275",
            影豹: "A0303275",
            其他: "A0303275",
        },
        新车延保渗透率: {
            所有车型: "A0303276",
            GS3: "A0303276",
            GS3POWER: "A0303276",
            GS4: "A0303276",
            GS4PHEV: "A0303276",
            GS4COUPE: "A0303276",
            GS4PLUS: "A0303276",
            GS7: "A0303276",
            GS8: "A0303276",
            GS8S: "A0303276",
            M6: "A0303276",
            M8: "A0303276",
            GA4: "A0303276",
            GA6: "A0303276",
            GA8: "A0303276",
            影豹: "A0303276",
            其他: "A0303276",
        }

    },


}


export {
    carTypeCode
};
