import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
      state: {
        // 默认读取storage里面的数据
        name: window.localStorage.getItem("user") || "",
      },
      mutations: {
        setData(state, data) {
          // 拿到数据有，向state和storage里面都放入数据
          state.name = data;
          window.localStorage.setItem("user", data);
        },
      },
      actions: {
        getData({ commit, state }, name) {
          commit("setData", name);
        },
      },
    },
);

export default store;

