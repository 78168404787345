<template functional>
  <a-sub-menu :key="props.menu.MenuId">
    <span slot="title">
      <a-icon :type="props.menu.Icon" :style="{fontSize:props.collapsed?'28px':''}" :alt="props.menu.MenuNameCN"/>
      {{ props.collapsed? '': props.menu.MenuNameCN }}
    </span>

    <template v-for="subMenu in props.menu.Child">
      <template v-if="subMenu.Child.length>0">
        <sub-Menu :key="subMenu.MenuId" :menu="subMenu" ></sub-Menu>
      </template>
      <template v-else>
        <a-menu-item :key="subMenu.MenuId">
          <span><a-icon :type="subMenu.Icon"/>{{ subMenu.MenuNameCN }}</span>
        </a-menu-item>
      </template>
    </template>

  </a-sub-menu>
</template>

<script>
export default {
  name: 'SubMenu',
  props: {
    menu: {},
    collapsed: {}
  }
}
</script>
