<template>
  <section>
    <div class="part">
      <a-row :gutter="0">
        <div class="report-title">
                    <span class="top-title" style="width: 38%">
                        广汽传祺{{ new Date(params.ReportDate).getMonth() + 1 }}月{{ params.PartName }}销售店业绩分析报告
                    </span>
          <span class="info">
                        <span>
                            {{ new Date(params.ReportDate).getMonth() + 1 }}月店均终端
                            <span class="num">56</span>
                        </span>
                        <span>
                            盈亏保本台次
                            <span class="num">99</span>
                        </span>
                    </span>
          <span class="title_right">
                        本月共回收报表438份（2020年新店/异常店暂未回收），分析有效数据源共计369份。
                    </span>
        </div>
      </a-row>
      <a-row :gutter="36">
        <a-col :span="24">
          <div class="part-title">
                        <span class="title">
                            <span>
                              <a-icon type="search"/>整体业务概览
                            </span>
                        </span>
            <div class="notice">
              <p><span class="notice-title">1、总体情况：</span>全国销售店盈利水平以及盈亏店数占比</p>
              <p><span class="notice-title">2、可变业务：</span>全国销售店可变业务盈利水平以及盈亏保本台次</p>
            
            </div>
            <div class="notice">
              <p><span class="notice-title">3、固定业务：</span>全国销售店固定业务盈利水平以及服务满足率</p>
              <p><span class="notice-title">4、前后端产值比：</span>反应全国销售店可变业务及固定业务的产值比例</p>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row style="margin-top: 10px" :gutter="36">
        <a-col :span="8" class="one_table">
          <h2>店均 利润报表</h2>
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_1_1"
          >
          </monthReportTableComponent>
          <h2 style="margin-top: 75px">店均可变业务：新车销售业务+销售水平事业</h2>
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_1_2"
          >
          </monthReportTableComponent>
          <p>盈亏保本台次=未覆盖费用/单台贡献（未覆盖费用=总费用-固定业务毛利）
          </p>
        </a-col>
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">盈亏店数比</h2>
          <chartComponent class="border chart-part" :chart-data="data.specialChartData_1_3"
                          chart-height="420px"
          />
          <h2 style="margin-top: 25px">店均固定业务：售后维修业务+售后水平事业</h2>
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_1_4"
          >
          </monthReportTableComponent>
          <p>服务满足率=固定业务毛利/全店费用</p>
        </a-col>
        <a-col :span="8">
          <h2 class="chart-title">店均净利润 万元</h2>
          <chartComponent class="border chart-part" :chart-data="data.chartData_1_5"
                          chart-height="420px"
          />
          <h2 class="chart-title">前后端产值比（可变业务收入/固定业务收入）</h2>
          <chartComponent class="border chart-part" :chart-data="data.chartData_1_6"
                          chart-height="450px"
          />
        </a-col>
      </a-row>
      <a-row :gutter="36">
        <a-col :span="24">
          <div class="part-title">
                        <span class="title">
                            <span>
                                <a-icon type="search"/>销售业务板块
                            </span>
                        </span>
            <div class="notice">
              <p><span class="notice-title">1、可变业务盈利能力：</span>反应销售店在可变业务各领域的盈利情况</p>
              <p><span class="notice-title">2、销售人员效率：</span>销售人员在所属岗位为销售店带来的效益</p>
              <p><span class="notice-title">3、销售渠道销量占比：</span>销售店在各销售渠道的新车销售能力</p>
            </div>
            <div class="notice">
              <p><span class="notice-title">4、销售过程效率：</span>反应销售店在各销售渠道的销售流程管控效率</p>
              <p><span class="notice-title">5、销售水平事业：</span>销售店在高毛利的业务中的盈利能力</p>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="36">
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">销售毛利</h2>
          <h3 class="sub-chart-title">裸车毛利率、返利后毛利率</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_2_1"
                          chart-height="600px"
          />
          <h3 class="sub-chart-title padTop10">单车销售综合毛利构成变化(元)</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_2_2"
                          chart-height="600px"
          />
        </a-col>
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">销售水平事业效率</h2>
          <h3 class="sub-chart-title">销售水平事业毛利（万元）及毛利率</h3>
          <chartComponent chart-height="600px" class="border chart-part"
                          :chart-data="data.chartData_2_3"
          />
          <h3 class="sub-chart-title padTop10">销售水平事业渗透率</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_2_4"
                          chart-height="600px"
          />
          <!--           <h3 class="sub-chart-title">销售水平事业渗透率</h3>
                 <div class="custom-table">
                     <customTableComment custom-table="customTable" :custom-table-data="customTableData.customTableData_4_3"/>
                   </div>-->
        </a-col>
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">渠道效率</h2>
          <h3 class="sub-chart-title">渠道销量占比</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_2_5"
                          chart-height="430px"
          />
          <h3 class="sub-chart-title padTop10">销售过程效率</h3>
          <div class="custom-table">
            <customTableComment custom-table="customTable" :custom-table-data="customTableData.customTableData_2_6"/>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="part">
      <a-row :gutter="36">
        <a-col :span="24">
          <div class="part-title">
                        <span class="title">
                            <span>
                               <a-icon type="search"/>售后业务板块
                            </span>
                        </span>
            <div class="notice">
              <p><span class="notice-title">1、售后来厂及保有客户维系：</span>反应销售店月度来场以及客户维系情况</p>
              <p><span class="notice-title">2、售后维修业务盈利能力：</span>反应销售店售后维修业务的盈利情况</p>
            </div>
            <div class="notice">
              <p><span class="notice-title">3、售后水平事业：</span>销售店在高毛利的业务中的盈利能力</p>
              <p><span class="notice-title">4、服务满足率：</span>反应销售店售后业务毛利能够覆盖销售店当月费用的能力</p>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="36">
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">保有客户维系及来厂台次</h2>
          <h3 class="sub-chart-title">店均有效保有客户数</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_3_1"
                          chart-height="500px"
          />
          <h3 class="sub-chart-title padTop10">回厂台次（台）</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_3_2"
                          chart-height="500px"
          />
        </a-col>
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">售后维修业务产值及毛利</h2>
          <h3 class="sub-chart-title">店均产值（万元）及单车产值（元）</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_3_3"
                          chart-height="500px"
          />
          <h3 class="sub-chart-title padTop10">售后单车毛利（元）及毛利率</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_3_4"
                          chart-height="500px"
          />
        </a-col>
        <a-col :span="8" class="one_table">
          <h2 class="chart-title">售后水平事业毛利率</h2>
          <h3 class="sub-chart-title">售后水平事业毛利（万元）及毛利率</h3>
          <chartComponent class="border chart-part" :chart-data="data.chartData_3_5"
                          chart-height="500px"
          />
          <h2 class="chart-title padTop10">服务满足率</h2>
          <chartComponent class="border chart-part" :chart-data="data.specialChartData_3_6"
                          chart-height="490px"
          />
        </a-col>
      </a-row>
      <a-row :gutter="36">
        <a-col :span="8">
          <div class="part-title">
                        <span class="title">
                            <span>
                               <a-icon type="search"/>资产风险
                            </span>
                        </span>
            <div class="notice">
              <p><span class="notice-title">1、资产风险管控：</span>反应销售店偿债能力以及资产流动情况</p>
              <p><span class="notice-title">2、库存风险管控：</span>反应销售店库存周转以及库存结构的健康程度</p>
            </div>
          </div>
        </a-col>
        <a-col :span="16">
          <div class="part-title">
                        <span class="title">
                            <span>
                                <a-icon type="search"/>费用管控
                            </span>
                        </span>
            <div class="notice">
              <p><span class="notice-title">1、整体费用管控：</span>从各类费用及费用占比反应销售店费用管控能力</p>
              <p><span class="notice-title">2、单车费用管控：</span>反应销售店在销售新车时对费用的利用情况</p>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="36">
        <a-col :span="8">
          <h2 class="chart-title">1、资产风险管控</h2>
          <chartComponent class="border chart-part" :chart-data="data.specialChartData_4_1"
                          chart-height="600px"
          />
          <h2 class="chart-title">2、偿债能力 速动比率=（流动资产-存货）/流动负债；流动比率=流动资产/流动负债</h2>
          <chartComponent class="border chart-part" :chart-data="data.chartData_4_2"
                          chart-height="600px"
          />
        
        </a-col>
        <a-col :span="8">
          <h2 class="chart-title">1、全店费用（万元）</h2>
          <chartComponent chart-height="600px" class="border chart-part" :chart-data="data.chartData_5_1"/>
          <h2 class="chart-title" style="margin-top: 200px">2、分领域费用（万元）-- 店均费用：123123</h2>
          <div class="custom-table">
            <customTableComment custom-table="customTable" :custom-table-data="customTableData.customTableData_5_2"/>
          </div>
        </a-col>
        <a-col :span="8">
          <h2 class="chart-title">3、销售领域费用分析 -- 平均单车费用:123123</h2>
          <div class="custom-table">
            <customTableComment custom-table="customTable" :custom-table-data="customTableData.customTableData_5_3"/>
          </div>
          <h2 class="chart-title" style="margin-top: 130px">4、长库龄(大于90天库存/总库存)</h2>
          <chartComponent class="border chart-part" :chart-data="data.chartData_5_4"
                          chart-height="600px"
          />
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import monthReportTableComponent from "@/components/monthReportTableComponent";
import chartComponent from "@/components/chartComponent";
import customTableComment from "@/components/customTableComment";
import { customTableGet } from "@/until/customTableGetData";
import { getEmptyTemplate, numFormat } from "@/until/reportCalculate";
import {
  columnsImport,
  overViewData,
  variableData,
  regularizeData,
  propertyData,
  costData,
} from "@/views/countryMonthReport/analysisReport/dataPrint.js";

export default {
  name: "print2",
  data() {
    return {
      params: {},
      areaData: {},
      emptyTemplate: getEmptyTemplate(),
      columnsData: columnsImport.columnsImportData,
      customTableData: {
        customTableData_5_2: {
          table: [{
            name: "人工费用",
            kpiCode: "C0202020",
          }, {
            name: "固定费用",
            kpiCode: "C0202020",
          }, {
            name: "其中：租赁费",
            kpiCode: "C0202025",
            indentation: 2,
          }, {
            name: "半固定费用",
            kpiCode: "C0202124",
          }, {
            name: "财务费用",
            kpiCode: "C0202238",
          }],
          data: [],
          unitD: "万元",
        },
        customTableData_5_3: {
          useAvg: true,
          table: [
            {
              name: "平均单车销售业务费用",
              kpiCode: "A0206032",
            }, {
              name: "平均单车广宣费用",
              kpiCode: "A0205034",
            }, {
              name: "平均单车垂直网站费用",
              kpiCode: "A0204041",
            }, {
              name: "平均单车财务费用",
              kpiCode: "A0205035",
            }, {
              name: "平均单车销售顾问工资",
              kpiCode: "A0204032",
            }, {
              name: "平均单车销售其他费用",
              kpiCode: "A0207021",
            }],
          data: [],
          unitD: "元",
        },
        customTableData_2_6: {
          useAvg: true,
          table: [{
            name: "展厅首次客流",
            kpiCode: "A1302012",
          }, {
            name: "展厅再次客流",
            kpiCode: "A1302013",
          }, {
            name: "展厅首次客流成交率",
            kpiCode: "A1303081",
            unit: "%",
          }, {
            name: "展厅再次客流成交率",
            kpiCode: "A1303082",
            unit: "%",
          },
            {
              name: "IDCC线索总量",
              kpiCode: "A1302115",
            }, {
              name: "IDCC线索有效率",
              kpiCode: "A1303036",
              unit: "%",
            }, {
              name: "iDCC邀约到店率",
              kpiCode: "A1303024",
              unit: "%",
            }, {
              name: "iDCC邀约到店成交率",
              kpiCode: "A1303028",
              unit: "%",
            }, {
              name: "iDCC线索转化率",
              kpiCode: "A1303032",
              unit: "%",
            }],
          data: [],
          unitD: "个",
        },
        customTableData_4_3: {
          table: [{
            name: "流失率",
            kpiCode: "C0303153",
            unit: "%",
          }, {
            name: "人均销量",
            kpiCode: "A0304034",
            unit: "台",
          }],
          data: [],
        },
        
      },
      data: {
        ...overViewData,
        ...variableData,
        ...regularizeData,
        ...propertyData,
        ...costData,
      },
    };
  },
  components: { monthReportTableComponent, chartComponent, customTableComment },
  mounted() {
    // 获取路由参数
    this.getParams();
    columnsImport.loadData(this, this.data, this.params);
    this.GetDealerInputStatistics();
    this.getCustomTableData();
  },
  methods: {
    async getCustomTableData() {
      await customTableGet(this, this.params, this.customTableData);
      
    },
    getParams() {
      let params = this.$route.query;
      if (Object.keys(params).length > 0) {
        this.params = params;
      }
    },
    async GetDealerInputStatistics() {
      let self = this;
      let res = await self.$Http.GetDealerInputStatistics(this.params);
      self.areaData = res.Data;
    },
    GetIndexValue(key, unit) {
      let value = 0;
      if (this.areaData && this.areaData["IndexValues"]) {
        value = this.areaData["IndexValues"][key];
      }
      return numFormat(key, value, unit);
    },
  },
};

</script>

<style lang="less" scoped>
/* 表格通用样式 */
@import "./src/assets/printCommon.less";

::v-deep .custom-table table {
  font-size: 22px !important;color: black !important;
  
  tr {
    min-height: 70px !important;
    }
  }

.company-info {
  height: 150px;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  font-size: 30px;
  }

.part-info {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  }

.part-tips {
  margin-bottom: 30px;
  font-size: 22px;
  }

::v-deep {
  .ant-table-thead > tr > th {
    padding: 5px 5px 5px 7px !important;
    text-align: right;
    background: #FAFAFA;
    color: #000;
    font-size: 22px;
    
    .ant-table-column-title {
      padding-left: 7px;
      }
    
    &:last-child {
      text-align: center;
      }
    }
  
  .ant-table-tbody .ant-table-row {
    &:nth-child(even) {
      //  background: #e8e8e8;
      }
    
    td {
      font-size: 22px;
      padding: 16px 5px;
      text-align: right;
      color: black;
      
      &:first-child {
        text-align: left;
        font-size: 22px; width: 400px;
        //  max-width: 240px;
        }
      
      &:last-child {
        text-align: center;
        font-size: 22px;
        //  max-width: 240px;
        }
      }
      
    }
  }

.padTop10 {
  margin-top: 20px;
  }
</style>
