<template>
    <div id="app">
        <div class="time no-print">
            <el-date-picker
                @change="chooseMonth"
                v-model="month"
                type="month"
                placeholder="选择月份"
                style="margin-left: 30px"
            >
            </el-date-picker>
            <el-select
                placeholder="请选择小组"
                style="margin-left: 30px"
                v-model="partName"
                @change="
          (value) => {
            chooseGroup(value, true);
          }
        "
            >
                <el-option v-for="(item, i) in group" :key="i" :value="item">
                </el-option>
            </el-select>
            <el-select
                placeholder="请选择经销商"
                style="margin-left: 30px"
                v-model="dealerCode"
                @change="chooseDealer"
            >
                <el-option
                    v-for="item in dealerList"
                    :key="item.DealerCode"
                    :label="item.DealerName"
                    :value="item.DealerCode"
                >
                </el-option>
            </el-select>
        </div>
        <div class="container">
            <div class="title" id="one">
                <div>{{ dealerName }}单店诊断报告</div>
            </div>
            <div class="lable_con">
                <div class="lable">销售店综合信息</div>
            </div>

            <div class="content">
                <div class="con_con">
                    <div class="con_title">销售店基本信息</div>
                    <div class="con_cot">
                        <div class="con_cot_cen">
              <span class="con_cot_text"
              >{{ dealerInfo.ProvinceName }}/{{ dealerInfo.AreaName }}</span
              ><span class="con_cot_text">{{ dealerInfo.OpenTime }}</span>
                        </div>
                    </div>
                    <div class="rideo_rs" id="rideoJsID">
                        <div style="font-size: 12px; font-weight: bold; margin-bottom: 8px">
                            效率评价
                        </div>
                        <div class="rideo_item">
                            <div class="rider_con">
                                <div class="ri_hover">
                                    <div class="rideo">
                                        <div
                                            :class="
                        efficiencyRemark > 80 ? 'active-circle' : 'circle'
                      "
                                            class="green"
                                        ></div>
                                    </div>
                                </div>
                                <div class="rider_tips">&gt;80</div>
                                <div class="rider_text">优秀</div>
                            </div>
                            <div class="rider_con">
                                <div class="ri_hover">
                                    <div class="rideo">
                                        <div
                                            :class="
                        efficiencyRemark <= 80 && efficiencyRemark >= 70
                          ? 'active-circle'
                          : 'circle'
                      "
                                            class="blue"
                                        ></div>
                                    </div>
                                </div>
                                <div class="rider_tips">70-80</div>
                                <div class="rider_text">健康</div>
                            </div>
                            <div class="rider_con">
                                <div class="ri_hover">
                                    <div class="rideo">
                                        <div
                                            :class="
                        efficiencyRemark <= 69 && efficiencyRemark >= 50
                          ? 'active-circle'
                          : 'circle'
                      "
                                            class="orange"
                                        ></div>
                                    </div>
                                </div>
                                <div class="rider_tips">50-69</div>
                                <div class="rider_text">成长</div>
                            </div>
                            <div class="rider_con">
                                <div class="ri_hover">
                                    <div class="rideo">
                                        <div
                                            :class="
                        efficiencyRemark < 50 && efficiencyRemark >= 0
                          ? 'active-circle'
                          : 'circle'
                      "
                                            class="red"
                                        ></div>
                                    </div>
                                </div>
                                <div class="rider_tips">&lt;50</div>
                                <div class="rider_text">预警</div>
                            </div>
                        </div>
                    </div>
                    <p class="con_lable_text">以下各项小分根据各门店该项实际数据，对比基准指标进行评分，数据超出基准越高，评分越高，反之评分越低。</p>
                </div>
                <div class="con_con_2">
                    <div class="con_title">业务概览</div>
                    <div class="yewu-item">
                        <div class="yewu-table">
                            <table border="2">
                                <tr>
                                    <th>项目</th>
                                    <th>实绩</th>
                                    <th>区域排名</th>
                                    <th>全国排名</th>
                                    <th>项目</th>
                                    <th>实绩</th>
                                    <th>区域排名</th>
                                    <th>全国排名</th>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold; background-color:#dfe2ee "
                                    >
                                        提车
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A1402155.NumValue.toFixed(0)
                                        }}台
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.A1402155.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A1402155.CountryRank
                                        }}
                                    </td>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold; background-color:#dfe2ee">
                                        售后来厂
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0302001.NumValue.toFixed(0)
                                        }}台
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.B0302001.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0302001.CountryRank
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold; background-color:#dfe2ee">
                                        终端
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A0302001.NumValue.toFixed(0)
                                        }}台
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.A0302001.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A0302001.CountryRank
                                        }}
                                    </td>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold; background-color:#dfe2ee">
                                        售后单车产值
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0104226.NumValue.toFixed(2)
                                        }}元
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.B0104226.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0104226.CountryRank
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold;background-color:#dfe2ee">
                                        销售单车毛利
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A0107004.NumValue.toFixed(2)
                                        }}元
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.A0107004.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A0107004.CountryRank
                                        }}
                                    </td>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold;background-color:#dfe2ee">
                                        售后单车毛利
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0106027.NumValue.toFixed(2)
                                        }}元
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.B0106027.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0106027.CountryRank
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold;background-color:#dfe2ee">
                                        销售人均生产力
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A0107014.NumValue.toFixed(2)
                                        }}元
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.A0107014.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.A0107014.CountryRank
                                        }}
                                    </td>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold;background-color:#dfe2ee">
                                        售后人均生产力
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0306002.NumValue.toFixed(2)
                                        }}元
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{ rankTable.B0306002.AreaRank }}
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.B0306002.CountryRank
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold;background-color:#dfe2ee">
                                        盈亏保本台次
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            rankTable.C0208001.NumValue.toFixed(0)
                                        }}台
                                    </td>
                                    <td style="background-color:#f0f6fb ">—</td>
                                    <td style="background-color:#f0f6fb ">—</td>
                                    <td style="text-align: left;padding-left: 10px;font-weight: bold;background-color:#dfe2ee">
                                        零服吸收率
                                    </td>
                                    <td style="text-align: right;padding-right: 1rem;background-color:#f0f6fb ">
                                        {{
                                            (rankTable.B0107002.NumValue*100).toFixed(1) + "%"
                                        }}
                                    </td>
                                    <td style="background-color:#f0f6fb ">—</td>
                                    <td style="background-color:#f0f6fb ">—</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <div class="lable">销售店指标状态分析</div>
            </div>
            <div style="margin-left: 20px">
                <div style="display: flex; flex-direction: column" id="two">
                    <div class="con_title">盈利能力</div>
                    <div class="con_lable">
                        <div class="con_lable_conten">
                            <p class="con_lable_title">三大指标</p>
                            <div class="con_lable_text">
                                业务概览主要体现了销售以及售后业务毛利获取能力，以及销售店费用管控情况。
                            </div>
                            <div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">1</div>
                                        </div>
                                        <div style="flex: 1">销售业务综合毛利率</div>
                                        <div :class="getGradeStyle(score.A0107002)">
                                            {{ score.A0107002 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>销售业务综合毛利÷销售业务综合收入</div>
                                        <div v-if="isShowSeeDetail(score.A0107002)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">2</div>
                                        </div>
                                        <div style="flex: 1">售后业务综合毛利率</div>
                                        <div :class="getGradeStyle(score.B0106026)">
                                            {{ score.B0106026 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>售后业务综合毛利÷售后业务综合收入</div>
                                        <div v-if="isShowSeeDetail(score.B0106026)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">3</div>
                                        </div>
                                        <div style="flex: 1">费用率</div>
                                        <div :class="getGradeStyle(score.C0207022)">
                                            {{ score.C0207022 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>总费用÷总收入</div>
                                        <div v-if="isShowSeeDetail(score.C0207022)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_bar">
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">1</div>
                                    </div>
                                    <div class="header-content">
                                        <p>销售业务综合毛利率</p>
                                        <p>范围值：3%～8%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0107002"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">2</div>
                                    </div>
                                    <div class="header-content">
                                        <p>售后业务综合毛利率</p>
                                        <p>范围值：30%～65%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="B0106026"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">3</div>
                                    </div>
                                    <div class="header-content">
                                        <p>费用率</p>
                                        <p>范围值：3.6%～5.6%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="C0207022"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-direction: column">
                    <div class="con_title_sub"></div>
                    <div class="con_lable">
                        <div class="con_lable_conten">
                            <p class="con_lable_title">单台毛利</p>
                            <div class="con_lable_text">
                                平均单车主要关注销售店新车销售返利的获取能力，是否能够足额的拿到主机厂的商务政策返利以及销售水平事业的开展情况。
                            </div>
                            <div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">1</div>
                                        </div>
                                        <div style="flex: 1">平均单车销售返利</div>
                                        <div :class="getGradeStyle(score.A0105071)">
                                            {{ score.A0105071 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>销售业务返利÷新车总销量</div>
                                        <div v-if="isShowSeeDetail(score.A0105071)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">2</div>
                                        </div>
                                        <div style="flex: 1">平均单车销售水平事业毛利</div>
                                        <div :class="getGradeStyle(score.A0105072)">
                                            {{ score.A0105072 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>销售水平事业毛利÷新车总销量</div>
                                        <div v-if="isShowSeeDetail(score.A0105072)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="con_bar">
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">1</div>
                                    </div>
                                    <div class="header-content">
                                        <p>平均单车销售返利</p>
                                        <p>范围值：5000-8000</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0105071"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">2</div>
                                    </div>
                                    <div class="header-content">
                                        <p>平均单车销售水平事业毛利</p>
                                        <p>范围值：2500-4500</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0105072"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 打印分页 -->
                <div style="page-break-after: always"></div>
                <div class="title">
                    <div>{{ dealerName }}单店诊断报告</div>
                </div>
                <div style="min-height: 400px; display: flex; flex-direction: column" id="there">
                    <div class="con_title">运营能力</div>
                    <div class="con_lable">
                        <div class="con_lable_conten">
                            <p class="con_lable_title">销售效率</p>
                            <div class="con_lable_text">
                                销售效率从新车销量、新车库存、人员销量、置换及转介绍这些销售重点指标，体现了销售效率的管理能力。
                            </div>
                            <div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">1</div>
                                        </div>
                                        <div style="flex: 1">终端达成率</div>
                                        <div :class="getGradeStyle(score.A0403002)">
                                            {{ score.A0403002 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>新车总销量÷终端目标销量</div>
                                        <div v-if="isShowSeeDetail(score.A0403002)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">2</div>
                                        </div>
                                        <div style="flex: 1">大车销量占比</div>
                                        <div :class="getGradeStyle(score.A0304151)">
                                            {{ score.A0304151 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>(M8终端销量+GS8终端销量+GA8终端销量)÷新车总销量</div>
                                        <div v-if="isShowSeeDetail(score.A0304151)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">3</div>
                                        </div>
                                        <div style="flex: 1">库销比</div>
                                        <div :class="getGradeStyle(score.A0404045)">
                                            {{ score.A0404045 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>新车库存期末台次÷新车总销量</div>
                                        <div v-if="isShowSeeDetail(score.A0404045)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">4</div>
                                        </div>
                                        <div style="flex: 1">长库龄新车库存占比</div>
                                        <div :class="getGradeStyle(score.A0404111)">
                                            {{ score.A0404111 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>长库龄新车库存台次÷新车库存期末台次</div>
                                        <div v-if="isShowSeeDetail(score.A0404111)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">5</div>
                                        </div>
                                        <div style="flex: 1">销售顾问人均销量(零售)</div>
                                        <div :class="getGradeStyle(score.A0304141)">
                                            {{ score.A0304141 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>(展厅终端台次+iDCC终端台次)÷销售顾问人数</div>
                                        <div v-if="isShowSeeDetail(score.A0304141)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">6</div>
                                        </div>
                                        <div style="flex: 1">二手车置换率</div>
                                        <div :class="getGradeStyle(score.A0303275)">
                                            {{ score.A0303275 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>二手车置换总数÷销量</div>
                                        <div v-if="isShowSeeDetail(score.A0303275)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">7</div>
                                        </div>
                                        <div style="flex: 1" id="A0205035_txtJsId">
                                            老客户转介绍率
                                        </div>
                                        <div :class="getGradeStyle(score.A0304030)">
                                            {{ score.A0304030 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>老客户转介绍销售台次÷新车总销量</div>
                                        <div v-if="isShowSeeDetail(score.A0304030)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_bar">
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">1</div>
                                    </div>
                                    <div class="header-content">
                                        <p>终端达成率</p>
                                        <p>范围值：100%-120%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0403002"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">2</div>
                                    </div>
                                    <div class="header-content">
                                        <p>大车销量占比</p>
                                        <p>范围值：15%-30%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0304151"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">3</div>
                                    </div>
                                    <div class="header-content">
                                        <p>库销比</p>
                                        <p>范围值：0.85-1.5</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0404045"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">4</div>
                                    </div>
                                    <div class="header-content">
                                        <p>长库龄新车库存占比</p>
                                        <p>范围值：4%-9%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0404111"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">5</div>
                                    </div>
                                    <div class="header-content">
                                        <p>销售顾问人均销量（零售）</p>
                                        <p>范围值：5-8</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0304141"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">6</div>
                                    </div>
                                    <div class="header-content">
                                        <p>二手车置换率</p>
                                        <p>范围值：10%-20%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0303275"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">7</div>
                                    </div>
                                    <div class="header-content">
                                        <p>老客户转介绍率</p>
                                        <p>范围值：6%-15%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0304030"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-direction: column">
                    <div class="con_title_sub"></div>
                    <div class="con_lable">
                        <div class="con_lable_conten">
                            <p class="con_lable_title">通道效率</p>
                            <div class="con_lable_text">
                                通道效率是体现了各个销售通道效率，以及展厅和iDCC通道作为零售的终端销量占比。
                            </div>
                            <div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">1</div>
                                        </div>
                                        <div style="flex: 1">展厅成交率</div>
                                        <div :class="getGradeStyle(score.A1303083)">
                                            {{ score.A1303083 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>
                                            (展厅首次客流新增订单+展厅再次客流新增订单)÷(展厅首次客流总数+展厅再次客流总数)
                                        </div>
                                        <div v-if="isShowSeeDetail(score.A1303083)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>

                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">2</div>
                                        </div>
                                        <div style="flex: 1">展厅二次进店率</div>
                                        <div :class="getGradeStyle(score.A1303007)">
                                            {{ score.A1303007 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>
                                            展厅再次客流总数÷(展厅再次客流总数+展厅首次客流总数)
                                        </div>
                                        <div v-if="isShowSeeDetail(score.A1303007)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>

                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">3</div>
                                        </div>
                                        <div style="flex: 1">iDCC线索转化率</div>
                                        <div :class="getGradeStyle(score.A1303032)">
                                            {{ score.A1303032 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>iDCC新增订单数÷iDCC线索总数</div>
                                        <div v-if="isShowSeeDetail(score.A1303032)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>


                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">4</div>
                                        </div>
                                        <div style="flex: 1" id="C0408001_txtJsId">
                                            零售终端占比
                                        </div>
                                        <div :class="getGradeStyle(score.A0304029)">
                                            {{ score.A0304029 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>(展厅终端台次+IDCC终端台次)÷新车总销量</div>
                                        <div v-if="isShowSeeDetail(score.A0304029)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_bar">
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">1</div>
                                    </div>
                                    <div class="header-content">
                                        <p>展厅成交率</p>
                                        <p>范围值：10%-15%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A1303083"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">2</div>
                                    </div>
                                    <div class="header-content">
                                        <p>展厅二次进店率</p>
                                        <p>范围值：15%-30%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A1303007"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">3</div>
                                    </div>
                                    <div class="header-content">
                                        <p>iDCC线索转化率</p>
                                        <p>范围值：2%-6%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A1303032"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">4</div>
                                    </div>
                                    <div class="header-content">
                                        <p>零售终端占比</p>
                                        <p>范围值：65%-95%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0304029"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-direction: column">
                    <div class="con_title_sub"></div>
                    <div class="con_lable">
                        <div class="con_lable_conten">
                            <p class="con_lable_title">水平事业效率</p>
                            <div class="con_lable_text">
                                水平事业主要是通过现阶段毛利最高的金融渗透率进行体现。
                            </div>
                            <div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">1</div>
                                        </div>
                                        <div style="flex: 1">金融渗透率</div>
                                        <div :class="getGradeStyle(score.A0303273)">
                                            {{ score.A0303273 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>金融按揭台次÷销量</div>
                                        <div v-if="isShowSeeDetail(score.A0303273)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_bar">
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">1</div>
                                    </div>
                                    <div class="header-content">
                                        <p>金融渗透率</p>
                                        <p>范围值：55%-80%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="A0303273"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 打印分页 -->
                <div style="page-break-after: always"></div>
                <div class="title">
                    <div>{{ dealerName }}单店诊断报告</div>
                </div>
                <div style="display: flex; flex-direction: column">
                    <div class="con_title">售后效率评估结果</div>
                    <div class="con_lable">
                        <div class="con_lable_conten" id="four">
                            <p class="con_lable_title">售后效率</p>
                            <div class="con_lable_text">
                                售后效率是指销售店售后部门的客户维系水平，也是售后部门获取利润以及价值增值的能力，是售后部门发展的重要基石。
                            </div>
                            <div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">1</div>
                                        </div>
                                        <div style="flex: 1">客户维系率</div>
                                        <div :class="getGradeStyle(score.B0303024)">
                                            {{ score.B0303024 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>连续两年都来店客户数÷N-2年来店客户数</div>
                                        <div v-if="isShowSeeDetail(score.B0303024)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">2</div>
                                        </div>
                                        <div style="flex: 1">AB类客户占比</div>
                                        <div :class="getGradeStyle(score.B0303051)">
                                            {{ score.B0303051 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>(A类客户数+B类客户数)÷有效保有客户数</div>
                                        <div v-if="isShowSeeDetail(score.B0303051)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_la_tent">
                                    <div class="con_la_con">
                                        <div>
                                            <div class="con_lable_fo">3</div>
                                        </div>
                                        <div style="flex: 1">单车售后业务产值</div>
                                        <div :class="getGradeStyle(score.B0105114)">
                                            {{ score.B0105114 }}分
                                        </div>
                                    </div>
                                    <div class="col_la_info">
                                        <div>售后综合收入÷售后来厂台次</div>
                                        <div v-if="isShowSeeDetail(score.B0105114)" class="see-detail"
                                             @click="seeDetail">查看详情>>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_bar">
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">1</div>
                                    </div>
                                    <div class="header-content">
                                        <p>客户维系率</p>
                                        <p>范围值：45%-65%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="B0303024"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">2</div>
                                    </div>
                                    <div class="header-content">
                                        <p>AB类客户占比</p>
                                        <p>范围值：45%-60%</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="B0303051"></div>
                            </div>
                            <div class="bar_sty">
                                <div class="header">
                                    <div>
                                        <div class="table-num">3</div>
                                    </div>
                                    <div class="header-content">
                                        <p>单车售后业务产值</p>
                                        <p>范围值：1000-1300</p>
                                    </div>
                                </div>
                                <div class="bar_sty_chart" id="B0105114"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="min-height: 400px; display: flex; flex-direction: column" id="five">
                    <div class="con_title">销售店盈利情况展示</div>
                    <div class="con_lable">
                        <div class="con_bar">
                            <div class="bar_sty_full">
                                <div class="full_header">
                                    <p>范围值：2%-3%</p>
                                    <p>范围值：3.5%-8.0%</p>
                                    <p>范围值：60%-100%</p>
                                    <p>范围值：0%-3%</p>
                                    <p>范围值：5%-15%</p>
                                </div>
                                <div class="bar_sty_chart_full" id="xsdyl-chart1"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 打印分页 -->
                <div style="page-break-after: always"></div>
                <div style="margin:0" class="title">
                    <div>{{ dealerName }}单店诊断报告</div>
                </div>
                <div style="display: flex; flex-direction: column;margin-top: 0" id="six">
                    <div style="  height: 20px;" class="con_title">
                        说明：差异列中红点为本店值小于全国标杆值的指标数据
                    </div>
                    <div class="con_lable">
                        <div class="con_bar">
                            <div class="bar_sty_full">
                                <table class="summary-table" border="1" style="width: 100%">
                                    <tr>
                                        <th>科目</th>
                                        <th>分类</th>
                                        <th>指标名称</th>
                                        <th>全国标杆值</th>
                                        <th>区域标杆值</th>
                                        <th>本店值</th>
                                        <th>差异</th>
                                    </tr>
                                    <tr>
                                        <td rowspan="11">整体业务概览</td>
                                        <td rowspan="4">整体业务盈利</td>
                                        <td>总毛利（万元）</td>
                                        <td>
                                            {{
                                                Number(
                                                    (tableData.C0407001.CountryBG / 10000).toFixed(2),
                                                ).toLocaleString()
                                            }}
                                        </td>           <td>
                                            {{
                                                Number(
                                                    (tableData.C0407001.AreaBG / 10000).toFixed(2),
                                                ).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                Number(
                                                    (tableData.C0407001.NumValue / 10000).toFixed(2),
                                                ).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0407001')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>总毛利率</td>
                                        <td>
                                            {{ (tableData.C0407004.CountryBG * 100).toFixed(1) }}%
                                        </td>      <td>
                                            {{ (tableData.C0407004.AreaBG * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            {{ (tableData.C0407004.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0407004')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>税前利润（万元）</td>
                                        <td>
                                            {{
                                                Number((tableData.C0408004.CountryBG / 10000).toFixed(2)).toLocaleString()
                                            }}
                                        </td>     <td>
                                            {{
                                                Number((tableData.C0408004.AreaBG / 10000).toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                Number((tableData.C0408004.NumValue / 10000).toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0408004')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>税前利润率</td>
                                        <td>
                                            {{ (tableData.C0408021.CountryBG * 100).toFixed(1) }}%
                                        </td>      <td>
                                            {{ (tableData.C0408021.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.C0408021.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0408021')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="3">费用情况</td>
                                        <td>总费用（万元）</td>
                                        <td>
                                            {{
                                                Number((tableData.C0206001.CountryBG / 10000).toFixed(2)).toLocaleString()
                                            }}
                                        </td>    <td>
                                            {{
                                                Number((tableData.C0206001.AreaBG / 10000).toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number((tableData.C0206001.NumValue / 10000).toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0206001')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>费用率</td>
                                        <td>
                                            {{ (tableData.C0207022.CountryBG * 100).toFixed(1) }}%
                                        </td>       <td>
                                            {{ (tableData.C0207022.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.C0207022.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0207022')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>人力费用率</td>
                                        <td>
                                            {{ (tableData.C0207028.CountryBG * 100).toFixed(1) }}%
                                        </td>      <td>
                                            {{ (tableData.C0207028.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.C0207028.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0207028')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4">单车费用支出</td>
                                        <td>平均单车总费用（元）</td>
                                        <td>
                                            {{
                                                Number(tableData.C0207009.CountryBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>             <td>
                                            {{
                                                Number(tableData.C0207009.AreaBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.C0207009.NumValue.toFixed(0)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('C0207009')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>平均单车广宣费用（元）</td>
                                        <td>
                                            {{
                                                Number(tableData.A0205034.CountryBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>
    <td>
                                            {{
                                                Number(tableData.A0205034.AreaBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0205034.NumValue.toFixed(0)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0205034')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>平均单车财务费用（元）</td>
                                        <td>
                                            {{
                                                Number(tableData.A0205035.CountryBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>        <td>
                                            {{
                                                Number(tableData.A0205035.AreaBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0205035.NumValue.toFixed(0)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0205035')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>平均单车销售顾问工资（元）</td>
                                        <td>
                                            {{
                                                Number(tableData.A0204032.CountryBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>               <td>
                                            {{
                                                Number(tableData.A0204032.AreaBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0204032.NumValue.toFixed(0)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0204032')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="28">销售业务</td>
                                        <td rowspan="4">新车销量</td>
                                        <td>终端</td>
                                        <td>
                                            {{ tableData.A0302001.CountryBG.toLocaleString() }}
                                        </td>           <td>
                                            {{ tableData.A0302001.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A0302001.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0302001')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>终端达成率</td>
                                        <td>
                                            {{ Number(tableData.A0403002.CountryBG * 100).toFixed(1) }}%
                                        </td>
          <td>
                                            {{ Number(tableData.A0403002.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ Number(tableData.A0403002.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0403002')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>提车</td>
                                        <td>
                                            {{
                                                Number(tableData.A1402155.CountryBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>     <td>
                                            {{
                                                Number(tableData.A1402155.AreaBG.toFixed(0)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A1402155.NumValue.toFixed(0)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1402155')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>提车达成率</td>
                                        <td>
                                            {{ Number(tableData.A0403001.CountryBG * 100).toFixed(1) }}%
                                        </td>        <td>
                                            {{ Number(tableData.A0403001.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ Number(tableData.A0403001.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0403001')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="5">销售业务盈利</td>
                                        <td>销售业务综合毛利率</td>
                                        <td>
                                            {{ (tableData.A0107002.CountryBG * 100).toFixed(1) }}%
                                        </td>        <td>
                                            {{ (tableData.A0107002.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0107002.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0107002')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>平均单车综合毛利（元）</td>
                                        <td>
                                            {{ (tableData.A0107004.CountryBG).toFixed(2).toLocaleString() }}
                                        </td>              <td>
                                            {{ (tableData.A0107004.AreaBG).toFixed(2).toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ (tableData.A0107004.NumValue).toFixed(2).toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0107004')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车裸车毛利（一级）</td>
                                        <td>
                                            {{
                                                Number(tableData.A0106026.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>       <td>
                                            {{
                                                Number(tableData.A0106026.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0106026.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0106026')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车销售返利（二级）</td>
                                        <td>
                                            {{
                                                Number(tableData.A0105071.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>       <td>
                                            {{
                                                Number(tableData.A0105071.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0105071.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0105071')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车水平事业毛利（三级）</td>
                                        <td>
                                            {{
                                                Number(tableData.A0105072.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>    <td>
                                            {{
                                                Number(tableData.A0105072.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0105072.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0105072')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">通道占比</td>
                                        <td>展厅销量占比</td>
                                        <td>
                                            {{ (tableData.A0304024.CountryBG * 100).toFixed(1) }}%
                                        </td>  <td>
                                            {{ (tableData.A0304024.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0304024.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0304024')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>IDCC销量占比</td>
                                        <td>
                                            {{ (tableData.A0304025.CountryBG * 100).toFixed(1) }}%
                                        </td>
       <td>
                                            {{ (tableData.A0304025.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0304025.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0304025')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="5">展厅效率</td>
                                        <td>展厅首次客流</td>
                                        <td>
                                            {{ tableData.A1302012.CountryBG.toLocaleString() }}
                                        </td>    <td>
                                            {{ tableData.A1302012.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A1302012.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1302012')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>展厅首次客流成交率</td>
                                        <td>
                                            {{ (tableData.A1303081.CountryBG * 100).toFixed(1) }}%
                                        </td>
     <td>
                                            {{ (tableData.A1303081.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303081.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303081')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>展厅再次客流</td>
                                        <td>
                                            {{ tableData.A1302013.CountryBG.toLocaleString() }}
                                        </td>  <td>
                                            {{ tableData.A1302013.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A1302013.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1302013')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>展厅再次客流成交率</td>
                                        <td>
                                            {{ (tableData.A1303082.CountryBG * 100).toFixed(1) }}%
                                        </td>     <td>
                                            {{ (tableData.A1303082.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303082.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303082')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>展厅二次进店率</td>
                                        <td>
                                            {{ (tableData.A1303007.CountryBG * 100).toFixed(1) }}%
                                        </td>    <td>
                                            {{ (tableData.A1303007.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303007.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303007')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="6">IDCC效率</td>
                                        <td>线索总数</td>
                                        <td>
                                            {{ tableData.A1302115.CountryBG.toLocaleString() }}
                                        </td>  <td>
                                            {{ tableData.A1302115.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A1302115.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1302115')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>有效线索数</td>
                                        <td>
                                            {{ tableData.A1302019.CountryBG.toLocaleString() }}
                                        </td>    <td>
                                            {{ tableData.A1302019.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A1302019.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1302019')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>线索有效率</td>
                                        <td>
                                            {{ (tableData.A1303036.CountryBG * 100).toFixed(1) }}%
                                        </td>      <td>
                                            {{ (tableData.A1303036.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303036.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303036')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>邀约到店率</td>
                                        <td>
                                            {{ (tableData.A1303024.CountryBG * 100).toFixed(1) }}%
                                        </td>  <td>
                                            {{ (tableData.A1303024.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303024.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303024')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>邀约到店成交率</td>
                                        <td>
                                            {{ (tableData.A1303028.CountryBG * 100).toFixed(1) }}%
                                        </td><td>
                                            {{ (tableData.A1303028.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303028.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303028')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>线索转化率</td>
                                        <td>
                                            {{ (tableData.A1303032.CountryBG * 100).toFixed(1) }}%
                                        </td><td>
                                            {{ (tableData.A1303032.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A1303032.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303032')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1">试乘试驾</td>
                                        <td>试乘试驾率</td>
                                        <td>
                                            {{ tableData.A1303001.CountryBG.toLocaleString() }}
                                        </td><td>
                                            {{ tableData.A1303001.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A1303001.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A1303001')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="5">新车库存管理</td>
                                        <td>在店库存</td>
                                        <td>
                                            {{ tableData.A0402006.CountryBG.toLocaleString() }}
                                        </td>    <td>
                                            {{ tableData.A0402006.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A0402006.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0402006')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>新车库存周转率(年化)</td>
                                        <td>
                                            {{ tableData.A0404002.CountryBG.toLocaleString() }}
                                        </td> <td>
                                            {{ tableData.A0404002.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.A0404002.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0404002')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>现金车占比</td>
                                        <td>
                                            {{ (tableData.A0404181.CountryBG * 100).toFixed(1) }}%
                                        </td>  <td>
                                            {{ (tableData.A0404181.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0404181.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0404181')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>库销比</td>
                                        <td>
                                            {{ (tableData.A0404045.CountryBG * 100).toFixed(1) }}%
                                        </td>
<td>
                                            {{ (tableData.A0404045.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0404045.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0404045')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>长库龄占比</td>
                                        <td>
                                            {{ (tableData.A0404111.CountryBG * 100).toFixed(1) }}%
                                        </td>     <td>
                                            {{ (tableData.A0404111.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0404111.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0404111')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="8">售后业务</td>
                                        <td rowspan="2">售后来厂</td>
                                        <td>售后来厂台次</td>
                                        <td>
                                            {{ tableData.B0302001.CountryBG.toLocaleString() }}
                                        </td><td>
                                            {{ tableData.B0302001.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.B0302001.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0302001')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>事故车台次</td>
                                        <td>
                                            {{ tableData.B0302121.CountryBG.toLocaleString() }}
                                        </td><td>
                                            {{ tableData.B0302121.AreaBG.toLocaleString() }}
                                        </td>

                                        <td>
                                            {{ tableData.B0302121.NumValue.toLocaleString() }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0302121')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4">售后业务盈利</td>
                                        <td>单车售后维修产值</td>
                                        <td>
                                            {{
                                                Number(tableData.B0104226.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td><td>
                                            {{
                                                Number(tableData.B0104226.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.B0104226.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0104226')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>售后维修业务毛利率</td>
                                        <td>
                                            {{ (tableData.B0105181.CountryBG * 100).toFixed(1) }}%
                                        </td> <td>
                                            {{ (tableData.B0105181.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.B0105181.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0105181')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车零部件毛利</td>
                                        <td>
                                            {{
                                                Number(tableData.B0105132.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td><td>
                                            {{
                                                Number(tableData.B0105132.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.B0105132.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0105132')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车工时毛利</td>
                                        <td>
                                            {{
                                                Number(tableData.B0105161.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td><td>
                                            {{
                                                Number(tableData.B0105161.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.B0105161.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0105161')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">客户维系</td>
                                        <td>客户维系率</td>
                                        <td>
                                            {{
                                                Number(
                                                    (tableData.B0303024.CountryBG * 100).toFixed(1),
                                                ).toLocaleString()
                                            }}%
                                        </td> <td>
                                            {{
                                                Number(
                                                    (tableData.B0303024.AreaBG * 100).toFixed(1),
                                                ).toLocaleString()
                                            }}%
                                        </td>

                                        <td>
                                            {{
                                                Number(
                                                    (tableData.B0303024.NumValue * 100).toFixed(1),
                                                ).toLocaleString()
                                            }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0303024')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>客户流失率</td>
                                        <td>
                                            {{ (tableData.B0304014.CountryBG * 100).toFixed(1) }}%
                                        </td> <td>
                                            {{ (tableData.B0304014.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.B0304014.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('B0304014')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="10">水平事业</td>
                                        <td rowspan="6">水平事业盈利</td>
                                        <td>销售水平事业毛利率</td>
                                        <td>
                                            {{ (tableData.A0105023.CountryBG * 100).toFixed(1) }}%
                                        </td>
<td>
                                            {{ (tableData.A0105023.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0105023.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0105023')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车用品加装金额</td>
                                        <td>
                                            {{
                                                Number(tableData.A0103068.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td><td>
                                            {{
                                                Number(tableData.A0103068.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0103068.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0103068')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车用品毛利</td>
                                        <td>
                                            {{
                                                Number(tableData.A0104115.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td><td>
                                            {{
                                                Number(tableData.A0104115.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0104115.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0104115')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车新车保险毛利</td>
                                        <td>
                                            {{
                                                Number(tableData.A0104116.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td> <td>
                                            {{
                                                Number(tableData.A0104116.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0104116.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0104116')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车金融按揭毛利</td>
                                        <td>
                                            {{
                                                Number(tableData.A0104117.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td><td>
                                            {{
                                                Number(tableData.A0104117.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0104117.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0104117')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>单车二手车毛利</td>
                                        <td>
                                            {{
                                                Number(tableData.A0104119.CountryBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td> <td>
                                            {{
                                                Number(tableData.A0104119.AreaBG.toFixed(2)).toLocaleString()
                                            }}
                                        </td>

                                        <td>
                                            {{
                                                Number(tableData.A0104119.NumValue.toFixed(2)).toLocaleString()
                                            }}
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0104119')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4">水平事业渗透</td>
                                        <td>新车用品渗透率（展厅+IDCC）</td>
                                        <td>
                                            {{ (tableData.A0303205.CountryBG * 100).toFixed(1) }}%
                                        </td>
<td>
                                            {{ (tableData.A0303205.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0303205.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0303205')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>新车保险渗透率（展厅+IDCC）</td>
                                        <td>
                                            {{ (tableData.A0303206.CountryBG * 100).toFixed(1) }}%
                                        </td>
<td>
                                            {{ (tableData.A0303206.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0303206.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0303206')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>金融按揭渗透率（展厅+IDCC）</td>
                                        <td>
                                            {{ (tableData.A0303273.CountryBG * 100).toFixed(1) }}%
                                        </td>    <td>
                                            {{ (tableData.A0303273.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0303273.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0303273')"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>二手车置换率（展厅+IDCC）</td>
                                        <td>
                                            {{ (tableData.A0303275.CountryBG * 100).toFixed(1) }}%
                                        </td>
   <td>
                                            {{ (tableData.A0303275.AreaBG * 100).toFixed(1) }}%
                                        </td>

                                        <td>
                                            {{ (tableData.A0303275.NumValue * 100).toFixed(1) }}%
                                        </td>
                                        <td>
                                            <div :class="getIsPoint('A0303275')"></div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 打印分页 -->
                <div style="page-break-after: always"></div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            rankTable: {
                "A1402155": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "A0302001": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "A0107004": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "B0302001": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "B0106027": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "B0104226": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "A0107014": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "B0306002": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "B0107002": {AreaRank: 0, CountryRank: 0, NumValue: 0},
                "C0208001": {AreaRank: 0, CountryRank: 0, NumValue: 0}
            },

            dialogVisible: false,
            isClose: true,
            isCloseA: true,
            month: null,
            group: [],
            partName: "",
            dealerInfo: {},
            SalesName: [],
            dealerList: [],
            dealerCode: "",
            dealerName: "",
            thisCompany: [],
            gradeChartData: [],
            YewuSale: {
                A0304001: 0,
                B0302001: 0,
                A0104090: 0,
                B0104202: 0,
                C0406004: 0,
                A0105034: 0,
                B0104311: 0,
                C0406012: 0,
                A0106024: 0,
                B0105113: 0,
                C0407001: 0,
                A0205001: 0,
                B0205011: 0,
                C0206001: 0,
                A0107013: 0,
                B0106038: 0,
                C0408004: 0,
                C0208001: 0,
                A0107004: 0,
                C0207031: 0,
                C0207021: 0,
            },

            //销售店综合信息
            //效率评价
            efficiencyRemark: 0,
            // 终端KPI值
            ZdKpiValue: [],
            // KPI标杆值
            kpiBg: [],
            diagnoseBGBackData: null,
            score: {
                // 盈利能力
                // 三大指标得分
                //销售业务综合毛利率 A0107002
                A0107002: 0,
                //售后业务综合毛利率 B0106026
                B0106026: 0,
                //费用率 C0207022
                C0207022: 0,

                //单台毛利
                //平均单车销售返利  A0105071
                A0105071: 0,
                //平均单车销售水平事业毛利 A0105072
                A0105072: 0,

                //销售效率
                //终端达成率 A0403002
                A0403002: 0,
                //大车销量占比 A0304151
                A0304151: 0,
                //库销比 A0404045
                A0404045: 0,
                //长库龄新车库存占比 A0404111
                A0404111: 0,
                //销售顾问人均销量(零售) A0304141
                A0304141: 0,
                //二手车置换率（展厅+IDCC） A0303275
                A0303275: 0,
                //老客户转介绍率, A0304030
                A0304030: 0,

                //通道效率
                //展厅成交率 A1303083
                A1303083: 0,
                //展厅二次进店率  A1303007
                A1303007: 0,
                //iDCC线索转化率 A1303032
                A1303032: 0,
                //零售终端占比 A0304029
                A0304029: 0,

                //水平事业效率
                //金融渗透率
                A0303273: 0,

                //售后效率
                // 客户维系率
                B0303024: 0,
                // AB类客户占比
                B0303051: 0,
                // 单车售后业务产值
                B0105114: 0,
            },

            //大表格数据
            tableData: {
                // 总毛利  	C0407001
                C0407001: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 总毛利率	C0407004
                C0407004: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 税前利润	C0408004
                C0408004: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 税前利润率	C0408021
                C0408021: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 总费用	C0206001
                C0206001: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 费用率	C0207022
                C0207022: {
                    NumValue: 0,
                    CountryBG: 0,

                    AreaBG:0,
                },
                // 人力费用率	C0207028
                C0207028: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,

                },
                // 平均单车总费用	C0207009
                C0207009: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 平均单车广宣费用	A0205034
                A0205034: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 平均单车财务费用	A0205035
                A0205035: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 平均单车销售顾问工资	A0204032
                A0204032: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 终端	A0302001
                A0302001: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 终端达成率	A0403002
                A0403002: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 提车	A1402155
                A1402155: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 提车达成率	A0403001
                A0403001: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 销售业务综合毛利率	A0107002
                A0107002: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 平均单车综合毛利	A0107004
                A0107004: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车裸车毛利（一级）	A0106026
                A0106026: {
                    NumValue: 0,
                    AreaBG:0,
                    CountryBG: 0,
                },
                // 单车销售返利（二级）	A0105071
                A0105071: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车水平事业毛利（三级）	A0105072
                A0105072: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 展厅销量占比	A0304024
                A0304024: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // IDCC销量占比	A0304025
                A0304025: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 展厅首次客流	A1302012
                A1302012: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 展厅首次客流成交率	A1303081
                A1303081: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 展厅再次客流	A1302013
                A1302013: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 展厅再次客流成交率	A1303082
                A1303082: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 展厅二次进店率	A1303007
                A1303007: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 线索总数	A1302115
                A1302115: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 有效线索数	A1302019
                A1302019: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 线索有效率	A1303036
                A1303036: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 邀约到店率	A1303024
                A1303024: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 邀约到店成交率	A1303028
                A1303028: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 线索转化率	A1303032
                A1303032: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 试乘试驾率 A1303001
                A1303001: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 在店库存	A0402006
                A0402006: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 新车库存周转率(年化)	A0404002
                A0404002: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 现金车占比	A0404181
                A0404181: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 库销比	A0404045
                A0404045: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 长库龄占比	A0404111
                A0404111: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 售后来厂台次	B0302001
                B0302001: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 事故车台次	B0302121
                B0302121: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车售后维修产值	B0104226
                B0104226: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 售后维修业务毛利率	B0105181
                B0105181: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车零部件毛利	B0105132
                B0105132: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车工时毛利	B0105161
                B0105161: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 客户维系率	B0303024
                B0303024: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 客户流失率	B0304014
                B0304014: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 销售水平事业毛利率	A0105023
                A0105023: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车用品加装金额	A0103068
                A0103068: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车用品毛利	A0104115
                A0104115: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车新车保险毛利	A0104116
                A0104116: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车金融按揭毛利	A0104117
                A0104117: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 单车置换毛利	A0104119
                A0104119: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 新车用品渗透率（展厅+IDCC）	A0303205
                A0303205: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 新车保险渗透率（展厅+IDCC）	A0303206
                A0303206: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 金融按揭渗透率（展厅+IDCC）	A0303273
                A0303273: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
                // 二手车置换率（展厅+IDCC）	A0303275
                A0303275: {
                    NumValue: 0,
                    CountryBG: 0,
                    AreaBG:0,
                },
            },

            //echarts配置参数
            //柱状图
            barWidth: 20,
            colorList: ["#5B9BD5", "#92D050","#DC143C"],
        };
    },
    methods: {
        gotoImprovePlan() {
            this.$router.push('/improvePlan/createImprovePlan')
        },
        //获取大区列表(该方法只在created里面调用)
        async getGroup() {
            let self = this;
            let res = await self.$Http.GetPartList({});
            self.group = self._.pluck(res.Data, "PartName");
            self.group = self._.uniq(self.group);
            // 选择用户所在的小组
            self.thisCompany = (await self.$Http.GetThisCompany()).Data;
            if (self.thisCompany.length > 0) {
                self.chooseGroup(self.thisCompany[0].PartName, false);
            }
        },
        // 获取区域各店得分
        // async getPointsRank () {
        //     let self = this;
        //     let reportdate = self.FormatDate(self.month);
        //     let res = await self.$Http.GetDiagnoseScore({
        //         ReportDate: reportdate,
        //         DealerCode: self.dealerCode,
        //     });
        //     self.SalesName = res.Data;
        //     // self.gradeChartData = [];
        //     // self.SalesName.forEach((ele) => {
        //     //   self.gradeChartData.push([ele.SaleNum, ele.Points, ele.DealerName]);
        //     // });
        //     //获取效率评价
        //     let efficInfoList = self._.where(self.SalesName, {
        //         DealerCode: self.dealerCode,
        //     });
        //     if (efficInfoList.length > 0) {
        //         self.efficiencyRemark = efficInfoList[0].Points;
        //     } else {
        //         self.efficiencyRemark = -1;
        //     }
        // },
        // 选择月份
        async chooseMonth() {
            if (this.month != null) {
                //刷新数据
                await this.getDealerInfo();
                await this.GetZdKpiValue();
                await this.getGetKpiBG();
                this.getTableData();
                // this.getPointsRank();
            }
        },
        //选择小组(参数isUser标记是否是用户选择的，还是系统自动选择用户所在小组的)
        chooseGroup(selectPart, isUser) {
            this.partName = selectPart;
            //获取小组经销商
            this.getDealerList(isUser);
        },
        //获取排行表格
        async getRankTable() {
            let codeList = this._.keys(this.rankTable);
            let res = await this.$Http.RankByCodes({
                //用underscore检索object拥有的所有可枚举属性的名称
                ListKpiCode: codeList,
                YearMonth: this.month,
                DealerCode: this.dealerCode
            });
            if (res.Success) {
                if (res.Data.length > 0) {
                    //处理数据
                    codeList.forEach((item) => {
                        //获取数据
                        let areaRank = this._.findWhere(res.Data, {KpiCode: item}).AreaRank;
                        let countryRank = this._.findWhere(res.Data, {KpiCode: item}).CountryRank;
                        let numvalue = this._.findWhere(res.Data, {KpiCode: item}).NumValue;
                        this.rankTable[item] = {AreaRank: areaRank, CountryRank: countryRank, NumValue: numvalue};
                    })
                }
            } else {
                this.$message.error(res.Message);
            }
        },
        //选择经销商
        chooseDealer(selectCode) {
            this.dealerCode = selectCode;
            this.dealerName = this._.where(this.dealerList, {
                DealerCode: selectCode,
            })[0].DealerName;
            //选择经销商之后重新渲染数据
            //获取经销商信息
            this.getDealerInfo();
            this.GetZdKpiValue();
            this.getGetKpiBG();
            this.getTableData();
            // this.getPointsRank();
        },
        //获取销售店基本信息
        async getDealerInfo() {
            let self = this;
            let res = await self.$Http.GetDealerInfo({
                DealerCode: self.dealerCode,
            });
            if (res.Data.length > 0) {
                self.dealerInfo = res.Data[0];
                //格式化日期
                let tmpDate = new Date(self.dealerInfo.OpenTime);
                self.dealerInfo.OpenTime = self.FormatDate(tmpDate);
            }
        },

        //获取经销商列表
        async getDealerList(isUser) {
            let self = this;
            let res = await self.$Http.GetPartList({
                PartName: self.partName,
            });
            self.dealerList = res.Data;
            //不是用户选择的，自动取用户自己的大区
            if (!isUser) {
                self.dealerCode = self.thisCompany[0].CompanyCode;
            }
            //是用户手动选择的，默认取第一个大区
            else {
                if (self.dealerList.length > 0) {
                    self.dealerCode = self.dealerList[0].DealerCode;
                } else {
                    self.dealerCode = "";
                }
            }
            self.chooseDealer(self.dealerCode);
        },

        //获取终端KPI值,获取带颜色分数
        async GetZdKpiValue() {
            let self = this;
            let res = await self.$Http.GetDiagnoseData({
                DealerCode: self.dealerCode,
                ReportDate: self.FormatDate(self.month),
                GroupId: self.groupId,
            });
            self.ZdKpiValue = res.Data;
            self.ZdKpiValue.forEach((element) => {
                if (element.Points == null) {
                    element.Points = 0;
                }
            });
            self.score.A0107002 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0107002"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0107002"})[0].Points
                    : 0;
            self.score.B0106026 =
                self._.where(self.ZdKpiValue, {KpiCode: "B0106026"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "B0106026"})[0].Points
                    : 0;
            self.score.C0207022 =
                self._.where(self.ZdKpiValue, {KpiCode: "C0207022"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "C0207022"})[0].Points
                    : 0;
            self.score.A0105071 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0105071"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0105071"})[0].Points
                    : 0;
            self.score.A0105072 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0105072"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0105072"})[0].Points
                    : 0;
            self.score.A0403002 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0403002"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0403002"})[0].Points
                    : 0;
            self.score.A0304151 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0304151"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0304151"})[0].Points
                    : 0;
            self.score.A0404045 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0404045"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0404045"})[0].Points
                    : 0;
            self.score.A0404111 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0404111"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0404111"})[0].Points
                    : 0;
            self.score.A0304141 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0304141"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0304141"})[0].Points
                    : 0;
            self.score.A0303275 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0303275"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0303275"})[0].Points
                    : 0;
            self.score.A0304030 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0304030"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0304030"})[0].Points
                    : 0;
            self.score.A1303083 =
                self._.where(self.ZdKpiValue, {KpiCode: "A1303083"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A1303083"})[0].Points
                    : 0;
            self.score.A1303007 =
                self._.where(self.ZdKpiValue, {KpiCode: "A1303007"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A1303007"})[0].Points
                    : 0;
            self.score.A1303032 =
                self._.where(self.ZdKpiValue, {KpiCode: "A1303032"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A1303032"})[0].Points
                    : 0;
            self.score.A0304029 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0304029"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0304029"})[0].Points
                    : 0;
            self.score.A0303273 =
                self._.where(self.ZdKpiValue, {KpiCode: "A0303273"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "A0303273"})[0].Points
                    : 0;
            self.score.B0303024 =
                self._.where(self.ZdKpiValue, {KpiCode: "B0303024"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "B0303024"})[0].Points
                    : 0;
            self.score.B0303051 =
                self._.where(self.ZdKpiValue, {KpiCode: "B0303051"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "B0303051"})[0].Points
                    : 0;
            self.score.B0105114 =
                self._.where(self.ZdKpiValue, {KpiCode: "B0105114"}).length > 0
                    ? self._.where(self.ZdKpiValue, {KpiCode: "B0105114"})[0].Points
                    : 0;
        },
        // 获取得分样式
        getGradeStyle(grade) {
            if (grade <= 2) {
                //红色
                return "con_la_grade_red";
            } else if (grade === 3) {
                //黄色
                return "con_la_grade_orange";
            } else if (grade === 4) {
                //蓝色
                return "con_la_grade_blue";
            } else {
                //绿色
                return "con_la_grade_green";
            }
        },

        //查看详情
        seeDetail() {
            this.dialogVisible = true;
        },
        //获取kpi标杆值
        async getGetKpiBG() {
            let self = this;
            let res = await self.$Http.GetDiagnoseBG({
                DealerCode: self.dealerCode,
                ReportDate: self.FormatDate(self.month),
                GroupId: self.groupId,
            });
            self.kpiBg = res.Data;
            //这个对象给下面的大表格用
            self.diagnoseBGBackData = res.Data;
            self.kpiBg.forEach((element) => {
                if (element.NumValue == null) {
                    element.NumValue = 0;
                }
            });

            //销售业务综合毛利率
            let A0107002Obj = self._.where(self.kpiBg, {KpiCode: "A0107002"});
            if (A0107002Obj.length > 0) {
                A0107002Obj = A0107002Obj[0];
            } else {
                A0107002Obj.CountryBG = 0;
                A0107002Obj.AreaBG = 0;
                A0107002Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0107002",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0107002Obj.CountryBG * 100).toFixed(1),
                    (A0107002Obj.AreaBG * 100).toFixed(1),
                    (A0107002Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //售后业务综合毛利率
            let B0106026Obj = self._.where(self.kpiBg, {KpiCode: "B0106026"});
            if (B0106026Obj.length > 0) {
                B0106026Obj = B0106026Obj[0];
            } else {
                B0106026Obj.CountryBG = 0;
                B0106026Obj.AreaBG = 0;
                B0106026Obj.NumValue = 0;
            }
            self.initBarChart(
                "B0106026",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (B0106026Obj.CountryBG * 100).toFixed(1),
                    (B0106026Obj.AreaBG * 100).toFixed(1),
                    (B0106026Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //费用率
            let C0207022Obj = self._.where(self.kpiBg, {KpiCode: "C0207022"});
            if (C0207022Obj.length > 0) {
                C0207022Obj = C0207022Obj[0];
            } else {
                C0207022Obj.CountryBG = 0;
                C0207022Obj.AreaBG = 0;
                C0207022Obj.NumValue = 0;
            }
            self.initBarChart(
                "C0207022",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (C0207022Obj.CountryBG * 100).toFixed(1),
                    (C0207022Obj.AreaBG * 100).toFixed(1),
                    (C0207022Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //平均单车销售返利
            let A0105071Obj = self._.where(self.kpiBg, {KpiCode: "A0105071"});
            if (A0105071Obj.length > 0) {
                A0105071Obj = A0105071Obj[0];
            } else {
                A0105071Obj.CountryBG = 0;
                A0105071Obj.AreaBG = 0;
                A0105071Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0105071",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    A0105071Obj.CountryBG.toFixed(0),
                    A0105071Obj.AreaBG.toFixed(0),
                    A0105071Obj.NumValue.toFixed(0)
                ],
                (obj) => {
                    if (obj.data > 999) {
                        let parts = obj.data.toString().split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        obj.data = parts.join(".");
                    }
                    return obj.data;
                },
                "{value}",
            );

            //平均单车销售水平事业毛利
            let A0105072Obj = self._.where(self.kpiBg, {KpiCode: "A0105072"});
            if (A0105072Obj.length > 0) {
                A0105072Obj = A0105072Obj[0];
            } else {
                A0105072Obj.CountryBG = 0;
                A0105072Obj.AreaBG = 0;
                A0105072Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0105072",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    A0105072Obj.CountryBG.toFixed(0),
                    A0105072Obj.AreaBG.toFixed(0),
                    A0105072Obj.NumValue.toFixed(0)
                ],
                (obj) => {
                    if (obj.data > 999) {
                        let parts = obj.data.toString().split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        obj.data = parts.join(".");
                    }
                    return obj.data;
                },
                "{value}",
            );

            //终端达成率
            let A0403002Obj = self._.where(self.kpiBg, {KpiCode: "A0403002"});
            if (A0403002Obj.length > 0) {
                A0403002Obj = A0403002Obj[0];
            } else {
                A0403002Obj.CountryBG = 0;
                A0403002Obj.AreaBG = 0;
                A0403002Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0403002",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0403002Obj.CountryBG * 100).toFixed(1),
                    (A0403002Obj.AreaBG * 100).toFixed(1),
                    (A0403002Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //大车销量占比
            let A0304151Obj = self._.where(self.kpiBg, {KpiCode: "A0304151"});
            if (A0304151Obj.length > 0) {
                A0304151Obj = A0304151Obj[0];
            } else {
                A0304151Obj.CountryBG = 0;
                A0304151Obj.AreaBG = 0;
                A0304151Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0304151",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0304151Obj.CountryBG * 100).toFixed(1),
                    (A0304151Obj.AreaBG * 100).toFixed(1),
                    (A0304151Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //库销比
            let A0404045Obj = self._.where(self.kpiBg, {KpiCode: "A0404045"});
            if (A0404045Obj.length > 0) {
                A0404045Obj = A0404045Obj[0];
            } else {
                A0404045Obj.CountryBG = 0;
                A0404045Obj.AreaBG = 0;
                A0404045Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0404045",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    A0404045Obj.CountryBG.toFixed(1),
                    A0404045Obj.AreaBG.toFixed(1),
                    A0404045Obj.NumValue.toFixed(1)
                ],
                (obj) => {
                    return obj.data;
                },
                "{value}",
            );

            //长库龄新车库存占比
            let A0404111Obj = self._.where(self.kpiBg, {KpiCode: "A0404111"});
            if (A0404111Obj.length > 0) {
                A0404111Obj = A0404111Obj[0];
            } else {
                A0404111Obj.CountryBG = 0;
                A0404111Obj.AreaBG = 0;
                A0404111Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0404111",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0404111Obj.CountryBG * 100).toFixed(1),
                    (A0404111Obj.AreaBG * 100).toFixed(1),
                    (A0404111Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //销售顾问人均销量（零售）
            let A0304141Obj = self._.where(self.kpiBg, {KpiCode: "A0304141"});
            if (A0304141Obj.length > 0) {
                A0304141Obj = A0304141Obj[0];
            } else {
                A0304141Obj.CountryBG = 0;
                A0304141Obj.AreaBG = 0;
                A0304141Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0304141",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    A0304141Obj.CountryBG.toFixed(0),
                    A0304141Obj.AreaBG.toFixed(0),
                    A0304141Obj.NumValue.toFixed(0)],
                (obj) => {
                    return obj.data;
                },
                "{value}",
            );

            //二手车置换率
            let A0303275Obj = self._.where(self.kpiBg, {KpiCode: "A0303275"});
            if (A0303275Obj.length > 0) {
                A0303275Obj = A0303275Obj[0];
            } else {
                A0303275Obj.CountryBG = 0;
                A0303275Obj.AreaBG = 0;
                A0303275Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0303275",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0303275Obj.CountryBG * 100).toFixed(1),
                    (A0303275Obj.AreaBG * 100).toFixed(1),
                    (A0303275Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            // 老客户转介绍率
            let A0304030Obj = self._.where(self.kpiBg, {KpiCode: "A0304030"});
            if (A0304030Obj.length > 0) {
                A0304030Obj = A0304030Obj[0];
            } else {
                A0304030Obj.CountryBG = 0;
                A0304030Obj.AreaBG = 0;
                A0304030Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0304030",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0304030Obj.CountryBG * 100).toFixed(1),
                    (A0304030Obj.AreaBG * 100).toFixed(1),
                    (A0304030Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //展厅成交率
            let A1303083Obj = self._.where(self.kpiBg, {KpiCode: "A1303083"});
            if (A1303083Obj.length > 0) {
                A1303083Obj = A1303083Obj[0];
            } else {
                A1303083Obj.CountryBG = 0;
                A1303083Obj.AreaBG = 0;
                A1303083Obj.NumValue = 0;
            }
            self.initBarChart(
                "A1303083",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A1303083Obj.CountryBG * 100).toFixed(1),
                    (A1303083Obj.AreaBG * 100).toFixed(1),
                    (A1303083Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //展厅二次进店率
            let A1303007Obj = self._.where(self.kpiBg, {KpiCode: "A1303007"});
            if (A1303007Obj.length > 0) {
                A1303007Obj = A1303007Obj[0];
            } else {
                A1303007Obj.CountryBG = 0;
                A1303007Obj.AreaBG = 0;
                A1303007Obj.NumValue = 0;
            }
            self.initBarChart(
                "A1303007",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A1303007Obj.CountryBG * 100).toFixed(1),
                    (A1303007Obj.AreaBG * 100).toFixed(1),
                    (A1303007Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //iDCC线索转化率
            let A1303032Obj = self._.where(self.kpiBg, {KpiCode: "A1303032"});
            if (A1303032Obj.length > 0) {
                A1303032Obj = A1303032Obj[0];
            } else {
                A1303032Obj.CountryBG = 0;
                A1303032Obj.AreaBG = 0;
                A1303032Obj.NumValue = 0;
            }
            self.initBarChart(
                "A1303032",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A1303032Obj.CountryBG * 100).toFixed(1),
                    (A1303032Obj.AreaBG * 100).toFixed(1),
                    (A1303032Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //零售终端占比
            let A0304029Obj = self._.where(self.kpiBg, {KpiCode: "A0304029"});
            if (A0304029Obj.length > 0) {
                A0304029Obj = A0304029Obj[0];
            } else {
                A0304029Obj.CountryBG = 0;
                A0304029Obj.AreaBG = 0;
                A0304029Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0304029",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0304029Obj.CountryBG * 100).toFixed(1),
                    (A0304029Obj.AreaBG * 100).toFixed(1),
                    (A0304029Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //金融渗透率
            let A0303273Obj = self._.where(self.kpiBg, {KpiCode: "A0303273"});
            if (A0303273Obj.length > 0) {
                A0303273Obj = A0303273Obj[0];
            } else {
                A0303273Obj.CountryBG = 0;
                A0303273Obj.AreaBG = 0;
                A0303273Obj.NumValue = 0;
            }
            self.initBarChart(
                "A0303273",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (A0303273Obj.CountryBG * 100).toFixed(1),
                    (A0303273Obj.AreaBG * 100).toFixed(1),
                    (A0303273Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //客户流失率
            let B0303024Obj = self._.where(self.kpiBg, {KpiCode: "B0303024"});
            if (B0303024Obj.length > 0) {
                B0303024Obj = B0303024Obj[0];
            } else {
                B0303024Obj.CountryBG = 0;
                B0303024Obj.AreaBG = 0;
                B0303024Obj.NumValue = 0;
            }
            self.initBarChart(
                "B0303024",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (B0303024Obj.CountryBG * 100).toFixed(1),
                    (B0303024Obj.AreaBG * 100).toFixed(1),
                    (B0303024Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //AB类客户占比
            let B0303051Obj = self._.where(self.kpiBg, {KpiCode: "B0303051"});
            if (B0303051Obj.length > 0) {
                B0303051Obj = B0303051Obj[0];
            } else {
                B0303051Obj.CountryBG = 0;
                B0303051Obj.AreaBG = 0;
                B0303051Obj.NumValue = 0;
            }
            self.initBarChart(
                "B0303051",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    (B0303051Obj.CountryBG * 100).toFixed(1),
                    (B0303051Obj.AreaBG * 100).toFixed(1),
                    (B0303051Obj.NumValue * 100).toFixed(1),
                ],
                (obj) => {
                    return obj.data + "%";
                },
                "{value}%",
            );

            //单车售后业务产值
            let B0105114Obj = self._.where(self.kpiBg, {KpiCode: "B0105114"});
            if (B0105114Obj.length > 0) {
                B0105114Obj = B0105114Obj[0];
            } else {
                B0105114Obj.CountryBG = 0;
                B0105114Obj.AreaBG = 0;
                B0105114Obj.NumValue = 0;
            }
            self.initBarChart(
                "B0105114",
                ["全国标杆值", "区域标杆值", "单店值"],
                [
                    B0105114Obj.CountryBG.toFixed(0),
                    B0105114Obj.AreaBG.toFixed(0),
                    B0105114Obj.NumValue.toFixed(0)
                ],
                (obj) => {
                    if (obj.data > 999) {
                        let parts = obj.data.toString().split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        obj.data = parts.join(".");
                    }
                    return obj.data;
                },
                "{value}",
            );
            //销售店盈利情况展示图表
            self.initxsdylchart1();
        },

        //通用初始化柱状图，参数：（元素ID，X轴数据，图表数据，label格式化方法,Y轴格式化类型）
        initBarChart(eleID, xAxisData, seriesData, labelFormatter, yAxisFormatter) {
            let self = this;
            let myChart = self.$echarts.init(document.getElementById(eleID));
            let option = {
                xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: {
                    show: false,
                    type: "value",
                    axisLabel: {
                        formatter: yAxisFormatter,
                    },

                    splitLine: {
                        show: false,
                    },
                },
                grid: {
                    top: "25%",
                    bottom: "20%",
                },
                series: [
                    {
                        data: seriesData,
                        type: "bar",
                        barWidth: self.barWidth, //柱图宽度
                        itemStyle: {
                            normal: {
                                // 自定义颜色
                                color: function (params) {
                                    return self.colorList[params.dataIndex];
                                },
                            },
                        },
                        label: {
                            show: true,
                            position: "top",
                            formatter: labelFormatter,
                            color: "black",
                        },
                    },
                ],
            };
            myChart.setOption(option);
        },

        initxsdylchart1() {
            let self = this;
            //全国标杆
            let xzbg = [];
            //区域标杆
            let qybg = [];
            //单店值
            let ddz = [];
            //人力费用率
            let C0207028Obj =
                this._.where(self.kpiBg, {KpiCode: "C0207028"}).length > 0
                    ? this._.where(self.kpiBg, {KpiCode: "C0207028"})[0]
                    : {};
            //总毛利率
            let C0407004Obj =
                this._.where(self.kpiBg, {KpiCode: "C0407004"}).length > 0
                    ? this._.where(self.kpiBg, {KpiCode: "C0407004"})[0]
                    : {};
            //总费用占总毛利比
            let C0408001Obj =
                this._.where(self.kpiBg, {KpiCode: "C0408001"}).length > 0
                    ? this._.where(self.kpiBg, {KpiCode: "C0408001"})[0]
                    : {};
            //税前利润率
            let C0408021Obj =
                this._.where(self.kpiBg, {KpiCode: "C0408021"}).length > 0
                    ? this._.where(self.kpiBg, {KpiCode: "C0408021"})[0]
                    : {};
            //资产回报率
            let C0408020Obj =
                this._.where(self.kpiBg, {KpiCode: "C0408020"}).length > 0
                    ? this._.where(self.kpiBg, {KpiCode: "C0408020"})[0]
                    : {};
            if (C0207028Obj != {}) {
                xzbg.push((C0207028Obj.CountryBG * 100).toFixed(1));
                qybg.push((C0207028Obj.CountryBG * 100).toFixed(1));
                ddz.push((C0207028Obj.NumValue * 100).toFixed(1));
            }

            if (C0407004Obj != {}) {
                xzbg.push((C0407004Obj.CountryBG * 100).toFixed(1));
                qybg.push((C0407004Obj.CountryBG * 100).toFixed(1));
                ddz.push((C0407004Obj.NumValue * 100).toFixed(1));
            }
            if (C0408001Obj != {}) {
                xzbg.push((C0408001Obj.CountryBG * 100).toFixed(1));
                qybg.push((C0408001Obj.CountryBG * 100).toFixed(1));
                ddz.push((C0408001Obj.NumValue * 100).toFixed(1));
            }
            if (C0408021Obj != {}) {
                xzbg.push((C0408021Obj.CountryBG * 100).toFixed(1));
                qybg.push((C0408021Obj.CountryBG * 100).toFixed(1));
                ddz.push((C0408021Obj.NumValue * 100).toFixed(1));
            }
            if (C0408020Obj != {}) {
                xzbg.push((C0408020Obj.CountryBG * 100).toFixed(1));
                qybg.push((C0408020Obj.CountryBG * 100).toFixed(1));
                ddz.push((C0408020Obj.NumValue * 100).toFixed(1));
            }

            let myChart = self.$echarts.init(document.getElementById("xsdyl-chart1"));
            let option = {
                color: self.colorList,
                legend: {
                    data: ["全国标杆值","区域标杆值", "单店值"],
                    right: 150,
                },
                xAxis: {
                    type: "category",
                    data: [
                        "人力费用率",
                        "总毛利率",
                        "总费用占总毛利比",
                        "税前利润率",
                        "资产回报率",
                    ],
                    axisLabel: {
                        interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                        textStyle: {
                            fontSize: "10",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        formatter: "{value}%",
                    },
                    splitLine: {show: false},
                },
                series: [
                    {
                        name: "全国标杆值",
                        data: xzbg,
                        type: "bar",
                        barWidth: 40,
                        label: {
                            show: true,
                            position: "top",
                            color: "black",
                            formatter: (obj) => {
                                return obj.data + "%";
                            },
                        },
                    },
                    {
                        name: "区域标杆值",
                        data: qybg,
                        type: "bar",
                        barWidth: 40,
                        label: {
                            show: true,
                            position: "top",
                            color: "black",
                            formatter: (obj) => {
                                return obj.data + "%";
                            },
                        },
                    },
                    {
                        name: "单店值",
                        data: ddz,
                        type: "bar",
                        barWidth: 40,
                        label: {
                            show: true,
                            position: "top",
                            color: "black",
                            formatter: (obj) => {
                                return obj.data + "%";
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option);
        },
        getTableData() {
            let self = this;

            // let res = await self.$Http.GetDiagnoseBG({
            //     DealerCode: self.dealerCode,
            //     ReportDate: self.FormatDate(self.month),
            //     GroupId: self.groupId,
            // });
            //循环数据
            self.diagnoseBGBackData.forEach((element) => {
                if (element.CountryBG == null) {
                    element.CountryBG = 0;
                }
                if (element.NumValue == null) {
                    element.NumValue = 0;
                }
            });
            self.tableData.C0407001 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0407001"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0407001"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0407004 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0407004"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0407004"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0408004 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0408004"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0408004"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0408021 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0408021"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0408021"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0206001 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0206001"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0206001"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0207022 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0207022"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0207022"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0207028 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0207028"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0207028"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.C0207009 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "C0207009"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "C0207009"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0205034 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0205034"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0205034"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0205035 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0205035"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0205035"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0204032 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0204032"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0204032"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0302001 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0302001"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0302001"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0403002 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0403002"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0403002"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1402155 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1402155"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1402155"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0403001 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0403001"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0403001"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0107002 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0107002"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0107002"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0107004 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0107004"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0107004"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0106026 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0106026"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0106026"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0105071 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0105071"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0105071"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0105072 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0105072"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0105072"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0304024 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0304024"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0304024"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0304025 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0304025"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0304025"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1302012 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1302012"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1302012"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303081 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303081"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303081"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1302013 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1302013"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1302013"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303082 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303082"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303082"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303007 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303007"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303007"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1302115 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1302115"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1302115"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1302019 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1302019"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1302019"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303036 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303036"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303036"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303024 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303024"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303024"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303028 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303028"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303028"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303032 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303032"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303032"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A1303001 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A1303001"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A1303001"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0402006 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0402006"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0402006"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0404002 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0404002"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0404002"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0404181 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0404181"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0404181"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0404045 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0404045"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0404045"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0404111 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0404111"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0404111"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0302001 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0302001"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0302001"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0302121 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0302121"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0302121"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0104226 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0104226"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0104226"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0105181 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0105181"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0105181"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0105132 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0105132"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0105132"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0105161 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0105161"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0105161"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0303024 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0303024"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0303024"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.B0304014 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "B0304014"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "B0304014"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0105023 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0105023"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0105023"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0103068 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0103068"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0103068"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0104115 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0104115"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0104115"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0104116 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0104116"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0104116"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0104117 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0104117"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0104117"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0104119 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0104119"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0104119"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0303205 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0303205"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0303205"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0303206 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0303206"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0303206"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0303273 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0303273"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0303273"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
            self.tableData.A0303275 =
                self._.where(self.diagnoseBGBackData, {KpiCode: "A0303275"}).length > 0
                    ? self._.where(self.diagnoseBGBackData, {KpiCode: "A0303275"})[0]
                    : {CountryBG: 0,AreaBG:0, NumValue: 0};
        },
        //获取是否标记红点
        getIsPoint(code) {
            let obj = this._.where(this.tableData, {KpiCode: code});
            if (obj.length > 0) {
                obj = obj[0];
                if (
                    (obj.PercentileSort == "ASC" && obj.CountryBG > obj.NumValue) ||
                    (obj.PercentileSort == "DESC" && obj.CountryBG < obj.NumValue)
                ) {
                    return "table-redpoint";
                } else {
                    return "none";
                }
            }
        },

        FormatDate(date) {
            if (typeof date != "string") {
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                return year + "-" + month + "-" + day;
            } else {
                return date;
            }
        },
        //获取上月
        getLastMonthDay() {
            let currentDate = new Date();
            //当前年
            let currentYear = currentDate.getFullYear();
            //当前月
            let currentMonth = currentDate.getMonth();
            if (currentMonth == 0) {
                currentMonth = "12";
                currentYear = currentYear - 1;
            }
            if (currentMonth < 10) {
                currentMonth = "0" + currentMonth;
            }
            return currentYear + "-" + currentMonth + "-01";
        },
        isShowSeeDetail(score) {
            return false;
            // return score<=2;
        },
        doPrint() {
            let monthStr = moment(this.month).format("YYYY-MM-DD");
            let url = "/diagnosisReportPrint?month=" + monthStr + "&partName=" + this.partName + "&dealerCode=" + this.dealerCode;
            window.open(url, "_blank");
        },
        formatTableData() {

        }, windowPrint() {
            setTimeout(() => {
                window.print();
            }, 5000);
        },
    },
    async mounted() {
        this.month = this.$route.query.month;
        this.partName = this.$route.query.partName;

        this.dealerCode = this.$route.query.dealerCode;
        this.dealerName = this.$route.query.dealerName;
        if (!this.month) {
            this.month = this.getLastMonthDay();
        }

        //   this.getPointsRank();
        await this.getDealerInfo();
        await this.GetZdKpiValue();
        await this.getGetKpiBG();
        await this.getRankTable();
        this.getTableData();
        //自动选择上个月的日期
        //获取大区
        // this.getGroup();
        //获取当前登录用户店
        this.windowPrint();
    },
};
</script>


<style lang="less" scoped>
@import "./diagnosisReprt.less";
</style>
