const lastFilterCodes = [
  "A1402171", "A1402191", "A1402172", "A1402192", "A1402173", "A1402193", "A1402174", "A1402194",
  "A1402175", "A1402195", "A1402176", "A1402196", "A1402177", "A1402197", "A1402178", "A1402198",
  "A1402179", "A1402199", "A1402180", "A1402200", "A1402181", "A1402201", "A1402182", "A1402202",
  "A1402183", "A1402203", "A1402184", "A1402204",
];
const currentFilterCodes = [
  "A1402171", "A1402191", "A1402172", "A1402192", "A1402173", "A1402193", "A1402174", "A1402194","(E1202091+E1202092+E1202093+E1202094)/30",
  "A1402175", "A1402195", "A1402176", "A1402196", "A1402177", "A1402197", "A1402178", "A1402198",
  "A1402179", "A1402199", "A1402180", "A1402200", "A1402181", "A1402201", "A1402182", "A1402202",
  "A1402183", "A1402203", "A1402184", "A1402204", "E1202091+E1202092+E1202093+E1202094",
  "A1402171", "A1202091", "A1303401", "A1303441+A1202171+A1202201+A1202231", "A1303291_avg", "A1402121", "A1402141", "A0303311", "A0402012", "A1402221_avg", "A1403022_avg", "A0105051_avg",
  "A1202092", "A1303402", "A1303442+A1202172+A1202202+A1202232", "A1303292_avg", "A1402122", "A1402142", "A0303312", "A0402018", "A1402222_avg", "A1403023_avg", "A0105052_avg",
  "A1202093", "A1303403", "A1303443+A1202173+A1202203+A1202233", "A1303293_avg", "A1402123", "A1402143", "A0303313", "A0402024", "A1402223_avg", "A1403024_avg", "A0105053_avg",
  "A1202094", "A1303404", "A1303444+A1202174+A1202204+A1202234", "A1303294_avg", "A1402124", "A1402144", "A0303314", "A0402030", "A1402224_avg", "A1403025_avg", "A0105054_avg",
  "A1202095", "A1303405", "A1303445+A1202175+A1202205+A1202235", "A1303295_avg", "A1402125", "A1402145", "A0303315", "A0402036", "A1402225_avg", "A1403026_avg", "A0105055_avg",
  "A1202096", "A1303406", "A1303446+A1202176+A1202206+A1202236", "A1303296_avg", "A1402126", "A1402146", "A0303316", "A0402042", "A1402226_avg", "A1403027_avg", "A0105056_avg",
  "A1202097", "A1303407", "A1303447+A1202177+A1202207+A1202237", "A1303297_avg", "A1402127", "A1402147", "A0303317", "A0402048", "A1402227_avg", "A1403028_avg", "A0105057_avg",
  "A1202098", "A1303408", "A1303448+A1202178+A1202208+A1202238", "A1303298_avg", "A1402128", "A1402148", "A0303318", "A0402054", "A1402228_avg", "A1403029_avg", "A0105058_avg",
  "A1202099", "A1303409", "A1303449+A1202179+A1202209+A1202239", "A1303299_avg", "A1402129", "A1402149", "A0303319", "A0402060", "A1402229_avg", "A1403030_avg", "A0105059_avg",
  "A1202100", "A1303410", "A1303450+A1202180+A1202210+A1202240", "A1303300_avg", "A1402130", "A1402150", "A0303320", "A0402115", "A1402230_avg", "A1403031_avg", "A0105060_avg",
  "A1202101", "A1303411", "A1303451+A1202181+A1202211+A1202241", "A1303301_avg", "A1402131", "A1402151", "A0303321", "A0402121", "A1402231_avg", "A1403032_avg", "A0105061_avg",
  "A1202102", "A1303412", "A1303452+A1202182+A1202212+A1202242", "A1303302_avg", "A1402132", "A1402152", "A0303322", "A0402127", "A1402232_avg", "A1403033_avg", "A0105062_avg",
  "A1202103", "A1303413", "A1303453+A1202183+A1202213+A1202243", "A1303303_avg", "A1402133", "A1402153", "A0303323", "A0402133", "A1402233_avg", "A1403034_avg", "A0105063_avg",
  "A1202104", "A1303414", "A1303454+A1202184+A1202214+A1202244", "A1303304_avg", "A1402134", "A1402154", "A0303324", "A0402147", "A1402234_avg", "A1403035_avg", "A0105064_avg",
  "A1402185", "A1402205", "A1302115_avg","A1302115/30","A1302115", "A1303045", "A1303071_avg",  "A1303071", "A1303083_avg","A1303083", "A1402135", "A1402155/30", "A0302001/30", "A0402006", "A1402235_avg", "A1403021_avg", "A0106026_avg",
  "E1202091", "E1202092", "E1202093", "E1202094", "E1202095", "E1202099", "E1202096+E1202097+E1202098", "A1402235", "A1403021","A0106026","A1402236","A1402237",
  "E1202091_avg+E120209_avg2+E1202093_avg+E1202094_avg", "A1303045", "A1303043", "A1302012", "A1302013_avg", "A1303007_avg", "A1302028", "A1303071/30", "A1303080",
  "A1302032", "A1302121", "A1302131", "A0402003", "A0302001", "A0302021", "A0302022", "A0302023", "A0302024", "A0304025_avg", "A0304026_avg", "A0304027_avg", "A0304024_avg", "A0403002_avg", "A0302001_avg", "A0302001_avg",
 "A0402001", "A1402155_avg", "A0403001_avg", "A1402155",  "A0402006", "A0402007+A0402008+A0402009", "A0403155", "A0404111_avg", "A0403152",
  "A0404181_avg", "A0404045_avg", "A1303080", "A1302014","A1302014_avg", "A1302111", "A1302032", "A1302121", "A1302131","A1303031",
  "A1303043", "A1302012", "A1302013", "A1202271_avg","A1202271", "A1203401", "A1303080", "A1302111_avg", "A1303081","A1303081_avg", "A1303082", "A1303082_avg", "A1303083_avg", "A0302021", "A1302019",
  "A1303036", "A1302028", "A1303024", "A1202291", "A1203421", "A1203421_avg", "A1302032", "A1302032_avg", "A1303028", "A1303028_avg", "A1303032", "A0302022",
  "E1202099", "E1202096+E1202097+E1202098", "E1202111", "E1202112", "E1202113", "E1202114", "E1202115", "E1202119", "E1202116+E1202117+E1202118",
  "E1303331", "E1303332", "E1303333", "E1303334", "E1303335", "E1303339",
  "E1303331_avg", "E1303332_avg", "E1303333_avg", "E1303334_avg", "E1303335_avg", "E1303339_avg",
  "E1202151", "E1202152", "E1202153", "E1202154", "E1202155", "E1202159", "E1202156+E1202157+E1202158",
  "E1303231", "E1303232", "E1303233", "E1303234", "E1303235", "E1303239",
  "E1303231_avg", "E1303232_avg", "E1303233_avg", "E1303234_avg", "E1303235_avg", "E1303239_avg",
  "E1202171", "E1202172", "E1202173", "E1202174", "E1202175", "E1202179", "E1202176+E1202177+E1202178",
  "E1303251", "E1303252", "E1303253", "E1303254", "E1303255", "E1303259",
  "E1303251_avg", "E1303252_avg", "E1303253_avg", "E1303254_avg", "E1303255_avg", "E1303259_avg",
  "E1303361", "E1303362", "E1303363", "E1303364", "E1303365", "E1303369",
  "E1303361_avg", "E1303362_avg", "E1303363_avg", "E1303364_avg", "E1303365_avg", "E1303369_avg",
  "E0302160", "E0302161", "E0302162", "E0302163", "E0302164", "E0302168", "E0302165+E0302166+E0302167",
  "A0302027",  "A0302027_avg", "A0302028",  "A0302028_avg", "A0302030",  "A0302030_avg", "A0302033", "A0302033_avg", "A0402173", "A0402174", "A0402175", "A0402176", "A0404105", "A0404126",
  "A0303272", "A0303273", "A0303275", "A0303276", "A0402179", "A0402180", "A0402181", "A0402182", "A0404106", "A0404127","A0303272_avg", "A0303273_avg", "A0303275_avg", "A0303276_avg",
  "A1402186", "A1402206", "A1202106", "A1303416", "A1303455+A1202185+A1202215+A1202245", "A1303305_avg", "A1402136", "A1402156", "A0303325", "A0402172", "A1402236_avg", "A1403036_avg", "A0105065_avg",
  "A1402187", "A1402207", "A1202105", "A1303415", "A1303456+A1202186+A1202216+A1202246", "A1303306_avg", "A1402137", "A1402157", "A0303326", "A0402178", "A1402237_avg", "A1403037_avg", "A0105066_avg",
  "A1202091", "E1202281+E1202301+E1202321+E1202341", "E1202281", "E1202301", "E1202321", "E1202341", "E1202361", "E1202441", "E1202381+E1202401+E1202421", "A1202091", "E1202281+E1202301+E1202321+E1202341",
  "A1202092", "E1202282+E1202302+E1202322+E1202342", "E1202282", "E1202302", "E1202322", "E1202342", "E1202362", "E1202442", "E1202382+E1202402+E1202422", "A1202092", "E1202282+E1202302+E1202322+E1202342",
  "A1202093", "E1202283+E1202303+E1202323+E1202343", "E1202283", "E1202303", "E1202323", "E1202343", "E1202363", "E1202443", "E1202383+E1202403+E1202423", "A1202093", "E1202283+E1202303+E1202323+E1202343",
  "A1202094", "E1202284+E1202304+E1202324+E1202344", "E1202284", "E1202304", "E1202324", "E1202344", "E1202364", "E1202444", "E1202384+E1202404+E1202424", "A1202094", "E1202284+E1202304+E1202324+E1202344",
  "A1202095", "E1202285+E1202305+E1202325+E1202345", "E1202285", "E1202305", "E1202325", "E1202345", "E1202365", "E1202445", "E1202385+E1202405+E1202425", "A1202095", "E1202285+E1202305+E1202325+E1202345",
  "A1202096", "E1202286+E1202306+E1202326+E1202346", "E1202286", "E1202306", "E1202326", "E1202346", "E1202366", "E1202446", "E1202386+E1202406+E1202426", "A1202096", "E1202286+E1202306+E1202326+E1202346",
  "A1202097", "E1202287+E1202307+E1202327+E1202347", "E1202287", "E1202307", "E1202327", "E1202347", "E1202367", "E1202447", "E1202387+E1202407+E1202427", "A1202097", "E1202287+E1202307+E1202327+E1202347",
  "A1202098", "E1202288+E1202308+E1202328+E1202348", "E1202288", "E1202308", "E1202328", "E1202348", "E1202368", "E1202448", "E1202388+E1202408+E1202428", "A1202098", "E1202288+E1202308+E1202328+E1202348",
  "A1202099", "E1202289+E1202309+E1202329+E1202349", "E1202289", "E1202309", "E1202329", "E1202349", "E1202369", "E1202449", "E1202389+E1202409+E1202429", "A1202099", "E1202289+E1202309+E1202329+E1202349",
  "A1202100", "E1202290+E1202310+E1202330+E1202350", "E1202290", "E1202310", "E1202330", "E1202350", "E1202370", "E1202450", "E1202390+E1202410+E1202430", "A1202100", "E1202290+E1202310+E1202330+E1202350",
  "A1202101", "E1202291+E1202311+E1202331+E1202351", "E1202291", "E1202311", "E1202331", "E1202351", "E1202371", "E1202451", "E1202391+E1202411+E1202431", "A1202101", "E1202291+E1202311+E1202331+E1202351",
  "A1202102", "E1202292+E1202312+E1202332+E1202352", "E1202292", "E1202312", "E1202332", "E1202352", "E1202372", "E1202452", "E1202392+E1202412+E1202432", "A1202102", "E1202292+E1202312+E1202332+E1202352",
  "A1202103", "E1202293+E1202313+E1202333+E1202353", "E1202293", "E1202313", "E1202333", "E1202353", "E1202373", "E1202453", "E1202393+E1202413+E1202433", "A1202103", "E1202293+E1202313+E1202333+E1202353",
  "A1202104", "E1202294+E1202314+E1202334+E1202354", "E1202294", "E1202314", "E1202334", "E1202354", "E1202374", "E1202454", "E1202394+E1202414+E1202434", "A1202104", "E1202294+E1202314+E1202334+E1202354",
  "A1202041", "A1202061", "A1202171", "A1202201", "A1202231", "E1202296", "E1202316", "E1202336", "E1202356", "E1202376", "E1202456", "E1202396+E1202416+E1202436",
  "A1202042", "A1202062", "A1202172", "A1202202", "A1202232", "E1202295", "E1202315", "E1202335", "E1202355", "E1202375", "E1202455", "E1202395+E1202415+E1202435",
  "A1202043", "A1202063", "A1202173", "A1202203", "A1202233", "A1202016", "A1202036", "A1202166",
  "A1202044", "A1202064", "A1202174", "A1202204", "A1202234", "A1202015", "A1202035", "A1202165",
  "A1202045", "A1202065", "A1202175", "A1202205", "A1202235", "A1202055", "A1202075", "A1202185", "A1202215", "A1202245", "A1303455+A1202185+A1202215+A1202245",
  "A1202046", "A1202066", "A1202176", "A1202206", "A1202236", "A1202056", "A1202076", "A1202186", "A1202216", "A1202246", "A1303456+A1202186+A1202216+A1202246",
  "A1202047", "A1202067", "A1202177", "A1202207", "A1202237", "A0302154", "A0302174", "A0302194", "A0302214",
  "A1202048", "A1202068", "A1202178", "A1202208", "A1202238", "A0302155", "A0302175", "A0302195", "A0302215",
  "A1202049", "A1202069", "A1202179", "A1202209", "A1202239",
  "A1202050", "A1202070", "A1202180", "A1202210", "A1202240",
  "A1202051", "A1202071", "A1202181", "A1202211", "A1202241",
  "A1202052", "A1202072", "A1202182", "A1202212", "A1202242",
  "A1202053", "A1202073", "A1202183", "A1202213", "A1202243",
  "A1202054", "A1202074", "A1202184", "A1202214", "A1202244",
  "A0302140", "A0302160", "A0302180", "A0302200", "A1402156",
  "A0302141", "A0302161", "A0302181", "A0302201", "A1402157",
  "A0302142", "A0302162", "A0302182", "A0302202",
  "A0302143", "A0302163", "A0302183", "A0302203",
  "A0302144", "A0302164", "A0302184", "A0302204",
  "A0302145", "A0302165", "A0302185", "A0302205",
  "A0302146", "A0302166", "A0302186", "A0302206",
  "A0302147", "A0302167", "A0302187", "A0302207",
  "A0302148", "A0302168", "A0302188", "A0302208",
  "A0302149", "A0302169", "A0302189", "A0302209",
  "A0302150", "A0302170", "A0302190", "A0302210",
  "A0302151", "A0302171", "A0302191", "A0302211",
  "A0302152", "A0302172", "A0302192", "A0302212",
  "A0302153", "A0302173", "A0302193", "A0302213",
  "A1402141", "A1402142", "A1402143", "A1402144", "A1402145", "A1402146", "A1402147", "A1402148", "A1402149", "A1402150", "A1402151", "A1402152", "A1402153", "A1402154",
  "A0402012", "A0402018", "A0402024", "A0402030", "A0402036", "A0402042", "A0402048", "A0402054", "A0402060", "A0402115", "A0402121", "A0402127", "A0402133", "A0402147",
  "A1202001", "A1202021", "A1202151",
  "A1202002", "A1202022", "A1202152",
  "A1202003", "A1202023", "A1202153",
  "A1202004", "A1202024", "A1202154",
  "A1202005", "A1202025", "A1202155",
  "A1202006", "A1202026", "A1202156",
  "A1202007", "A1202027", "A1202157",
  "A1202008", "A1202028", "A1202158",
  "A1202009", "A1202029", "A1202159",
  "A1202010", "A1202030", "A1202160",
  "A1202011", "A1202031", "A1202161",
  "A1202012", "A1202032", "A1202162",
  "A1202013", "A1202033", "A1202163",
  "A1202014", "A1202034", "A1202164",
  "A1302115_avg", "A1302019", "A1303036", "A1303036_avg", "A1302028", "A1303024", "A1303024_avg", "A1302032", "A1303028", "A1303028_avg", "A1303032",  "A1303032_avg", "A0302022",
  "A0402013", "A0402014", "A0402015", "A0402016", "A0404091", "A0404112",
  "A0402019", "A0402020", "A0402021", "A0402022", "A0404092", "A0404113",
  "A0402025", "A0402026", "A0402027", "A0402028", "A0404093", "A0404114",
  "A0402031", "A0402032", "A0402033", "A0402034", "A0404094", "A0404115",
  "A0402037", "A0402038", "A0402039", "A0402040", "A0404095", "A0404116",
  "A0402043", "A0402044", "A0402045", "A0402046", "A0404096", "A0404117",
  "A0402049", "A0402050", "A0402051", "A0402052", "A0404097", "A0404118",
  "A0402055", "A0402056", "A0402057", "A0402058", "A0404098", "A0404119",
  "A0402061", "A0402062", "A0402063", "A0402064", "A0404099", "A0404120",
  "A0402116", "A0402117", "A0402118", "A0402119", "A0404100", "A0404121",
  "A0402122", "A0402123", "A0402124", "A0402125", "A0404101", "A0404122",
  "A0402128", "A0402129", "A0402130", "A0402131", "A0404102", "A0404123",
  "A0402134", "A0402135", "A0402136", "A0402137", "A0404103", "A0404124",
  "A0402148", "A0402149", "A0402150", "A0402171", "A0404104", "A0404125",

];

const rateFilterCodes = [
  "E1202281", "E1202282", "E1202283", "E1202284", "E1202285", "E1202286", "E1202287", "E1202288", "E1202289", "E1202290", "E1202291", "E1202292", "E1202293", "E1202294",
  "A1202041", "A1202042", "A1202043", "A1202044", "A1202045", "A1202046", "A1202047", "A1202048", "A1202049", "A1202050", "A1202051", "A1202052", "A1202053", "A1202054",
  "A0302140", "A0302141", "A0302142", "A0302143", "A0302144", "A0302145", "A0302146", "A0302147", "A0302148", "A0302149", "A0302150", "A0302151", "A0302152", "A0302153",
  "A1402141", "A1402142", "A1402143", "A1402144", "A1402145", "A1402146", "A1402147", "A1402148", "A1402149", "A1402150", "A1402151", "A1402152", "A1402153", "A1402154",
  "A1202001", "A1202002", "A1202003", "A1202004", "A1202005", "A1202006", "A1202007", "A1202008", "A1202009", "A1202010", "A1202011", "A1202012", "A1202013", "A1202014",
  "A1303441+A1202171+A1202201+A1202231", "A1303442+A1202172+A1202202+A1202232", "A1303443+A1202173+A1202203+A1202233", "A1303444+A1202174+A1202204+A1202234", "A1303445+A1202175+A1202205+A1202235", "A1303446+A1202176+A1202206+A1202236", "A1303447+A1202177+A1202207+A1202237", "A1303448+A1202178+A1202208+A1202238", "A1303449+A1202179+A1202209+A1202239", "A1303450+A1202180+A1202210+A1202240", "A1303451+A1202181+A1202211+A1202241", "A1303452+A1202182+A1202212+A1202242", "A1303453+A1202183+A1202213+A1202243", "A1303454+A1202184+A1202214+A1202244",
];

export {
  lastFilterCodes, currentFilterCodes, rateFilterCodes,
};
