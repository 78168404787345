const HUMAN_MANNAGE_API = {
    //人员列表
    getUserList: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetAllUsersPage'
    },
    // 获取巨区
    getBGPart: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetBGPart'
    },

    // 获取小区
    getAreaByPart: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetAreaByPart',
    },

    // 获取城市
    getCity: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCityByArea',
    },

    // 获取经销商
    getCompany: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCompany',
    },

    // 获取用户详情
    getUserInfo: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetEditUserInfo',
    },

    // 获取部门
    getDepartmentInfo: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetDepartmentInfo',
    },

    // 获取岗位
    getPostionInfo: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPostionInfo',
    },

    //获取管理权限
    getPermissionInfo: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPermissionInfo',
    },

    //保存人员
    editUsers: {
        method: 'post',
        url: '/BasicSetting/BasicSet/EditUsers',
    },

    //新增人员
    addUser: {
        method: 'post',
        url: '/BasicSetting/BasicSet/AddUsers',
    },
    //修改密码
    changePwd: {
        method: 'post',
        url: '/BasicSetting/BasicSet/UpdatePwd',
    },
    //重置密码
    resetPwd: {
        method: 'post',
        url: '/BasicSetting/BasicSet/ResetPwd',
    },
    //获取用户名后缀
    GetAccountSuffix: {
        method: 'post',
        url: '/XDealerAccountManage/GetAccountSuffix',
    },
}
export default HUMAN_MANNAGE_API
