<template>
  <dt v-if="item.HasChildren" @click="showDetail">
    <ol>{{ item.KpiName }}</ol>
    <ul>
      <li v-if="item.StandardValue!=null"><span>标杆值：</span><span>{{ item.StandardValueText }}</span></li>
      <li v-if="item.StandardValue!=null"><span>标杆达标率：</span><span>{{ item.UpToStandardRateText }}</span></li>
      <li><span>平均值：</span><span>{{ item.AvgValueText }}</span></li>
    </ul>
  </dt>
  <div v-else :class="['row-list-col',item.class]" @click="showDetail">
    <ol>• {{ item.KpiName }}</ol>
    <ul>
      <li v-if="item.StandardValue!=null"><span>标杆值：</span><span>{{ item.StandardValueText }}</span></li>
      <li v-if="item.StandardValue!=null"><span>标杆达标率：</span><span>{{ item.UpToStandardRateText }}</span></li>
      <li><span>平均值：</span><span>{{ item.AvgValueText }}</span></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: null
  },
  computed: {
    item() {
      let data = this.data ?? {
        KpiName: "未知",
        Unit: "[-]",
        StandardValue: 0,
        UpToStandardRate: 0,
        AvgValue: 0,
        Level: 0,
        HasChildren: false,
      };
      if (data.StandardValue != null) {
        data.StandardValueText = this.format(data.StandardValue, data.Unit);
        data.UpToStandardRateText = this.format(data.UpToStandardRate, "%");
      }
      data.AvgValueText = this.format(data.AvgValue, data.Unit);
      if (data.Level === 10) {
        data.class = this.isHeader ? "r-green" : "c-green";
      } else if (data.Level === 20) {
        data.class = this.isHeader ? "r-red" : "c-red";
      }
      return data;
    }
  },
  methods: {
    format(value, unit) {
      return unit === "%"
          ? Number((value * 100).toFixed(1)).toLocaleString("zh-CN") + unit
          : Number(value.toFixed(2)).toLocaleString("zh-CN") + unit;
    },
    showDetail() {
      if (this.data.KpiCode == null) {
        return;
      }
      this.$router.push({
        name: "IndustryTable",
        params: {
          reportDate: this.data.ReportDate,
          kpiCode: this.data.KpiCode
        },
      });
    }
  }
}
</script>

<style lang="less" scoped>
.sound {
  padding-top: 24px;

  span:nth-child(2) {
    color: #00B478;
  }

  span:last-child {
    color: #24619e;
  }
}

.space {
  padding-top: 20px;
}

.row-list {
  width: 100%;
  overflow: hidden;

  dt {
    float: left;
    border: 1px solid #86A3A1;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    filter: blur(0px);
    margin-bottom: 10px;

    ol {
      padding: 0;
      margin: 0;
      background: #E6ECEC;
      text-align: center;
      height: 42px;
      line-height: 42px;
      color: #004540;
      border-radius: 8px 8px 0 0;
      border-bottom: 1px solid #86A3A1;
    }

    ul {
      padding: 7px 15px;
      margin: 0;
      border-radius: 0 0 8px 8px;
    }

    &:nth-child(2n) {
      width: 2%;
      border: 0;
      text-align: center;
      padding-top: 40px;
      font-size: 24px;
      color: #286674;
      font-weight: 500;

      &:hover {
        border: none;
        box-shadow: 0 0 0 #fff;
      }
    }

    &:hover {
      border: 1px solid #86A3A1;
      box-shadow: 2px 4px 8px 2px rgba(78, 78, 78, 0.7);

    }

    &.r-green {
      /*  ol {
          background: #86A3A1;
          }*/

      ul {
        background: #B2E8D6;

        span:nth-child(2) {
          color: #00B478;
        }
      }
    }

    &.r-red {
      ol {
        background: #86A3A1;
      }

      ul {
        background: #F9D1D1;

        span:nth-child(2) {
          color: #24619e;
        }
      }
    }
  }
}

.row-list-col {
  font-size: 14px;
  text-align: left;
  border: 1px dashed #004540;
  border-radius: 8px;
  padding: 5px 10px;
  color: #777;
  font-weight: 500;
  margin-bottom: 2px;

  ol {
    color: #666
  }

  ul {
    padding-left: 8px
  }

  &:hover {
    border: 1px solid #004540;
    box-shadow: 2px 4px 8px 2px rgba(78, 78, 78, 0.7);
  }

  &.c-green {
    background: #B2E8D6;

    span:last-child {
      color: #00B478;
    }
  }

  &.c-red {
    background: #F9D1D1;

    span:last-child {
      color: #24619e;
    }
  }
}
</style>
