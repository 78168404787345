const DashBord_API = {
    // 全国看版
    CountryPartDealerDashBord: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CountryPartDealerDashBord",
    },// 店端看版
    DealerDashBord: {
        method: "post",
        url: "/DealerKpi/SecondBDM/DealerDashBord",
    },
    // 全国售后来厂台次12月
    CountryPartGetYearKpiCode: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CountryPartGetYearKpiCode",
    },
    // 售后来厂台次12月
    GetYearKpiCode: {
        method: "post",
        url: "/DealerKpi/SecondBDM/GetYearKpiCode",
    },
    // 全国车型环比同比
 CountryPartGetCarTypeHbTb: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CountryPartGetCarTypeHbTb",
    },
    // 车型环比同比
    GetCarTypeHbTb: {
        method: "post",
        url: "/DealerKpi/SecondBDM/GetCarTypeHbTb",
    },
    // 车型分析 获取大区，省市下拉选项
    GetAnalysisPartSelect: {
        method: "post",
        url: "/DealerKpi/SecondBDM/GetAnalysisPartSelect",
    },
    // 车型分析 全国上险
    CountryInsure: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CountryInsure",
    },
    // 车型分析 车型级别市占率
    CarTypeMarketShare: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CarTypeMarketShare",
    },
    // 车型分析 各车系市占率
    CarSeriesZB: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CarSeriesZB",
    },
    // 车型分析 自主品牌占有率
    CarBrandZB: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CarBrandZB",
    },
    // 车型分析 传祺车型市占率（竞品共用此接口）
    CarCQCartypeZB: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CarCQCartypeZB",
    },
    // 车型分析 竞品下拉选择
    GetCarCompete: {
        method: "post",
        url: "/DealerKpi/SecondBDM/GetCarCompete",
    },
    // 实时车型分析
    Realtime: {
        method: "post",
        url: "/XVehicleTypeAnalyze/Realtime",
    },
    // 区域业绩表现
    Analyze: {
        method: "post",
        url: "/XAreaPerformance/Analyze",
    },
    // 传祺市占率推移总览
    PushOverall: {
        method: "post",
        url: "/DealerKpi/SecondBDM/PushOverall",
    },
    // 传祺市占率销售数据对比
    DataRatio: {
        method: "post",
        url: "/DealerKpi/SecondBDM/DataRatio",
    },
    // 传祺市占率派系、车型数据
    SeriesCartypeRatio: {
        method: "post",
        url: "/DealerKpi/SecondBDM/SeriesCartypeRatio",
    },
    // 车型竞品接口
    CarCompeteSaleRatio: {
        method: "post",
        url: "/DealerKpi/SecondBDM/CarCompeteSaleRatio",
    },
    // 车型看板   获取竞品下拉
    GetCompeteCar:{
        method: "post",
        url: "/DealerKpi/SecondBDM/GetCompeteCar",
    }
};
export default DashBord_API;
