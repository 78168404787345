const IMPROVEPLAN_API = {
    // 厂端弱项改善列表
    GetFactoryImprovePlanListPage: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetFactoryImprovePlanListPage'
    },
    // 店端改善列表分页
    GetImprovePlanListPage: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetImprovePlanListPage'
    },
    // 案例集列表
    GetCaseBookListPage: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetCaseBookListPage'
    },
    // 放弃改善
    AbandonPlanStatus: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/AbandonPlanStatus'
    },
    //设定取消案例集
    SetCasebook: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/SetCasebook'
    },
    //诊断报告里的弱项点击查出一二级信息
    GetWeakListByCode: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetWeakListByCode'
    },
    //新增改善计划
    InsertImprovePlan: {
        method: 'post',
        url: '/DealerKpi/SecondBDM/InsertImprovePlan'
    },
    //弱项联动一级项
    GetWeakCodeList: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetWeakCodeList'
    },
    //弱项二级联动下拉
    GetWeakCodeByFirst: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetWeakCodeByFirst'
    },
    //点击改善行动带出详情信息
    GetImproveDetail: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetImproveDetail'
    },
    //上传文件
    UploadFile: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/UploadFile'
    },
    //保存改善行动
    InsertPlanDetail: {
        method: 'post',
        url: '/DealerKpi/SecondBDM/InsertPlanDetail'
    },

    //根据code查询前两月的指标值下拉选择
    GetNumValueByCode: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetNumValueByCode'
    },
    //查询该code的全国区域标杆值
    GetBGNumValue: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetBGNumValue'
    },
    //根据code查询对应的案例集
    GetCaseBookByCode: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/GetCaseBookByCode'
    },
    //删除案例集
    DeleteBookCase: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/DeleteBookCase'
    },
    //上传保存案例集
    InsertBookCase: {
        method: 'get',
        url: '/DealerKpi/SecondBDM/InsertBookCase'
    }
}
export default IMPROVEPLAN_API
