<template>
    <div>footersdfds</div>
</template>

<script>
    export default {
        name: "footerr"
    }
</script>

<style scoped>

</style>
