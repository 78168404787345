const AREA_MANNAGE_API = {
    //小区列表
    GetAreaListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetAreaListByPage'
    },

    //获取小区排序号
    GetAreaSortIndex: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetAreaSortIndex'
    },
    //大区列表
    GetPart: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPart'
    },

    //新增大区
    InsertArea: {
        method: 'get',
        url: '/BasicSetting/BasicSet/InsertArea'
    },

    //获取详情
    GetAreaInfoById: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetAreaInfoById'
    },

    //编辑大区
    EditArea: {
        method: 'get',
        url: '/BasicSetting/BasicSet/EditArea'
    },

    //删除大区
    DeleteArea: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeleteArea'
    }
}
export default AREA_MANNAGE_API
