<template>
  <section>
    <a-card class="card">
      <a-form
        :form="form"
        layout="horizontal"
        :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="大区">
              <a-select show-search
                        :filterOption="filterOption" v-model="form.partCode" @change="BGPartChange">
                <a-select-option
                  v-for="(item, i) in BGPart"
                  :key="i"
                  :value="item.SimpleCode"
                >
                  {{ item.PartName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="小区">
              <a-select show-search
                        :filterOption="filterOption" v-model="form.areaCode" @change="BigAreaChange">
                <a-select-option
                  v-for="(item, i) in bigArea"
                  :key="i"
                  :value="item.SimpleCode"
                >
                  {{ item.AreaName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="城市">
              <a-select show-search
                        :filterOption="filterOption" v-model="form.cityCode">
                <a-select-option
                  v-for="(item, i) in city"
                  :key="i"
                  :value="item.SimpleCode"
                >
                  {{ item.CityName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="关键字查询">
              <a-input
                placeholder="请输入销售店名称/代码"
                v-model="form.companyName"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="状态">
              <a-select v-model="form.status" >
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="新店">
                  新店
                </a-select-option>
                <a-select-option value="正常">
                  正常
                </a-select-option>
                <a-select-option value="异常">
                  异常
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="getList(1)"> 查询 </a-button>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button
                type="primary"
                @click="$router.push('/companyMannage/add')"
              >
                新增销售店
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card class="card" style="margin-top: 18px;">
      <a-table
        rowKey="CompanyId"
        :columns="columns"
        :data-source="tableData"
        :pagination="paginationParam"
        @change="tableChange"
      >
        <span slot="action" slot-scope="record">
          <a-button
            size="small"
            type="primary"
            @click="doEdit(record.CompanyId)"
            >编辑</a-button
          >
<!--          <a-divider type="vertical" />-->
<!--          <a-popconfirm-->
<!--            title="确定删除吗？"-->
<!--            @confirm="() => doDel(record.CompanyId)"-->
<!--          >-->
<!--            <a-button size="small" type="primary">删除</a-button>-->
<!--          </a-popconfirm>-->
        </span>
      </a-table>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
const columns = [
  {
    title: '序号',
    width: '60px',
    customRender:(text,record,index)=>`${index+1}`,
  },
  {
    title: "经销商名称（中文）",
    dataIndex: "CompanyNameCN",
    key: "CompanyNameCN",
  },
  {
    title: "经销商名称（英文）",
    dataIndex: "CompanyNameEN",
    key: "CompanyNameEN",
  },
  {
    title: "经销商代码",
    dataIndex: "CompanyCode",
    key: "CompanyCode",
  },
  {
    title: "经营类型",
    key: "CompanyType",
    dataIndex: "CompanyType",
  },
  {
    title: "大区名称",
    key: "PartName",
    dataIndex: "PartName",
  },
  {
    title: "小区名称",
    key: "AreaName",
    dataIndex: "AreaName",
  },
  {
    title: "城市",
    key: "CityName",
    dataIndex: "CityName",
  },
  {
    title: "地址",
    key: "Address",
    dataIndex: "Address",
  },
  {
    title: "状态",
    key: "Status",
    dataIndex: "Status",
    width: 80
  },
  {
    title: "操作",
    key: "Action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      form: {
        //大区
        partCode: "",
        //大区
        areaCode: "",
        //小区（城市）
        cityCode: "",
        //关键字
        companyName: "",
        status: ""
      },
      //分页数据
      pageData: {},
      //表格数据
      tableData: [],
      columns,
      // 大区
      BGPart: [],
      // 小区
      bigArea: [{ AreaName: "全部", SimpleCode: "" }],
      // 城市
      city: [{ CityName: "全部", SimpleCode: "" }],
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.getList(this.paginationParam.current);
    },

    gotoForm() {
      this.$router.push("/humanMannage/form");
    },

    async BigAreaChange(value) {
      //获取城市
      let res = await this.$Http.getCity({ areaCode: value });
      this.city = res.Data;
      this.form.cityCode = "";
      this.city.unshift({ CityName: "全部", SimpleCode: "" });
    },

    async BGPartChange(value) {
      //获取大区
      let res = await this.$Http.getAreaByPart({ partCode: value });
      this.bigArea = res.Data;
      this.form.areaCode = "";
      this.form.cityCode = "";
      this.bigArea.unshift({ AreaName: "全部", SimpleCode: "" });
    },

    //获取大区
    async getBGPart() {
      let res = await this.$Http.getBGPart();
      this.BGPart = res.Data;
      this.BGPart.unshift({ PartName: "全部", SimpleCode: "" });
    },

    //获取列表
    async getList(current) {
      let self = this;
      //将分页参数传入form对象
      self.form.pageSize = self.paginationParam.pageSize;
      self.form.pageIndex = current;
      self.paginationParam.current=current;
      let res = await self.$Http.GetCompanyListByPage(self.form);
      self.paginationParam.total = res.TotalCount;
      self.tableData = res.Data;
    },
    //编辑
    doEdit(id) {
      this.$router.push("/companyMannage/edit?companyId=" + id);
    },
    async doDel(id) {
      let self = this;
      let res = await self.$Http.DeleteCompany({ companyId: id });
      if (res.Success) {
        self.$message.success(res.Data);
        self.getList(1);
      } else {
        self.$message.error(res.Message);
      }
    },
  },
  created() {},
  mounted() {
    //获取大区
    this.getBGPart();
    this.getList(1);
  },
};
</script>
