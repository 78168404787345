<template>
  <section>
    <a-card class="">
      <a-form
        :form="form"
        layout="horizontal"
        :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="大区">
              <a-select show-search
                        :filterOption="filterOption" v-model="form.bigPart" @change="BGPartChange"
              >
                <a-select-option
                  v-for="(item, i) in BGPart"
                  :key="i"
                  :value="item.SimpleCode"
                >
                  {{ item.PartName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="小区">
              <a-select show-search
                        :filterOption="filterOption" v-model="form.smallPart" @change="BigAreaChange"
              >
                <a-select-option
                  v-for="(item, i) in bigArea"
                  :key="i"
                  :value="item.SimpleCode"
                >
                  {{ item.AreaName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="城市">
              <a-select show-search
                        :filterOption="filterOption" v-model="form.cityPart"
              >
                <a-select-option
                  v-for="(item, i) in city"
                  :key="i"
                  :value="item.SimpleCode"
                >
                  {{ item.CityName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="状态">
              <a-select show-search
                        :filterOption="filterOption" default-value="在职" v-model="form.IsonJob"
              >
                <a-select-option
                  v-for="(item, i) in StatusList"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="经销商">
              <a-select
                allowClear
                show-search
                :default-active-first-option="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="handleChange"
                v-model="form.companyCode"
              >
                <a-select-option
                  v-for="(d, i) in companyList"
                  :key="i"
                  :value="d.CompanyCode"
                >
                  {{ d.CompanyNameCN }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="关键字查询">
              <a-input
                placeholder="请输入姓名/账号/职位"
                v-model="form.keyWords"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="getList(1)"> 查询</a-button>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="$router.push('/humanMannage/add')">
                新增
              </a-button>
            </a-form-item>
          </a-col>
            <a-col :span="4">
            <a-form-item label="">
              <a-button type="primary" @click="doExport">导出
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card style="margin-top: 18px">
      <a-table
        rowKey="UserId"
        :columns="columns"
        :data-source="tableData"
        :pagination="paginationParam"
        @change="tableChange"
      >
        <span slot="IsOnJob" slot-scope="IsOnJob">
          {{ IsOnJob == 1 ? "在职" : "离职" }}
        </span>
        <span slot="action" slot-scope="record">
          <a-button size="small" type="primary" @click="doEdit(record.UserId)"
          >编辑</a-button
          >

          <a-popconfirm
            title="确定重置密码吗？"
            @confirm="() => resetPwd(record.UserId)"
          >
            <a-button size="small" type="primary">重置密码</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
import Http from "@/api/http";
import axios from "axios";

let timeout;

function fetch (value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  async function fake () {
    let res = await Http.getCompany({ keyCodes: value });
    callback(res);
  }

  timeout = setTimeout(fake, 300);
}

const columns = [
  {
    title: "序号",
    width: "60px",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "姓名",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "账号",
    dataIndex: "UserName",
    key: "UserName",
  },
  {
    title: "手机号",
    dataIndex: "MobilePhone",
    key: "MobilePhone",
  },
  {
    title: "状态",
    dataIndex: "IsOnJob",
    key: "IsOnJob",
    scopedSlots: { customRender: "IsOnJob" },
  },
  {
    title: "操作",
    key: "Action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data () {
    return {
      form: {
        //大区
        bigPart: "",
        //小区
        smallPart: "",
        //小区（城市）
        cityPart: "",
        //状态
        IsonJob: 0,
        //关键字
        keyWords: "",
        //经销商代码
        companyCode: "",
        pageIndex: 1,
      },
      //分页数据
      pageData: {},
      //表格数据
      tableData: [],
      columns,
      // 大区
      BGPart: [],
      // 小区
      bigArea: [{ AreaName: "全部", SimpleCode: "" }],
      // 城市
      city: [{ CityName: "全部", SimpleCode: "" }],
      //状态列表
      StatusList: [
        {
          title: "全部",
          value: null,
        },
        {
          title: "在职",
          value: 0,
        },
        {
          title: "离职",
          value: 1,
        },
      ],
      //销售店列表
      companyList: [
        {
          CompanyNameCN: "全部",
          CompanyCode: "",
        },
      ],
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  methods: {
      async doExport(){
              axios({
                  url: '/apis/BasicSet/UserExport',
                  method: "get",
                  responseType: "blob",
              }).then(response => {
                  const blob = response.data;
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = `人员列表.xls`;
                  link.click();
              },
          )
      },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    tableChange (pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.getList(this.paginationParam.current);
    },
    //重置密码
    async resetPwd (userId) {
      let self = this;
      let res = await self.$Http.resetPwd({ userId: userId });
      if (res.Success) {
        self.$message.success(res.Data);
      } else {
        self.$message.error(res.Message);
      }
    },
    gotoForm () {
      this.$router.push("/humanMannage/form");
    },

    handleSearch (value) {
      fetch(value, (data) => (this.companyList = data.Data));
    },
    handleChange (value) {
      if (typeof value == "undefined") {
        this.form.companyCode = "";
        this.companyList = [
          {
            CompanyNameCN: "全部",
            CompanyCode: "",
          },
        ];
      } else {
        fetch(value, (data) => (this.companyList = data.Data));
      }
    },

    async BigAreaChange (value) {
      //获取城市
      let res = await this.$Http.getCity({ areaCode: value });
      this.city = res.Data;
      this.form.cityPart = "";
      this.city.unshift({ CityName: "全部", SimpleCode: "" });
    },

    async BGPartChange (value) {
      //获取小区
      let res = await this.$Http.getAreaByPart({ partCode: value });
      this.bigArea = res.Data;
      this.form.smallPart = "";
      this.form.cityPart = "";
      this.bigArea.unshift({ AreaName: "全部", SimpleCode: "" });
    },

    //获取大区
    async getBGPart () {
      let res = await this.$Http.getBGPart();
      this.BGPart = res.Data;
      this.BGPart.unshift({ PartName: "全部", SimpleCode: "" });
    },

    //获取列表
    async getList (pageIndex) {
      let self = this;
      //将分页参数传入form对象
      self.form.pageSize = self.paginationParam.pageSize;
      self.form.pageIndex = self.paginationParam.current = pageIndex;
      let res = await self.$Http.getUserList(self.form);
      self.paginationParam.total = res.TotalCount;
      self.tableData = res.Data;
    },
    //编辑用户
    doEdit (userID) {
      this.$router.push("/humanMannage/form?userID=" + userID);
    },
  },
  created () {
  },
  mounted () {
    //获取大区
    this.getBGPart();
    this.getList(1);
  },
};
</script>
