<template>
  <section>
    <a-card>
      <div class="sea">
        <span>计划月份：</span>
        <a-month-picker
            v-model="formInfo.YearMonth"
            @change="onDateChange"
            placeholder="请选择计划月份"
            :allowClear="false" style="max-width: 120px"
        />
        <span style="padding-left: 16px">校核状态：</span>
        <a-select
            v-model="formInfo.ValidateStatus"
            style="width: 130px"
            @change="validatestatusChange"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="-2"> 未上传</a-select-option>
          <a-select-option value="0"> 未校核</a-select-option>
          <a-select-option value="1"> 校核成功</a-select-option>
          <a-select-option value="2"> 特殊校核成功</a-select-option>
          <a-select-option value="-1"> 校核失败</a-select-option>
        </a-select>
        <span style="padding-left: 16px">审核状态：</span>
        <a-select default-value="" style="width: 130px" placeholder="全部状态" @change="auditStatusChange">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="审核成功"> 审核成功</a-select-option>
          <a-select-option value="厂端巡回员驳回"> 厂端巡回员驳回</a-select-option>
          <a-select-option value="厂端管理员驳回"> 厂端管理员驳回</a-select-option>
          <a-select-option value="待所属巡回员审核"> 待所属巡回员审核</a-select-option>
          <!--          <a-select-option value="1">
                      店端经理通过
                    </a-select-option>
                    <a-select-option value="-1">
                      店端经理驳回
                    </a-select-option>
                    <a-select-option value="2">
                      厂端通过
                    </a-select-option>
                    <a-select-option value="-2">
                      厂端驳回
                    </a-select-option>-->
        </a-select>
        <span style="padding-left: 16px">经销商选择：</span>
        <a-cascader style="width: 240px" :options="dealers" :allowClear="true"
            placeholder="请选择/输入销售店" v-model="choseDealer" @change="dealerChange"
            :show-search="true"
        />
<!--        <a-button
            type="primary"

            @click="doSearch"
        >
          搜索
        </a-button>-->
        <a-button @click="ExportRejectDetail" style="margin-left: 20px">导出驳回</a-button>
        <a-button @click="downLoad"> 下载</a-button>
        <a-button type="primary" @click="reUpload">重新上传</a-button>
      </div>
    </a-card>
    <div class="top">
      <span>未上传数<b>{{ uploadInfo.NoUploadCount }}</b></span>
      <span>已上传数 <b>{{ uploadInfo.UploadCount }}</b> </span>【
      <span> 其中： </span>
      <span>未校核数 <b>{{ uploadInfo.NoValiCount }}</b></span>
      <span>校核通过数 <b>{{ uploadInfo.PassCount }}</b></span>
      <span>校核失败数 <b>{{ uploadInfo.FailCount }}</b></span>
      <span>特殊校核数 <b>{{ uploadInfo["SpePassCount"] }}</b></span>】
    </div>
    <a-card style="margin-top: 8px">
      <a-table
          :columns="columns"
          :data-source="data"
          :pagination="false"
          :rowKey="(record, index) => index"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="YearMonth" slot-scope="text">{{ dayFormatMouth(text) }}</span>
        <a
            slot="ExcelName"
            slot-scope="record"
            @click.stop="newDownExcel(record.ObjectKey)"
        >
          <b style="font-weight: 500; text-decoration: underline">
            {{ record["ExcelName"] }}
          </b>
        </a>

        <span slot="ReportStatus" slot-scope="tags">
          <a-badge :color="tagsColor(tags)"/>
          <span>{{ tags }}</span>
        </span>
        <span slot="AuditType" slot-scope="record">
          <a v-if="record['AuditType']==='厂端管理员驳回' || record['AuditType']==='厂端巡回员驳回'" @click.stop="getAuditTypeData(record)">{{
              record['AuditType'] }}</a>
          <span v-else>{{ record['AuditType'] }}</span>
        </span>
        <span slot="action" slot-scope="record" class="action">
          <a v-if="record['IsInitInspec'] === 0 && record['IsKong'] > 0" @click.stop="() => showError(record)">查看错误
          </a>
          <a v-if="record['IsInitInspec'] === 1 || record['IsInitInspec'] === 2" @click.stop="() => GOReport(record)">查看报告</a>
          <a v-if="record['IsInitInspec'] === -1 && record['jhError'] > 0" @click.stop="() => showError(record)">查看错误</a>
          <a v-if="record['IsInitInspec'] === -1" @click.stop="() => showError(record)">查看错误</a>
          <a v-if="record['IsInitInspec'] === 2 && record.AuditStatus===2 " @click.stop="() => showPassModal(record,null)">查看特殊校核备注</a
          >
        </span>
        <span slot="audit" slot-scope="record">
          <a v-if="record['IsInitInspec']>0  && record.AuditStatus !==2 && record['ReportSwitch']===0 && record.AuditStatus>=0" @click.stop="() => showPassModal(record,slot)">审核</a>
        </span>
      </a-table>
      <!--分页-->
      <!--      :show-total="(total, range) => ` (共 ${total} 条)`"
            show-size-changer show-quick-jumper-->
      <a-pagination
          class="page"
          size="small"
          v-model="pageIndex"
          :total="total"
          :pageSize="pageSize"
          @showSizeChange="getData"
          @change="getData"
      >
      </a-pagination>
    </a-card>
    <!--校核结果查看    -->
    <a-modal :width="800" v-model="isError" title="校核结果查看" :footer="null">
      <a-table
          :columns="errorColumns"
          :data-source="errorData"
          :row-key="(record, i) => i"
      ></a-table>

      <div style="text-align: center; padding-top: 20px">
        <a-button type="">
          <a @click="newDownExcel(downUrl)">下载标错报表</a>
        </a-button>
      </div>
    </a-modal>

    <!-- 审核不通过    -->
    <a-modal
        :width="800"
        v-model="noPass"
        title="查看备注"
        :pagination="false"
        :footer="isCheck"
    >
      <a-row>
        <p>数据已校核通过,请再次确认数据口径无误后，点击通过。</p>
        <a-table
            :columns="noPasscolumns"
            :data-source="noPassData"
            :rowKey="(record, index) => index"
        ></a-table>
      </a-row>
      <template slot="footer">
        <a-button type="primary" @click="CheckCircumstances('2')">通过</a-button>
        <a-button type="primary" @click="overrule()">驳回</a-button>
      </template>
    </a-modal>
    <div class="foot-pad30"></div>

    <a-modal v-model="reUploadVisible" title="设置重新上传" @ok="handleResetFormOk" @cancel="handleResetFormCancel">
      <a-form-model
          :rules="reUploadRules"
          ref="reUploadForm"
          :model="reUploadForm"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="月份" prop="YearMonth">
          <a-month-picker placeholder="选择月份" v-model="reUploadForm.YearMonth"/>
        </a-form-model-item>
        <a-form-model-item label="销售店" prop="DealerCode">
          <a-cascader :options="dealers" :allowClear="false"
              placeholder="请选择/输入销售店" v-model="reUploadForm.DealerCode"
              :show-search="true"
          />
        </a-form-model-item>
        <a-form-item label="驳回原因">
          <a-select v-model="reasonForRejection.rejectTitle" style="width: 100%">
            <a-select-option v-for="(item,i) of reasonForRejection.data" :value="item" :key="i++">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="审批意见">
          <a-textarea v-model="reasonForRejection.rejectContent" placeholder="请输入审批意见(选填)" :rows="4"/>
        </a-form-item>
      </a-form-model>
    </a-modal>
    <!-- 驳回原因    -->
    <a-modal v-model="reasonForRejection.isShow" :title="null" @ok="reasonForRejectionOk" @cancel="reasonForRejection.isShow=false">
      <div style="height: 30px">&nbsp;</div>
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="驳回原因">
          <a-select v-model="reasonForRejection.rejectTitle" style="width: 100%">
            <a-select-option v-for="(item,i) of reasonForRejection.data" :value="item" :key="i++">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="审批意见">
          <a-textarea v-model="reasonForRejection.rejectContent" placeholder="请输入审批意见(选填)" :rows="4"/>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 查看驳回原因    -->
    <a-modal v-model="AuditTypeData.isShow" :title="null" :footer="null" @cancel="AuditTypeData.isShow = false">
      <div style="height: 30px">&nbsp;</div>
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="驳回原因">
          <p>{{ AuditTypeData.RejectTitle }}</p>
        </a-form-item>
        <a-form-item label="审批意见">
          <a-textarea readonly :value="AuditTypeData.RejectContent" placeholder="暂无" :rows="4"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import { dayFormatMouth } from "@/until/dayFormat";
import moment from "moment";

export default {
  data() {
    return {
      dayFormatMouth,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      isError: false,
      isCheck: null,
      RowId: 0,
      noPass: false,
      noPassval: "",
      noPassData: [],
      dealers: [],
      downUrl: "",
      dealerMap: {},
      choseDealer: [],
      PermissionName: "",
      errorData: [],
      columns: [
        {
          title: "序号",
          width: "60px",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "区域名称",
          dataIndex: "PartName",
          key: "PartName",
        },
        {
          title: "销售店名称",
          dataIndex: "DealerName",
          key: "DealerName",
        },
        {
          title: "销售店代码",
          dataIndex: "DealerCode",
          key: "DealerCode",
          /*  //排序方法
            sorter: (a, b) => {
              let aTime = new Date(a.address).getTime();
              let bTime = new Date(b.address).getTime();
              console.log("a", a);
              return aTime - bTime;
            },*/
        },
        {
          title: "Excel下载地址",
          key: "ExcelName",
          scopedSlots: { customRender: "ExcelName" },
        },
        {
          title: "上传次数",
          key: "UploadNum",
          dataIndex: "UploadNum",
          // scopedSlots: { customRender: "tags" },
        },
        {
          title: "报表月份",
          key: "YearMonth",
          dataIndex: "YearMonth",
          scopedSlots: { customRender: "YearMonth" },

          //排序方法
          // sorter: (a, b) => {
          //   let aTime = new Date(a.YearMonth).getTime();
          //   let bTime = new Date(b.YearMonth).getTime();
          //   return aTime - bTime;
          // },
        },
        {
          title: "最新上传时间",
          key: "LastUpdateTime",
          dataIndex: "LastUpdateTime",
        },
        {
          title: "校核状态",
          key: "ReportStatus",
          dataIndex: "ReportStatus",
          scopedSlots: { customRender: "ReportStatus" },
        },
        {
          title: "审核时间",
          dataIndex: "AuditTime",
          key: "AuditTime",
        },
        {
          title: "审核状态",
          key: "AuditType", scopedSlots: { customRender: "AuditType" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
        {
          title: "审核操作",
          key: "audit",
          scopedSlots: { customRender: "audit" },
        },
      ],
      errorColumns: [
        {
          title: "序号",
          width: "60px",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "指标名称",
          dataIndex: "ErrVarName",
          key: "ErrVarName",
        },
        {
          title: "店端实际数据",
          dataIndex: "NumValue",
          key: "NumValue",
        },
        {
          title: "错误详情",
          dataIndex: "ErrDesc",
          key: "ErrDesc",
        },
      ],
      noPasscolumns: [
        {
          title: "序号",
          width: 60,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "指标名称",
          dataIndex: "KpiName",
          key: "KpiName",
        },
        {
          title: "店端实际数据",
          dataIndex: "NumValue",
          key: "NumValue",
        },
        {
          title: "理论范围值",
          dataIndex: "Limit",
          key: "Limit",
        },
        {
          title: "原因",
          dataIndex: "Memo",
          key: "Memo",
        },
      ],
      data: [],
      DealerCode: "",
      formInfo: {
        YearMonth: null,
        ValidateStatus: "",
        PartName: "",
        auditStatus: "",
        CompanyCode: "",
      },
      //上传信息
      uploadInfo: {
        NoUploadCount: 0,
        UploadCount: 0,
        NoValiCount: 0,
        PassCount: 0,
        FailCount: 0,
      },
      partList: [],
      dealerList: [],
      reUploadForm: {
        YearMonth: null,
        DealerCode: [],
      },
      reUploadRules: {
        YearMonth: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: ["blur", "change"],
          },
        ],
        DealerCode: [
          {
            required: true,
            message: "请选择销售店",
            trigger: ["blur", "change"],
          },
        ],
      },
      reUploadVisible: false,
      reasonForRejection: {
        isShow: false,
        rejectTitle: "其他",
        rejectContent: "",
        data: ["收集表数据财务费用不符合审核标准", "收集表数据销售数据不符合审核标准", "收集表数据售后数据不符合审核标准", "特殊校核备注不符合审核标准", "店端要求驳回收集表", "其他"],
      },
      AuditTypeData: {
        isShow: false,
        RejectTitle: "",
        RejectContent: "",
      },
    };
  },
  async created() {
    //计算当前月的上个月
    this.formInfo.YearMonth = this.getYearMonth();
    this.partList = await this.getPartList();
    await this.getData();
    await this.getUpdateInfo();
    await this.getDealersByRole();

  },
  methods: {
    async handleResetFormOk() {
      this.$refs.reUploadForm.validate(async(valid) => {
        if(valid) {
          let subForm = {
            YearMonth: moment(this.reUploadForm.YearMonth).format("yyyy-MM-01"),
            DealerCode: this.reUploadForm.DealerCode[1],
            rejectTitle: this.reasonForRejection.rejectTitle,
            rejectContent: this.reasonForRejection.rejectContent,
          };
          let res = await this.$Http.ResetUpload(subForm);
          if(res["Success"]) {
            this.reUploadVisible = false;
            this.$message.success(res.Data);
          } else {
            this.$message.error(res.Message);
          }
        }
      });
    },
    handleResetFormCancel() {
      this.$refs.reUploadForm.resetFields();
    },
    async reUpload() {
      this.reasonForRejection.rejectTitle = "收集表数据财务费用不符合审核标准";
      this.reasonForRejection.rejectContent = "";
      this.reUploadVisible = true;
      //获取所有经销商
      await this.getDealersByRole();
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if(!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      let dealerCount = 0;
      this.dealers = res.Data.chain().map(p => {
        dealerCount += p.Dealers.length;
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          this.dealerMap[dealer.value] = [part.value, dealer.value];
          return dealer;
        }).value();
        return part;
      }).value();
      this.hasMultipleDealer = dealerCount > 1;
    },

    selectorFilter(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    /*审核通过*/
    showCheck(data) {
      this.isCheck = true;
      this.RowId = data.Id;
    },
    /* 审核情况 1 驳回 -1 驳回 */
    async CheckCircumstances(AuditStatus) {
      let arr = {};
      arr["AuditStatus"] = AuditStatus;
      arr["RowId"] = this.RowId;
      if(AuditStatus === "-2") {
        arr["rejectTitle"] = this.reasonForRejection.rejectTitle;
        arr["rejectContent"] = this.reasonForRejection.rejectContent;
      }
      let res = await this.$Http.AuditkPI(arr);
      if(!res["Success"]) {
        this.$message.error(res.Message);
      }
      this.noPass = false;
      await this.getData();
    },
    /*驳回*/
    overrule() {
      this.reasonForRejection.isShow = true;
    },
    reasonForRejectionOk() {
      this.reasonForRejection.isShow = false;
      this.CheckCircumstances("-2");
    },
    async getAuditTypeData(data) {
      const res = await this.$Http.GetRejectDetail({
        rowId: data.Id,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      if(res.Data) {
        this.AuditTypeData.RejectTitle = res.Data.RejectTitle;
        this.AuditTypeData.RejectContent = res.Data.RejectContent;
      } else {
        this.AuditTypeData.RejectTitle = "";
        this.AuditTypeData.RejectContent = "";
      }
      this.AuditTypeData.isShow = true;
    },
    /*审核不通过*/
    async showPassModal(val, Ispecial) {
      this.noPass = !this.noPass;
      this.isCheck = Ispecial;
      this.RowId = val.Id;
      let YearMonth = val.YearMonth.toString().substr(0, 10);

      let res = await this.$Http.GetSpecialMemo({
        DealerCode: val.DealerCode,
        YearMonth: YearMonth,
      });

      this.noPassData = res.Data;
    },
    //查看报告
    GOReport(record) {
      let YearMonth = record.YearMonth.toString().substr(0, 10);
      window.open(
          "/AllDashboard?DealerCode=" +
          record.DealerCode +
          "&ReportDate=" +
          YearMonth,
          "_blank",
      );
    },
    // 查看错误文件 工具
    async showError(val) {
      this.isError = !this.isError;
      this.downUrl = val.ObjectKey;
      let YearMonth = val.YearMonth.toString().substr(0, 10);
      let res = await this.$Http.GetErrorLogs({
        DealerCode: val.DealerCode,
        YearMonth,
      });
      if(res["Success"]) {
        this.errorData = res.Data;
      }
      /*   if(this.errorData.length === 0) {
           let rcs = await this.$Http.GetErrorKpiDetail({
             DealerCode: val.DealerCode,
             YearMonth,
           });
           this.errorData = rcs.Data;
         }*/
    },
    async getUpdateInfo() {
      let self = this;
      let res = await self.$Http.GetDealerUploadInfo({
        YearMonth: self.formInfo.YearMonth,
      });
      if(res["Success"]) {
        self.uploadInfo = res.Data[0];
      } else {
        self.$message.error(res.Message);
      }
    },
    partChange(select) {
      // console.log(select);
      this.formInfo.PartName = select;
      this.getDealerList();
    },
    //获取销售店列表
    async getDealerList() {
      let self = this;
      let res = await self.$Http.GetPartList({
        PartName: self.formInfo.PartName,
      });
      self.dealerList = res.Data;
      self.dealerList.unshift({ DealerName: "全部", DealerCode: "" });
      if(self.dealerList.length > 0) {
        //默认选择第一个销售店
        self.formInfo.CompanyCode = self.dealerList[0].DealerCode;
        //获取数据
        // self.getData();
      }
    },

    async getPartList() {
      let self = this;
      let res = await self.$Http.GetPartListByPage({
        pageSize: 10000,
        pageIndex: 1,
      });
      if(res.Data.length > 0) {
        return res.Data;
      } else {
        return [];
      }
    },
    getYearMonth() {
      let currentDate = new Date();
      //当前年
      let currentYear = currentDate.getFullYear();
      //当前月
      let currentMonth = currentDate.getMonth();
      if(currentMonth == 0) {
        currentMonth = "12";
        currentYear = currentYear - 1;
      }
      if(currentMonth < 10) {
        currentMonth = "0" + currentMonth;
      }
      return currentYear + "-" + currentMonth + "-01";
    },
    /* 接口下载EXCEL*/
    async newDownExcel(val) {
      let res = await this.$Http.FileShareLink({
        objectkey: val,
      });
      if(res.Success) {
        window.open(res.Data);
      }
    },
    async getData() {
      let self = this;
      let res = await self.$Http.GetFactoryKpiDataListByPage(
          self._.extend(self.formInfo, {
            total: self.total,
            pageIndex: self.pageIndex,
            pageSize: self.pageSize,
            //  AuditType: 1,
          }),
      );
      if(res.Success) {
        self.data = res.Data;
        self.total = res.TotalCount;
        self.pageIndex = res.PageIndex;
        self.pageSize = res.PageSize;
      } else {
        self.$message.error("错误");
      }
      /*获取当前的用户*/
      let ras = await this.$Http.GetOwnerPermissionName();
      if(ras.Success) {
        this.PermissionName = ras.Data[0].PermissionName;
      } else {
        this.$message.error(res.Message);
      }
    },
    dealerChange() {
      this.formInfo.CompanyCode = this.choseDealer[1];
      this.getData();
    },
    onDateChange(day, strDay) {
      this.formInfo.YearMonth = strDay + "-01";
      this.getUpdateInfo();
      this.getData();
    },
    reset() {
      this.formInfo.YearMonth = this.getYearMonth();
      this.formInfo.ValidateStatus = "";
      this.formInfo.PartName = "";
      this.formInfo.CompanyCode = "";
      this.getData();
    },
    validatestatusChange(val) {
      this.formInfo.ValidateStatus = val;
      this.getData();
    },
    auditStatusChange(val) {
      this.formInfo.auditStatus = val;
      this.getData();
    },
    doSearch() {
      this.pageIndex = 1;
      this.getData();
    },
    async downLoad() {
      window.open(
          `/apis/DealerKpi/KpiData/DownDealerUploadInfo?yearMonth=${this.formInfo.YearMonth}&partName=${this.formInfo.PartName}&companyCode=${this.formInfo.CompanyCode}&validateStatus=${this.formInfo.ValidateStatus}`,
      );
    },
    tagsColor(tags) {
      switch (tags) {
      case "校核成功":
        return "#19A47A";
      case "特殊校核成功":
        return "#19A47A";
      case "校核失败":
        return "#FD595A";
      case "等待校核":
        return "gold";
      default:
        return "#856AFF";
      }
    },
    async ExportRejectDetail() {
      const res = await this.$Http.ExportRejectDetail({
        yearMonth: this.formInfo.YearMonth,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(res);
      link.download = moment(this.formInfo.YearMonth).format("yyyy年M月") + `销售店上传数据驳回明细总览.xls`;
      link.click();
    },
  },
};
</script>

<style lang="less" scoped>
// @import url('./dataList.less');
.top {
  margin-bottom: 8px;
  margin-top: 8px;
  background: #e6f7ff;
  height: 38px;
  line-height: 38px;
  padding: 0 24px;
  border: 1px solid #bae7ff;

  span {
    padding-right: 36px;
    color: #444444;

    b {
      color: #24619e;
      font-weight: 500;
      text-align: center;
      }
    }

  a {
    float: right;
    padding-right: 20px;
    }

  .sea {
    span {
      padding-left: 36px;
      color: red;
      }
    }
  }

.action {
  a {
    padding: 0 5px;
    }
  }
</style>
