import moment from "moment";
import underscore from "underscore";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";

const kpiCode = {
    monthRankCodes: ["A1402155", "A0302001",],
    yearRankCodes: ["A1402155", "A0302001", "C0208001", "A0302001"],
    monthStandardPush: [
        "C0207021", "C0305011", "C0408004", "C0408021", "A0107014", "A0304034",
        "B0107002", "B0104202", "B0302001", "B0302121", "B0104202", "B0105113", "A0304018",
    ],
    monthPushCodes: ["A0204008", "A0302001", "A1302032", "A1303036", "A1303024", "A1303028",
        "C0406004", "C0406012", "C0407001", "C0206001", "C0408004", "C0402004", "C0402007",
        "C0408002", "C0408021", "C0207021", "C0305011", "C0207022", "C0203020", "C0207028",
        "A0202025", "C0207029", "A0107014", "C0302191", "A0106026", "A0105071", "A0105072",
        "A0107002","A0303312", "A0303313", "A0303314", "A0303315", "A0303316",  "B0107002",
        "A0303318", "A0303319", "A0303320", "A0303321", "A0303322", "A0303323", "A0303324",
        "A0302021", "A0302022", "A0302023", "A0302024", "A0304026", "A0402006", "B0105182",
        "A0404045", "A0404111", "A0304034", "A1303043", "A1302012", "A0303317", "B0403006",
        "A1302013", "A1303080", "A1302014", "A1302111", "A1303081", "A1303082", "B0403005",
        "B0302001", "B0302121", "B0104202", "B0105113", "B0103099", "B0104187", "B0105108",
        "B0104226", "B0105132", "B0105161", "B0105181", "B0302034", "B0302035", "B0302036",
        "B0302039", "B0302047", "B0303024", "B0302038", "B0304014", "B0403003", "B0403004",
        "A0303311", "A0106027", "A0106028", "B0303025", "B0303026", "A1303007", "B0107002",
        "A0106029", "A0106030", "A0106031", "A0303316", "A0106032", "A1303083", "A0102084",
        "A0106034", "A0106035", "A0106036", "A0106037", "B0303031", "A0303325",
        "A0106038", "A0106039", "A0303324", "A0106040", "B0303008", "A0106041",

    ],
    richCodes: ["C0208001", "A0302001", "C0202025", "C0303030", "B0303052", "A0106023", "A0107001",
        "A0204008", "A0202039", "A0205035", "A0202007", "A0202043", "A0205034", "C0202262",
        "C0202263", "A0202024", "A0204032", "C0407004", "A0107002", "A0107004", "B0106026",
        "B0106027", "C0207022", "C0207028", "C0207021", "E1202153", "E1202173", "B0104242",
        "C0408019", "C0408004", "C0408024", "C0107006", "A0404002", "A0404001", "C0107003",
        "C0106003", "C0107002", "C0203020", "C0205006", "C0302002", "C0202026", "C0205010",
        "C0205005", "C0205021", "A1302012", "A1302013", "A1302014", "A1302111", "A0302021",
        "A1302115", "A1302019", "A1302028", "A1303071", "A0302022", "E1202093", "E1202113",
        "E0302162", "C0302121", "A0302281", "A0303351", "C0302122", "A0302282", "A0303352",
        "A0104090", "A0103001", "A0104044", "A0103024", "A0106024", "A0105001", "A0106001",
        "A0104067", "A0304024", "A0303200", "A0304025", "A0303201", "A0304026", "A0304027",
        "A0304151", "A0304030", "A0303311", "A0303312", "A0106027", "A0106028", "A0303313",
        "A0303314", "A0303315", "A0106029", "A0106030", "A0106031", "A0303316", "A0106032",
        "A0303318", "A0303319", "A0106034", "A0106035", "A0303320", "A0106036", "A0303321",
        "A0106037", "A0303322", "A0106038", "A0303323", "A0106039", "A0303324", "A0106040",
        "A0304004", "A0304005", "A0304006", "A0304007", "A0304008", "A0304009", "A0304011",
        "A0304012", "A0304013", "A0304014", "A0304015", "A0304016", "A0304017", "B0104244",
        "A0402013", "A0402019", "A0402025", "A0402031", "A0402037", "A0402043", "A0402055",
        "A0402061", "A0402116", "A0402122", "A0402128", "A0402134", "A0402148", "A0402014",
        "A0402020", "A0402026", "A0402032", "A0402038", "A0402044", "A0402056", "A0402062",
        "A0402117", "A0402123", "A0402129", "A0402135", "A0402149", "A0402015", "A0402021",
        "A0402027", "A0402033", "A0402039", "A0402045", "A0402057", "A0402063", "A0402118",
        "A0402124", "A0402130", "A0402136", "A0402150", "A0402016", "A0402022", "A0402028",
        "A0402034", "A0402040", "A0402046", "A0402058", "A0402064", "A0402119", "A0402125",
        "A0402131", "A0402137", "A0402171", "A0404061", "A0404063", "A0404064", "A0404065",
        "A0404066", "A0404068", "A0404069", "A0404070", "A0404071", "A0404072", "A0404073",
        "A0404074", "C0302233", "C0302314", "C0302315", "B0104202", "B0104205", "B0104246",
        "B0103097", "B0103099", "B0105113", "B0104165", "B0104187", "B0105108", "B0302017",
        "B0302021", "B0302019", "B0302075", "B0102095", "B0102099", "B0102097", "B0102114",
        "B0103104", "B0103108", "B0103106", "B0103113", "B0104259", "B0303101", "B0105150",
        "B0304001", "B0303062", "B0302037", "B0303019", "B0302047", "B0302038", "B0104250",
        "B0303026", "B0302042", "B0303031", "B0303008", "A0403002", "A0403001", "A0304018",
        "B0304003", "B0302028", "B0302032", "B0303017", "C0302317", "C0302210","A0303325",
        "C0302213", "C0302318", "B0105133", "B0303036", "B0104256", "B0104283", "B0402005",
        "B0402006", "B0402007", "B0402008", "B0403003", "B0403004", "B0403005", "B0403006",
        "C0208001", "C0302121", "A1402155", "A0302001", "B0107002",
        "A0302281", "A0303351", "C0302122", "A0302282", "A0303352",
        "A0303312", "A0303313", "A0303314", "A0303315",
        "A0303316", "A0303318", "A0303319", "A0303320", "A0303321",
        "A0303322", "A0303323", "A0303324", "A0304004", "A0304005",
        "A0304006", "A0304007", "A0304008", "A0304009", "A0304011", "C0302233", "C0302314", "C0302315",
        "A0304012", "A0304013", "A0304014", "A0304015", "A0304016", "A0304017", "B0302001", "B0302121", "B0104202", "B0105113",
        "A0402013", "A0402019", "A0402025", "A0402031", "A0402037",
        "A0402043", "A0402055", "A0402061", "A0402116", "A0402122",
        "A0402128", "A0402134", "A0402148", "A0402014", "A0402020",
        "A0402026", "A0402032", "A0402038", "A0402044", "A0402056",
        "A0402062", "A0402117", "A0402123", "A0402129", "A0402135",
        "A0402149", "A0402015", "A0402021", "A0402027", "A0402033",
        "A0402039", "A0402045", "A0402057", "A0402063", "A0402118",
        "A0402124", "A0402130", "A0402136", "A0402150", "A0402016",
        "A0402022", "A0402028", "A0402034", "A0402040", "A0402046",
        "A0402058", "A0402064", "A0402119", "A0402125", "A0402131",
        "A0402137", "A0402171", "A0404061", "A0404062", "A0404063",
        "A0404064", "A0404065", "A0404066", "A0404068", "A0404069",
        "A0404070", "A0404071", "A0404072", "A0404073", "A0404074",
        "A0107014", "A0304034", "A1303043", "A1302013", "A1302014", "A1303044",
        "A1302111", "A0302021", "E1202091", "E1202111", "E1202151",
        "E1202171", "E0302160", "E1202093", "E1202113", "E1202153",
        "E1202173", "E0302162", "C0302121", "A0302281", "A0303351",
        "C0302122", "A0302282", "A0303352", "A1302115", "A1302019",
        "A1302028", "A1302032", "A0302022", "C0302317", "C0302210",
        "C0302213", "C0302318", "B0402005", "B0402006", "B0402007",
        "B0402008", "B0302017", "B0102095", "B0103104", "B0302021",
        "B0302019", "B0302075", "B0102099", "B0102097", "B0102114",
        "B0103108", "B0103106", "B0103113", "B0302003", "B0105114", "B0106027",
        "B0102001", "B0102050", "B0103023", "B0103063", "B0105191", "B0302004", "B0102002",
        "B0102051", "B0103024", "B0103064", "B0105192", "B0302005", "B0102003", "B0102052",
        "B0103025", "B0103065", "B0105193", "B0302006", "B0102004", "B0102053", "B0103026",
        "B0103066", "B0105194", "B0302071", "B0102008", "B0102057", "B0103036", "B0103076",
        "B0105197", "B0302008", "B0102006", "B0102055", "B0103028", "B0103068", "B0105196",
        "B0302009", "B0102007", "B0102056", "B0103029", "B0103069", "B0105198", "B0302010",
        "B0102012", "B0102061", "B0103030", "B0103070", "B0105199", "B0302013", "B0102015",
        "B0102064", "B0103033", "B0103073", "B0105202", "B0302014", "B0102016", "B0102065",
        "B0103034", "B0103074", "B0105203", "B0303025", "B0303071", "B0303072", "B0303006",
        "B0303029", "B0303032", "B0302001","B0103007","B0103047","B0104043","B0104063","B0105182"
    ],
};
const columns12 = [
    {
        key: "name", title: "指标名称", dataIndex: "name", width: "10%",
        scopedSlots: { customRender: "name" },
    },
    { key: "Unit", title: "单位", dataIndex: "Unit", width: "6%" },
    { key: "3", title: "3月", dataIndex: "3", width: "6%" },
    { key: "4", title: "4月", dataIndex: "4", width: "6%" },
    { key: "5", title: "5月", dataIndex: "5", width: "6%" },
    { key: "6", title: "6月", dataIndex: "6", width: "6%" },
    { key: "7", title: "7月", dataIndex: "7", width: "6%" },
    { key: "8", title: "8月", dataIndex: "8", width: "6%" },
    { key: "9", title: "9月", dataIndex: "9", width: "6%" },
    { key: "10", title: "10月", dataIndex: "10", width: "6%" },
    { key: "11", title: "11月", dataIndex: "11", width: "6%" },
    { key: "12", title: "12月", dataIndex: "12", width: "6%" },
    { key: "1", title: "1月", dataIndex: "1", width: "6%" },
    { key: "2", title: "2月", dataIndex: "2", width: "6%" },
    { key: "YearSum", title: "2021年累计", dataIndex: "YearSum", width: "6%" },
    { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
];

/*
* 隐藏列
* @columns 要拷贝的列数组
* @i int 要隐藏的列号
* */
function customRenderD(columns, i) {
    const arr = JSON.parse(JSON.stringify(columns));
    arr[i].colSpan = 0;
    arr[i].customRender = () => {
        return { attrs: { colSpan: 0 } };
    };
    return arr;
}

/*
*
* 12个月的推移处理
*
* */
function mouth12(columns, reportDate) {
    let time = moment(reportDate).add(-12, "M");
    for (let i = 2; i <= 13; i++) {
        let month = time.add(1, "M").format("M");
        columns[i].key = month;
        columns[i].dataIndex = month;
        columns[i].title = month + "月";
    }
}

function monthData(monthPush, tableData) {
    tableData.table.forEach((d, i = 2) => {
        let obj = {};
        let ut = "";
        if (!monthPush) {
            tableData.columns.forEach(v => {
                obj[v.key] = 0;
            });
            obj.Unit = "";
            obj.name = d.name;
        } else {
            obj.name = d.name;
            if (typeof d.kpiCode === "string") {
                if(monthPush[d.kpiCode] !== undefined)  ut = monthPush[d.kpiCode].Unit ?? '';
                if (d.name === "影豹系列终端销量") ut = "台";
                if (d.name === "影豹系列单车毛利") ut = "元";
            } else {
              if(monthPush[d.kpiCode[0]] !== undefined)  ut = monthPush[d.kpiCode[0]].Unit ?? '';
              d.kpiCode.forEach(k => {
                if(ut === ""){
                  if(monthPush[k] !== undefined) ut = monthPush[k].Unit;
                }
              });
            }
            if (d.ut) {
                if (d.ut === "1") ut = "万元";
            }
            if(ut === "") {
              if(d.name.indexOf("毛利") > 0) ut = "元";
              if(d.name.indexOf("销量")  > 0) ut = "台";
            }
            obj.Unit = ut;
            obj.indentation = d.indentation ?? 0;

            tableData.columns.forEach((v, j) => {
                if (j > 1) {
                    if (typeof d.kpiCode === "string") {
                        if(monthPush[d.kpiCode] ){
                         obj[v.key] = UnitDataFormat (ut,monthPush[d.kpiCode][v.key] ?? 0);
                        }else {
                         obj[v.key] = 0
                        }
                    } else {
                        let n = 0;
                        d.kpiCode.forEach(k => {
                          if(monthPush[k] !== undefined) n = Number(monthPush[k][v.key] ?? 0);
                            obj[v.key] = Number((Number(obj[v.key] ?? 0)) + n).toFixed(3);
                        });
                        obj[v.key] = Number(obj[v.key]).toFixed(0);
                    }
                }
            });
        }
        tableData.data.push(obj);
    });
}

function UnitDataFormat(Unit, val) {
    val = val ?? "0";
    if (Unit === "元" || Unit === "天" || Unit === "台" || Unit === "个") {
        return ThousandYuan(val);
    } else if (Unit === "次") {
        return Number(val).toFixed(1);
    } else if (Unit === "倍") {
        return Number(val).toFixed(2);
    } else if (Unit === "%") {
        return percentage(val);
    } else if (Unit === "万元") {
        return tenThousandYuan(val);
    } else {
        return val;
    }
}

function monthGetData(tableMouthData, monthPush, reportDate, i) {
    tableMouthData.data = [];
    //   tableMouthData.columns = JSON.parse(JSON.stringify(columns12)); // 复制列
    tableMouthData.columns = columns12.map(d => Object.assign({}, d));// 复制列
    tableMouthData.columns = customRenderD(columns12, i); // 隐藏列
    mouth12(tableMouthData.columns, reportDate); // 动态列
    monthData(monthPush, tableMouthData); // 加载数据
}

const columnsNorm = [
    {
        key: "name", title: "指标名称", dataIndex: "name",
        scopedSlots: { customRender: "name" },
    },
    { key: "Unit", title: "单位", dataIndex: "Unit" },
    {
        key: "Current", title: "当月值", dataIndex: "Current",
        scopedSlots: { customRender: "current" },
    },
    { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
    { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
    { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
    { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
    { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
    { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
];

function tableIndex(tableData, rich) {
    tableData.data = [];
    tableData.table.forEach(d => {
        let obj = {};
        if (!rich) {
            obj.indentation = 0;
            obj.Unit = "-";
            obj.name = d.name;
            obj.Current = 0;
            obj.MarchAverage = 0;
            obj.LastYear = 0;
            obj.RegionalStandard = 0;
            obj.NationwideStandard = 0;
            obj.IndexRange = 0;
            obj.PercentileSort = 0;
            obj.CurrentYear = 0;
        } else {
            let ut = rich[d.kpiCode].Unit;
            if (d.ut) {
                if (d.ut === "1") ut = "万元";
            }

            if (d.indentation) {
                obj.indentation = d.indentation;
            } else {
                obj.indentation = 0;
            }
            obj.Unit = ut;
            obj.name = d.name;
            // if (d.kpiCode === "A0304025") console.log(rich[d.kpiCode].RegionalStandard);
            obj.Current = UnitDataFormat(ut, rich[d.kpiCode].Current);
            if (d.kpiCode === "A0304025") obj.Current = Number(Number(Number(rich[d.kpiCode].Current) * 100).toFixed(1)).toFixed(1) + "%";
            obj.MarchAverage = UnitDataFormat(ut, rich[d.kpiCode]["PreThreeMonthAvg"]);
            obj.LastYear = UnitDataFormat(ut, rich[d.kpiCode].LastYear);
            obj.RegionalStandard = UnitDataFormat(ut, rich[d.kpiCode].RegionalStandard);
            obj.NationwideStandard = UnitDataFormat(ut, rich[d.kpiCode].NationwideStandard);
            if (d.kpiCode === "A0202043") obj.RegionalStandard = "-";
            if (d.kpiCode === "A0202043") obj.NationwideStandard = "-";
            obj.IndexRange = d.IndexRange ?? "-";
            obj.PercentileSort = rich[d.kpiCode].PercentileSort;
            obj.CurrentYear = UnitDataFormat(ut, rich[d.kpiCode].YearSum);
        }
        tableData.data.push(obj);
    });
}

function tableGetData(tableData, rich) {
    tableData.data = [];
    tableData.columns = JSON.parse(JSON.stringify(columnsNorm)); // 复制列
    //  tableData.columns = columnsNorm.map(d => Object.assign({}, d));
    tableIndex(tableData, rich);
}

/*
*
* 12个月的Chart推移处理
*
* */
function mouth12Chart(myChart, monthPush, monthStandardPush, reportDate) {
    let date = moment(reportDate).add(-12, "M");
    let months = underscore.range(12).map(() => date.add(1, "M").format("M"));
    myChart.rows = months.map(m => {
        let row = {};
        row["日期"] = m + "月";
        myChart.kpiCode.forEach(k => {
            let data = {};
         let value = null;
         if(!monthPush || !monthStandardPush) {
          value = 0;
         } else {
          k.Name.indexOf("全国") > 0 ? data = monthStandardPush[k.Code] : data = monthPush[k.Code];
          /*  if (k.Code === "C0207022")
                console.log(monthPush[k.Code]["6"]);*/
          if(data && data[m]) {
           value = data[m];
          }
         }

            row[k.Name] = value;
        });
        return row;
    });
}

// chart 配置
const fontSize = 12;
const barMaxWidth = "20";
const settingsLabel = {
    show: true, // 显示数字
    //   position: "top",
    textStyle: {
        fontSize, color: "black",
    },
    formatter: function(params) {
        if (params.componentSubType === "line") {
            return Number((Number(params.data) * 100).toFixed(2)).toFixed(1) + "%";
        } else {
            return Number(params.data ?? 0).toFixed(0);
        }
    },
};
const optionsNationwide = {
    yAxis: yAxisUnit("倍", 0),
    xAxis,
    series: [
        {
            barMaxWidth,
            label: {
                formatter: function(params) {
                    return (params.value).toFixed(0);
                },
            },
        }, { barMaxWidth },
        {
            lineStyle: {
                //   color: '#5470C6',
                width: 3,
                //   type: 'dashed'
            },
            symbolSize: 12,
            itemStyle: {
                borderWidth: 1,
                //  borderColor: '#db0a7d',
            },
        },
        {
            lineStyle: {
                //   color: '#5470C6',
                width: 3,
                //   type: 'dashed'
            },
            symbolSize: 12,
            itemStyle: {
                borderWidth: 1,
                //  borderColor: '#db0a7d',
            },
        },
    ],
};

const funnelLabel = {
    show: true,
    position: "inside",
    textStyle: {
        color: "black", fontSize,
    },
    formatter: function(params) {
        return `${params.name}: ${Number(params.value).toFixed(0)}`;
    },
};
const tooltip = {
    trigger: "axis",
    formatter: function(params) {
        let arr = [];
        params.forEach((d) => {
            let valData = d.value ?? "0";
            let minvalue = 0;
            if (d.componentSubType === "bar") {
                minvalue = Number((Number(valData) / 10000).toFixed(3)).toFixed(2) ?? "-";
                arr.push({
                    val: `<span style="background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;"></span>${d.seriesName}:${minvalue}万元`,
                });
            } else {
                minvalue = Number((Number(valData) * 100).toFixed(2)).toFixed(1) ?? "-";

                arr.push(
                    {
                        val: `<span style="background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;"></span>${d.seriesName}:${minvalue}%`,
                    },
                );
            }
        });
        //        arr.reverse(); // 加栽顺序反一下
        let v1 = "";
        arr.forEach(v => v1 = v1 + v.val + "<br />");
        return `${params[0].axisValue} <br /> ${v1}`;
    },
};
const funnelTooltip = {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c}",
};
const yAxis = [
    {
        type: "value",
        name: "万元",
        splitLine: {
            show: true,
            lineStyle: {
                type: "dashed",
            },
        },
        axisLabel: {
            formatter: (d) => {
                return Number(d / 10000).toFixed(2);

            },
        },
    },
    {
        type: "value",
        name: "%",
        axisLabel: {
            formatter: (d) => {
                return Number(d * 100).toFixed(0);
            },
        },
    },
];

function yAxisUnit(ut, i) {
    return [
        {
            type: "value",
            name: ut,
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                },
            },
            axisLabel: {
                formatter: (d) => {
                    return Number(d).toFixed(i);
                },
            },
        },
        {
            type: "value",
            name: "%",
            axisLabel: {
                formatter: (d) => {
                    return Number(d * 100).toFixed(1);
                },
            },
        },
    ];
}

const xAxis = {
    axisLabel: {
        textStyle: {
            fontSize, color: "black",
        },
        interval: 0,
    },
    axisLine: {
        show: true,
    },
    // X轴刻度
    /* axisTick: {
        show:true
    }*/
};
export {
    kpiCode, columns12, columnsNorm, settingsLabel, tooltip, yAxis, xAxis,
    barMaxWidth, funnelLabel, funnelTooltip, yAxisUnit, optionsNationwide,
    customRenderD, mouth12, monthData, monthGetData, tableIndex, tableGetData, mouth12Chart,
};
