<template>
  <section>
    <a-card class="card">
      <a-input-search class="onSearch"
          placeholder="权限名称"
          enter-button="查询"
          size="large"
          v-model.trim="keyword"
          @search="onSearch(1)"
      />
    </a-card>
    <div>
      <a-tooltip class="display-block tooltip">
        <template slot="title">
          先添加权限范围，再配置菜单功能!
        </template>
        <p><span>* </span>添加权限流程：先添加权限范围，然后再配置菜单功能</p>
      </a-tooltip>
      <div class="pddding-top-10">
        <a-card>
          <a-button type="primary" icon="plus" @click="addAuth">
            添加权限范围
          </a-button>

          <p class="pddding-top-10">
            <a-table :columns="columns" :data-source="data" :pagination="paginationParam" @change="tableChange" rowKey="key">
                            <span slot="menu" slot-scope="record">
                                <a-tag @click="viewMenu(record)">配置菜单功能	</a-tag>
                            </span>
              <span slot="view" slot-scope="record, index">
                                <a @click="viewUser(record)">查看用户</a>
                            </span>
              <span slot="action" slot-scope="record, index">
                                 <a-tag @click="editAuth(record)">编辑</a-tag>
                <a-popconfirm
                    title="确定删除吗？"
                    @confirm="() => delAuth(record.PermissionCode)"
                >
            <a-button size="small" type="primary">删除</a-button>
          </a-popconfirm>
                            </span>
            </a-table>
          </p>
        </a-card>
      </div>
    </div>
    <div class="foot-pad30"></div>
    <a-modal
        :title="`编辑权限名称`"
        :visible="visible"
        @ok="handleOk"
        @cancel="visible = false"
    >
      <a-input
          type="text"
          name="txtPermission"
          v-model.trim="Title"
          placeholder="请输入权限范围名称（必填）"
      />
    </a-modal>
  </section>
</template>
<script>
const columns = [
  {
    title: "序号",
    width: "60px",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "权限名称",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "菜配置单",
    key: "menu",
    width: 200,
    scopedSlots: { customRender: "menu" },
  },
  {
    title: "查看用户",
    //  dataIndex: 'view', // 加入数据绑定，SLOT就只传入这个数据，不加就传入所有
    key: "view",
    width: 200,
    scopedSlots: { customRender: "view" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  //   name: 'rightsManage',
  data() {
    return {
      data: [],
      keyword: "",
      columns,
      Title: "",
      permissionId: "",
      visible: false,
      //分页参数
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    //   获取数据列表
    async onSearch() {
      //
      let res = await this.$Http.commonapi({
        W: this.keyword.replace(/\s*/g, ""),
        page: this.paginationParam.current,
        pagesize: this.paginationParam.pageSize,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.data = [];
      this.paginationParam.total = res.ApiParamObj[1][0]["totalRow"];

      res.ApiParamObj[0].forEach((item, resKey) => {
        this.data.push({
          key: resKey.toString(),
          name: item.PermissionName,
          PermissionCode: item.PermissionCode,
          PermissionType: item.PermissionType,
          PermissionId: item.PermissionId,
        });
      });
    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      this.paginationParam.current = pagination.current;
      this.onSearch(pagination.current);
    },
    addAuth(key) {
      this.$router.push("/addPermissions");
    },
    viewMenu(key) {
      this.$router.push(
          {
            path: "/menuPermissions",
            query: {
              name: key.name,
              PermissionCode: key.PermissionCode,
              PermissionId: key.PermissionId,
            },
          },
      );
    },
    viewUser(key) {
      this.$router.push(
          {
            path: "/viewPermissions",
            query: {
              PermissionCode: key.PermissionCode,
            },
          });
    },
    editAuth(key) {
      this.visible = true;
      this.Title = key.name;
      this.permissionId = key.PermissionId;
      //   权限配置功能暂时取消
      /* this.$router.push({
         path: "/addPermissions",
         query: {
           PermissionCode: key.PermissionCode,
           PermissionId: key.PermissionId,
           edit: true,
         }
       })*/
    },
    async delAuth(key) {
      let self = this;
      let res = await self.$Http.DeletePermission({ permissionCode: key });
      if(res.Success) {
        self.$message.success(res.Data);
        self.onSearch();
      } else {
        self.$message.error(res.Message);
      }
    },
    async handleOk() {
      if(!this.Title) this.$message.error("名称必须填写!");
      if(!this.permissionId) this.$message.error("错误,请联系管理员!");
      let res = await this.$Http.UpdatePermissionName({
        permissionName: this.Title,
        permissionId: this.permissionId,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.$message.success("编辑成功!");
      this.visible = false;
      await this.onSearch(1);
    },
  },
};
</script>
<style lang="less" scoped>
.tooltip {
  width: 30%;
  font-size: 14px;
  color: #D27735;
  padding-top: 20px;

  span {
    font-size: 18px;
    font-weight: bold;
    }
  }

.card {
  min-height: 100px;

  .onSearch {
    width: 400px;
    }

  }

p {
  color: @primary-color;
  cursor: pointer;

  span {
    cursor: pointer;
    }
  }

</style>
