const Auth_API = {
  // 登陆
  getLogin: {
    method: 'get',
    url: '/BasicSetting/BasicSet/Login'
  },
  // 登出
  LoginOut: {
    method: 'get',
    url: '/BasicSetting/BasicSet/LoginOutFromSSO'
  },
  // 权限列表
  commonapi: {
    method: 'get',
    //  url: '/api/commonapi'
    url: '/api/commonapi?API=Jason_GetPermissionByProjectCodePaging&PermissionType=BASIC&sorts=+PermissionCode+desc'
  },// 编辑权限名称
  UpdatePermissionName: {
    method: 'get',
    url: '/BasicSetting/BasicSet/UpdatePermissionName'
  },
  // 获取带全部的大区
  GetPart_All: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetPart_All'
  },
  // 根据大区选择小区 传入 partCode
  GetAreaByPart_All: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetAreaByPart_All'
  },
  // 根据大区选择小区 传入 partCode
  GetCityByArea_All: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetCityByArea_All'
  },
  // 获取部门列表
  GetDeparmentNo: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetDeparmentNo'
  },
  // 获取部门列表
  GetPositionNo: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetPositionNo'
  },
  // 获取经销商列表
  GetCompanyListByPage: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetCompanyListByPage'
  },
  // 添加经销商列表
  AddPermission: {
    method: 'post',
    url: '/BasicSetting/BasicSet/AddPermission'
  },
  // 添加经销商列表
  EditPermission: {
    method: 'post',
    url: '/BasicSetting/BasicSet/EditPermission'
  },
  // 添加经销商列表
  DeletePermission: {
    method: 'get',
    url: '/BasicSetting/BasicSet/DeletePermission'
  },
  // 分页查看权限所属用户
  ViewPermissionUsers: {
    method: 'get',
    url: '/BasicSetting/BasicSet/ViewPermissionUsers'
  },
  // 编辑所属用户权限范围
  GetEditPermissionInfo: {
    method: 'get',
    url: '/BasicSetting/BasicSet/GetEditInfo'
  },

  // 编辑所属用户权限范围
  AddMenu: {
    method: 'post',
    url: '/BasicSetting/BasicSet/AddMenu'
  },
  // 获取自己权限角色名称
  GetOwnerPermissionName: {
    method: 'get',
    url: '/DealerKpi/KpiData/GetOwnerPermissionName'
  },
  //

}
export default Auth_API
