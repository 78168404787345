const GROUP_REPORT_API = {
    // 获取小组对标报告分类
    getTypeNameList: {
        method: 'get',
        url: '/api/commonapi?API=TypeNameListGet_common&TypeClass=1'
    },
    // 获取BM报告
    getBmReport: {
        method: 'get',
        url: '/XBmReport/Calc'
    },
    // 获取BM报告
    getIndustryAnalyzeReport: {
        method: 'get',
        url: '/XIndustryAnalyze/GetReportData'
    },
    // 获取对标报告
    getGroupReport: {
        method: 'get',
        url: '/XCalculator/GroupReportSearch'
    },
    // 公共API.
    commonApi: {
        method: 'get',
        url: '/api/commonapi'
    },
    // 获取对标小组明细
    getDealerGroupDetail: {
        method: 'get',
        url: '/XGroupManage/GetGroupDetail'
    },
    // 修改对标小组明细
    modifyDealerGroupDetail: {
        method: 'post',
        url: '/api/commonapi?API=DealerDBGroupAdd_robin'
    },
    // 查询对标小组
    queryDealerGroup: {
        method: 'get',
        url: '/XGroupManage/Query'
    },
    // 保存对标小组
    saveDealerGroup: {
        method: 'post',
        url: '/XGroupManage/Save'
    },
    // 删除对标小组
    DeleteDealerGroup: {
        method: 'get',
        url: '/XGroupManage/Delete'
    },
    // 复制对标小组
    CopyDealerGroup: {
        method: 'get',
        url: '/XGroupManage/Copy'
    },
    // 修改对标小组明细
    modifyIndexData: {
        method: 'post',
        url: '/api/commonapi?API=Stark_UpdateKpiFormula'
    },
    // 修改指标状态
    modifyIndexDataStatus: {
        method: 'get',
        url: "/api/commonapi?API=Stark_UpdateKpiExcelContent"
    },
    // 获取在线填报模板
    getInputTemplate: {
        method: 'get',
        url: '/XIndexDataInput/GetTemplate'
    },
    // 获取当前登录人经销商信息
    getCurrDealerInfo: {
        method: 'get',
        url: '/XIndexDataInput/GetDealerInfo'
    },
    // 是否为管理员
    isAdmin: {
        method: 'get',
        url: '/XGroupManage/IsAdmin'
    },
    // 指标试算，显示计算步骤
    ShowCalcStep: {
        method: 'post',
        url: '/XCalculator/ShowCalcStep'
    },
    // 指标重算并保存
    KpiReCalcAndSave: {
        method: 'post',
        url: '/XCalculator/KpiReCalcAndSave'
    },
    // 修改对标小组明细
    GetDealersByRole: {
        method: 'get',
        url: '/XCalculator/GetDealersByRole'
    },
    // 获取自定义指标类型
    GetCustomReportTypes: {
        method: 'get',
        url: '/XCustomReport/GetTypes'
    },
    // 获取自定义指标
    GetCustomReportCodes: {
        method: 'get',
        url: '/XCustomReport/GetCodes'
    },
    // 获取自定义指标类型
    GetCustomReportAllKpiCodes: {
        method: 'get',
        url: '/XCustomReport/GetAllKpiCodes'
    },
    // 查询自定义数据报表
    StartGetCustomReport: {
        method: 'post',
        url: '/XCustomReport/startGetReport',
    },
    // 查询任务执行结果
    GetProcessResult: {
        method: 'get',
        url: '/XProcess/GetResult',
        disableWait: true
    },
    // 下载自定义数据报表
    StartDownloadCustomReport: {
        method: 'post',
        url: '/XCustomReport/StartDownloadReport',
    },
    // 保存自定义报表模板
    SaveCustomReportTemplate: {
        method: 'post',
        url: '/XCustomReport/SaveTemplate'
    },
    // 查询自定义报表模板
    QueryCustomReportTemplate: {
        method: 'post',
        url: '/XCustomReport/QueryTemplate'
    },
    // 获取自定义报表模板
    GetCustomReportTemplate: {
        method: 'post',
        url: '/XCustomReport/GetTemplate'
    },
    // 删除自定义报表模板
    DeleteCustomReportTemplate: {
        method: 'post',
        url: '/XCustomReport/DeleteTemplate'
    },
    // 查询行业标杆
    SearchIndustryStandard: {
        method: 'get',
        url: '/IndustryStandard/Search'
    },
    // 删除行业标杆
    DeleteIndustryStandard: {
        method: 'post',
        url: '/IndustryStandard/Delete'
    },
    // 保存行业标杆
    SaveIndustryStandard: {
        method: 'post',
        url: '/IndustryStandard/Save'
    },
    // 查询行业标杆报告
    IndustryStandardReport: {
        method: 'post',
        url: '/IndustryStandard/Report'
    },
    // 查询行业标杆报告明细
    IndustryStandardReportDetail: {
        method: 'post',
        url: '/IndustryStandard/ReportDetail'
    },
    // 获取省/地区/
    GetDealersTreeByRole: {
        method: 'post',
        url: '/XCalculator/GetDealersTreeByRole'
    },
}
export default GROUP_REPORT_API
