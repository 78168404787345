<template>
  <section>
    <div>
      <div class="chart" ref="chart"></div>
    </div>
    <div class="chart" ref="chart_gauge"></div>
  </section>
</template>
<script>
import * as echarts5 from "echarts5";

export default {
  data() {
    return {
      op: {
        title: {
          text: "ECharts 入门示例",
        },
        tooltip: {},
        legend: {
          data: ["销量"],
        },
        xAxis: {
          data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20],
          },
        ],
      },
      myData: [],
      op_gauge: {
        series: [{
          type: "gauge",
          center: ["50%", "60%"],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          splitNumber: 12,
          itemStyle: {
            color: "#FFAB91",
          },
          progress: {
            show: true,
            width: 30,
          },
          
          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 30,
            },
          },
          axisTick: {
            distance: -45,
            splitNumber: 5,
            lineStyle: {
              width: 2,
              color: "#999",
            },
          },
          splitLine: {
            distance: -52,
            length: 14,
            lineStyle: {
              width: 3,
              color: "#999",
            },
          },
          axisLabel: {
            distance: -20,
            color: "#999",
            fontSize: 20,
          },
          anchor: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: "60%",
            lineHeight: 40,
            height: "15%",
            borderRadius: 8,
            offsetCenter: [0, "-15%"],
            fontSize: 60,
            fontWeight: "bolder",
            formatter: "{value} °C",
            color: "auto",
          },
          data: [{
            value: 20,
          }],
        },
          
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            itemStyle: {
              color: "#FD7347",
            },
            progress: {
              show: true,
              width: 8,
            },
            
            pointer: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [{
              value: 20,
            }],
            
          },
        ],
      },
    };
  },
  created() {
    this.myData = [35, 20, 36, 10, 10, 20];
    
  },
  methods: {
    Gauge() {
      /*使用 echarts5 */
      let myChartG = echarts5.init(this.$refs.chart_gauge);
      let random = (Math.random() * 60).toFixed(1) - 0;
      this.op_gauge.series[0].data[0].value = random;
      this.op_gauge.series[1].data[0].value = random;
      myChartG.setOption(this.op_gauge);
      
    },
  },
  mounted() {
    /*使用 echarts4 */
    let myChart = this.$echarts.init(this.$refs.chart);
    myChart.setOption(this.op);
    setInterval(() => {
      this.Gauge();
    }, 2000);
  },
};
</script>

<style scoped>
.chart {
  width: 500px;
  height: 500px;
  }
</style>

