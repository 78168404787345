<template>
  <a-row :gutter="[24,24]">
    <a-col :span="24">
      <a-card style="padding-top: 12px">
        <a-descriptions bordered :column="3" style="margin-bottom: 20px;">
          <a-descriptions-item label="盈利店数量">{{ GetIndexValue("盈利店数量") }}</a-descriptions-item>
          <a-descriptions-item label="店均税前利润">{{ GetIndexValue("店均税前利润", "万元") }}</a-descriptions-item>
          <a-descriptions-item label="店均前后端产值比">{{ GetIndexValue("店均前后端产值比") }}</a-descriptions-item>
        </a-descriptions>
        <div class="info">
          <p>
            1、店均总体情况：店均总收入
            <span v-html='GetDesc(data,"C0406004", ["上升至", "下降至"])'></span>
            ，总成本
            <span v-html='GetDesc(data,"C0406012", ["增至", "降至"])'></span>
            ，总费用为
            <span v-html='GetDesc(data,"C0206001", ["增至", "降至"])'></span>
            ，店均税前利润
            <span v-html='GetDesc(data,"C0408004")'></span>
            ，
            <br/>
            <span style="padding-left: 1.5em;font-weight: normal !important;">较上月 </span>
            <span v-html='GetDesc(data,"C0408004", ["盈利", "亏损"], false,true)'></span>
            ，本年店均累计
            <span v-html="currentYearSumProfitDesc"></span>
            ；盈利店
            <span style="font-weight: bold">{{ GetIndexValue("盈利店数量") }}</span>
            家，亏损店
            <span style="font-weight: bold">{{ areaData["DataSuccess"] - GetIndexValue("盈利店数量") }}</span>
            家，盈利店占比
            <span style="font-weight: bold">{{ gainRate }}</span>
            ，较上月
            <span v-html="contrastLastMonthProfitRateDesc"></span>
            。
          </p>
          <p>
            2、店均销售业务：店均销售业务收入
            <span v-html='GetDesc(data,"A0104090", ["上升至", "下降至"])'></span>
            ，单台毛利贡献
            <span v-html='GetDesc(data,"A0107004")'></span>
            ，店均盈亏保本台次
            <span v-html='GetDesc(data,"C0208001")'></span>
            ，店均终端
            <span v-html='GetDesc(data,"A0302001")'></span>
            ，两者相差
            <span v-html='CalcDiff(data,"C0208001","A0302001")'></span>
            。
          </p>
          <p>
            3、店均售后业务：店均售后业务收入
            <span v-html='GetDesc(data,"B0104202", ["上升至", "下降至"])'></span>
            ，售后业务毛利
            <span v-html='GetDesc(data,"B0105113", ["增至", "降至"])'></span>
            ；零服吸收率
            <span v-html='GetDesc(data,"B0107002")'></span>
            ；售后业务毛利率
            <span v-html='GetDesc(data,"B0106026")'></span>
            。
          </p>
          <p id="one">
            4、前后端产值比：
            <span style="font-weight: bold">{{ GetIndexValue("店均前后端产值比") }}</span>
            ，其中：销售业务收入较上月
            <span v-html='GetDesc(data,"A0104090", ["上升至", "下降至"])'></span>
            ，售后业务收入较上月
            <span v-html='GetDesc(data,"B0104202", ["上升至", "下降至"])'></span>
            。
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card title="1、店均总体情况">
        <a-col :span="isWin">
          <monthReportTableComponent :columns-data="columnsData"
              :table-data="data.tableData_1_1_1"
          >
          </monthReportTableComponent>
        </a-col>
        <a-col :span="isWin" class="ppTop30">
          <chartComponent :chart-data="data.chartData_1_3_2"/>
        </a-col>
        <a-col :span="24">
          <chartComponent :chart-data="data.specialChartData_1_3_1"/>
        </a-col>
      </a-card>
      <div id="two"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="2、店均销售业务">
        <a-col :span="isWin">
          <monthReportTableComponent :columns-data="columnsData"
              :table-data="data.tableData_1_2_1"
          >
          </monthReportTableComponent>
        </a-col>
        <a-col :span="isWin" class="ppTop30">
          <chartComponent :chart-data="data.chartData_1_3_3"/>
        </a-col>
      </a-card>
      <div id="there"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="3、店均售后业务">
        <a-col :span="isWin">
          <monthReportTableComponent :columns-data="columnsData"
              :table-data="data.tableData_1_3_1"
          >
          </monthReportTableComponent>
        </a-col>
        <a-col :span="isWin" class="ppTop30">
          <chartComponent :chart-data="data.chartData_1_3_4"/>
        </a-col>
      </a-card>
      <div id="four"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="4、前后端产值比">
        <a-col class="ppTop30">
          <chartComponent :chart-data="data.chartData_1_4_1"/>
        </a-col>
      </a-card>
    </a-col>
    <a-anchor v-if="reportParams.isClose">
      <div class="close" @click="closeAnchor()">
        <a-icon type="close"/>
        关闭
      </div>
      <a-anchor-link href="#one" title="1、店均总体情况"/>
      <a-anchor-link href="#two" title="2、店均销售业务"/>
      <a-anchor-link href="#there" title="3、店均售后业务"/>
      <a-anchor-link href="#four" title="4、前后端产值比"/>
    </a-anchor>
  </a-row>
</template>

<script>
import monthReportTableComponent from "../../../../components/monthReportTableComponent";
import chartComponent from "../../../../components/chartComponent";
import { CalcDiff, descFormat, GetDesc, getEmptyTemplate, numFormat } from "@/until/reportCalculate";
import { GetWindows } from "@/until/dayFormat";
import { columnsImport, overViewData } from "../data.js";
import moment from "moment";

export default {
  props: {
    reportParams: {
      isClose:true,
    },
    reportDate: String,
    areaData: {},
    isRegionReport: Boolean, // 是否地区报告,true就是地区
  },
  components: { monthReportTableComponent, chartComponent },
  data() {
    return {
      isWin: 24,
      isClose: true,
      emptyTemplate: getEmptyTemplate(),
      columnsData: columnsImport.columnsImportDataPC,
      data: overViewData,
      contrastLastMonthProfitRateDesc: "<b>-</b>",
    };
  },
  computed: {
    gainRate: function() {
      return this.areaData["DataSuccess"] <= 0 ? "0%"
          : (this.GetIndexValue("盈利店数量") / this.areaData["DataSuccess"] * 100).toFixed(0) + "%";
    },
    currentYearSumProfitDesc: function() {
      let sumValue = this.areaData["DataSuccess"] <= 0 ? 0 : Number(this.GetIndexValue("年初至今税前利润累计").replace(/,/g, "")) / this.areaData.DataSuccess;
      return descFormat(sumValue, 0, "元", ["盈利", "亏损"], false, true);
    },
  },
  watch: {
    reportParams: {
      handler: function() {
        columnsImport.loadData(this, this.data, this.reportParams);
        this.contrastLastMonthProfitRate();
      },
      deep: true,
    },
  },
  async created() {
    if(this.isRegionReport) {
      this.columnsData = columnsImport.columnsImportDataPcRegionReport;
    }
    this.isWin = GetWindows();
    await columnsImport.loadData(this, this.data, this.reportParams);
    await this.contrastLastMonthProfitRate();
  },
  methods: {
    closeAnchor() {
      // eslint-disable-next-line vue/no-mutating-props
      this.reportParams.isClose = false;
    },
    GetIndexValue(key, unit) {
      let value = 0;
      if(this.areaData && this.areaData["IndexValues"]) {
        value = this.areaData["IndexValues"][key];
      }
      return numFormat(key, value, unit);
    },
    GetDesc,
    CalcDiff,
    async contrastLastMonthProfitRate() {
      // 计算盈利店占比
      this.contrastLastMonthProfitRateDesc = "<b>-</b>";
      let params = Object.assign({}, this.reportParams);
      params.ReportDate = moment(params.ReportDate).add(-1, "M").format("YYYY-MM-01");
      let res = await this.$Http.GetDealerInputStatistics(params);
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      let before = res.Data["DataSuccess"] <= 0 ? 0 : res.Data["IndexValues"]["盈利店数量"] / res.Data["DataSuccess"];
      let current = this.areaData["DataSuccess"] <= 0 ? 0 : this.GetIndexValue("盈利店数量") / this.areaData["DataSuccess"];
      this.contrastLastMonthProfitRateDesc = descFormat(current, before, "%", ["上升", "下降"], true);
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";
</style>
