const ITINERANT_API = {
    //巡回员列表查看
    GetItinerantManPageList: {
        method: "post",
        url: "/DealerKpi/KpiData/GetItinerantManPageList",
    },

    //新增巡回员
    InsertItinerantMan: {
        method: "post",
        url: "/DealerKpi/KpiData/InsertItinerantMan",
    },

    //修改巡回员
    UpdateItinerantMan: {
        method: "post",
        url: "/DealerKpi/KpiData/UpdateItinerantMan",
    },

    // 删除巡回员
    DeleteItinerantMan: {
        method: "post",
        url: "/DealerKpi/KpiData/DeleteItinerantMan",
    },
    //获取巡回员所属于经销商
    GetDealerByMan:{
        method: "get",
        url: "/DealerKpi/KpiData/GetDealerByMan",
    },
    //人员模糊搜索
    GetUserByKey:{
        method: "get",
        url: "/DealerKpi/KpiData/GetUserByKey",
    },
    //获取巡回员类型列表
    GetItinerantType:{
        method: "post",
        url: "/DealerKpi/KpiData/GetItinerantType",
    },
    //巡回员模板下载
    ItinerantExport:{
        method: "get",
        url: "/DealerKpi/Third/ItinerantExport",
    }

}
export default ITINERANT_API;
