import GroupReport from "../views/GroupReport/GroupReport";
import TypeNameManage from "../views/GroupReport/TypeNameManage";
import GroupManage from "../views/GroupReport/GroupManage";
import GroupIndexManage from "../views/GroupReport/GroupIndexManage";
import IndexDataInput from "../views/GroupReport/IndexDataInput";
import IndexFormulaManage from "../views/GroupReport/IndexFormulaManage";
import diagnosisReport from "../views/diagnosisReport/view";
import StoreManagement from "../views/monthReport/analysisReport/StoreManagement";
import PushDownReport from "@/views/PushDownReport/PushDownReport";
import CountryReport from "@/views/countryMonthReport/analysisReport/Report";
import RegionReport from "@/views/regionMonthReport/analysisReport/Report";
import investorReport from "@/views/investorReport/view";
import ShowCalcStep from "@/views/GroupReport/ShowCalcStep";
import customReport from "@/views/customReport/customReport";
import IndustryBenchmark from "@/views/IndustryBenchmark/IndustryBenchmark";
import IndustryTable from "@/views/IndustryBenchmark/IndustryTable";
import IndustryManage from "@/views/IndustryBenchmark/IndustryManage";
import ShopAnalysis from "@/views/monthReport/shopAnalysis/shopAnalysis";
import BmReport from "@/views/GroupReport/BmReport";
import IndustryAnalyze from "@/views/GroupReport/IndustryAnalyze";

let reportTaberRouter = [
  // 指标试算
  {
    path: "/ShowCalcStep",
    component: ShowCalcStep,
  },

  // 全国经营报告
  {
    path: "/CountryReport",
    component: CountryReport,
  },
  // 区域经营报告
  {
    path: "/RegionReport",
    component: RegionReport,
  },
  // 店端经营分析报告
  {
    path: "/StoreManagement",
    component: StoreManagement,
  },
  /* 2021-12-27 新版业绩报告 storePerformanceReport
  * */
  {
    path: "/nationalPerformanceReport",
    component: () => import("@/views/performanceReport/nationalPerformanceReport"),
  },
  {
    path: "/regionalPerformanceReport",
    component: () => import("@/views/performanceReport/regionalPerformanceReport"),
  },
  {
    path: "/storePerformanceReport",
    component: () => import("@/views/performanceReport/storePerformanceReport"),
  },

  // 投资人简报
  {
    path: "/investorReport",
    component: investorReport,
  },
  /*对标报告分析*/
  {
    path: "/groupReport",
    component: GroupReport,
  },
  {
    path: "/bmReport",
    component: BmReport,
  },
  {
    path: "/industryAnalyze",
    component: IndustryAnalyze,
  },
  /*对标报告类别管理*/
  {
    path: "/TypeNameManage",
    component: TypeNameManage,
  },
  /*对标小组管理*/
  {
    path: "/GroupManage",
    component: GroupManage,
  },
  /*对标指标管理*/
  {
    path: "/GroupIndexManage",
    component: GroupIndexManage,
  },

  /*指标在线填报*/
  {
    path: "/IndexDataInput",
    component: IndexDataInput,
  },
  /*指标公式管理*/
  {
    path: "/IndexFormulaManage",
    component: IndexFormulaManage,
  },
  //诊断报告
  {
    path: "/diagnosisReport",
    component: diagnosisReport,
  },
  // 自定义数据报表
  {
    path: "/customReport",
    component: customReport,
  },
  // 业绩运营分析
  {
    path: "/PushDownReport",
    component: PushDownReport,
  },
  // 行业标杆维护
  {
    path: "/IndustryManage",
    component: IndustryManage,
  },
  // 行业标杆报告
  {
    path: "/IndustryBenchmark",
    component: IndustryBenchmark,
  },
  // 行业标杆明细
  {
    name: "IndustryTable",
    path: "/IndustryTable",
    component: IndustryTable,
  },
  // 店端经营业绩分析报告 新版
  {
    path: "/shopAnalysis",
    component: ShopAnalysis,
  },
];

export default reportTaberRouter;
