const lastFilterCodes = [
 "A1402172", "A1402173", "A1402174", "A1402175",
];
const currentFilterCodes = [
 "E1202091+E1202092+E1202093+E1202094", "E1202092", "A1303045", "E1202091+E1202092+E1202093+E1202094"
];

export {
 lastFilterCodes, currentFilterCodes,
};
