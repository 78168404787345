import axios from 'axios'
import service from './contactApi'
import AuthAPI from './authApi'
import {Toast} from 'vant'
import GROUP_REPORT_API from "@/api/groupReportApi";
import HUMAN_MANNAGE_API from "@/api/humanMannage";
import BG_MANNAGE_API from "@/api/bgMannage";
import AREA_MANNAGE_API from "@/api/areaMannage";
import CITY_MANNAGE_API from "@/api/cityMannage";
import POSITION_MANNAGE_API from "@/api/positionMannage";
import DEPARTMENT_MANNAGE_API from "@/api/departmentMannage";
import COMPANY_MANNAGE_API from "@/api/companyMannage"
import MSG_MANNAGE_API from "@/api/msgMannage"
import UPLOAD_API from '@/api/upload'
import Data_List_API from "@/api/dataList";
import MONTH_REPORT_API from "@/api/monthReport";
import DIAGNOSISREPORT_API from "@/api/diagnosisReport";
import ITINERANT_API from "@/api/itinerantMannage";
import InvestorReport_API from "@/api/investorReport";
import QUJIAN_API from "@/api/qujian";
import IMPROVEPLAN_API from "@/api/improvePlan";
import DashBord_API from "@/api/DashBord"
import INCOMEANALYSIS_API from "@/api/incomeAnalysis"
import DEALERACCOUNT_MANNAGE_API from "@/api/dealerAccountMannage"
import router from "@/router/router";

import NProgress from "nprogress";

NProgress.configure({
    easing: "spinner",  // 动画方式
    speed: 1000,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 100, // 自动递增间隔
    minimum: 0.3, // 初始化时的最小百分比
});

let waitingCount = 0;

function startWaiting() {
    if (waitingCount++ <= 0) {
        NProgress.start();
        Toast.loading({
            mask: false,
            duration: 0,// 一直存在
            forbidClick: true, // 禁止点击
            loadingType: "spinner",
            message: '加载中...'
        })
    }
}

function endWaiting(isFeedback) {
    if (isFeedback) {
        if (--waitingCount <= 0) {
            NProgress.done();
            Toast.clear()
            waitingCount = 0;
        }
    } else {
        // 延迟关闭等待框，避免在多个连续请求间隔间闪烁
        setTimeout(() => endWaiting(true), 100)
    }
}

export function initHttp(baseUrl) {
    // service 循环遍历输出不同的请求方法
    let instance = axios.create({
        baseURL: baseUrl,
        timeout: 60000
    })
    const Http = {baseUrl}; // 包裹请求方法的容器

    const myApi = [service, AuthAPI, GROUP_REPORT_API, HUMAN_MANNAGE_API, BG_MANNAGE_API, AREA_MANNAGE_API, CITY_MANNAGE_API,
        POSITION_MANNAGE_API, DEPARTMENT_MANNAGE_API, COMPANY_MANNAGE_API, MSG_MANNAGE_API,
        UPLOAD_API, Data_List_API, MONTH_REPORT_API, DIAGNOSISREPORT_API, ITINERANT_API, InvestorReport_API, QUJIAN_API, IMPROVEPLAN_API, DashBord_API,
        INCOMEANALYSIS_API, DEALERACCOUNT_MANNAGE_API]

    for (let ii in myApi) {
        // 请求格式/参数的统一
        for (let key in myApi[ii]) {
            let api = myApi[ii][key]; // url method
            // async 作用：避免进入回调地狱
            Http[key] = async function (
                params, // 请求参数 get：url，put，post，patch（data），delete：url
                isFormData = false,// 标识是否是form-data请求
                config = {} // 配置参数
            ) {
                let newParams = {}
                config.disableWait = api.disableWait;
                if (api.responseType)
                    config.responseType = api.responseType;

                //  content-type是否是form-data的判断
                if (params && isFormData) {
                    newParams = new FormData()
                    for (let i in params) {
                        newParams.append(i, params[i])
                    }
                } else {
                    newParams = params
                }
                // 不同请求的判断
                let response = {}; // 请求的返回值
                if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
                    try {
                        response = await instance[api.method](api.url, newParams, config)
                    } catch (err) {
                        response = err
                    }
                } else if (api.method === 'delete' || api.method === 'get') {
                    config.params = newParams
                    try {
                        response = await instance[api.method](api.url, config);
                        //  console.log("undefined:  " + response) response === isempty() ||
                        if (typeof (response) === undefined) {
                            this.$message.error("远程错误，请联系管理员！");
                        }
                    } catch (err) {
                        this.$message.error("远程错误，请联系管理员！");
                        response = err;

                    }
                }
                return response; // 返回响应值
            }
        }
    }

    // 请求拦截器
    instance.interceptors.request.use(config => {
        if (!config.disableWait)
            startWaiting();
        return config
    }, (error) => {
        // 请求错误
        if (!error.config.disableWait)
            endWaiting();
        Toast('请求错误，请求稍后重试')
    })
    // 响应拦截器
    instance.interceptors.response.use(res => {
        // 请求成功
        if (!res.config.disableWait)
            endWaiting();
        return res.data
    }, (error) => {
        if (!error.config.disableWait)
            endWaiting();
        if (error.response.status === 401) {
            let loginPath = "/login";
            if (loginPath !== window.location.pathname) {
                router.push({path: loginPath});
            }
            return;
        }
        Toast('请求错误，请求稍后重试')
    })

    return Http;
}

const Http = initHttp("/apis");
const HttpStaging = initHttp("https://bdmtest.gacmotor.com/apis");

export {HttpStaging};
export default Http;
