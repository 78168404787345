const DEALERACCOUNT_MANNAGE_API = {
    //经销商账户列表
    QueryDealerAccountManage: {
        method: 'get',
        url: '/XDealerAccountManage/Query'
    },
    //创建经销商账户
    CreateDealerAccountManage: {
        method: 'get',
        url: '/XDealerAccountManage/Create'
    },

}
export default DEALERACCOUNT_MANNAGE_API
