<template>
    <section>
      <div style="display: flex;justify-content: space-between;">
        <div>
          <a-tooltip placement="topLeft" :title="tips" autoAdjustOverflow>
          <span>{{ rateDate.title }}  <a-icon v-if="tips" type="question-circle-o"/></span>
           <div  :class="tips?'title-num3':'title-num2'">{{ rateDate.cont }}</div>
          </a-tooltip>
        </div>
        <div style="display: flex;flex-direction: column;align-items: flex-end;">
          <a-progress type="circle" :width="70" strokeColor="#FF786A" :percent="getPercent(rateDate.percent)" :format="percent=> formatPercent(percent)"/>
          <div v-if="rateDate.title === 'AB类客户数'">
            占有效保有客户数比
          </div>
        </div>
      </div>
    </section>

</template>

<script>

export default {
  name: "rateComponent",
  props: {
    rateDate: {},
      tips:null,
  },

  methods: {
      getPercent(value){
          //放大倍数
          let times = 1
          if (this.rateDate.title === "速动比率" || this.rateDate.title ==="流动资产周转率"){
              times = 100
          }
          return Number(value) * times
      },
    formatPercent(percent) {
      if (this.rateDate.title === "AB类客户数" || this.rateDate.title === "客户维系率"|| this.rateDate.title === "有效保有客户数占档案客户数比"|| this.rateDate.title === "客户流失率") {
        return Number(percent).toFixed(1) + "%";

      }
      else if( this.rateDate.title === "速动比率" || this.rateDate.title ==="流动资产周转率"){
          let rNUm = (percent/100);
          if (rNUm >=1){
              return "√"
          }else {
              return rNUm
          }
      }
      else {
        return percent;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../common";
</style>
