<template>
  <section>
    <a-row :gutter="[0,18]">
      <a-col :span="24">
        <a-card>
          <div class="search">
            <ol>
              <span style="display:block; float: left; width: 70px;text-align: right;">报告组：</span>
              <a-checkable-tag v-for="item in tagData" :key="item.Id" v-model="item.IsSelect" @change="tagSelect(item)">
                <a-tooltip placement="top" :title="item.IsPrivate ?  '私有模板':'公用模板'" arrow-point-at-center>
                  {{ item.Name }}
                </a-tooltip>
              </a-checkable-tag>
              <a-popconfirm :ok-text="'删除'"
                            :title="`是否删除 ${selectedTag.Name} 模板` "
                            @confirm="() => tagDel()">
                <a-tag v-if="selectedTag.IsPrivate || isAdmin" color="#f40">删除{{ selectedTag.Name }}</a-tag>
              </a-popconfirm>
            </ol>
            <ul>
              <li v-if="isAdmin">
                <span>模板所属：</span>
                <p style="text-align: left;padding-top: 5px">
                  <a-switch checked-children="私有模板" un-checked-children="公用模板" v-model="selectedTag.IsPrivate"/>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <span>报告时间：</span>
                <p>
                  <a-range-picker style="line-height: 40px"
                                  format="YYYY-MM" :placeholder="['开始月份', '结束月份']"
                                  :value="search.dateRange" :mode="search.dataMode"
                                  @change="dateHandleChange" @panelChange="dateHandlePanelChange"
                  />
                </p>
              </li>
              <li>
                <span>范围：</span>
                <p>
                  <el-cascader style="width: 100%;" :options="ranges"
                               v-model="choseRangesBind"
                               :props="search.rangesCascadeProps" clearable filterable collapse-tags
                               :show-all-levels="false"
                               popper-class="myClass"
                  ></el-cascader>
                </p>
              </li>
              <li>
                <span>指标：</span>
                <p>
                  <el-cascader style="width: 100%;" :options="codes"
                               v-model="search.choseCodes"
                               :props="search.codesCascadeProps"
                               clearable filterable collapse-tags
                               popper-class="myClass"
                  ></el-cascader>
                </p>
              </li>
              <li>
                <p>
                  <a-button type="primary" @click="showData">搜索</a-button>
                  <a-button type="primary" @click="download">导出Excel</a-button>
                  <a-button @click="resetSearch">重置</a-button>
                  <a-popconfirm :ok-text="selectedTag.Id ? '编辑':'添加'"
                                :title="`是否${selectedTag.Id ? '编辑':'添加'}模板`"
                                @confirm="() => tagSave()">
                    <a-tooltip placement="top" :title="selectedTag.Id  ?  '编辑当前选项做为模板':'添加当前选项做为模板'"
                               arrow-point-at-center>
                      <a-button v-if="selectedTag.IsPrivate || isAdmin" type="">
                        {{ selectedTag.Id ? "编辑" : "添加" }}模板
                      </a-button>
                    </a-tooltip>
                    <a-tooltip placement="top" :title="'添加当前选项做为模板'" arrow-point-at-center>
                      <a-button v-if="!isAdmin &&  !selectedTag.Id">添加模板</a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </p>
              </li>
            </ul>
          </div>
        </a-card>
      </a-col>
      <a-col :span="24" style="clear:both;">
        <a-card v-if="tables.length>0">
          <a-tabs>
            <a-tab-pane v-for="table in tables" :key="table.rangeName" :tab="table.rangeName">
              <a-table :columns="table.columns" :data-source="tableData"
                       :rowKey="(record, index) => index" :pagination="true" bordered
                       :scroll="{ x: 'max-content',y: false}"></a-table>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
    </a-row>
    <a-modal v-model="isAdd" title="添加模板" okText="添加" @ok="tagSave">
      <p>
        <a-input placeholder="请输入模板名称" v-model="selectedTag.Name"/>
      </p>
      <p v-if="selectedTag.IsPrivate || isAdmin">
        <a-switch checked-children="私有模板" un-checked-children="通用模板" v-model="selectedTag.IsPrivate"/>
      </p>
    </a-modal>
    <a-modal :title="null" :visible="isProcess" :centered="true" :footer="null" @cancel="cancel">
      <div style="background: #fff; padding: 10px">
        <p>{{ dataProcess.Tip }}</p>
        <a-progress :percent="dataProcess.Process" :stroke-color="{ '0%': '#ff0000', '100%': '#87d068',}"/>
      </div>
    </a-modal>
  </section>
</template>

<script>
import {formatDataNumber} from "@/until/reportCalculate";
import moment from "moment";

export default {
  name: "customReport",
  data() {
    return {
      isAdd: false,
      isAdmin: false,
      isProcess: false,
      timer: null,
      tagData: [],
      selectedTag: {},
      tables: [],
      tableData: [],
      ranges: [],
      codes: [],
      dataProcess: {
        Process: 0,
        Tip: "",
      },
      search: {
        dateRange: [],
        dataMode: ["month", "month"],
        choseRanges: [],
        choseCodes: [],
        rangesCascadeProps: {
          multiple: true,
          expandTrigger: "hover"
        },
        codesCascadeProps: {
          multiple: true,
          expandTrigger: "hover",
        },
      },
    };
  },
  computed: {
    choseRangesBind: {
      get() {
        return this.search.choseRanges;
      },
      set(choseRanges) {
        if (this.search.choseRanges.length <= 0) {
          this.dimensionChanged(choseRanges[0][0]);
        } else if (choseRanges.length <= 0) {
          this.dimensionChanged(0);
        }
        this.search.choseRanges = choseRanges;
      }
    },
    simpleChoseRanges: {
      get() {
        let ranges = [];
        if (this.search.choseRanges.length > 0) {
          let dimension = this.search.choseRanges[0].length - 1;
          while (--dimension > 0) {
            ranges.push(null);
          }
          ranges.push(this.search.choseRanges.chain().map(range => range.chain().last().value()).value());
        }
        return ranges;
      },
      set(newValue) {
        if (newValue == null) {
          newValue = [[]];
        }
        let dimension = newValue.length;
        this.dimensionChanged(dimension);
        let choseRange = [dimension];
        while (--dimension > 0) {
          choseRange.push(undefined);
        }
        this.search.choseRanges = newValue.chain().last().value().map(range => {
          let item = Object.assign([], choseRange);
          item.push(range);
          return item;
        });
      },
    }
  },
  async created() {
    await this.getAdminInfo();
    await this.getDealersTreeByRole();
    await this.getCustomReportAllKpiCodes();
    await this.tagGet();
  },
  methods: {
    cancel() {
      this.isProcess = false;
      clearTimeout(this.timer);
      this.timer = null;
    },
    async getAdminInfo() {
      let res = await this.$Http.getCurrDealerInfo();
      if (!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.isAdmin = res.Data["IsAdmin"];
    },
    dateHandleChange(value) {
      this.search.dateRange = value;
    },
    dateHandlePanelChange(value, mode) {
      this.search.dateRange = value;
      this.search.dataMode = [mode[0] === "date" ? "month" : mode[0], mode[1] === "date" ? "month" : mode[1]];
    },
    resetSearch() {
      this.search.dateRange = [];
      this.search.choseRanges = [];
      this.search.choseCodes = [];
    },
    async getDealersTreeByRole() {
      let res = await this.$Http.GetDealersTreeByRole();
      if (!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      let rangesJson = JSON.stringify(res.Data);
      this.ranges = [{
        label: "区域",
        children: this.filterRanges(JSON.parse(rangesJson), 1),
        value: 1
      }, {
        label: "省份",
        children: this.filterRanges(JSON.parse(rangesJson), 2),
        value: 2
      }, {
        label: "城市",
        children: this.filterRanges(JSON.parse(rangesJson), 3),
        value: 3
      }, {
        label: "经销商",
        children: this.filterRanges(res.Data, 4),
        value: 4
      }];
    },
    filterRanges(ranges, level) {
      if (--level === 0) {
        ranges.forEach(range => range.children = undefined);
      } else {
        ranges.forEach(range => {
          range.value = undefined;
          this.filterRanges(range.children, level);
        })
      }
      return ranges;
    },
    dimensionChanged(dimension) {
      if (dimension <= 0) {
        this.ranges.forEach((range) => {
          range.disabled = false;
        });
      } else {
        this.ranges.forEach((range) => {
          range.disabled = dimension !== range.value;
        });
      }
    },
    async getCustomReportAllKpiCodes() {
      let res = await this.$Http.GetCustomReportAllKpiCodes();
      if (!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.codes = res.Data;
    },
    getParams() {
      let params = {};
      if (this.search.dateRange.length !== 2) {
        this.$message["warn"]("请选择报告时间");
        return;
      }
      params.reportDateStart = this.search.dateRange[0].format("YYYY-MM-01");
      params.reportDateEnd = this.search.dateRange[1].format("YYYY-MM-01");
      if (this.search.choseRanges.length <= 0) {
        this.$message["warn"]("请选择范围");
        return;
      }
      params.ranges = this.simpleChoseRanges;
      if (this.search.choseCodes.length <= 0) {
        this.$message["warn"]("请选择指标");
        return;
      }
      params.filterCodes = this.search.choseCodes.chain().map(d => d[2]).value();
      return params;
    },
    async showData() {
      let params = this.getParams();
      if (params == null) {
        return;
      }
      let rbs = await this.$Http.StartGetCustomReport(params);
      if (!rbs["Success"]) {
        this.$message.error(rbs.Message);
        return;
      }
      this.isProcess = true;
      this.dataProcess.Process = 0;
      this.dataProcess.Tip = "请求正在处理...";
      setTimeout(() => this.loadTableResult(rbs.Data), 500);
    },
    async loadTableResult(taskId) {
      let res = await this.$Http.GetProcessResult({taskId});
      if (!res["Success"]) {
        this.$message.error(res["Message"]);
        this.isProcess = false;
        return;
      }
      this.dataProcess.Process = parseInt(res.Data["Process"]);
      if (res.Data["Tip"])
        this.dataProcess.Tip = res.Data["Tip"];
      if (res.Data["Process"] === 100) {
        this.isProcess = false;
        res = JSON.parse(res.Data["ResultJson"]);
        if (!res["Success"]) {
          this.$message.error(res["Message"]);
          return;
        }
        this.getTableData(res.Data);
        return;
      }
      this.timer = setTimeout(() => this.loadTableResult(taskId), 500);
    },
    getTableData(data) {
      this.tables = [];
      this.tableData = [];
      data["TableData"].forEach(data => formatDataNumber(data, data.KpiName));
      this.tables = data["ReportRanges"].chain().map(reportRange => {
        let table = {rangeName: reportRange.RangeName};
        let fixedPreColumn = reportRange.DealerGroups.length > 11;
        table.columns = [
          {
            title: "序号",
            dataIndex: "key",
            key: "key",
            width: 60,
            align: "center",
            fixed: fixedPreColumn,
          },
          {
            title: "指标名称",
            dataIndex: "KpiName",
            key: "KpiName",
            width: 200,
            fixed: fixedPreColumn,
          },
          {
            title: "单位",
            dataIndex: "Unit",
            key: "Unit",
            width: 80,
            fixed: fixedPreColumn,
          },
          {
            title: "标杆值",
            dataIndex: reportRange.RangeName + "_标杆值Text",
            key: reportRange.RangeName + "_标杆值Text",
            align: "right",
            width: 100,
            fixed: fixedPreColumn,
          },
          {
            title: "均值",
            dataIndex: reportRange.RangeName + "_均值Text",
            key: reportRange.RangeName + "_均值Text",
            align: "right",
            width: 100,
            fixed: fixedPreColumn,
          }, ...reportRange.DealerGroups.chain().map(kv => {
            let column = {};
            column.title = kv.Name;
            column.dataIndex = kv.Id + "Text";
            column.key = kv.Id + "Text";
            column.width = 100;
            column.align = "right";
            return column;
          }).value()];
        return table;
      }).value();

      this.tableData = data["TableData"].chain().map((d, i) => {
        let arr = {};
        arr["key"] = i + 1;
        Object.keys(d).forEach(f => {
          arr[f] = d[f];
        });
        return arr;
      }).value();
    },
    async download() {
      let params = this.getParams();
      if (params == null) {
        return;
      }
      let rbs = await this.$Http.StartDownloadCustomReport(params);
      if (!rbs["Success"]) {
        this.$message.error(rbs.Message);
        return;
      }
      this.isProcess = true;
      this.dataProcess.Process = 0;
      this.dataProcess.Tip = "请求正在处理...";
      setTimeout(() => this.getDownloadExcelResult(rbs.Data), 500);
    },
    async getDownloadExcelResult(taskId) {
      let res = await this.$Http.GetProcessResult({taskId});
      if (!res["Success"]) {
        this.$message.error(res["Message"]);
        this.isProcess = false;
        return;
      }
      this.dataProcess.Process = parseInt(res.Data["Process"]);
      if (res.Data["Tip"])
        this.dataProcess.Tip = res.Data["Tip"];
      if (res.Data["Process"] === 100) {
        this.isProcess = false;
        res = JSON.parse(res.Data["ResultJson"]);
        if (!res["Success"]) {
          this.$message.error(res["Message"]);
          return;
        }
        window.open(res.Data["FileUrl"]);
        return;
      }
      this.timer = setTimeout(() => this.getDownloadExcelResult(taskId), 500);
    },
    async tagSave() {
      if (!this.selectedTag.Name) {
        this.isAdd = true;
      } else {
        if (!this.selectedTag.Name) {
          this.$message.error("名称必须填写");
          return;
        }
        let obj = {};
        if (this.selectedTag.Id) obj.Id = this.selectedTag.Id;
        obj.Name = this.selectedTag.Name;
        if (this.selectedTag.IsPrivate) obj.IsPrivate = this.selectedTag.IsPrivate;
        if (!this.isAdmin) obj.IsPrivate = true;
        obj.Template = {};
        if (this.search.dateRange.length === 2) {
          obj.Template.ReportDate = this.search.dateRange.chain().map(date => date.format("YYYY-MM-01")).value();
        }
        if (this.search.choseRanges.length > 0) {
          obj.Template.Ranges = this.simpleChoseRanges;
        }
        if (this.search.choseCodes.length > 0) {
          obj.Template.KpiCodes = this.search.choseCodes.chain().map(d => d[2]).value();
        }
        let res = await this.$Http.SaveCustomReportTemplate(obj);
        if (res["Success"]) {
          this.isAdd = false;
        }
        await this.tagGet();
      }
    },
    async tagGet() {
      let res = await this.$Http.QueryCustomReportTemplate();
      if (!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.tagData = [];
      res.Data.forEach(d => {
        let arr = d;
        arr["IsSelect"] = false;
        this.tagData.push(arr);
        this.tagData.sort((a, b) => a.IsPrivate - b.IsPrivate);
      });
    },
    async tagSelect(val) {
      this.selectedTag = val;
      this.selectedTag.IsPrivate = val.IsPrivate;
      this.tagData.forEach(d => {
        d.IsSelect = val.Id === d.Id;
      });
      let res = await this.$Http.GetCustomReportTemplate({
        Id: Number(val.Id),
      });
      if (res.Data == null) {
        return;
      }
      this.search.dateRange = res.Data.ReportDate && res.Data.ReportDate.length === 2
          ? res.Data.ReportDate.chain().map(date => moment(date)).value()
          : [];
      this.simpleChoseRanges = res.Data.Ranges;
      this.search.choseCodes = res.Data.KpiCodes && res.Data.KpiCodes.length > 0
          ? res.Data.KpiCodes.chain().map(code => [undefined, undefined, code]).filter(v => v != null).value()
          : [];
    },
    async tagDel() {
      if (!this.selectedTag.Id) return;
      let res = await this.$Http.DeleteCustomReportTemplate({
        Id: Number(this.selectedTag.Id),
      });
      if (res["Success"]) {
        this.$message.success("删除成功!");
        this.selectedTag.Id = null;
        this.selectedTag.IsPrivate = false;
        this.resetSearch();
        await this.tagGet();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less">
.myClass {
  margin-left: 200px !important;


}
</style>
<style lang="less" scoped>
.search {
  li {
    clear: both;

    > span {
      display: block;
      width: 70px;
      line-height: 40px;
      text-align: right;
      float: left;
    }

    > p {
      width: 93%;
      float: left;
    }

    &:last-child {
      text-align: center;
    }
  }
}

.ant-tag-checkable {
  border: 1px solid #d5d5d5;
  cursor: pointer;
}
</style>
<style>
.el-cascader-menu__wrap {
  height: 500px !important;
}


.ant-modal-centered .ant-modal {
  vertical-align: top !important;
  top: 150px !important;
}
</style>
