<template>
  <a-card class="card">
    <a-form-model
      ref="form"
      :rules="rules"
      :model="form"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 6 }"
    >
      <a-form-model-item label="岗位名称（中文）" prop="positionNameCN">
        <a-input v-model="form.positionNameCN" />
      </a-form-model-item>
      <a-form-model-item label="岗位名称（英文）" prop="positionNameEN">
        <a-input v-model="form.positionNameEN" />
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="sort">
        <a-input-number v-model="form.sort" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" @click="save"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: {
        positionNameCN: "",
        positionNameEN: "",
        sort: null,
        positionId: "",
      },
      rules: {
        positionNameCN: [
          {
            required: true,
            message: "请输入岗位名称（中文）",
            trigger: ["blur", "change"],
          },
        ],
        positionNameEN: [
          {
            required: true,
            message: "请输入岗位名称（英文）",
            trigger: ["blur", "change"],
          },
        ],
        sort: [
          {
            required: true,
            message: "请选择排序号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async init() {
      let self = this;
      //编辑
      if (self.$route.path == "/positionMannage/edit") {
        //获取当前数据
        let res = await self.$Http.GetPositionInfoById({
          positionId: self.$route.query.positionId,
        });
        if (res.Data.length > 0) {
          self.form.positionNameCN = res.Data[0].PositionNameCN;
          self.form.positionNameEN = res.Data[0].PositionNameEN;
          self.form.sort = res.Data[0].Sort;
          self.form.positionId = res.Data[0].PositionId;
        }
      } else {
        this.getSort();
      }
    },
    async save() {
      let self = this;
      self.$refs.form.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res =
            self.$route.path == "/positionMannage/edit"
              ? await self.$Http.EditPosition(self.form)
              : await self.$Http.InsertPosition(self.form);
          if (res.Success) {
            self.$message.success(res.Data);
            self.$router.push("/positionMannage/positionList");
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
    //获取排序号
    async getSort() {
      let self = this;
      let res = await self.$Http.GetPositionSortIndex();
      self.form.sort = res.Data;
    },
  },
  mounted() {
    this.init();
  },
};
</script>