import { loadAreaReportData } from "@/until/reportCalculate";
import {
  percentage,
  tenThousandYuan,
  ThousandYuan,
} from "@/until/option.js";
import { xAxis, legend, yAxisDefault, seriesBar, seriesLine, yAxisTenThousandYuan, yAxisRightUnit, yAxisDefaultUnit,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";

const font = 14;

function toolFun(params, unit,lineUnit) {
  let arr = {};
  params.forEach((d, i) => {
    if(d.componentSubType === "bar") {
      if(unit === "万元") {
        arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${unit}`;
      } else if(unit === "%") {
        arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
      } else {
        arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${unit}`;
      }
    }
    if(d.componentSubType === "line") {
        //如果指定了折线图单位
        if (lineUnit){
            if(lineUnit === "万元") {
                arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${lineUnit}`;
            } else if(lineUnit === "%") {
                arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
            } else {
                arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${lineUnit}`;
            }
        }else {
            arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
        }
    }
  });
  let re = params[0].name + "<br />";
  Object.values(arr).forEach(a => {
    re += a + "<br />";
  });
  return re;
}

function labelFormat(params, unit) {
  if(params["componentSubType"] === "line") {
    return percentage(params.data);
  } else {
    if(unit === "万元") return tenThousandYuan(params.data);
    if(unit === "%") return percentage(params.data);
    return ThousandYuan(params.data);
  }
}

let columnsImport = {
  columnsImportData: [
    {
      title: "指标名称",
      dataIndex: "name",
      key: "name",
      scopedSlots: { customRender: "name" },
    },
    {
      title: "单位",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "当月",
      dataIndex: "CurrentText",
      key: "Current",
    },
    {
      title: "上月",
      dataIndex: "LastMonthText",
      key: "LastMonth",
    },
    {
      title: "环比",
      dataIndex: "QOQ",
      key: "QOQ",
    },
    {
      title: "去年同期",
      dataIndex: "LastYearText",
      key: "LastYear",
    },
    {
      title: "同比",
      dataIndex: "YOY",
      key: "YOY",
    },
    {
      title: "行业标杆",
      dataIndex: "Flag",
      key: "Flag",
    },
    {
      title: "年度累计",
      dataIndex: "YearSumText",
      key: "YearSum",
    },
  ],
  /*PC 显示 不进行列表宽度控制*/
  columnsImportDataPC: [
    {
      title: "指标名称",
      dataIndex: "name",
      key: "name", width: "10%",
      scopedSlots: { customRender: "name" },
    },
    {
      title: "单位",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "当月",
      dataIndex: "CurrentText",
      key: "Current",
    },
    {
      title: "上月",
      dataIndex: "LastMonthText",
      key: "LastMonth",
    },
    {
      title: "环比",
      dataIndex: "QOQ",
      key: "QOQ",
    },
    {
      title: "去年同期",
      dataIndex: "LastYearText",
      key: "LastYear",
    },
    {
      title: "同比",
      dataIndex: "YOY",
      key: "YOY",
    },
    {
      title: "行业标杆",
      dataIndex: "Flag",
      key: "Flag",
    },
    {
      title: "年度累计",
      dataIndex: "YearSumText",
      key: "YearSum",
    },
  ],
  /*区域 PC */
  columnsImportDataPcRegionReport: [
    {
      title: "指标名称",
      dataIndex: "name",
      key: "name",
      scopedSlots: { customRender: "name" },
    },
    {
      title: "单位",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "当月",
      dataIndex: "CurrentText",
      key: "Current",
    },
    {
      title: "上月",
      dataIndex: "LastMonthText",
      key: "LastMonth",
    },
    {
      title: "环比",
      dataIndex: "QOQ",
      key: "QOQ",
    },
    {
      title: "去年同期",
      dataIndex: "LastYearText",
      key: "LastYear",
    },
    {
      title: "同比",
      dataIndex: "YOY",
      key: "YOY",
    },
    {
      title: "全国标杆",
      dataIndex: "NationStandardText",
      key: "NationStandard",
    },
    {
      title: "全国均值",
      dataIndex: "NationAvgText",
      key: "NationAvg",
    },
    {
      title: "年度累计",
      dataIndex: "YearSumText",
      key: "YearSum",
    },
  ],
  async loadData(root, data, reportParams) {
    if(!reportParams.PartName)
      return;
    await loadAreaReportData(root, data, reportParams);
  },
};

let overViewData = {
  resTable: {},
  resChart: {},
  tableDescData_1: {
    table: [{
      name: "店均终端",
      kpiCode: "A0302001",
    }],
  },
  tableData_1_1_1: {
    table: [{
      name: "总收入",
      kpiCode: "C0406004",
    }, {
      name: "总成本(含返利)",
      kpiCode: "C0406012",
    }, {
      name: "总毛利",
      kpiCode: "C0407001",
    }, {
      name: "总毛利率",
      kpiCode: "C0407004",
    }, {
      name: "总费用",
      kpiCode: "C0206001",
    }, {
      name: "总费用占总毛利比",
      kpiCode: "C0408001",
    }, {
      name: "税前利润",
      kpiCode: "C0408004",
    }, {
      name: "税前利润率",
      kpiCode: "C0408021",
    }, {
      name: "净利润",
      kpiCode: "C0408002",
    }, {
      name: "净利润率",
      kpiCode: "C0408019",
    }, {
      name: "资产回报率",
      kpiCode: "C0408020",
    }],
    data: [],
  },
  tableData_1_2_1: {
    useAvg: true,
    table: [{
      name: "销售业务收入",
      kpiCode: "A0104090",
    }, {
      name: "销售业务成本(含返利)",
      kpiCode: "A0105034",
    }, {
      name: "销售业务毛利",
      kpiCode: "A0106025",
    }, {
      name: "销售业务毛利率",
      kpiCode: "A0107002",
    }, {
      name: "单台贡献",
      kpiCode: "A0107004",
    }, {
      name: "盈亏保本台次",
      kpiCode: "C0208001",
    }],
    data: [],
  },
  tableData_1_3_1: {
    useAvg: true,
    table: [{
      name: "售后业务收入",
      kpiCode: "B0104202",
    }, {
      name: "其中：售后维修业务收入",
      kpiCode: "B0103097",
    }, {
      name: "售后业务成本(含返利)",
      kpiCode: "B0104311",
    }, {
      name: "售后业务毛利",
      kpiCode: "B0105113",
    }, {
      name: "售后业务毛利率",
      kpiCode: "B0106026",
    }, {
      name: "零服吸收率",
      kpiCode: "B0107002",
    }],
    data: [],
  },
  specialChartData_1_3_1: {
    settings: {
      type: "histogram",
      showLine: ["盈利店占比"],
      stack: { all: ["亏损店", "盈利店"] },
      axisSite: { right: ["盈利店占比"] },
      yAxisType: ["", "0.[0]%"],
      yAxisName: ["条", "%"],
      label: {
        show: true,
        position: "inside",
        textStyle: {
          fontSize: font, color: "black",
        },
        formatter: function(params) {
          return labelFormat(params);
        },
      },
    },
    chartField: { Code: "C0408004", Columns: ["亏损店", "盈利店", "盈利店占比"] },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个"), yAxisRightUnit("%")],
      series: [seriesBar, seriesBar, seriesLine],
    },
    // options: myOptionFont(font, "个"),
  },
  chartData_1_3_2: {
    columns: ["日期", "税前利润", "税前利润率"],
    settings: {
      type: "histogram",
      showLine: ["税前利润率"],
      axisSite: { right: ["税前利润率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "C0408004", Name: "税前利润", useAvg: true },
      { Code: "C0408021", Name: "税前利润率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
    //  options: myOptionTwo(font),
  },
  chartData_1_3_3: {
    settings: {
      type: "histogram",
      showLine: ["销售业务毛利率"],
      axisSite: { right: ["销售业务毛利率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "A0106025", Name: "销售业务毛利", useAvg: true },
      { Code: "A0107002", Name: "销售业务毛利率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
    //options: myOptionTwo(font),
  },
  chartData_1_3_4: {
    settings: {
      type: "histogram",
      showLine: ["售后业务毛利率"],
      axisSite: { right: ["售后业务毛利率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "B0105113", Name: "售后业务毛利", useAvg: true },
      { Code: "B0106026", Name: "售后业务毛利率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
    // options: myOptionTwo(font),
  },
  chartData_1_4_1: {
    settings: {
      type: "histogram",
      stack: { all: ["销售业务收入", "售后业务收入"] },
      yAxisType: ["0.[0]%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "%");
        },
      },
    },
    chartField: [
      {
        Code: "chartData_1_4_1_A0104090",
        Name: "销售业务收入",
        includeCodes: ["A0104090", "B0104202"],
        formula: (v) => v[0] / (v[0] + v[1]),
      },
      {
        Code: "chartData_1_4_1_B0104202",
        Name: "售后业务收入",
        includeCodes: ["A0104090", "B0104202"],
        formula: (v) => v[1] / (v[0] + v[1]),
      },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "%");
        },
      },
      xAxis,
      yAxis: [yAxisDefault],
      series: [seriesBar, seriesBar],
    },
    /* options: {
       legend: {
         textStyle: {
           fontSize: font,
         },
       },
       xAxis,
       yAxis: [
         {
           name: "%",
           axisLabel: {
             textStyle: {
               fontSize: font, color: "black",
             },
             formatter: (val) => {
               return percentage(val);
             },
           },
         },
       ],
       series: [{
         barMaxWidth,
       }],
     },*/
  },
};

let variableData = {
  resTable: {},
  resChart: {},
  tableDescData_2: {
    table: [{
      name: "展厅终端销量占比",
      kpiCode: "A0304024",
    }, {
      name: "IDCC终端销量占比",
      kpiCode: "A0304025",
    }, {
      name: "二网终端销量占比",
      kpiCode: "A0304026",
    }, {
      name: "大客户终端销量占比",
      kpiCode: "A0304027",
    }, {
      name: "销售水平事业毛利",
      kpiCode: "A0104067",
    }],
  },
  tableData_2_1_1: {
    useAvg: true,
    table: [
      {
      name: "新车销量",
      kpiCode: "A0302001",
    }, {
      name: "销售业务综合毛利率",
      kpiCode: "A0107002",
    }, {
      name: "其中：新车销售裸车毛利率",
      kpiCode: "A0106023",
    }, {
      name: "新车销售返利后毛利率",
      kpiCode: "A0107001",
      indentation: 3,
    }, {
      name: "销售水平事业毛利率",
      kpiCode: "A0105023",
      indentation: 3,
    }, {
      name: "平均单车销售综合毛利",
      kpiCode: "A0107004",
    }, {
      name: "平均单车新车裸车毛利",
      kpiCode: "A0106026",
    }, {
      name: "平均单车销售返利金额",
      kpiCode: "A0105071",
      indentation: 0,
    }, {
      name: "平均单车销售水平事业毛利",
      kpiCode: "A0105072",
      indentation: 0,
    }],
    data: [],
  },
  chartData_2_1_1: {
    settings: {
      type: "histogram",
      showLine: ["新车销售返利后毛利率"],
      // axisSite: {right: ["新车销售返利后毛利率"]},
      yAxisType: ["0.[0]%"],
      yAxisName: ["%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "%");
        },
      },
    },
    chartField: [
      { Code: "A0106023", Name: "新车销售裸车毛利率" },
      { Code: "A0107001", Name: "新车销售返利后毛利率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "%");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_2_1_2: {
    settings: {
      type: "histogram",
      stack: { stack1: ["平均单车新车裸车毛利", "平均单车销售返利", "平均单车销售水平事业毛利"] },
      yAxisType: ["0"],
      yAxisName: ["元"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "元");
        },
      },
    },
    chartField: [
      { Code: "A0106026", Name: "平均单车新车裸车毛利", useAvg: true },
      { Code: "A0105071", Name: "平均单车销售返利", useAvg: true },
      { Code: "A0105072", Name: "平均单车销售水平事业毛利", useAvg: true },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元")],
      series: [seriesBar, seriesBar, seriesBar],
    },
    /* options: {
       legend: {
         textStyle: {
           fontSize: font,
         },
       },
       tooltip: {
         show: true,
         trigger: "axis",
         formatter: function(params) {
           let arr = [];
           params.forEach((d) => {
             let minvalue = 0;
             if(d.value === undefined) {
               minvalue = "-";
             } else {
               minvalue = Number(d.value).toFixed(0);
             }
             arr.push({
               val: `<span style="background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;"></span>${d["seriesName"]}: ${minvalue}元`,
             });
           });
           return `${params[0]["axisValue"]} <br /> ${arr[2].val} <br />${arr[1].val}<br />${arr[0].val}`;
         },
       },
       xAxis,
       yAxis: [
         {
           name: "元",
           nameTextStyle: {
             fontSize: font,
           },
           type: "value",
           axisLine: {
             //y轴
             show: true,

           },
           axisTick: {
             show: false,
           },
           splitLine: {
             show: false,
           },
           axisLabel: {
             textStyle: {
               fontSize: font, color: "black",
             },
             formatter: (val) => {
               return `${val}`;
             },
           },
         },
       ],
       series: [
         {
           barMaxWidth,
           label: {
             normal: {
               show: true,
               textStyle: {
                 color: "black",
               },
               formatter: function(params) {
                 return ThousandYuan(params.value);
               },
             },
           },
         }, {
           label: {
             normal: {
               show: true,
               textStyle: {
                 color: "black",
               },
               formatter: function(params) {
                 return ThousandYuan(params.value);
               },
             },
           },
         }, {
           label: {
             normal: {
               show: true,
               textStyle: {
                 color: "black",
               },
               formatter: function(params) {
                 return ThousandYuan(params.value);
               },
             },
           },
         },
       ],
     },*/
  },
  tableData_2_2_1: {
    useAvg: true,
    table: [{
      name: "销售顾问流失率",
      kpiCode: "C0303153",
    }, {
      name: "销售顾问人均销量",
      kpiCode: "A0304034",
    }, {
      name: "销售顾问人均生产力",
      kpiCode: "A0107014",
    }],
    data: [],
  },
  chartData_2_3_1: {
    settings: {
      type: "histogram",
      stack: { stack1: ["展厅终端销量占比", "iDCC终端销量占比", "二网终端销量占比", "大客户终端销量占比"] },
      yAxisType: ["0.[0]%"],
      yAxisName: ["%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "%");
        },
      },
    },
    chartField: [
      { Code: "A0304024", Name: "展厅终端销量占比" },
      { Code: "A0304025", Name: "iDCC终端销量占比" },
      { Code: "A0304026", Name: "二网终端销量占比" },
      { Code: "A0304027", Name: "大客户终端销量占比" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "%");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("%")],
      series: [seriesBar, seriesBar, seriesBar, seriesBar],
    },
  },
  tableData_2_4_1: {
    useAvg: true,
    table: [{
      name: "展厅首次客流",
      kpiCode: "A1302012",
    }, {
      name: "展厅再次客流",
      kpiCode: "A1302013",
    }, {
      name: "展厅首次客流成交率",
      kpiCode: "A1303081",
    }, {
      name: "展厅再次客流成交率",
      kpiCode: "A1303082",
    }, {
      name: "IDCC线索总量",
      kpiCode: "A1302115",
    }, {
      name: "IDCC线索有效率",
      kpiCode: "A1303036",
    }, {
      name: "iDCC邀约到店率",
      kpiCode: "A1303024",
    }, {
      name: "iDCC邀约到店成交率",
      kpiCode: "A1303028",
    }, {
      name: "iDCC线索转化率",
      kpiCode: "A1303032",
    }, {
      name: "当月新增潜客数",
      kpiCode: "A1303041",
    }, {
      name: "新增潜客试乘试驾率",
      kpiCode: "A1303002",
    }, {
      name: "当月新增潜客成交率",
      kpiCode: "A1304001",
    }],
    data: [],
  },
  chartData_2_4_1: {
    settings: {
      type: "histogram",
      showLine: ["当月新增潜客成交率"],
      axisSite: { right: ["当月新增潜客成交率"] },
      yAxisType: ["0", "0.[0]%"],
      yAxisName: ["个", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "个");
        },
      },
    },
    chartField: [
      { Code: "A1303041", Name: "当月新增潜客数", useAvg: true },
      { Code: "A1304001", Name: "当月新增潜客成交率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个"), yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_2_4_2: {
    settings: {
      type: "histogram",
      showLine: ["IDCC线索有效率", "IDCC线索转化率"],
      axisSite: { right: ["IDCC线索有效率", "IDCC线索转化率"] },
      yAxisType: ["0", "0.[0]%"],
      yAxisName: ["个", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "个");
        },
      },
    },
    chartField: [
      { Code: "A1302115", Name: "IDCC线索总数", useAvg: true },
      { Code: "A1303036", Name: "IDCC线索有效率" },
      { Code: "A1303032", Name: "IDCC线索转化率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个"), yAxisRightUnit("%")],
      series: [seriesBar, seriesLine, seriesLine],
    },
  },
  tableData_2_5_1: {
    useAvg: true,
    table: [{
      name: "新车用品渗透率",
      kpiCode: "A0303271",
    }, {
      name: "新车保险渗透率",
      kpiCode: "A0303272",
    }, {
      name: "金融按揭渗透率",
      kpiCode: "A0303273",
    }, {
      name: "上牌验车渗透率",
      kpiCode: "A0303274",
    }, {
      name: "二手车置换率",
      kpiCode: "A0303275",
    }, {
      name: "新车延保渗透率",
      kpiCode: "A0303276",
    }, {
      name: "平均单车销售水平事业毛利(新车终端销量)",
      kpiCode: "A0105072",
        indentation: 0,

    }, {
      name: "平均单车新车用品毛利(用品台次)",
      kpiCode: "A0104115",
    }, {
      name: "平均单车新车保险毛利(新保台次)",
      kpiCode: "A0104116",
    }, {
      name: "平均单车金融按揭毛利(金融按揭台次)",
      kpiCode: "A0104117",
    }, {
      name: "平均单车上牌验车毛利(上牌验车台次)",
      kpiCode: "A0104118",
    }, {
      name: "平均单车二手车毛利(二手车台次)",
      kpiCode: "A0104119",
    }, {
      name: "平均单车新车延保毛利(延保台次)",
      kpiCode: "A0104120",
    }],
    data: [],
  },
  chartData_2_5_1: {
    settings: {
      type: "histogram",
      showLine: ["销售水平事业毛利率"],
      axisSite: { right: ["销售水平事业毛利率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "A0104067", Name: "销售水平事业毛利", useAvg: true },
      { Code: "A0105023", Name: "销售水平事业毛利率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_2_5_2: {
    settings: {
      type: "histogram",
      showLine: ["新车保险渗透率", "金融按揭渗透率"],
      yAxisType: ["0.[0]%"],
      yAxisName: ["%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "%");
        },
      },
    },
    chartField: [
      { Code: "A0303272", Name: "新车保险渗透率" },
      { Code: "A0303273", Name: "金融按揭渗透率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("%")],
      series: [seriesLine, seriesLine],
    },
    // options: myOptionPercentage(font),
  },
};

let regularizeData = {
  resTable: {},
  resChart: {},
  tableDescData_3: {
    table: [{
      name: "有效保有客户数",
      kpiCode: "B0302037",
    }, {
      name: "零服吸收率",
      kpiCode: "B0107002",
    }, {
      name: "平均单车售后零部件总毛利",
      kpiCode: "B0105131",
    }, {
      name: "售后水平事业毛利",
      kpiCode: "B0104187",
    }, {
      name: "售后水平事业毛利率",
      kpiCode: "B0105108",
    }],
  },
  tableData_3_1_1: {
    table: [
      {
        name: "售后来厂台次",
        kpiCode: "B0302001",
      }, {
        name: "A类客户数",
        kpiCode: "B0302034",
      }, {
        name: "B类客户数",
        kpiCode: "B0302035",
      }, {
        name: "C类客户数",
        kpiCode: "B0302036",
      }, {
        name: "新销售未回厂数",
        kpiCode: "B0302039",
      }, {
        name: "流失客户数",
        kpiCode: "B0302038",
      }, {
        name: "·客户占有效保有客户数比",
        kpiCode: "B0303051",
      }, {
        name: "客户流失率",
        kpiCode: "B0304014",
      }, {
        name: "客户维系率",
        kpiCode: "B0303024",
      }],
    data: [],
  },
  tableData_3_2_1: {
    useAvg: true,
    table: [{
      name: "售后零部件收入",
      kpiCode: "B0103007",
    }, {
      name: "售后维修工时收入",
      kpiCode: "B0103047",
    }, {
      name: "平均单车售后维修业务收入",
      kpiCode: "B0104226",
    }, {
      name: "平均单车售后零部件收入",
      kpiCode: "B0104254",
    }, {
      name: "平均单车售后维修工时收入",
      kpiCode: "B0104255",
    }, {
      name: "售后维修业务毛利",
      kpiCode: "B0104165",
    }, {
      name: "售后零部件毛利",
      kpiCode: "B0104043",
    }, {
      name: "售后维修工时毛利",
      kpiCode: "B0104063",
    }, {
      name: "售后维修业务毛利率",
      kpiCode: "B0105086",
    }, {
      name: "售后零部件毛利率",
      kpiCode: "B0105043",
    }, {
      name: "售后维修工时毛利率",
      kpiCode: "B0105044",
    }, {
      name: "平均单车售后维修业务毛利",
      kpiCode: "B0105182",
    }, {
      name: "平均单车售后零部件毛利",
      kpiCode: "B0105132",
    }, {
      name: "平均单车售后维修工时毛利",
      kpiCode: "B0105161",
    }],
    data: [],
  },
  chartData_3_1_1: {
    settings: {
      type: "histogram",
      showLine: ["AB类客户占有效保有客户数比"],
      axisSite: { right: ["AB类客户占有效保有客户数比"] },
      yAxisType: ["0", "0.[0]%"],
      yAxisName: ["个", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "个");
        },
      },
    },
    chartField: [
      {
        Code: "B0302034+B0302035",
        Name: "AB类客户数",
        includeCodes: ["B0302034", "B0302035"],
        formula: (v) => v[0] + v[1],
      },
      { Code: "B0303051", Name: "AB类客户占有效保有客户数比" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个"), yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_3_1_2: {
    settings: {
      type: "histogram",
      showLine: ["回厂台次占保客比"],
      axisSite: { right: ["回厂台次占保客比"] },
      yAxisType: ["0", "0.[0]%"],
      yAxisName: ["个", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "个");
        },
      },
    },
    chartField: [
      { Code: "B0302001", Name: "售后来厂台次" },
      { Code: "B0303081", Name: "回厂台次占保客比" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个"), yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_3_2_1: {
    settings: {
      type: "histogram",
      showLine: ["售后维修业务毛利率"],
      axisSite: { right: ["售后维修业务毛利率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "B0104165", Name: "售后维修业务毛利", useAvg: true },
      { Code: "B0105086", Name: "售后维修业务毛利率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
    // options: myOptionTwo(font),
  },
  chartData_3_2_2: {
    settings: {
      type: "histogram",
      stack: { stack1: ["平均单车售后零部件毛利", "平均单车维修工时毛利", "平均单车售后水平事业毛利"] },
      yAxisType: ["0"],
      yAxisName: ["元"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "元");
        },
      },
    },
    chartField: [
      { Code: "B0105132", Name: "平均单车售后零部件毛利", useAvg: true },
      { Code: "B0105161", Name: "平均单车维修工时毛利", useAvg: true },
      { Code: "B0105134", Name: "平均单车售后水平事业毛利", useAvg: true },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: [seriesBar, seriesBar, seriesBar],
    },
  },
  chartData_3_2_3: {
    settings: {
      type: "histogram",
      showLine: ["售后单车收入", "售后单车毛利"],
      axisSite: { right: ["售后单车收入", "售后单车毛利"] },
      yAxisType: ["0", "0"],
      yAxisName: ["台", "元"],
      label: {
        show: true,
        //         position: "inside",
        textStyle: {
          fontSize: font,
          color: "black",
        },
        formatter: function(params) {
          return (params.data).toFixed(0);
        },
      },
    },
    chartField: [
      { Code: "B0302001", Name: "售后来厂台次" },
      { Code: "B0104226", Name: "售后单车收入", useAvg: true },
      { Code: "B0105182", Name: "售后单车毛利", useAvg: true },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "台","元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("台"), yAxisRightUnit("元")],
      series: [seriesBar, seriesLine, seriesLine],
    },
  },
  chartData_3_3_1: {
    settings: {
      type: "histogram",
      showLine: ["售后水平事业毛利率"],
      axisSite: { right: ["售后水平事业毛利率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "B0104187", Name: "售后水平事业毛利", useAvg: true },
      { Code: "B0105108", Name: "售后水平事业毛利率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar,  seriesLine],
    },
  //  options: myOptionTwo(font),
  },
  specialChartData_3_4_1: {
    settings: {
      type: "histogram",
      showLine: ["零服吸收率"],
      axisSite: { right: ["零服吸收率"] },
      yAxisType: ["0", "0.[0]%"],
      yAxisName: ["个", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "个");
        },
      },
    },
    chartField: {
      Code: "B0107002", Columns: ["零服吸收率大于100%的店数", {
        Code: "B0107002", Name: "零服吸收率",
      }],
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个"), yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
};

let propertyData = {
  resTable: {},
  resChart: {},
  tableData_4_1_1: {
    table: [{
      name: "资产负债率",
      kpiCode: "C0107003",
    }, {
      name: "流动比率",
      kpiCode: "C0106003",
    }, {
      name: "速动比率",
      kpiCode: "C0107002",
    }, {
      name: "总资产周转率",
      kpiCode: "C0107006",
    }, {
      name: "流动资产周转率",
      kpiCode: "C0106006",
    }],
    data: [],
  },
  tableData_4_2_1: {
    table: [{
      name: "新车库存周转率",
      kpiCode: "A0404002",
    }, {
      name: "新车库销比例",
      kpiCode: "A0404045",
    }, {
      name: "现金车金额占期末库存车金额比",
      kpiCode: "A0403091",
    }, {
      name: "长库龄新车库存台次占比",
      kpiCode: "A0404111",
    }, {
      name: "零部件库存周转率",
      kpiCode: "B0404002",
    }, {
      name: "365天零部件库存占比",
      kpiCode: "B0403006",
    }],
    data: [],
  },
  specialChartData_4_1_1: {
    settings: {
      type: "histogram",
      stack: { stack1: ["资产负债率<40%的店", "40%~70%的店", ">70%的店"] },
      yAxisType: ["0"],
      yAxisName: ["个"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "个");
        },
      },
    },
    chartField: { Code: "C0107003", Columns: ["资产负债率<40%的店", "40%~70%的店", ">70%的店"] },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "个");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("个")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_4_2_1: {
    settings: {
      type: "histogram",
      showLine: ["长库龄新车库存台次占比"],
      axisSite: { right: ["长库龄新车库存台次占比"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["次", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "次");
        },
      },
    },
    chartField: [
      { Code: "A0404002", Name: "新车库存周转率" },
      { Code: "A0404111", Name: "长库龄新车库存台次占比" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "次");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("次"), yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
};

let costData = {
  resTable: {},
  resChart: {},
  tableDescData_5: {
    table: [{
      name: "固定费用",
      kpiCode: "C0205006",
    }, {
      name: "财务费用",
      kpiCode: "C0205021",
    }, {
      name: "收入",
      kpiCode: "C0406004",
    }, {
      name: "厂家融资贴息",
      kpiCode: "A0202039",
    }],
  },
  tableData_5_1_1: {
    table: [{
      name: "总费用",
      kpiCode: "C0206001",
    }, {
      name: "固定费用",
      kpiCode: "C0202020",
    }, {
      name: "其中：租赁费",
      kpiCode: "C0202025",
    }, {
      name: "半固定费用",
      kpiCode: "C0202124",
    }, {
      name: "变动费用",
      kpiCode: "C0202001",
    }, {
      name: "其中：销售业务广宣费用",
      kpiCode: "A0202007",
    }, {
      name: "财务费用",
      kpiCode: "C0205021",
    }, {
      name: "其中：销售业务财务费用",
      kpiCode: "A0204008",
    }, {
      name: "费用率",
      kpiCode: "C0207022",
    }, {
      name: "人力费用率",
      kpiCode: "C0207028",
    }, {
      name: "其中：销售人力费用率",
      kpiCode: "C0207029",
    }, {
      name: "售后人力费用率",
      kpiCode: "C0207030",
      indentation: 3,
    }, {
      name: "总费用占总毛利比",
      kpiCode: "C0408001",
    },
    ],
    data: [],
  },
  chartData_5_1_1: {
    settings: {
      type: "histogram",
      showLine: ["费用率"],
      axisSite: { right: ["费用率"] },
      yAxisType: ["0.[00]", "0.[0]%"],
      yAxisName: ["万元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "万元");
        },
      },
    },
    chartField: [
      { Code: "C0206001", Name: "总费用" },
      { Code: "C0207022", Name: "费用率" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "万元");
        },
      },
      xAxis,
      yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
      series: [seriesBar, seriesLine],
    },
  },
  chartData_5_1_2: {
    settings: {
      type: "histogram",
      showLine: ["总费用占总毛利比"],
      yAxisType: ["0.[0]%"],
      yAxisName: ["%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "%");
        },
      },
    },
    chartField: [
      { Code: "C0408001", Name: "总费用占总毛利比" },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "%");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("%")],
      series: [seriesLine],
    },
  },
  tableData_5_2_1: {
    useAvg: true,
    table: [{
      name: "平均单车销售业务费用",
      kpiCode: "A0206032",
    }, {
      name: "平均单车广宣费用",
      kpiCode: "A0205034",
    }, {
      name: "平均单车垂直网站费用",
      kpiCode: "A0204041",
    }, {
      name: "平均单车财务费用",
      kpiCode: "A0205035",
    }, {
      name: "平均单车销售顾问工资",
      kpiCode: "A0204032",
    }, {
      name: "平均单车销售其他费用",
      kpiCode: "A0207021",
    }],
    data: [],
  },
  chartData_5_2_1: {
    settings: {
      type: "histogram",
      stack: { stack1: ["平均单车广宣费用", "平均单车垂直网站费用", "平均单车财务费用", "平均单车销售顾问工资", "平均单车销售其他费用"] },
      yAxisType: ["0"],
      yAxisName: ["元"],
      label: {
        show: false, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return labelFormat(params, "元");
        },
      },
    },
    chartField: [
      {
        Name: "平均单车广宣费用",
        Code: "A0205034", useAvg: true,
      },
      {
        Name: "平均单车垂直网站费用",
        Code: "A0204041", useAvg: true,
      }, {
        Name: "平均单车财务费用",
        Code: "A0205035", useAvg: true,
      }, {
        Name: "平均单车销售顾问工资",
        Code: "A0204032", useAvg: true,
      }, {
        Name: "平均单车销售其他费用",
        Code: "A0207021", useAvg: true,
      },
    ],
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: [seriesBar, seriesBar, seriesBar, seriesBar, seriesBar],
    },
  },
};

export
{
  columnsImport, overViewData, variableData, regularizeData, propertyData, costData,toolFun,
};



