import ProfitReport from "@/views/profitReport/profitReport";
import OperationReport from "@/views/operationReport/operationReport";

let performanceRouter = [
 // 盈利分析报告
 {
  path: "/profitReport",
  component: ProfitReport,
 },
 // 运营分析报告
 {
  path: "/operationReport",
  component: OperationReport,
 },
];

export default performanceRouter;
