const COMPANY_MANNAGE_API = {

    // 销售店列表
    GetCompanyListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCompanyListBy'
    },

    //新增销售店
    InsertCompany: {
        method: 'post',
        url: '/BasicSetting/BasicSet/InsertCompany'
    },

    //搜索集团店
    GetGroupCompany: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetGroupCompany'
    },

    //获取详情信息
    GetCompanyInfoById: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCompanyInfoById'
    },

    //编辑销售店保存
    EditCompany: {
        method: 'post',
        url: '/BasicSetting/BasicSet/EditCompany'
    },

    //删除销售店
    DeleteCompany: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeleteCompany'
    },

    //获取城市
    GetCityName: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCityName'
    }
}
export default COMPANY_MANNAGE_API
