const BG_MANNAGE_API = {
    //大区列表
    GetPartListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPartListByPage'
    },

    //获取大区排序号
    GetPartSortIndex: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPartSortIndex'
    },

    //新增大区
    InsertPart: {
        method: 'get',
        url: '/BasicSetting/BasicSet/InsertPart'
    },

    //获取详情
    GetPartInfoById: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPartInfoById'
    },

    //编辑大区
    EditPart: {
        method: 'get',
        url: '/BasicSetting/BasicSet/EditPart'
    },

    //删除大区
    DeletePart: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeletePart'
    }


}
export default BG_MANNAGE_API
