const lastFilterCodes = [
 "A1402172", "A1402173", "A1402174", "A1402175",
];
const currentFilterCodes = [
 "E1202091+E1202092+E1202093+E1202094", "E1202092", "A1303045", "E1202091+E1202092+E1202093+E1202094"
];

const carType = ["所有车型", "GS3 Power", "GS4",  "GS4PLUS", "GS8",  "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"];


export {
 lastFilterCodes, currentFilterCodes,carType
};
