<template>
  <div style="min-width: 1440px">
    <a-card style="margin-bottom: 10px">
      报告时间：
      <a-month-picker v-model="reportDate" :disabledDate="disabledDate" :placeholder="'请选择报告月份'" @change="loadData([])"/>
    </a-card>
    <a-row :gutter="[10]">
      <a-col :span="6">
        <a-card title="业绩分析" style="min-height: 75vh">
          <a-tree :showLine="true"
                  :treeData="menus"
                  :expandedKeys.sync="expandedKeys"
                  :replaceFields="{children:'SubMenus',title:'Name',key:'Id'}"
                  @select="selectdKeys=>{loadData(selectdKeys);}"/>
        </a-card>
      </a-col>
      <a-col :span="18">
        <a-card :title="tableTitle">
          <a-table :rowKey="(record, index) => index" :columns="tableColumns" :data-source="tableData"
                   :pagination="false" bordered>
          </a-table>
        </a-card>
        <a-card title="月度趋势" style="margin-top: 10px">
          <ve-chart :data="chartData" :settings="chartSettings" :after-set-option="afterSetOption"></ve-chart>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
import { disabledDate }  from '../../until/dayFormat.js'

export default {
  data() {
    return {
      menus: [],
      menuPaths: {},
      expandedKeys: [],
      tableTitle: "请选择业绩分析项目",
      reportDate: moment().add(-1, 'M'),
      dealerCode: "",
      currMenuId: 0,
      tableColumns: [
        {
          title: "科目名称",
          dataIndex: "KpiName",
          key: "KpiName",
          width: 200
        },
        {
          title: "单位",
          dataIndex: "Unit",
          key: "Unit",
          width: 150,
        },
        {
          title: "当月值",
          dataIndex: "Current",
          key: "Current",
          width: 150,
        },
        {
          title: "前三月滚动值",
          dataIndex: "PreThreeMonth",
          key: "PreThreeMonth",
          width: 150,
        },
        {
          title: "去年同期",
          dataIndex: "LastYear",
          key: "LastYear",
          width: 150,
        },
        {
          title: "年累计",
          dataIndex: "CurrentYear",
          key: "CurrentYear",
          width: 150,
        },
        {
          title: "预算目标",
          dataIndex: "Budget",
          key: "Budget",
          width: 150,
        }
      ],
      tableData: [],
      chartSettings: {
        type: "line"
      },
      chartData: {
        columns: [],
        rows: [],
        options:{
          legend: {
            type: "scroll",
            /* orient: "vertical",
             x: "left",      //可设定图例在左、右、居中
             y: "center",     //可设定图例在上、下、居中*/
            itemHeight: 22,
            // itemWidth: 100,
            textStyle: {
              color: "black",
              fontSize: 12,
            },
          },
        },
      }
    };
  },
  created() {
    this.getDealerInfo();
    this.getMenus();
  },
  methods: {
    disabledDate,
    async getDealerInfo() {
      let res = await this.$Http.getCurrDealerInfo();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.dealerCode = res.Data.DealerCode;
    },
    async getMenus() {
      let res = await this.$Http.GetPushDownReportMenu();
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.menus = res.Data;
      this.expandedKeys = this.getMenuAllExpandKeys(this.menus, [], "");
    },
    getMenuAllExpandKeys(menus, keys, pathPrefix) {
      if (pathPrefix.length > 0) {
        pathPrefix += "-";
      }
      menus.forEach(m => {
        let menuPath = pathPrefix + m.Name;
        this.menuPaths[m.Id] = menuPath;
        if (m.SubMenus != null) {
          this.getMenuAllExpandKeys(m.SubMenus, keys, menuPath);
          keys.push(m.Id);
        }
      })
      return keys;
    },
    async loadData(selectedKeys) {
      if (selectedKeys.length > 0) {
        this.currMenuId = selectedKeys[0];
      }
      if (this.currMenuId <= 0 || this.dealerCode === "") {
        return;
      }
      this.tableTitle = this.menuPaths[this.currMenuId];
      let res = await this.$Http.GetPushDownReport({
        menuId: this.currMenuId,
        dealerCode: this.dealerCode,
        reportDate: this.reportDate.format("YYYY-MM-01")
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      // 计算表格显示内容
      res.Data.Table.forEach(d => {
        if(d.KpiName === '售后业务综合毛利' || d.KpiName === '售后业务综合收入') {
          d.Unit = "万元";
        }
        Object.keys(d).forEach(key => {
          let value = d[key];
          if (typeof value != "number")
            return;
          if (d.Unit === "%") {
            value = (value * 100).toFixed(2) + "%";
          }else if (d.Unit === "万元") {
            value = Number((value / 10000).toFixed(3)).toFixed(2)
          } else {
            value = Number(value.toFixed(2)).toLocaleString();
          }
          d[key] = value;
        })
      });
      this.tableData = res.Data.Table;

      // 计算图表显示内容
      let lineData = res.Data.Line;
      let columns = Object.keys(lineData);
      let rows = [];
      for (let month = 0; month < 12; month++) {
        let row = {"日期": (month + 1) + "月"};
        columns.forEach(column => {
          let value = lineData[column][month]
          if(column==='售后业务综合毛利' || column === '售后业务综合收入') value = Number(value/10000).toFixed(2)
          row[column] = value;
        });
        rows.push(row);
      }
      this.chartData.columns = ["日期", ...columns];
      this.chartData.rows = rows;
    },
    afterSetOption(chartObj) {
      if (this.chartData.options != null) {
        chartObj.setOption(this.chartData.options);
      }
    },
  },
}
</script>

<style scoped>
::v-deep table tr:nth-child(even) {
  background-color: #F8F8F8 !important;
}
</style>
