<template>
  <section>
    <div id="editor"></div>
    <a-button-group>
      <a-button icon="check" type="primary" @click="submit"> 提交 </a-button>
      <a-button icon="delete" type="danger" @click="clean"> 清空 </a-button>
    </a-button-group>
  </section>
</template>

<script>
import E from "wangeditor";
export default {
  data() {
    return {
      editor: null,
    };
  },
  methods: {
    submit() {
   //   console.log(this.editor.txt.html());
    },
    clean() {
      this.editor.txt.clear();
    },
  },
  mounted() {
    this.editor = new E("#editor");
    this.editor.config.placeholder = "自定义 placeholder 提示文字";
    this.editor.create();
  },
};
</script>
<style scoped>
#editor {
  margin-bottom: 30px;
}
</style>
