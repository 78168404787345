import { render, staticRenderFns } from "./AddPermissiones.vue?vue&type=template&id=743fabac&scoped=true&"
import script from "./AddPermissiones.vue?vue&type=script&lang=js&"
export * from "./AddPermissiones.vue?vue&type=script&lang=js&"
import style0 from "./AddPermissiones.vue?vue&type=style&index=0&id=743fabac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743fabac",
  null
  
)

export default component.exports