import { loadAreaReportData } from "@/until/reportCalculate";
import {
    barMaxWidth, grid, grid2, gridLine, xAxis, yAxisShow, axisLine, lineStyle, PotItemStyle, position,
    myOptionColor,
    myOptionFont,
    myOptionTwo,
    percentage,
    tenThousandYuan,
    ThousandYuan, myOptionPercentage,
    YtenThousandYuan, symbolSize,
} from "@/until/optionPrint.js";

const font = 22;
const textStyle = {
    fontSize: font, color: "black",
};

let columnsImport = {
    columnsImportData: [
        {
            title: "项目",
            dataIndex: "name",
            key: "name",
            scopedSlots: { customRender: "name" },
        },

        {
            title: "当月",
            dataIndex: "CurrentText",
            key: "Current",
        },
        {
            title: "上月",
            dataIndex: "LastMonthText",
            key: "LastMonth",
        },
        {
            title: "月均",
            dataIndex: "YearSumText",
            key: "YearSum",
        },
        {
            title: "",
            dataIndex: "Unit",
            key: "Unit",
        },
    ],
    /*PC 显示 不进行列表宽度控制*/
    columnsImportDataPC: [
        {
            title: "指标名称",
            dataIndex: "name",
            key: "name",
            scopedSlots: { customRender: "name" },
        },
        {
            title: "单位",
            dataIndex: "Unit",
            key: "Unit",
        },
        {
            title: "当月",
            dataIndex: "CurrentText",
            key: "Current",
        },
        {
            title: "上月",
            dataIndex: "LastMonthText",
            key: "LastMonth",
        },
        {
            title: "环比",
            dataIndex: "QOQ",
            key: "QOQ",
        },
        {
            title: "去年同期",
            dataIndex: "LastYearText",
            key: "LastYear",
        },
        {
            title: "同比",
            dataIndex: "YOY",
            key: "YOY",
        },
        {
            title: "行业标杆",
            dataIndex: "Flag",
            key: "Flag",
        },
        {
            title: "年度累计",
            dataIndex: "YearSumText",
            key: "YearSum",
        },
    ],
    async loadData(root, data, reportParams) {
        if (!reportParams.PartName)
            return;
        await loadAreaReportData(root, data, reportParams);
    },
};
let overViewData = {
    tableData_1_1: {
        table: [{
            name: "总收入",
            kpiCode: "C0406004",
        }, {
            name: "总成本(含返利)",
            kpiCode: "C0406012",
        }, {
            name: "总费用",
            kpiCode: "C0206001",
        }, {
            name: "税前利润",
            kpiCode: "C0408004",
        }, {
            name: "净利润",
            kpiCode: "C0408002",
        }],
        data: [],
    },
    tableData_1_2: {
        useAvg: true,
        table: [{
            name: "可变业务收入",
            kpiCode: "A0104090",
        }, {
            name: "可变业务成本",
            kpiCode: "A0105026",
        }, {
            name: "可变业务毛利",
            kpiCode: "A0106025",
        }, {
            name: "可变业务毛利率",
            kpiCode: "A0107002",
        }, {
            name: "单台贡献",
            kpiCode: "A0107004",
        }, {
            name: "盈亏保本台次",
            kpiCode: "C0208001",
        }],
        data: [],
    },
    tableData_1_3:{
        useAvg: false,
        table: [{
            name: "店均终端台次",
            kpiCode: "A0302001",
        }, {
            name: "店均盈亏保本台次",
            kpiCode: "C0208001",
        }],
        data: [],
    },
    specialChartData_1_3: {
        settings: {
            type: "histogram",
            showLine: ["盈利店占比"],
            stack: { all: ["亏损店", "盈利店"] },
            axisSite: { right: ["盈利店占比"] },
            yAxisType: ["", "0.[0]%"],
            yAxisName: ["", ""],
            label: {
                show: true,
                position: "inside",
                textStyle,
                formatter: (params) => {
                    if (params["componentSubType"] === "line") {
                        return percentage(params.data);
                    } else {
                        return params.data;
                    }
                },
            },
        },
        chartField: { Code: "C0408004", Columns: ["亏损店", "盈利店", "盈利店占比"] },
        rows: [],
        options: myOptionFont(font),
    },
    tableData_1_4: {
        useAvg: true,
        table: [{
            name: "固定业务收入",
            kpiCode: "B0104202",
        }, {
            name: "其中：售后维修业务收入",
            kpiCode: "B0103097",
            indentation: 2,
        }, {
            name: "固定业务成本",
            kpiCode: "B0104203",
        }, {
            name: "固定业务毛利",
            kpiCode: "B0105113",
        }, {
            name: "固定业务毛利率",
            kpiCode: "B0106026",
        }, {
            name: "服务满足率",
            kpiCode: "C0207021",
        }],
        data: [],
    },

    chartData_1_5: {
        settings: {
            type: "histogram",
            showLine: ["净利润率"],
            axisSite: { right: ["净利润率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
        },
        chartField: [
            { Code: "C0408002", Name: "净利润", useAvg: true },
            { Code: "C0408019", Name: "净利润率" },
        ],
        rows: [],
        options: myOptionTwo(font),
    },
    chartData_1_6: {
        settings: {
            type: "histogram",
            //   stack: {all: ["可变业务收入", "固定业务收入"]},
            yAxisType: ["0"],
            label: {
                show: true,
                textStyle,
                formatter: function(params) {
                    return ThousandYuan(params.data);
                },
            },
        },
        chartField: [
            { Code: "C0305011", Name: "前后端产值" },
        ],
        rows: [],
        options: {
            legend: {
                show: false,
                textStyle,
            },
            grid: grid2, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return ThousandYuan(val);
                        },
                    },
                }, {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle: {
                            fontSize: font, color: "black",
                        },
                        formatter: (val) => {
                            return ThousandYuan(val);
                        },
                    },
                },
            ],
            series: [
                { barMaxWidth },
            ],
        },
    },
};

let variableData = {
    chartData_2_1: {
        settings: {
            type: "histogram",
            showLine: ["裸车毛利率", "裸车返利后毛利率"],
            yAxisType: ["0.[0]%"],
            label: {
                formatter: function(params) {
                    return percentage(params.data);
                },
            },
        },
        chartField: [
            { Code: "A0106023", Name: "裸车毛利率" },
            { Code: "A0107001", Name: "裸车返利后毛利率" },
        ],
        rows: [],
        options: myOptionPercentage(font),
    },
    chartData_2_2: {
        settings: {
            type: "histogram",
            showLine: ["可变业务毛利率"],
            stack: { stack1: ["单车裸车毛利", "单车销售返利", "单车水平事业毛利"] },
            axisSite: { right: ["可变业务毛利率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            yAxisName: ["", ""],
        },
        chartField: [
            { Code: "A0106026", Name: "单车裸车毛利", useAvg: true },
            { Code: "A0105071", Name: "单车销售返利", useAvg: true },
            { Code: "A0105072", Name: "单车水平事业毛利", useAvg: true },
            { Code: "A0107002", Name: "可变业务毛利率" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid: {
                left: "-3%",
                right: "-1%",
                bottom: "14px",
                containLabel: true,
            }, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    name: "元",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    type: "value",
                    axisLine,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return `${val}`;
                        },
                    },
                }, {
                    show: false,
                },
            ],
            series: [
                {
                    barMaxWidth,
                    label: {
                        show: true,
                        textStyle, position: "right",
                        formatter: function(params) {
                            return ThousandYuan(params.value);
                        },
                    },
                }, {
                    label: {
                        show: true,
                        textStyle, position: "right",
                        formatter: function(params) {
                            return ThousandYuan(params.value);
                        },
                    },
                }, {
                    label: {
                        show: true, position: "right",
                        textStyle,
                        formatter: function(params) {
                            return ThousandYuan(params.value);
                        },
                    },
                }, {
                    lineStyle, symbolSize,
                    label: {
                        show: true,
                        textStyle, position,
                        formatter: function(params) {
                            return percentage(params.value);
                        },
                    },
                },
            ],
        },
    },
    chartData_2_3: {
        settings: {
            type: "histogram",
            showLine: ["销售水平事业毛利率"],
            axisSite: { right: ["销售水平事业毛利率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            yAxisName: ["", ""],
        },
        chartField: [
            { Code: "A0104067", Name: "店均销售水平事业毛利", useAvg: true },
            { Code: "A0105023", Name: "销售水平事业毛利率" },
        ],
        rows: [],
        options: {
            textStyle,
            xAxis, grid: {
                left: "-1%",
                right: "-5%",
                bottom: "14px",
                containLabel: true,
            },
            yAxis: [
                {
                    name: "万元",
                    show: yAxisShow,
                    nameTextStyle: {
                        fontSize: font, color: "black",
                    },
                    type: "value",
                    axisLine,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return `${val / 10000}`;
                        },
                    },
                }, {
                    show: false,
                    axisLabel: {
                        fontSize: font, color: "black",
                        formatter: (val => {
                            return percentage(val);
                        }),
                    },
                },
            ],
            series: [
                {
                    barMaxWidth,
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle,
                            formatter: function(params) {
                                return (params.value / 10000).toFixed(2);
                            },
                        },

                    },
                }, {
                    symbolSize,
                    lineStyle,
                    itemStyle: PotItemStyle,
                    label: {
                        position,
                        show: true,
                        textStyle,
                        formatter: function(params) {
                            return (params.value * 100).toFixed(1) + "%";
                        },
                    },
                },
            ],

        },
    },
    chartData_2_4: {
        settings: {
            type: "histogram",
            showLine: ["用品", "新保", "金融"],
            //  axisSite: {right: ["裸车返利后毛利率"]},
            yAxisType: ["0.[0]%"],
            label: {
                show: true,
                //      position: "inside",
                textStyle,
                formatter: function(params) {
                    return percentage(params.data);
                },
            },
        },
        chartField: [
            { Code: "A0303271", Name: "用品" },
            { Code: "A0303272", Name: "新保" },
            { Code: "A0303273", Name: "金融" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            xAxis, grid: gridLine,
            yAxis: [
                {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return percentage(val);
                        },
                    },
                },
            ],
            series: [
                {
                    barMaxWidth,
                    symbolSize,
                    lineStyle,
                    itemStyle: PotItemStyle,
                    label: {
                        position,
                        show: true,
                        textStyle,

                    },
                }, {
                    symbolSize,
                    lineStyle,
                    itemStyle: PotItemStyle,
                    label: {
                        position,
                        show: true,
                        textStyle,
                    },
                }, {
                    symbolSize,
                    lineStyle,
                    itemStyle: PotItemStyle,
                    label: {
                        position,
                        show: true,
                        textStyle,
                    },
                },
            ],
        },
    },
    chartData_2_5: {
        settings: {
            type: "histogram",
            stack: { stack1: ["展厅终端销量占比", "iDCC终端销量占比", "二网终端销量占比", "大客户终端销量占比"] },
            yAxisType: ["0.[0]%"],
            yAxisName: ["", ""],
            label: {
                show: true,
                //        position: "inside",
                textStyle,
                formatter: function(params) {
                    return percentage(params.data);
                },
            },
        },
        chartField: [
            { Code: "A0304024", Name: "展厅终端销量占比" },
            { Code: "A0304025", Name: "iDCC终端销量占比" },
            { Code: "A0304026", Name: "二网终端销量占比" },
            { Code: "A0304027", Name: "大客户终端销量占比" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            xAxis, grid: {
                left: "-5%",
                right: "1%",
                bottom: "14px",
                containLabel: true,
            },
            yAxis: [
                {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return percentage(val);
                        },
                    },
                },
            ],
            series: [
                {
                    barMaxWidth,
                    label: {
                        normal: {
                            show: true,
                            textStyle,
                        },
                    },
                }, {
                    label: {
                        normal: {
                            show: true,
                            textStyle,
                        },
                    },
                },
            ],
        },
    },
    tableData_2_6: {
        useAvg: true,
        table: [{
            name: "展厅首次客流",
            kpiCode: "A1302012",
        }, {
            name: "展厅再次客流",
            kpiCode: "A1302013",
        }, {
            name: "展厅首次客流成交率",
            kpiCode: "A1303081",
        }, {
            name: "展厅再次客流成交率",
            kpiCode: "A1303082",
        }, {
            name: "IDCC线索总量",
            kpiCode: "A1302115",
        }, {
            name: "IDCC线索有效率",
            kpiCode: "A1303036",
        }, {
            name: "iDCC邀约到店率",
            kpiCode: "A1303024",
        }, {
            name: "iDCC邀约到店成交率",
            kpiCode: "A1303028",
        }, {
            name: "iDCC线索转化率",
            kpiCode: "A1303032",
        }],
        data: [],
    },

};

let regularizeData = {
    chartData_3_1: {
        settings: {
            type: "histogram",
            showLine: ["AB类客户数占比"],
            stack: { stack1: ["A", "B", "C", "新销售未回厂数"] },
            axisSite: { right: ["AB类客户数占比"] },
            yAxisType: ["0", "0.[0]%"],
            yAxisName: ["个", "%"],
            label: {
                normal: {
                    show: true,
                    fontSize: font,
                    color: "black",
                    formatter: function(params) {
                        if (params["componentSubType"] === "line") {
                            return percentage(params.data);
                        } else {
                            return (params.data).toFixed(0);
                        }
                    },
                },
            },
        },
        chartField: [
            { Code: "B0302034", Name: "A" },
            { Code: "B0302035", Name: "B" },
            { Code: "B0302036", Name: "C" },
            { Code: "B0302039", Name: "新销售未回厂数" },
            { Code: "B0303051", Name: "AB类客户数占比" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    name: "个",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    type: "value",
                    axisLabel: {
                        fontSize: font, color: "black",
                    },
                }, {
                    show: yAxisShow,
                    axisLabel: {
                        fontSize: font, color: "black",
                        formatter: (val => {
                            return percentage(val);
                        }),
                    },
                },
            ],
            series: [{ barMaxWidth }, {}, {}, {},
                {
                    symbolSize,
                    lineStyle,
                    itemStyle: PotItemStyle,
                    label: {
                        position,
                        show: true,
                        textStyle,
                    },
                },

            ],
        },
    },
    chartData_3_2: {
        settings: {
            type: "histogram",
            showLine: ["回厂台次占保客比"],
            axisSite: { right: ["回厂台次占保客比"] },
            yAxisType: ["", "0.[0]%"],
            yAxisName: ["", ""],
            label: {
                show: true,
                //        position: "inside",
                textStyle,
                formatter: function(params) {
                    if (params["componentSubType"] === "line") {
                        return percentage(params.data);
                    } else {
                        return (params.data).toFixed(0);
                    }
                },
            },
        },
        chartField: [
            { Code: "B0302001", Name: "店均来厂台次" },
            { Code: "B0303081", Name: "回厂台次占保客比" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            }, grid: {
                left: "-2%",
                right: "-4%",
                bottom: "14px",
            }, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    name: "台",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    type: "value",
                    axisLabel: {
                        fontSize: font, color: "black",
                    },
                }, {
                    show: yAxisShow,
                    axisLabel: {
                        fontSize: font, color: "black",
                        formatter: (val => {
                            return percentage(val);
                        }),
                    },
                },
            ],
            series: [{ barMaxWidth }, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            }],
        },
    },
    chartData_3_3: {
        settings: {
            type: "histogram",
            showLine: ["单车售后维修业务产值"],
            stack: { stack1: ["店均零部件产值", "店均工时产值"] },
            yAxisType: ["0.[00]", "0.[00]"],
            axisSite: { right: ["单车售后维修业务产值"] },
            yAxisName: [""],
            label: {
                show: true,
                //         position: "inside",
                textStyle,
                formatter: function(params) {
                    if (params["componentSubType"] === "line") {
                        return (params.data).toFixed(0);
                    } else {
                        return (params.data / 10000).toFixed(2);
                    }
                },
            },
        },
        chartField: [
            { Code: "B0103007", Name: "店均零部件产值", useAvg: true },
            { Code: "B0103047", Name: "店均工时产值", useAvg: true },
            { Code: "B0104226", Name: "单车售后维修业务产值", useAvg: true },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid: {
                left: "-7%",
                right: "-3%",
                bottom: "14px",
            }, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    name: "元",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    type: "value",
                    axisLabel: {
                        fontSize: font, color: "black",
                    },
                }, {
                    show: yAxisShow,
                    name: "万元",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    axisLabel: {
                        fontSize: font, color: "black",
                        formatter: (val => {
                            return tenThousandYuan(val);
                        }),
                    },
                },
            ],
            series: [{
                barMaxWidth,
            }, {}, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            }],
        },
    },
    chartData_3_4: {
        settings: {
            type: "histogram",
            showLine: ["售后维修业务毛利率"],
            stack: { stack1: ["单车工时毛利", "单车零部件毛利"] },
            yAxisType: ["0", "0.[00]"],
            axisSite: { right: ["售后维修业务毛利率"] },
            yAxisName: [""],
            label: {
                show: true,
                //         position: "inside",
                textStyle,
                formatter: function(params) {
                    if (params["componentSubType"] === "line") {
                        return percentage(params.data);
                    } else {
                        return (params.data).toFixed(0);
                    }
                },
            },
        },
        chartField: [
            { Code: "B0105161", Name: "单车工时毛利", useAvg: true },
            { Code: "B0105131", Name: "单车零部件毛利", useAvg: true },
            { Code: "B0105181", Name: "售后维修业务毛利率", useAvg: true },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    name: "元",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    type: "value",
                    axisLabel: {
                        fontSize: font, color: "black",
                    },
                }, {
                    show: yAxisShow,
                    axisLabel: {
                        fontSize: font, color: "black",
                        formatter: (val => {
                            return percentage(val);
                        }),
                    },
                },
            ],
            series: [{ barMaxWidth }, {}, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            }],
        },
    },
    chartData_3_5: {
        settings: {
            type: "histogram",
            showLine: ["售后水平事业毛利率"],
            axisSite: { right: ["售后水平事业毛利率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            yAxisName: ["", ""],
        },
        chartField: [
            { Code: "B0104187", Name: "售后水平事业毛利", useAvg: true },
            { Code: "B0105108", Name: "售后水平事业毛利率" },
        ],
        rows: [],
        options: myOptionTwo(font),
    },
    specialChartData_3_6: {
        settings: {
            type: "histogram",
            showLine: ["服务满足率"],
            axisSite: { right: ["服务满足率"] },
            yAxisType: ["", "0.[0]%"],
            yAxisName: ["", ""],

        },
        chartField: {
            Code: "C0207021", Columns: ["服务满足率大于100%的店数", {
                Code: "C0207021", Name: "服务满足率",
            }],
        },
        rows: [],
        options: myOptionColor(font),
    },
};

let propertyData = {
    specialChartData_4_1: {
        settings: {
            type: "histogram",
            showLine: ["资产负责率"],
            stack: { stack1: ["资产负债率<40%的店", "40%~70%的店", ">70%的店"] },
            yAxisType: ["0"],
            yAxisName: [""],
            label: {
              //  position: "right",
                show: true,
                textStyle,
                formatter: function(params) {
                    return ThousandYuan(params.data);
                },
            },
        },
        chartField: { Code: "C0107003", Columns: ["资产负债率<40%的店", "40%~70%的店", ">70%的店", "资产负责率"] },
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid: {
                left: "-2%",
                right: "1%",
                bottom: "14px",
                containLabel: true,
            }, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return ThousandYuan(val);
                        },
                    },
                },
            ],
            series: [{ barMaxWidth }],
        },
    },
    chartData_4_2: {
        settings: {
            type: "histogram",
            showLine: ["速动比率", "流动比率"],
            yAxisType: ["0.[00]"],
            yAxisName: [""],
            label: {
                show: true,
                textStyle,
                formatter: function(params) {
                    return params.data.toFixed(2);
                },
            },
        },
        chartField: [
            { Code: "C0107002", Name: "速动比率" },
            { Code: "C0106003", Name: "流动比率" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid: {
                left: "-2%",
                right: "1%",
                bottom: "14px",
                containLabel: true,
            }, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                    },
                },
            ],
            series: [{
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            }, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                },
            }],
        },
    },
};

let costData = {
    chartData_5_1: {
        settings: {
            type: "histogram",
            showLine: ["费用率"],
            axisSite: { right: ["费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            yAxisName: ["", ""],
            label: {
                show: true,
                //           position: "inside",
                textStyle,
                formatter: function(params) {
                    if (params["componentSubType"] === "line") {
                        return percentage(params.data);
                    } else {
                        return (params.data).toFixed(2);
                    }
                },
            },
        },
        chartField: [
            { Code: "C0206001", Name: "总费用" },
            { Code: "C0207022", Name: "费用率" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },

            yAxis: [
                {
                    show: yAxisShow,
                    name: "万元",
                    nameTextStyle: {
                        fontSize: font,
                    },
                    type: "value",
                    axisLine,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return YtenThousandYuan(val);
                        },
                    },
                }, {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                        formatter: (val) => {
                            return percentage(val);
                        },
                    },
                },
            ],
            grid: {
                left: "-1%",
                right: "-4%",
                bottom: "14px",
                containLabel: true,
            }, xAxis,
            series: [
                {
                    barMaxWidth,
                    label: {
                        normal: {
                            show: true,
                            //   position:'insideRight',
                            textStyle,
                            formatter: function(params) {
                                return tenThousandYuan(params.value);
                            },
                        },
                    },
                }, {
                    symbolSize,
                    lineStyle,
                    itemStyle: PotItemStyle,
                    label: {
                        position,
                        show: true,
                        textStyle,
                        formatter: function(params) {
                            return percentage(params.value);
                        },
                    },
                },
            ],
        },
    },
    tableData_5_2: {
        table: [{
            name: "人工费用",
            kpiCode: "C0202020",
        }, {
            name: "固定费用",
            kpiCode: "C0202020",
        }, {
            name: "其中：租赁费",
            kpiCode: "C0202025",
        }, {
            name: "半固定费用",
            kpiCode: "C0202124",
        }, {
            name: "财务费用",
            kpiCode: "C0202238",
        }],
        data: [],
    },
    tableData_5_3: {
        useAvg: true,
        table: [{
            name: "平均单车总费用",
            kpiCode: "C0207009",
        }, {
            name: "平均单车广宣费用",
            kpiCode: "A0205034",
        }, {
            name: "平均单车财务费用",
            kpiCode: "A0205035",
        }, {
            name: "平均单车销售顾问工资",
            kpiCode: "A0204032",
        }],
        data: [],
    },
    chartData_5_4: {
        settings: {
            type: "histogram",
            showLine: ["长库龄占比"],
            axisSite: { right: ["长库龄占比"] },
            yAxisType: ["0", "0.[0]%"],
            yAxisName: ["", ""],
        },
        chartField: [
            { Code: "A0403155", Name: "长库龄台次" },
            { Code: "A0404111", Name: "长库龄占比" },
        ],
        rows: [],
        options: {
            legend: {
                textStyle,
            },
            grid: {
                left: "-1%",
                right: "-1%",
                bottom: "14px",
                containLabel: true,
            }, xAxis,
            yAxis: [
                {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                    },
                }, {
                    show: yAxisShow,
                    axisLabel: {
                        textStyle,
                    },
                },
            ],
            series: [{
                barMaxWidth,
                label: {
                    //   position: "top",
                    show: true,
                    textStyle,
                    formatter: function(params) {
                        return ThousandYuan(params.value);
                    },
                },
            }, {
                symbolSize,
                lineStyle,
                itemStyle: PotItemStyle,
                label: {
                    position,
                    show: true,
                    textStyle,
                    formatter: function(params) {
                        return percentage(params.value);
                    },
                },
            }],
        },
    },
};

export
{
    columnsImport, overViewData, variableData, regularizeData, propertyData, costData,
};



