/**
 * js自带的toFixed方法，采用的是四舍六入五成双方法，对于普通用户十分诡异，而且返回的是一个字符串
 * 所以写一个简单的处理方法
 *
 * @param {number} num 待处理数字
 * @param {number} fractionDigits=2 保留有效数字
 * @returns {number} 返回处理后的数字
 *
 * @example
 *
 * _.simpleFixed(1.234,2);
 * //=> 1.23
 */

//四舍五入法，返回数字
export function simpleFixed(num, fractionDigits = 2) {
 return (parseInt(String( num * Math.pow( 10,fractionDigits ) + 0.5 )) / Math.pow(10, fractionDigits));
}

