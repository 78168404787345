import ModelAnalysis from "@/views/ModelAnalysis/ModelAnalysis";
import MarketYearRate from "@/views/modelYearAnalysis/marketYearRate";
import modelAnalysisUpload from "@/views/ModelAnalysis/Upload";
import yearAnalysis from "@/views/incomeAnalysis/yearAnalysis";
import monthAnalysis from "@/views/incomeAnalysis/monthAnalysis";
import monthAnalysisStatistics from "@/views/incomeAnalysis/statisticsList";
import modelAnalysisReset from "@/views/incomeAnalysis/list";
import RealTimeModelAnalysis from "@/views/realTimeModelAnalysis/realTimeModelAnalysis";
import RealTimeModelAnalysisImg from "@/views/realTimeModelAnalysis/realTimeModelAnalysisImg";
import MonthModelAnalysis from "@/views/monthModelAnalysis/monthModelAnalysis";
import DayModelAnalysis from "@/views/monthModelAnalysis/dayModelAnalysis";
import YearModelAnalysis from "@/views/monthModelAnalysis/yearModelAnalysis";
import afterSaleYearAnalysis from "@/views/afterSaleAnalysis/yearAnalysis";
import afterSaleMonthAnalysis from "@/views/afterSaleAnalysis/monthAnalysis";

let businessManageRouter = [
    // 市占率
    {
        path: "/modelAnalysis",
        component: ModelAnalysis,
    },// 年度市占率
    {
        path: "/marketYearRate",
        component: MarketYearRate,
    },
    //实时车型分析
    {
        path: "/realTimeModelAnalysis",
        component: RealTimeModelAnalysis,
    },
    {
        path: "/realTimeModelAnalysisImg",
        component: RealTimeModelAnalysisImg,
    },
    //月度车型分析（）
    {
        path: "/monthModelAnalysis",
        component: MonthModelAnalysis,
    },
    //日度车型分析（）
    {
        path: "/dayModelAnalysis",
        component: DayModelAnalysis,
    },
    //年度车型分析（）
    {
        path: "/yearModelAnalysis",
        component: YearModelAnalysis,
    },
    {
        path: "/modelAnalysisUpload",
        component: modelAnalysisUpload
    },
    //年度盈利预测
    {
        path: "/modelYearAnalysis",
        component: yearAnalysis
    },
    //月度盈利预测（月度盈利分析）
    {
        path: "/modelMonthAnalysis",
        component: monthAnalysis
    },
    //盈利预测填报情况查看
    {
        path: "/modelAnalysisStatistics",
        component: monthAnalysisStatistics
    },
    //售后板块月推移
    {
        path: "/aftersaleMonthAnalysis",
        component: afterSaleMonthAnalysis
    },
    //售后板块年推移
    {
        path: "/aftersaleYearAnalysis",
        component: afterSaleYearAnalysis
    },
    //填报区间
    {
        path: "/modelAnalysis/reset",
        component: modelAnalysisReset
    }
];

export default businessManageRouter;
