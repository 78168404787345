<template>
  <section>
    <a-card>
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :labelCol="{ span: 2 }"
        :wrapperCol="{ span: 4 }"
      >
        <a-form-model-item label="旧密码" prop="oldPwd">
          <a-input-password v-model.trim="form.oldPwd" />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPwd">
          <a-input-password v-model.trim="form.newPwd" />
        </a-form-model-item>
        <a-form-model-item label="确认新密码" prop="confirmPwd">
          <a-input-password v-model.trim="form.confirmPwd" />
        </a-form-model-item>
        <a-form-model-item label="所属销售店">
          <p style="color: red; font-size: 25px; font-weight: bold">
            {{ thisCompany }}
          </p>
        </a-form-model-item>
        <a-row>
          <a-col :span="2" :offset="20">
            <a-button type="primary" @click="save"> 确认修改</a-button>
          </a-col>
          <a-col :span="2">
            <a-button @click="$router.go(-1)"> 取消</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>
<script>
export default {
  data() {
    /*新密码验证*/
    var checkNewPassword = (rule, value, callback) => {
      if (value == "") {
        return callback(new Error("请输入新密码"));
      } else {
        if (this.form.oldPwd == value) {
          return callback(new Error("新密码不能与旧密码相同"));
        }
        else {
          callback();
        }
      }
    };
    /*确认密码验证*/
    var checkConfirmPassword = (rule, value, callback) => {
      if (value == "") {
        return callback(new Error("请确认新密码"));
      } else {
        if (this.form.newPwd != value) {
          return callback(new Error("两次输入的新密码不一致"));
        } else {
          callback();
        }
      }
    };
    return {
      form: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
      },
      thisCompany: "",
      //验证规则
      rules: {
        oldPwd: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: ["blur", "change"],
          },
        ],
        newPwd: [
          { validator: checkNewPassword, trigger: ["blur"] },
          {
            min: 8,
            message: "长度最少8位",
            trigger: "blur",
          },
          {
            max: 25,
            message: "长度最多25位",
            trigger: "blur",
          },
            {
                message: "新密码必须含有大小写字母、数字、字符",
                pattern: /^(?=(?:.*?\d){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?=.*[a-z]){1}(?=(?:.*?[A-Z]){1})/,
                trigger: 'blur'
            }
        ],
        confirmPwd: [{ validator: checkConfirmPassword, trigger: ["blur"] }],
      },
    };
  },
  created() {
    this.getThisCompany();
  },
  mounted() {},
  computed: {},
  methods: {
    async getThisCompany() {
      let self = this;
      let res = await self.$Http.GetThisCompany();
      if (res.Data.length > 0) {
        self.thisCompany = res.Data[0].CompanyNameCN;
      }
    },
    CookieClear() {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    async save() {
      let self = this;
      self.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await self.$Http.changePwd(self.form);
          if (res.Success) {
            self.$message.success(res.Data);
            this.CookieClear();
            window.open("/login", "_top");
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
  },
  filters: {},
};
</script>
<style lang="less" scoped>
</style>
