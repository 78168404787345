<template>
    <section>
        <a-card>
            选择月份：
            <a-range-picker style="margin-right: 20px"
                    format="YYYY-MM" :placeholder="['开始月份', '结束月份']"
                    :value="search.dateRange" :mode="search.dataMode"
                    @change="dateHandleChange" @panelChange="dateHandlePanelChange"
            />
            对标小组：
            <a-select style="margin-right: 20px;width: 200px" v-model="search.groupOption"
                    :labelInValue="true"
            >
                <a-select-opt-group label="对我可见" v-if="groups.false!=null">
                    <a-select-option v-for="group in groups.false" :key="group.GroupId">
                        {{ group.GroupName }}
                    </a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="通用" v-if="groups.true!=null">
                    <a-select-option v-for="group in groups.true" :key="group.GroupId">
                        {{ group.GroupName }}
                    </a-select-option>
                </a-select-opt-group>
            </a-select>
            <a-button type="primary" style="margin-right: 20px" @click="getGroupReport">
                搜索
            </a-button>
            <a-button type="default" @click="()=>{
      this.search.dateRange = [];
      this.search.groupOption = {key: 0, label: '--请选择--'};
    }"
            >
                重置
            </a-button>
        </a-card>
        <a-card title="特约店业绩小组对标分析报告" style="margin-top: 8px;margin-bottom: 18px;">
            <a-tabs v-model="activeTypeId" @change="getGroupReport">
                <a-tab-pane v-for="type in types" :key="type.ID" :tab="type.Name">
                    <div v-if="type.ID===activeTypeId">
                        <h2 style="text-align: center" v-if="tableColumns.length>0 && search.dateRange.length===2">
                            特约店总体情况汇总({{ search.dateRange[0].format("YYYY年M月") }}-{{
                                search.dateRange[1].format("YYYY年M月") }})</h2>
                        <a-table :rowKey="(record, index) => index" :columns="tableColumns" :data-source="tableData"
                                :pagination="false" bordered :scroll="{ x: 'max-content',y: false}"
                        >
               <span slot="valueOutput" slot-scope="text, record">
               <span v-if="record.Icon">
                 <span style="color: red;font-size: 20px" v-if="record.MaxValue===text">↑</span>
                 <span style="color: green;font-size: 20px" v-if="record.MinValue===text">↓</span>
               </span>
               {{ text }}
               </span>
                        </a-table>
                    </div>
                </a-tab-pane>
                <a-button slot="tabBarExtraContent" type="primary" @click="downloadExcel" style="margin-right: 20px">
                    <a-icon type="cloud-download"/>
                    下载报表
                </a-button>
            </a-tabs>
        </a-card>
        <div v-show="isTop" @click="backtop" class="ant-back-top">
            <div class="" style="height: 40px; width: 40px; line-height: 40px; border-radius: 4px; background-color: #24619e; color: rgb(255, 255, 255); text-align: center; font-size: 20px;"><a-icon type="vertical-align-top" /></div>
        </div>
    </section>
</template>

<script>

export default {
 data() {
  return {
   isTop: false,
   groups: [],
   types: [],
   tableData: [],
   tableColumns: [],
   activeTypeId: 0,
   search: {
    dateRange: [],
    dataMode: ["month", "month"],
    groupOption: { key: 0, label: "--请选择--" },
   },
  };
 },
 created() {
  this.getDealerGroupsDropList();
  this.getTypeNameList();
  window.addEventListener("scroll", this.showBtn, true);
 },
 methods: {
  // 显示回到顶部按钮
  showBtn() {
   let that = this;
   that.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
   that.isTop = that.scrollTop > 200;
  },
  backtop() {
   document.body.scrollTop = 0;
   document.documentElement.scrollTop = 0;
  },
  dateHandleChange(value) {
   this.search.dateRange = value;
  },
  dateHandlePanelChange(value, mode) {
   this.search.dateRange = value;
   this.search.dataMode = [mode[0] === "date" ? "month" : mode[0], mode[1] === "date" ? "month" : mode[1]];
  },
  async getDealerGroupsDropList() {
   let res = await this.$Http.queryDealerGroup();
   if(!res.Success) {
    this.$message.error(res.Message);
    return;
   }
   this.groups = res.Data.chain().groupBy(d => d.CreateUserId === "").value();
  },
  async getTypeNameList() {
   let res = await this.$Http.getTypeNameList();
   if(!res.Success) {
    this.$message.error(res.Message);
    return;
   }
   this.types = res.ApiParamObj;
   if(this.types.length > 0) {
    this.activeTypeId = this.types[0].ID;
   }
  },
  paramsIsError() {
   if(this.search.dateRange.length !== 2) {
    this.$message.warn("请选择月份");
    return true;
   }
   if(this.search.groupOption.key <= 0) {
    this.$message.warn("请选择对标小组");
    return true;
   }
   return false;
  },
  async getGroupReport() {
   this.tableColumns = [];
   this.tableData = [];
   if(this.paramsIsError()) {
    return;
   }
   let res = await this.$Http.getGroupReport({
    groupId: this.search.groupOption.key,
    subjectType: this.activeTypeId,
    startDate: this.search.dateRange[0].format("YYYY-MM-01"),
    endDate: this.search.dateRange[1].format("YYYY-MM-01"),
   });
   if(!res.Success) {
    this.$message.error(res.Message);
    return;
   }
   let dealerInfo = res.Data.Dealers;
   // 绑定数据
   res.Data.ReportData.forEach(data => {
    dealerInfo.forEach(dealer => {
     data[dealer.DealerName] = data.DealerValues[dealer.DealerName];
    });
   });
   this.tableData = res.Data.ReportData;
   this.tableColumns = [
    {
     title: "序号",
     dataIndex: "Index",
     key: "Index",
     width: 80,
     fixed: true,
    },
    {
     title: "指标类别",
     dataIndex: "FirstSubjectCode",
     key: "FirstSubjectCode",
     width: 150,
     fixed: true,
     customRender: (value, row) => {
      return {
       children: value,
       attrs: { rowSpan: row.RowSpan },
      };
     },
    },
    {
     title: "指标名称",
     dataIndex: "SecondSubject",
     key: "SecondSubject",
     width: 150,
     fixed: true,
     customCell: this.customCell,
    },
    {
     title: "小组标杆值",
     dataIndex: "StandardCont",
     key: "StandardCont",
     width: 150,
     fixed: true,
     customCell: this.customCell,
    },
    {
     title: "小组中位值",
     dataIndex: "MedianCont",
     key: "MedianCont",
     width: 150,
     fixed: true,
     customCell: this.customCell,
    },
    {
     title: "单位",
     dataIndex: "Unit",
     key: "Unit",
     width: 100,
     fixed: true,
     customCell: this.customCell,
    }];
   if(dealerInfo.length < 11) {
    this.tableColumns.forEach(d => {
     d.fixed = false;
    });
   }
   dealerInfo.forEach(d => {
    this.tableColumns.push({
     title: d.DealerName,
     dataIndex: d.DealerName,
     key: d.DealerName,
     //    width: 150,
     customCell: this.customCell,
     scopedSlots: { customRender: "valueOutput" },
    });
   });
  },
  downloadExcel() {
   if(this.paramsIsError()) {
    return;
   }
   let a = document.createElement("a");
   a.href = this.$Http.baseUrl + "/XCalculator/GroupReportDown?groupId=" + this.search.groupOption.key +
    "&groupName=" + this.search.groupOption.label.trim() +
    "&startDate=" + this.search.dateRange[0].format("YYYY-MM-01") +
    "&endDate=" + this.search.dateRange[1].format("YYYY-MM-01");
   a.click();
  },
  customCell(record) {
   let style = {};
   // 背景颜色
   if(record.BgColor) {
    style["background-color"] = "#DDDDDD";
   }
   return { style };
  },
 },
};
</script>
<style lang="less">
.ant-back-top {
  bottom:18px !important;
  right: 46px !important;
  }
</style>
