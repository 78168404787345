<template>
  <table>
    <tr v-for="(item,i) in customTableData.data" :key="i" :style="`margin-top:${item.name==='IDCC线索总量'?70:0 }px`">
      <td :style="`padding-left:${item.indentation}em`">{{ item.name }}</td>
      <td v-for="(d,j) in item.arr" :key="j++">
        {{ d === "0.00" || d === "0" || d === "0.0%" ? "-" : d }}
      </td>
    </tr>
    <tr>
      <td>月份</td>
      <td>月均</td>
      <td v-for=" i in 12" :key="i++">
        {{ i + "月" }}
      </td>
    </tr>
  </table>
</template>

<script>

export default {
  name: "customTableComment",
  props: {
    customTableData: {
      data: [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import "./src/assets/printCommon.less";

</style>
