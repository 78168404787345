<template>
    <a-row :guter="[28, 28]">
        <a-col :span="24">
            <a-card style="overflow-x: scroll">
                <div>
                    <table class="common_table1">
                        <thead>
                        <th v-for="(item, i) in tableData.table_1.columnsData">
                            {{ item }}
                        </th>
                        </thead>
                        <tbody>
                        <tr v-for="(row, i) in tableData.table_1.data" :key="i">
                            <td v-for="(item,i) in row" :key="i">
                                {{ item }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </a-card>
        </a-col>
        <!--  线索分析      -->
        <a-col class="carding2" :span="24">
            <a-card>
                <div class="title">线索分析</div>
                <table class="common_table">
                    <thead>
                    <th v-for="(item, k) in tableData.table_2.columnsData" :key="k++">
                        {{ item }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_2.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>
            </a-card>
        </a-col>
        <!--   线索分析      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    线索分析【线索月累计：<span
                    style="color: #24619e">{{
                        (getDataFromBackendData(this.carTypeCode["总线索"][this.dataParams.carType], "MonthSum")).toFixed(0)
                    }}条</span>】
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData1"/>
                </div>
            </a-card>
        </a-col>
        <!--客流分析        -->
        <a-col class="carding2" :span="24">
            <a-card>
                <div class="title">客流分析</div>
                <table class="common_table">
                    <thead>
                    <th
                        v-for="(item, i) in tableData.table_3.columnsData"
                        :key="i"
                    >
                        {{ item }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_3.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>
            </a-card>
        </a-col>

        <!--   客流分析      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    客流分析【客流月累计：<span
                    style="color: #24619e">
                    {{
                        (getDataFromBackendData(this.carTypeCode["总客流"][this.dataParams.carType], "MonthSum")).toFixed(0)
                    }}
                    个</span>】
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData2"/>
                </div>
            </a-card>
        </a-col>
        <!--基盘客户分析        -->
        <a-col class="carding2" :span="24">
            <a-card>
                <div class="title">基盘客户分析</div>
                <table class="common_table">
                    <thead>
                    <th
                        v-for="(item, i) in tableData.table_4.columnsData"
                        :key="i"
                    >
                        {{ item }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_4.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>
            </a-card>
        </a-col>
        <!--   基盘客户分析      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    基盘客户分析
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData3"/>
                </div>
            </a-card>
        </a-col>
        <!--订单总数        -->
        <a-col class="carding2" :span="24">
            <a-card>
                <div class="title">订单总数</div>
                <table class="common_table">
                    <thead>
                    <th
                        v-for="(item, i) in tableData.table_5.columnsData"
                        :key="i"
                    >
                        {{ item }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_5.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>
            </a-card>
        </a-col>
        <!--   订单总数      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    订单总数【订单月累计：<span
                    style="color: #24619e">{{
                        (getDataFromBackendData(this.carTypeCode["总订单"][this.dataParams.carType], "MonthSum")).toFixed(0)
                    }}个</span>】
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData4"/>
                </div>
            </a-card>
        </a-col>

        <!--   各车型终端分析      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">各车型订单数</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData7"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">终端总数【终端月累计：<span
                    style="color: #24619e">{{
                        (getDataFromBackendData(this.carTypeCode["终端实绩"][this.dataParams.carType], "MonthSum")).toFixed(0)
                    }}台</span>】
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData8"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">分车型终端实绩</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData13"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">分车型终端车型占比</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData14"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">终端通道结构分析</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData9"/>
                </div>
            </a-card>
        </a-col>

        <!--   提车总数      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    提车总数【提车月累计：<span
                    style="color: #24619e">{{
                        (getDataFromBackendData(this.carTypeCode["提车实绩"][this.dataParams.carType], "MonthSum")).toFixed(0)
                    }}台</span>】
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData10"/>
                </div>
            </a-card>
        </a-col>

        <!--   库存总数      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">库存总数【库存当月值：<span
                    style="color: #24619e">{{
                        (getDataFromBackendData(this.carTypeCode["库存"][this.dataParams.carType], "N-0")).toFixed(0)
                    }}台</span>】
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData11"/>
                </div>
            </a-card>
        </a-col>
        <!--   分车型库存实绩      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">分车型库存实绩</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData15"/>
                </div>
            </a-card>
        </a-col>
        <!--   分车型库存占比      -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">分车型库存占比</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData16"/>
                </div>
            </a-card>
        </a-col>
        <!--库存明细         -->
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title"
                     style="display: flex;justify-content: space-between;margin-bottom: 10px;align-items: center">库存明细
                    <div>
                        筛选日期：
                        <a-select v-model="currentDay"
                                  style="width: 200px;margin-left:1vw;"
                                  @change="currentDayChange"
                        >
                            <a-select-option v-for="(item,i) in dayList" :key="i" :value="item">
                                {{ item }}日
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <table>
                    <thead>
                    <th
                        v-for="(item, i) in tableData.table_7.columnsData"
                        :key="i"
                    >
                        {{ item.name }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_7.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>

            </a-card>
        </a-col>

        <!--分车型终端让价        -->
        <a-col class="carding" :span="24">
            <a-card style="overflow-x: scroll">
                <div class="title">分车型终端让价</div>
                <table class="common_table">
                    <thead>
                    <th
                        v-for="(item, i) in tableData.table_8.columnsData"
                        :key="i"
                    >
                        {{ item }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_8.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">分车型终端让价</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData17"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card style="overflow-x: scroll">
                <div class="title">分车型裸车毛利</div>
                <table class="common_table">
                    <thead>
                    <th
                        v-for="(item, i) in tableData.table_9.columnsData"
                        :key="i"
                    >
                        {{ item }}
                    </th>
                    </thead>
                    <tr v-for="(row, i) in tableData.table_9.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                </table>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">分车型裸车毛利</div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chartData18"/>
                </div>
            </a-card>
        </a-col>
    </a-row>
</template>

<script>
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import {
    grid, itemStyle, lineStyle, symbolSize,
} from "@/views/monthModelAnalysis/monthModelAnalysisChildComponent/chartConfig";
import {
     xAxis, yAxisDefault, yAxisRight, yAxisRight2, seriesBar, seriesLine,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import moment from "moment";
import {carTypeCode} from "@/views/monthModelAnalysis/daycarTypeCode";


export default {
    name: "oneRealTime",
    components: {mainChart},
    props: {
        dataParams: {},
    },
    data() {
        return {
            carTypeCode,
            backendData: {},
            table7BackendData: {},
            ranges: [],
            currentMoment: moment(),
            currentYear: "",
            currentMonth: '',
            currentDay: 1,
            dayList: [],
            lineStyle,
            symbolSize,
            itemStyle,
            grid,
            tableData: {
                table_1: {
                    columnsData: [],
                    rowData: [
                        "总线索",
                        "总客流",
                        "总订单",
                        "订单成交率",
                        "终端实绩",
                        "终端达成率",
                        "提车实绩",
                        "提车达成率",
                        "库存",
                        "库销比",
                        "长库龄占比",
                        "现金车占比",
                        "终端让价",
                        "单车裸车毛利",
                    ],
                    data: [],
                    code: []
                },
                //线索分析
                table_2: {
                    columnsData: [""],
                    rowData: ["总线索", "垂媒线索", "占比"],
                    data: [],
                    code: [],
                },
                //客流分析
                table_3: {
                    columnsData: [""],
                    rowData: ["客流总数"],
                    data: [],
                    code: [],
                },
                //基盘客户分析
                table_4: {
                    columnsData: [""],
                    rowData: ["基盘客户", "HAB客户", "占比"],
                    data: [],
                    code: [],
                },
                // 订单总数
                table_5: {
                    columnsData: [""],
                    rowData: ["订单总数"],
                    data: [],
                    code: [],
                },

                //分车型终端让价
                table_8: {
                    columnsData: [""],
                    rowData: ["单车终端让价"],
                    data: [],
                    code: [],
                },
                //分车型裸车毛利
                table_9: {
                    columnsData: [""],
                    rowData: ["单车裸车毛利"],
                    data: [],
                    code: [],
                },
                table_7: {
                    columnsData: [
                        {
                            name: "指标名称",
                        },
                        {
                            name: "库销比",
                        },
                        {
                            name: "0-30天库存",
                        },
                        {
                            name: "31-60天库存",
                        },
                        {
                            name: "61-90天库存",
                        },
                        {
                            name: ">90天库存",
                        },
                        {
                            name: "总库存",
                        },
                        {
                            name: "其中-现金车台次",
                        },
                    ],
                    rowData: [
                        "GS3",
                        "GS3 Power",
                        "GS4",
                        "GS4 PHEV",
                        "GS4 COUPE",
                        "GS4PLUS",
                        "GS7",
                        "GS8",
                        "GS8S",
                        "M6",
                        "M8",
                        "GA4",
                        "GA6",
                        "GA8",
                        "影豹","其他",
                        "合计",
                    ],
                    data: [],
                    code: []
                },
            },
            chartData: {
                chartData1: {
                    columns: [
                        "月份",
                        "汽车之家",
                        "易车",
                        "懂车帝",
                        "太平洋",
                        "广宣线索",
                        "自店",
                        "其他",
                        "总线索同比",
                        "总线索环比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [
                                "汽车之家",
                                "易车",
                                "懂车帝",
                                "太平洋",
                                "广宣线索",
                                "自店",
                                "其他",
                            ],
                        },
                        showLine: ["总线索同比", "总线索环比"],
                        axisSite: {
                            right: ["总线索同比", "总线索环比"],
                        },

                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["条", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}条`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault, yAxisRight
                        ],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData2: {
                    columns: [
                        "月份",
                        "展厅首次客流",
                        "展厅再次客流",
                        "IDCC客流",
                        "客流总数环比",
                        "客流总数同比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [
                                "展厅首次客流",
                                "展厅再次客流",
                                "IDCC客流",
                            ],
                        },
                        showLine: ["客流总数环比", "客流总数同比"],
                        axisSite: {
                            right: ["客流总数环比", "客流总数同比"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["个", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}个`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault, yAxisRight
                        ],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData3: {
                    columns: [
                        "月份",
                        "H级客户数",
                        "A级客户数",
                        "B级客户数",
                        "C级客户数",
                        "H级客户占比",
                        "A级客户占比",
                        "B级客户占比",
                        "C级客户占比",
                        "基盘客户同比",
                        "基盘客户环比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["H级客户数", "A级客户数", "B级客户数", "C级客户数"],
                        },
                        showLine: ["H级客户占比", "A级客户占比", "B级客户占比", "C级客户占比", "基盘客户同比", "基盘客户环比"],
                        axisSite: {
                            right: ["H级客户占比", "A级客户占比", "B级客户占比", "C级客户占比", "基盘客户同比", "基盘客户环比"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["个", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                   x: "left",      //可设定图例在左、右、居中
                                   y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}个`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault, yAxisRight
                        ],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar, seriesLine, seriesLine, seriesLine, seriesLine,
                            seriesLine, seriesLine,
                        ],
                    },
                },
                chartData4: {
                    columns: ["月份", "订单总数", "订单总数环比", "订单总数同比"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["订单总数"],
                        },
                        showLine: ["订单总数环比", "订单总数同比"],
                        axisSite: {
                            right: ["订单总数环比", "订单总数同比"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["个", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}个`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault, yAxisRight
                        ],
                        series: [
                            seriesBar, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData7: {
                    columns: ["月份", "GS3 POWER",  "GS4", "GS4PLUS","GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["GS3 POWER",  "GS4", "GS4PLUS","GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["个"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}个`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault
                        ],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar,
                        ],
                    },
                },
                chartData13: {
                    columns: ["月份", "GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["台"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault
                        ],
                        series: [
                            {
                                barMaxWidth: "40%",
                            },
                        ],
                    },
                },
                chartData14: {
                    columns: ["月份","GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: { val: ["GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],},
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]%"],
                        yAxisName: ["%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            (d.value ?? 0) * 100
                                        ).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisRight2],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar,
                        ],
                    },
                },
                chartData8: {
                    columns: [
                        "月份",
                        "终端总数",
                        "终端总数环比",
                        "终端总数同比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: ["终端总数环比", "终端总数同比"],
                        axisSite: {
                            right: ["终端总数环比", "终端总数同比"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["台", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [
                            yAxisDefault, yAxisRight
                        ],
                        series: [
                            seriesBar, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData9: {
                    columns: [
                        "月份",
                        "展厅终端销量",
                        "IDCC终端销量",
                        "二网终端销量",
                        "大客户终端销量",
                        "展厅终端销量占比",
                        "IDCC终端销量占比",
                        "二网终端销量占比",
                        "大客户终端销量占比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["展厅终端销量",
                                "IDCC终端销量",
                                "二网终端销量",
                                "大客户终端销量"],
                        },
                        showLine: [
                            "展厅终端销量占比",
                            "IDCC终端销量占比",
                            "二网终端销量占比",
                            "大客户终端销量占比"],
                        axisSite: {
                            right: [
                                "展厅终端销量占比",
                                "IDCC终端销量占比",
                                "二网终端销量占比",
                                "大客户终端销量占比"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["台", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar, seriesLine, seriesLine, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData10: {
                    columns: [
                        "月份",
                        "提车总数",
                        "提车总数环比",
                        "提车总数同比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: [
                            "提车总数环比",
                            "提车总数同比",],
                        axisSite: {
                            right: [
                                "提车总数环比",
                                "提车总数同比",],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["台", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData11: {
                    columns: [
                        "月份",
                        "库存总数",
                        "库存总数环比",
                        "库存总数同比",
                        "库销比",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: ["库存总数环比",
                            "库存总数同比",
                            "库销比",
                        ],
                        axisSite: {
                            right: ["库存总数环比",
                                "库存总数同比",
                                "库销比",
                            ],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["台", "%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        if (d.seriesName === "库销比") {
                                            arr[i] = `${d.marker} ${d.seriesName}: ${
                                                Number((d.value ?? 0)).toFixed(2)}倍`;
                                        } else {
                                            arr[i] = `${d.marker} ${d.seriesName}: ${
                                                Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                        }

                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar, seriesLine, seriesLine, seriesLine,
                        ],
                    },
                },
                chartData15: {
                    columns: ["月份", "GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["台"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar,
                        ],
                    },
                },
                chartData16: {
                    columns: ["月份", "GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: ["GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]%"],
                        yAxisName: ["%"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            (d.value ?? 0) * 100
                                        ).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar,
                        ],
                    },
                },
                chartData17: {
                    columns: ["月份","GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: ["GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["元"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {

                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            (d.value ?? 0)).toFixed(1)}元`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesLine, seriesLine, seriesLine, seriesLine,
                            seriesLine, seriesLine, seriesLine, seriesLine,
                            seriesLine, seriesLine, seriesLine
                        ],
                    },
                },
                chartData18: {
                    columns: ["月份", "GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: ["GS3 POWER", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["元"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                       x: "left",      //可设定图例在左、右、居中
                                       y: "center",     //可设定图例在上、下、居中*/
                            itemHeight: 22,
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            (d.value ?? 0)).toFixed(1)}元`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesLine, seriesLine, seriesLine, seriesLine,
                            seriesLine, seriesLine, seriesLine, seriesLine,
                            seriesLine, seriesLine, seriesLine,
                        ],
                    },
                },


            },
        };
    },
    async mounted() {
        let nowMoment = moment();
        this.getDayList(nowMoment);
        this.currentYear = nowMoment.year();
        this.currentMonth = nowMoment.month() + 1;
        await this.getData();
        await this.getDayKpiValue();
        this.generateTable();
        this.getChartData();
    },
    methods: {
        async currentDayChange() {
            await this.getDayKpiValue();
        },
        async getDayKpiValue() {
            let res = await this.dataParams.Http.DayKpiValue({
                reportDate: this.currentMoment.format("yyyy-MM-") + this.currentDay,
                ranges: this.ranges,
                filterCodes: this._.without((this._.uniq(this.getrateFilterCodes())), "--")
            })
            if (res.Success) {
                this.table7BackendData = res.Data ? res.Data : {};
            } else {
                this.$message.error(res.Message)
            }
        },
        generateTable() {
            //表格一
            this.tableData.table_1.columnsData = [
                "指标",
                "单位",
                "月累计"
            ];
            this.tableData.table_1.columnsData =
                this.tableData.table_1.columnsData.concat(this.dayList.map(i => i + "日"))

            this.tableData.table_1.data = [];
            this.tableData.table_1.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    if (code === "A1302115") {
                        row.push("条");
                    } else {
                        row.push(backObjData.Unit);
                    }
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    let terminal = ""
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                        terminal = "%"
                    }
                    if (backObjData.Unit === "倍") {
                        fixdigtal = 2;
                    }
                    row.push((backObjData.MonthSum * productNum).toFixed(fixdigtal) + terminal);
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal) + terminal)
                    })

                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                    row.push("--");
                }
                this.tableData.table_1.data.push(row);
            })


            //表格二
            this.tableData.table_2.columnsData = ["指标", "单位"];
            this.tableData.table_2.columnsData =
                this.tableData.table_2.columnsData.concat(this.dayList.map(i => i + "日"));

            this.tableData.table_2.data = [];
            this.tableData.table_2.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    row.push("条")
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                    }
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal))
                    })
                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                }
                this.tableData.table_2.data.push(row);
            })

            //表格三
            this.tableData.table_3.columnsData = ["指标", "单位"];
            this.tableData.table_3.columnsData =
                this.tableData.table_3.columnsData.concat(this.dayList.map(i => i + "日"));
            this.tableData.table_3.data = [];
            this.tableData.table_3.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    row.push(backObjData.Unit);
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                    }
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal))
                    })
                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                }
                this.tableData.table_3.data.push(row);
            })

            //表格四
            this.tableData.table_4.columnsData = ["指标", "单位"];
            this.tableData.table_4.columnsData =
                this.tableData.table_4.columnsData.concat(this.dayList.map(i => i + "日"));
            this.tableData.table_4.data = [];
            this.tableData.table_4.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    row.push(backObjData.Unit)
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                    }
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal))
                    })
                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                }
                this.tableData.table_4.data.push(row);
            })

            //表格五
            this.tableData.table_5.columnsData = ["指标", "单位"];
            this.tableData.table_5.columnsData =
                this.tableData.table_5.columnsData.concat(this.dayList.map(i => i + "日"));
            this.tableData.table_5.data = [];
            this.tableData.table_5.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    row.push(backObjData.Unit);
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                    }
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal))
                    })
                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                }
                this.tableData.table_5.data.push(row);
            })


            //表格八
            this.tableData.table_8.columnsData = ["指标", "单位"];
            this.tableData.table_8.columnsData =
                this.tableData.table_8.columnsData.concat(this.dayList.map(i => i + "日"));
            this.tableData.table_8.data = [];
            this.tableData.table_8.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    row.push(backObjData.Unit);
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                    }
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal))
                    })
                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                }
                this.tableData.table_8.data.push(row);
            })

            //表格九
            this.tableData.table_9.columnsData = ["指标", "单位"];
            this.tableData.table_9.columnsData =
                this.tableData.table_9.columnsData.concat(this.dayList.map(i => i + "日"));
            this.tableData.table_9.data = [];
            this.tableData.table_9.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item][this.dataParams.carType];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (code !== "--") {
                    row.push(backObjData.Unit);
                    //修复位数
                    let fixdigtal = 0;
                    //乘积数
                    let productNum = 1;
                    if (backObjData.Unit === "%") {
                        fixdigtal = 1;
                        productNum = 100;
                    }
                    this.dayList.forEach(day => {
                        row.push(((backObjData["D-" + day]) * productNum).toFixed(fixdigtal))
                    })
                } else {
                    row.push("--");
                    this.dayList.forEach(() => {
                        row.push("--");
                    })
                }
                this.tableData.table_9.data.push(row);
            })


            //表格七
            this.tableData.table_7.data = [["单位", "倍", "台", "台", "台", "台", "台", "台"]];
            this.tableData.table_7.rowData.forEach((item) => {
                let row = [];
                row.push(item);

                this.tableData.table_7.columnsData.forEach(j => {
                    if (j.name !== "指标名称") {
                        let code = this.carTypeCode[j.name + "_table7"][item];
                        let backObjData = this.table7BackendData[code];
                        if (code !== "--") {
                            if (backObjData) {
                                //修复位数
                                let fixdigtal = 0;
                                if (code.indexOf('A0404091', 'A0404092', 'A0404093', 'A0404094', 'A0404095', 'A0404096',
                                    'A0404097', 'A0404098', 'A0404099', 'A0404100', 'A0404101', 'A0404102', 'A0404103',
                                    'A0404104', 'A0404105', 'A0404106', 'A0404045')) {
                                    fixdigtal = 1;
                                }
                                row.push(backObjData.toFixed(fixdigtal))
                            } else {
                                row.push(0)
                            }
                        } else {
                            row.push("--");
                        }
                    }
                })
                this.tableData.table_7.data.push(row);
            })

        },
        getrateFilterCodes() {
            //初始化几个图表左上角的code写进去
            let rateFilterCodes =
                [
                    this.carTypeCode["总线索"][this.dataParams.carType],
                    this.carTypeCode["总客流"][this.dataParams.carType],
                    this.carTypeCode["总订单"][this.dataParams.carType],
                    this.carTypeCode["提车实绩"][this.dataParams.carType],
                    this.carTypeCode["库存"][this.dataParams.carType],
                    this.carTypeCode["终端实绩"][this.dataParams.carType],
                ];

            //获取用户所选车型，根据车型筛选Code
            //修改table1的code
            this.tableData.table_1.code = [];
            this.tableData.table_1.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_1.code.push(code);
            })

            //修改table2的code
            this.tableData.table_2.code = [];
            this.tableData.table_2.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_2.code.push(code);
            })

            //修改table3的code
            this.tableData.table_3.code = [];
            this.tableData.table_3.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_3.code.push(code);
            })

            //修改table4的code
            this.tableData.table_4.code = [];
            this.tableData.table_4.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_4.code.push(code);
            })

            //修改table5的code
            this.tableData.table_5.code = [];
            this.tableData.table_5.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_5.code.push(code);
            })


            //修改table8的code
            this.tableData.table_8.code = [];
            this.tableData.table_8.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_8.code.push(code);
            })

            //修改table9的code
            this.tableData.table_9.code = [];
            this.tableData.table_9.rowData.forEach(i => {
                //获取code(根据名字和车型)
                let code = this.carTypeCode[i][this.dataParams.carType];
                this.tableData.table_9.code.push(code);
            })

            //修改table7的code（库存明细）
            this.tableData.table_7.code = [];
            this._.pluck(this.tableData.table_7.columnsData, "name").forEach(i => {
                if (i !== "指标名称") {
                    this.tableData.table_7.rowData.forEach(j => {
                        this.tableData.table_7.code.push(this.carTypeCode[i + "_table7"][j])
                    })
                }
            })
            //图表Code(用两层循环取出来)
            let chart1Codes = [];
            this._.keys(this.carTypeCode.chartData1).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData1[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart1Codes.push(obj1[key2]);
                })
            })


            let chart2Codes = [];
            this._.keys(this.carTypeCode.chartData2).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData2[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart2Codes.push(obj1[key2]);
                })
            })

            let chart3Codes = [];
            this._.keys(this.carTypeCode.chartData3).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData3[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart3Codes.push(obj1[key2]);
                })
            })
            let chart4Codes = [];
            this._.keys(this.carTypeCode.chartData4).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData4[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart4Codes.push(obj1[key2]);
                })
            })

            let chart7Codes = [];
            this._.keys(this.carTypeCode.chartData7).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData7[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart7Codes.push(obj1[key2]);
                })
            })

            let chart8Codes = [];
            this._.keys(this.carTypeCode.chartData8).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData8[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart8Codes.push(obj1[key2]);
                })
            })


            let chart9Codes = [];
            this._.keys(this.carTypeCode.chartData9).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData9[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart9Codes.push(obj1[key2]);
                })
            })


            let chart10Codes = [];
            this._.keys(this.carTypeCode.chartData10).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData10[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart10Codes.push(obj1[key2]);
                })
            })

            let chart11Codes = [];
            this._.keys(this.carTypeCode.chartData11).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData11[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart11Codes.push(obj1[key2]);
                })
            })
            // let chart12Codes = [];
            // this._.keys(this.carTypeCode.chartData12).forEach(key1 => {
            //     let obj1 = this.carTypeCode.chartData12[key1];
            //     this._.keys(obj1).forEach(key2 => {
            //         chart12Codes.push(obj1[key2]);
            //     })
            // })
            let chart13Codes = [];
            this._.keys(this.carTypeCode.chartData13).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData13[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart13Codes.push(obj1[key2]);
                })
            })

            let chart14Codes = [];
            this._.keys(this.carTypeCode.chartData14).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData14[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart14Codes.push(obj1[key2]);
                })
            })

            let chart15Codes = [];
            this._.keys(this.carTypeCode.chartData15).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData15[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart15Codes.push(obj1[key2]);
                })
            })

            let chart16Codes = [];
            this._.keys(this.carTypeCode.chartData16).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData16[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart16Codes.push(obj1[key2]);
                })
            })

            let chart17Codes = [];
            this._.keys(this.carTypeCode.chartData17).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData17[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart17Codes.push(obj1[key2]);
                })
            })

            let chart18Codes = [];
            this._.keys(this.carTypeCode.chartData18).forEach(key1 => {
                let obj1 = this.carTypeCode.chartData18[key1];
                this._.keys(obj1).forEach(key2 => {
                    chart18Codes.push(obj1[key2]);
                })
            })


            rateFilterCodes = rateFilterCodes
                .concat(this.tableData.table_1.code)
                .concat(this.tableData.table_2.code)
                .concat(this.tableData.table_3.code)
                .concat(this.tableData.table_4.code)
                .concat(this.tableData.table_5.code)
                .concat(this.tableData.table_8.code)
                .concat(this.tableData.table_9.code)
                .concat(this.tableData.table_7.code)
                .concat(chart1Codes)
                .concat(chart2Codes)
                .concat(chart3Codes)
                .concat(chart4Codes)
                .concat(chart8Codes)
                .concat(chart9Codes)
                .concat(chart10Codes)
                .concat(chart11Codes)
                // .concat(chart12Codes)
                .concat(chart13Codes)
                .concat(chart14Codes)
                .concat(chart15Codes)
                .concat(chart16Codes)
                .concat(chart17Codes)
                .concat(chart18Codes)

            return rateFilterCodes
        },
        async getData() {
            let res = await this.dataParams.Http.DayPush({
                reportDate: this.currentMoment.format("yyyy-MM"),
                ranges: this.ranges,
                rateFilterCodes: this._.without((this._.uniq(this.getrateFilterCodes())), "--")
            })
            if (res.Success) {
                this.backendData = res.Data;
            } else {
                this.$message.error(res.Message)
            }
        },
        //从后端中取得数据
        getDataFromBackendData(code, field) {
            let obj = this.backendData[code];
            return obj && obj[field] ? (obj[field]) : 0;
        },
        getChartData() {
            Object.keys(this.chartData).forEach((chart) => {
                this.chartData[chart].rows = [];
                this.dayList.map((c) => {
                    let arr = {};
                    this.chartData[chart].columns.map((d) => {
                        if (d !== "月份") {
                            let code = this.carTypeCode[chart][d][this.dataParams.carType];
                            if (code === "--") {
                                arr[d] = null
                            } else {
                                //默认取值字段为普通字段（非同比环比）
                                let field = "";
                                if (d.indexOf("同比") > -1) {
                                    field = "YearOnYear";
                                }
                                if (d.indexOf("环比") > -1) {
                                    field = "RingRate"
                                }
                                let data = this.backendData[code]["D-" + c + field];
                                if (data === 0) {
                                    arr[d] = 0
                                } else {
                                    arr[d] = data
                                }
                            }

                        }
                    });
                    arr["月份"] = c + "日";
                    this.chartData[chart].rows.push(arr);
                });
            });
        },
        getDayList(currentMoment) {
            this.dayList = [];
            for (let i = 1; i < currentMoment.daysInMonth() + 1; i++) {
                this.dayList.push(i)
            }
        },

    },
    watch: {
        dataParams: {
            handler: async function () {
                //生成月推的月份
                this.currentMoment = this.dataParams.yearValue;
                //因为之后的shift会影响到这个this.dataParams.AreaData，从而触发watch，造成死循环，所以必须要深拷贝
                let areaData = JSON.parse(JSON.stringify(this.dataParams.AreaData));
                this.ranges = areaData;
                this.ranges.shift();
                this.getDayList(this.currentMoment);
                await this.getData();
                await this.getDayKpiValue();
                this.generateTable();
                this.getChartData();
            },
            deep: true,
        },
    },
};
</script>

<style lang="less" scoped>
@import "realTime";
</style>
