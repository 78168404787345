<template>
  <section class="section">
    <div class="head">广汽传祺BDM系统 <b>{{ title }}</b> 店端经营分析报告</div>
    <div class="head_text">经营概览</div>
    <one-overview :all-data="allData" :data-params="params"/>
    <div class="head">广汽传祺BDM系统 <b>{{ title }}</b> 店端经营分析报告</div>
    <div class="head_text">销售业务</div>
    <two-sell :all-data="allData" :data-params="params"/>
    <div class="head">广汽传祺BDM系统 <b>{{ title }}</b> 店端经营分析报告</div>
    <div class="head_text">售后业务</div>
    <there-aftersale :all-data="allData" :data-params="params"/>
  </section>
</template>
<script>
import OneOverview from "@/views/monthReport/printReport/oneOverview";
import ThereAftersale from "@/views/monthReport/printReport/thereAftersale";
import TwoSell from "@/views/monthReport/printReport/twoSell";
import { kpiCode } from "@/views/monthReport/shopAnalysis/component/kpiCode";
import moment from "moment";

export default {
  name: "monthReportPrint",
  components: { TwoSell, ThereAftersale, OneOverview },
  data() {
    return {
      allData: {},
      params: {},
      title: "",
    };
  },
  created() {
    this.getParams();
  },
  methods: {
    async getParams() {
      let params = this.$route.query;
      if (Object.keys(params).length > 0) {
        this.params = params;
        const reportDate = moment(this.params.ReportDate).format("YYYY年MM月");
        this.title = reportDate + this.params.DealerName;
        await this.getData();
      }
    },
    async getData() {

      this.params["monthRankCodes"] = this._.uniq(kpiCode.monthRankCodes);
      this.params["yearRankCodes"] = this._.uniq(kpiCode.monthRankCodes);
      this.params["monthPushCodes"] = this._.uniq(kpiCode.monthPushCodes);
      this.params["richCodes"] = kpiCode.richCodes;
      this.params["monthStandardPushCodes"] = kpiCode.monthStandardPush;
      let res = await this.$Http["DealerBusinessAnalysisReport"](this.params);
      if (res.Data)
        this.allData = res.Data;
    },
  },
};
</script>
<style lang="less">
@import "printReport/monthReport.less";
</style>
