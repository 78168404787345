<template>
    <div>
        <div class="part">
            <div class="part-title common-title">
                上传车型分析
                <el-button type="primary" style="margin-left: 100px" @click="downLoad">下载模板</el-button>
            </div>
            <div style="margin-top: 10px;width: 600px">
                <el-upload
                    class="upload-demo"
                    :action="'/apis/DealerKpi/SecondBDM/CarAnalysisDataImport'"
                    :on-preview="handlePreview"
                    :on-success="handleSuccess"
                    :on-remove="handleRemove"
                    :limit="1"
                    :file-list="fileList"
                    :on-exceed="handleExceed"
                    drag
                    multiple
                    :disabled="!isEditable"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">填报说明：</div>
                    <div class="el-upload__tip" slot="tip">1、下载Excel表格，需修改表格名称，例如，上传2021年9月数据，格式：车型分析_2021-09-01；</div>
                    <div class="el-upload__tip" slot="tip">2、表格中A列-K列，直接复制粘贴源数据，保存；</div>
                    <div class="el-upload__tip" slot="tip">3、保存后，通过市占率导入界面，点击上传，显示上传成功，即可。</div>
                </el-upload>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "Upload",
    data() {
        return {
            isEditable: true,
            fileList: [],
        }
    },
    methods: {
        downLoad(){
            window.open("/apis/XExcelTemplate/MarketShareImport")
        },
        //点击已上传文件的钩子
        async handlePreview(file) {
            //下载
            let res = await this.$Http.FileShareLink({objectkey: file.response.Data});
            if (res.Success) {
                window.open(res.Data)
            } else {
                this.$message.error(res.Message)
            }
        },
        handleSuccess(response, file, fileList) {
            this.fileList = fileList
            if (response.Success) {
                this.$message.success(response.Data)
            } else {
                this.$message.error(response.Message)
                this.fileList.pop()
            }
        },
        handleRemove(file, fileList) {
            this.fileList = fileList
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
    }
}
</script>

<style scoped>

.part {
    margin: 20px auto;
    position: relative;
}

.common-title {
    background: #DDDDDD;
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
}

.common-content {
    border: #e8e8e8 2px solid;
    border-top: none;
}

.table-title {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.table-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
}

.part-title {
    padding-left: 30px;
}

.part-content {
    padding: 30px;
    display: flex;
    align-items: center;
}

</style>