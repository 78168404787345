<template>
    <a-row :guter="[28, 28]">
        <a-col :span="24">
            <a-card>
                <div class="title">
                    来厂分析
                </div>
                <table class="common_table">
                    <thead>
                    <th v-for="(item, i) in tableData.table_1.columnsData">
                        {{ item }}
                    </th>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in tableData.table_1.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </a-card>
        </a-col>

        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    有偿来厂及总来厂
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart2"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    来厂台次结构
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart3"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <div class="title">
                    产值分析
                </div>
                <table class="common_table">
                    <thead>
                    <th v-for="(item, i) in tableData.table_2.columnsData">
                        {{ item }}
                    </th>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in tableData.table_2.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    店均产值
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart5"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    单车产值
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart6"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    工时毛利、零件毛利和毛利率
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart7"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    产值结构
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart8"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <div class="title">
                客户维系率
            </div>
            <a-card>
                <div class="title">
                    客户保有量
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart9"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    客户结构
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart10"/>
                </div>
            </a-card>
        </a-col>
        <a-col class="carding" :span="24">
            <a-card>
                <div class="title">
                    客户招揽分析
                </div>
                <div class="clear-both">
                    <main-chart :chart-data="chartData.chart11"/>
                </div>
            </a-card>
        </a-col>
        <a-col :span="24">
            <a-card>
                <div class="title">
                    满意度分析
                </div>
                <table class="common_table">
                    <thead>
                    <th v-for="(item, i) in tableData.table_3.columnsData">
                        {{ item }}
                    </th>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in tableData.table_3.data" :key="i">
                        <td v-for="(item,i) in row" :key="i">
                            {{ item }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </a-card>
        </a-col>
    </a-row>
</template>

<script>
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import {
    grid,
    itemStyle,
    lineStyle,
    symbolSize,
} from "@/views/afterSaleAnalysis/monthAnalysisChildComponent/chartConfig";
import {
    axisLine,
    axisTick,
    barMaxWidth, seriesBar, seriesLine,
    splitLine, xAxis, yAxisDefault, yAxisRight,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import moment from "moment";
import {carTypeCode} from "@/views/afterSaleAnalysis/carTypeCode";


export default {
    name: "oneRealTime",
    components: {mainChart},
    props: {
        dataParams: {},
    },
    data() {
        return {
            carTypeCode,
            backendData: {},
            currentMoment: moment(),
            currentYear: "",
            currentMonth: '',
            monthList: [],
            lineStyle,
            symbolSize,
            itemStyle,
            ranges: [],
            grid,
            tableData: {
                table_1: {
                    columnsData: [],
                    rowData: [
                        "来厂实绩",
                    ],
                    data: [],
                    code: []
                },
                table_2: {
                    columnsData: [],
                    rowData: [
                        "总产值",
                        "店均产值",
                        "单车产值",
                        "毛利率",
                    ],
                    data: [],
                    code: []
                },
                table_3: {
                    columnsData: [],
                    rowData: [
                        "满意度调查成绩",
                        "售后万台投诉率",
                    ],
                    data: [],
                    code: []
                },
            },
            chartData: {
                chart2: {
                    columns: [
                        "月份",
                        "总来厂台次",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {},
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["台"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                } else {
                                    return Number(data.data).toFixed(1)
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesBar
                        ],
                    },
                },
                chart3: {
                    columns: [
                        "月份",
                        "首保",
                        "定保",
                        "一般维修",
                        "改装美容",
                        "市场活动",
                        "保修",
                        "钣喷",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [
                                "首保",
                                "定保",
                                "一般维修",
                                "改装美容",
                                "市场活动",
                                "保修",
                                "钣喷",
                            ],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["台"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}台`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar,
                            seriesBar
                        ],
                    },
                },
                chart5: {
                    columns: [
                        "月份",
                        "店均产值",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[00]"],
                        yAxisName: ["万元"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                } else {
                                    return Number(data.data).toFixed(1)
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}万元`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesBar
                        ],
                    },
                },
                chart6: {
                    columns: [
                        "月份",
                        "单车产值",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]"],
                        yAxisName: ["元"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                } else {
                                    return Number(data.data).toFixed(1)
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}元`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar
                        ],
                    },
                },
                chart7: {
                    columns: [
                        "月份",
                        "工时毛利",
                        "零部件毛利",
                        "毛利率",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [
                                "工时毛利",
                                "零部件毛利",
                            ],
                        },
                        showLine: ["毛利率"],
                        axisSite: {
                            right: ["毛利率"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["万元", "%"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                } else {
                                    return Number(data.data).toFixed(1)
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}万元`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar,
                            seriesBar, seriesLine
                        ],
                    },
                },
                chart8: {
                    columns: [
                        "月份",
                        "首保",
                        "定保",
                        "一般维修",
                        "改装美容",
                        "市场活动",
                        "保修",
                        "钣喷",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [
                                "首保",
                                "定保",
                                "一般维修",
                                "改装美容",
                                "市场活动",
                                "保修",
                                "钣喷",
                            ],
                        },
                        showLine: [],
                        axisSite: {
                            right: [],
                        },
                        yAxisName: ["万元"],
                        label: {
                            show: false, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}万元`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesBar, seriesBar, seriesBar,
                            seriesBar, seriesBar, seriesBar,
                            seriesBar
                        ],
                    },
                },
                chart9: {
                    columns: [
                        "月份",
                        "保有客户数",
                        "保有率",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: ["保有率"],
                        axisSite: {
                            right: ["保有率"],
                        },
                        yAxisType: ["0.[0]", "0.[0]%"],
                        yAxisName: ["个", "%"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                } else {
                                    return Number(data.data).toFixed(1)
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}个`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar, seriesLine
                        ],
                    },
                },
                chart10: {
                    columns: [
                        "月份",
                        "A类客户占比",
                        "B类客户占比",
                        "C类客户占比",
                        "ABC类客户",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [
                                "A类客户占比",
                                "B类客户占比",
                                "C类客户占比",
                            ],
                        },
                        showLine: ["ABC类客户"],
                        axisSite: {
                            right: ["ABC类客户"],
                        },
                        yAxisType: ["0.[0]%", "0.[0]"],
                        yAxisName: ["%", "个"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return Number(data.data).toFixed(1)
                                } else {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "bar") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${Number(
                                            d.value ?? 0
                                        ).toFixed(1)}个`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault, yAxisRight],
                        series: [
                            seriesBar, seriesBar,
                            seriesBar, seriesLine
                        ],
                    },
                },
                chart11: {
                    columns: [
                        "月份",
                        "首保招揽成功率",
                        "定保招揽成功率",
                    ],
                    rows: [],
                    setting: {
                        type: "histogram",
                        stack: {
                            val: [],
                        },
                        showLine: ["首保招揽成功率",
                            "定保招揽成功率",
                        ],
                        axisSite: {
                            right: [],
                        },
                        yAxisType: ["0.[0]%"],
                        yAxisName: ["%"],
                        label: {
                            show: true, // 显示数字
                            position: "inside",
                            textStyle: {
                                color: "black",
                            },
                            formatter: function (data) {
                                if (data.componentSubType === "line") {
                                    return (Number(data.data) * 100).toFixed(1) + "%"
                                } else {
                                    return Number(data.data).toFixed(1)
                                }
                            }
                        },
                    },
                    options: {
                        legend: {
                            type: "scroll",
                            /* orient: "vertical",
                                               x: "left",      //可设定图例在左、右、居中
                                               y: "center",     //可设定图例在上、下、居中*/
                            // itemWidth: 100,
                            textStyle: {
                                color: "black",
                                fontSize: 12,
                            },
                        },
                        grid,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                // 坐标轴指示器，坐标轴触发有效
                                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                            },
                            formatter: function (params) {
                                let arr = {};
                                params.forEach((d, i) => {
                                    if (d.componentSubType === "line") {
                                        arr[i] = `${d.marker} ${d.seriesName}: ${
                                            Number((d.value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                });
                                let re = params[0].name + "<br />";
                                Object.values(arr).forEach((a) => {
                                    re += a + "<br />";
                                });
                                return re;
                            },
                        },
                        xAxis,
                        yAxis: [yAxisDefault],
                        series: [
                            seriesLine,
                            seriesLine
                        ],
                    },
                },

            },
        };
    },
    async created() {
        let nowMoment = moment();
        this.currentYear = nowMoment.year();
        this.getYearList(this.currentYear)
        await this.getData();
        this.generateTable();
        this.getChartData();
    },
    methods: {
        generateTable() {
            //表格一
            this.tableData.table_1.columnsData = [
                "指标",
                "单位",
            ];
            this.tableData.table_1.columnsData =
                this.tableData.table_1.columnsData.concat(this.yearList.map(i => i + "年"))

            this.tableData.table_1.data = [];
            this.tableData.table_1.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item];
                let backObjData = this.backendData[code];
                // 后端返回对象
                row.push(backObjData.Unit);
                //修复位数
                let fixdigtal = 0;
                //乘积数
                let productNum = 1;
                let terminal = ""
                if (backObjData.Unit === "%") {
                    fixdigtal = 1;
                    productNum = 100;
                    terminal = "%"
                }
                if (backObjData.Unit === "倍") {
                    fixdigtal = 2;
                }
                for (let i = 4; i >= 0; i--) {
                    row.push(((backObjData["N-" + i]) * productNum).toFixed(fixdigtal) + terminal)
                }
                this.tableData.table_1.data.push(row);
            })

            //表格二
            this.tableData.table_2.columnsData = [
                "指标",
                "单位",
            ];
            this.tableData.table_2.columnsData =
                this.tableData.table_2.columnsData.concat(this.yearList.map(i => i + "年"))

            this.tableData.table_2.data = [];
            this.tableData.table_2.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item];
                let backObjData = this.backendData[code];
                // 后端返回对象
                if (item === "总产值" || item === "店均产值") {
                    row.push("万元");
                } else {
                    row.push(backObjData.Unit);
                }
                //修复位数
                let fixdigtal = 0;
                //乘积数
                let productNum = 1;
                let terminal = ""
                if (backObjData.Unit === "%") {
                    fixdigtal = 1;
                    productNum = 100;
                    terminal = "%"
                }
                if (backObjData.Unit === "倍") {
                    fixdigtal = 2;
                }
                if (item === "总产值" || item === "店均产值") {
                    productNum = 0.0001;
                    fixdigtal = 1;
                }
                for (let i = 4; i >= 0; i--) {
                    row.push(((backObjData["N-" + i]) * productNum).toFixed(fixdigtal) + terminal)
                }
                this.tableData.table_2.data.push(row);
            })
            //表格三
            this.tableData.table_3.columnsData = [
                "指标",
                "单位",
            ];
            this.tableData.table_3.columnsData =
                this.tableData.table_3.columnsData.concat(this.yearList.map(i => i + "年"))

            this.tableData.table_3.data = [];
            this.tableData.table_3.rowData.forEach(item => {
                let row = [];
                row.push(item);
                let code = this.carTypeCode[item];
                let backObjData = this.backendData[code];
                // 后端返回对象

                row.push(backObjData.Unit);
                //修复位数
                let fixdigtal = 0;
                //乘积数
                let productNum = 1;
                let terminal = ""
                if (backObjData.Unit === "%") {
                    fixdigtal = 1;
                    productNum = 100;
                    terminal = "%"
                }
                if (backObjData.Unit === "分") {
                    fixdigtal = 2;
                }
                if (item === "总产值" || item === "店均产值") {
                    productNum = 0.0001;
                    fixdigtal = 1;
                }
                for (let i = 4; i >= 0; i--) {
                    row.push(((backObjData["N-" + i]) * productNum).toFixed(fixdigtal) + terminal)
                }
                this.tableData.table_3.data.push(row);
            })
        },
        async getData() {
            let rateFilterCodes = [];
            //获取用户所选车型，根据车型筛选Code
            //修改table1的code
            this.tableData.table_1.code = [];
            this.tableData.table_1.rowData.forEach(i => {
                //获取code(根据名字)
                let code = this.carTypeCode[i];
                this.tableData.table_1.code.push(code);
            })
            //修改table2的code
            this.tableData.table_2.code = [];
            this.tableData.table_2.rowData.forEach(i => {
                //获取code(根据名字)
                let code = this.carTypeCode[i];
                this.tableData.table_2.code.push(code);
            })
            //修改table3的code
            this.tableData.table_3.code = [];
            this.tableData.table_3.rowData.forEach(i => {
                //获取code(根据名字)
                let code = this.carTypeCode[i];
                this.tableData.table_3.code.push(code);
            })
            //图表Code
            let chart1Codes = [];
            this._.keys(this.carTypeCode.chart1).forEach(key1 => {
                chart1Codes.push(this.carTypeCode.chart1[key1]);
            })

            let chart2Codes = [];
            this._.keys(this.carTypeCode.chart2).forEach(key1 => {
                chart2Codes.push(this.carTypeCode.chart2[key1]);
            })

            let chart3Codes = [];
            this._.keys(this.carTypeCode.chart3).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart3[key1]);
            })

            let chart4Codes = [];
            this._.keys(this.carTypeCode.chart4).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart4[key1]);
            })

            let chart5Codes = [];
            this._.keys(this.carTypeCode.chart5).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart5[key1]);
            })

            let chart6Codes = [];
            this._.keys(this.carTypeCode.chart6).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart6[key1]);
            })

            let chart7Codes = [];
            this._.keys(this.carTypeCode.chart7).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart7[key1]);
            })
            let chart8Codes = [];
            this._.keys(this.carTypeCode.chart8).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart8[key1]);
            })
            let chart9Codes = [];
            this._.keys(this.carTypeCode.chart9).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart9[key1]);
            })
            let chart10Codes = [];
            this._.keys(this.carTypeCode.chart10).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart10[key1]);
            })
            let chart11Codes = [];
            this._.keys(this.carTypeCode.chart11).forEach(key1 => {
                chart3Codes.push(this.carTypeCode.chart11[key1]);
            })
            rateFilterCodes = rateFilterCodes
                .concat(this.tableData.table_1.code)
                .concat(this.tableData.table_2.code)
                .concat(this.tableData.table_3.code)
                .concat(chart1Codes)
                .concat(chart2Codes)
                .concat(chart3Codes)
                .concat(chart4Codes)
                .concat(chart5Codes)
                .concat(chart6Codes)
                .concat(chart7Codes)
                .concat(chart8Codes)
                .concat(chart9Codes)
                .concat(chart10Codes)
                .concat(chart11Codes)
            let res = await this.dataParams.Http.YearPush({
                year:moment(this.currentYear.toString()).year(),
                ranges: this.ranges,
                pushFilterCodes: this._.without(this._.uniq(rateFilterCodes), "--")
            })
            if (res.Success) {
                this.backendData = res.Data;
            } else {
                this.$message.error(res.Message)
            }
        },
        //从后端中取得数据
        getDataFromBackendData(code, field) {
            let obj = this.backendData[code];
            return obj && obj[field] ? (obj[field]) : 0;
        },
        getChartData() {
            Object.keys(this.chartData).forEach((chart) => {
                this.chartData[chart].rows = [];
                this.yearList.map((c, i) => {
                    let arr = {};
                    this.chartData[chart].columns.map((d) => {
                        if (d !== "月份") {
                            let code = this.carTypeCode[chart][d];
                            if (code === "--") {
                                arr[d] = null
                            } else {
                                //默认取值字段为普通字段（非同比环比）
                                let field = "";
                                if (d.indexOf("同比") > -1) {
                                    field = "YearOnYear";
                                }
                                if (d.indexOf("环比") > -1) {
                                    field = "RingRate"
                                }
                                if (this.backendData[code]["N-" + (4 - i) + field] !== 0) {
                                    if (
                                        (chart === "chart4" && d === "产值实绩") ||
                                        (chart === "chart5" && d === "店均产值") ||
                                        (chart === "chart7" && d === "工时毛利") ||
                                        (chart === "chart7" && d === "零部件毛利") ||
                                        (chart === "chart8" && d === "首保") ||
                                        (chart === "chart8" && d === "定保") ||
                                        (chart === "chart8" && d === "一般维修") ||
                                        (chart === "chart8" && d === "改装美容") ||
                                        (chart === "chart8" && d === "市场活动") ||
                                        (chart === "chart8" && d === "保修") ||
                                        (chart === "chart8" && d === "钣喷")
                                    ) {
                                        arr[d] = ((this.backendData[code]["N-" + (4 - i) + field]) / 10000).toFixed(1);
                                    } else {
                                        arr[d] = this.backendData[code]["N-" + (4 - i) + field];
                                    }
                                }else{
                                    arr[d] = null
                                }

                            }

                        }
                    });
                    arr["月份"] = c + "年";
                    this.chartData[chart].rows.push(arr);
                });
            });
        },
        getYearList(currentYear) {
            this.yearList = [];
            for (let i = currentYear; i > currentYear - 5; i--) {
                this.yearList.push(i)
            }
            this.yearList.reverse();

        },

    },
    watch: {
        dataParams: {
            handler: async function () {
                //生成月推的月份
                this.currentYear = this.dataParams.yearValue;
                //因为之后的shift会影响到这个this.dataParams.AreaData，从而触发watch，造成死循环，所以必须要深拷贝
                let areaData = JSON.parse(JSON.stringify(this.dataParams.AreaData));
                this.ranges = areaData;
                this.ranges.shift();
                this.getYearList(moment(this.currentYear.toString()).year());
                await this.getData();
                this.generateTable();
                this.getChartData();
            },
            deep: true,
        },
    },
};
</script>

<style lang="less" scoped>
@import "realTime";
</style>
