<template>
  <div :style="`height:${chartHeight?chartHeight:'360'}px;`" class="chart" ref="chart_pie"></div>
</template>

<script>
import * as echarts5 from "echarts5";
import { tenThousandYuan } from "@/until/optionPrint";

export default {
  name: "pieChart",
  props: {
    chartHeight: {
      type: String,
    },
    pieData: {
      type: Array,
    },
    //是否需要除以万元处理
    isNeedDeal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const chartColors = ["#FF786A", "#FFC800", "#856AFF", "#5ADCA6", "#FF4040", "#8543E0", "#13C2C2", "#3436C7", "#F04864"];
    return {
      op_pie: {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            if (this.isNeedDeal) {
              return "<span style=\"background-color:" + params.color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params.name + "<br/> " + (params.value) + "万元(" + Number(params.percent).toFixed(1) + "%)";
            } else {
              return "<span style=\"background-color:" + params.color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>" + params.name + "<br/> " + (params.value) + "万元(" + Number(params.percent).toFixed(1) + "%)";
            }
          },
        },
        legend: {
          show: false,
        },
        color: chartColors,
        series: [
          {
            //  name: "访问来源",
            type: "pie",
            radius: ["30%", "60%"],
            label: {
              position: "inner", color: "#fff",
              fontSize: 14,
              formatter: function(data) {
                return data.percent.toFixed(1) + "%";
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            //  name: "访问来源",
            type: "pie",
            radius: ["45%", "60%"],
            labelLine: {
              length: 30,
            },
            label: {
               // formatter: "{a|{b}}{abg|}\n{hr|}\n  {b|{c}}万元",
              formatter: (params) => {
                return "{a|" + params.name + "}{abg|}\n{hr|}\n  {b|" + (params.value) + "}万元";
              },
              rich: {
                a: {
                  color: "#666",
                  lineHeight: 24,
                  align: "center",
                },
                hr: {
                  borderColor: "#ddd",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: "#666",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 24,
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    Gauge(pieData) {
      this.op_pie.series[0].data = pieData;
      this.op_pie.series[1].data = pieData;
      /*使用 echarts5 */
      let myChartG = echarts5.init(this.$refs.chart_pie);
      myChartG.setOption(this.op_pie);
    },
  },
  mounted() {
    this.Gauge(this.pieData);
  },
  watch: {
    pieData: {
      handler: function() {
        this.Gauge(this.pieData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.chart {
  width: auto;
  position: relative;
  bottom: 50px;
  }
</style>
