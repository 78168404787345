<template>
    <section>
        <a-card class="card">
            <a-form
                :form="form"
                layout="horizontal"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 20 }"
            >
                <a-row :gutter="24">
                    <a-col :span="6">
                        <a-form-item label="巡回员">
                            <a-input placeholder="请输入姓名/账号" v-model="form.Name">
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="2">
                        <a-form-item label="">
                            <a-button type="primary" @click="getList(1)"> 查询</a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :span="2">
                        <a-form-item label="">
                            <a-button type="primary" @click="add"> 新增</a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-item label="">
                            <a-upload
                                name="file"
                                action="/apis/DealerKpi/Third/ItinerantImport"
                                @change="handleChange"
                                :file-list="fileList"
                                :showUploadList="false"

                            >
                                <a-button type="primary"> 上传巡回员账号配置导入清单</a-button>
                            </a-upload>
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-item label="">
                            <a-button type="primary" @click="download"> 下载巡回员账号配置导入清单</a-button>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </a-card>
        <a-card class="card" style="margin-top: 18px;">
            <a-table
                rowKey="PartId"
                :columns="columns"
                :data-source="tableData"
                :pagination="paginationParam"
                @change="tableChange"
            >
        <span slot="Action" slot-scope="record">
          <a-button size="small" type="primary" @click="doEdit(record)"
          >编辑</a-button
          >
          <a-divider type="vertical"/>
          <a-popconfirm
              title="确定删除吗？"
              @confirm="() => doDel(record)"
          >
            <a-button size="small" type="primary">删除</a-button>
          </a-popconfirm>
             <a-divider type="vertical"/>
            <a-button size="small" type="primary" @click="doViewDealer(record)">查看经销商</a-button>
        </span>
            </a-table>
        </a-card>
        <a-modal
            v-model="visible"
            :title="modalTitle"
            @ok="handleOk"
            @cancel="handleCancel"
        >
            <a-form-model
                :rules="rules"
                ref="modelForm"
                :model="modelForm"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 14 }"
            >
                <a-form-model-item label="账号" prop="UserName">
                    <!--          <a-input v-model="modelForm.UserName"/>-->
                    <a-select
                        show-search
                        allowClear
                        :default-active-first-option="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="handleSearch"
                        v-model="modelForm.UserName"
                    >
                        <a-select-option v-for="(d, i) in userList" :key="i" :value="d.UserName">
                            {{ d.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--                <a-form-model-item label="大区" prop="PartName">-->
                <!--                    <a-select v-model="modelForm.PartName" show-search :filterOption="selectorFilter"-->
                <!--                              @change="partChange" placeholder="该选择框仅供查询经销商">-->
                <!--                        <a-select-option v-for="item in partList" :key="item.PartId" :value="item.PartName">-->
                <!--                            {{ item.PartName }}-->
                <!--                        </a-select-option>-->
                <!--                    </a-select>-->
                <!--                </a-form-model-item>-->
                <!--                <a-form-model-item label="经销商" prop="DealerCode">-->
                <!--                    <a-select v-model="modelForm.DealerCode" show-search :filterOption="selectorFilter" mode="multiple">-->
                <!--                        <a-select-option v-for="item in declareList" :key="item.value">-->
                <!--                            {{ item.label }}-->
                <!--                        </a-select-option>-->
                <!--                    </a-select>-->
                <!--                </a-form-model-item>-->
                <a-form-model-item label="经销商" prop="DealerCode">
                    <el-cascader style="width: 100%;" :options="dealers"
                                 v-model="modelForm.DealerCode"
                                 :props="props" filterable
                                 :show-all-levels="false"
                    ></el-cascader>
                </a-form-model-item>
                <a-form-model-item label="巡回员类型" prop="UseType">
                    <a-select show-search v-model="modelForm.UseType" :filterOption="selectorFilter">
                        <a-select-option :value="0">
                            主任
                        </a-select-option>
                        <a-select-option :value="1">
                            战区经理
                        </a-select-option>
                        <a-select-option :value="2">
                            巡回员
                        </a-select-option>
                        <a-select-option :value="3">
                            商贸员
                        </a-select-option>
                        <a-select-option :value="4">
                            战区支持经理
                        </a-select-option>
                        <a-select-option :value="5">
                            战区支持专员
                        </a-select-option>
                        <a-select-option :value="6">
                            其他人员
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <a-modal v-model="visible2" title="经销商列表" :footer="null">
            <a-table :columns="columns2" :data-source="tableData2" :pagination="false">
            </a-table>
        </a-modal>
        <div class="foot-pad30"></div>
    </section>
</template>

<script>
import Http from "@/api/http";
import axios from "axios";

const columns = [
    {
        title: '序号',
        width: '60px',
        customRender: (text, record, index) => `${index + 1}`,
    },
    {
        title: "姓名",
        dataIndex: "Name",
        key: "Name",
    },
    {
        title: "账号",
        dataIndex: "UserName",
        key: "UserName",
    },
    // {
    //   title: "经销商名称",
    //   dataIndex: "DealerName",
    //   key: "DealerName",
    // },
    // {
    //   title: "经销商代码",
    //   dataIndex: "DealerCode",
    //   key: "DealerCode",
    // },
    {
        title: "类型名称",
        dataIndex: "TypeName",
        key: "TypeName",
    },
    {
        title: "操作",
        key: "Action",
        scopedSlots: {customRender: "Action"},
    },
];
const columns2 = [
    {
        title: '经销商名称',
        key: 'DealerName',
        dataIndex: 'DealerName',
    },
    {
        title: '经销商Code',
        key: 'DealerCode',
        dataIndex: 'DealerCode',
    },
];

let timeout;

function fetch(value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    async function fake() {
        let res = await Http.GetUserByKey({KeyWord: value});
        callback(res);
    }

    timeout = setTimeout(fake, 300);
}


export default {
    data() {
        return {
            fileList: [],
            dealers: [],
            props: {multiple: true, expandTrigger: "hover"},
            partList: [],
            visible: false,
            //查看经销商对话框
            visible2: false,
            modalTitle: "",
            //是否新增
            isAdd: null,
            form: {
                //账户名
                Name: "",
                //关键词 销售店名称或代码
                // Dealer: "",
            },
            modelForm: {
                RowId: null,
                UserName: null,
                DealerCode: [],
                UseType: null,
                PartName: undefined
            },
            //分页数据
            pageData: {},
            //表格数据
            tableData: [],
            columns,
            //账号列表
            userList: [],
            //经销商列表
            declareList: [],
            tableData2: [],
            columns2,
            //分页参数
            paginationParam: {
                current: 1,
                total: 0,
                pageSize: 10,
            },
            rules: {
                UserName: [
                    {
                        required: true,
                        message: "请输入账号",
                        trigger: ["blur", "change"],
                    },
                ],
                DealerCode: [
                    {
                        required: true,
                        message: "请选择经销商",
                        trigger: ["blur", "change"],
                    },
                ],
                UseType: [
                    {
                        required: true,
                        message: "请选择巡回员类型",
                        trigger: ["blur", "change"],
                    },
                ],
            },
        };
    },
    methods: {
        handleChange(info) {
            let fileList = [...info.fileList];
            if (info.file.status === 'done') {
                let res = info.file.response
                if (res.Success) {
                    this.$message.success(res.Data)
                } else {
                    this.$message.error(res.Message)
                    fileList = []
                }
            }
            this.fileList = fileList
            // if (info.file.status === 'done') {
            //     this.$message.success(`${info.file.name} file uploaded successfully`);
            // } else if (info.file.status === 'error') {
            //     this.$message.error(`${info.file.name} file upload failed.`);
            // }
        },
        async download() {
            // let res = await this.$Http.ItinerantExport();
            // if (res.Success) {
            //     window.open(res.Data)
            // }
            axios({
                url: "/apis/DealerKpi/Third/ItinerantExport",
                method: "get",
                //  data:{},
                responseType: "blob",
            }).then(response => {
                const blob = response.data;
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `巡回员账号配置导入清单.xlsx`;
                link.click();
            }, error => {
                this.$message.error("导出excel出错");
            });
        },
        async getDealersByRole() {
            let res = await this.$Http.GetDealersByRole({all: true});
            if (!res.Success) {
                this.$message.error(res.Message);
                return;
            }
            let dealers = [];
            res.Data.chain().each(p => {
                let part = {};
                part.value = p.PartId;
                part.label = p.PartName;
                part.children = p.Dealers.chain().map(d => {
                    let dealer = {};
                    dealer.value = d.DealerCode;
                    dealer.label = d.DealerName;
                    return dealer;
                }).value();
                dealers.push(part);
            });
            this.dealers = dealers;
        },
        async partChange(partName) {
            let self = this;
            let res = await self.$Http.GetPartList({
                PartName: partName,
            });
            if (res.Success) {
                self.declareList = res.Data.map((value) => {
                    return {
                        label: value.DealerName,
                        value: value.DealerCode
                    }
                });
            } else {
                self.$message.error(res.Message)
            }
        },
        //查看经销商
        async doViewDealer(record) {
            let self = this;
            let res = await self.$Http.GetDealerByMan({UserName: record.UserName,UseType:record.UseType});
            if (res.Success) {
                self.visible2 = true;
                self.tableData2 = res.Data;
            } else {
                self.$message.error(res.Message);
            }
        },
        handleSearch(value) {
            fetch(value, (data) => (this.userList = data.Data));
        },
        selectorFilter(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async add() {
            let self = this;
            self.visible = true;
            self.modalTitle = "新增巡回员";
            self.isAdd = true;
            self.getDealersByRole();
            // self.partList = await self.getPartList();
        },
        async getCompanyList() {
            let self = this;
            let res = await self.$Http.GetCompanyListByPage({
                pageSize: 500000,
                pageIndex: 1
            })
            return res.Data.map((currentValue) => {
                return {
                    label: currentValue.CompanyNameCN,
                    value: currentValue.CompanyCode
                }
            })
        },
        async getPartList() {
            let self = this;
            let res = await self.$Http.GetPartListByPage({
                pageSize: 1000,
                pageIndex: 1,
            });
            if (res.Success && res.Data.length > 0) {
                return res.Data;
            } else {
                self.$message.error(res.Message)
            }
        },
        async GetUserByKey(key) {
            let self = this;
            let res = await self.$Http.GetUserByKey({KeyWord: key});
            if (res.Success) {
                return res.Data;
            } else {
                self.$message.error(res.Message);
            }
        },
        handleOk() {
            let self = this;
            //经销商字段处理为API所需字段类型
            this.modelForm.DealerCode = self._.chain(this.modelForm.DealerCode).map(i => i[1]).value();
            self.$refs.modelForm.validate(async (valid) => {
                if (valid) {
                    //新增or编辑
                    let res = self.isAdd
                        ? await self.$Http.InsertItinerantMan(self.modelForm)
                        : await self.$Http.UpdateItinerantMan(self.modelForm);
                    if (res.Success) {
                        self.$message.success(res.Data);
                        self.visible = false;
                        this.$refs["modelForm"].resetFields();
                        self.getList(self.form.pageIndex);
                    } else {
                        self.$message.error(res.Message);
                    }
                }
            });
        },
        handleCancel() {
            this.modalTitle = "";
            this.userList = [];
            this.$refs["modelForm"].resetFields();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        tableChange(pagination, filters, sorter, {currentDataSource}) {
            this.paginationParam.current = pagination.current;
            this.getList(this.paginationParam.current);
        },
        //获取列表
        async getList(current) {
            let self = this;
            //将分页参数传入form对象
            self.form.pageSize = self.paginationParam.pageSize;
            self.form.pageIndex = self.paginationParam.current = current;
            let res = await self.$Http.GetItinerantManPageList(self.form);
            self.paginationParam.total = res.TotalCount;
            self.tableData = res.Data;
        },
        //编辑
        async doEdit(record) {
            this.visible = true;
            this.modalTitle = "巡回员";
            this.isAdd = false;
            await this.getDealersByRole();
            this.declareList = await this.getCompanyList();
            this.userList = await this.GetUserByKey(record.UserName);
            // this.modelForm.RowId = record.Id;
            this.modelForm.UserName = record.UserName;
            this.modelForm.UseType = record.UseType;
            // 获取该巡回员经销商的CodeList
            let resCodeList = await this.$Http.GetDealerByMan({UserName: record.UserName,UseType:record.UseType});
            if (resCodeList.Success) {
                //拼接组件所需数据
                this.modelForm.DealerCode = this._.pluck(resCodeList.Data, "DealerCode")
                let tmpList = [];
                this.modelForm.DealerCode.forEach(item => {
                    let tmpItem = [];
                    //获取这个Code对应的子节点
                    this.dealers.forEach(i => {
                        if (this._.any(i.children, {value: item})) {
                            tmpItem.push(i.value);
                            tmpItem.push(item);
                            tmpList.push(tmpItem)
                            return
                        }
                    })
                })
                this.modelForm.DealerCode = tmpList
            } else {
                this.$message.error(resCodeList.Message);
            }
            // this.$router.push("/bgMannage/edit?partId=" + id);
        },
        //删除
        async doDel(record) {
            let self = this;
          let res = await self.$Http.DeleteItinerantMan({
            userName: record.UserName,
            useType: record.UseType
          });
            if (res.Success) {
                self.$message.success(res.Data);
                self.getList(self.form.pageIndex);
            } else {
                self.$message.error(res.Message);
            }
        },
        // /DealerKpi/KpiData/GetItinerantType 获取巡回员类型列表 api
        async GetItinerantList() {
            let res = await this.$Http.GetItinerantType();
        },
    },
    mounted() {
        this.getList(1);
        //   this.GetItinerantList();
    },
};
</script>
<style lang="less">
.el-input.el-input--small.el-input--suffix {
    height: 33px;
}

.el-cascader__tags .el-tag:not(.is-hit) {
    i {
        display: none;
    }
}
</style>
