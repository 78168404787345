<template>
  <div id="chart" ref="chart1"></div>
</template>
<script>

export default {
  data () {
    return {
      miniChartData: [],
    }
  },
  created () {
    /*小图表数据*/
    this.miniChartData = [20, 90, 30, 80, 20, 40, 10, 100, 90, 30, 80, 20, 40, 10, 20, 40, 10]
  },
  methods: {},
  mounted () {
    let mychart = this.$echarts.init(this.$refs.chart1)
    let op = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      xAxis: [
        {
          type: 'category',
          show: false,
          data: this.miniChartData,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          show: false
        }
      ],
      series: [
        {
          type: 'bar',
          barWidth: '40%',
          data: this.miniChartData
        }
      ]
    }
    mychart.setOption(op)

  },
}
</script>

<style scoped>
#chart {
  width: 100%;
  height: 70px;
}
</style>
