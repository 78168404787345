const chineseNumber = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"]

export async function showTipsConform(targetValues, saveFunc) {
    let tips = [];
    if ((targetValues["B0105160"] + targetValues["B0105134"]) * targetValues["B0302001"]
        >= (targetValues["C0202020"] + targetValues["C0205010"] + targetValues["C0202238"] + targetValues["C0205005"] + targetValues["C0203020"])) {
        tips.push("售后业务综合毛利已覆盖总费用，请检查填报内容是否正确，请核实。");
    }
    if ((targetValues["A0107003"] + targetValues["A0105072"]) < 0) {
        tips.push("单车销售综合毛利为负，请检查填报内容是否正确，请核实。");
    }
    if (((targetValues["C0202020"] + targetValues["C0205010"] + targetValues["C0202238"] + targetValues["C0205005"] + targetValues["C0203020"])
        / (targetValues["A0103001"] + targetValues["A0103024"] + targetValues["B0103097"] + targetValues["B0103099"])) >= 0.12) {
        tips.push("总费用率高于12%，请检查总收入是否偏低以及费用偏高，请核实。");
    }
    if (tips.length <= 0) {
        await saveFunc();
    } else {
        let index = 1;
        tips = tips.map(tip => `提示${chineseNumber[index++]}：${tip}`);
        await this.$confirm({
            title: "存在以下问题，请确认是否继续保存",
            content: h => <div>{tips.map(tip => <span>{tip}<br/></span>)}</div>,
            async onOk() {
                await saveFunc();
            },
        });
    }
}