<template>
    <a-row :gutter="[0,10]">
        <!-- 售后业务效率(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">售后业务效率({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_1"/>
            </div>
        </a-col>
        <!--   服务满足率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">零服吸收率</div>
                <main-chart :chart-data="chartData.chartData_1"/>
            </div>
        </a-col>
        <!-- 来厂台次(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">来厂台次({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_2"/>
            </div>
        </a-col>
        <!--   来厂台次及单车维修业务产值  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">来厂台次及单车维修业务产值</div>
                <main-chart :chart-data="chartData.chartData1"/>
            </div>
        </a-col>
        <!--   售后维修业务分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">售后维修业务分析</div>
                <main-chart :chart-data="chartData.chartData2"/>
            </div>
        </a-col>
        <!-- 保有客户(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">保有客户({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_3"/>
            </div>
        </a-col>
        <!--   保有客户分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">保有客户分析</div>
                <main-chart :chart-data="chartData.chartData3"/>
            </div>
        </a-col>
        <!--   客户维修率及流失率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">客户维修率及流失率</div>
                <main-chart :chart-data="chartData.chartData4"/>
            </div>
        </a-col>
        <!-- 水平事业(东北)   -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">水平事业({{ Area }})</div>
                <profit-table :columnsData="columnsData" :tableData="tableData.table_4"/>
            </div>
        </a-col>
        <!--   单车水平事业毛利及水平事业毛利率  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">单车水平事业毛利及水平事业毛利率</div>
                <main-chart :chart-data="chartData.chartData5"/>
            </div>
        </a-col>
        <!--   水平事业分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">水平事业分析</div>
                <main-chart :chart-data="chartData.chartData6"/>
            </div>
        </a-col>
        <!--   水平事业渗透分析  -->
        <a-col :span="24">
            <div class="card-border">
                <div class="title">水平事业渗透分析
                    <a-select @change="chartData1Change('chartData7',$event)" v-model="carType.chart1" style="float: right; width: 130px;margin-right:1vw;">
                        <a-select-option v-for="(item,i) in selectValue" :key="i" :value="item.title">
                            {{ item.title }}
                        </a-select-option>
                    </a-select>
                </div>
                <main-chart :chart-data="chartData.chartData7"/>
            </div>
        </a-col>
    </a-row>
</template>

<script>
import profitTable from "@/components/profitTable";
import mainChart from "@/views/mainComponent/mainComponets/componets/mainChart";
import { getChangeChartData, getColumnsData, getMyChartData, getMyTableData } from "@/views/profitReport/untilProfit";
import {
 legend, grid, xAxis, yAxisDefault, yAxisRight, seriesLine, seriesBar, smooth,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import echarts from "echarts";

export default {
 name: "thereOperation",
 components: { profitTable, mainChart },
 props: {
  dataParams: {},
  allData: {},
 },
 data() {
  return {
   Area: this.dataParams.PartName.replace("营销中心", ""),
   columnsData: getColumnsData(this.dataParams.ReportDate),
   selectValue: [
    { title: "新车用品", code: "A0303271" },
    { title: "新车保险", code: "A0303272" },
    { title: "金融按揭", code: "A0303273" },
    { title: "上牌验车", code: "A0303274" },
    { title: "二手车", code: "A0303275" },
    { title: "新车延保", code: "A0303276" },
    { title: "自主服务项目", code: "A0303277" },
   ],
   carType: {
    chart1: "新车用品",
   },
   tableData: {
    table_1: {
     data: [],
     kpiCodes: [
      {
       title: "零服吸收率",
       unit: "%",
       code: "B0107002",
      },
     ],
    },
    table_2: {
     data: [],
     kpiCodes: [
      {
       title: "来厂台次",
       unit: "台",
       code: "B0302001",
      },
      {
       title: "单车维修产值",
       unit: "元",
       code: "B0104226",
      },
      {
       title: "单车零部件毛利",
       unit: "元",
       code: "B0105131",
      },
      {
       title: "单车工时毛利",
       unit: "元",
       code: "B0105161",
      },
      {
       title: "售后维修业务毛利率",
       unit: "%",
       code: "B0105181",
      },
     ],
    },
    table_3: {
     data: [],
     kpiCodes: [
      {
       title: "总保有量",
       unit: "个",
       code: "B0303062",
      }, {
       title: "保有客户数",
       unit: "个",
       code: "B0302037",
      }, {
       title: "保有率",
       unit: "%",
       code: "B0304012",
      },
     ],
    },
    table_4: {
     data: [],
     kpiCodes: [
      {
       title: "单车新车用品毛利",
       unit: "元",
       code: "A0104115",
      },
      {
       title: "单车新车保险毛利",
       unit: "元",
       code: "A0104116",
      },
      {
       title: "单车金融按揭毛利",
       unit: "元",
       code: "A0104117",
      },
      {
       title: "单车上牌验车毛利",
       unit: "元",
       code: "A0104118",
      },
      {
       title: "单车二手车毛利",
       unit: "元",
       code: "A0104119",
      },
      {
       title: "单车新车延保毛利",
       unit: "元",
       code: "A0104120",
      },
      {
       title: "单车自主服务项目毛利",
       unit: "元",
       code: "A0104133",
      },
     ],
    },
   },
   chartData: {
    chartData_1: {
     columns: ["区域", "零服吸收率"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "B0107002" },
     ],
     setting: {
      type: "histogram",
      showLine: ["单车维修业务产值"],
      axisSite: { right: ["单车维修业务产值"] },
      yAxisType: ["0.[0]"],
      yAxisName: ["%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar],
     },
    },
    chartData1: {
     columns: ["区域", "来厂台次", "单车维修业务产值"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "B0302001" },
      { unit: "元", code: "B0104226" },
     ],
     setting: {
      type: "histogram",
      showLine: ["单车维修业务产值"],
      axisSite: { right: ["单车维修业务产值"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["个", "元"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}元`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData2: {
     columns: ["区域", "单车零部件毛利", "单车工时毛利", "售后维修业务毛利率"],
     rows: [],
     kpiCodes: [
      { unit: "个", code: "B0105131" },
      { unit: "个", code: "B0105161" },
      { unit: "%", code: "B0105181" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["单车零部件毛利", "单车工时毛利"] },
      showLine: ["售后维修业务毛利率"],
      axisSite: { right: ["售后维修业务毛利率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["个", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}个`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesLine],
     },
    },
    chartData3: {
     columns: ["区域", "A类客户占比", "B类客户占比", "C类客户占比", "保有率"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "B0303019" },
      { unit: "%", code: "B0302035/B0302037" },
      { unit: "%", code: "B0302036/B0302037" },
      { unit: "%", code: "B0304012" },
     ],
     setting: {
      type: "histogram",
      stack: { val: ["A类客户占比", "B类客户占比", "C类客户占比"] },
      showLine: ["保有率"],
      axisSite: { right: ["保有率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["%", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return (params.data) + "%";
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesLine],
     },
    },
    chartData4: {
     columns: ["区域", "客户维系率", "客户流失率"],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "B0303024" },
      { unit: "%", code: "B0304014" },
     ],
     setting: {
      type: "histogram",
      showLine: ["客户维系率", "客户流失率"],
      yAxisType: ["0.[0]"],
      yAxisName: ["%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return (params.data) + "%";
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesLine, seriesLine],
     },
    },
    chartData5: {
     columns: ["区域", "单车水平事业毛利", "水平事业毛利率"],
     rows: [],
     kpiCodes: [
      { unit: "元", code: "A0105072" },
      { unit: "%", code: "A0105023" },
     ],
     setting: {
      type: "histogram",
      showLine: ["水平事业毛利率"],
      axisSite: { right: ["水平事业毛利率"] },
      yAxisType: ["0.[0]", "0.[0]"],
      yAxisName: ["元", "%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        if(params.componentSubType === "line") {
         return (params.data) + "%";
        } else {
         return Number(params.data ?? 0).toFixed(0);
        }
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         if(d.componentSubType === "bar") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(d.value ?? 0).toFixed(0)}元`;
         }
         if(d.componentSubType === "line") {
          arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(1)}%`;
         }
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesLine],
     },
    },
    chartData6: {
     columns: ["区域", "新车用品", "新车保险", "金融按揭", "上牌验车", "二手车", "新车延保", "自主服务项目"],
     rows: [],
     kpiCodes: [
      { unit: "元", code: "A0102085" },
      { unit: "元", code: "A0102086" },
      { unit: "元", code: "A0102087" },
      { unit: "元", code: "A0102088" },
      { unit: "元", code: "A0102089" },
      { unit: "元", code: "A0102091" },
      { unit: "元", code: "A0102261" },
     ],
     setting: {
      type: "histogram",
      stack: {
       val: ["新车用品", "新车保险", "金融按揭", "上牌验车", "二手车", "新车延保", "自主服务项目"],
      },
      yAxisType: ["0.[0]"],
      yAxisName: ["元"],
      label: {
       show: false, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return Number(params.data ?? 0).toFixed(0);
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number(Number((d.value ?? 0)).toFixed(2)).toFixed(0)}元`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault, yAxisRight],
      series: [seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar, seriesBar],
     },
    },
    chartData7: {
     columns: ["区域", "新车用品"],
     defaultColumns: [""],
     rows: [],
     kpiCodes: [
      { unit: "%", code: "A0303271" },
     ],
     setting: {
      type: "histogram",
      stack: { val: [], defaultVal: [] },
      showLine: ["新车用品"],
      defaultShowLine: ["新车用品"],
      axisSite: { right: [], defaultRight: [] },
      yAxisType: ["0.[0]"],
      yAxisName: ["%"],
      label: {
       show: true, // 显示数字
       position: "inside",
       textStyle: {
        color: "black",
       },
       formatter: function(params) {
        return (params.data) + "%";
       },
      },
     },
     options: {
      legend, grid,
      tooltip: {
       show: true,
       trigger: "axis",
       axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
       },
       formatter: function(params) {
        let arr = {};
        params.forEach((d, i) => {
         arr[i] = `${d.marker} ${d.seriesName}: ${Number((d.value ?? 0)).toFixed(1)}%`;
        });
        let re = params[0].name + "<br />";
        Object.values(arr).forEach(a => {
         re += a + "<br />";
        });
        return re;
       },
      },
      xAxis,
      yAxis: [yAxisDefault],
      series: [
       {
        areaStyle: {
         normal: {
          color: new echarts["graphic"].LinearGradient(0, 0, 0, 1, [
           {
            offset: 0,
            color: "rgba(43,230,129,1)",
           }, {
            offset: 0.5,
            color: "rgba(43,230,129,0.5)",
           }, {
            offset: 1,
            color: "rgba(255,255,255,0.3)",
           },
          ]),
         },
        },
        smooth,
       }],
     },
    },
   },
  };
 },
 methods: {
  getArea() {
   this.Area = this.dataParams.PartName.replace("营销中心", "");
  },
  getTableData() {
   this.columnsData = getColumnsData(this.dataParams.ReportDate);
   getMyTableData(this.tableData, this.columnsData, this.allData[this.dataParams.PartId]);
  },
  getChartData() {
   getMyChartData(this.allData, this.chartData, this.dataParams.AreaList);
  },
  chartData1Change(chart, val) {
   const tableData = this.chartData[chart];
   let arr = [];
   tableData.defaultColumns.forEach(cl => {
    cl = val + cl;
    arr.push(cl);
   });
   tableData.columns = ["区域", ...arr];

   let stackArr = [];
   tableData.setting.stack.defaultVal.forEach(sl => {
    sl = val + sl;
    stackArr.push(sl);
   });
   tableData.setting.stack.val = stackArr;

   let showLineArr = [];
   tableData.setting.defaultShowLine.forEach(() => {
    showLineArr.push(val);
   });
   tableData.setting.showLine = showLineArr;

   let axisSiteArr = [];
   tableData.setting.axisSite.defaultRight.forEach(sl => {
    sl = val + sl;
    axisSiteArr.push(sl);
   });
   tableData.setting.axisSite.right = axisSiteArr;
   /*下拉切换CODE*/
   this.selectValue.forEach(d => {
    if(d.title === val) tableData.kpiCodes[0].code = d.code;
   });
   getChangeChartData(this.allData, tableData, this.dataParams.AreaList);
  },
 },
 created() {
  this.getTableData();
  this.getChartData();
  this.getArea();
 },
 watch: {
  allData: {
   handler: function() {
    this.getTableData();
    this.getChartData();
    this.getArea();
   },
   deep: true,
  },
 },
};
</script>

<style lang="less" scoped>
@import "../../profitReport/profit";
</style>

