import dayjs from "dayjs";
import moment from "moment";

export function validatenull (val) {
  if (typeof val == "boolean") {
    return false;
  }
}

export function dayFormatMouth (day) {
    if (day === undefined){
        return ''
    }
  return dayjs(day).format("YYYY年MM月");
}

export function dayFormat (day) {
  return dayjs(day).format("YYYY年MM月DD日");
}

export function CreadeDay (day) {
  return dayjs(day).format("YYYY-MM-DD");
}

//限制当天之前的日期不可选
export function disabledDate(current) {
 // return current && current > moment().subtract(1, "days"); //当天之后的不可选，不包括当天
//  return current && current > moment().endOf("day");// 当天之后的不可选，包括当天
}
/*根据窗口大小返回值 暂时取消适屏
* */
export function GetWindows(){
   return  document.documentElement.clientWidth > 1400 ?  24 :  24;
 //  return  document.documentElement.clientWidth > 1400 ?  12 :  24;
}
