import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import Antd from "ant-design-vue";
import "./assets/theme.less";
import "vant/lib/index.css";
import Vuex from "vuex";
import store from "./store/store";
import Http, {HttpStaging} from "./api/http";
import "ant-design-vue/dist/antd.less";
import "./assets/index.css";
import {
    Button,
    DatePicker,
    Select,
    Option,
    Row,
    Col,
    Table,
    TableColumn,
    Pagination,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Cascader,
    Upload,
} from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import "babel-polyfill";

// Vue.use(ElementUI);

Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Select);
Vue.use(Option);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Cascader);
Vue.use(Upload);
Vue.config.productionTip = false;
Vue.use(Antd);
// 全局配置消息位置
Vue.prototype.$message.config({ top: "30%" });

Vue.use(Vuex);
Vue.prototype.$store = store;
import switchSetting from "@/components/switchSetting";

Vue.prototype.$Http = Http; // 正式环境数据
Vue.prototype.$HttpStaging = HttpStaging;  // 测试环境数据
Vue.component("switch-setting",switchSetting);

Vue.prototype.$axios = axios;

/*vuex
控制登陆  路由拦截器 */

/*router.beforeEach((to, from, next) => {
    if (store.state.userInfo || to.path === "/login") {
        next()
    } else {
        next({
            path: "/login"
        })
    }

})*/
//引用 echarts 4.9
import * as echarts from "echarts";

Vue.prototype.$echarts = echarts;//将echarts绑定到vue原型链上

// 引用 V-Charts
import VCharts from "v-charts";
Vue.use(VCharts);

//引用 underscore
import underscore from "underscore";

Vue.prototype._ = underscore;
Array.prototype.chain = function() {
    return underscore.chain(this);
};

Vue.prototype.$toFixed = function (length, inputNum) {
    if (inputNum === null) inputNum = 0;
    var carry = 0; //存放进位标志
    var num, multiple; //num为原浮点数放大multiple倍后的数，multiple为10的length次方
    var str = inputNum + ""; //将调用该方法的数字转为字符串
    var dot = str.indexOf("."); //找到小数点的位置
    if (dot != -1 && str.substr(dot + length + 1, 1) >= 5) {
        carry = 1; //找到要进行舍入的数的位置，手动判断是否大于等于5，满足条件进位标志置为1
    }
    multiple = Math.pow(10, length); //设置浮点数要扩大的倍数
    let multiply = this.$numMulti(inputNum, multiple); //小数相乘精度问题
    num = Math.floor(multiply) + carry; //去掉舍入位后的所有数，然后加上我们的手动进位数
    var result = num / multiple + ""; //将进位后的整数再缩小为原浮点数

    /*
     * 处理进位后无小数
     */
    dot = result.indexOf(".");
    if (dot < 0) {
        result += ".";
        dot = result.indexOf(".");
    }

    /*
     * 处理多次进位
     */
    var len = result.length - (dot + 1);
    if (len < length) {
        for (var i = 0; i < length - len; i++) {
            result += 0;
        }
    }
    return result;
};

// 页面进度条
import "nprogress/nprogress.css";

router.beforeEach((to, from, next) => {
    // 根据 Cookie 进行页面跳转
    let nextUrl = null;
    let redirectToChangePwd = "/changePwd";
    let redirectToLogin = "/login";
    if (document.cookie.indexOf("CJ_LMS_SSO_UID") < 0) {
        nextUrl = redirectToLogin;
    } else if (document.cookie.indexOf("NEED_MODIFY_PASSWORD") >= 0) {
        nextUrl = redirectToChangePwd;
    }
    if (nextUrl != null && to.path !== nextUrl) {
        next(nextUrl);
    } else {
        next();
    }
});
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");


