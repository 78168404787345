const CITY_MANNAGE_API = {
    //城市列表
    GetCityListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCityListByPage'
    },

    //小区列表
    GetArea: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetArea'
    },

    //新增大区
    InsertCity: {
        method: 'get',
        url: '/BasicSetting/BasicSet/InsertCity'
    },

    //获取详情
    GetCityInfoById: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetCityInfoById'
    },

    //编辑大区
    EditCity: {
        method: 'get',
        url: '/BasicSetting/BasicSet/EditCity'
    },

    //删除大区
    DeleteCity: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeleteCity'
    }
}
export default CITY_MANNAGE_API
