<template>
  <a-card class="card">
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 6 }"
    >
      <a-form-model-item label="名称" prop="partName">
        <a-input v-model="form.partName" />
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="sort">
        <a-input-number v-model="form.sort" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" @click="save"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: {
        partName: "",
        sort: null,
        partId: "",
      },
      rules: {
        partName: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async init() {
      let self = this;
      //编辑
      if (self.$route.path == "/bgMannage/edit") {
        //获取当前数据
        let res = await self.$Http.GetPartInfoById({
          partId: self.$route.query.partId,
        });
        if (res.Data.length > 0) {
          self.form.partName = res.Data[0].PartName;
          self.form.sort = res.Data[0].Sort;
          self.form.partId = res.Data[0].PartId;
        }
      }
      //新增
      else {
        //获取排序号
        let res = await self.$Http.GetPartSortIndex();
        self.form.sort = res.Data;
      }
    },
    async save() {
      let self = this;
      self.$refs.form.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res =
            self.$route.path == "/bgMannage/edit"
              ? await self.$Http.EditPart(this.form)
              : await self.$Http.InsertPart(this.form);
          if (res.Success) {
            this.$message.success(res.Data);
            this.$router.push("/bgMannage/list");
          } else {
            this.$message.error(res.Message);
          }
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>