<template>
    <section>
        <a-card>
            <h1 class="title">改善计划制定</h1>
            <a-form-model ref="form" layout="horizontal" :model="form" :rules="rules"
                          :label-col="{span:6}"
                          :wrapper-col="{span:14}">
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="弱项KPI上层名称">
                            <a-select style="width: 200px" show-search v-model="KPIObj.FirstKpi"
                                      @change="firstKpiCodeChange"
                                      :filterOption="selectorFilter" :disabled="!KPIBaseEditable">
                                <a-select-option v-for="(item,i) in FirstKpiList" :key="i" :value="item">
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="弱项KPI名称">
                            <a-select style="width: 200px" show-search :filter-option="false" v-model="KPIObj.Kpi" @search="searchKpi"
                                      :labelInValue="true" @change="KpiCodeChange" :disabled="!KPIBaseEditable">
                                <a-select-option v-for="item in KpiList" :key="item.KpiCode" :value="item.KpiCode" :disabled="item.disabled">
                                    {{ item.KpiName }}
                                </a-select-option>
                            </a-select>
                            <a style="padding-left: 20px" v-if="KPIObj.Kpi.key!=''" @click="showCaseList">查看案例集</a>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="弱项KPI当前值">
                            <!--                        <a-input style="width: 200px" placeholder="请选择弱项KPI当前值" v-model="queryParam.NumValue"></a-input>-->
                            <a-select style="width: 200px" show-search v-model="queryParam.NumValue"
                                      :filterOption="selectorFilter"
                                      :disabled="!KPIBaseEditable"
                            >
                                <a-select-option v-for="(item,index) in KpiValueList" :key="index" :value="item">
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="改善目标值" prop="TargetValue">
                            <a-input-number style="width: 200px" v-model="form.TargetValue" placeholder="请输入"/>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="计划开始时间">
                            <a-date-picker style="width: 200px" :disabled="true" v-model="form.PlanStartTime"
                                           placeholder="请选择"
                                           :disabledDate="disabledDate"/>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="计划结束时间">
                            <a-date-picker style="width: 200px" :disabled="true" v-model="form.PlanEndTime"
                                           placeholder="请选择"
                                           :disabledDate="disabledDate"/>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="联系号码" prop="Mobile">
                            <a-input style="width: 200px" v-model="form.Mobile" placeholder="请输入"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="执行部门" prop="ExecPart">
                            <a-input style="width: 200px" v-model="form.ExecPart" placeholder="请输入"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="执行人" prop="ExecPeople">
                            <a-input style="width: 200px" v-model="form.ExecPeople" placeholder="请输入"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="全国标杆">
                            <a-input :disabled="true" style="width: 200px" v-model="BG.CountryBg"
                                     placeholder="未知"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="区域标杆">
                            <a-input :disabled="true" style="width: 200px" v-model="BG.AreaBg"
                                     placeholder="未知"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <a-form-model ref="form1" :model="form" :rules="rules">
                <a-row>
                    <a-col>
                        <a-form-model-item label="改善思路提示">
                            <div
                                style="min-height: 100px;overflow: scroll; overflow:hidden;border: #d5d5d5 1px solid;padding: 0.5rem "
                                v-html="KPINotice">
                            </div>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="弱项KPI现状问题描述" prop="ProblemDesc">
                            <a-textarea
                                v-model="form.ProblemDesc"
                                placeholder="请输入100字左右的现状描述"
                                :auto-size="{ minRows: 8, maxRows: 15 }"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="制定改善计划" prop="PlanDesc">
                            <a-textarea
                                v-model="form.PlanDesc"
                                placeholder="请输入具体的行动计划"
                                :auto-size="{ minRows: 8, maxRows: 15 }"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div style="display: flex;justify-content: center">
                <a-button @click="$router.go(-1)">取消</a-button>
                <a-button type="primary" @click="submit">提交</a-button>
            </div>
        </a-card>
        <a-modal v-model="visible" title="案例集" :footer="null" @cancel="handelCancel">
            <a-list item-layout="horizontal" :data-source="diaLogData">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <a-list-item-meta
                    >
                        <a-button slot="description" type="primary" size="small" @click="downLoad(item)">下载</a-button>
                        <span slot="title">
                            <div style="display: flex;justify-content: space-between">
                               <span>{{ item.FileUploadName }}</span>
                                <span>{{ item.CreateTime }}</span>
                            </div>
                        </span>
                    </a-list-item-meta>
                </a-list-item>
            </a-list>
        </a-modal>
    </section>
</template>

<script>
import moment from "moment";

export default {
    name: "createImprovePlan",
    data() {
        return {
            kpiData:[],
            diaLogData: [],
            visible: false,
            BG: {
                AreaBg: 0,
                CountryBg: 0
            },
            //标杆查询日期，默认为上个月
            yearMonth: moment().add(-1, 'Month').format('YYYY-MM-01'),
            form: {
                // FirstCategory: '',
                // FirstKpiCode: '',
                // KpiName: '',
                KpiCode: '',
                TargetValue: '',
                PlanStartTime: '',
                PlanEndTime: '',
                Mobile: '',
                ExecPart: '',
                ExecPeople: '',
                ProblemDesc: '',
                PlanDesc: ''
            },
            //两个KPI选择框对象
            KPIObj: {
                FirstKpi: '',
                Kpi: {key: '', label: ''},
            },
            queryParam: {
                code: '',
                NumValue: '',
                //默认不处理数据
                formatType: ""
            },
            rules: {
                TargetValue: [
                    {
                        required: true,
                        message: '请输入改善目标值',
                        trigger: ["blur", "change"]
                    },
                ],
                Mobile: [
                    {
                        required: true,
                        message: '请输入联系号码',
                        trigger: ["blur", "change"]
                    },
                    {
                        message: '手机号格式错误',
                        trigger: 'blur',
                        pattern: /^0?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9])[0-9]{8}$/
                    }
                ],
                ProblemDesc: [
                    {
                        required: true,
                        message: '请输入弱项KPI现状问题描述',
                        trigger: ["blur", "change"]
                    },
                ],
                PlanDesc: [
                    {
                        required: true,
                        message: '请输入制定改善计划',
                        trigger: ["blur", "change"]
                    },
                ],
                ExecPart: [
                    {
                        required: true,
                        message: '请输入执行部门',
                        trigger: ["blur", "change"]
                    },
                ],
                ExecPeople: [
                    {
                        required: true,
                        message: '请输入执行人',
                        trigger: ["blur", "change"]
                    },
                ],
            },
            //弱项KPI上层列表
            FirstKpiList: [],
            //弱项KPI名称列表
            KpiList: [],
            //弱项KPI当前值列表
            KpiValueList: [],
            //KPI基础数据是否可编辑
            KPIBaseEditable: true,
            KPINotice: "<p>暂无</p>"

        }
    },
    created() {
        this.getParamsFromQuery();
        this.getImprovePlanDate();
    },
    mounted() {
        //获取KPI上层List
        this.getFirstKpiList();
    },
    methods: {
        searchKpi(value) {
            const { kpiData } = this;
            if (value.length === 0) {
                if (kpiData.length > 50) {
                    let data = kpiData.filter((_k, i) => i < 50);
                    this.KpiList = [
                        ...data,
                        { KpiCode: "tip", KpiName: "（仅展示前50条）", disabled: true },
                    ];
                } else {
                    this.KpiList = kpiData;
                }
            } else {
                let data = kpiData.filter(k =>String(k.KpiName).toLowerCase().indexOf(value.toLowerCase()) !== -1);
                if (data.length > 50) {
                    this.KpiList = [
                        ...data.filter((_k, i) => i < 50),
                        { KpiCode: "tip", KpiName: "（仅展示前50条）", disabled: true },
                    ];
                } else {
                    this.KpiList = data;
                }
            }
        },
        handelCancel() {
            this.diaLogData = [];
        },
        async showCaseList() {
            this.visible = true;
            //获取数据
            let res = await this.$Http.GetCaseBookByCode({KpiCode: this.KPIObj.Kpi.key});
            if (res.Success) {
                this.diaLogData = res.Data;
            } else {
                this.$message.error(res.Message);
            }

        },
        async downLoad(item) {
            //下载
            let res = await this.$Http.FileShareLink({objectkey: item.ObjectKey});
            if (res.Success) {
                window.open(res.Data)
            } else {
                this.$message.error(res.Message)
            }
        },
        //获取该计划开始时间和结束时间
        getImprovePlanDate() {
            //开始时间为下个月1号
            this.form.PlanStartTime = moment().add(1, "Month").format("YYYY-MM-01")
            //结束时间为三个月后的最后一天
            this.form.PlanEndTime = moment(moment().add(4, "Month").format("YYYY-MM-01")).add(-1, "days").format("YYYY-MM-DD")
        },
        //限制当天之前的日期不可选
        disabledDate(current) {
            return current && current < moment().subtract(1, "days"); //当天之后的不可选，不包括当天
        },

        selectorFilter(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        submit() {
            //连续验证两个表单
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.$refs.form1.validate(async (valid2) => {
                        if (valid2) {
                            // 合法性校验
                            if (this.form.PlanEndTime <= this.form.PlanStartTime) {
                                this.$message.error("计划开始时间不能晚于计划结束时间")
                                return
                            }
                            if (this.KPIObj.FirstKpi.label === '') {
                                this.$message.error("请选择弱项KPI上层名称")
                                return
                            }
                            if (this.KPIObj.Kpi.label === '') {
                                this.$message.error("请选择弱项KPI名称")
                                return
                            }
                            //ASC越大越好,DESC指标越小越好
                            if (this.queryParam.PercentileSort === "ASC") {
                                if (this.form.TargetValue <= this.queryParam.NumValue) {
                                    this.$message.error("该弱项KPI改善目标值不能小于或等于当前值")
                                    return
                                }
                            } else {
                                if (this.form.TargetValue >= this.queryParam.NumValue) {
                                    this.$message.error("该弱项KPI改善目标值不能大于或等于当前值")
                                    return
                                }
                            }
                            // this.form.FirstCategory = this.KPIObj.FirstKpi.label;
                            // this.form.FirstKpiCode = this.KPIObj.FirstKpi.key;
                            // this.form.KpiName = this.KPIObj.Kpi.label;
                            this.form.KpiCode = this.KPIObj.Kpi.key;
                            let res = await this.$Http.InsertImprovePlan(this.form);
                            if (res.Success) {
                                this.$message.success(res.Data);
                                this.$router.push('/improveplan/dealer/list')
                            } else {
                                this.$message.error(res.Message);
                            }
                        }
                    });
                }
            });
        },
        async getParamsFromQuery() {
            if (Object.keys(this.$route.query).length !== 0) {
                //如果路由中有对应参数，则不可以编辑基础数据
                this.KPIBaseEditable = false;
                this.queryParam = this.$route.query;
                this.yearMonth = this.queryParam.month;
                this.queryParam.NumValue = this.dealData(this.queryParam.NumValue);
                //根据Code查询对应指标名
                let res = await this.$Http.GetWeakListByCode({WeakCode: this.queryParam.code});
                if (res.Success) {
                    if (res.Data.length > 0) {
                        let resData = res.Data[0];
                        this.KPIObj.FirstKpi = resData.FirstCategory;
                        this.firstKpiCodeChange();
                        this.KPIObj.Kpi.label = resData.KpiName;
                        this.KPIObj.Kpi.key = resData.KpiCode;
                        this.getWeakList()
                        this.queryParam.PercentileSort = resData.PercentileSort;
                    }
                } else {
                    this.$message.error(res.Message);
                }
                await this.getBGValue(this.queryParam.code);


            }
        },
        async getBGValue(code) {
            //根据Code查询标杆值
            let res = await this.$Http.GetBGNumValue({KpiCode: code, YearMonth: this.yearMonth});
            if (res.Success) {
                if (res.Data.length > 0) {
                    let resData = res.Data[0];
                    this.BG.AreaBg = this.dealData(resData.AreaBG);
                    this.BG.CountryBg = this.dealData(resData.CountryBG);
                }
            } else {
                this.$message.error(res2.Message);
            }
        },

        //根据要求处理数据
        dealData(value) {
            //处理KPI当前值数据
            switch (this.queryParam.formatType) {
                // 保留整数：0
                case "0":
                    return Number(value).toFixed(0);
                // 率处理：1
                case "1":
                    return (value * 100).toFixed(1);
                // 保留两位：2
                case "2":
                    return Number(value).toFixed(2);
                // 万元处理：3
                case "3":
                    return (value / 10000).toFixed(2)
                // 默认不处理
                default:
                    return value;
            }
        },
        async getFirstKpiList() {
            let res = await this.$Http.GetWeakCodeList();
            if (res.Success) {
                let data=[...res.Data.filter(k=>k.FirstCategory!="其他类别指标"),{FirstCategory:"其他类别指标"}];
                this.FirstKpiList = this._.pluck(data, "FirstCategory");
            } else {
                this.$message.error(res.Message);
            }
        },
        async firstKpiCodeChange() {
            let res = await this.$Http.GetWeakCodeByFirst({FirstCategory: this.KPIObj.FirstKpi})
            if (res.Success) {
                this.kpiData = res.Data;
                if(Array.isArray(this.kpiData)&&this.kpiData.length>50){
                    this.KpiList=[...this.kpiData.filter((_k,i)=>i<50),{KpiCode:"tip",KpiName:"（仅展示前50条）",disabled:true}];
                }else{
                    this.KpiList=this.kpiData;
                }
                // 只有用户修改才清空级联
                if (Object.keys(this.$route.query).length === 0) {
                    //清空级联
                    this.KPIObj.Kpi = {key: '', label: ''};
                    this.queryParam.NumValue = null;
                    this.KpiValueList = [];
                    //清空改善思路
                    this.KPINotice = "<p>暂无</p>"
                }

            } else {
                this.$message.error(res.Message);
            }
        },
        async KpiCodeChange(obj) {
            let res = await this.$Http.GetNumValueByCode({KpiCode: obj.key});
            this.getBGValue(obj.key)
            if (res.Success) {
                this.KpiValueList = this._.pluck(res.Data, "NumValue");
                if (this.KpiValueList.length > 0) {
                    this.queryParam.PercentileSort = res.Data[0].PercentileSort;
                    this.queryParam.NumValue = this.KpiValueList[0];
                }
            } else {
                this.$message.error(res.Message);
            }

            await this.getWeakList();
        },
        async getWeakList() {
            let res2 = await this.$Http.GetWeakListByCode({WeakCode: this.KPIObj.Kpi.key});
            if (res2.Success) {
                if (res2.Data.length > 0) {
                    if (res2.Data[0].KpiDesc) {
                        this.KPINotice = res2.Data[0].KpiDesc;
                    } else {
                        this.KPINotice = "<p>暂无</p>";
                    }
                }
            } else {
                this.$message.error(res.Message);
            }
        }
    },
}
</script>

<style scoped>
.title {
    text-align: center;
    font-weight: bold;
}
</style>
