<template>
  <a-row :gutter="[24,24]">
    <a-col :span="24">
      <a-card style="padding-top: 12px">
        <a-descriptions bordered :column="3" style="margin-bottom: 20px;">
          <a-descriptions-item label="店均新车终端销量">{{ GetIndexValue("店均新车终端销量","取整") }}</a-descriptions-item>
          <a-descriptions-item label="店均平均单车销售综合毛利">{{
              GetIndexValue("店均平均单车可变业务毛利", "元")
            }}
          </a-descriptions-item>
          <a-descriptions-item label="店均零售渠道终端销量占比">{{
              GetIndexValue("店均零售渠道终端销量占比", "%")
            }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="info">
          <p>
            1、店均销售业务盈利能力：单台裸车毛利
            <span v-html='GetDesc(data,"A0106026", ["上升至", "下降至"])'></span>
            ；单车返利
            <span v-html='GetDesc(data,"A0105071")'></span>
            ；单车水平事业毛利
            <span v-html='GetDesc(data,"A0105072", ["上升至", "下降至"])'></span>
            ；销售业务综合毛利率
            <span v-html='GetDesc(data,"A0107002", ["上升至", "下降至"])'></span>
            。
          </p>
          <p>
            2、店均销售渠道销量占比：展厅终端销量占比
            <span v-html='GetDesc(data,"A0304024")'></span>
            ；IDCC终端销量占比
            <span v-html='GetDesc(data,"A0304025")'></span>
            ；二网终端销量占比
            <span v-html='GetDesc(data,"A0304026")'></span>
            ；大客户终端销量占比
            <span v-html='GetDesc(data,"A0304027")'></span>
            。
          </p>
          <p>
            3、店均销售过程效率：展厅店均首次客流
            <span v-html='GetDesc(data,"A1302012")'></span>
            （成交率
            <span v-html='GetDesc(data,"A1303081", ["上升至", "下降至"])'></span>
            ）；展厅店均再次客流
            <span v-html='GetDesc(data,"A1302013")'></span>
            （成交率
            <span v-html='GetDesc(data,"A1303082", ["上升至", "下降至"])'></span>
            ）；IDCC店均线索总数
            <span v-html='GetDesc(data,"A1302115")'></span>
            （线索转化率
            <span v-html='GetDesc(data,"A1303032")'></span>
            ）。
          </p>
          <p id="one_2">
            4、店均销售水平事业：店均销售水平事业毛利
            <span v-html='GetDesc(data,"A0104067")'></span>
            ；新车用品渗透率
            <span v-html='GetDesc(data,"A0303271", ["上升至", "下降至"])'></span>
            ；新车保险渗透率
            <span v-html='GetDesc(data,"A0303272", ["上升至", "下降至"])'></span>
            ；金融按揭渗透率
            <span v-html='GetDesc(data,"A0303273", ["上升至", "下降至"])'></span>
            ；上牌验车渗透率
            <span v-html='GetDesc(data,"A0303274", ["上升至", "下降至"])'></span>
            。
          </p>
          <p>
            5、店均销售人员效率：销售顾问流失率
            <span v-html='GetDesc(data,"C0303153")'></span>
            ，人均效率
            <span v-html='GetDesc(data,"A0107014", ["增至", "降至"])'></span>
            。
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card title="1、店均销售业务盈利能力">
        <a-col :span="isWin">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_2_1_1"
          >
          </monthReportTableComponent>
        </a-col>
        <a-col :span="isWin" class="ppTop30">
          <chartComponent :chart-data="data.chartData_2_1_1"/>
        </a-col>
        <a-col :span="24">
          <chartComponent :chart-data="data.chartData_2_1_2"/>
        </a-col>
      </a-card>
      <div id="two_2"></div>
    </a-col>

    <a-col :span="24">
      <a-card title="2、店均渠道销量占比">
        <a-col :span="24" class="ppTop30">
          <chartComponent :chart-data="data.chartData_2_3_1"/>
        </a-col>

      </a-card>
      <div id="there_2"></div>

    </a-col>
    <a-col :span="24">
      <a-card title="3、店均销售过程效率">
        <a-col :span="isWin">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_2_4_1"
          >
          </monthReportTableComponent>
        </a-col>
        <a-col :span="isWin" class="ppTop30">
          <chartComponent :chart-data="data.chartData_2_4_1"/>
        </a-col>
        <a-col :span="24">
          <chartComponent :chart-data="data.chartData_2_4_2"/>
        </a-col>
      </a-card>
      <div id="four_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="4、店均销售水平事业">
        <a-col :span="isWin">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_2_5_1"
          >
          </monthReportTableComponent>
        </a-col>
        <a-col :span="isWin" class="ppTop30">
          <chartComponent :chart-data="data.chartData_2_5_1"/>
        </a-col>
        <a-col :span="24">
          <chartComponent :chart-data="data.chartData_2_5_2"/>
        </a-col>
      </a-card>
      <div id="five_2"></div>
    </a-col>
    <a-col :span="24">
      <a-card title="5、店均销售人员效率">
        <a-col :span="24">
          <monthReportTableComponent :columns-data="columnsData"
                                     :table-data="data.tableData_2_2_1"
          >
          </monthReportTableComponent>
        </a-col>
      </a-card>
    </a-col>
    <a-anchor v-if="reportParams.isClose">
      <div class="close" @click="closeAnchor()">
        <a-icon type="close"/>
        关闭
      </div>
      <a-anchor-link href="#one_2" title="1、店均销售业务盈利能力"/>
      <a-anchor-link href="#two_2" title="2、店均渠道销量占比"/>
      <a-anchor-link href="#there_2" title="3、店均销售过程效率"/>
      <a-anchor-link href="#four_2" title="4、店均销售水平事业"/>
      <a-anchor-link href="#five_2" title="5、店均销售人员效率"/>
    </a-anchor>
  </a-row>
</template>

<script>
import monthReportTableComponent from "../../../../components/monthReportTableComponent";
import chartComponent from "../../../../components/chartComponent";
import { GetDesc, getEmptyTemplate, numFormat } from "@/until/reportCalculate";
import { columnsImport, variableData } from "../data.js";
import { GetWindows } from "@/until/dayFormat";

export default {
  props: {
    reportParams: {
      isClose:true,
    },
    reportDate: String,
    areaData: {},
    isRegionReport: Boolean, // 是否地区报告,true就是地区
  },
  components: { monthReportTableComponent, chartComponent },
  data() {
    return {
      isWin: 24,
      isClose: true,
      emptyTemplate: getEmptyTemplate(),
      columnsData: columnsImport.columnsImportDataPC,
      data: variableData,
    };
  },
  watch: {
    reportParams: {
      handler: function() {
        columnsImport.loadData(this, this.data, this.reportParams);
      },
      deep: true,
    },
  },
  created() {
    if (this.isRegionReport) {
      this.columnsData = columnsImport.columnsImportDataPcRegionReport;
    }
    this.isWin = GetWindows();
    columnsImport.loadData(this, this.data, this.reportParams);
  },
  methods: {
    closeAnchor() {
      // eslint-disable-next-line vue/no-mutating-props
      this.reportParams.isClose = false;
    },
    GetIndexValue(key, unit) {
      let value = 0;
      if (this.areaData && this.areaData["IndexValues"]) {
        value = this.areaData["IndexValues"][key];
      }
      return numFormat(key, value, unit);
    },
    GetDesc,
  },
};
</script>
<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";
</style>
