<template>
  <a-row :gutter="[0,8]">
    <a-col :span="24" v-if="isCloseA">
      <a-anchor :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          <a-col :span="20">
            月份：
            <a-month-picker @change="handleDateChange" :disabledDate="disabledDate" placeholder="请选择报告月份"
                v-model="ReportDateMoment" :allowClear="false" style="width: 110px"
            />
            区域：
            <a-select @change="handleDateChange" v-model="params.PartName" style="width: 130px" placeholder="请选择大区">
              <a-select-option v-for="item in partList" :key="item.PartId" :value="item.PartName">
                {{ item.PartName }}
              </a-select-option>
            </a-select>
            <a-tooltip style="margin-left: 5px;" placement="top" title="是否去除新店或异常店?">
              异常店
              <a-icon type="info-circle"/>
              :
              <a-select @change="handleDateChange" v-model="params.copyType" style="width:50px;">
                <a-select-option :value="0">
                  否
                </a-select-option>
                <a-select-option :value="1">
                  是
                </a-select-option>
              </a-select>
            </a-tooltip>
          </a-col>
          <a-col style="float: right;">
          <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
            <a-tooltip placement="top" :title="`点击生成《${ moment(this.params.ReportDate).format('yyyy年MM月')+this.params.PartName }业绩分析报告-${this.views[this.activeKey].name}》`">
              <a-button type="primary" @click="generateImages()">生成长图</a-button>
            </a-tooltip>
            <a-button type="primary" @click="doPrint" style="float: right;">生成PDF</a-button>
          </a-col>
        </div>
      </a-anchor>
    </a-col>
    <a-col :span="24">
      <div class="" ref="capture">
        <a-card class="border">
          <div class="report_bg">
            BDM业绩分析报告 ({{ getNewName(views[activeKey].name) }}) <br>
            <span style="font-size: 22px;font-weight: 500"> {{
                params.PartName + moment(params.ReportDate).format("  yyyy年MM月") }}</span>
          </div>
          <a-descriptions bordered :column="3" style="padding-top: 30px">
            <a-descriptions-item label="销售店总数">{{ areaData.Total }}</a-descriptions-item>
            <a-descriptions-item label="数据使用店数">{{ areaData["DataSuccess"] }}</a-descriptions-item>
            <a-descriptions-item label="新店数量">{{ areaData["New"] }}</a-descriptions-item>
            <a-descriptions-item label="数据收集店数">{{ areaData["Used"] }}</a-descriptions-item>
            <a-descriptions-item label="数据未通过店数">{{ areaData["DataFail"] }}</a-descriptions-item>
            <a-descriptions-item label="异常店数量">{{ areaData["Exception"] }}</a-descriptions-item>
          </a-descriptions>
        </a-card>

        <div style="margin-top: 8px;">
          <Overview v-if="activeKey==='d082cc8d1'" :report-params="params" :area-data="areaData"></Overview>
          <variable v-else-if="activeKey==='8480fd2a2'" :report-params="params" :area-data="areaData"></variable>
          <regularize v-else-if="activeKey==='509aa1bd3'" :report-params="params" :area-data="areaData"></regularize>
          <property v-else-if="activeKey==='54b6730f4'" :report-params="params" :area-data="areaData"></property>
          <cost v-else-if="activeKey==='4f6acd6e5'" :report-params="params" :area-data="areaData"></cost>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import moment from "moment";
import Overview from "@/views/countryMonthReport/analysisReport/childComponent/Overview";
import variable from "@/views/countryMonthReport/analysisReport/childComponent/variable";
import regularize from "@/views/countryMonthReport/analysisReport/childComponent/regularize";
import property from "@/views/countryMonthReport/analysisReport/childComponent/property";
import cost from "@/views/countryMonthReport/analysisReport/childComponent/cost";
import { disabledDate } from "@/until/dayFormat";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
  components: { Overview, variable, regularize, property, cost },
  data() {
    return {
      moment,
      isCloseA: true,
      activeKey: "NONE",
      views: {
        "d082cc8d1": {
          name: "整体概览",
        },
        "8480fd2a2": {
          name: "销售业务板块",
        },
        "509aa1bd3": {
          name: "售后业务板块",
        },
        "54b6730f4": {
          name: "资产风险",
        },
        "4f6acd6e5": {
          name: "费用管控",
        },
      },
      isRegionReport: true,
      areaData: {},
      params: {
        ReportDate: "",
        PartName: null,
        copyType: 0,
        isClose: true,
      },
      partList: [],
    };
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
  watch: {
    "$route"(to, from) {
      if(this.$route.query.key) {
        this.activeKey = this.$route.query.key;
      }
    },
  },
  async created() {
    let key = this.$route.query.key;
    let view = this.views[key];
    if(!view) {
      this.$message.error("请求参数有误，请联系管理员处理");
      return;
    }
    this.activeKey = key;

    // js判断是否为空对象
    this.params.copyType = 0;
    this.params.ReportDate = this.$route.query.ReportDate ?? moment().add(-1, "M").format("YYYY-MM-01");
    this.params.PartName = this.$route.query.PartName;

    await this.getPart();
    await this.GetDealerInputStatistics();
  },
  methods: {
    getNewName(val) {
      if(val === "销售业务板块") {
        return "销售-业务板块";
      } else if(val === "售后业务板块") {
        return "售后-业务板块";
      } else {
        return val;
      }
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      this.params.isClose = false;
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator.msSaveOrOpenBlob) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, `${moment(this.params.ReportDate).format("yyyy年MM月") + this.params.PartName}业绩分析报告-${this.getTitle(this.activeKey)}.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${moment(this.params.ReportDate).format("yyyy年MM月") + this.params.PartName}业绩分析报告-${this.getTitle(this.activeKey)}.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
    disabledDate,
    async handleDateChange() {
      await this.GetDealerInputStatistics();
    },
    async getPart() {
      let res = await this.$Http.GetDealersByRole();
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.partList = res.Data;
      if(!this.params.PartName && this.partList.length > 0) {
        this.params.PartName = res.Data[0].PartName;
      }
    },
    // 点击打印双预览
    async doPrint() {
      let res = await this.$Http.PdfGenerateByUrl({
        Url: `${window.location.origin}/regionMonthReport/print2?ReportDate=${this.params.ReportDate}&PartName=${this.params.PartName}`,
        FileName: `${this.ReportDateMoment.format("YYYY年MM月")}${this.params.PartName}经营分析报告`,
        PageWidth: 16.54,
        PageHeight: 11.7,
        Cookie: document.cookie,
      });
      if(res.isError) {
        this.$message.error(res["errMsg"]);
        return;
      }
      window.open(res.data["pdfUrl"]);
    },
    setActionKey(key) {
      this.activeKey = key;
    },
    async GetDealerInputStatistics() {
      if(!this.params.PartName) {
        return;
      }
      let res = await this.$Http.GetDealerInputStatistics(this.params);
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.areaData = res.Data;
    },

    getTitle(activeKey) {
      switch (activeKey) {
      case "2":
        return "销售业务板块";
      case "3":
        return "售后业务板块";
      case "4":
        return "资产风险";
      case "5":
        return "费用管控";
      default:
        return "整体概览";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fix_border {
  padding: 20px 10px 20px 10px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
  margin-left: -2px;
  }

.space {
  margin-left: 20px;

  .ant-tag {
    cursor: pointer;
    padding: 4px 13px;
    }
  }

.report_bg {
  height: 136px;padding-top: 42px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  }

.info {
  margin: 12px auto;
  background: #e6f7ff;
  padding: 24px 22px;
  border: 1px solid #bae7ff;
  }

.pai-ming {
  padding-top: 20px;
  margin-right: 40px;
  color: #24619e;
  font-size: 30px;

  em {
    font-size: 50px;
    }
  }

.zhi-bio {
  ul {
    li {
      float: left;
      text-align: center;

      > div {
        border: 1px solid #dddddd;
        height: 88px;
        padding: 14px;
        background: rgba(242, 245, 248, 0.6);

        div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 4px solid rgba(67, 167, 16, 0.3);

          span {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #43a710;
            }
          }
        }

      > p {
        height: 25px;
        line-height: 25px;
        background: #e8e8e8;
        border: 1px solid #dddddd;
        }

      &:nth-child(2) {
        > div {
          div {
            border: 4px solid rgba(88, 163, 247, 0.3);

            span {
              background: #58a3f7;
              }
            }
          }
        }

      &:nth-child(3) {
        > div {
          div {
            border: 4px solid rgba(255, 197, 66, 0.3);

            span {
              background: #ffc542;
              }
            }
          }
        }

      &:nth-child(4) {
        > div {
          div {
            border: 4px solid rgba(253, 89, 90, 0.3);

            span {
              background: #fd595a;
              }
            }
          }
        }

      /*选中状态*/

      &:nth-child(1).hover {
        > div {
          border: 1px solid #43a710;
          }

        > p {
          background: #43a710;
          color: #ffffff;
          border: 1px solid #43a710;
          }
        }

      &:nth-child(2).hover {
        > div {
          border: 1px solid #58a3f7;
          }

        > p {
          background: #58a3f7;
          color: #ffffff;
          border: 1px solid #58a3f7;
          }
        }

      &:nth-child(3).hover {
        > div {
          border: 1px solid #ffc542;
          }

        > p {
          background: #ffc542;
          color: #ffffff;
          border: 1px solid #ffc542;
          }
        }

      &:nth-child(4).hover {
        > div {
          border: 1px solid #fd595a;
          }

        > p {
          background: #fd595a;
          color: #ffffff;
          border: 1px solid #fd595a;
          }
        }
      }
    }
  }

::v-deep {
  .ant-card-body {
    padding: 5px;
    }

  .ant-tabs-nav-scroll {
    background: #ffffff;
    padding-bottom: 5px;
    }

  .ant-tabs-bar {
    margin-bottom: 1px !important;
    }

  .ant-card-bordered {
    border-top: 0;
    }

  .ant-anchor-ink {
    height: 0;
    }
  }

::v-deep {

  .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #b9d0d0 !important;
    }

  .ant-descriptions-bordered .ant-descriptions-view {
    border-top: 1px solid #b9d0d0 !important;
    border-left: 1px solid #b9d0d0 !important;
    }

  .ant-descriptions-bordered .ant-descriptions-item-label {
    background: #E6ECEC !important;
    }

  .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: 1px solid #b9d0d0 !important;
    }
  }
</style>
