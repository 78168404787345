<template>
    <section>
        <a-card>
            <div class="title-part">
                <div class="left">
                    <div class="item">
                        月份：
                        <a-month-picker v-model="queryForm.PlanDate"/>
                    </div>
                    <!--                    <div class="item">-->
                    <!--                        弱项KPI名称：-->
                    <!--                        <a-input style="width: 200px" placeholder="请输入KPI名称"></a-input>-->
                    <!--                    </div>-->
                  <div class="item">
                    区域：
                    <a-cascader style="width: 240px" :options="dealers" :allowClear="false"
                        placeholder="请选择/输入销售店" v-model="choseDealer" @change="dealerChange"
                        :show-search="true" expandTrigger="hover" :change-on-select="true"
                    />
                  </div>
                    <div class="item">
                        状态：
                        <a-select default-value="" style="width: 200px" v-model="queryForm.PlanStatus"
                                  :allowClear="true">
                            <a-select-option v-for="item in PlanStatus" :key="item.status" :value="item.status">
                                {{ item.key }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="item">
                        <a-button type="primary" @click="getList(1)">搜索</a-button>
                    </div>
                </div>
                <div>
                    <!--                    <a-button @click="$router.push('/improvePlan/createImprovePlan')">-->
                    <!--                        <a-icon type="plus"/>-->
                    <!--                        新增计划-->
                    <!--                    </a-button>-->

                    <!--                    <a-badge :count="caseCount">-->

                    <a-dropdown >
                        <a-button>弱项改善</a-button>
                        <a-menu slot="overlay">
                            <a-menu-item key="1" ghost @click="UploadDiaLogShow=true">批量上传</a-menu-item>
                            <a-menu-item key="2" @click="downLoadFile">下载模板</a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <a-button type="primary" ghost @click="$router.push('/improvePlan/casebook')">
                        案例集
                    </a-button>
                    <!--                    </a-badge>-->
                </div>
            </div>
        </a-card>
        <a-card>
            <a-table :columns="columns" :data-source="tabledata" :row-key="record=>record.PlanId"
                     @change="tableChange"
                     :pagination="paginationParam">
               <span slot="PlanStartTime" slot-scope="PlanStartTime">
                        {{ PlanStartTime|FormatDate }}
                </span>
                <span slot="PlanEndTime" slot-scope="PlanEndTime">
                        {{ PlanEndTime|FormatDate }}
                </span>
                <span slot="StatusName" slot-scope="StatusName,record">
                    <a-badge :color="getTagColor(record)"/>
                    {{ StatusName }}
                </span>
                <span slot="action" slot-scope="text, record">
                  <a @click="$router.push('/improvePlan/viewImproveView?StartTime='+record.PlanStartTime+'&PlanId='+record.PlanId)">查看详情</a>
                  <a-divider type="vertical" v-if="record.PlanStatus === 1"/>
                    <!--                  <a @click="$router.push('/ImprovePlan/improveView')">查看</a>-->
                    <!--                  <a-divider type="vertical" v-if="record.PlanStatus !== -1"/>-->
                    <!--                  <a @click="abandon(record)" v-if="record.PlanStatus !== -1">放弃</a>-->
                    <!--                  <a-divider type="vertical" v-if="record.PlanStatus === 1"/>-->
                    <!--                  <a @click="setCase(record)"-->
                    <!--                     v-if="record.PlanStatus === 1">{{ record.IsCasebook === 1 ? "取消设定案例集" : "设定案例集" }}</a>-->
                </span>
            </a-table>
        </a-card>
        <a-modal v-model="UploadDiaLogShow" title="上传弱项改善">
            <a-form-model-item label="上传文件">
                <a-upload
                    name="file"
                    action="/apis/DealerKpi/SecondBDM/BatchExcelPlan"
                    @change="uploadFileChange"
                    :fileList="fileList"
                >
                    <a-button v-if="fileList.length < 1">
                        <a-icon type="upload"/>
                        点击上传
                    </a-button>
                </a-upload>
            </a-form-model-item>
            <!--            这是个footer插槽-->
            <template slot="footer">
                <a-button type="primary" @click="handleCancel">取消</a-button>
            </template>
        </a-modal>
    </section>
</template>

<script>
import moment from "moment";

const columns = [
    {
        title: "区域",
        key: "PartName",
        dataIndex: "PartName",

    },
    {
        title: "经销商名称",
        key: "CompanyNameCN",
        dataIndex: "CompanyNameCN",
    },
    {
        title: "计划名称",
        dataIndex: "PlanName",
        key: "PlanName",
        ellipsis: true
    },
    {
        title: "计划开始时间",
        dataIndex: "PlanStartTime",
        key: "PlanStartTime",
        scopedSlots: {customRender: 'PlanStartTime'},
    },
    {
        title: "计划结束时间",
        dataIndex: "PlanEndTime",
        key: "PlanEndTime",
        scopedSlots: {customRender: 'PlanEndTime'},
    },
    // {
    //     title: "弱项KPI上层名称",
    //     key: "FirstCategory",
    //     dataIndex: "FirstCategory",
    //     ellipsis:true
    // },
    {
        title: "弱项KPI名称",
        key: "KpiName",
        dataIndex: "KpiName",
        ellipsis: true
    },
    {
        title: "弱项KPI目标值",
        key: "TargetValue",
        dataIndex: "TargetValue",
    },
    {
        title: "弱项KPI当前值",
        key: "NumValue",
        dataIndex: "NumValue",
    },
    {
        title: "最新更新时间",
        key: "LastUpdateTime",
        dataIndex: "LastUpdateTime",
    },
    {
        title: "完成状态",
        key: "StatusName",
        dataIndex: "StatusName",
        scopedSlots: {customRender: 'StatusName'},
    },
    {
        title: "操作",
        key: "action",
        scopedSlots: {customRender: "action"},
        width: 220
    },

];

export default {
    name: "list",
    data() {
        return {
            //已上传文件列表
            fileList: [],
            UploadDiaLogShow: false,
            tabledata: [],
            columns,
            partList: [],
            dealerList: [],
            dealers: [], // 区域
            choseDealer:[],
            dealerMap: {},
            // caseCount: 0,
            //查询对象
            queryForm: {
                pageSize: 10,
                pageIndex: 1,
                PlanDate: null,
                PartName: null,
                DealerCode: "",
                Dealer: null,
                PlanStatus: ''
            },
            //分页参数
            paginationParam: {
                current: 1,
                total: 0,
                pageSize: 10,
            },
            //计划状态列表
            PlanStatus: [
                {
                  key: "全部状态",
                  status: ""
                },{
                    key: "进行中",
                    status: "0"
                }, {
                    key: "已完成",
                    status: "1"
                }, {
                    key: "未完成",
                    status: "2"
                }, {
                    key: "已放弃",
                    status: "-1"
                }
            ],
            rules: {
                    Comment: [
                        {
                            required: true,
                            message: '请输入放弃原因',
                            trigger: ["blur", "change"]
                        },
                    ]
                },
        }
            ;
    },
    mounted() {
        this.getPartList();
        this.getDealersByRole();
        // this.getCasebookCount();
    },
    methods: {
        async downLoadFile() {
            let res = await this.$Http.FileShareLink({
                objectkey: "弱项改善导入模板.xlsx"
            })
            if (res.Success) {
                window.open(res.Data);
            } else {
                this.$message.error("出现未知错误");
            }

        },
        uploadFileChange(info) {
            this.fileList = info.fileList;
            //上传成功的回调
            if (info.file.status === "done") {
                this.upLoadStatus = true;
                //获取objectKey
                let res = info.file.response
                if (res.Success) {
                    this.$message.success(res.Data);
                    this.fileList = [];
                    this.UploadDiaLogShow = false;
                    this.getList(1)
                } else {
                    this.$message.error(res.Message)
                    this.fileList = [];
                }
            }
        },
        handleCancel() {
            this.UploadDiaLogShow = false;
        },
        tableChange(pagination, filters, sorter, {currentDataSource}) {
            this.paginationParam.current = pagination.current;
            this.getList(this.paginationParam.current);
        },
        // async getCasebookCount() {
        //     let res = await this.$Http.GetCasebookCount();
        //     if (res.Success) {
        //         if (res.Data.length > 0) {
        //             this.caseCount = res.Data[0].CountNum;
        //         }
        //     } else {
        //         this.$message.error(res.Message);
        //     }
        // },
        selectorFilter(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        // async setCase(record) {
        //     let setSubmitForm = {
        //         PlanId: record.PlanId,
        //         IsCasebook: record.IsCasebook === 0 ? 1 : 0
        //     }
        //     let res = await this.$Http.SetCasebook(setSubmitForm);
        //     if (res.Success) {
        //         this.$message.success(res.Data.Tips);
        //         // this.getCasebookCount();
        //         this.caseCount = Number(res.Data.CasebookCount);
        //         this.getList(this.queryForm.PageIndex);
        //     } else {
        //         this.$message.error(res.Message);
        //     }
        // },

        async dealerChange(value, val) {
          this.queryForm.PartName = val[0].label;
          this.queryForm.Dealer = val.length > 1 ? val[1].value : "";
        },
        async getList(pageIndex) {
                let self = this;
                self.queryForm.pageSize = self.paginationParam.pageSize;
                self.queryForm.pageIndex = self.paginationParam.current = pageIndex;
                if (self.queryForm.PlanDate) {
                    self.queryForm.PlanDate = moment(self.queryForm.PlanDate).format("YYYY-MM-01");
                }
                let res = await self.$Http.GetFactoryImprovePlanListPage(self.queryForm)
                if (res["Success"]) {
                    self.tabledata = res.Data;
                    self.paginationParam.total = res.TotalCount;
                } else {
                    self.$message.error(res.Message);
                }
        },
        async getPartList() {
            let self = this;
            let res = await self.$Http.GetPartListByPage({
                pageSize: 1000,
                pageIndex: 1,
            });
            if (res.Success && res.Data.length > 0) {
                self.partList = res.Data;
            } else {
                self.$message.error(res.Message)
            }
        },
        async partChange(partName) {
            let self = this;
            self.queryForm.Dealer = "";
            let res = await self.$Http.GetPartList({
                PartName: partName,
            });
            if (res.Success) {
                self.dealerList = res.Data;
            } else {
                self.$message.error(res.Message)
            }
        },
        getTagColor(record) {
            switch (record.PlanStatus) {
                case 0:
                    return "#856AFF";
                case 1:
                    return "#19A47A";
                case 2:
                    return "#FD595A";
                case -1:
                    return "#FD595A";
            }
        },
      // 获取当前选择的经销商信息
      async getDealerInfo() {
       /* let res = await this.$Http.getCurrDealerInfo({
          DealerCode: this.queryForm.DealerCode,
        });
        if(!res["Success"]) {
          this.$message.error(res.Message);
          return;
        }
        this.choseDealer = [res.Data.PartId];
        this.queryForm.PartName = res.Data.PartName;*/
        //  this.dealers=[]
        if(this.dealers.length === 0)  return
        this.choseDealer = [this.dealers[0].value]
        this.queryForm.PartName = this.dealers[0].label;
        await this.getList(1);
      },
        async getDealersByRole() {
          let res = await this.$Http.GetDealersByRole();
          if(!res["Success"]) {
            this.$message.error(res.Message);
            return;
          }
          let dealerCount = 0;
          this.dealers = res.Data.chain().map(p => {
            dealerCount += p.Dealers.length;
            let part = {};
            part.value = p.PartId;
            part.label = p.PartName;
            part.children = p.Dealers.chain().map(d => {
              let dealer = {};
              dealer.value = d.DealerCode;
              dealer.label = d.DealerName;
              this.dealerMap[dealer.value] = [part.value, dealer.value];
              return dealer;
            }).value();
            return part;
          }).value();
         await this.getDealerInfo();
        },
    },
    filters: {
        FormatDate: function (value) {
            return moment(value).format("YYYY-MM-DD")
        },
    }
};
</script>

<style lang="less" scoped>
.title-part {
    display: flex;
    justify-content: space-between;

}

.title-part > .left {
    display: flex;
}

.title-part > .left > .item {
    padding-left: 10px;
}
::v-deep  .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 16px 0 16px 4px !important;
    width: 9%;

    &:first-child{
      padding-left: 2% !important;
      width: 10%;
      }
    }
</style>
