<template>
    <section>
        <a-row :gutter="[8,8]">
            <a-col :span="24">
                <a-card style="text-align: center;">
                    <table class="custom-table">
                        <thead>
                        <tr>
                            <td>指标</td>
                            <td>月度提车</td>
                            <td>月度终端</td>
                            <td>年度提车</td>
                            <td>年度终端</td>
                        </tr>
                        </thead>
                        <tr>
                            <td>实绩</td>
                            <td v-for="(item,i) in descriptionsItem.data1" :key="i">
                                {{ item.value }}
                            </td>
                        </tr>
                        <tr>
                            <td>区域排名</td>
                            <td v-for="(item,i) in descriptionsItem.data2" :key="i">
                                {{ item.value }}
                            </td>
                        </tr>
                        <tr>
                            <td>全国排名</td>
                            <td v-for="(item,i) in descriptionsItem.data3" :key="i">
                                {{ item.value }}
                            </td>
                        </tr>
                    </table>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <h2>一、经营业绩</h2>
                    <a-my-table :columns-data="tableData1.columns" :table-data="tableData1"></a-my-table>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <a-my-table :columns-data="tableData2.columns" :table-data="tableData2"></a-my-table>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <h3>税前利润及利润率</h3>
                    <chartComponent :chart-data="chartData.chartData1"></chartComponent>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <h2>二、三大能力</h2>
                    <aCustomTable class="san_style" :columns-data="tableData3.columns" :table-data="tableData3"></aCustomTable>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <h3>零服吸收率及前后端产值比</h3>
                    <chartComponent :chart-data="chartData.chartData2"></chartComponent>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <h2>三、费用管控</h2>
                    <aCustomTable :columns-data="tableData4.columns" :table-data="tableData4"></aCustomTable>
                </a-card>
            </a-col>
            <a-col :span="12">
                <a-card>
                    <h3>总费用及费用率</h3>
                    <chartComponent :chart-data="chartData.chartData3"></chartComponent>
                </a-card>
            </a-col>
            <a-col :span="12">
                <a-card>
                    <h3>人力费用及人力费用率</h3>
                    <chartComponent :chart-data="chartData.chartData4"></chartComponent>
                </a-card>
            </a-col>
            <a-col :span="12">
                <a-card>
                    <h3>销售部人力费用、人力费用率</h3>
                    <chartComponent :chart-data="chartData.chartData5"></chartComponent>
                </a-card>
            </a-col>
            <a-col :span="12">
                <a-card>
                    <h3>销售顾问人均销量、人均生产力</h3>
                    <chartComponent :chart-data="chartData.chartData6"></chartComponent>
                </a-card>
            </a-col>
            <a-col :span="24">
                <a-card>
                    <h3>总员工人数</h3>
                    <chartComponent :chart-data="chartData.chartData7"></chartComponent>
                </a-card>
            </a-col>
        </a-row>
    </section>
</template>

<script>
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import {
  columns12,
  columnsNorm,
  tooltip,
  yAxis,
  barMaxWidth,
  settingsLabel,
  tableIndex,
  mouth12Chart,
  monthGetData, yAxisUnit,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import chartComponent from "@/components/chartComponent";
import aCustomTable from "@/views/monthReport/shopAnalysis/component/aCustomTable";
import { oneData } from "@/views/monthReport/shopAnalysis/component/myData";
import Tooltip from "ant-design-vue/lib/tooltip/Tooltip";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";
import {
  yAxisDefaultUnit, yAxisRightUnit, grid,
  xAxis, seriesBar, seriesLine, yAxisTenThousandYuan,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";

function toolFun(params, unit) {
  let arr = {};
  params.forEach((d, i) => {
    if(d.componentSubType === "bar") {
      if(unit === "万元") {
        arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${unit}`;
      } else if(unit === "%") {
        arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
      } else if(unit === "倍") {
        arr[i] = `${d.marker} ${d.seriesName}: ${(d.value ?? 0).toFixed(1)}${unit}`;
      } else {
        arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${unit}`;
      }
    }
    if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
    }
  });
  let re = params[0].name + "<br />";
  Object.values(arr).forEach(a => {
    re += a + "<br />";
  });
  return re;
}

function labelFormat(params, unit) {
  if(params["componentSubType"] === "line") {
    return percentage(params.data);
  } else {
    if(unit === "万元") return tenThousandYuan(params.data);
    if(unit === "倍") return params.data.toFixed(1);
    if(unit === "%") return percentage(params.data);
    return ThousandYuan(params.data);
  }
}

export default {
  name: "oneOperating",
  components: { aMyTable, aCustomTable, chartComponent, Tooltip },
  props: {
    allData: {
      type: Object,
    },
    dataParams: {},
  },
  data() {
    return {
      columns12, columnsNorm,
      descriptionsItem: {
        data1: [
          { title: "月度提车", value: "0台", kpiCode: "A1402155" },
          { title: "月度终端", value: "0台", kpiCode: "A0302001" },
          { title: "年度提车", value: "0台", kpiCode: "A1402155" },
          { title: "年度终端", value: "0台", kpiCode: "A0302001" },
        ],
        data2: [
          { title: "区域排名", value: "第0名", kpiCode: "A1402155" },
          { title: "区域排名", value: "第0名", kpiCode: "A0302001" },
          { title: "区域排名", value: "第0名", kpiCode: "A1402155" },
          { title: "区域排名", value: "第0名", kpiCode: "A0302001" },
        ],
        data3: [
          { title: "全国排名", value: "第0名", kpiCode: "A1402155" },
          { title: "全国排名", value: "第0名", kpiCode: "A0302001" },
          { title: "全国排名", value: "第0名", kpiCode: "A1402155" },
          { title: "全国排名", value: "第0名", kpiCode: "A0302001" },
        ],
      },
      columnsC: [
        {
          key: "name", title: "指标名称", dataIndex: "name", width: "10%",
          customRender: (text, record) => {
            if(record.indentation > 0) {
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            }
            return text;
          },
        },
        { key: "Unit", title: "单位", dataIndex: "Unit" },
        {
          key: "Current", title: <div>当月值&nbsp;
            <Tooltip placement="bottom" title="备注:当月值与全国标杆值对比。">
              <a-icon type="info-circle"/>
            </Tooltip>
          </div>, dataIndex: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
            if(record.PercentileSort === "DESC") flag = !flag;
            if(flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑ </div>;
            }
          },
        },
        { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
        { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
        { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
        { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
        { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
        { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
      ],
      tableData1: {
        table: [
          { name: "终端实绩", kpiCode: "A0302001" },
          { name: "来厂台次", kpiCode: "B0302001" },
          { name: "总收入", kpiCode: "C0406004", ut: "1" },
          { name: "总成本（含返利）", kpiCode: "C0406012", ut: "1" },
          { name: "销售其他返利", kpiCode: "A0102084", ut: "1" },
          { name: "总毛利", kpiCode: "C0407001", ut: "1" },
          { name: "总费用", kpiCode: "C0206001", ut: "1" },
          { name: "主营业务税前利润", kpiCode: "C0408004", ut: "1" },
          { name: "营业外收支", kpiCode: "C0402004", ut: "1" },
          { name: "所得税费用", kpiCode: "C0402007", ut: "1" },
          { name: "净利润", kpiCode: "C0408002", ut: "1" },
        ],
        columns: [],
        data: [],
      },
      tableData2: oneData.tableData2,
      tableData3: {
        table: oneData.tableData3.table,
        columns: [
          {
            key: "iFiction",
            title: "指标分类", width: 100,
            customRender: (value, row, index) => {
              let obj = {
                children: "",
                attrs: {},
              };
              switch (index) {
              case 0:
                obj = {
                  children: "盈利能力",
                  attrs: { rowSpan: 2 },
                };
                break;
              case 2:
                obj = {
                  children: "运营能力",
                  attrs: { rowSpan: 3 },
                };
                break;
              case 5:
                obj = {
                  children: "偿债能力",
                  attrs: { rowSpan: 3 },
                };
                break;
              default:
                obj.attrs.colSpan = 0;

              }
              return obj;
            },
          },
          {
            key: "name", title: "指标名称", dataIndex: "name", width: 160,
            customRender: (text, record) => {
              if(record.indentation > 0) {
                return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
              }
              return text;
            },
          },
          { key: "Unit", title: "单位", dataIndex: "Unit" },
          {
            key: "Current", title: <div>当月值&nbsp;
              <Tooltip placement="bottom" title="备注:当月值与全国标杆值对比。">
                <a-icon type="info-circle"/>
              </Tooltip>
            </div>, dataIndex: "Current",
            customRender: (text, record) => {
              let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
              if(record.PercentileSort === "DESC") flag = !flag;
              if(flag) {
                return <div style="color:red;">{text} ↓ </div>;
              } else {
                return <div style="color:green;">{text} ↑</div>;
              }
            },
          },
          { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
          { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
          { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
          { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
          { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
          { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
        ],
        data: [],
      },
      tableData4: {
        table: oneData.tableData4.table,
        columns: [
          {
            key: "iFiction",
            title: "指标分类", width: 100,
            customRender: (value, row, index) => {
              let obj = {
                children: "",
                attrs: {},
              };
              switch (index) {
              case 0:
                obj = {
                  children: "费用结构",
                  attrs: { rowSpan: 7 },
                };
                break;
              case 7:
                obj = {
                  children: "单车费用",
                  attrs: { rowSpan: 10 },
                };
                break;
              default:
                obj.attrs.colSpan = 0;

              }
              return obj;
            },
          },
          {
            key: "name", title: "指标名称", dataIndex: "name", width: 160,
            customRender: (text, record) => {
              if(record.indentation > 0) {
                return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
              }
              return text;
            },
          },
          { key: "Unit", title: "单位", dataIndex: "Unit" },
          {
            key: "Current", title: <div>当月值&nbsp;
              <Tooltip placement="bottom" title="备注:当月值与全国标杆值对比。">
                <a-icon type="info-circle"/>
              </Tooltip>
            </div>, dataIndex: "Current",
            customRender: (text, record) => {
              let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
              if(record.PercentileSort === "DESC") flag = !flag;
              if(flag) {
                return <div style="color:red;">{text} ↓ </div>;
              } else {
                return <div style="color:green;">{text} ↑</div>;
              }
            },
          },
          { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
          { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
          { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
          { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
          { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
          { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
        ],
        data: [],
      },
      chartData: {
        chartData1: oneData.chartData.chartData1,
        chartData2: {
          columns: oneData.chartData.chartData3.columns,
          kpiCode: oneData.chartData.chartData3.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
           // stack: { all: ["前后端产值比", "前后端产值比全国标杆"] },
            showLine: ["零服吸收率", "零服吸收率全国标杆"],
            axisSite: { right: ["零服吸收率", "零服吸收率全国标杆"] },
            yAxisType: ["0.[0]", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "倍");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "倍");
              },
            },
            yAxis: [yAxisDefaultUnit("倍"), yAxisRightUnit("%")],
            xAxis,
            series: [seriesBar, seriesBar, seriesLine, seriesLine],
          },
        },
        chartData3: {
          columns: oneData.chartData.chartData4.columns,
          kpiCode: oneData.chartData.chartData4.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["总费用率"],
            axisSite: { right: ["总费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "万元");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "万元");
              },
            },
            yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
            xAxis,
            series: [seriesBar, seriesLine],
          },
        },
        chartData4: {
          columns: oneData.chartData.chartData5.columns,
          kpiCode: oneData.chartData.chartData5.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人力费用率"],
            axisSite: { right: ["人力费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            label: settingsLabel,
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "万元");
              },
            },
            yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
            xAxis,
            series: [seriesBar, seriesLine],
          },
        },
        chartData5: {
          columns: oneData.chartData.chartData6.columns,
          kpiCode: oneData.chartData.chartData6.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人力费用率"],
            axisSite: { right: ["人力费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
            label: settingsLabel,
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "万元");
              },
            },
            yAxis: [yAxisTenThousandYuan, yAxisRightUnit("%")],
            xAxis,
            series: [seriesBar, seriesLine],
          },
        },
        chartData6: {
          columns: oneData.chartData.chartData7.columns,
          kpiCode: oneData.chartData.chartData7.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人均生产力"],
            axisSite: { right: ["人均生产力"] },
            yAxisType: ["0", "0"],
            yAxisName: ["台", "元"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return ThousandYuan(params.data);
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                      arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}台`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}元`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            yAxis: [yAxisDefaultUnit("台"), yAxisRightUnit("元")],
            xAxis,
            series: [seriesBar, seriesLine],
          },
        },
        chartData7: {
          columns: ["日期", "总员工人数"],
          kpiCode: [
            { Code: "C0302191", Name: "总员工人数" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            yAxisType: ["0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "人");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "人");
              },
            },
            yAxis: [yAxisDefaultUnit("人")],
            xAxis,
            series: [seriesBar],
          },
        },
      },
    };
  },
  async created() {
    await this.getDescriptionsItem();
    await this.getTableData();
    await this.getChartData();
  },
  methods: {
    getDescriptionsItem() {
      const desData = this.allData["MonthRank"];
      const deData = this.allData["YearRank"];
      Object.values(this.descriptionsItem).forEach((t, j) => {
        t.forEach((d, i) => {
          let data1 = "";
          let data2 = "";
          if(typeof (desData) == "undefined") {
            data1 = "0";
          } else if(typeof (deData) == "undefined") {
            data2 = "0";
          } else {
            if(JSON.stringify(desData) === "{}") {
              data1 = "0";
            } else {
              switch (j) {
              case 0:
                data1 = Number(desData[d.kpiCode]["NumValue"] ?? 0) + desData[d.kpiCode].Unit;
                break;
              case 1:
                data1 = "第" + Number(desData[d.kpiCode]["RegionalRank"] ?? 0).toFixed(0) + "名";
                break;
              default:
                data1 = "第" + Number(desData[d.kpiCode]["NationwideRank"] ?? 0).toFixed(0) + "名";
              }
            }
            if(JSON.stringify(deData) === "{}") {
              data2 = "0";
            } else {
              switch (j) {
              case 0:
                data2 = Number(deData[d.kpiCode]["NumValue"] ?? 0) + deData[d.kpiCode].Unit;
                break;
              case 1:
                data2 = "第" + Number(deData[d.kpiCode]["RegionalRank"] ?? 0).toFixed(0) + "名";
                break;
              default:
                data2 = "第" + Number(deData[d.kpiCode]["NationwideRank"] ?? 0).toFixed(0) + "名";
              }
            }
          }
          if(i < 2) {
            d.value = data1;
          } else {
            d.value = data2;
          }
        });
      });
    },
    getTableData() {
      const monthPush = this.allData["MonthPush"];
      const rich = this.allData["Rich"];
      monthGetData(this.tableData1, monthPush, this.dataParams.ReportDate, this.tableData1.columnsI ?? 15);
      this.tableData4.columns[1].width = 180;
      this.tableData2.columns = this.columnsC;
      tableIndex(this.tableData3, rich);
      tableIndex(this.tableData2, rich);
      tableIndex(this.tableData4, rich);

    },
    getChartData() {
      const monthPush = this.allData["MonthPush"];
      const monthStandardPush = this.allData["MonthStandardPush"];
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], monthPush, monthStandardPush, this.dataParams.ReportDate);
      });
    },
  },
  watch: {
    allData: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "../component/shopAnalysis.less";

::v-deep .san_style {
  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          .ant-table-content {
            .ant-table-body {
              table {
                tbody {
                  tr:first-child {
                    td:first-child {
                      background: #E6ECEC !important
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
</style>
