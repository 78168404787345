<template>
  <a-row :gutter="[8,8]">
    <a-col :span="24">
      <a-card style="text-align: center;">
        <!--        <a-descriptions bordered :column="5" style="padding-top: 10px">
                  <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem" :key="i">
                    {{ item.value }}
                  </a-descriptions-item>
                </a-descriptions>-->
        <table class="custom-table">
          <thead>
          <tr>
            <td v-for="(item,i) in descriptionsItem" :key="i++">{{ item.title }}</td>
          </tr>
          </thead>
          <tr>
            <td v-for="(item,i) in descriptionsItem" :key="i++">{{ item.value }}</td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3 style="height: 24px;">售后来厂台次</h3>
        <chartComponent :chart-data="chartData.chartData1"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>售后业务综合产值及毛利</h3>
        <chartComponent :chart-data="chartData.chartData2"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h2>一、利润表</h2>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData1"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card style="height: 483px;">
        <h2>售后水平事业</h2>
        <table class="my-customize-table">
          <tr>
            <th style="width: 150px;">指标名称</th>
            <th style="width: 100px;">台次(台）</th>
            <th>收入(万元）</th>
            <th>毛利(万元）</th>
            <th>单车毛利(元）</th>
          </tr>
          <tr v-for="(item,i) in myTable.data" :key="i++">
            <td class="">{{ item.name }}</td>
            <td class="">{{ item.one }}</td>
            <td class="">{{ item.two }}</td>
            <td class="">{{ item.there }}</td>
            <td class="">{{ item.four }}</td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>售后水平事业</h3>
        <chartComponent :chart-data="chartData.chartData3"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>单车售后详情</h3>
        <a-my-table :columns-data="tableMouthData.tableMouth1.columns" :table-data="tableMouthData.tableMouth1"></a-my-table>
      </a-card>
    </a-col>
<!--       <a-col :span="24">
          <a-card>
            <h3>单车售后详情</h3>
            <chartComponent :chart-data="chartData.chartData4"></chartComponent>
          </a-card>
        </a-col> -->
    <a-col :span="24">
      <a-card>
        <h3>售后维修业务关键KPI</h3>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData2"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>售后分业务详情</h3>
        <table class="my-customize-table">
          <tr>
            <th style="width: 150px;">指标名称</th>
            <th style="width: 100px;">作业数(台）</th>
            <th>零部件产值(万元）</th>
            <th>工时产值(万元）</th>
            <th>零部件毛利(万元）</th>
            <th>工时毛利(万元）</th>
            <th>单车维修业务毛利(元）</th>
          </tr>
          <tr v-for="(item,i) in myTable1.data" :key="i++">
            <td>{{ item.name }}</td>
            <td>
               <span v-if="i === 11">
                <a-tooltip placement="top" title="售后来厂台次">{{ item.one }} <a-icon type="question-circle"/></a-tooltip>
              </span>
              <span v-else>{{ item.one }}</span>
            </td>
            <td>{{ item.two }}</td>
            <td>{{ item.there }}</td>
            <td>{{ item.four }}</td>
            <td>{{ item.five }}</td>
            <td>{{ item.six }}</td>
          </tr>
        </table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>客户管理</h3>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData3"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <h3>有效保有客户数</h3>
      <a-card>
        <chartComponent :chart-data="chartData.chartData5"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>客户维系率</h3>
        <chartComponent :chart-data="chartData.chartData6"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>客户流失率</h3>
        <chartComponent :chart-data="chartData.chartData7"></chartComponent>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>运营效率</h3>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData4"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>工位周转率</h3>
        <a-my-table :columns-data="columnsC" :table-data="tableData.tableData5"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>人员效率</h3>
        <a-descriptions bordered :column="4" style="padding-top: 10px">
          <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem1" :key="i">
            {{ item.value }}
          </a-descriptions-item>
        </a-descriptions>
        <a-my-table style="margin-top: 10px" :columns-data="columnsC" :table-data="tableData.tableData6"></a-my-table>
      </a-card>
    </a-col>
    <a-col :span="24">
      <a-card>
        <h3>零部件库存管理</h3>
        <a-descriptions bordered :column="4" style="padding-top: 10px">
          <a-descriptions-item :label="item.title" v-for="(item,i) in descriptionsItem2" :key="i">
            {{ item.value }}
          </a-descriptions-item>
        </a-descriptions>
        <a-my-table style="margin-top: 10px" :columns-data="columnsC" :table-data="tableData.tableData7"></a-my-table>
      </a-card>
    </a-col>

    <a-col :span="24">
      <a-card>
        <h3>库存金额占比</h3>
        <chartComponent :chart-data="chartData.chartData8"></chartComponent>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import chartComponent from "@/components/chartComponent";
import {
  barMaxWidth, monthGetData,
  mouth12Chart,
  settingsLabel,
   yAxis, tooltip,
  yAxisUnit, tableIndex,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import { oneData } from "@/views/monthReport/shopAnalysis/component/myData";
import Tooltip from "ant-design-vue/lib/tooltip/Tooltip";
import {
  seriesBar,
  seriesLine, xAxis,
  yAxisDefaultUnit, yAxisRightUnit, yAxisTenThousandYuan,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { percentage, tenThousandYuan, ThousandYuan } from "@/until/option";

function toolFun(params, unit) {
  let arr = {};
  params.forEach((d, i) => {
    if(d.componentSubType === "bar") {
      if(unit === "万元") {
        arr[i] = `${d.marker} ${d.seriesName}: ${tenThousandYuan(d.value ?? 0)}${unit}`;
      } else if(unit === "%") {
        arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
      } else {
        arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}${unit}`;
      }
    }
    if(d.componentSubType === "line") {
      arr[i] = `${d.marker} ${d.seriesName}: ${percentage(d.value ?? 0)}`;
    }
  });
  let re = params[0].name + "<br />";
  Object.values(arr).forEach(a => {
    re += a + "<br />";
  });
  return re;
}

function labelFormat(params, unit) {
  if(params["componentSubType"] === "line") {
    return percentage(params.data);
  } else {
    if(unit === "万元") return tenThousandYuan(params.data);
    if(unit === "%") return percentage(params.data);
    return ThousandYuan(params.data);
  }
}

export default {
  name: "fourAfter",
  components: { aMyTable, chartComponent },
  props: {
    allData: { type: Object },
    dataParams: {},
  },
  data() {
    return {
      descriptionsItem: [
        { title: "零服吸收率", value: "0", kpiCode: "B0107002" },
        { title: "来厂台次", value: "0台", kpiCode: "B0302001" },
        { title: "事故车台次", value: "0台", kpiCode: "B0302121" },
        { title: "售后业务综合产值", value: "0台", kpiCode: "B0104202" },
        { title: "售后业务综合毛利", value: "0台", kpiCode: "B0105113" },
        { title: "单车售后业务综合产值", value: "0台", kpiCode: "B0105114" },
        { title: "单车售后业务综合毛利", value: "0台", kpiCode: "B0106027" },
      ],
      descriptionsItem1: [
        { title: "本月零部件人数", value: "0台", kpiCode: "C0302317" },
        { title: "本月服务顾问人数", value: "0台", kpiCode: "C0302210" },
        { title: "本月机电技师人数", value: "0台", kpiCode: "C0302213" },
        { title: "本月钣喷技师人数", value: "0台", kpiCode: "C0302318" },
      ],
      descriptionsItem2: [
        { title: "本月0-90天库存金额", value: "0台", kpiCode: "B0402005" },
        { title: "本月91-180天库存金额", value: "0台", kpiCode: "B0402006" },
        { title: "本月181-365天库存金额", value: "0台", kpiCode: "B0402007" },
        { title: "本月>365天库存金额", value: "0台", kpiCode: "B0402008" },
      ],
      columnsC: [
        {
          key: "name", title: "指标名称", dataIndex: "name", width: 150,
          customRender: (text, record) => {
            if (record.indentation > 0) {
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            }
            return text;
          },
        },
        { key: "Unit", title: "单位", dataIndex: "Unit" },
        {
          key: "Current", title: <div>当月值&nbsp;
            <Tooltip placement="bottom" title="备注:当月值与全国标杆值对比。">
              <a-icon type="info-circle"/>
            </Tooltip>
          </div>, dataIndex: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
            if (record.PercentileSort === "DESC") flag = !flag;
            if (flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑ </div>;
            }
          },
        },
        { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
        { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
        { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
        { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
        { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
        { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
      ],
      tableData: {
        tableData1: {
          table: [
            { name: "售后业务综合收入", kpiCode: "B0104202", ut: "1" },
            { name: "售后维修业务收入", kpiCode: "B0103097", ut: "1", indentation: 1 },
            { name: "售后水平事业收入", kpiCode: "B0103099", ut: "1", indentation: 1 },
            { name: "售后业务综合毛利", kpiCode: "B0105113", ut: "1" },
            { name: "售后维修业务毛利", kpiCode: "B0104165", ut: "1", indentation: 1 },
            { name: "售后水平事业毛利", kpiCode: "B0104187", ut: "1", indentation: 1 },
            { name: "售后水平事业毛利率", kpiCode: "B0105108" },
          ],
          columns: [],
          data: [],
        },
        tableData2: {
          table: [
            { name: "零件工时比", kpiCode: "B0104259", IndexRange: "1.2~2.0" },
            { name: "事故车占比", kpiCode: "B0303101", IndexRange: "15.0%~30.0%" },
            { name: "钣喷金额占比", kpiCode: "B0105150", IndexRange: "40.0%~55.0%" },
            { name: "机电工位日均周转次数", kpiCode: "B0304001", IndexRange: "-" },
          ],
          columns: [],
          data: [],
        },
        tableData3: {
          table: [
            { name: "档案客户数", kpiCode: "B0303062" },
            { name: "有效保有客户数", kpiCode: "B0302037" },
            { name: "忠诚客户占年度活跃客户比", kpiCode: "B0303019" },
            { name: "连续两年都来店客户数", kpiCode: "B0302047" },
            { name: "流失客户数", kpiCode: "B0302038" },
          ],
          columns: [],
          data: [],
        },
        tableData4: {
          table: [
            { name: "首次付费保养进场率（销售台次）", kpiCode: "B0303026" },
            { name: "首次付费保养客户数", kpiCode: "B0302042" },
            { name: "次新车延保率", kpiCode: "B0303052" },
            { name: "非新车延保渗透率", kpiCode: "B0303008" },
            { name: "续保渗透率", kpiCode: "B0304003" },
          ],
          columns: [],
          data: [],
        },
        tableData5: {
          table: [
            { name: "机电工位数", kpiCode: "B0302028" },
            { name: "机电工位日均周转次数", kpiCode: "B0304001" },
            { name: "烤房数量", kpiCode: "B0302032" },
            { name: "平均每个烤房喷漆面数", kpiCode: "B0303017" },
          ],
          columns: [],
          data: [],
        },
        tableData6: {
          table: [
            { name: "零部件人员人均生产力", kpiCode: "B0105133" },
            { name: "服务顾问人均日均接车量", kpiCode: "B0303036" },
            { name: "机电技师人均生产力", kpiCode: "B0104256" },
            { name: "钣喷技师人均生产力", kpiCode: "B0104283" },
          ],
          columns: [],
          data: [],
        },
        tableData7: {
          table: [
            { name: "0-90天库存金额占比", kpiCode: "B0403003" },
            { name: "91-180天库存金额占比", kpiCode: "B0403004" },
            { name: "181-365天库存金额占比", kpiCode: "B0403005" },
            { name: ">365天库存金额占比", kpiCode: "B0403006" },
          ],
          columns: [],
          data: [],
        },
      },
      tableMouthData: {
        tableMouth1: {
          table: [
            { name: "单车售后维修业务产值", kpiCode: "B0104226" },
            { name: "单车售后维修业务毛利", kpiCode: "B0105182" },
            { name: "单车零部件毛利", kpiCode: "B0105132" },
            { name: "单车工时毛利", kpiCode: "B0105161" },
            { name: "售后维修业务毛利率", kpiCode: "B0105181" },
          ],
          columns: [],
          data: [],
        },
        tableMouth2: {
          table: [
            { name: "展厅客流", kpiCode: "A1303043" },
            { name: "展厅首次客流", kpiCode: "A1302012" },
            { name: "展厅再次客流", kpiCode: "A1302013" },
            { name: "展厅订单", kpiCode: "A1303080" },
            { name: "首次客户订单", kpiCode: "A1302014" },
            { name: "再次客户订单", kpiCode: "A1302111" },
            { name: "展厅销量", kpiCode: "A0302021" },
            { name: "首次成交率", kpiCode: "A1303081" },
            { name: "再次成交率", kpiCode: "A1303082" },
          ],
          columns: [],
          data: [],
          columnsI: 14,
        },
      },
      chartData: {
        chartData1: {
          columns: ["日期", "来厂台次", "来厂台次全国标杆", "事故车来厂台次", "事故车来厂台次全国标杆"],
          kpiCode: [
            { Code: "B0302001", Name: "来厂台次" },
            { Code: "B0302001", Name: "来厂台次全国标杆" },
            { Code: "B0302121", Name: "事故车来厂台次" },
            { Code: "B0302121", Name: "事故车来厂台次全国标杆" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            //  stack: { all: ["来厂台次", "事故车来厂台次"] },
            showLine: ["来厂台次全国标杆", "事故车来厂台次全国标杆"],
            axisSite: { right: ["来厂台次全国标杆", "事故车来厂台次全国标杆"] },
            yAxisType: ["0", "0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return ThousandYuan(params.data);
              },
            },
          },
          options: {
            tooltip:{
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                let arr = {};
                params.forEach((d, i) => {
                  if(d.componentSubType === "bar") {
                      arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}台`;
                  }
                  if(d.componentSubType === "line") {
                    arr[i] = `${d.marker} ${d.seriesName}: ${ThousandYuan(d.value ?? 0)}台`;
                  }
                });
                let re = params[0].name + "<br />";
                Object.values(arr).forEach(a => {
                  re += a + "<br />";
                });
                return re;
              },
            },
            xAxis,
            yAxis: [yAxisDefaultUnit('台'), {
                name: "台",
                show: false,
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                  },
                },
              },
            ],
            series: [seriesBar, seriesLine,seriesBar, seriesLine, ],
          },
        },
        chartData2: {
          columns: ["日期", "售后业务综合产值", "售后业务综合产值全国标杆", "售后业务综合毛利", "售后业务综合毛利全国标杆"],
          kpiCode: [
            { Code: "B0104202", Name: "售后业务综合产值" },
            { Code: "B0104202", Name: "售后业务综合产值全国标杆" },
            { Code: "B0105113", Name: "售后业务综合毛利" },
            { Code: "B0105113", Name: "售后业务综合毛利全国标杆" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            //   stack: { all: ["售后业务综合产值", "售后业务综合毛利"] },
            showLine: ["售后业务综合产值全国标杆", "售后业务综合毛利全国标杆"],
            axisSite: { right: ["售后业务综合产值全国标杆", "售后业务综合毛利全国标杆"] },
            yAxisType: ["0", "0"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return tenThousandYuan(params.data);
              },
            },
          },
          options: {
            tooltip: {
              trigger: "axis",
              formatter: function(params) {
                let arr = [];
                params.forEach((d) => {
                  let valData = d.value ?? "0";
                  let minvalue = 0;
                  minvalue = Number(valData / 10000).toFixed(2) ?? "-";
                  arr.push({
                    val: `<span style="background-color:${d.color};display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;"></span>${d.seriesName}:${minvalue}万元`,
                  });

                });
                //        arr.reverse(); // 加栽顺序反一下
                let v1 = "";
                arr.forEach(v => v1 = v1 + v.val + "<br />");
                return `${params[0].axisValue} <br /> ${v1}`;
              },
            },
            yAxis: [yAxisTenThousandYuan, {
              name: "台",
              show: false,
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                },
              },
            },],
            xAxis,
            series: [seriesBar, seriesLine,seriesBar, seriesLine, ],
          },
        },
        chartData3: {
          columns: oneData.afterData.chartData.chartData3.columns,
          kpiCode: oneData.afterData.chartData.chartData3.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["售后水平事业收入", "售后水平事业毛利"] },
            showLine: ["售后水平事业毛利率"],
            axisSite: { right: ["售后水平事业毛利率"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "万元");
              },
            },
          },
          options: {
            tooltip,
            yAxis:[yAxisTenThousandYuan,yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesBar,seriesLine],
          },
        },
        chartData4: {
          columns: oneData.afterData.chartData.chartData4.columns,
          kpiCode: oneData.afterData.chartData.chartData4.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["单车售后产值", "单车零部件毛利", "单车工时毛利"] },
            showLine: ["售后维修业务毛利率"],
            axisSite: { right: ["售后维修业务毛利率"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "元");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "元");
              },
            },
            yAxis: [yAxisDefaultUnit('元'),yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesBar,seriesBar,seriesLine],
          },
        },
        chartData5: {
          columns: oneData.afterData.chartData.chartData5.columns,
          kpiCode: oneData.afterData.chartData.chartData5.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["A类客户数", "B类客户数", "C类客户数", "新销售未回厂数"] },
            yAxisType: ["0"],
            label: {
              show: true, // 显示数字
              //   position: "top",
              textStyle: {
                fontSize: 12, color: "black",
              },
              formatter: function(params) {
                return ThousandYuan(params.value)
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "个");
              },
            },
            yAxis: [yAxisDefaultUnit('个')],
            xAxis,
            series: [seriesBar,seriesBar,seriesBar,seriesBar,],
          },
        },
        chartData6: {
          columns: oneData.afterData.chartData.chartData6.columns,
          kpiCode: oneData.afterData.chartData.chartData6.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["客户维系率"],
            axisSite: { right: ["客户维系率"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true,
              position: "inside",
              textStyle: {
                 color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "个");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "个");
              },
            },
            yAxis: [yAxisDefaultUnit('个'),yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesLine],
          },
        },
        chartData7: {
          columns: ["日期", "流失客户数", "客户流失率"],
          kpiCode: [
            { Code: "B0302038", Name: "流失客户数" },
            { Code: "B0304014", Name: "客户流失率" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            showLine: ["客户流失率"],
            axisSite: { right: ["客户流失率"] },
            yAxisType: ["0", "0.[0]%"],
            label: {
              show: true,
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "个");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "个");
              },
            },
            yAxis: [yAxisDefaultUnit('个'),yAxisRightUnit('%')],
            xAxis,
            series: [seriesBar,seriesLine],
          },
        },
        chartData8: {
          columns: ["日期", "0-90天库存金额占比", "91-180天库存金额占比", "181-365天库存金额占比", ">365天库存金额占比"],
          kpiCode: [
            { Code: "B0403003", Name: "0-90天库存金额占比" },
            { Code: "B0403004", Name: "91-180天库存金额占比" },
            { Code: "B0403005", Name: "181-365天库存金额占比" },
            { Code: "B0403006", Name: ">365天库存金额占比" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            stack: { all: ["0-90天库存金额占比", "91-180天库存金额占比", "181-365天库存金额占比", ">365天库存金额占比"] },
            yAxisType: ["0.[0]%"],
            label: {
              show: true, // 显示数字
              position: "inside",
              textStyle: {
                color: "black",
              },
              formatter: function(params) {
                return labelFormat(params, "%");
              },
            },
          },
          options: {
            tooltip: {
              show: true,
              trigger: "axis",
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                return toolFun(params, "%");
              },
            },
            yAxis:[yAxisDefaultUnit('%')],
            xAxis,
            series: [seriesBar,seriesBar,seriesBar,seriesBar],
          },
        },
      },
      myTable: {
        set: [
          { name: "售后养护品", tables: "B0302017", income: "B0102095", grossProfit: "B0103104", parts: "B0104242" },
          { name: "售后续保", tables: "B0302021", income: "B0102099", grossProfit: "B0103108", parts: "B0104244" },
          { name: "非新车续保", tables: "B0302019", income: "B0102097", grossProfit: "B0103106", parts: "B0104246" },
          { name: "自主服务项目", tables: "B0302075", income: "B0102114", grossProfit: "B0103113", parts: "B0104250" },
        ],
        data: [],
      },
      myTable1: {
        set: [
          {
            name: "机电",
            tables: "B0302003",
            income: "B0102001",
            grossProfit: "B0102050",
            parts: "B0103023",
            working: "B0103063",
            maintenance: "B0105191",
          },
          {
            name: "机电-首保",
            tables: "B0302004",
            income: "B0102002",
            grossProfit: "B0102051",
            parts: "B0103024",
            working: "B0103064",
            maintenance: "B0105192",
          },
          {
            name: "机电-定保",
            tables: "B0302005",
            income: "B0102003",
            grossProfit: "B0102052",
            parts: "B0103025",
            working: "B0103065",
            maintenance: "B0105193",
          },
          {
            name: "机电-一般维修",
            tables: "B0302006",
            income: "B0102004",
            grossProfit: "B0102053",
            parts: "B0103026",
            working: "B0103066",
            maintenance: "B0105194",
          },
          {
            name: "机电-改装美容",
            tables: "B0302071",
            income: "B0102008",
            grossProfit: "B0102057",
            parts: "B0103036",
            working: "B0103076",
            maintenance: "B0105197",
          },
          {
            name: "机电-市场活动",
            tables: "B0302008",
            income: "B0102006",
            grossProfit: "B0102055",
            parts: "B0103028",
            working: "B0103068",
            maintenance: "B0105196",
          },
          {
            name: "机电-保修",
            tables: "B0302009",
            income: "B0102007",
            grossProfit: "B0102056",
            parts: "B0103029",
            working: "B0103069",
            maintenance: "B0105198",
          },
          {
            name: "钣喷",
            tables: "B0302010",
            income: "B0102012",
            grossProfit: "B0102061",
            parts: "B0103030",
            working: "B0103070",
            maintenance: "B0105199",
          },
          {
            name: "钣喷-保修理赔",
            tables: "B0302013",
            income: "B0102015",
            grossProfit: "B0102064",
            parts: "B0103033",
            working: "B0103073",
            maintenance: "B0105202",
          },
          {
            name: "钣喷-客户自费",
            tables: "B0302014",
            income: "B0102016",
            grossProfit: "B0102065",
            parts: "B0103034",
            working: "B0103074",
            maintenance: "B0105203",
          },
          {
            name: "合计",
            tables: "B0302001",
            income: "B0103007",
            grossProfit: "B0103047",
            parts: "B0104043",
            working: "B0104063",
            maintenance: "B0105182",
          },
        ],
        data: [],
      },
    };
  },
  created() {
    this.getDescriptionsItem();
    this.getTableData();
    this.getChartData();
  },
  methods: {
    getDescriptionsItem() {
      let desData = this.allData["Rich"];
      if (!desData) {
        return false;
      }
      this.descriptionsItem.forEach((d, i) => {
        if (!desData[d.kpiCode]) {
          d.value = 0;
        } else {
          if (i === 0) {
            d.value = Number(desData[d.kpiCode].Current * 100 ?? 0).toFixed(1) + desData[d.kpiCode].Unit;
          } else if (i === 3 || i === 4) {
            d.value = Number(desData[d.kpiCode].Current / 10000 ?? 0).toFixed(2) + "万元";
          } else {
            d.value = Number(desData[d.kpiCode].Current ?? 0).toFixed(0) + desData[d.kpiCode].Unit;
          }
        }
      });
      this.descriptionsItem1.forEach(d => {
        if (!desData[d.kpiCode]) {
          d.value = 0;
        } else {
          if (d.kpiCode) {
            d.value = (desData[d.kpiCode].Current ?? 0) + desData[d.kpiCode].Unit;
          }
        }
      });
      this.descriptionsItem2.forEach(d => {
        if (!desData[d.kpiCode]) {
          d.value = 0;
        }else {
          if (d.kpiCode) {
            d.value = Number(desData[d.kpiCode].Current / 10000 ?? 0).toFixed(2) + "万元";
          }
        }
      });
    },
    getTableData() {
      const monthPush = this.allData["MonthPush"];
      const rich = this.allData["Rich"];

      /*tableMouthData*/
      for (let [k, v] of Object.entries(this.tableMouthData)) {
        monthGetData(this.tableMouthData[k], monthPush, this.dataParams.ReportDate, v.columnsI ?? 15);
      }
      Object.keys(this.tableData).forEach(d => {
        tableIndex(this.tableData[d], rich);
      });
      let onedata = 0;
      let twodata = 0;
      let theredata = 0;
      let fourdata = 0;
      this.myTable.data = [];

      this.myTable.set.forEach(d => {
        let arr = {};
        arr["name"] = d.name;
        if (rich[d.tables]) {
          arr["one"] = rich[d.tables].Current ?? 0;
          onedata = Number(rich[d.tables].Current ?? 0) + Number(onedata);
        }
        if (rich[d.income]) {
          arr["two"] = Number((rich[d.income].Current ?? 0) / 10000).toFixed(2);
          twodata = Number(rich[d.income].Current / 10000 ?? 0) + Number(twodata);
        }
        if (rich[d.grossProfit]) {
          arr["there"] = Number(Number(rich[d.grossProfit].Current) / 10000 ?? 0).toFixed(2);
          theredata = Number(rich[d.grossProfit].Current / 10000 ?? 0) + Number(theredata);
        }
        if (rich[d.parts]) {
          arr["four"] = Number(Number(rich[d.parts].Current) ?? 0).toFixed(0);
          fourdata = Number(Number(rich[d.parts].Current ?? 0).toFixed(0)) + Number(fourdata);
        }
        this.myTable.data.push(arr);
      });
      this.myTable.data.push({
        name: "合计",
        one: onedata,
        two: Number(twodata ?? 0).toFixed(2),
        there: Number(theredata ?? 0).toFixed(2),
        four: Number(fourdata ?? 0).toFixed(0),
      });
      /*{ name: "机电-改装美容", tables: "B0302071", income: "B0102008", grossProfit: "B0102057",parts:"B0103036",working:"B0103076",maintenance:"B0105197" },
      * */
      let onedata1 = 0;
      let twodata1 = 0;
      let theredata1 = 0;
      let fourdata1 = 0;
      let fivedata1 = 0;
      let sixdata1 = 0;
      this.myTable1.data = [];
      this.myTable1.set.forEach((d, i) => {
        let arr = {};
        arr["name"] = d.name;
        if (rich[d.tables]) {
          arr["one"] = rich[d.tables].Current ?? 0;
          if (i === 0 || i === 7) {
            onedata1 = Number(rich[d.tables].Current ?? 0) + Number(onedata1);
          }
        }
        if (rich[d.income]) {
          arr["two"] = Number((rich[d.income].Current ?? 0) / 10000).toFixed(2);
          if (i === 0 || i === 7) {
            twodata1 = Number(rich[d.income].Current ?? 0) + Number(twodata1);
          }
        }
        if (rich[d.grossProfit]) {
          arr["there"] = rich[d.grossProfit].Current ? Number(rich[d.grossProfit].Current / 10000).toFixed(2) : "0.00";
          if (i === 0 || i === 7) {
            theredata1 += rich[d.grossProfit].Current;
          }
        }
        if (rich[d.parts]) {
          arr["four"] = Number(rich[d.parts].Current / 10000 ?? 0).toFixed(2);
          if (i === 0 || i === 7) {
            fourdata1 += rich[d.parts].Current ?? 0;
          }
        }
        if (rich[d.working]) {
          arr["five"] = Number(rich[d.working].Current / 10000 ?? 0).toFixed(2);
          if (i === 0 || i === 7) {
            fivedata1 += rich[d.working].Current ?? 0;
          }
        }
        if (rich[d.maintenance]) {
          arr["six"] = Number(rich[d.maintenance].Current ?? 0).toFixed(0);
          if (i === 0 || i === 7) {
            sixdata1 += rich[d.maintenance].Current ?? 0;
          }
        }
        this.myTable1.data.push(arr);
      });
 /*     this.myTable1.data.push({
        name: "合计",
        one: onedata1,
        two: twodata1 ? Number(twodata1 / 10000).toFixed(2) : "0.00",
        there: theredata1 ? Number(theredata1 / 10000).toFixed(2) : "0.00",
        four: Number(fourdata1 / 10000 ?? 0).toFixed(2),
        five: Number(fivedata1 / 10000 ?? 0).toFixed(2),
        six: Number(sixdata1 ?? 0).toFixed(0),
      });*/
    },
    getChartData() {
      const monthPush = this.allData["MonthPush"];
      const monthStandardPush = this.allData["MonthStandardPush"];
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], monthPush, monthStandardPush, this.dataParams.ReportDate);
      });
    },
  },
  watch: {
    allData: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "../component/shopAnalysis.less";

.my-customize-table {
  width: 100%;border-top: 1px solid #86a3a1;border-bottom: 1px solid #86a3a1;font-size: 12px;

  tr {
    height: 30px;line-height: 30px;background: #fff;

    th {
      text-align: right;padding: 2px 5px;

      &:first-child {
        text-align: left;
        }

      }

    td {
      text-align: right;

      &:first-child {
        text-align: left;
        padding-left: 2px;
        }

      &:last-child {
        padding-right: 7px;
        }

      }

    &:hover {
      color: #405E5C !important;
      background: #adcbca !important;
      box-shadow: 1px 2px 5px 1px #768e86
      }

    &:nth-child(odd) {
      background: #E6ECEC;
      }

    &:first-child, &:first-child:hover {
      background: #33554a !important;color: #fff !important;
      }
    }


  }

</style>
