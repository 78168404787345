<template>
  <div class="card-padding">
    <div>{{ title_b }}
      <span>
        <a-tooltip :title="title_s" slot="action">
          <a-icon type="info-circle-o"/>
        </a-tooltip>
      </span>
    </div>
    <div style="margin: 10px 10px">
      {{ total }}
    </div>
    <!--    <div>-->
    <!--      <p>周同比 <span class="D6000F"><a-icon :type="UpDown(sequential_1)"/> {{ sequential_1 }}%</span></p>-->
    <!--      <p>日环比 <span class="C7BD250"><a-icon :type="UpDown(sequential_2)"/> {{ sequential_2 }}%</span></p>-->
    <!--    </div>-->
    <!--    <slot name="parent-foot"></slot>-->
  </div>
</template>
<script>
/*
* by zyzhan
* title_b 标题
* title_s 弹出的标题
* total 数量
* sequential_1 环比 1
* sequential_2 环比 2
* */
export default {
  name: 'MainParent',
  props: ['title_b', 'title_s', 'total', 'sequential_1', 'sequential_2'],
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    UpDown(data) {
      if (data > 10) {
        return 'arrow-up'
      } else {
        return 'arrow-down'
      }
    }
  },
  filters: {},
};
</script>
<style lang="less" scoped>

.card-padding {
  font-family: HelveticaNeue, sans-serif;
  padding: 20px 14px 5px 14px;
  border: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    border-color: rgba(0, 0, 0, .3);
  }

  div:first-child {
    color: rgba(0, 0, 0, .45);
    font-family: PingFangSC-Regular, PingFang SC, serif;

    span {
      float: right;
    }
  }

  div:nth-child(2) {
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    font-weight: 400;
    text-shadow: 0 2px 4px rgba(32, 0, 0, 0.1);
    font-family: HelveticaNeue, sans-serif;
  }

  div:nth-child(3) {
    overflow: hidden;
    height: 70px;
    padding-top: 20px;

    p {
      float: left;
      width: 50%;

      span {
        font-size: 14px;
      }

      span.D6000F {
        color: #24619e;
      }

      span.C7BD250 {
        color: #7BD250;
      }

    }
  }

  div:nth-child(4) {
    // border-top: 1px solid #DDDDDD;
    color: rgba(0, 0, 0, 65);
    line-height: 32px;
    font-size: 14px;

    span {
      color: rgba(0, 0, 0, .85);
    }
  }
}


</style>
