<template>
    <a-card class="card">
        <a-form-model
            ref="form"
            :rules="rules"
            :model="form"
            :label-col="{ span: 2 }"
            :wrapper-col="{ span: 6 }"
        >
            <a-form-model-item label="简称（中文）" prop="CompanyNameCN">
                <a-input v-model="form.CompanyNameCN"/>
            </a-form-model-item>
            <a-form-model-item label="简称（英文）" prop="CompanyNameEN">
                <a-input v-model="form.CompanyNameEN"/>
            </a-form-model-item>
            <a-form-model-item label="经销商编号" prop="CompanyCode">
                <a-input v-model="form.CompanyCode"/>
            </a-form-model-item>
            <a-form-model-item label="城市" prop="CityId">
                <a-select show-search option-filter-prop="children" v-model="form.CityId">
                    <a-select-option
                        v-for="(item, i) in cityList"
                        :key="i"
                        :value="item.CityId"
                    >
                        {{ item.CityName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="类型" prop="CompanyType">
                <a-radio-group name="radioGroup" v-model="form.CompanyType">
                    <a-radio value="集团店"> 集团店</a-radio>
                    <a-radio value="销售店"> 销售店</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
                label="所属经销商"
                prop="ParentId"
                v-if="form.CompanyType == '销售店'"
            >
                <a-select
                    show-search
                    allowClear
                    :default-active-first-option="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleSearch"
                    v-model="form.ParentId"
                    @change="companyChange"
                >
                    <a-select-option v-for="(d, i) in companyList" :key="i" :value="d.id">
                        {{ d.CompanyNameCN }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="Address">
                <a-input v-model="form.Address"/>
            </a-form-model-item>
            <a-form-model-item label="开业时间" prop="OpenTime">
                <a-date-picker v-model="form.OpenTime" @change="openTimeChange"/>
            </a-form-model-item>
            <a-form-model-item label="联系人">
                <a-input v-model="form.Contacts"/>
            </a-form-model-item>
            <a-form-model-item label="手机号">
                <a-input v-model="form.Mobile"/>
            </a-form-model-item>
            <a-form-model-item label="联系电话">
                <a-input v-model="form.Telephone"/>
            </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select v-model="form.Status" style="width: 120px">
              <a-select-option value="新店">
                新店
              </a-select-option>
              <a-select-option value="正常">
                正常
              </a-select-option>
              <a-select-option value="异常">
                异常
              </a-select-option>
              <a-select-option value="退网">
                退网
              </a-select-option>
            </a-select>
          </a-form-model-item>
            <a-row>
                <a-col :span="2" :offset="20">
                    <a-button type="primary" @click="save"> 提交</a-button>
                </a-col>
                <a-col :span="2">
                    <a-button @click="$router.push('/companyMannage/list')">
                        取消
                    </a-button>
                </a-col>
            </a-row>
            <!-- <a-form-model-item :wrapper-col="{ span: 2, offset: 2 }">
              <a-button type="primary" @click="save"> 提交 </a-button>
            </a-form-model-item> -->
        </a-form-model>
    </a-card>
</template>

<script>
import Http from "@/api/http";

let timeout;

//经销商
function fetch(value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    async function fake() {
        let res = await Http.GetGroupCompany({keyWords: value});
        callback(res);
    }

    timeout = setTimeout(fake, 300);
}

export default {
    data() {
        return {
            form: {
                CompanyId: null,
                CompanyNameCN: "",
                CompanyNameEN: "",
                CompanyCode: "",
                CityId: "",
                CompanyType: "集团店",
                ParentId: "00000000-0000-0000-0000-000000000000",
                Address: "",
                OpenTime: null,
                Contacts: "",
                Mobile: "",
                Telephone: "",
                Status: "新店",
            },
            companyList: [
                {
                    id: "00000000-0000-0000-0000-000000000000",
                    CompanyNameCN: "无(请输入拼音或汉字搜索销售店)",
                },
            ],
            cityList: [],
            rules: {
                CompanyNameCN: [
                    {
                        required: true,
                        message: "请输入简称（中文）",
                        trigger: ["blur", "change"],
                    },
                ],
                CompanyNameEN: [
                    {
                        required: true,
                        message: "请输入简称（英文）",
                        trigger: ["blur", "change"],
                    },
                ],
                CompanyCode: [
                    {
                        required: true,
                        message: "请输入经销商编号",
                        trigger: ["blur", "change"],
                    },
                ],
                CityId: [
                    {
                        required: true,
                        message: "请选择城市",
                        trigger: ["blur", "change"],
                    },
                ],
                CompanyType: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: ["blur", "change"],
                    },
                ],
                ParentId: [
                    {
                        required: true,
                        message: "请选择所属经销商",
                        trigger: ["blur", "change"],
                    },
                ],
                Address: [
                    {
                        required: true,
                        message: "请输入地址",
                        trigger: ["blur", "change"],
                    },
                ],
                OpenTime: [
                    {
                        required: true,
                        message: "请选择开业时间",
                        trigger: ["blur", "change"],
                    },
                ],
            },
        };
    },
    watch: {
        //数据切换时初始化
        "form.CompanyType"(newValue, oldValue) {
            // if (newValue == "集团店") {
            //   this.form.ParentId = "00000000-0000-0000-0000-000000000000";
            // }
            // if (newValue == "销售店" && ) {
            //   this.companyList = [
            //     {
            //       id: "00000000-0000-0000-0000-000000000000",
            //       CompanyNameCN: "无",
            //     },
            //   ];
            // }
        },
    },
    methods: {
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        //初始化页面
        async init() {
            let self = this;
            if (self.$route.path === "/companyMannage/edit") {
                //获取当前数据
              let res = await this.$Http.GetCompanyInfoById({
                companyId: this.$route.query.companyId,
              });
              if (!res.Success) {
                this.$message.error(res.Message);
                return;
              }
              this.form = res.Data;
              //展示远程搜索名字而非code
              if (
                  self.form.CompanyType === "销售店" &&
                  self.form.ParentId !== "00000000-0000-0000-0000-000000000000"
              ) {
                  self.companyList = [
                      {
                          id: self.form.ParentId,
                          CompanyNameCN: self.form.GroupCompany,
                      },
                  ];
              }
            }
        },
        //经销商搜索
        handleSearch(value) {
            fetch(value, (data) => (this.companyList = data.Data));
        },

        //获取城市
        async getCityList() {
            let res = await this.$Http.GetCityName();
            this.cityList = res.Data;
        },
        //保存
        async save() {
            let self = this;
            self.$refs.form.validate(async (valid) => {
                if (valid) {
                    if (self.form.CompanyType === "集团店") {
                        self.form.ParentId = "00000000-0000-0000-0000-000000000000";
                    }
                    //新增or编辑
                    //moment转换为string
                    if (typeof self.form.OpenTime != "string") {
                        self.form.OpenTime = self.form.OpenTime.format("YYYY-MM-DD");
                    }
                    let res =
                        self.$route.path === "/companyMannage/edit"
                            ? await self.$Http.EditCompany(self.form)
                            : await self.$Http.InsertCompany({companyInfo: self.form});
                    if (res.Success) {
                        self.$message.success(res.Data);
                        self.$router.push("/companyMannage/list");
                    } else {
                        self.$message.error(res.Message);
                    }
                }
            });
        },
        companyChange(value) {
            if (typeof value == "undefined") {
                this.form.ParentId = "00000000-0000-0000-0000-000000000000";
                this.companyList = [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        CompanyNameCN: "无(输入拼音或汉字搜经销商)",
                    },
                ];
            }
        },
        openTimeChange(value) {
            // console.log(value.format('YYYY-MM-DD'));
        },
    },
    mounted() {
        this.init();
        this.getCityList();
    },
};
</script>
