<template>
  <section ref="capture">
    <a-row :gutter="[0,8]">
      <a-col :span="24">
        <div class="report_bg">
          {{ titleData.yearValue }}年{{
            titleData.mothValue === "全部" || Number(titleData.mothValue) === 0 ? "度" : parseInt(titleData.mothValue) + "月阶段" }}{{
            titleData.AreaValue }}车型分析实时报告
        </div>
        <one-real-time-model :data-params="allData"/>
        <two-real-time-model :data-params="allData"/>
      </a-col>
    </a-row>
    <img :src="img" alt="">
  </section>
</template>
<script>
import moment from "moment";
import underscore from "underscore";
import { disabledDate } from "@/until/dayFormat";
import oneRealTimeModel from "@/views/realTimeModelAnalysis/childComponent/oneRealTimeModel";
import twoRealTimeModel from "@/views/realTimeModelAnalysis/childComponent/twoRealTimeModel";
import {
  currentFilterCodes,
  lastFilterCodes,
  rateFilterCodes,
} from "@/views/realTimeModelAnalysis/childComponent/kpiCode";
import html2canvas from "html2canvas";
import { message } from "ant-design-vue";
import * as pdfMake from "pdfmake";
import JsPDF from "jspdf";

export default {
  name: "realTimeModelAnalysis",
  components: { oneRealTimeModel, twoRealTimeModel },
  data() {
    return {
      disabledDate, currentFilterCodes, lastFilterCodes,
      allData: {
        data: {},
        date: {},
      },
      isCloseA: true,
      Http: {},
      selectValue: {
        yearValue: "",
        defaultYearValue: "全部",
        defaultMothValue: "全部",
        mothValue: [],
        areaArr: [],
        AreaValue: "全国",
        areaCode: "",
      },
      titleData: {
        yearValue: "",
        mothValue: "",
        AreaValue: "全国",
      },
      treeData: [
        {
          title: "全国",
          value: "",
          key: "",
          children: [],
        }],
      dealers: [], // 新的区域
      pickerData: [],
      DefaultTreeData: [],
      DefaultPickerData: "",
      params: {
        yearValue: "",
        mothValue: "",
        AreaValue: "",
        areaCode: "",
      },
    };
  },
  async created() {
    await this.getTreeData();
    await this.getPickerData();
  //  setTimeout(() => this.generateImages(), 8000);
  },
  methods: {
    getParams() {
      message.loading("生成中,请稍后....", 6);
      let params = this.$route.query;
      if(Object.keys(params).length > 0) {
        this.params = params;
        this.selectValue.defaultYearValue = Number(this.params.yearValue);
        this.selectValue.defaultMothValue = Number(this.params.mothValue);
        this.selectValue.AreaValue = this.params.AreaValue;
        let arr = params.areaArr.split(",");
        arr.shift();
        this.selectValue.areaArr = arr;
      }
      this.search();
    },
    generateImages() {
      html2canvas(this.$refs.capture, {
        allowTaint: true, // 是否允许跨域图像
        dpi: 300, // 清晰度
        scale: 2, // 此参数会放大2倍,在导入PDF时开启
      }).then(canvas => {
        this.img = canvas.toDataURL();
        const title = `${this.params.yearValue}年${this.params.mothValue === "全部" || this.titleData.mothValue === 0 ? "度" : parseInt(this.titleData.mothValue) + "月阶段"}${this.titleData.AreaValue}车型分析实时报告`;
        // 使用PDFMAKE导出PDF不能自动分页,显示不完整
        /*       const docDefinition = {
          content: [{
            image: this.img,
            width: 500
          }]
        };
        pdfMake.createPdf(docDefinition).download("fileName.pdf");*/

        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        const pdfWidth = (contentWidth + 10) / 2 * 0.75;
        const pdfHeight = (contentHeight + 200) / 2 * 0.75; // 500为底部留白
        const imgWidth = pdfWidth;
        const imgHeight = contentHeight / 2 * 0.75; //内容图片这里不需要留白的距离
        const pageData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new JsPDF("", "pt", [pdfWidth, pdfHeight]);
        pdf.addImage(pageData, "jpeg", 0, 0, imgWidth, imgHeight);
        pdf.save(title + ".pdf");

        // 导出图片
        /*if(window.navigator.msSaveOrOpenBlob) {
          const bstr = atob(this.img.split(",")[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(blob, title + "." + "png");
        } else {
          const a = document.createElement("a");
          a.href = this.img;
          a.setAttribute("download", title);
          a.click();
      //    this.$router.push("/realTimeModelAnalysis?yearValue=" + this.selectValue.defaultYearValue + "&mothValue=" + this.selectValue.defaultMothValue + "&AreaValue=" + this.params.AreaValue + "&areaCode=" + this.selectValue.areaCode);
        }*/
      }).catch((error) => {
        this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
      });
      /* setTimeout(()=>{
         this.$router.push("/realTimeModelAnalysis?yearValue=" + this.selectValue.defaultYearValue + "&mothValue=" + this.selectValue.defaultMothValue + "&AreaValue=" + this.titleData.AreaValue + "&areaCode=" + this.selectValue.areaCode);
       },500)*/
    },
    async search() {
      /*标题*/
      this.titleData.yearValue = this.selectValue.defaultYearValue;
      this.titleData.mothValue = this.selectValue.defaultMothValue;
      this.titleData.AreaValue = this.selectValue.AreaValue;
      /*加载数据*/
      let mothValue = this.selectValue.defaultMothValue;
      if(mothValue === "全部" || this.selectValue.mothValue === "") mothValue = 0;
      let arr = {};
      arr["year"] = parseInt(this.selectValue.defaultYearValue);
      arr["month"] = parseInt(mothValue);
      arr["ranges"] = this.selectValue.areaArr;
      /* if(this.selectValue.areaCode.length > 8 || this.selectValue.areaCode === "") {
         arr["partId"] = this.selectValue.areaCode;
       } else {
         arr["dealerCode"] = this.selectValue.areaCode;
       }
       */
      arr["lastFilterCodes"] = lastFilterCodes;
      arr["currentFilterCodes"] = currentFilterCodes;
      arr["rateFilterCodes"] = rateFilterCodes;
      // 注意这里是手工取数,如果上线得加载开关
      let res = await this.$Http.Realtime(arr);
      if(res["Success"]) {
        this.allData.data = res.Data;
      } else {
        this.$message.error(res["Message"]);
      }
    },
    yearChange(val) {
      this.selectValue.mothValue = ["全部"];
      if(val === moment().format("y")) {
        let data2 = parseInt(moment().format("MM"));
        underscore.range(data2).map(d => {
          this.selectValue.mothValue.push(d + 1);
        });
      } else {
        underscore.range(12).map(d => {
          this.selectValue.mothValue.push(d + 1);
        });
      }
      this.selectValue.defaultYearValue = val;
    },
    mothChange(val) {
      this.selectValue.defaultMothValue = val;
    },
    onAreaChange(value, selectedOptions) {
      this.selectValue.areaCode = value[value.length - 1];
      this.selectValue.areaArr = value;
      this.selectValue.AreaValue = selectedOptions[selectedOptions.length - 1].label;
      this.params.AreaValue = "";
      selectedOptions.forEach((d, i) => {
        if(selectedOptions.length === i + 1) {
          this.params.AreaValue += d.label;
        } else {
          this.params.AreaValue += d.label + "/";
        }
      });
    },
    onPickerChange(val) {
      if(val.length > 4) {
        this.selectValue.defaultYearValue = moment(val).format("y");
        this.selectValue.defaultMothValue = moment(val).format("MM");
      } else {
        this.selectValue.defaultYearValue = val;
        this.selectValue.defaultMothValue = 0;
      }
    },
    getPickerData() {
      this.selectValue.defaultYearValue = moment().format("y");
      let data1 = moment(this.selectValue.defaultYearValue).add(-4, "y");
      let yearData = underscore.range(4).map(() => data1.add(1, "y").format("y")).reverse();
      this.pickerData = yearData.chain().map(y => {
        let obj = {};
        obj.value = y;
        obj.key = y;
        obj.title = y;
        let mothData = [];
        if(y === moment().format("y")) {
          let data2 = parseInt(moment().format("MM"));
          underscore.range(data2).map(d => {
            mothData.push(d + 1);
          });
        } else {
          underscore.range(12).map(d => {
            mothData.push(d + 1);
          });
        }
        obj.children = mothData.chain().map(m => {
          let childObj = {};
          childObj.value = y + "-" + m;
          childObj.key = y + "-" + m;
          childObj.title = y + "-" + m;
          return childObj;
        }).value();
        return obj;
      }).value();
      this.DefaultPickerData = this.pickerData[0].children[this.pickerData[0].children.length - 1].value;
      this.getParams();
    },
    async getTreeData() {
      let ras = await this.$Http.GetDealersTreeByRole();
      if(!ras.Data) {
        this.$message.error(ras.Message);
        return;
      }
      this.dealers = [{
        value: "",
        label: "全国",
        children: ras.Data,
      }];

    },
  },
};
</script>

<style scoped>

</style>
