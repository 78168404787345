<template>
  <section id="one_4">
    <a-row :gutter="[24,24]">
      <a-col :span="24">
        <a-card style="padding-top: 12px">
          <a-descriptions bordered :column="3" style="margin-bottom: 20px;">
            <a-descriptions-item label="店均资产负债率">{{ GetIndexValue("店均资产负债率", "%") }}</a-descriptions-item>
            <a-descriptions-item label="店均新车库存周转率">{{ GetIndexValue("店均新车库存周转率", "") }}</a-descriptions-item>
            <a-descriptions-item label="店均长库龄新车库存台次占比">{{ GetIndexValue("店均长库龄新车库存台次占比", "%") }}</a-descriptions-item>
          </a-descriptions>
          <div class="info">
            <p>
              1、资产风险管控：资产负债率：
              <span style="font-weight: normal !important;" v-html="C0107003"></span>
              ；
              <span style="padding-left: 1.5em">流动比率</span>
              <span v-html='GetDesc(data,"C0106003")'></span>
              ；速动比率
              <span v-html='GetDesc(data,"C0107002")'></span>
                ，较上月
              <span v-html='GetDesc(data,"C0107002", ["上升", "下降"],false,true)'></span>
              。
            </p>
            <p>
              2、库存风险管控：新车库存周转率
              <span v-html='GetDesc(data,"A0404002")'></span>
              ；长库龄新车库存台次占比
              <span v-html='GetDesc(data,"A0404111")'></span>
              ，较上月
              <span v-html='GetDesc(data,"A0404111", ["上升", "下降"],false,true)'></span>
              。
            </p>
          </div>
        </a-card>
      </a-col>
      <a-col :span="24">
        <a-card title="1、资产风险管控">
          <a-col :span="isWin">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_4_1_1"
            >
            </monthReportTableComponent>
          </a-col>
          <a-col :span="isWin" class="ppTop30">
            <chartComponent :chart-data="data.specialChartData_4_1_1"/>
          </a-col>
        </a-card>
      </a-col>
      <a-col :span="24">
        <a-card title="2、库存风险管控">
          <a-col :span="isWin">
            <monthReportTableComponent :columns-data="columnsData"
                                       :table-data="data.tableData_4_2_1"
            >
            </monthReportTableComponent>
          </a-col>
          <a-col :span="isWin" class="ppTop30">
            <chartComponent :chart-data="data.chartData_4_2_1"/>
          </a-col>
        </a-card>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import monthReportTableComponent from "@/components/monthReportTableComponent";
import chartComponent from "@/components/chartComponent";
import {GetDesc, getEmptyTemplate, numFormat} from "@/until/reportCalculate";
import {columnsImport, propertyData} from "../data.js";
import {GetWindows} from "@/until/dayFormat";
import moment from "moment";

export default {
  props: {
    reportParams: {},
    reportDate: String,
    areaData: {},
    isRegionReport: Boolean, // 是否地区报告,true就是地区
  },
  data() {
    return {
      isWin: 24,
      emptyTemplate: getEmptyTemplate(),
      columnsData: columnsImport.columnsImportDataPC,
      data: propertyData,
    };
  },
  created() {
    if (this.isRegionReport) {
      this.columnsData = columnsImport.columnsImportDataPcRegionReport;
    }
    this.isWin = GetWindows();
    columnsImport.loadData(this, this.data, this.reportParams);
  },
  computed: {
    C0107003: function () {
      if (this.data.resChart.SpecialValue) {
        let data = this.data.resChart.SpecialValue["C0107003"];
        if (data) {
          data = data.Values[moment(this.reportParams.ReportDate).month()];
          if (data) {
            let low = data["资产负债率<40%的店占比"] ?? 0;
            let middle = data["40%~70%的店占比"] ?? 0;
            let high = data[">70%的店占比"] ?? 0;
            let risk;
            if (low >= middle && low >= high) {
              risk = "小";
            } else if (middle >= high) {
              risk = "一般";
            } else {
              risk = "大";
            }
            low = (low * 100).toFixed(2) + "%";
            middle = (middle * 100).toFixed(2) + "%";
            high = (high * 100).toFixed(2) + "%";
            return `资产负债率<40%的店占比 <b>${low}</b>、<70%的店占比 <b>${middle}</b>、>70%的店占比 <b>${high}</b>，资金风险 <b>${risk}</b>`;
          }
        }
      }
      return "<b>-</b>";
    }
  },
  methods: {
    GetIndexValue(key, unit) {
      let value = 0;
      if (this.areaData && this.areaData.IndexValues) {
        value = this.areaData.IndexValues[key];
      }
      return numFormat(key, value, unit);
    },
    GetDesc,
  },
  components: {
    monthReportTableComponent, chartComponent,
  },
  watch: {
    reportParams: {
      handler: function () {
        columnsImport.loadData(this, this.data, this.reportParams);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";
</style>
