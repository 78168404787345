<template>
    <section>
        <a-row>
            <a-col :span="24"  v-if="isCloseA">
                <a-anchor :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
                    <div class="fix_border">
                      <a-col :span="18">
                        报告月份：
                        <a-month-picker @change="dateChange" placeholder="请选择报告月份" v-model="params.ReportDate"
                                        :disabledDate="disabledDate"
                                        style="width: 110px" :allowClear="false"/>

                        大区：
                        <a-select show-search v-model="partName" @change="getDealerList" placeholder="请选择大区"
                                  style="width: 150px;" :filterOption="selectorFilter">
                            <a-select-option v-for="item in partList" :key="item.PartId" :value="item.PartName">
                                {{ item.PartName }}
                            </a-select-option>
                        </a-select>
                        销售店:
                        <a-select show-search @change="dealerChange" v-model="params.DealerInfo" placeholder="请选择销售店"
                                  style="width: 150px;" label-in-value :filterOption="selectorFilter">
                            <a-select-option v-for="(item,i) in dealerList" :key="i" :value="item.DealerCode">
                                {{ item.DealerName }}
                            </a-select-option>
                        </a-select>
                        <a-button type="primary" style="margin-left: 20px" @click="getData">搜索</a-button>
                      </a-col>
                      <a-col style="float: right;">
                  <span style="position: relative;float: right; top: -12px;margin-left:1vw" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
                        <a-tooltip placement="top" :title="`点击生成《${ ReportDateStr + params.DealerInfo.label.trim()}店投资人简报》`">
                          <a-button type="primary" @click="generateImages()">生成长图</a-button>
                        </a-tooltip>
                      </a-col>
                    </div>
                </a-anchor>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
              <div class="" ref="capture">
                <a-card class="main">
                    <div class="main-title main">
                        <h1>{{ ReportDateStr }}投资人简报</h1>
                        <h3>{{ params.DealerInfo.label.trim() }}店</h3>
                    </div>
                    <a-card class="main-content">
                        <a-col :span="24">
                            <h3 class="title">
                                盈利能力
                            </h3>
                        </a-col>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>资产回报率(年化）</p>
                                    <p>评估公司相对其总资产值盈利能力的主要指标（税前利润*12/总资产）</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_1_1.chartData"
                                                  :settings="investorData.chart_1_1.chartSetting"
                                                  :extend="investorData.chart_1_1.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>税前利润</p>
                                    <p>反映公司的经营盈利能力</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_1_2.chartData"
                                                  :settings="investorData.chart_1_2.chartSetting"
                                                  :extend="investorData.chart_1_2.chartextend"
                                                  :after-set-option="investorData.chart_1_2.options"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：
                                                <span :class='getTxtClass("C0408004", "QOQ", "NationwideStandard")'>
                                                {{
                                                        getTableData("C0408004", "QOQ", "NationwideStandard")
                                                    }}
                                                </span>

                                            </th>
                                            <th>
                                                区域标杆值环比：
                                                <span :class='getTxtClass("C0408004", "QOQ", "RegionalStandard")'>
                                                    {{ getTableData("C0408004", "QOQ", "RegionalStandard") }}
                                                </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：
                                                <span :class='getTxtClass("C0408004", "QOQ", "Current")'>
                                                    {{ getTableData("C0408004", "QOQ", "Current") }}
                                                </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>税前利润率</p>
                                    <p>反映公司的经营盈利能力</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_1_3.chartData"
                                                  :settings="investorData.chart_1_3.chartSetting"
                                                  :extend="investorData.chart_1_3.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：
                                                <span :class='getTxtClass("C0408021", "QOQ", "NationwideStandard")'>
                                                     {{
                                                        getTableData("C0408021", "QOQ", "NationwideStandard")
                                                    }}
                                                </span>
                                            </th>
                                            <th>
                                                区域标杆值环比:
                                                <span :class='getTxtClass("C0408021", "QOQ", "RegionalStandard")'>
                                                    {{ getTableData("C0408021", "QOQ", "RegionalStandard") }}
                                                </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：
                                                <span :class='getTxtClass("C0408021", "QOQ", "Current")'>
                                                    {{ getTableData("C0408021", "QOQ", "Current") }}
                                                </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>税前利润增长率&同比</p>
                                    <p>反映公司的经营盈利增长能力</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_1_4.chartData"
                                                  :settings="investorData.chart_1_4.chartSetting"
                                                  :extend="investorData.chart_1_4.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24">
                                <h3 class="title">
                                    业务能力——销售业务
                                </h3>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>新车销量</p>
                                    <p>
                                        反映在一定时期内的新车裸车销量
                                    </p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_1.chartData"
                                                  :settings="investorData.chart_2_1.chartSetting"
                                                  :extend="investorData.chart_2_1.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：
                                                <span :class='getTxtClass("A0302001", "QOQ", "NationwideStandard")'>
                                                  {{
                                                        getTableData("A0302001", "QOQ", "NationwideStandard")
                                                    }}
                                                </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：
                                                <span :class='getTxtClass("A0302001", "QOQ", "RegionalStandard")'>
                                                    {{ getTableData("A0302001", "QOQ", "RegionalStandard") }}
                                                </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：
                                                <span :class='getTxtClass("A0302001", "QOQ", "Current")'>
                                                    {{ getTableData("A0302001", "QOQ", "Current") }}
                                                </span>
                                            </th>
                                        </tr>

                                    </table>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值同比：
                                                <span :class='getTxtClass("A0302001", "YOY", "NationwideStandard")'>
                                                    {{
                                                        getTableData("A0302001", "YOY", "NationwideStandard")
                                                    }}
                                                </span>
                                            </th>
                                            <th>
                                                区域标杆值同比：<span
                                                :class='getTxtClass("A0302001", "YOY", "RegionalStandard")'>
                                                {{
                                                    getTableData("A0302001", "YOY", "RegionalStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店同比：
                                                <span :class='getTxtClass("A0302001", "YOY", "Current")'>
                                                                                                    {{
                                                        getTableData("A0302001", "YOY", "Current")
                                                    }}
                                                </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>单车销售综合毛利构成</p>
                                    <p>反映单车销售综合毛利的结构组成</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_2.chartData"
                                                  :settings="investorData.chart_2_2.chartSetting"
                                                  :extend="investorData.chart_2_2.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>新车返利后毛利率、销售综合毛利率</p>
                                    <p>
                                        反映新车返利后毛利和新车销售综合毛利在各收入中占比情况
                                    </p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_3.chartData"
                                                  :settings="investorData.chart_2_3.chartSetting"
                                                  :extend="investorData.chart_2_3.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24">
                                <h3 class="title">
                                    业务能力——售后业务/水平事业
                                </h3>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>售后来厂台次</p>
                                    <p>
                                        反映来店维修台次情况
                                    </p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_4.chartData"
                                                  :settings="investorData.chart_2_4.chartSetting"
                                                  :extend="investorData.chart_2_4.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("B0302001", "QOQ", "NationwideStandard")'>
                                                    {{
                                                    getTableData("B0302001", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：<span
                                                :class='getTxtClass("B0302001", "QOQ", "RegionalStandard")'>
                                                    {{ getTableData("B0302001", "QOQ", "RegionalStandard") }}
                                                </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：<span
                                                :class='getTxtClass("B0302001", "QOQ", "Current")'>
                                                {{ getTableData("B0302001", "QOQ", "Current") }}
                                            </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>售后单车产值及售后综合毛利率</p>
                                    <p>反映每台售后来厂台次获取的售后收入及售后业务的综合盈利能力</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_5.chartData"
                                                  :settings="investorData.chart_2_5.chartSetting"
                                                  :extend="investorData.chart_2_5.chartextend"
                                                  :after-set-option="investorData.chart_2_5.options"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>售后业务结构占比</p>
                                    <p>反映售后收入中机电、钣喷、水平事业收入占比</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_6.chartData"
                                                  :settings="investorData.chart_2_6.chartSetting"
                                                  :extend="investorData.chart_2_6.chartextend"


                                    ></ve-histogram>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>三大业务毛利贡献</p>
                                    <p>反映毛利中销售后、水平事业的占比</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_7.chartData"
                                                  :settings="investorData.chart_2_7.chartSetting"
                                                  :extend="investorData.chart_2_7.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>单车销售水平事业毛利</p>
                                    <p>每台新车获取的销售水平事业毛利</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_8.chartData"
                                                  :settings="investorData.chart_2_8.chartSetting"
                                                  :extend="investorData.chart_2_8.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>水平事业渗透率</p>
                                    <p>各项销售水平业务台次占新车总销量比</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_9.chartData"
                                                  :settings="investorData.chart_2_9.chartSetting"
                                                  :extend="investorData.chart_2_9.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>水平事业渗透率</p>
                                    <p>各项销售水平业务台次占新车总销量比</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_2_10.chartData"
                                                  :settings="investorData.chart_2_10.chartSetting"
                                                  :extend="investorData.chart_2_10.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-col :span="24">
                            <h3 class="title">
                                管理能力
                            </h3>
                        </a-col>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>经费覆盖率</p>
                                    <p>售后及水平事业毛利占总费用比</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_3_1.chartData"
                                                  :settings="investorData.chart_3_1.chartSetting"
                                                  :extend="investorData.chart_3_1.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("C0408013", "QOQ", "NationwideStandard")'>
                                                {{
                                                    getTableData("C0408013", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：<span
                                                :class='getTxtClass("C0408013", "QOQ", "RegionalStandard")'>
                                                {{ getTableData("C0408013", "QOQ", "RegionalStandard") }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：<span
                                                :class='getTxtClass("C0408013", "QOQ", "Current")'>
                                                {{ getTableData("C0408013", "QOQ", "Current") }}
                                                </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>零服吸收率</p>
                                    <p>售后服务产生的利润与特约店运营成本的比率，衡量售后服务盈利能力的重要指标</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_3_2.chartData"
                                                  :settings="investorData.chart_3_2.chartSetting"
                                                  :extend="investorData.chart_3_2.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("B0107002", "QOQ", "NationwideStandard")'>
                                                 {{
                                                    getTableData("B0107002", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：<span
                                                :class='getTxtClass("B0107002", "QOQ", "RegionalStandard")'>
                                                {{ getTableData("B0107002", "QOQ", "RegionalStandard") }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：<span
                                                :class='getTxtClass("B0107002", "QOQ", "Current")'>
                                                {{ getTableData("B0107002", "QOQ", "Current") }}
                                            </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>总费用占总毛利比</p>
                                    <p>衡量特约店毛利留存情况，值越高表明毛利留存越低，反之则越高</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_3_3.chartData"
                                                  :settings="investorData.chart_3_3.chartSetting"
                                                  :extend="investorData.chart_3_3.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("C0408001", "QOQ", "NationwideStandard")'>
                                                 {{
                                                    getTableData("C0408001", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：<span
                                                :class='getTxtClass("C0408001", "QOQ", "RegionalStandard")'>
                                                {{ getTableData("C0408001", "QOQ", "RegionalStandard") }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：<span
                                                :class='getTxtClass("C0408001", "QOQ", "Current")'>
                                                {{ getTableData("C0408001", "QOQ", "Current") }}
                                            </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                        </a-row>
                        <a-col :span="24">
                            <h3 class="title">
                                运营能力
                            </h3>
                        </a-col>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>新车库存周转率</p>
                                    <p>反映新车库存周转快慢程度，衡量特约店销售能力及新车存货管理水平的指标</p>
                                </div>
                                <div class="chart-part">

                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_4_1.chartData"
                                                  :settings="investorData.chart_4_1.chartSetting"
                                                  :extend="investorData.chart_4_1.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("A0404002", "QOQ", "NationwideStandard")'>
                                                 {{
                                                    getTableData("A0404002", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：<span
                                                :class='getTxtClass("A0404002", "QOQ", "RegionalStandard")'>
                                                {{ getTableData("A0404002", "QOQ", "RegionalStandard") }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比：<span
                                                :class='getTxtClass("A0404002", "QOQ", "Current")'>
                                                {{ getTableData("A0404002", "QOQ", "Current") }}
                                            </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>新车库存结构占比</p>
                                    <p>反映库存车龄的健康程度</p>
                                </div>
                                <div class="chart-part">

                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_4_2.chartData"
                                                  :settings="investorData.chart_4_2.chartSetting"
                                                  :extend="investorData.chart_4_2.chartextend"
                                    ></ve-histogram>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>零部件库存周转率</p>
                                    <p>反映零部件库存周转快慢程度，衡量特约店销售能力及零部件存货管理水平的指标</p>
                                </div>
                                <div class="chart-part">
                                    <div class="chart-part">
                                        <ve-histogram :colors="chartColor"
                                                      :data="investorData.chart_4_3.chartData"
                                                      :settings="investorData.chart_4_3.chartSetting"
                                                      :extend="investorData.chart_4_3.chartextend"
                                        ></ve-histogram>
                                        <table class="table">
                                            <tr>
                                                <th>
                                                    全国标杆值环比：<span
                                                    :class='getTxtClass("B0404002", "QOQ", "NationwideStandard")'>
                                                        {{
                                                        getTableData("B0404002", "QOQ", "NationwideStandard")
                                                    }}
                                                </span>
                                                </th>
                                                <th>
                                                    区域标杆值环比：<span
                                                    :class='getTxtClass("B0404002", "QOQ", "RegionalStandard")'>
                                                    {{ getTableData("B0404002", "QOQ", "RegionalStandard") }}
                                                </span>
                                                </th>
                                                <th>
                                                    {{ params.DealerInfo.label.trim() }}店环比： <span
                                                    :class='getTxtClass("B0404002", "QOQ", "Current")'>
                                                    {{ getTableData("B0404002", "QOQ", "Current") }}
                                                </span>
                                                </th>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                        <a-col :span="24">
                            <h3 class="title">
                                风险管控能力
                            </h3>
                        </a-col>
                        <a-row :gutter="36">
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>资产负债率</p>
                                    <p>衡量公司负债水平及风险程度的重要指标</p>
                                </div>
                                <div class="chart-part">

                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_5_1.chartData"
                                                  :settings="investorData.chart_5_1.chartSetting"
                                                  :extend="investorData.chart_5_1.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("C0107003", "QOQ", "NationwideStandard")'>
                                                     {{
                                                    getTableData("C0107003", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比： <span
                                                :class='getTxtClass("C0107003", "QOQ", "RegionalStandard")'>
                                                {{ getTableData("C0107003", "QOQ", "RegionalStandard") }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比： <span
                                                :class='getTxtClass("C0107003", "QOQ", "Current")'>
                                                {{ getTableData("C0107003", "QOQ", "Current") }}
                                            </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="chart-title">
                                    <p>速动比率</p>
                                    <p>衡量公司流动资产中可立即变现用于偿还流动负债的能力</p>
                                </div>
                                <div class="chart-part">
                                    <ve-histogram :colors="chartColor"
                                                  :data="investorData.chart_5_2.chartData"
                                                  :settings="investorData.chart_5_2.chartSetting"
                                                  :extend="investorData.chart_5_2.chartextend"
                                    ></ve-histogram>
                                    <table class="table">
                                        <tr>
                                            <th>
                                                全国标杆值环比：<span
                                                :class='getTxtClass("C0107002", "QOQ", "NationwideStandard")'>
                                                        {{
                                                    getTableData("C0107002", "QOQ", "NationwideStandard")
                                                }}
                                            </span>
                                            </th>
                                            <th>
                                                区域标杆值环比：<span
                                                :class='getTxtClass("C0107002", "QOQ", "RegionalStandard")'>
                                                    {{ getTableData("C0107002", "QOQ", "RegionalStandard") }}
                                            </span>
                                            </th>
                                            <th>
                                                {{ params.DealerInfo.label.trim() }}店环比： <span
                                                :class='getTxtClass("C0107002", "QOQ", "Current")'>
                                                {{ getTableData("C0107002", "QOQ", "Current") }}
                                            </span>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-card>
              </div>
            </a-col>
        </a-row>
    </section>
</template>
<script>
import moment from "moment";
import {
    investorData,
    stdChartDataRows,
    codeMap,
    stdchartextend1,
    stdchartextend2,
    stdchartextend2_2,
} from "./data.js"
import {disabledDate} from '../../until/dayFormat.js'
import {xAxis, yAxisDefault} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
    data() {
        return {
            investorData,
            stdChartDataRows,
            codeMap,
            stdchartextend1,
            stdchartextend2,
            stdchartextend2_2,
            partList: [],
            isCloseA:true,
            dealerList: [],
            chartColor: ["#5A6C90", "#267C93", "#73A9AB", "#C3DBDB", "#f9cf36", "#4a5bdc", "#4cd698", "#f4914e", "#fcb75b", "#ffe180", "#b6c2ff", "#96edc1"],
            partName: '',
            ReportDateStr: "",
            resData: [],
            params: {
                //报告月份
                ReportDate: null,
                //销售店信息对象
                DealerInfo: {key: "", label: ""}
            },
            chartData: {
                columns: ["key", "data"],
                rows: [{
                    "key": "全国标杆值",
                    "data": 1,
                },
                    {
                        "key": "区域标杆值",
                        "data": 1,
                    },
                    {
                        "key": "当前店",
                        "data": 1,
                    }],
            },
        };
    },
    created() {
    },
    mounted() {
        this.params.ReportDate = moment().add(-1, "M").format("YYYY-MM-01");
        this.ReportDateStr = moment().add(-1, "M").format("YYYY年MM月");
        this.partList = this.getPartList();
    },
    computed: {},
    methods: {
        disabledDate,
        //指标code，同比or环比，地区
      generateImages() {
        message.loading("生成中,请稍后....", 4);
        this.params.isClose = false;
        setTimeout(() => {
          html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
            this.img = canvas.toDataURL();
            if(window.navigator.msSaveOrOpenBlob) {
              const atob1 = atob(this.img.split(",")[1]);
              let n = atob1.length;
              const u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = atob1.charCodeAt(n);
              }
              const blob = new Blob([u8arr]);
              window.navigator.msSaveOrOpenBlob(blob, `${this.ReportDateStr + this.params.DealerInfo.label.trim()}店投资人简报.png`);
            } else {
              const a = document.createElement("a");
              a.href = this.img;
              a.setAttribute("download", `${this.ReportDateStr + this.params.DealerInfo.label.trim()}店投资人简报.png`);
              a.click();
            }
          }).catch((error) => {
            this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
          });
        }, 1000);
      },
      getTableData(code, type, region) {
            let tableDataObj = this._.where(this.resData, {Key: code});
            if (tableDataObj.length > 0) {
                return (tableDataObj[0].Value[type][region] ?? 0) + "%";
            } else {
                return 0 + "%";
            }
        },
        getTxtClass(code, type, region) {
            let tableDataObj = this._.where(this.resData, {Key: code});
            if (tableDataObj.length > 0) {
                return tableDataObj[0].Value[type][region] > 0 ? "greenTxt" : "redTxt"
            } else {
                return "greenTxt";
            }
        },
        //获取报表数据
        async getData() {
            let self = this;
            //过滤指标，后端要的
            let filterCodes = []
            //这里用于拼接后端API所需数据
            let allCodeList = self.getAllCodes();
            allCodeList.forEach(item => {
                filterCodes.push({
                    KpiCode: item.KpiCode,
                    ShowQOQ: item.ShowQOQ ?? false,
                    ShowYOY: item.ShowYOY ?? false
                })
            })
            //获取当前页面所需Code
            let res = await self.$Http.InvestorBriefReport({
                ReportDate: self.params.ReportDate,
                DealerCode: self.params.DealerInfo.key,
                filterCodes: filterCodes
            });
            //对数据展开计算，渲染图表
            if (res.Success) {
                //赋值给resData对象
                self.resData = res.Data;
                self.doCalc(res.Data);
            } else {
                self.$message.error(res.Message);
            }
        },
        //获取所有Code对象
        getAllCodes() {
            let self = this;
            let allCodeList = [];
            self._.keys(self.investorData).forEach((chartName) => {
                let chartObj = self.investorData[chartName];
                // let codeList = self._.pluck(chartObj.code, "KpiCode");
                allCodeList = allCodeList.concat(chartObj.code);
            });
            return allCodeList;
        },
        //对数据展开计算，渲染图表
        doCalc(data) {
            let self = this;
            // data.forEach(dataObj => {
            //     self._.keys(self.investorData).forEach((chartName) => {
            //         if (self.investorData[chartName].code.indexOf(dataObj.Key) > -1) {
            //             //普通柱状图
            //             if (self.investorData[chartName].code.length === 1) {
            //                 self.investorData[chartName].chartData.rows[0].data = dataObj.Value.Current.NationwideStandard;
            //                 self.investorData[chartName].chartData.rows[1].data = dataObj.Value.Current.RegionalStandard;
            //                 self.investorData[chartName].chartData.rows[2].data = dataObj.Value.Current.Current;
            //             }
            //             //堆叠图
            //             else {
            //                 self.investorData[chartName].code.forEach(code => {
            //                     //获取code的中文名
            //                     let strCodeName = codeMap[code];
            //                     console.log(strCodeName)
            //                     //修改chartData
            //
            //                 })
            //                 self.investorData[chartName].chartData.columns;
            //             }
            //            //
            //         }
            //     });
            // })
            // 分割线------------------------------------------------以下为新逻辑
            self._.keys(self.investorData).forEach((chartName) => {
                let dataObj = self.investorData[chartName];
                //普通柱状图(单指标)
                if (dataObj.code.length === 1) {
                    let stdChartDataRows = {
                        columns: ["key"],
                        rows: [{
                            "key": "全国标杆值",
                            // "data": {
                            //     value: 0,
                            //     itemStyle: {
                            //         color: '#5A6C90'
                            //     }
                            // },
                        },
                            {
                                "key": "区域标杆值",
                                // "data": {
                                //     value: 0,
                                //     itemStyle: {
                                //         color: '#267C93'
                                //     }
                                // },
                            },
                            {
                                "key": "当前店",
                                // "data": {
                                //     value: 0,
                                //     itemStyle: {
                                //         color: '#73A9AB'
                                //     }
                                // },
                            }],
                    }

                    //从后端返回值中拿取到数据
                    let valueObj = self._.where(data, {Key: dataObj.code[0].KpiCode})
                    let strCodeName = codeMap[dataObj.code[0].KpiCode];
                    if (valueObj.length > 0) {
                        let tmp = valueObj[0].Value;
                        //百分位处理
                        if (tmp.Unit === "%") {
                            dataObj.chartSetting.yAxisType = ["0.0%"];
                            dataObj.chartextend = self.stdchartextend1;
                        } else if (tmp.Unit === "次" || tmp.Unit === "倍") {
                            dataObj.chartSetting.yAxisType = ["0.0"];
                            dataObj.chartextend = self.stdchartextend2_2;
                        } else {
                            dataObj.chartSetting.yAxisType = ["0"];
                            dataObj.chartextend = self.stdchartextend2;
                        }

                        // 1_2图需要使用万元做单位
                        if (chartName == "chart_1_2") {
                            let stdchartextend_1_2 = {
                                series: {
                                    label: {
                                        show: true,
                                        formatter: function (params) {
                                            return (params.data.value / 10000).toFixed(1) + "万元";
                                        },
                                        color: "black",
                                    },
                                    barWidth: 60,
                                },
                                legend: {
                                    show: false
                                },
                                xAxis,
                                yAxis: [yAxisDefault],


                            }
                            dataObj.chartextend = stdchartextend_1_2;
                            // dataObj

                        }
                        //给X轴赋值赋名字
                        stdChartDataRows.columns.push(strCodeName);
                        stdChartDataRows.rows.forEach((rowItem, index) => {
                            switch (index) {
                                case 0:
                                    Object.assign(rowItem, rowItem[strCodeName] =
                                        {
                                            value: tmp.Current[dataObj.chartXAxisKey[0]] === 0 ? null : tmp.Current[dataObj.chartXAxisKey[0]],
                                            itemStyle: {
                                                color: '#5A6C90'
                                            }
                                        });
                                    break;
                                case 1:
                                    Object.assign(rowItem, rowItem[strCodeName] =
                                        {
                                            value: tmp.Current[dataObj.chartXAxisKey[1]] === 0 ? null : tmp.Current[dataObj.chartXAxisKey[1]],
                                            itemStyle: {
                                                color: '#267C93'
                                            }
                                        });
                                    break;
                                case 2:
                                    Object.assign(rowItem, rowItem[strCodeName] =
                                        {
                                            value: tmp.Current[dataObj.chartXAxisKey[2]] === 0 ? null : tmp.Current[dataObj.chartXAxisKey[2]],
                                            itemStyle: {
                                                color: '#73A9AB'
                                            }
                                        });
                                    break;
                                default:
                                    Object.assign(rowItem, rowItem[strCodeName] = null);
                                    break;
                            }
                        })
                        dataObj.chartData = stdChartDataRows;
                        // //最后一列的名字，如果是当前店的话，取当前店名，否则使用原来的数据
                        dataObj.chartData.rows[2].key = dataObj.chartXAxisName[2] === "当前店" ? self.params.DealerInfo.label.trim() : dataObj.chartXAxisName[2];
                    }
                }
                //堆叠图（多指标图）
                else {
                    let columns = ["key"];
                    let rows = [{
                        "key": "全国标杆值",
                    },
                        {
                            "key": "区域标杆值",
                        },
                        {
                            "key": self.params.DealerInfo.label.trim(),
                        }];
                    //1-4图表（税前利润增长率&同比）与其他图表不同，是从环比和同比对象去取得数据的
                    if (chartName === "chart_1_4") {
                        columns = ["key", "税前利润增长率", "税前利润同比"];
                        let code = "C0408004";
                        //拿取数据
                        let valueObjList = self._.where(data, {Key: code})
                        if (valueObjList.length > 0) {
                            let valueObj = valueObjList[0];
                            rows.forEach((rowItem, index) => {
                                switch (index) {
                                    case 0:
                                        if (valueObj.Value.QOQ.NationwideStandard === 0) {
                                            Object.assign(rowItem, rowItem["税前利润增长率"] = null);
                                        } else {
                                            Object.assign(rowItem, rowItem["税前利润增长率"] = Number((valueObj.Value.QOQ.NationwideStandard / 100).toFixed(3)));
                                        }
                                        if (valueObj.Value.YOY.NationwideStandard === 0) {
                                            Object.assign(rowItem, rowItem["税前利润同比"] = null);
                                        } else {
                                            Object.assign(rowItem, rowItem["税前利润同比"] = Number((valueObj.Value.YOY.NationwideStandard / 100).toFixed(3)));
                                        }
                                        break;
                                    case 1:
                                        if (valueObj.Value.QOQ.RegionalStandard === 0) {
                                            Object.assign(rowItem, rowItem["税前利润增长率"] = null);
                                        } else {
                                            Object.assign(rowItem, rowItem["税前利润增长率"] = Number((valueObj.Value.QOQ.RegionalStandard / 100).toFixed(3)));
                                        }
                                        if (valueObj.Value.YOY.RegionalStandard === 0) {
                                            Object.assign(rowItem, rowItem["税前利润同比"] = null);
                                        } else {
                                            Object.assign(rowItem, rowItem["税前利润同比"] = Number((valueObj.Value.YOY.RegionalStandard / 100).toFixed(3)));
                                        }
                                        break;
                                    case 2:
                                        if (valueObj.Value.QOQ.Current === 0) {
                                            Object.assign(rowItem, rowItem["税前利润增长率"] = null);
                                        } else {
                                            Object.assign(rowItem, rowItem["税前利润增长率"] = Number((valueObj.Value.QOQ.Current / 100).toFixed(3)));
                                        }
                                        if (valueObj.Value.YOY.Current === 0) {
                                            Object.assign(rowItem, rowItem["税前利润同比"] = null);
                                        } else {
                                            Object.assign(rowItem, rowItem["税前利润同比"] = Number((valueObj.Value.YOY.Current / 100).toFixed(3)));
                                        }
                                        break;
                                    default:
                                        Object.assign(rowItem, rowItem["税前利润增长率"] = null);
                                        Object.assign(rowItem, rowItem["税前利润同比"] = null);
                                        break;
                                }
                            })
                        }
                    } else {
                        dataObj.code.forEach(code => {
                            let strCodeName = codeMap[code.KpiCode];
                            //修改chartData
                            columns.push(strCodeName)
                            rows.forEach((rowItem, index) => {
                                let valueObjList = self._.where(data, {Key: code.KpiCode})
                                if (valueObjList.length > 0) {
                                    let valueObj = valueObjList[0];
                                    switch (index) {
                                        case 0:
                                            Object.assign(rowItem, rowItem[strCodeName] =
                                                (valueObj.Value.Current[dataObj.chartXAxisKey[0]] === 0 ? null : valueObj.Value.Current[dataObj.chartXAxisKey[0]]));
                                            rowItem.key = dataObj.chartXAxisName[0];
                                            break;
                                        case 1:
                                            Object.assign(rowItem, rowItem[strCodeName] =
                                                (valueObj.Value.Current[dataObj.chartXAxisKey[1]] === 0 ? null : valueObj.Value.Current[dataObj.chartXAxisKey[1]]));
                                            rowItem.key = dataObj.chartXAxisName[1];
                                            break;
                                        case 2:
                                            Object.assign(rowItem, rowItem[strCodeName] =
                                                (valueObj.Value.Current[dataObj.chartXAxisKey[2]] === 0 ? null : valueObj.Value.Current[dataObj.chartXAxisKey[2]]));
                                            //最后一列的名字，如果是当前店的话，取当前店名，否则使用原来的数据
                                            rowItem.key = dataObj.chartXAxisName[2] === "当前店" ? self.params.DealerInfo.label.trim() : dataObj.chartXAxisName[2];
                                            break;
                                        default:
                                            Object.assign(rowItem, rowItem[strCodeName] = null);
                                            break;
                                    }
                                }
                            })
                        })
                    }
                    //给chartData对象赋值
                    dataObj.chartData.columns = columns;
                    dataObj.chartData.rows = rows;
                }
            });

        },
        selectorFilter(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        dateChange(date, dateString) {
            this.ReportDateStr = date.format("YYYY年MM月");
            this.getData();
        },
        async getPartList() {
            let self = this;
            let res = await self.$Http.GetPartListByPage({
                pageSize: 100,
                pageIndex: 1,
            });
            if (res.Data.length > 0) {
                self.partList = res.Data;
                self.partName = self.partList[0].PartName;
                await self.getDealerList(self.partName);
            }
        },
        //获取销售店列表
        async getDealerList(partName) {
            let self = this;
            let res = await self.$Http.GetPartList({
                PartName: partName,
            });
            self.dealerList = res.Data;
            //如果返回值有值并且是用户所选择的(非系统赋值的)，默认选择第一个销售店
            if (self.dealerList.length > 0) {
                self.dealerChange({key: self.dealerList[0].DealerCode, label: self.dealerList[0].DealerName});
            }
        },
        // 销售店改变
        dealerChange(value) {
            this.params.DealerInfo = value;
            this.getData();
            this.setChartDealerName()
        },
        // 设置表格中店名称
        setChartDealerName() {
            this._.keys(this.investorData).forEach((chartName) => {
                this.investorData[chartName].chartData.rows[2].key = this.params.DealerInfo.label;
            });
        }


    },
    filters: {},
};
</script>
<style lang="less" scoped>
.fix_border {
    padding: 20px 10px 20px 30px;
    background: #fff;
    border: 1px solid #e8e8e8;
    box-shadow: 0 2px 4px 0 rgba(32, 0, 0, 0.1);
    margin-left: -2px;
}

.main {
    background: url("../../../src/assets/纹理.png") no-repeat;
    background-size: 100% auto;
}

.main-title {
    text-align: center;
}

.main-title h1 {
    font-weight: bold;
    font-size: 2.5rem;
}

.main-title h3 {
    font-size: 2rem;
    color: #666666;
}

.title {
    //border-left: #24619e 3px solid;
    padding-left: 10px;
    height: 40px;
    background-color: #e3e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
}

.main-content {
    margin-top: 60px
}

.chart-part {
    height: 500px;
}

.chart-title {
    margin-top: 20px;
}

.chart-title p:first-child {
    font-weight: bold;
    font-size: 16px;
}

.chart-title p:nth-child(2) {
    color: #666;
}

.table {
    text-align: center;
    border: #FFFFFF 1px solid;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    font-family: "Microsoft YaHei";

    th {
        background: #DDDDDD;

        span {

        }
    }
}

.greenTxt {
    color: green;
}

.redTxt {
    color: red;
}

</style>
