<template>
  <section>
    <div>
      <a-card>
        <template slot="title">
          <div slot="tit">修改 【<span style="color: #24619e">{{ name }}</span>】 菜单</div>
        </template>
        <div style="padding-bottom: 20px;padding-top: 20px">
          <a-tooltip class="display-block tooltip">
            <template slot="title">
              选中菜单即刻拥有权限 !
            </template>
            <p><span>* </span>修改权限：勾选中的
              <a-checkbox checked></a-checkbox>
              ，为已拥有权限的菜单;
              <a-checkbox></a-checkbox>
              未选中的是还未拥有权限的菜单
            </p>
          </a-tooltip>
          
          <a-button type="primary" icon="check" @click="SaveAuth">
            保存已勾选菜单
          </a-button>
          <span class="space">&nbsp;&nbsp;&nbsp;</span>
          <a-button type="" icon="close" @click="ClearAuth">
            清空所有菜单
          </a-button>
          <a-button type="" @click="authList">
            返回权限列表
          </a-button>
        </div>
        
        <div style="height: 800px; overflow:auto;padding-top: 20px;">
          <a-tree
            v-model="checkedKeys"
            checkable
            class="tree_class"
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :tree-data="authData"
            @expand="onExpand"
          >
          </a-tree>
        </div>
      </a-card>
    </div>
  
  </section>
</template>
<script>
export default {
  
  data() {
    return {
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      authData: [],
      PermissionCode: "",
      name: "",
      ids: [],
      menuMap: {},
    };
  },
  created() {
    this.PermissionCode = this.$route.query.PermissionCode;
    this.name = this.$route.query.name;
    this.getData();
    setTimeout(() => {
      this.getUserData();
    }, 500);
  },
  watch: {
    checkedKeys(val) {
      this.ids = val;
    },
  },
  methods: {
    async getData() {
      let res = await this.$Http.commonApi({
        API: "Jason_AllMenu",
      });
      if (!res.Success) {
        this.$message.error("错误！");
        return false;
      }
      this.expandedKeys = [];
      this.authData = this.traverseTree(res.ApiParamObj);
    },
    traverseTree(nodes, parentIds) {
      let value = nodes.chain().map(node => {
        let menu = {};
        let menuPath = Object.assign([], parentIds);
        menuPath.push(node.MenuId);
        this.expandedKeys.push(node.MenuId);
        this.menuMap[node.MenuId] = menuPath;
        menu.key = node.MenuId;
        menu.title = node.MenuNameCN;
        if (node.Child && node.Child.length > 0)
          menu.children = this.traverseTree(node.Child, menuPath);
        return menu;
      }).value();
      return value;
    },
    async getUserData() {
      if (!this.$route.query.PermissionCode) {
        this.$message.error("未获取到对应的权限名称！");
        setTimeout(() => {
          this.$router.push("permissions");
        }, 500);
      }
      
      let rfs = await this.$Http.commonApi({
        API: "Jason_GetMenuByPermissionId",
        PermissionCode: this.$route.query.PermissionCode,
      });
      
      if (!rfs.Success) {
        this.$message.error("错误！");
        return false;
      }
      this.checkedKeys = [];
      this.loadSelectKeys(rfs.ApiParamObj);
    },
    loadSelectKeys(nodes) {
      nodes.forEach(node => {
        if (node.Child && node.Child.length > 0) {
          this.loadSelectKeys(node.Child);
        } else {
          this.checkedKeys.push(node.MenuId);
        }
      });
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    async SaveAuth() {
      let ids = {};
      this.ids.forEach(id => this.menuMap[id].forEach(menuId => ids [menuId] = null));
      //   console.log(Object.keys(ids));
      let formData = {
        permissionId: this.$route.query.PermissionId,
        ids: Object.keys(ids).toString(),
      };
      let res = await this.$Http.AddMenu(formData);
      if (res.Success) {
        this.$message.success(res.Data);
      } else {
        this.$message.error("保存失败，请重新保存！多次失败，请联系管理员！");
      }
      setTimeout(() => {
        this.authList();
      }, 1000);
    },
    ClearAuth() {
      this.checkedKeys = [];
    },
    authList() {
      this.$router.push("/Permissions");
    },
  },
};
</script>
<style lang="less" scoped>
.tooltip {
  width: 50%;
  font-size: 14px;
  color: #D27735;
  
  span {
    font-size: 18px;
    font-weight: bold;
    }
  }
</style>
