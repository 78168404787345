<template>
  <section>
    <a-card>
      选择月份：
      <a-month-picker style="margin-right: 20px"
                      format="YYYY-MM" placeholder="请选择月份"
                      @change="value => search.reportDate = value"/>
      <a-button type="primary" style="margin-right: 20px" @click="getReport">
        搜索
      </a-button>
    </a-card>
    <a-card title="分析报告" style="margin-top: 8px;margin-bottom: 18px;">
      <a-tabs>
        <a-tab-pane v-for="sheet in dataSheets" :key="sheet.SheetName" :tab="sheet.SheetName">
          <a-table :rowKey="(record, index) => index" :columns="tableColumns" :data-source="sheet.Details"
                   :pagination="false" bordered :scroll="{ x: 'max-content',y: false}">
            <span slot="valueOutput" slot-scope="value, record">
              {{ formatValue(value, record) }}
            </span>
            <span slot="nameOutput" slot-scope="name" v-html="name">
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <div v-show="isTop" @click="backtop" class="ant-back-top">
      <div class=""
           style="height: 40px; width: 40px; line-height: 40px; border-radius: 4px; background-color: #24619e; color: rgb(255, 255, 255); text-align: center; font-size: 20px;">
        <a-icon type="vertical-align-top"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isTop: false,
      dataSheets: [],
      tableColumns: [
        {
          title: "序号",
          dataIndex: "Index",
          key: "Index",
          width: 80,
          customRender: (text, record, index) => `${index + 1}`,
        }, {
          title: "指标名称",
          dataIndex: "KpiName",
          key: "KpiName",
          scopedSlots: {customRender: "nameOutput"},
        }, {
          title: "单位",
          dataIndex: "Unit",
          key: "Unit",
        }, {
          title: "自主均值",
          dataIndex: "自主Avg",
          key: "自主Avg",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "自主中位值",
          dataIndex: "自主Median",
          key: "自主Median",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "自主标杆值",
          dataIndex: "自主Standard",
          key: "自主Standard",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "豪华均值",
          dataIndex: "豪华Avg",
          key: "豪华Avg",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "豪华中位值",
          dataIndex: "豪华Median",
          key: "豪华Median",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "豪华标杆值",
          dataIndex: "豪华Standard",
          key: "豪华Standard",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "合资均值",
          dataIndex: "合资Avg",
          key: "合资Avg",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "合资中位值",
          dataIndex: "合资Median",
          key: "合资Median",
          scopedSlots: {customRender: "valueOutput"},
        }, {
          title: "合资标杆值",
          dataIndex: "合资Standard",
          key: "合资Standard",
          scopedSlots: {customRender: "valueOutput"},
        },
      ],
      activeTypeId: 0,
      search: {
        reportDate: null
      },
    };
  },
  created() {
    window.addEventListener("scroll", this.showBtn, true);
  },
  methods: {
    // 显示回到顶部按钮
    showBtn() {
      let that = this;
      that.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.isTop = that.scrollTop > 200;
    },
    backtop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    paramsIsError() {
      if (this.search.reportDate == null) {
        this.$message.warn("请选择月份");
        return true;
      }
      return false;
    },
    async getReport() {
      this.tableData = [];
      if (this.paramsIsError()) {
        return;
      }
      let res = await this.$Http.getBmReport({
        reportDate: this.search.reportDate.format("YYYY-MM"),
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      res.Data.forEach(sheet => {
        sheet.Details.forEach(record => {
          record.KpiName = record.KpiName.replaceAll(' ','&nbsp;');
        });
      });
      this.dataSheets = res.Data;
    },
    formatValue(value, record) {
      if (value == null)
        value = 0;

      if (record.Unit !== '%')
        return value.toFixed(record.DecimalDigits);

      value *= 100;
      return value.toFixed(record.DecimalDigits) + "%";
    }
  },
};
</script>
<style lang="less">
.ant-back-top {
  bottom: 18px !important;
  right: 46px !important;
}
</style>
