<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created () {
      document.body.removeChild(document.getElementById('preloader'))   // 加载页面完后移除加载动画
  }
};
</script>

<style>
</style>
