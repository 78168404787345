<template>
  <div>
    <div id="chart" ref="chart1"></div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      mydata:[]
    }
  },
  created () {
    this.mydata=[35, 20, 36, 10, 10, 20];

  },
  methods: {},
  mounted () {
    let mychart = this.$echarts.init(this.$refs.chart1)
    const op = {
      title: {
        text: '示例',
      },
      tooltip: {},
      legend: {
        data: ['销量'],
      },
      xAxis: {
        data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子'],
      },
      yAxis: {},
      series: [
        {
          name: '销量',
          type: 'bar',
          data: this.mydata,
        },
      ],
    }
    mychart.setOption(op)
  },
}
</script>

<style scoped>
#chart {
  width: 1000px;
  height: 500px;
}
</style>
