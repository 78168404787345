<template>
    <a-row :gutter="[0,8]">
        <a-col :span="24" v-if="isCloseA">
            <a-anchor style="min-width: 1440px" :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
                <div class="fix_border">
                    <a-col :span="19">
                      <switch-setting :api-instance.sync="selectValue.Http"/>
                      <a-month-picker v-model="selectValue.yearValue" placeholder="选择月份" :allowClear="false"/>
                        <a-select v-model="selectValue.carType"
                                  style="width: 200px;margin-left:1vw;">
                            <a-select-option v-for="(item,i) in car" :key="i" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                        <a-cascader style="width: 380px;margin-left: 1vw" :options="dealers" :allowClear="false"
                                    change-on-select expandTrigger="hover"
                                    placeholder="全国" @change="onAreaChange"
                                    :show-search="true"
                        />
                    </a-col>
                    <a-col style="float: right" >
                        <span style="float: right;position: relative;top: -12px;margin-left:1vw"
                              @click="isCloseA=false">
                            <a-icon type="close"/>
                        </span>
                      <a-tooltip placement="top" :title="`点击生成《${ moment(selectValue.yearValue).format('yyyy年M月') + selectValue.carType + selectValue.AreaValue }车型分析日报告》`">
                        <!--<a-button type="primary" @click="generateImages()">生成长图</a-button>-->
                      </a-tooltip>
                    </a-col>
                </div>
            </a-anchor>
        </a-col>
        <a-col :span="24">
          <div class="" ref="capture">
            <div class="report_bg">
                {{ moment(selectValue.yearValue).format("yyyy年M月") + selectValue.carType + selectValue.AreaValue }}车型分析日报告
            </div>
            <one-real-time :data-params="selectValue"/>
          </div>
        </a-col>
    </a-row>
</template>
<script>
import moment from "moment";
import {disabledDate} from "@/until/dayFormat";
import oneRealTime from "@/views/monthModelAnalysis/dayModelAnalysisChildComponent/oneRealTime";
import {currentFilterCodes, lastFilterCodes} from "@/views/monthModelAnalysis/monthModelAnalysisChildComponent/kpiCode";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";
import { carType } from "@/views/monthModelAnalysis/yearModelAnalysisChildComponent/kpiCode";

export default {
    name: "monthModelAnalysis",
    components: {oneRealTime},
    data() {
        return {
            moment, disabledDate, currentFilterCodes, lastFilterCodes,
            car:carType,
            isCloseA: true,
            dealers: [],  // 新的区域列表
            selectValue: {
                yearValue: null,
                AreaValue: "全国",
                AreaData: [],
                carType: '所有车型',
                Http: {},
            },
            treeData: [
                {
                    title: "全国",
                    value: "",
                    key: "",
                    children: [],
                }],
            // pickerData: [],
            DefaultTreeData: [],
            //  DefaultPickerData: [],
        };
    },
    mounted() {
        this.getYear();
        this.getTreeData();
    },
    methods: {
        generateImages() {
          message.loading("生成中,请稍后....", 4);
          setTimeout(() => {
            html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
              this.img = canvas.toDataURL();
              if(window.navigator.msSaveOrOpenBlob) {
                const atob1 = atob(this.img.split(",")[1]);
                let n = atob1.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = atob1.charCodeAt(n);
                }
                const blob = new Blob([u8arr]);
                window.navigator.msSaveOrOpenBlob(blob, `${ moment(this.selectValue.yearValue).format("yyyy年M月") + this.selectValue.carType + this.selectValue.AreaValue}车型分析日报告.png`);
              } else {
                const a = document.createElement("a");
                a.href = this.img;
                a.setAttribute("download", `${ moment(this.selectValue.yearValue).format("yyyy年M月") + this.selectValue.carType + this.selectValue.AreaValue}车型分析日报告.png`);
                a.click();
              }
            }).catch((error) => {
              this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
            });
          }, 1000);
        },
        search() {
        },
        onAreaChange(value, selectedOptions) {
            this.selectValue.AreaData = value;
            this.selectValue.AreaValue = selectedOptions[selectedOptions.length - 1].label;
        },
        getYear() {
            this.selectValue.yearValue = moment();
        },

        async getTreeData() {
            // let res = await this.$Http.GetDealersByRole();
            // if (!res.Data) {
            //     this.$message.error(res.Message);
            //     return;
            // }
            // this.treeData[0].children = res.Data.chain().map(p => {
            //     let obj = {};
            //     obj.value = p.PartId;
            //     obj.key = p.PartId;
            //     obj.title = p.PartName;
            //     obj.children = p.Dealers.chain().map(d => {
            //         let childObj = {};
            //         childObj.value = d.DealerCode;
            //         childObj.key = d.DealerCode;
            //         childObj.title = d.DealerName;
            //         return childObj;
            //     }).value();
            //     return obj;
            // }).value();
            // this.DefaultTreeData.push(
            //     this.treeData[0].key,
            // );

            let res = await this.$Http.GetDealersTreeByRole();
            if (!res.Data) {
                this.$message.error(res.Message);
                return;
            }
            this.dealers = [{
                value: "",
                label: "全国",
                children: res.Data,
            }];
        },

    },
};
</script>

<style scoped>

</style>
