<template>
  <a-card class="card">
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 6 }"
    >
      <a-form-model-item label="省份名称" prop="provinceName">
        <a-input v-model="form.provinceName" />
      </a-form-model-item>
      <a-form-model-item label="城市名称" prop="cityName">
        <a-input v-model="form.cityName" />
      </a-form-model-item>
      <a-form-model-item label="小区名称" prop="areaId">
        <a-select v-model="form.areaId">
          <a-select-option
            v-for="(item, i) in areaList"
            :key="i"
            :value="item.AreaId"
          >
            {{ item.AreaName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" @click="save"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: {
        areaId: "",
        cityName: "",
        provinceName: "",
        cityId: "",
      },
      rules: {
        provinceName: [
          {
            required: true,
            message: "请输入省份名称",
            trigger: ["blur", "change"],
          },
        ],
        cityName: [
          {
            required: true,
            message: "请输入城市名称",
            trigger: ["blur", "change"],
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择小区名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      areaList: [],
    };
  },
  methods: {
    async init() {
      let self = this;
      //编辑
      if (self.$route.path == "/cityMannage/edit") {
        //获取当前数据
        let res = await self.$Http.GetCityInfoById({
          cityId: self.$route.query.cityId,
        });
        if (res.Data.length > 0) {
          self.form.provinceName = res.Data[0].ProvinceName;
          self.form.cityName = res.Data[0].CityName;
          self.form.areaId = res.Data[0].AreaId;
          self.form.cityId = res.Data[0].CityId;
        }
      } else {
        //判断是否有partId
        if (typeof self.$route.query.areaId != "undefined") {
          self.form.areaId = self.$route.query.areaId;
        }
      }
    },
    async save() {
      let self = this;
      self.$refs.form.validate(async (valid) => {
        if (valid) {
          //新增or编辑
          let res =
            self.$route.path == "/cityMannage/edit"
              ? await self.$Http.EditCity(self.form)
              : await self.$Http.InsertCity(self.form);
          if (res.Success) {
            self.$message.success(res.Data);
            self.$router.push("/cityMannage/list");
          } else {
            self.$message.error(res.Message);
          }
        }
      });
    },
    //获取大区列表
    async getAreaList() {
      let self = this;
      let res = await self.$Http.GetArea();
      self.areaList = res.Data;
    },
  },
  mounted() {
    this.init();
    this.getAreaList();
  },
};
</script>