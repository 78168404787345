const MSG_MANNAGE_API = {
    //站内信列表
    GetMsgListByPage: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetMsgListByPage'
    },
    //查看已推送人员
    ViewMsgUsers: {
        method: 'get',
        url: '/BasicSetting/BasicSet/ViewMsgUsers'
    },
    //创建站内信
    AddMsg: {
        method: 'post',
        url: '/BasicSetting/BasicSet/AddMsg'
    },
    //编辑站内信
    EditMsg: {
        method: 'post',
        url: '/BasicSetting/BasicSet/EditMsg'
    },
    //手动推送
    PushMsg: {
        method: 'get',
        url: '/BasicSetting/BasicSet/PushMsg'
    },
    // 删除站内信
    DeleteMsg: {
        method: 'get',
        url: '/BasicSetting/BasicSet/DeleteMsg'
    },
    //获取权限列表
    GetPermission: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetPermissionListByPage'
    },
    //获取未读消息个数
    GetMsgNoRead: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetMsgNoRead'
    },
    //用户查看自己消息
    GetMsgByUserId: {
        method: 'get',
        url: '/BasicSetting/BasicSet/GetMsgByUserId'
    },
    //更新已读消息状态
    UpdateReadMsg: {
        method: 'get',
        url: '/BasicSetting/BasicSet/UpdateReadMsg'
    }
}
export default MSG_MANNAGE_API
