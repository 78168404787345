import { simpleFixed } from "@/until/simpleFixed.ts";

const ListKpiCode =
    [
      "B0302001", "B0106026", "B0302037", "B0106027", "B0104226", "B0103097", "B0103083", "B0103081", "B0103087", "B0103082", "B0103047",
      "B0103007", "B0103099", "B0303071", "B0303025", "B0303026", "B0303072", "B0303006", "B0304003", "B0303050", "B0304012", "B0303024",
      "C0408004", "C0408020", "C0406004", "C0406012", "C0407001", "C0206001", "C0208001", "A0302001", "C0409001", "A0104090", "C0409002",
      "B0107002", "C0106006", "C0107002", "C0107003", "C0107006", "A0302021", "A0302022", "A0302023", "A0302024", "A0402006", "A0402007",
      "A0402008", "A0402009", "A0402010", "A0107003", "A0105072", "A1303024", "A1303028", "A1303032", "A1303043", "A1303081", "A1303082",
      "A0303273", "A0303272", "A0303275", "A0303311", "A0303312", "A0303313", "A0303314", "A0303315", "A0303316", "A0303317", "A0303318",
      "A0303319", "A0303320", "A0303321", "A0303322", "A0303323", "A0303324", "A0304004", "A0304005", "A0304006", "A0304007", "A0304008",
      "A0304009", "A0304010", "A0304011", "A0304012", "A0304013", "A0304014", "A0304015", "A0304016", "A0304017", "A0304036", "B0103097",
      "A0304037", "A0304038", "A0304039", "A0304040", "A0304041", "A0304042", "A0304043", "A0304044", "A0304045", "A0304046", "A0304047",
      "A0304048", "A0304049", "A0304056", "A0304057", "A0304058", "A0304059", "A0304060", "A0304061", "A0304062", "A0304063", "A0304064",
      "A0304065", "A0304066", "A0304067", "A0304068", "A0304069", "A0304076", "A0304077", "A0304078", "A0304079", "A0304080", "A0304081",
      "A0304082", "A0304083", "A0304084", "A0304085", "A0304086", "A0304087", "A0304088", "A0304089", "A0304096", "A0304097", "A0304098",
      "A0304099", "A0304100", "A0304101", "A0304102", "A0304103", "A0304104", "A0304105", "A0304106", "A0304107", "A0304108", "A0304109",
      "A0402013", "A0402019", "A0402025", "A0402031", "A0402037", "A0402043", "A0402049", "A0402055", "A0402061", "A0402116", "A0402122",
      "A0402128", "A0402134", "A0402148", "A0402014", "A0402015", "A0402020", "A0402021", "A0402026", "A0402027", "A0402032", "A0402033",
      "A0402038", "A0402039", "A0402044", "A0402045", "A0402050", "A0402051", "A0402056", "A0402057", "A0402062", "A0402063", "A0402117",
      "A0402118", "A0402123", "A0402124", "A0402129", "A0402130", "A0402135", "A0402136", "A0402149", "A0402150", "A0402016", "A0402022",
      "A0402028", "A0402034", "A0402040", "A0402046", "A0402052", "A0402058", "A0402064", "A0402119", "A0402125", "A0402131", "A0402137",
      "A0402171", "A0107004", "A0105051", "A0105052", "A0105053", "A0105054", "A0105055", "A0105056", "A0105057", "A0105058", "A0105059",
      "A0105060", "A0105061", "A0105062", "A0105063", "A0105064", "A0104051", "A0104052", "A0104053", "A0104054", "A0104055", "A0104056",
      "A0104057", "A0104058", "A0104059", "A0104060", "A0104061", "A0104062", "A0104063", "A0104064", "A0104351", "A0104352", "A0104353",
      "A0104354", "A0104355", "A0104356", "A0104357", "A0104358", "A0104359", "A0104360", "A0104361", "A0104362", "A0104363", "A0104364",
      "A1302115", "A1302019", "A1302028", "B0104202", "C0404001", "A0106024", "B0105113", "C0405004", "C0203020", "C0205006", "C0205010",
      "C0205005", "C0205021", "A1302032", "A1302012", "A1302013", "A0103001", "A0106001", "B0104282", "B0303051", "B0105114", "B0104321",
      "B0103047", "B0104204", "B0104215", "B0304014", "A0303271", "A0303274", "A0303276", "B0302004", "B0302005", "B0303019", "B0302035",
      "B0302036", "B0104227", "A0303325", "A0303326", "A0304018", "A0304019", "A0304050", "A0304051", "A0304070", "A0304071", "A0304090",
      "A0304091", "A0304110", "A0304111", "A0105065", "A0104365", "A0105066", "A0104366", "A0402172", "A0402173", "A0402174+A0402175", "A0402176",
      "A0402178", "A0402179", "A0402180+A0402181", "A0402182", "A0303100", "A0303120", "A0303140", "A0303160", "A0303101", "A0303121", "A0303141", "A0303161",
      "A0303102", "A0303122", "A0303142", "A0303162", "A0303103", "A0303123", "A0303143", "A0303163", "A0303104", "A0303124", "A0303144", "A0303164", "A0303105", "A0303125", "A0303145", "A0303165",
      "A0303106", "A0303126", "A0303146", "A0303166", "A0303107", "A0303127", "A0303147", "A0303167", "A0303108", "A0303128", "A0303148", "A0303168", "A0303109", "A0303129", "A0303149", "A0303169",
      "A0303110", "A0303130", "A0303150", "A0303170", "A0303111", "A0303131", "A0303151", "A0303171", "A0303112", "A0303132", "A0303152", "A0303172", "A0303113", "A0303133", "A0303153", "A0303173",
      "A0303114", "A0303134", "A0303154", "A0303174", "A0303115", "A0303135", "A0303155", "A0303175", "A0302140", "A0302160", "A0302180", "A0302200", "A0302141", "A0302161", "A0302181", "A0302201", "A0302142", "A0302162", "A0302182", "A0302202",
      "A0302143", "A0302163", "A0302183", "A0302203", "A0302144", "A0302164", "A0302184", "A0302204", "A0302145", "A0302165", "A0302185", "A0302205",
      "A0302146", "A0302166", "A0302186", "A0302206", "A0302147", "A0302167", "A0302187", "A0302207", "A0302148", "A0302168", "A0302188", "A0302208",
      "A0302149", "A0302169", "A0302189", "A0302209", "A0302150", "A0302170", "A0302190", "A0302210", "A0302151", "A0302171", "A0302191", "A0302211",
      "A0302152", "A0302172", "A0302192", "A0302212", "A0302153", "A0302173", "A0302193", "A0302213", "A0302154", "A0302174", "A0302194", "A0302214", "A0302155", "A0302175", "A0302195", "A0302215", "A0404002", "A1303071", "A1303461",
      "A1303462", "A1303463", "A1303464", "A1303465", "A1303466", "A1303467", "A1303468", "A1303469", "A1303470", "A1303471", "A1303472",
      "A1303473", "A1303474", "A1303475", "A1303476", "A1303461", "A1303462", "A1303463", "A1303464",
      "A1303465", "A1303466", "A1303467", "A1303468", "A1303469", "A1303470", "A0104621", "A0104622", "A0104623", "A0104624", "A0104625", "A0104626", "A0104627", "A0104628", "A0104629", "A0104630", "A0104631",
      "A0105141", "A0105142", "A0105143", "A0105144", "A0105145", "A0105146", "A0105147", "A0105148", "A0105149", "A0105150", "A0105151",
      "A1303471", "A1303472", "A1303473", "A1303474", "A1303475", "A1303476", "A0106026", "A0105071","A0105072",
      "A1402205", "A1402191", "A1402192", "A1402193", "A1402194", "A1402195", "A1402196", "A1402197", "A1402198", "A1402199", "A1402200", "A1402201", "A1402202", "A1402203", "A1402204", "A1402206", "A1402207",
      "A1402235", "A1402221", "A1402222", "A1402223", "A1402224", "A1402225", "A1402226", "A1402227", "A1402228", "A1402229", "A1402230", "A1402231", "A1402232", "A1402233", "A1402234", "A1402236", "A1402237",
    ];

function GetGauge(val) {
  //   return Number(val ?? 0).toFixed(1).toString();
  return simpleFixed(val ?? 0, 1);
}

function GetThreeGauge(val) {
  //  return Number(val ?? 0).toFixed(3);
  return simpleFixed(val ?? 0, 3);

}

function PercentageCode(val) {
  //  return Number(Number(Number(val ?? 0) * 100).toFixed(2)).toFixed(1);
  return simpleFixed((val ?? 0) * 100, 1);
}

export {
  ListKpiCode, PercentageCode,
  GetGauge, GetThreeGauge,
};
