<template>
  <section>
    <a-card>
      <div class="sea ">
        <!--        <span style="padding-left: 36px">区域：</span>
                <a-select style="width: 120px" placeholder="请选择">
                  <a-select-option value="1">
                    选项一
                  </a-select-option>
                  <a-select-option value="2">
                    选项二
                  </a-select-option>
                  <a-select-option value="3">
                    选项三
                  </a-select-option>
                </a-select>-->
        <span style="padding-left: 36px">经销商名称：</span>
        <!--        <a-select style="width: 120px" placeholder="请选择">
                  <a-select-option value="1">
                    选项一
                  </a-select-option>
                  <a-select-option value="2">
                    选项二
                  </a-select-option>
                  <a-select-option value="3">
                    选项三
                  </a-select-option>
                </a-select>-->
        <a-input placeholder="请输入经销商名称" style="width: 200px"/>
        <a-button type="primary" style="margin-right: 20px;margin-left: 50px;">
          搜索
        </a-button>
        <a-button type="default" @click="()=>{
      this.search.dateRange = [];
      this.search.groupOption = {key: 0, label: '--请选择--'};
    }"
        >
          重置
        </a-button>
      </div>
    </a-card>
    <a-card style="margin-top: 8px">
      <a-table :columns="columns" :data-source="data" :rowKey="(record, index) => index">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="tags" slot-scope="tags">
         <a-badge :color="tagsColor(tags)"/>
          {{ tags }}
        </span>
        <span slot="action" slot-scope="text, record">
      <a>编辑 </a>
      <a-divider type="vertical"/>
      <a>删除</a>
     </span>
      </a-table>

    </a-card>
    <div class="foot-pad30"></div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '60px',
          customRender:(text,record,index)=>`${index+1}`,
        },
        {
          title: "区域",
          dataIndex: "AreaName",
          key: "AreaName",
        },
        {
          title: "经销商名称",
          dataIndex: "DealerName",
          key: "DealerName",
        },
        {
          title: "上传次数",
          dataIndex: "FaileCount",
          key: "FaileCount",
        },
        {
          title: "审核失败次数",
          dataIndex: "UploadNum",
          key: "UploadNum",
        },
        {
          title: "一次性通过率",
          dataIndex: "PassPercent",
          key: "PassPercent",
          //  scopedSlots: { customRender: "action" },
        },
      ],
      data: [

        {
          key: "1",
          name: "山西太原店张三",
          age: "山西太原店_202012.xlsx",
          address: "3",
          address1: "待经理审核",
          tags: "校验失败",
        },
        {
          key: "2",
          name: "山西太原店张三",
          age: "山西太原店_202012.xlsx",
          address1: "3",
          tags: "校验成功",
        },
        {
          key: "3",
          name: "山西太原店张三",
          age: "山西太原店_202012.xlsx",
          address1: "待经理审核",
          tags: "未校验",
        },
      ],
    };
  },
  created () {
    this.getData();
  },
  methods: {
    async getData () {
      let fromData = {
        DealerCode: "whcj",
        yearMonth: "2020-12-01",
        /* validateStatus:0,
         auditStatus:0,*/
      };
      let res = await this.$Http.GetPassPercent(fromData);
      if (res.Success) {
        this.data = res.Data;
      } else {
        this.$message.error("错误");
      }

    },
    onDataChange () {
     // console.log("on");
    },
    tagsColor (tags) {
      switch (tags) {
        case "校验成功":
          return "#5AAAFF";
        case "校验失败":
          return "#FD595A";
        default:
          return "#856AFF";
      }
    },
  },
};
</script>

<style lang="less" scoped>
// @import url('./dataList.less');
.top {
  margin-bottom: 8px;
  background: #E6F7FF;
  height: 38px;
  line-height: 38px;
  padding: 0 24px;
  border: 1px solid #BAE7FF;

  span {
    padding-right: 36px;
    color: #444444;

    b {
      color: #24619e;
      font-weight: 500;
      text-align: center;
    }
  }

  .sea {
    span {
      padding-left: 36px;
      color: red;
    }
  }


}

</style>
