const lineStyle = {
    width: 3,
};
const symbolSize = 10;
const itemStyle = {
    // backgroundColor: "#000",
    //  borderColor: "#000",
    // color: "#000",
};
const grid = {
    bottom: "15px",
    containLabel: true,
};
export { lineStyle, symbolSize, itemStyle, grid };
