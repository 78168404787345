import improvePlanListFactory from "../views/ImprovePlan/factory/list"
import improvePlanListDealer from "../views/ImprovePlan/dealer/list"
import improvePlanForm from "../views/ImprovePlan/form"
import createImprovePlan from "../views/ImprovePlan/createImprovePlan"
import improveView from "../views/ImprovePlan/improveView"
import viewImproveView from "../views/ImprovePlan/viewImproveView"
import caseBook from "../views/ImprovePlan/CaseBook"

let improvePlanRouter = [
    //案例集
    {
        path: '/improvePlan/casebook',
        component: caseBook
    },
    //厂端列表
    {
        path: "/improvePlan/factory/list",
        component: improvePlanListFactory,
    },
    //店端列表
    {
        path: "/improvePlan/dealer/list",
        component: improvePlanListDealer,
    },
    //创建弱项改善
    {
        path: "/improvePlan/createImprovePlan",
        component: createImprovePlan,
    },
    //改善计划行动创建
    {
        path: "/improvePlan/form",
        component: improvePlanForm,
    },
    //编辑改善计划行动
    {
        path: "/improvePlan/editImproveView",
        component: improveView,
    },
    //查看改善计划行动
    {
        path: "/improvePlan/viewImproveView",
        component: viewImproveView,
    }
]

export default improvePlanRouter