<template>
  <section>
    <a-card>
      选择月份：
      <a-month-picker style="margin-right: 20px"
                      format="YYYY-MM" placeholder="请选择月份"
                      @change="value => search.reportDate = value"/>
      <a-button type="primary" style="margin-right: 20px" @click="getReport">
        搜索
      </a-button>
    </a-card>
    <a-card title="分析报告" style="margin-top: 8px;margin-bottom: 18px;">
      <a-tabs>
        <a-tab-pane v-for="sheet in sheets" :key="sheet" :tab="sheet">
          <a-table :rowKey="(record, index) => index" :columns="tableColumns" :data-source="sheetsData[sheet]"
                   :pagination="false" bordered :scroll="{ x: 'max-content',y: false}">
            <span slot="valueOutput" slot-scope="value, record">
              {{ formatValue(value, record) }}
            </span>
            <span slot="nameOutput" slot-scope="name" v-html="name">
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <div v-show="isTop" @click="backtop" class="ant-back-top">
      <div class=""
           style="height: 40px; width: 40px; line-height: 40px; border-radius: 4px; background-color: #24619e; color: rgb(255, 255, 255); text-align: center; font-size: 20px;">
        <a-icon type="vertical-align-top"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isTop: false,
      sheets: ["自主", "合资", "豪华"],
      sheetsData: {},
      lastKpiType: "",
      tableColumns: [
        {
          title: "序号",
          dataIndex: "Sort",
          key: "Sort",
          width: 80,
        }, {
          title: "指标分类",
          dataIndex: "KpiType",
          key: "KpiType",
          scopedSlots: {customRender: "nameOutput"},
          customRender: (value, record) => {
            return {
              children: value,
              attrs: { rowSpan: record.KpiTypeRowSpan },
            };
          },
        }, {
          title: "指标名称",
          dataIndex: "KpiName",
          key: "KpiName",
          scopedSlots: {customRender: "nameOutput"},
        }, {
          title: "单位",
          dataIndex: "Unit",
          key: "Unit",
        }, {
          title: "均值",
          dataIndex: "Avg",
          key: "Avg",
        }, {
          title: "中位值",
          dataIndex: "Median",
          key: "Median",
        }, {
          title: "标杆值",
          dataIndex: "Standard",
          key: "Standard",
        }
      ],
      activeTypeId: 0,
      search: {
        reportDate: null
      },
    };
  },
  created() {
    window.addEventListener("scroll", this.showBtn, true);
  },
  methods: {
    // 显示回到顶部按钮
    showBtn() {
      let that = this;
      that.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.isTop = that.scrollTop > 200;
    },
    backtop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    paramsIsError() {
      if (this.search.reportDate == null) {
        this.$message.warn("请选择月份");
        return true;
      }
      return false;
    },
    async getReport() {
      this.tableData = [];
      if (this.paramsIsError()) {
        return;
      }
      let res = await this.$Http.getIndustryAnalyzeReport({
        reportDate: this.search.reportDate.format("YYYY-MM"),
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.sheets.forEach(sheet => {
        res.Data [sheet].forEach(record => {
          record.KpiName = record.KpiName.replaceAll(' ', '&nbsp;');
        });
      })
      this.sheetsData = res.Data;
    },
  },
};
</script>
<style lang="less">
.ant-back-top {
  bottom: 18px !important;
  right: 46px !important;
}
</style>
