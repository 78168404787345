<template>
  <section>
    <div style="background: #ffffff">
      <a-row>
        <a-col :span="24" class="tabs">
          <a-row>
            <a-col :span="8">
              <span @click="tabChange(0)" :class="{active:MsgType===0}">
                <a>系统消息<b v-show="IsSetingMsg">•</b></a>
              </span>
              <span @click="tabChange(1)" :class="{active:MsgType===1}">
                <a>填报消息<b v-show="IsReportMsg">•</b></a>
              </span>
            </a-col>
            <a-col :span="8" :offset="8" style="text-align: right">
              <a-select @change="handleChange" default-value="全部">
                <a-select-option value="全部" key=""> 全部消息</a-select-option>
                <a-select-option value="已读" key="1"> 已读消息</a-select-option>
                <a-select-option value="未读" key="0"> 未读消息</a-select-option>
              </a-select>
              <div v-if="isPermission" class="setting" @click="$router.push('/msgMannage/list')">
                <a-icon type="setting"/>
                设置
              </div>
            </a-col>
          </a-row>


        </a-col>
        <a-col v-show="MsgType===0" :span="24" style="padding:30px 20px;">
          <ul v-if="systemMsg.length > 0">
            <li class="msgInfo" v-for="i in systemMsg" :key="i.ID">
              <p class="time">
                <a-icon type="clock-circle"/>
                {{ i.CreateTime }} 来自 <b>{{ i.Creator }}</b> 的温馨提示
                <a-tag v-if="i.IsRead!==1" @click="SetMsgRead(i.MsgUserId)">设置为已读</a-tag>
              </p>
              <div class="msgMain">
                <p class="title">
                  <a-badge :dot="i.IsRead!==1">
                    <a href="#" class="head-example"></a>
                  </a-badge>
                  {{ i.MsgTitle }}
                </p>
                <p class="content">{{ i.MsgContent }}</p>
              </div>
              <div class="float-right">
                {{ i.IsRead === 1 ? "已读" : "未读" }}
              </div>
            </li>
          </ul>
          <p class="no-data" v-else>暂无数据！</p>
        </a-col>
        <a-col v-show="MsgType===1" :span="24" style="padding:30px 20px;">
          <ul v-if="reportMsg.length > 0">
            <li class="msgInfo" v-for="i in reportMsg" :key="i.ID">
              <p class="time">
                <a-icon type="clock-circle"/>
                {{ i.CreateTime }} 来自 <b>{{ i.Creator }}</b> 的温馨提示
                <a-tag v-if="i.IsRead!==1" @click="SetMsgRead(i.MsgUserId)">设置为已读</a-tag>
              </p>
              <div class="msgMain">
                <p class="title">
                  <a-badge :dot="i.IsRead!==1">
                    <a href="#" class="head-example"></a>
                  </a-badge>
                  {{ i.MsgTitle }}
                </p>
                <p class="content">{{ i.MsgContent }}</p>
              </div>
              <div class="float-right">
                {{ i.IsRead === 1 ? "已读" : "未读" }}
              </div>
            </li>
          </ul>
        </a-col>

      </a-row>
    </div>
    <div>
      <a-back-top>
        <div class="back-top">
          <a-icon class="back-top-icon" type="vertical-align-top"/>
        </div>
      </a-back-top>
    </div>
  </section>
</template>
<script>
import { msgTypeEnum } from "@/enum/enum.js";

export default {
  data() {
    return {
      systemMsg: [],
      reportMsg: [],
      MsgType: "",
      msgTypeEnum: msgTypeEnum,
      IsSetingMsg: false,
      IsReportMsg: false,
      isPermission: false,
    };
  },
  created() {
    this.getPermission();
    this.getSystemMsg("");
  },
  methods: {
    getPermission() {
      const Permission = window.localStorage.getItem("permission");
      if(Permission.indexOf("站内信管理") > 0) this.isPermission = true;
    },
    async doRead(msgUserID, msgType) {
      let self = this;
      let res = await self.$Http.UpdateReadMsg({ MsgUserId: msgUserID });
      if(res.Success) {
        switch (msgType) {
        case msgTypeEnum.systemMsg:
          self.getSystemMsg("");
          break;
        case msgTypeEnum.reportMsg:
          self.getReportMsg("");
          break;
        default:
          break;
        }
        self.$message.success(res.Data);
      } else {
        self.$message.error(res.Message);
      }
    },
    async getSystemMsg(val) {
      let self = this;
      this.MsgType = msgTypeEnum.systemMsg;
      let res = await self.$Http.GetMsgByUserId({
        MsgType: msgTypeEnum.systemMsg,
        IsRead: val,
      });
      self.systemMsg = res.Data;
      self.systemMsg.forEach(item => {
        if(item.IsRead === 0) {
          this.IsSetingMsg = true;
        }
      });
    },
    async getReportMsg(val) {
      let self = this;
      this.MsgType = msgTypeEnum.reportMsg;
      let res = await self.$Http.GetMsgByUserId({
        MsgType: msgTypeEnum.reportMsg,
        IsRead: val,
      });
      self.reportMsg = res.Data;
      self.reportMsg.forEach(item => {
        if(item.IsRead === 0) {
          this.IsSetingMsg = true;
        }
      });
    },
    tabChange(activeKey) {
      let self = this;
      switch (activeKey) {
      case msgTypeEnum.systemMsg:
        this.getSystemMsg("");
        break;
      case msgTypeEnum.reportMsg:
        this.getReportMsg("");
        break;
      default:
        break;
      }
    },
    handleChange(value) {
      let val = "";
      switch (value) {
      case "已读":
        val = "1";
        break;
      case "未读":
        val = "0";
        break;
      default:
        val = "";
      }
      if(this.MsgType === 0) {
        this.getSystemMsg(val);
      } else {
        this.getReportMsg(val);
      }
    },
    async SetMsgRead(ID) {
      //设置已读
      let res = await this.$Http.UpdateReadMsg({
        MsgUserId: ID,
      });
      this.handleChange("");

    },
  },
  filters: {},
};
</script>
<style lang="less" scoped>
.ant-badge {
  bottom: 10px !important;
  }

.msgInfo {
  overflow: hidden;

  .time {
    color: #bfbfbf;

    b {
      color: rgba(215, 0, 6, .6);
      font-weight: 400
      }
    }

  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, .8);

    .head-example {
      top: -10px
      }
    }

  .content {
    color: #acacac;
    }

  .msgMain {
    padding-left: 20px;
    }

  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  }


.no-data {
  text-align: center;
  font-size: 15px;
  }

.setting {
  float: right;
  margin-left: 30px;
  cursor: pointer;
  }

.tabs {
  background: #FAFAFA;
  height: 54px;
  line-height: 54px;
  overflow: hidden;
  padding-right: 20px;

  span {
    padding: 10px 30px;
    margin: 0 10px;
    cursor: pointer;

    &:first-child {
      border-right: 1px solid #DDDDDD;
      }

    a {
      color: #000000;

      b {
        color: #24619e;
        font-size: 30px;
        }
      }
    }

  .active a {
    padding: 10px 0;
    border-bottom: 1px solid #24619e;
    }

  }

</style>
