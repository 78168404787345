<template>
    <a-row :gutter="[18,18]" class="mHeight">
        <a-col :span="8">
            <div class="con_title">一、经营业绩</div>
            <div class="borderQ">
                <a-row :gutter="[18,0]" style="overflow:hidden;position: relative;top:-2px;">
                    <a-col :span="6" class="one_left_1" v-for="(item,i) in descriptionsItem.data" :key="i++">
                        <div class="">{{ item.name }}: <b> {{ item.one ? item.one : 0 }} 台</b></div>
                        <div class="">
                            <p>区域排名： 第 {{ item.two ? item.two : 0 }} 名</p>
                            <p>全国排名： 第 {{ item.there ? item.there : 0 }} 名</p>
                        </div>
                    </a-col>
                    <a-col :span="24" class="margin10">
                            <a-my-table :columns-data="tableData1.columns" :table-data="tableData1"></a-my-table>
                    </a-col>
                </a-row>
            </div>
            <a-row :gutter="[18,0]">
                <a-col :span="24" class="margin10">
                        <a-my-table style="overflow:hidden;position: relative;top:-2px;" :columns-data="tableData2.columns" :table-data="tableData2"></a-my-table>
                </a-col>
            </a-row>
            <!-- chart      -->
            <a-col :span="24" class="margin10">
                <div class="con_title ">主营业务税前利润及利润率</div>
                <chartComponent class="borderF" style="padding-top: 0" :chart-data="chartData.chartData1" :chart-height="'630px'"></chartComponent>
            </a-col>
            <a-col :span="24" class="margin10">
                <div class="con_title ">总毛利及毛利率</div>
                <chartComponent class="borderF" style="padding-top: 0" :chart-data="chartData.chartData2" :chart-height="'630px'"></chartComponent>
            </a-col>
        </a-col>
        <!--三大能力    -->
        <a-col :span="8">
            <div class="con_title">二、三大能力</div>
            <a-row :gutter="[18,0]">
                <a-col :span="24">
                        <aCustomTable :columns-data="tableData3.columns" :table-data="tableData3"></aCustomTable>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">零服吸收率及前后端产值比</div>
                    <chartComponent class="borderF" :chart-data="chartData.chartData3" :chart-height="'580px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">三、费用管控</div>
                        <aCustomTable class="borderF"  style="padding-top: 0;height: 1340px;"  :columns-data="tableData4.columns" :table-data="tableData4"></aCustomTable>
                </a-col>
            </a-row>
        </a-col>
        <!--  总费用及费用率  -->
        <a-col :span="8">
            <div class="con_title">总费用及费用率</div>
            <a-row :gutter="[18,0]">
                <a-col :span="24">
                    <chartComponent class="borderF" :chart-data="chartData.chartData4" :chart-height="'650px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">人力费用及人力费用率</div>
                    <chartComponent class="borderF" :chart-data="chartData.chartData5" :chart-height="'580px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">销售部人力费用、人力费用率</div>
                    <chartComponent class="borderF" :chart-data="chartData.chartData6" :chart-height="'600px'"></chartComponent>
                </a-col>
                <a-col :span="24" class="margin10">
                    <div class="con_title">销售部人均销量、人均生产力</div>
                    <chartComponent class="borderF" :chart-data="chartData.chartData7" :chart-height="'600px'"></chartComponent>
                </a-col>
            </a-row>
        </a-col>
    </a-row>
</template>

<script>
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import aCustomTable from "@/views/monthReport/shopAnalysis/component/aCustomTable";
import chartComponent from "@/components/chartComponent";
import {
  barMaxWidth,
  monthGetData,
  mouth12Chart,
  tableIndex,
} from "@/views/monthReport/shopAnalysis/component/kpiCode";
import {
  oneData,
  fontSize,
  lineStyle,
  symbolSize, textStyle,
  itemStyle, PrintyAxis, PrintXAxis,
} from "@/views/monthReport/shopAnalysis/component/myData";
import { percentage, tenThousandYuan, ThousandYuan, YtenThousandYuan } from "@/until/option";

export default {
  name: "oneOverview",
  components: { aMyTable, aCustomTable, chartComponent },
  props: {
    allData: { type: Object },
    dataParams: {},
  },
  data() {
    return {
      params: {},
      monthRank: {},
      monthPush: {},
      rich: {},
      monthStandardPush: {},
      yearRank: {},
      descriptionsItem: {
        kpiCodes: ["A1402155", "A0302001", "A1402155", "A0302001"],
        data: [],
      },
      columnsC: [
        {
          key: "name", title: "指标名称", dataIndex: "name", width: 260,
          customRender: (text, record) => {
            if(record.indentation > 0) {
              return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
            }
            return text;
          },
        },
        { key: "Unit", title: "单位", dataIndex: "Unit" },
        {
          key: "Current", title: "当月值", dataIndex: "Current",
          customRender: (text, record) => {
            let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
            if(record.PercentileSort === "DESC") flag = !flag;
            if(flag) {
              return <div style="color:red;">{text} ↓ </div>;
            } else {
              return <div style="color:green;">{text} ↑</div>;
            }
          },
        },
        { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
        { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
        { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
        { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
        { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
        { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
      ],
      tableData1: {
        table: [
          { name: "总收入", kpiCode: "C0406004", ut: "1" },
          { name: "总成本（含返利）", kpiCode: "C0406012", ut: "1" },
          { name: "总毛利", kpiCode: "C0407001", ut: "1" },
          { name: "总费用", kpiCode: "C0206001", ut: "1" },
          { name: "主营业务税前利润", kpiCode: "C0408004", ut: "1" },
          { name: "净利润", kpiCode: "C0408002", ut: "1" },
        ],
        columns: [],
        data: [],
      },
      tableData2: oneData.tableData2,
      tableData3: {
        table: oneData.tableData3.table,
        columns: [
          {
            key: "iFiction",
            title: "指标分类",
            customRender: (value, row, index) => {
              let obj = {
                children: "",
                attrs: {},
              };
              switch (index) {
              case 0:
                obj = {
                  children: "盈利能力",
                  attrs: { rowSpan: 2 },
                };
                break;
              case 2:
                obj = {
                  children: "运营能力",
                  attrs: { rowSpan: 3 },
                };
                break;
              case 5:
                obj = {
                  children: "偿债能力",
                  attrs: { rowSpan: 3 },
                };
                break;
              default:
                obj.attrs.colSpan = 0;

              }
              return obj;
            },
          },
          {
            key: "name", title: "指标名称", dataIndex: "name",
            customRender: (text, record) => {
              if(record.indentation > 0) {
                return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
              }
              return text;
            },
          },
          { key: "Unit", title: "单位", dataIndex: "Unit" },
          {
            key: "Current", title: "当月值", dataIndex: "Current",
            customRender: (text, record) => {
              let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
              if(record.PercentileSort === "DESC") flag = !flag;
              if(flag) {
                return <div style="color:red;">{text} ↓ </div>;
              } else {
                return <div style="color:green;">{text} ↑</div>;
              }
            },
          },
          { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
          { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
          { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
          { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
          { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
          { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
        ],
        data: [],
      },
      tableData4: {
        table: oneData.tableData4.table,
        columns: [
          {
            key: "iFiction",
            title: "指标分类", width: 110,
            customRender: (value, row, index) => {
              let obj = {
                children: "",
                attrs: {},
              };
              switch (index) {
              case 0:
                obj = {
                  children: "费用结构",
                  attrs: { rowSpan: 7 },
                };
                break;
              case 7:
                obj = {
                  children: "单车费用",
                  attrs: { rowSpan: 10 },
                };
                break;
              default:
                obj.attrs.colSpan = 0;

              }
              return obj;
            },
          },
          {
            key: "name", title: "指标名称", dataIndex: "name", width: 160,
            customRender: (text, record) => {
              if(record.indentation > 0) {
                return <div style={"paddingLeft:" + record.indentation + "em"}>{text}</div>;
              }
              return text;
            },
          },
          { key: "Unit", title: "单位", dataIndex: "Unit" },
          {
            key: "Current", title: "当月值", dataIndex: "Current",
            customRender: (text, record) => {
              let flag = parseFloat(record.Current) < parseFloat(record.NationwideStandard);
              if(record.PercentileSort === "DESC") flag = !flag;
              if(flag) {
                return <div style="color:red;">{text} ↓ </div>;
              } else {
                return <div style="color:green;">{text} ↑</div>;
              }
            },
          },
          { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
          { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
          { key: "RegionalStandard", title: "区域标杆", dataIndex: "RegionalStandard" },
          { key: "NationwideStandard", title: "全国标杆", dataIndex: "NationwideStandard" },
          { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
          { key: "CurrentYear", title: "2021年累计", dataIndex: "CurrentYear" },
        ],
        data: [],
      },
      chartData: {
        chartData1: {
          columns: ["日期", "主营业务税前利润", "主营业务税前利润率"],
          kpiCode: [
            { Code: "C0408004", Name: "主营业务税前利润" },
            { Code: "C0408021", Name: "主营业务税前利润率" },
          ],
          rows: oneData.chartData.chartData1.rows,
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["主营业务税前利润率"],
            axisSite: { right: ["主营业务税前利润率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: PrintyAxis,
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value / 10000).toFixed(2);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideLeft",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData2: {
          columns: ["日期", "总毛利", "毛利率"],
          kpiCode: [
            { Code: "C0407001", Name: "总毛利" },
            { Code: "C0407004", Name: "毛利率" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["毛利率"],
            axisSite: { right: ["毛利率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: PrintyAxis,
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return tenThousandYuan(params.data);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                formatter: function(params) {
                  return percentage(params.data);
                },
              },
            ],
          },
        },
        chartData3: {
          columns: ["日期", "前后端产值比", "零服吸收率"],
          kpiCode: [
            { Code: "C0305011", Name: "前后端产值比" },
            { Code: "B0107002", Name: "零服吸收率" },
          ],
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["零服吸收率"],
            axisSite: { right: ["零服吸收率"] },
            yAxisType: ["0.[0]", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "倍",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(1);
                  },
                },
              },
              {
                type: "value",
                name: "%",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d * 100).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return (params.value).toFixed(0);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "insideLeft",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData4: {
          columns: oneData.chartData.chartData4.columns,
          kpiCode: oneData.chartData.chartData4.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["总费用率"],
            axisSite: { right: ["总费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: PrintyAxis,
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return tenThousandYuan(params.data);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "top",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData5: {
          columns: oneData.chartData.chartData5.columns,
          kpiCode: oneData.chartData.chartData5.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人力费用率"],
            axisSite: { right: ["人力费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: PrintyAxis,
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return tenThousandYuan(params.data);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "top",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData6: {
          columns: oneData.chartData.chartData6.columns,
          kpiCode: oneData.chartData.chartData6.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人力费用率"],
            axisSite: { right: ["人力费用率"] },
            yAxisType: ["0.[00]", "0.[0]%"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: PrintyAxis,
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return tenThousandYuan(params.data);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "top",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return percentage(params.data);
                  },
                },
              },
            ],
          },
        },
        chartData7: {
          columns: oneData.chartData.chartData7.columns,
          kpiCode: oneData.chartData.chartData7.kpiCode,
          rows: [],
          settings: {
            type: "histogram",
            // stack: { all: ["访问用户", "下单用户"] },
            showLine: ["人均生产力"],
            axisSite: { right: ["人均生产力"] },
            yAxisType: ["0", "0"],
          },
          options: {
            legend: {
              textStyle,
            },
            yAxis: [
              {
                type: "value",
                name: "台",
                nameTextStyle: {
                  fontSize,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                  },
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);

                  },
                },
              },
              {
                type: "value",
                name: "元",
                nameTextStyle: {
                  fontSize,
                },
                axisLabel: {
                  fontSize,
                  formatter: (d) => {
                    return Number(d).toFixed(0);
                  },
                },
              },
            ],
            xAxis: PrintXAxis,
            series: [
              {
                barMaxWidth,
                label: {
                  show: true, // 显示数字
                  position: "insideRight",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return ThousandYuan(params.data);
                  },
                },
              },
              {
                lineStyle,
                symbolSize,
                itemStyle,
                label: {
                  show: true, // 显示数字
                  position: "top",
                  textStyle: {
                    fontSize, color: "black",
                  },
                  formatter: function(params) {
                    return ThousandYuan(params.data);
                  },
                },
              },
            ],
          },
        },
      },
    };
  },
  created() {
    if(this.allData) {
      this.getDescriptionsItem();
      this.getChartData();
      this.getTableData();
    }
  },
  methods: {
    getDescriptionsItem() {
      if(!this.rich) {
        return false;
      }
      if(!this.monthRank) {
        return false;
      }
      if(!this.yearRank) {
        return false;
      }
      this.descriptionsItem.data = [];
      this.descriptionsItem.kpiCodes.forEach((d, i) => {
        let arr = {};
        switch (i) {
        case 0:
          arr["name"] = "月度提车";
          break;
        case 1:
          arr["name"] = "月度终端";
          break;
        case 2:
          arr["name"] = "年度提车";
          break;
        default:
          arr["name"] = "年度终端";
        }
        if(this.monthRank[d] || this.yearRank[d]) {
          if(!this.monthRank[d] || !this.yearRank[d]) {
            arr["one"] = 0;
            arr["two"] = 0;
            arr["there"] = 0;
          } else {
            i < 2 ? arr["one"] = this.monthRank[d].NumValue : arr["one"] = this.yearRank[d].NumValue;
            arr["two"] = this.monthRank[d]["NationwideRank"];
            arr["there"] = this.yearRank[d]["NationwideRank"];
          }
        }
        this.descriptionsItem.data.push(arr);
      });
    },
    getTableData() {
      monthGetData(this.tableData1, this.monthPush, this.dataParams.ReportDate, this.tableData1.columnsI ?? 15);
      this.tableData1.columns[0].width = 190;
      this.tableData4.columns[1].width = 320;
      this.tableData2.columns = this.columnsC;
      tableIndex(this.tableData2, this.rich);
      tableIndex(this.tableData3, this.rich);
      tableIndex(this.tableData4, this.rich);
    },
    getChartData() {
      Object.keys(this.chartData).forEach(d => {
        mouth12Chart(this.chartData[d], this.monthPush, this.monthStandardPush, this.dataParams.ReportDate);
      });
    },
  },
  watch: {
    allData: {
      handler: function() {
        if(this.allData) {
          this.monthRank = this.allData["MonthRank"];
          this.monthPush = this.allData["MonthPush"];
          this.rich = this.allData["Rich"];
          this.monthStandardPush = this.allData["MonthStandardPush"];
          this.yearRank = this.allData["YearRank"];
        }
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
    dataParams: {
      handler: function() {
        this.getDescriptionsItem();
        this.getTableData();
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "monthReport.less";
</style>
