import {
    grid,
    itemStyle,
    lineStyle,
    symbolSize,
} from "@/views/afterSaleAnalysis/monthAnalysisChildComponent/chartConfig";
import {
    axisLine,
    axisTick,
    barMaxWidth, color, fontSize, seriesBar, seriesLine,
    splitLine, xAxis, yAxisDefault, yAxisRight,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";

//数据类型模板
let stdChartDataRows = {
    columns: ["key", "data"],
    rows: [{
        "key": "全国标杆值",
        // "data": {
        //     value: 0,
        //     itemStyle: {
        //         color: '#5A6C90'
        //     }
        // },
    },
        {
            "key": "区域标杆值",
            // "data": {
            //     value: 0,
            //     itemStyle: {
            //         color: '#267C93'
            //     }
            // },
        },
        {
            "key": "当前店",
            // "data": {
            //     value: 0,
            //     itemStyle: {
            //         color: '#73A9AB'
            //     }
            // },
        }],
}


//图表类型设置模板
let stdChartSetting = {
    label: {
        show: true,
        position: "inside",
    },
}

//单柱图extend配置
// 所有图表保留一位小数带百分号
let stdchartextend1 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                return (params.data.value * 100).toFixed(1) + "%";
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },
    legend: {
        show: false
    },
    xAxis,
    yAxis: yAxisDefault,


}
// 所有图表保留整数
let stdchartextend2 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                return params.data.value.toFixed(0);
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },
    legend: {
        show: false
    },
    xAxis,
    yAxis: yAxisDefault,

}
// 保留一位小数
let stdchartextend2_2 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                return params.data.value.toFixed(1);
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },
    legend: {
        show: false
    },
    xAxis,
    yAxis: yAxisDefault,


}

//堆叠柱状图百分比格式化
let stdchartextend3 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                return (params.data * 100).toFixed(1) + "%";
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },
    legend: {
        show: true
    },
    xAxis,
    yAxis: yAxisDefault,


}

//堆叠柱状图保留两位小数字
let stdchartextend4 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                return Number(params.data ?? 0).toFixed(0);
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },
    legend: {
        show: true
    },

    xAxis,
    yAxis: yAxisDefault,


}


let stdchartextend5 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                if (params.componentSubType == "line") {
                    return (params.data * 100).toFixed(1) + "%";
                } else {
                    return Number(params.data ?? 0).toFixed(0);
                }
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },

    legend: {
        show: true
    },
    xAxis,
    yAxis: {
        splitLine: {
            show: true,
            lineStyle: {
                color: "#999999",
                type: "dashed",
            },
        }
    },
}
//双y轴
let stdchartextend6 = {
    series: {
        label: {
            show: true,
            formatter: function (params) {
                if (params.componentSubType == "line") {
                    return (params.data * 100).toFixed(1) + "%";
                } else {
                    return Number(params.data ?? 0).toFixed(0);
                }
            },
            color: "black",
        },
        barWidth: 60,
        smooth: false
    },
    legend: {
        show: true
    },
    xAxis,
    yAxis: {
        splitLine: {
            show: true,
            lineStyle: {
                color: "#999999",
                type: "dashed",
            },
        },
    },
}


//CodeMap目前仅仅设置了堆叠图的Code对应的Name，普通柱状图的Name统一为Data
let codeMap = {
    A0106026: "平均单车新车裸车毛利",
    A0105071: "平均单车新车销售返利金额",
    A0105072: "平均单车销售水平事业毛利",
    A0107001: "新车返利后毛利率",
    A0107002: "销售综合毛利率",
    B0105114: "售后单车产值",
    B0106026: "售后综合毛利率",
    B0105139: '机电维修收入占售后综合收入比',
    B0105150: "钣喷维修收入占售后综合收入比",
    B0105112: "售后水平事业收入占售后综合收入比",
    C0408015: "销售业务毛利占总毛利比",
    C0408016: "售后业务毛利(工时+零部件+返利)占总毛利比",
    C0408010: "水平事业毛利占总毛利比",
    A0303273: "金融按揭渗透率",
    A0303272: "新车保险渗透率",
    A0303271: "新车用品渗透率",
    A0303275: "二手车置换率",
    A0403024: "0~30天期末新车库存台次占期末新车库存台次比",
    A0403025: "31~60天期末新车库存台次占期末新车库存台次比",
    A0403026: "61~90天期末新车库存台次占期末新车库存台次比",
    A0403027: ">90天期末新车库存台次占期末新车库存台次比",

    // 单Code图
    C0408020: '资产回报率(年化）',
    C0408004: "税前利润",
    C0408021: "税前利润率",
    A0302001: "新车销量",
    B0302001: "售后来厂台次",
    // A0105072:"单车销售水平事业毛利",
    C0408013: "经费覆盖率",
    B0107002: "零服吸收率",
    C0408001: "总费用占总毛利比",
    A0404002: "新车库存周转率",
    B0404002: "零部件库存周转率",
    C0107003: "资产负债率",
    C0107002: "速动比率"
}


// 投资人简报数据
let investorData = {
    chart_1_1: {
        code: [{KpiCode: "C0408020"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },

        },
        chartextend: {},
        //x轴数据指标名称
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_1_2: {
        code: [{KpiCode: "C0408004", ShowQOQ: true, ShowYOY: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {
        },
        //x轴数据指标名称
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"],
        options(chartObj) {
            chartObj.setOption({
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: function (params) {
                        let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>税前利润: " + (params[0].value / 10000).toFixed(1) + "万元";
                        return `${params[0].axisValue} <br /> ${val1}`;
                    },
                },
                yAxis: [
                    {
                        name: "万元",
                        type: "value",
                        axisLine: {
                            //y轴
                            show: false
                        },
                        axisTick,
                        splitLine,
                        axisLabel: {
                            fontSize:14,
                            color:"#999",
                            formatter: (val) => {
                                return `${(val / 10000).toFixed(1)}`;
                            },
                        },
                    },

                ],


            });

        },
    },
    chart_1_3: {
        code: [{KpiCode: "C0408021", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            showLine: ["data"],
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_1_4: {
        //1_4这个Code不生效的啊,随便配的,当初就这样设计的，必须要有两个以上Code它才能走对应逻辑!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        code: [{KpiCode: "C0408021"}, {KpiCode: "C0408021"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            showLine: ["税前利润增长率"],
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"],
    },
    chart_2_1: {
        code: [{KpiCode: "A0302001", ShowQOQ: true, ShowYOY: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_2_2: {
        code: [{KpiCode: "A0106026"}, {KpiCode: "A0105071"}, {KpiCode: "A0105072"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0"],
            stack: {'用户': ['平均单车新车裸车毛利', '平均单车新车销售返利金额', '平均单车销售水平事业毛利']}
        },
        chartextend: stdchartextend4,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"],
    },
    chart_2_3: {
        code: [{KpiCode: "A0107001"}, {KpiCode: "A0107002"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
            showLine: ["新车返利后毛利率", "销售综合毛利率"],
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_2_4: {
        code: [{KpiCode: "B0302001", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_2_5: {
        code: [{KpiCode: "B0105114"}, {KpiCode: "B0106026"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            showLine: ['售后综合毛利率'],
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0", "0.0%"],
            axisSite: {right: ["售后综合毛利率"]},
        },

        chartextend: stdchartextend6,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"],
        options(chartObj) {
            chartObj.setOption({
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: function (params) {
                        let val1 = "<span style=\"background-color:" + params[0].color + ";display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right:2px;\"></span>税前利润: " + (params[0].value / 10000).toFixed(1) + "万元";
                        return `${params[0].axisValue} <br /> ${val1}`;
                    },
                },
                yAxis: [
                    {
                        type: "value",
                        axisLine: {
                            //y轴
                            show: false
                        },
                        axisTick,
                        splitLine,
                        axisLabel: {
                            fontSize:14,
                            color:"#999",
                            formatter: (val) => {
                                return `${(val ).toFixed(0)}`;
                            },
                        },
                    },
                    {
                        type: "value",
                        axisLine: {
                            //y轴
                            show: false
                        },
                        axisTick,
                        splitLine:{
                            show: false
                        },
                        axisLabel: {
                            fontSize:14,
                            color:"#999",
                            formatter: (val) => {
                                return `${(val * 100).toFixed(0)}%`;
                            },
                        },
                    },

                ],


            });

        },

    },
    chart_2_6: {
        code: [{KpiCode: "B0105139"}, {KpiCode: "B0105150"}, {KpiCode: "B0105112"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
            stack: {'用户': ['机电维修收入占售后综合收入比', '钣喷维修收入占售后综合收入比', '售后水平事业收入占售后综合收入比']}
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_2_7: {
        code: [{KpiCode: "C0408015"}, {KpiCode: "C0408016"}, {KpiCode: "C0408010"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
            stack: {'用户': ['销售业务毛利占总毛利比', '售后业务毛利(工时+零部件+返利)占总毛利比', '水平事业毛利占总毛利比']}
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_2_8: {
        code: [{KpiCode: "A0105072"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_2_9: {
        code: [{KpiCode: "A0303273"}, {KpiCode: "A0303272"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
            showLine: ["金融按揭渗透率", "新车保险渗透率"],
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_2_10: {
        code: [{KpiCode: "A0303271"}, {KpiCode: "A0303275"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
            showLine: ["新车用品渗透率", "二手车置换率"],
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_3_1: {
        code: [{KpiCode: "C0408013", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_3_2: {
        code: [{KpiCode: "B0107002", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_3_3: {
        code: [{KpiCode: "C0408001", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_4_1: {
        code: [{KpiCode: "A0404002", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_4_2: {
        code: [{KpiCode: "A0403024"}, {KpiCode: "A0403025"}, {KpiCode: "A0403026"}, {KpiCode: "A0403027"}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
            yAxisType: ["0.0%"],
            stack: {'用户': ['0~30天期末新车库存台次占期末新车库存台次比', '31~60天期末新车库存台次占期末新车库存台次比', '61~90天期末新车库存台次占期末新车库存台次比', '>90天期末新车库存台次占期末新车库存台次比']},
        },
        chartextend: stdchartextend3,
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },

    chart_4_3: {
        code: [{KpiCode: "B0404002", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_5_1: {
        code: [{KpiCode: "C0107003", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
    chart_5_2: {
        code: [{KpiCode: "C0107002", ShowQOQ: true}],
        chartData: JSON.parse(JSON.stringify(stdChartDataRows)),
        chartSetting: {
            label: {
                show: true,
                position: "inside",
            },
        },
        chartextend: {},
        chartXAxisKey: ["NationwideStandard", "RegionalStandard", "Current"],
        chartXAxisName: ["全国标杆值", "区域标杆值", "当前店"]
    },
}


export {investorData, stdChartDataRows, codeMap, stdchartextend1, stdchartextend2, stdchartextend2_2}
