<template>
    <a-row :gutter="[0,8]">
        <a-col :span="24" v-if="isCloseA">
            <a-anchor style="min-width: 1440px" :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
                <div class="fix_border">
                    <a-col :span="20">
                        <a-date-picker
                                format="YYYY"
                                mode="year"
                                :value="params.ReportDate"
                                :open="open"
                                @openChange="openChange"
                                @panelChange="panelChange"
                        />
                        <a-cascader style="width: 380px;margin-left: 20px" :options="dealers" :allowClear="false" :change-on-select="true"
                                placeholder="全国" @change="onAreaChange"
                                :show-search="true"
                        />
                        <!--<a-button type="primary" style="margin-left: 10px" @click="selectFinch">生成图表</a-button>-->
                    </a-col>
                    <a-col style="float: right">
                        <span style="float: right;position: relative;top: -20px;margin-right: 4px" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
                      <a-tooltip placement="top" :title="`点击生成《${moment(title.ReportDate).format('yyyy年M月')+title.choseDealer}市占率推移总览》`">
                        <!--<a-button type="primary" @click="generateImages()">生成长图</a-button>-->
                      </a-tooltip>
                    </a-col>
                </div>
            </a-anchor>
        </a-col>
      <div  ref="capture">
        <div >
            <div class="report_bg" style="height: 220px;">
                {{ moment(title.ReportDate).format("yyyy年") }} {{ title.choseDealer }} 市占率推移总览
            </div>
            <div class="table-border">
                <div style="float:left;width: 50%">
                    <table class="leftTable">
                        <thead>
                        <th v-for="(item,k) in tableData.tableData1.columnsData" :key="k++">
                            {{
                               item
                            }}
                        </th>
                        </thead>
                        <tr v-for="(item,i) in tableData.tableData1.data" :key="i++">
                            <td :style="k===0?'text-align:center':''" v-for="(abr,k) in tableData.tableData1.columnsData" :key="abr">{{
                                   getTable1Text(item[k],i)  }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div  style="float:left;width: 50%;padding:0 0 4px 10px" >
                    <table class="rightTable">
                        <thead>
                        <tr>
                            <th colspan="12"> {{ moment(params.ReportDate).format("YYYY") }}</th>
                        </tr>
                        <tr>
                            <th v-for="(item,i) in 12" :key="i++"> {{ item }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(data,d) in yearData" :key="d++">
                            <td v-for="(item,t) in data.val" :key="t++"> {{ item }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- 销量数据对比    -->
            <div class="table-border">
                <div class="title">销量数据对比</div>
                <profit-table :columnsData="tableData.tableData2.columnsData" :tableData="tableData.tableData2"/>
            </div>
        <!--  派系占比对比      -->
            <div class="table-border">
                <div class="title">派系占比对比</div>
                <markerYearTable :table-data="tableRowsData.tableData3"/>
            </div>
        <!--  车型级别占比对比      -->
            <div class="table-border">
                <div class="title">车型级别占比对比</div>
                <markerYearTable :table-data="tableRowsData.tableData4"/>
                <br>
                <markerYearTable :table-data="tableRowsData.tableData5"/>
            </div>
        <!--  主要自主品牌销量/市占对比      -->
            <div class="table-border">
                <div class="title">主要自主品牌销量/市占对比</div>
                <markerYearTable :table-data="tableRowsData.tableData7"/>
            </div>
        <!--  车型竞品对比      -->
            <div class="table-border">
                <div class="title">车型竞品对比
                </div>
                <markerYearTable :table-data="tableRowsData.tableData8"/>
            </div>
      </div>
    </a-row>
</template>

<script>
import moment from "moment";
import underscore from "underscore";
import { getTableData, getTableData5 } from "@/views/modelYearAnalysis/markerYearUnit";
import markerYearTable from "@/views/modelYearAnalysis/markerYearTable";
import profitTable from "@/components/profitTable";
import { percentage, UnitProcessing } from "@/until/option";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";

export default {
 name: "marketYearRate",
 components: { markerYearTable, profitTable },
 data() {
  return {
   moment, percentage,
   isCloseA: true,
      车型竞品对比排序: {"影豹":1,"GS4":2,"GS8":3,"GS3":4,"M6":5,"M8":6},
   params: {
    ReportDate: moment(),
    choseDealer: "全国",   // 新的区域
    AreaData: [],
    ranges: [],
   },
   title: {
    ReportDate: "",
    choseDealer: "",
   },
   open: false,
   dealers: [],  // 新的区域
   ReportDateMoment: "",
   tableData: {
    tableData1: {
     columnsData: ["区域", "2017", "2018", "2019", "2020", "2021"],
     data: [
      //  { 0: "上海", 1: 33, 2: 32, 3: 32, 4: 342, 5: 342 },
     ],
    },
    tableData2: {
     columnsData: ["上海", "单位", "2017", "2018", "2019", "2020", "2021"],
     rowData: [
      { title: "销量", unit: "个", resName: "saledata" },
      { title: "增速", unit: "%", resName: "speeddata" },
      { title: "全省排名", unit: "名", resName: "arearank" },
      { title: "全国排名", unit: "名", resName: "countryrank" },
      { title: "占比", unit: "%", resName: "zb" },
     ],
     data: [],
    },
   },
   // 有跨行Table
   tableRowsData: {
    tableData3: {
     columnsData: [
      /*"上海", "派系", "2017", "2018", "2019", "2020", "2021"*/
     ],
     colData: [
      { title: "销量", unit: "台", rowspan: 6, children: [], resName: "seriesata" },
      { title: "派系占比", unit: "%", rowspan: 6, children: [], resName: "serieszb" },
     ],
     data: [
      /* { title: "销量", rowspan: 6, val: ["德系", 2, 3, 4, 5, 6] },
       { title: "韩系", val: ["韩系", 2, 3, 4, 5, 6] },
       { title: "美系", val: ["美系", 2, 3, 4, 5, 6] },
       { title: "日系", val: ["日系", 2, 3, 4, 5, 6] },
       { title: "意法系", val: ["意法系", 2, 3, 4, 5, 6] },
       { title: "自主", val: ["自主", 2, 3, 4, 5, 6] },

       { title: "派系占比", rowspan: 6, val: ["德系", 2, 3, 4, 5, 6] },
       { title: "韩系", val: ["韩系", 2, 3, 4, 5, 6] },
       { title: "美系", val: ["美系", 2, 3, 4, 5, 6] },
       { title: "日系", val: ["日系", 2, 3, 4, 5, 6] },
       { title: "意法系", val: ["意法系", 2, 3, 4, 5, 6] },
       { title: "自主", val: ["自主", 2, 3, 4, 5, 6] },*/
     ],
     columnName: "派系",
     resHttp: "series",
    },
    tableData4: {
     columnsData: [],
     colData: [
      { title: "轿车", unit: "台", rowspan: 5, children: [], resName: "Car" },
      { title: "SUV", unit: "台", rowspan: 4, children: [], resName: "SUV" },
      { title: "MPV", unit: "台", rowspan: 3, children: [], resName: "MPV" },
     ],
     data: [],
     isSum: true,
     resHttp: "carlevel",
    },
    tableData5: {
     columnsData: [],
     colData: [
       { title: "轿车", unit: "%", rowspan: 3, children: [], resName: "Car" },
       { title: "SUV", unit: "%", rowspan: 1, children: [], resName: "SUV" },
       { title: "MPV", unit: "%", rowspan: 1, children: [], resName: "MPV" },
     ],
     data: [],
     resHttp: "carlevelzb",
    },
    tableData6: {
     columnsData: [],
     colData: [
      { title: "轿车", unit: "%", rowspan: 4, children: [], resName: "Car" },
      { title: "SUV", unit: "%", rowspan: 3, children: [], resName: "SUV" },
      { title: "MPV", unit: "%", rowspan: 2, children: [], resName: "MPV" },
     ],
     data: [],
     columnName: "细分级别", resHttp: "carleveldetailzb",
    },
    tableData7: {
     columnsData: [],
     colData: [
      {
       title: "销量",
       unit: "台",
       rowspan: 8,
       children: [],
       resName: "zzbrandsale",
      },
      {
       title: "市占率",
       unit: "%",
       rowspan: 8,
       children: [],
       resName: "zzbrandzb",
      },
     ],
     data: [],
     columnName: "品牌",
     resHttp: "zzBrand",
    },
    tableData8: {
     columnsData: [],
     data: [],
    },
   },
   yearData: [],
   YearFive: [], // 五年推移
   year: moment().get("year").toString(), // 当年
    month: moment().get("month").toString(), // 上一月的1号 精确到毫秒 ,要不firefox safari 中显示Invalid Date
    sysMonth:0,
  };
 },
 async created() {
  this.title.ReportDate = this.params.ReportDate;
  this.title.choseDealer = this.params.choseDealer;
    await this.getTreeData();
    await this.selectFinch();
     // await this.getTableData1Data();
     // await  this.getTableData2Data();
     // await this.getTableData3();
 },
 methods: {
     getTable1Text(item,i){
         if(i===3){
             item = item.replace("大区","")
         }
         return  item
     },
  generateImages() {
     message.loading("生成中,请稍后....", 4);
     this.isShow = false; // 隐藏填报说明
     setTimeout(() => {
       html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
         this.img = canvas.toDataURL();
         if(window.navigator.msSaveOrOpenBlob) {
           const atob1 = atob(this.img.split(",")[1]);
           let n = atob1.length;
           const u8arr = new Uint8Array(n);
           while (n--) {
             u8arr[n] = atob1.charCodeAt(n);
           }
           const blob = new Blob([u8arr]);
           window.navigator.msSaveOrOpenBlob(blob, `${moment(this.title.ReportDate).format('yyyy年M月')+this.title.choseDealer}市占率推移总览.png`);
         } else {
           const a = document.createElement("a");
           a.href = this.img;
           a.setAttribute("download", `${moment(this.title.ReportDate).format('yyyy年M月')+this.title.choseDealer}市占率推移总览.png`);
           a.click();
         }
       }).catch((error) => {
         this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
       });
     }, 1000);
   },
  async selectFinch() {
   this.title.ReportDate = this.params.ReportDate;
   this.title.choseDealer = this.params.choseDealer;
  this.getYearFive(); // 五年的推荐数组
    await Promise.all([this.getTableData1Data(), this.getTableData2Data(), this.getTableData3()]);
  },
  async getTableData1Data() {
   this.getYearFive()
   let ranges = JSON.parse(JSON.stringify(this.params.AreaData));
   ranges.shift();
   this.params.ranges = ranges;
   let res = await this.$Http.PushOverall({
    year: this.params.ReportDate.format("Y"),
    ranges: this.params.ranges,
   });
   if(!res["Success"]) {
    this.$message.error(res.Message);
   }
   let resData = res.Data;
   //获取数组，并按sort排序
      let monthData = resData["monthdata"];
      let keyList = this._.keys(monthData)
      keyList.forEach(key=>{
          Object.assign(monthData[key],{part:key})
      })
      let arr = Object.values(monthData).sort((a,b)=>{return a.sort - b.sort});
      let keyListOrdered = this._.pluck(arr,"part");
    // 左侧年推移表格加载数据
    this.tableData.tableData1.data = [];
    if(resData) {
      let resYear = resData["yeardata"];
        keyListOrdered.map((ob) => {
        let arr = {};
        arr[0] = ob;
        Object.values(resYear[ob]).forEach((d, i) => {
          d = percentage(d);
          arr[i + 1] = d;
        });
        this.tableData.tableData1.data.push(arr);
      });
   // 右侧当年表格数据
   this.yearData = [];
   if(resData !== undefined) {
    let resMonth = resData["monthdata"];
     this.tableData.tableData1.data.map((ob) => {
     let arr = {};
     arr.title = ob[0];
     arr.val = [];
     Object.values(resMonth[ob[0]]).slice(0,12).forEach((d,i) => {
       if(d === 0) {
         if(this.sysMonth === 0) {
           this.sysMonth = i;
           this.getYearFive()
         }
       }
       this.tableData.tableData1.columnsData = ["区域", ...this.YearFive];
       d = percentage(d);
       if(d === "0%"){
           d = "-";
       }
      arr.val.push(d);
     });
     this.yearData.push(arr);
     });
   }
   }
  },
  async getTableData2Data() {
   let res = await this.$Http.DataRatio({
    year: this.params.ReportDate.format("Y"),
    ranges: this.params.ranges,
   });
   if(!res["Success"]) {
    this.$message.error(res.Message);
   }
   let resData = res.Data;
   let table = this.tableData.tableData2;
   table.columnsData = [this.params.choseDealer, "单位", ...this.YearFive];
   table.data = [];
   if(resData) {
    Object.keys(resData).map((ob) => {
     let arr = {};
     // 名称,单位取出
     table.rowData.forEach(r => {
      if(ob === r.resName) {
       arr[0] = r.title;
       arr[1] = r.unit;
      }
     });
     // 加载数据
     if(Object.keys(resData[ob]).length > 0) {
      Object.values(resData[ob]).forEach((d, i) => {
       d = UnitProcessing(arr[1], d ?? 0); // 单位处理
       arr[i + 2] = d;
      });
     }
     table.data.push(arr);
    });
   }
   // 加载表格数据
   switch (this.params.AreaData.length) {
   case 3:
    table.data.splice(2, 1);
    table.data.splice(3, 1);
    table.data[2][0] = "省份排名"; // 选择省,显示省份排名
    break;
   case 4:
    break;
   default:
    table.data.splice(2, 3);
   }
  },
  async getTableData3() {
    let  parmId = ""
    if(this.params.ranges.length > 0) parmId = this.params.ranges[this.params.ranges.length - 1];
    let res = await this.$Http.SeriesCartypeRatio({
     year: this.params.ReportDate.format("Y"),
     parmId,
   });
   if(!res["Success"]) {
    this.$message.error(res.Message);
   }
   let resData = res.Data;
   if(!resData)
    return;
   Object.keys(this.tableRowsData).map(table => {
    if(table === "tableData8" || table === "tableData5")
     return;
    getTableData(this.tableRowsData[table], this.params.choseDealer, this.YearFive, resData);
   });
   this.sumData(this.tableRowsData.tableData4, 0, 4); // 合计轿车
   this.sumData(this.tableRowsData.tableData4, 5, 8); // 合计 SUV
   this.sumData(this.tableRowsData.tableData4, 9, 11); // 合计 MPV
    // tableData5 加载
   getTableData5(this.tableRowsData.tableData5,this.params.choseDealer, this.YearFive, resData)
   //  tableData8 加载
   let years = this.YearFive.chain().map(year => {
    return  year.split("(")[0];
   }).value();
   let tableData = this.tableRowsData.tableData8;
   let data = [];
   let getRowFunc = (caption, carName, source) => {
       if (caption == "销量影豹(台)"){
           caption = "影豹竞品销量（台）"
       }
        if (caption == "市占率影豹(%)"){
            caption = "影豹竞品市占率（%）"
        }
       if (carName === "传祺GS4"){
           caption = caption + "<br/> "+ "（含GS4 PLUS 、GS4 PHEV、GS4 COUPE)"
       }
       if (carName === "传祺GS8"){
           caption = caption + "<br/> "+ "（含GS8S)"
       }
       if (carName === "传祺M6"){
           caption = caption + "<br/> "+ "（含M6 PRO)"
       }
       if (carName === "传祺M8"){
           caption = caption + "<br/> "+ "（含M8 大师版)"
       }
    // if(carName === "影豹") carName = "传祺影豹"
    let sourceRow = source[carName];
    let row = [carName];
    years.forEach(year => {
      let value = 0
      if(sourceRow !== undefined){
        value = sourceRow[year] === undefined ? 0 : sourceRow[year];
      }
     if(caption.indexOf("市占率") > -1) value = percentage(value);
     row.push(value);
    });
    return { title: caption, val: row };
   };
   let pushDataRowFunc = (field, caption, code) => {
     let name = "传祺" + code;
    let nameStr = name
    if(code === "影豹") name = "影豹"
    if(code === "GS3") nameStr = "GS3 Power"


    let unit = "(台)";
    if(caption === "市占率") unit = "(%)";
    // caption += code + unit;
    caption = nameStr + "竞品" + caption + unit

    if(resData[field]) {
     let source = resData[field][code];
     if(source) {
      let firstRow = getRowFunc(caption, name, source);
      let keys = Object.keys(source);
       firstRow.rowspan = keys.length;
       if(keys.indexOf(name) === -1){
         firstRow.rowspan ++;
       }
      data.push(firstRow);

      keys.forEach(key => {
       if(key === name)
        return;
       data.push(getRowFunc(caption, key, source));
      });
     }
    }
   };
   // 车型从后台取出
   let CarCode = Object.keys(resData["competesale"]).chain().map(c => {
    return c;
   }).value().sort((a,b)=>{
        return this.车型竞品对比排序[a] - this.车型竞品对比排序[b]
    })
   CarCode.forEach(code => {
    pushDataRowFunc("competesale", "销量", code);
    pushDataRowFunc("competezb", "市占率", code);
   });
   tableData.columnsData = [this.params.choseDealer, "车型", ...this.YearFive];
    data.forEach(d=>{
      d.title = d.title.replace("传祺","")
      d.val = d.val.chain().map(v => {
        v = v.toString().replace("传祺", "");
        return v;
      }).value();
    })
   tableData.data = data;
  },

  // 合计计算
  sumData(tableData, start, end) {
   let val1 = [0, 0, 0, 0, 0];
   tableData.data.forEach((d, i) => {
    if(i >= start && i < end) {
     val1.forEach((_, j) => {
      val1[j] += Number(d.val[j + 1]);
     });
    }
    if(i === end) {
     d.val = ["合计"];
     val1.forEach(v => {
      d.val.push(v.toString());
     });
    }
   });
  },
  // 五年的推移数组
  getYearFive() {
   this.YearFive = [];
   let selectYear = moment(moment(this.params.ReportDate.toString()).format("YYYY")).add(-5, "y").format("YYYY");
   underscore.range(5).map(() => {
    selectYear = moment(selectYear).add(1, "y").format("YYYY");
    if(selectYear === this.year) {
     // 当年只显示上一个月前的计算数据
     //    获取2021年有数据的数
      let val = this.month
      if(this.sysMonth !== 0) val = this.sysMonth
     selectYear = selectYear + "(1~" + val + ")";
      // 12月时只显示年不用显示 1~12
     //   if(this.month === "11") selectYear = "全年";
     // 1月时只显示(1) 不用显示 1~1
     if(this.month === "0") selectYear = this.year + "(1)月";
    }
    this.YearFive.push(selectYear);
    this.$forceUpdate()
   });
  },
  //Ant Design封装年份选择组件
  openChange(status) {
   this.open = !!status;
  },
  panelChange(value) {
   if(Number(value.format("YYYY")) > Number(this.year)) {
    this.$message.error("啊哦,未来的数据,我太难了!");
    return false;
   }
   this.params.ReportDate = value;
   this.open = false;
   // this.getYearFive();
   // this.getTableData1Data();
   // this.getTableData2Data();
   // this.getTableData3();
  },
  // 地区选择
  onAreaChange(value, selectedOptions) {
   this.params.AreaData = value;
   this.params.choseDealer = selectedOptions[selectedOptions.length - 1].label;
   // this.getTableData1Data();
   // this.getTableData2Data();
   // this.getTableData3();
  },
  async getTreeData() {
   let ras = await this.$Http.GetDealersTreeByRole({
    level: 3, // 取出三级地区,不传取出全部地区
   });
   if(!ras.Data) {
    this.$message.error(ras.Message);
    return;
   }
   this.dealers = [{
    value: "",
    label: "全国",
    children: ras.Data,
   }];
  },
 },
};
</script>

<style lang="less" scoped>
.table-border {
  clear: both;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  padding: 10px 0;overflow: hidden;
  margin: 10px auto;

  .title {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    padding-left: 13px;
    height: 40px;line-height: 40px;
    }
  }


.rightTable {
  width: 100%;border: 1px solid #ddd;

  thead {
    tr {
      th {
        height: 19px;line-height: 19px;
        text-align: center;color: #fff;
        background: rgb(43, 130, 29);
        border-right: 1px solid #ddd;padding-right: 4px;
        }

      &:last-child th {
        background: #d5d5d5;color: #000;font-weight: 500;

        }
      }
    }

  tr {
    border-bottom: 1px solid #ccc;

    td {
      text-align: right;height: 40px;line-height: 40px;width: 8.33%;
      border-right: 1px solid #ddd;padding-right: 4px;
      }

    &:nth-child(odd) {
      background: rgba(43, 130, 29, .1);
      border: 1px solid #DDDDDD;
      }
    }

  }

.leftTable {
  width: 100%;border: 1px solid #ddd;

  thead {
    text-align: center;color: #fff;
    background: #33554A;height: 40px;line-height: 40px;

    th {
      border-right: 1px solid #DDDDDD;
      text-align: center;

      &:first-child {
        min-width: 150px;
        }

      &:last-child {
        border-right: 0;min-width: 100px;
        }
      }
    }

  tr {height: 40px;line-height: 40px;


    td {
      width: 6%;text-align: right;
      border-right: 1px solid #ddd;padding-right: 4px;

      &:first-child {
        text-align: left;padding-left: 1vw;
        }


      &:last-child {
        border-right: 0;
        }

      }

    &:nth-child(even) {
      background: #E6ECEC;
      border: 1px solid #DDDDDD;
      }
    }

  }
</style>
