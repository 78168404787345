<template>
    <section>
        <a-card>
            <div class="title-part">
                <div class="left">
                    <div class="item">
                        计划月份：
                        <a-month-picker v-model="queryForm.PlanDate"/>
                    </div>
                    <div class="item">
                        弱项KPI名称：
                        <a-input v-model="queryForm.KpiName" style="width: 200px" placeholder="请输入KPI名称"></a-input>
                    </div>
                    <div class="item">
                        计划状态：
                        <a-select default-value="" style="width: 200px" v-model="queryForm.PlanStatus"
                                  :allowClear="true" placeholder="请选择计划状态">
                            <a-select-option v-for="item in PlanStatus" :key="item.status" :value="item.status">
                                {{ item.key }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="item">
                        <a-button type="primary" @click="getList(1)">搜索</a-button>
                    </div>
                </div>
                <div>
                    <a-button @click="$router.push('/improvePlan/createImprovePlan')">
                        <a-icon type="plus"/>
                        新增计划
                    </a-button>
                    <a-badge :count="caseCount">
                        <a-button type="primary" ghost @click="$router.push('/improvePlan/casebook')">
                            案例集
                        </a-button>
                    </a-badge>
                </div>
            </div>
        </a-card>
        <a-card>
            <a-table :columns="columns" :data-source="tabledata" :row-key="record=>record.PlanId"
                     @change="tableChange"
                     :pagination="paginationParam"
                     :scroll="{ x: 1800, y: 500 }">
               <span slot="PlanStartTime" slot-scope="PlanStartTime">
                        {{ PlanStartTime|FormatDate }}
                </span>
                <span slot="PlanEndTime" slot-scope="PlanEndTime">
                        {{ PlanEndTime|FormatDate }}
                </span>
                <span slot="StatusName" slot-scope="StatusName,record">
                       <a-badge :color="getTagColor(record)"/>
                    {{ StatusName }}
                </span>
                <span slot="action" slot-scope="text, record">
                  <a v-if="record.PlanStatus === 0 &&isCanImprovePlan(record)"
                     @click="$router.push('/improvePlan/editImproveView?StartTime='+record.PlanStartTime+'&PlanId='+record.PlanId)">改善行动</a>
                  <a-divider type="vertical" v-if="record.PlanStatus === 0 &&isCanImprovePlan(record)"/>
                  <a @click="$router.push('/improvePlan/viewImproveView?StartTime='+record.PlanStartTime+'&PlanId='+record.PlanId)">查看</a>
                  <a-divider type="vertical" v-if="record.PlanStatus === 0"/>
                  <a @click="abandon(record)" v-if="record.PlanStatus === 0">放弃</a>
                </span>
            </a-table>
        </a-card>
        <a-modal v-model="abandonVisible" title="原因填写" @ok="handleOk" @cancel="handleCancel">
            <a-form-model
                :rules="rules"
                ref="abandonForm"
                :model="abandonForm"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 20 }">
                <a-form-model-item label="原因" prop="Comment">
                    <a-textarea
                        v-model="abandonForm.Comment"
                        placeholder="请输入放弃原因"
                        :auto-size="{ minRows: 5, maxRows: 7 }"
                    />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </section>
</template>

<script>
import moment from "moment";

const columns = [
    {
        title: "计划名称",
        dataIndex: "PlanName",
        key: "PlanName",
        ellipsis: true,
        width: 200
    },
    // {
    //     title: "销售店名称",
    //     key: "CompanyNameCN",
    //     dataIndex: "CompanyNameCN",
    // },
    {
        title: "计划开始时间",
        dataIndex: "PlanStartTime",
        key: "PlanStartTime",
        scopedSlots: {customRender: 'PlanStartTime'},
        width:150
    },
    {
        title: "计划结束时间",
        dataIndex: "PlanEndTime",
        key: "PlanEndTime",
        scopedSlots: {customRender: 'PlanEndTime'},
        width:150
    },
    {
        title: "弱项KPI名称",
        key: "KpiName",
        dataIndex: "KpiName",

        width:300
    },
    {
        title: "弱项KPI目标值",
        key: "TargetValue",
        dataIndex: "TargetValue",
        width:100
    },
    {
        title: "弱项KPI当前值",
        key: "NumValue",
        dataIndex: "NumValue",
        width:100
    },
    {
        title: "最新更新时间",
        key: "LastUpdateTime",
        dataIndex: "LastUpdateTime",
        width:200
    },
    {
        title: "完成状态",
        key: "StatusName",
        dataIndex: "StatusName",
        scopedSlots: {customRender: 'StatusName'},
        width:100
    },
    {
        title: "联系号码",
        key: "Mobile",
        dataIndex: "Mobile",
        scopedSlots: {customRender: 'Mobile'},
        width:150

    }, {
        title: "执行部门",
        key: "ExecPart",
        dataIndex: "ExecPart",
        scopedSlots: {customRender: 'ExecPart'},
        width:200

    }, {
        title: "执行人",
        key: "ExecPeople",
        dataIndex: "ExecPeople",
        scopedSlots: {customRender: 'ExecPeople'},
        width:200
    },
    {
        title: "操作",
        fixed: 'right',
        key: "action",
        scopedSlots: {customRender: "action"},
        width:150
    },

];

export default {
    name: "list",
    data() {
        return {
            tabledata: [],
            columns,
            abandonVisible: false,
            partList: [],
            dealerList: [],
            caseCount: 0,
            abandonForm: {
                PlanId: null,
                Comment: ''
            },
            //查询对象
            queryForm: {
                pageSize: 10,
                pageIndex: 1,
                PlanDate: null,
                KpiName: '',
                PlanStatus: ''
            },
            //分页参数
            paginationParam: {
                current: 1,
                total: 0,
                pageSize: 10,
            },
            //计划状态列表
            PlanStatus: [
                {
                    key: "进行中",
                    status: "0"
                }, {
                    key: "已完成",
                    status: "1"
                }, {
                    key: "未完成",
                    status: "2"
                }, {
                    key: "已放弃",
                    status: "-1"
                }
            ],
            rules:
                {
                    Comment: [
                        {
                            required: true,
                            message: '请输入放弃原因',
                            trigger: ["blur", "change"]
                        },
                    ]
                }
            ,
        }
            ;
    },
    mounted() {
        this.getList(1);
    },
    methods: {
        tableChange(pagination, filters, sorter, {currentDataSource}) {
            this.paginationParam.current = pagination.current;
            this.getList(this.paginationParam.current);
        },

        //只有在计划开始和结束之间才能填写改善计划
        isCanImprovePlan(record) {
            return moment() > moment(record.PlanStartTime) && moment() < moment(record.PlanEndTime);
        },
        selectorFilter(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        abandon(record) {
            this.abandonVisible = true;
            this.abandonForm.PlanId = record.PlanId;
        },
        async handleOk() {
            this.$refs.abandonForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$Http.AbandonPlanStatus(this.abandonForm);
                    if (res.Success) {
                        this.$message.success(res.Data);
                        this.getList(this.queryForm.PageIndex);
                        this.abandonVisible = false;
                        this.$refs["abandonForm"].resetFields();
                    } else {
                        this.$message.error(res.Message);
                    }
                }
            });

        },
        handleCancel() {
            this.$refs["abandonForm"].resetFields();
        },
        async getList(pageIndex) {
            try {
                let self = this;
                //将分页参数传入form对象
                self.queryForm.pageSize = self.paginationParam.pageSize;
                self.queryForm.pageIndex = self.paginationParam.current = pageIndex;
                if (self.queryForm.PlanDate) {
                    self.queryForm.PlanDate = moment(self.queryForm.PlanDate).format("YYYY-MM-01");
                }
                let res = await self.$Http.GetImprovePlanListPage(self.queryForm)
                if (res.Success) {
                    self.tabledata = res.Data;
                    self.paginationParam.total = res.TotalCount;
                } else {
                    self.$message.error(res.Message);
                }
            } catch (e) {
                this.$message.error(e)
            }
        },

        getTagColor(record) {
            switch (record.PlanStatus) {
                case 0:
                    return "#856AFF";
                case 1:
                    return "#19A47A";
                case 2:
                    return "#FD595A";
                case -1:
                    return "#FD595A";
            }
            // console.log(record)
        }
    },
    filters: {
        FormatDate: function (value) {
            return moment(value).format("YYYY-MM-DD")
        },
    }
};
</script>

<style scoped>
.title-part {
    display: flex;
    justify-content: space-between;

}

.title-part > .left {
    display: flex;
}

.title-part > .left > .item {
    padding-left: 10px;
}
</style>
