<template>
  <a-card>
    <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 6 }"
    >
      <a-form-model-item label="消息标题" prop="MsgTitle">
        <a-input v-model="form.MsgTitle" :disabled="disabled"/>
      </a-form-model-item>
      <a-form-model-item label="消息副标题" prop="SubTitle">
        <a-input v-model="form.SubTitle" :disabled="disabled"/>
      </a-form-model-item>
      <a-form-model-item label="消息类型" prop="MsgType">
        <a-select v-model="form.MsgType" :disabled="disabled">
          <a-select-option
              v-for="(item, i) in MsgTypeList"
              :key="i"
              :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="内容" prop="MsgContent">
        <a-textarea
            v-model="form.MsgContent"
            :auto-size="{ minRows: 5 }"
            :disabled="disabled"
        />
      </a-form-model-item>
      <a-form-model-item label="权限" prop="PermissionCode">
        <a-select v-model="form.PermissionCode" :disabled="disabled" mode="multiple">
          <a-select-option
              v-for="item in permissionList"
              :key="item.label"
              :value="item.value"
          >
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-row>
        <a-col :span="2" :offset="20" v-if="!disabled">
          <a-button type="primary" @click="save"> 提交</a-button>
        </a-col>
        <a-col :span="2" :offset="disabled ? 22 : 0">
          <a-button @click="$router.push('/msgMannage/list')"> 取消</a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </a-card>
</template>

<script>
import { msgTypeEnum } from "@/enum/enum.js";

export default {
  data() {
    return {
      disabled: false,
      form: {
        MsgTitle: "",
        SubTitle: "",
        MsgContent: "",
        PermissionCode: [],
        MsgType: msgTypeEnum.systemMsg,
      },
      rules: {
        MsgTitle: [
          {
            required: true,
            message: "请输入消息标题",
            trigger: ["blur", "change"],
          },
        ],
        SubTitle: [
          {
            required: true,
            message: "请输入消息副标题",
            trigger: ["blur", "change"],
          },
        ],
        MsgContent: [
          {
            required: true,
            message: "请输入内容",
            trigger: ["blur", "change"],
          },
        ],
        PermissionCode: [
          {
            required: true,
            message: "请选择权限",
            trigger: ["blur", "change"],
          },
        ],
        MsgType: [
          {
            required: true,
            message: "请选择消息类型",
            trigger: ["blur", "change"],
          },
        ],
      },
      MsgTypeList: [
        {
          label: "系统消息",
          value: msgTypeEnum.systemMsg,
        },
        {
          label: "填报消息",
          value: msgTypeEnum.reportMsg,
        },
      ],
      //权限列表
      permissionList: [],
    };
  },
  methods: {
    save() {
      let self = this;
      self.$refs.form.validate(async(valid) => {
        if(valid) {
          this.form.PermissionCode = this.form.PermissionCode.toString();
          let res =
              self.$route.path === "/msgMannage/edit"
                  ? await self.$Http.EditMsg(this.form)
                  : await self.$Http.AddMsg(this.form);
          if(res["Success"]) {
            this.$message.success(res.Data);
            await this.$router.push("/msgMannage/list");
          } else {
            this.$message.error(res.Message);
          }
        }
      });
    },
    //获取权限列表
    async getPermissionList() {
      let self = this;
      let res = await self.$Http.GetPermission({
        pageIndex: 1,
        pageSize: 1000,
        keyWords: "",
      });
      if(res["Success"]) {
        self.permissionList = [];
        res.Data.forEach(data=>{
          self.permissionList.push({
            value:data.PermissionName,
            label:data.PermissionCode,
          })
        })
        this.init();
      } else {
        this.$message.error(res.Message);
      }

    },
    //初始化
    init() {
      if(this.$route.path === "/msgMannage/view") {
        this.disabled = true;
      }
      if(
          this.$route.path === "/msgMannage/edit" ||
          this.$route.path === "/msgMannage/view"
      ) {
        //读取query
        this.form = this.$route.query;
        //bugfix
        this.form.MsgType = Number(this.form.MsgType);
        this.form.msgId = this.form.ID;
        let code = this.form.PermissionCode.split(",");
        let defaultText = [];
        code.forEach(c => {
          this.permissionList.forEach(d => {
            if(c === d.label) {
              defaultText.push(d.value);
            }
          });
        });
        this.form.PermissionCode = defaultText;
      }
    },
  },
  mounted() {
    this.getPermissionList();
  },
};
</script>
