<template>
  <div>
    <a-card>
      <div style="display: flex;justify-content: space-around" v-if="isDealer">
        <div>
          <div>
            店代码：{{ basicInfo.DealerCode }}
          </div>
          <div>店简称：{{ basicInfo.DealerName }}</div>
        </div>
        <div>
          <div style="display: flex;align-items: center">
            <div>填报人：{{ basicInfo.Modifier }}</div>
          </div>
          <div style="display: flex;align-items: center">
            <div>填报人联系电话：{{ basicInfo.MobilePhone }}</div>
          </div>
        </div>
        <div>
          <div>区域：{{ basicInfo.PartName }}</div>
          <div>巡回员：{{ basicInfo.Itinerant }}</div>
        </div>
        <div>
          <div>年销规模：{{ basicInfo.SalesTarget }}</div>
          <div>城市级别：{{ basicInfo.CityLevel }}</div>
        </div>
        <div>
          <div>开业时间：{{ moment(basicInfo.OpenTime).format("YYYY年M月D日") }}</div>
        </div>
      </div>
    </a-card>
    <div class="part2">
      <div class="title">
        {{ selectedDate }}年度利润预测
      </div>
      <a-card>
        <div style="display: flex;justify-content: space-around">
          <div class="content">
            <div class="item">
              <div class="item-title">
                终端台次
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.A0302001.TargetValues }}台
              </div>
            </div>
          </div>
          <p class="operator">×</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                单车销售毛利
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.A0107004.TargetValues }}元
              </div>
            </div>
          </div>
          <p class="operator">+</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                来厂台次
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.B0302001.TargetValues }}台
              </div>
            </div>
          </div>
          <p class="operator">×</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                单车售后毛利
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.B0106027.TargetValues }}元
              </div>
            </div>
          </div>
          <p class="operator">-</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                总费用
              </div>
              <div class="item-num">
                {{ (forecastAndAchievement.C0206001.TargetValues / 10000).toFixed(1) }}万元
              </div>
            </div>
          </div>
          <p class="operator">
            =
          </p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                税前利润
              </div>
              <div class="item-num">
                {{ (forecastAndAchievement.C0408004.TargetValues / 10000).toFixed(1) }}万元
              </div>
            </div>
          </div>
        </div>
      </a-card>
      <div class="title">
        {{ selectedDate }}年度阶段实绩
      </div>
      <a-card>
        <div style="display: flex;justify-content: space-around">
          <div class="content">
            <div class="item">
              <div class="item-title">
                终端台次
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.A0302001.CurrentValues }}台
              </div>
            </div>
          </div>
          <p class="operator">×</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                单车销售毛利
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.A0107004.CurrentValues }}元
              </div>
            </div>
          </div>
          <p class="operator">+</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                来厂台次
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.B0302001.CurrentValues }}台
              </div>
            </div>
          </div>
          <p class="operator">×</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                单车售后毛利
              </div>
              <div class="item-num">
                {{ forecastAndAchievement.B0106027.CurrentValues }}元
              </div>
            </div>
          </div>
          <p class="operator">-</p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                总费用
              </div>
              <div class="item-num">
                {{ (forecastAndAchievement.C0206001.CurrentValues / 10000).toFixed(1) }}万元
              </div>
            </div>
          </div>
          <p class="operator">
            =
          </p>
          <div class="content">
            <div class="item">
              <div class="item-title">
                税前利润
              </div>
              <div class="item-num">
                {{ (forecastAndAchievement.C0408004.CurrentValues / 10000).toFixed(1) }}万元
              </div>
            </div>
          </div>
        </div>
      </a-card>
    </div>
    <div class="part3">
      <div style="display: flex">
        <a-card class="table-item">
          <div class="title">
            利润报表
          </div>
          <a-table :columns="columns" :data-source="data" :pagination="false">
            <div slot="name" slot-scope="text,record"
                :style="record.indentation ? 'paddingLeft:'+record.indentation+'em':''"
            >
              {{ record.name }}
            </div>
          </a-table>
        </a-card>
        <a-card class="table-item">
          <div class="title">
            辅助工具-盈利达成测算模型
          </div>
          <a-table :columns="columns2" :data-source="data2" :pagination="false">
            <template
                slot="forcast"
                slot-scope="text, record, index"
            >
              <div v-if="index===0"
              >
                <a-input
                    :disabled="disabled"
                    :value="record.forcast"
                    @blur="e => handleChange2(e.target.value, index)"
                />
              </div>
              <div v-else>
                {{ text }}
              </div>
            </template>
          </a-table>
          <div class="text">
            <p>
              1.未覆盖费用=总费用-售后业务毛利，指售后业务毛利未覆盖掉费用的部分需要从销售找回：
            </p>
            <p>
              2.本模型帮助预测盈利达成需要完成的终端台次及单台毛利，作为计划调整参考
            </p>
          </div>
        </a-card>
      </div>
      <div style="display: flex">
        <a-card class="table-item">
          <div class="title">
            关键KPI
          </div>
          <a-table :columns="columns3" :data-source="data3" :pagination="false">
            <div slot="name" slot-scope="text,record"
                :style="record.indentation ? 'paddingLeft:'+record.indentation+'em':''"
            >
              {{ record.name }}
            </div>
          </a-table>
        </a-card>
        <a-card class="table-item">
          <div class="title">
            辅助工具-判断售前售后是否平衡经营
          </div>
          <a-table :columns="columns4" :data-source="data4" :pagination="false">
          </a-table>
          <div class="text">
            <p>
              1.前后端产值比大于15说明售后稍弱，小于10说明新车销量应加大力度，店端应追求平衡发展：
            </p>
            <p>
              2.零服吸收率=售后业务综合毛利/（总费用-（变动费用*60%）-销售顾问工资奖金）
            </p>
          </div>
        </a-card>
      </div>
    </div>
    <div class="part4">
      <div>
        <div class="title" style="position: relative">
          <span>目标分解</span>
          <!--                    <a-tooltip>-->
          <!--                        <template slot="title">-->
          <!--                            请联系厂端人员释放操作权限-->
          <!--                        </template>-->
          <!--                        <a-button type="primary" style="position: absolute;right: 10px;top: 10px">编辑表格</a-button>-->
          <!--                    </a-tooltip>-->
        </div>
      </div>

      <div>
        <a-table :columns="columns5" :data-source="data5" bordered :pagination="false">
          <template
              slot="currentTarget"
              slot-scope="text, record, index"
          >
            <div v-if="getShowInput(index)"
            >
              <a-input
                  :disabled="handleDisable(record)"
                  :title="handleTitle(record)"
                  :value="record.currentTarget"
                  @blur="e => handleChange(e.target.value, index)"
              />
            </div>
            <div v-else>
              {{ text }}
            </div>
          </template>
          <template
              slot="currentCompleteRate"
              slot-scope="text, record, index"
          >
            <div :class="getRateClass(record)">
              {{ text + "%" }}
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="part5" v-if="isDealer">
      <div class="title">目标达成主要经营策略</div>
      <a-card>
        <div style="display: flex;justify-content: space-around">
          <div style="width: 45%">
            <div class="item-title">
              经营策略
            </div>
            <div>
              <a-textarea :disabled="disabled" v-model="basicInfo.OperatingStrategy"
                  :auto-size="{ minRows: 8, maxRows:8 }"
              />
            </div>
          </div>

          <div style="width: 45%">
            <div class="item-title">
              关键措施
            </div>
            <div>
              <a-textarea :disabled="disabled" v-model="basicInfo.KeyMeasures"
                  :auto-size="{ minRows: 8, maxRows:8 }"
              />
            </div>
          </div>
        </div>
        <div v-if="!disabled" style="text-align: center;margin-top: 20px">
          <a-button type="primary" @click="doSave('SUBMITTED')">提交</a-button>
          <a-button type="primary" @click="doSave('NONE')">保存</a-button>
        </div>
      </a-card>
    </div>
    <div class="" v-if="isShow">
      <a-affix :offsetBottom="0" v-if="affixShow&&isDealer" style="position: absolute;right: 0">
        <a-card title="填报说明" style="width: 300px">
          <a-icon style="color: #24619e;cursor: pointer" slot="extra" type="close" @click="affixShow=false"/>
          <ol style="list-style:decimal">
            <li>填报“目标数”一列即可，无数据处请填写"0"，不可为空；灰色区域为自动计算。</li>
            <li>台次取整；金额的数据精确到元，不保留小数。</li>
          </ol>
        </a-card>
      </a-affix>
    </div>
  </div>
</template>

<script>
import monthReportTableComponent from "@/components/monthReportTableComponent";
import moment from "moment";
import {showTipsConform} from "@/views/incomeAnalysis/showTips";

const renderContent = (value, row, index) => {
  const obj = {
    children: value,
    attrs: {},
  };
  return obj;
};
export default {

  name: "profitForecast",
  components: {
    monthReportTableComponent,
  },
  props: {
    selectedDate: String,
    dealerCode: String,
    isShow: Boolean,
  },
  watch: {
    selectedDate: {
      handler: function(newVal) {
        this.changeTableTitle(newVal);
        this.getData();
      },
      deep: true,
    },
    dealerCode: {
      handler: function(newVal) {
        this.dealerCodeFromUser = newVal;
        if(newVal.length !== "DSXA040".length) {
          //不是销售店的，不显示部分销售店才有的数据
          this.isDealer = false;
          this.affixShow = false;
        } else {
          this.isDealer = true;
          this.affixShow = true;
        }
        this.getData();
      },
      deep: true,
    },
    isShow: function(newVal, oldVal) {
      // console.log(newVal);
    },
  },
  data() {
    return {
      affixShow: true, moment,
      isDealer: true,
      disabled: false,
      //用户选择的店名称
      dealerCodeFromUser: "",
      columns: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "实绩",
          dataIndex: "achievement",
          key: "achievement",
        },
        {
          title: "目标",
          dataIndex: "target",
          key: "target",
        },
        {
          title: "今年实绩",
          dataIndex: "currentAchievement",
          key: "currentAchievement",
        },
        {
          title: "范围值",
          dataIndex: "valueRange",
          key: "valueRange",
        },
      ],
      data: [
        {
          key: "A0106024",
          unit: "元",
          name: "销售业务综合毛利",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "--",
        },
        {
          key: "A0107004",
          unit: "元",
          name: "其中-单车销售综合毛利",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "5000-12000",
          indentation: 1,
        },
        {
          key: "B0105113",
          unit: "元",
          name: "售后业务综合毛利",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "--",
        },
        {
          key: "B0106027",
          unit: "元",
          name: "其中-单车售后综合毛利",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "300-750",
          indentation: 1,
        },
        {
          key: "C0206001",
          unit: "元",
          name: "总费用",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "--",
        },
        {
          key: "A0205034",
          unit: "元",
          name: "其中-单车广宣费用",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "400-1200",
          indentation: 1,
        },
        {
          key: "C0408004",
          unit: "元",
          name: "税前利润",
          achievement: 0,
          currentAchievement: 0,
          target: 0,
          valueRange: "--",
        },

      ],
      columns2: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
        },

        {
          title: "预测",
          dataIndex: "forcast",
          key: "forcast",
          scopedSlots: { customRender: "forcast" },
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
        },
      ],
      data2: [
        {
          key: "年度利润目标",
          name: "年度利润目标",
          unit: "元",
          forcast: 0,
          remark: "填入希望达成的利润目标",
        },
        {
          key: "2",
          name: "盈利达成台次",
          unit: "台",
          forcast: 0,
          remark: "（未覆盖费用+利润目标)/单车综合毛利",
        },
        {
          key: "3",
          name: "盈利达成单台毛利",
          unit: "元",
          forcast: 0,
          remark: "（未覆盖费用+利润目标)/终端目标",
        },
      ],
      columns3: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "实绩",
          dataIndex: "achievement",
          key: "achievement",
        },
        {
          title: "目标",
          dataIndex: "target",
          key: "target",
        },
        {
          title: "范围值",
          dataIndex: "valueRange",
          key: "valueRange",
        },
      ],
      data3: [
        {
          key: "C0407004",
          name: "总毛利率",
          achievement: "0%",
          target: "0%",
          valueRange: "3.5%-8.0%",
        },
        {
          key: "A0107002",
          name: "其中-销售业务毛利率",
          achievement: "0%",
          target: "0%",
          valueRange: "3%-8%",
          indentation: 1,

        },
        {
          key: "B0106026",
          name: "其中-售后业务毛利率",
          achievement: "0%",
          target: "0%",
          valueRange: "36%-55%",
          indentation: 1,
        },
        {
          key: "C0207022",
          name: "总费用率",
          achievement: "0%",
          target: "0%",
          valueRange: "3.6%-5.6%",

        },
        {
          key: "C0207028",
          name: "其中-人力费用率",
          achievement: "0%",
          target: "0%",
          valueRange: "2.0%-3.0%",
          indentation: 1,
        },
        {
          key: "C0408021",
          name: "税前利润率",
          achievement: "0%",
          target: "0%",
          valueRange: "0.5%-3.0%",
        },

      ],
      columns4: [
        {
          title: "指标名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "预测",
          dataIndex: "forcast",
          key: "forcast",
        },
        {
          title: "范围值",
          dataIndex: "valueRange",
          key: "valueRange",
        },
      ],
      data4: [
        {
          key: "C0305011",
          name: "前后端产值比",
          forcast: "0%",
          valueRange: "10~15",
        },
        {
          key: "B0107002",
          name: "零服吸收率",
          forcast: "0%",
          valueRange: "40%~120%",
        },
      ],
      columns5: [
        {
          title: "项目",
          colSpan: 4,
          dataIndex: "title1",
          width: 100,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            //终端目标
            if(index === 0) {
              obj.attrs.rowSpan = 28;
              obj.attrs.colSpan = 2;
            }
            if(index > 0 && index < 28)
              obj.attrs.colSpan = 0;
            //收入预测
            if(index === 28) {
              obj.attrs.rowSpan = 4;
              obj.attrs.colSpan = 2;
            }
            if(index > 28 && index < 28 + 4)
              obj.attrs.colSpan = 0;
            //毛利预测
            if(index === 28 + 4) {
              obj.attrs.rowSpan = 17;
            }
            if(index > 28 + 4 && index < 28 + 4 + 22)
              obj.attrs.colSpan = 0;
            //费用预测
            if(index === 23 + 4 + 22) {
              obj.attrs.rowSpan = 14;
              obj.attrs.colSpan = 2;
            }
            if(index > 23 + 4 + 22 && index < 23 + 4 + 22 + 14)
              obj.attrs.colSpan = 0;
            return obj;
          },
        },
        {
          title: "title2",
          colSpan: 0,
          dataIndex: "title2",
          width: 100,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if(index >= 0 && index < 28) {
              obj.attrs.colSpan = 0;
            }
            // 收入预测
            if(index >= 28 && index < 28 + 4) {
              obj.attrs.colSpan = 0;
            }
            if(index === 28 + 4)
              obj.attrs.rowSpan = 13;
            // 销售毛利预测
            if(index > 28 + 4 && index < 28 + 4 + 13)
              obj.attrs.colSpan = 0;
            if(index === 28 + 4 + 13)
              obj.attrs.rowSpan = 4;

            if(index > 28 + 4 + 13 && index <= 28 + 4 + 13 + 4)
              obj.attrs.colSpan = 0;

            if(index > 28 + 4 + 13 + 4)
              obj.attrs.colSpan = 0;
            return obj;
          },
        },
        {
          title: "title3",
          colSpan: 0,
          dataIndex: "title3",
          width: 150,
          // customRender: renderContent
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: { colSpan: 1 },
            };
            // // 终端目标开始
            if(index === 1) {
              obj.attrs.rowSpan = 4;
            }
            if(index > 1 && index < 5) {
              obj.attrs.rowSpan = 0;
            }
            if(index === 5) {
              obj.attrs.rowSpan = 12;
            }
            if(index > 5 && index < 17) {
              obj.attrs.rowSpan = 0;
            }
            if(index === 17) {
              obj.attrs.rowSpan = 11;
            }
            if(index > 17 && index < 28) {
              obj.attrs.rowSpan = 0;
            }
            // // 终端目标结束
            // //收入预测开始
            if(index === 28) {
              obj.attrs.rowSpan = 2;
            }
            if(index > 28 && index < 30) {
              obj.attrs.rowSpan = 0;
            }
            if(index === 30) {
              obj.attrs.rowSpan = 2;
            }
            if(index > 30 && index < 32) {
              obj.attrs.rowSpan = 0;
            }
            // //收入预测结束
            //
            // //毛利预测开始
            if(index === 32) {
              obj.attrs.rowSpan = 12;
            }
            if(index > 32 && index < 44) {
              obj.attrs.rowSpan = 0;
            }
            // // 空白栏预留
            if(index === 45)
              obj.attrs.rowSpan = 4;

            if(index > 45 && index < 49)
              obj.attrs.colSpan = 0;
            //毛利预测结束

            //费用预测开始
            if(index === 50)
              obj.attrs.rowSpan = 2;

            if(index > 50 && index < 52)
              obj.attrs.colSpan = 0;

            if(index === 52 + 1)
              obj.attrs.rowSpan = 2;

            if(index > 52 + 1 && index < 52 + 3)
              obj.attrs.colSpan = 0;

            if(index === 52 + 3)
              obj.attrs.rowSpan = 5;

            if(index > 52 + 3 && index < 52 + 3 + 5)
              obj.attrs.colSpan = 0;

            if(index === 52 + 3 + 5)
              obj.attrs.rowSpan = 2;

            if(index > 52 + 3 + 5 && index < 52 + 3 + 5 + 2)
              obj.attrs.colSpan = 0;
            //费用预测结束
            return obj;
          },
        },
        {
          title: "title4",
          colSpan: 0,
          dataIndex: "title4",
          width: 150,
          customRender: renderContent,
        },
        {
          title: "去年实绩",
          dataIndex: "lastYearAchievement",
          width: 150,
          customRender: renderContent,
        },
        {
          title: "今年目标",
          dataIndex: "currentTarget",
          width: 150,
          scopedSlots: { customRender: "currentTarget" },
        },
        {
          title: "今年实绩",
          dataIndex: "currentAchievement",
          customRender: renderContent,
          width: 150,
        },
        {
          title: "今年目标完成率",
          dataIndex: "currentCompleteRate",
          width: 150,
          scopedSlots: { customRender: "currentCompleteRate" },
        },
      ],
      data5: [
        {
          key: "A0303004",
          code: "A0303004",
          title1: "终端目标",
          title2: "",
          title3: "年度目标",
          title4: "年终端目标",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,

        },
        {
          key: "A0302021",
          code: "A0302021",
          title1: "终端目标",
          title2: "",
          title3: "分通道目标",
          title4: "展厅",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0302022",
          code: "A0302022",
          title1: "终端目标",
          title2: "",
          title3: "分通道目标",
          title4: "IDCC",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0302023",
          code: "A0302023",
          title1: "终端目标",
          title2: "",
          title3: "分通道目标",
          title4: "二网",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0302024",
          code: "A0302024",
          title1: "终端目标",
          title2: "",
          title3: "分通道目标",
          title4: "大客户",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "1",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "1月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "2",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "2月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "3",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "3月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "4",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "4月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "5",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "5月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "6",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "6月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "7",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "7月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "8",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "8月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "9",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "9月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "10",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "10月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "11",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "11月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "12",
          code: "A0302001",
          title1: "终端目标",
          title2: "",
          title3: "月度目标",
          title4: "12月",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
       /* {
          key: "A0303311",
          code: "A0303311",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS3",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },*/
        {
          key: "A0303312",
          code: "A0303312",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS3 POWER",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0303313",
          code: "A0303313",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS4",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
/*
        {
          key: "A0303314",
          code: "A0303314",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS4 PHEV",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0303315",
          code: "A0303315",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS4 COUPE",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
*/

        {
          key: "A0303316",
          code: "A0303316",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS4 PLUS",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
/*        {
          key: "A0303317",
          code: "A0303317",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS7",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },*/
        {
          key: "A0303318",
          code: "A0303318",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS8",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
/*        {
          key: "A0303319",
          code: "A0303319",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GS8S",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },*/
        {
          key: "A0303320",
          code: "A0303320",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "M6",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0303321",
          code: "A0303321",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "M8",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0303322",
          code: "A0303322",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GA4",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0303323",
          code: "A0303323",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GA6",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0303324",
          code: "A0303324",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "GA8",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0303325",
          code: "A0303325",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "影豹",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0303326",
          code: "A0303326",
          title1: "终端目标",
          title2: "",
          title3: "分车型目标",
          title4: "其他",
          unit: "台",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0103001",
          code: "A0103001",
          title1: "收入预测",
          title2: "",
          title3: "销售业务",
          title4: "新车裸车销售收入",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0103024",
          code: "A0103024",
          title1: "收入预测",
          title2: "",
          title3: "销售业务",
          title4: "销售水平事业收入",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "B0103097",
          code: "B0103097",
          title1: "收入预测",
          title2: "",
          title3: "售后业务",
          title4: "售后维修业务收入",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "B0103099",
          code: "B0103099",
          title1: "收入预测",
          title2: "",
          title3: "售后业务",
          title4: "售后水平事业收入",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
      /*  {
          key: "A0106027",
          code: "A0106027",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS3",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },*/
        {
          key: "A0106028",
          code: "A0106028",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS3 POWER",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0106029",
          code: "A0106029",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS4",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
      /*  {
          key: "A0106030",
          code: "A0106030",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS4 PHEV",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106031",
          code: "A0106031",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS4 COUPE",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, */
        {
          key: "A0106032",
          code: "A0106032",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS4 PLUS",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
     /*   {
          key: "A0106033",
          code: "A0106033",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS7",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },*/
        {
          key: "A0106034",
          code: "A0106034",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS8",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
      /*  {
          key: "A0106035",
          code: "A0106035",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GS8S",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, */
        {
          key: "A0106036",
          code: "A0106036",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "M6",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106037",
          code: "A0106037",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "M8",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106038",
          code: "A0106038",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GA4",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106039",
          code: "A0106039",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GA6",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106040",
          code: "A0106040",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "GA8",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106041",
          code: "A0106041",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "影豹",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0106042",
          code: "A0106042",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "其他",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0107003",
          code: "A0107003",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "分车型单车综合毛利（一级+二级毛利之和）",
          title4: "单车销售综合毛利",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        }, {
          key: "A0105072",
          code: "A0105072",
          useSum: true,
          title1: "毛利预测",
          title2: "销售毛利预测",
          title3: "单车综合毛利（三级毛利）",
          title4: "单车水平事业毛利",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "B0302001",
          code: "B0302001",

          title1: "毛利预测",
          title2: "售后毛利预测",
          title3: "",
          title4: "来厂台次",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "B0104226",
          code: "B0104226",
          useSum: true,
          title1: "毛利预测",
          title2: "售后毛利预测",
          title3: "",
          title4: "单车售后维修业务收入",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "B0105160",
          code: "B0105160",
          useSum: true,
          title1: "毛利预测",
          title2: "售后毛利预测",
          title3: "",
          title4: "单车售后维修业务毛利",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "B0105134",
          code: "B0105134",
          useSum: true,
          title1: "毛利预测",
          title2: "售后毛利预测",
          title3: "",
          title4: "单车售后水平事业毛利",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0206001",
          code: "C0206001",

          title1: "费用预测",
          title3: "总费用",
          title4: "总费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0202020",
          code: "C0202020",

          title1: "费用预测",
          title3: "固定费用",
          title4: "固定费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0202026",
          code: "C0202026",
          title1: "费用预测",
          title3: "固定费用",
          title4: "其中-摊销及折旧",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0205010",
          code: "C0205010",
          title1: "费用预测",
          title3: "半固定费用",
          title4: "半固定费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0202238",
          code: "C0202238",
          title1: "费用预测",
          title3: "财务费用",
          title4: "财务费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0202039",
          code: "A0202039",
          title1: "费用预测",
          title3: "财务费用",
          title4: "其中-厂家融资贴息",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0205005",
          code: "C0205005",
          title1: "费用预测",
          title3: "变动费用",
          title4: "变动费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0202007",
          code: "A0202007",
          title1: "费用预测",
          title3: "变动费用",
          title4: "广宣活动费",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0202043",
          code: "A0202043",
          title1: "费用预测",
          title3: "变动费用",
          title4: "其中-厂家活动返利",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0202262",
          code: "C0202262",
          title1: "费用预测",
          title3: "变动费用",
          title4: "垂直平台费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0202263",
          code: "C0202263",
          title1: "费用预测",
          title3: "变动费用",
          title4: "其中-厂家平台补贴",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: true,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "C0203020",
          code: "C0203020",
          title1: "费用预测",
          title3: "人力费用",
          title4: "人力费用",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
        {
          key: "A0202024",
          code: "A0202024",
          title1: "费用预测",
          title3: "人力费用",
          title4: "其中-销售顾问工资奖金",
          unit: "元",
          // currentCompleteRate高于100%是否向上
          arrowDirectionUp: false,
          lastYearAchievement: 0,
          currentTarget: 0,
          currentAchievement: 0,
          currentCompleteRate: 0,
        },
      ],
      //后端返回原始数据
      BackendData: {},
      //基础信息（非Code信息）
      basicInfo: {
        DealerCode: "",
        DealerName: "",
        Modifier: "",
        PartName: "",
        Itinerant: "",
        SalesTarget: 0,
        CityLevel: 0,
        OpenTime: "",
        OperatingStrategy: "",
        KeyMeasures: "",
        Status: "NONE",
      },
      //预测和实绩
      forecastAndAchievement: {
        //终端台次
        A0302001: {
          TargetValues: 0,
          CurrentValues: 0,
        },
        //单车销售毛利
        A0107004: {
          TargetValues: 0,
          CurrentValues: 0,
        },
        //来厂台次
        B0302001: {
          TargetValues: 0,
          CurrentValues: 0,
        },
        //单车售后毛利
        B0106027: {
          TargetValues: 0,
          CurrentValues: 0,
        },
        //总费用
        C0206001: {
          TargetValues: 0,
          CurrentValues: 0,
        },
        //税前利润
        C0408004: {
          TargetValues: 0,
          CurrentValues: 0,
        },
      },
    };
  },
  methods: {
    showTipsConform,
    handleDisable(record) {
      if(record.code == "A0303311") {
        return true;
      } else if(record.code == "A0303317") {
        return true;
      } else if(record.code == "A0303319") {
        return true;
      } else if(record.code == "A0303315") {
        return true;
      } else if(record.code == "A0303314") {
        return true;
      } else if(record.code == "A0106027") {
        return true;
      } else if(record.code == "A0106033") {
        return true;
      } else if(record.code == "A0106035") {
        return true;
      } else if(record.code == "A0106031") {
        return true;
      } else if(record.code == "A0106030") {
        return true;
      }
      return this.disabled;
    },
    handleTitle(record) {
      if(record.code == "A0303311" || record.code == "A0106027") {
        return "填入GS3 POWER";
      } else if(record.code == "A0303317" || record.code == "A0106033") {
        return "此系列已停产";
      } else if(record.code == "A0303319" || record.code == "A0106035") {
        return "填入其他车型";
      } else if(record.code == "A0303315" || record.code == "A0106031") {
        return "填入GS4";
      } else if(record.code == "A0303314" || record.code == "A0106030") {
        return "填入GS4";
      }
      return undefined;
    },
    getRateClass(record) {
      let classList = [];
      if(record.arrowDirectionUp) {
        if(record.currentCompleteRate > 100) {
          classList.push("green");
          classList.push("uparror");
        }
        if(record.currentCompleteRate < 100) {
          classList.push("red");
          classList.push("downarror");
        }
      }
      if(!record.arrowDirectionUp) {
        if(record.currentCompleteRate < 100) {
          classList.push("red");
          classList.push("uparror");
        }
        if(record.currentCompleteRate > 100) {
          classList.push("green");
          classList.push("downarror");
        }
      }

      return classList;
    },
    async doSave(status) {
      let targetValues = this.getTargetValues();
      //分车型目标应当等于年度目标
      let sumCarTypeTarget =
          targetValues.A0303311 +
          targetValues.A0303312 +
          targetValues.A0303313 +
          targetValues.A0303314 +
          targetValues.A0303315 +
          targetValues.A0303316 +
          targetValues.A0303317 +
          targetValues.A0303318 +
          targetValues.A0303319 +
          targetValues.A0303320 +
          targetValues.A0303321 +
          targetValues.A0303322 +
          targetValues.A0303323 +
          targetValues.A0303324 +
          targetValues.A0303325 +
          targetValues.A0303326;

      if(sumCarTypeTarget !== targetValues.A0303004) {
        this.$message.warning("【分车型目标】累计应与【年终端目标】相等");
        return null;
      }
      let monthTargetValues = this.getMonthTargetValues();
      let sumMonthTarget =
          monthTargetValues.A0302001["1"] +
          monthTargetValues.A0302001["2"] +
          monthTargetValues.A0302001["3"] +
          monthTargetValues.A0302001["4"] +
          monthTargetValues.A0302001["5"] +
          monthTargetValues.A0302001["6"] +
          monthTargetValues.A0302001["7"] +
          monthTargetValues.A0302001["8"] +
          monthTargetValues.A0302001["9"] +
          monthTargetValues.A0302001["10"] +
          monthTargetValues.A0302001["11"] +
          monthTargetValues.A0302001["12"];
      if(sumMonthTarget !== targetValues.A0303004) {
        this.$message.warning("【月度目标】累计应与【年终端目标】相等");
        return null;
      }
      let saveFunc = async () => await this.executeSaveRequest(status, targetValues, monthTargetValues);
      await this.showTipsConform(targetValues, saveFunc);
    },
    async executeSaveRequest(status, targetValues, monthTargetValues) {
      let res = await this.$Http.SaveAnnual({
        year: this.selectedDate,
        operatingStrategy: this.basicInfo.OperatingStrategy,
        keyMeasures: this.basicInfo.KeyMeasures,
        targetValues: targetValues,
        monthTargetValues: monthTargetValues,
        Status: status,
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      await this.getData();
    },
    //获取用户填报的数据
    getTargetValues() {
      // 获取table2的用户填入数据
      let table2TargetValues = {
        "年度利润目标": this._.findWhere(this.data2, { key: "年度利润目标" }).forcast,
      };
      // 获取table5的用户填入数据
      let table5TargetValues = {};
      let table5keys = this._.uniq(this._.pluck(this.data5, "code"));
      table5keys.forEach(key => {
        if(key !== "A0302001") {
          let val = this._.findWhere(this.data5, { code: key }).currentTarget;
          Object.assign(table5TargetValues, { [key]: val });
        }
      });

      // let table1TargetValues = {};
      // let table1Keys = this._.uniq(this._.pluck(this.data, 'key'));
      // table1Keys.forEach(key => {
      //     let val = this._.findWhere(this.data, {key: key}).currentAchievement;
      //     Object.assign(table1TargetValues, {[key]: val});
      // })
      // let table3TargetValues = {};
      // let table3Keys = this._.uniq(this._.pluck(this.data3, 'key'));
      // table3Keys.forEach(key => {
      //     let val = this._.findWhere(this.data3, {key: key}).currentAchievement;
      //     Object.assign(table3TargetValues, {[key]: val});
      // });
      //
      // let table4TargetValues = {};
      let value = Object.assign(table2TargetValues, table5TargetValues);
      return new Proxy(value, {
        get: function(obj, prop) {
          return prop in obj ? obj[prop] : 0;
        }
      });
    },
    //获取按月填报数据
    getMonthTargetValues() {
      let obj = {};
      this._.where(this.data5, { code: "A0302001" }).forEach(item => {
        Object.assign(obj, { [item.key]: item.currentTarget });
      });
      return { "A0302001": obj };
    },
    handleChange(value, index) {
      let valueObj = this.data5[index];
      valueObj.currentTarget = parseInt(value);
      this.BackendData.TargetValues[valueObj.code + "_avg"] = valueObj.currentTarget;

      // 计算目标完成率
      if(valueObj.currentAchievement === 0 && valueObj.currentTarget !== 0)
        valueObj.currentCompleteRate = 0;
      else if(valueObj.currentTarget === 0)
        valueObj.currentCompleteRate = 100;
      else
        valueObj.currentCompleteRate = parseFloat(((valueObj.currentAchievement / valueObj.currentTarget) * 100).toFixed(1));

      //其中-厂家融资贴息,其中-厂家活动返利,其中-厂家平台补贴只能填负数
      // if (index === 64 || index === 67 || index === 69) {
      //     if (value > 0) {
      //         valueObj.currentTarget = 0
      //         this.$message.warning("该项不能大于0");
      //     }
      // }

      this.tableCalc();
      this.getTable2Data();

    },

    //表格中的无需手动填写的数据计算
    tableCalc() {
      // 只有单店进行计算
      if(this.isDealer) {
        //表格五
        //几个不能手填的指标计算
        //年终端目标 A0302021+A0302022+A0302023+A0302024
        let A0303004_obj = this._.findWhere(this.data5, { code: "A0303004" });
        A0303004_obj.currentTarget =
            parseInt((this._.findWhere(this.data5, { code: "A0302021" }).currentTarget +
                this._.findWhere(this.data5, { code: "A0302022" }).currentTarget +
                this._.findWhere(this.data5, { code: "A0302023" }).currentTarget +
                this._.findWhere(this.data5, { code: "A0302024" }).currentTarget).toFixed(0));
        this.BackendData.TargetValues["A0303004_avg"] = A0303004_obj.currentTarget;

        if(A0303004_obj.currentAchievement === 0 && A0303004_obj.currentTarget !== 0)
          A0303004_obj.currentCompleteRate = 0;
        else if(A0303004_obj.currentTarget === 0)
          A0303004_obj.currentCompleteRate = 100;
        else
          A0303004_obj.currentCompleteRate = parseFloat(((A0303004_obj.currentAchievement / A0303004_obj.currentTarget) * 100).toFixed(1));

        // 单车销售业务综合毛利:
        // (A0303311*A0106027+
        // A0303312*A0106028+
        // A0303313*A0106029+
        // A0303314*A0106030+
        // A0303315*A0106031+
        // A0303316*A0106032+
        // A0303317*A0106033+
        // A0303318*A0106034+
        // A0303319*A0106035+
        // A0303320*A0106036+
        // A0303321*A0106037+
        // A0303322*A0106038+
        // A0303323*A0106039+
        // A0303324*A0106040)/A0303004
        let A0107003_obj = this._.findWhere(this.data5, { code: "A0107003" });
        if(!this.BackendData.TargetValues["A0303004_avg"]
            || this.BackendData.TargetValues["A0303004_avg"] === 0) {
          A0107003_obj.currentTarget = 0;
        } else {
          A0107003_obj.currentTarget =
              parseInt(
                  (
                      (
                          (this.BackendData.TargetValues["A0303311_avg"] ?? 0) * (this.BackendData.TargetValues["A0106027_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303312_avg"] ?? 0) * (this.BackendData.TargetValues["A0106028_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303313_avg"] ?? 0) * (this.BackendData.TargetValues["A0106029_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303314_avg"] ?? 0) * (this.BackendData.TargetValues["A0106030_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303315_avg"] ?? 0) * (this.BackendData.TargetValues["A0106031_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303316_avg"] ?? 0) * (this.BackendData.TargetValues["A0106032_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303317_avg"] ?? 0) * (this.BackendData.TargetValues["A0106033_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303318_avg"] ?? 0) * (this.BackendData.TargetValues["A0106034_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303319_avg"] ?? 0) * (this.BackendData.TargetValues["A0106035_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303320_avg"] ?? 0) * (this.BackendData.TargetValues["A0106036_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303321_avg"] ?? 0) * (this.BackendData.TargetValues["A0106037_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303322_avg"] ?? 0) * (this.BackendData.TargetValues["A0106038_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303323_avg"] ?? 0) * (this.BackendData.TargetValues["A0106039_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303324_avg"] ?? 0) * (this.BackendData.TargetValues["A0106040_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303325_avg"] ?? 0) * (this.BackendData.TargetValues["A0106041_avg"] ?? 0) +
                          (this.BackendData.TargetValues["A0303326_avg"] ?? 0) * (this.BackendData.TargetValues["A0106042_avg"] ?? 0)
                      )
                      / (this.BackendData.TargetValues["A0303004_avg"])
                  )
                      .toFixed(0));
        }
        this.BackendData.TargetValues["A0107003_avg"] = A0107003_obj.currentTarget;
        if(A0107003_obj.currentAchievement === 0 && A0107003_obj.currentTarget !== 0)
          A0107003_obj.currentCompleteRate = 0;
        else if(A0107003_obj.currentTarget === 0)
          A0107003_obj.currentCompleteRate = 100;
        else
          A0107003_obj.currentCompleteRate = parseFloat(((A0107003_obj.currentAchievement / A0107003_obj.currentTarget) * 100).toFixed(1));

        // 单车售后维修业务收入
        // B0103097/B0302001
        let B0104226_obj = this._.findWhere(this.data5, { code: "B0104226" });
        if(this.BackendData.TargetValues["B0302001_avg"] && this.BackendData.TargetValues["B0302001_avg"] !== 0) {
          B0104226_obj.currentTarget =
              parseFloat(
                  ((this.BackendData.TargetValues["B0103097_avg"] ?? 0)
                      / this.BackendData.TargetValues["B0302001_avg"]).toFixed(0),
              );
        } else
          B0104226_obj.currentTarget = 0;
        // if (this._.findWhere(this.data5, {code: "B0302001"}).currentTarget !== 0)
        //     B0104226_obj.currentTarget =
        //         parseFloat(
        //             (this._.findWhere(this.data5, {code: "B0103097"}).currentTarget
        //                 / this._.findWhere(this.data5, {code: "B0302001"}).currentTarget).toFixed(0));
        // else
        //     B0104226_obj.currentTarget = 0;
        this.BackendData.TargetValues["B0104226_avg"] = B0104226_obj.currentTarget;
        if(B0104226_obj.currentAchievement === 0 && B0104226_obj.currentTarget !== 0)
          B0104226_obj.currentCompleteRate = 0;
        else if(B0104226_obj.currentTarget === 0)
          B0104226_obj.currentCompleteRate = 100;
        else
          B0104226_obj.currentCompleteRate =
              parseFloat(((B0104226_obj.currentAchievement / B0104226_obj.currentTarget) * 100).toFixed(1));

        // 总费用
        // C0202020+C0205010+C0202238+C0205005+C0203020
        let C0206001_obj = this._.findWhere(this.data5, { code: "C0206001" });
        C0206001_obj.currentTarget =
            parseInt((this._.findWhere(this.data5, { code: "C0202020" }).currentTarget +
                this._.findWhere(this.data5, { code: "C0205010" }).currentTarget +
                this._.findWhere(this.data5, { code: "C0202238" }).currentTarget +
                this._.findWhere(this.data5, { code: "C0205005" }).currentTarget +
                this._.findWhere(this.data5, { code: "C0203020" }).currentTarget).toFixed(0));
        this.BackendData.TargetValues["C0206001_avg"] = C0206001_obj.currentTarget;
        if(C0206001_obj.currentAchievement === 0 && C0206001_obj.currentTarget !== 0)
          C0206001_obj.currentCompleteRate = 0;
        else if(C0206001_obj.currentTarget === 0)
          C0206001_obj.currentCompleteRate = 100;
        else
          C0206001_obj.currentCompleteRate = parseFloat(((C0206001_obj.currentAchievement / C0206001_obj.currentTarget) * 100).toFixed(1));
      }

      //表格一
      // 销售业务综合毛利
      this._.findWhere(this.data, { key: "A0106024" }).target =
          ((
                  this._.findWhere(this.data5, { code: "A0107003" }).currentTarget
                  + this._.findWhere(this.data5, { code: "A0105072" }).currentTarget)
              * this._.findWhere(this.data5, { code: "A0303004" }).currentTarget).toFixed(0);

      // 其中-单车销售综合毛利：单车销售业务综合毛利
      // 单车销售毛利
      if(this.BackendData.TargetValues["A0303004_avg"] && this.BackendData.TargetValues["A0303004_avg"] !== 0) {
        this._.findWhere(this.data, { key: "A0107004" }).target =
            Number(
                // (
                //     (this.BackendData.TargetValues["A0303311_avg"] ?? 0) * (this.BackendData.TargetValues["A0106027_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303312_avg"] ?? 0) * (this.BackendData.TargetValues["A0106028_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303313_avg"] ?? 0) * (this.BackendData.TargetValues["A0106029_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303314_avg"] ?? 0) * (this.BackendData.TargetValues["A0106030_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303315_avg"] ?? 0) * (this.BackendData.TargetValues["A0106031_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303316_avg"] ?? 0) * (this.BackendData.TargetValues["A0106032_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303317_avg"] ?? 0) * (this.BackendData.TargetValues["A0106033_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303318_avg"] ?? 0) * (this.BackendData.TargetValues["A0106034_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303319_avg"] ?? 0) * (this.BackendData.TargetValues["A0106035_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303320_avg"] ?? 0) * (this.BackendData.TargetValues["A0106036_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303321_avg"] ?? 0) * (this.BackendData.TargetValues["A0106037_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303322_avg"] ?? 0) * (this.BackendData.TargetValues["A0106038_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303323_avg"] ?? 0) * (this.BackendData.TargetValues["A0106039_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303324_avg"] ?? 0) * (this.BackendData.TargetValues["A0106040_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303325_avg"] ?? 0) * (this.BackendData.TargetValues["A0106041_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303326_avg"] ?? 0) * (this.BackendData.TargetValues["A0106042_avg"] ?? 0)
                // )
                // / (this.BackendData.TargetValues["A0303004_avg"])
                this._.findWhere(this.data5, { code: "A0107003" }).currentTarget
                + (this.BackendData.TargetValues["A0105072_avg"] ?? 0),
            ).toFixed(0);
      } else {
        return 0;
      }

      //售后业务综合毛利
      this._.findWhere(this.data, { key: "B0105113" }).target =
          ((this._.findWhere(this.data5, { code: "B0105160" }).currentTarget
                  + this._.findWhere(this.data5, { code: "B0105134" }).currentTarget)
              * this._.findWhere(this.data5, { code: "B0302001" }).currentTarget).toFixed(0);
      // 其中-单车售后综合毛利
      this._.findWhere(this.data, { key: "B0106027" }).target =
          ((this.BackendData.TargetValues["B0105160_avg"] ?? 0) +
              (this.BackendData.TargetValues["B0105134_avg"] ?? 0)).toFixed(0);

      // 总费用
      this._.findWhere(this.data, { key: "C0206001" }).target = this._.findWhere(this.data5, { code: "C0206001" }).currentTarget;
      // 其中-单车广宣费用
      this._.findWhere(this.data, { key: "A0205034" }).target =
          this.fixNaN(
              (
                  (this.BackendData.TargetValues["A0202007_avg"] ?? 0) - (this.BackendData.TargetValues["A0202043_avg"] ?? 0)
              ) / (this.BackendData.TargetValues["A0303004_avg"] ?? 0),
          ).toFixed(0);

      // 其中-单车广宣费用的去年实绩计算
      this._.findWhere(this.data, { key: "A0205034" }).achievement =
          this.fixNaN((this._.findWhere(this.data5, { code: "A0202007" }).lastYearAchievement -
                  this._.findWhere(this.data5, { code: "A0202043" }).lastYearAchievement)
              / this._.findWhere(this.data5, { code: "A0303004" }).lastYearAchievement).toFixed(0);
      // 其中-单车广宣费用的今年实绩计算
      this._.findWhere(this.data, { key: "A0205034" }).currentAchievement =
          this.fixNaN((this._.findWhere(this.data5, { code: "A0202007" }).currentAchievement -
                  this._.findWhere(this.data5, { code: "A0202043" }).currentAchievement)
              / this._.findWhere(this.data5, { code: "A0303004" }).currentAchievement).toFixed(0);

      // 税前利润
      // C0408004 = (A0107003+A0105072)*A0303004 + (B0105160 + B0105134) * B0302001 - C0206001
      this._.findWhere(this.data, { key: "C0408004" }).target =
          (
              (
                  this._.findWhere(this.data5, { code: "A0107003" }).currentTarget
                  + this._.findWhere(this.data5, { code: "A0105072" }).currentTarget
              )
              * this._.findWhere(this.data5, { code: "A0303004" }).currentTarget
              +
              (
                  this._.findWhere(this.data5, { code: "B0105160" }).currentTarget
                  + this._.findWhere(this.data5, { code: "B0105134" }).currentTarget
              )
              * this._.findWhere(this.data5, { code: "B0302001" }).currentTarget
              - this._.findWhere(this.data5, { code: "C0206001" }).currentTarget
          ).toFixed(0);

      //表格三
      //总毛利率

      this._.findWhere(this.data3, { key: "C0407004" }).target =
          (this.fixNaN(
              (
                  Number(this._.findWhere(this.data, { key: "A0106024" }).target) +
                  Number(this._.findWhere(this.data, { key: "B0105113" }).target)
              )
              /
              (
                  Number(this._.findWhere(this.data5, { code: "A0103001" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "A0103024" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103097" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103099" }).currentTarget)
              ),
          ) * 100).toFixed(1) + "%";

      // 其中-销售业务毛利率
      this._.findWhere(this.data3, { key: "A0107002" }).target =
          (this.fixNaN(Number(this._.findWhere(this.data, { key: "A0106024" }).target) /
              (
                  Number(this._.findWhere(this.data5, { code: "A0103001" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "A0103024" }).currentTarget)
              )) * 100).toFixed(1) + "%";
      // 其中-售后业务毛利率
      this._.findWhere(this.data3, { key: "B0106026" }).target =
          (this.fixNaN(Number(this._.findWhere(this.data, { key: "B0105113" }).target) /
              (
                  Number(this._.findWhere(this.data5, { code: "B0103097" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103099" }).currentTarget)
              )) * 100).toFixed(1) + "%";
      // 总费用率
      this._.findWhere(this.data3, { key: "C0207022" }).target =
          (this.fixNaN(Number(this._.findWhere(this.data5, { code: "C0206001" }).currentTarget) /
              (
                  Number(this._.findWhere(this.data5, { code: "A0103001" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "A0103024" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103097" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103099" }).currentTarget)
              )) * 100).toFixed(1) + "%";

      // 其中-人力费用率
      this._.findWhere(this.data3, { key: "C0207028" }).target =
          (this.fixNaN(Number(this._.findWhere(this.data5, { code: "C0203020" }).currentTarget) /
              (Number(this._.findWhere(this.data5, { code: "A0103001" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "A0103024" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103097" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103099" }).currentTarget))) * 100).toFixed(1) + "%";
      // 税前利润率
      this._.findWhere(this.data3, { key: "C0408021" }).target =
          (this.fixNaN(Number(this._.findWhere(this.data, { key: "C0408004" }).target) /
              (
                  Number(this._.findWhere(this.data5, { code: "A0103001" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "A0103024" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103097" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103099" }).currentTarget)
              )) * 100).toFixed(1) + "%";

      // 表格四
      //前后端产值比
      this._.findWhere(this.data4, { key: "C0305011" }).forcast =
          (this.fixNaN(
              (Number(this._.findWhere(this.data5, { code: "A0103001" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "A0103024" }).currentTarget)) /
              (Number(this._.findWhere(this.data5, { code: "B0103097" }).currentTarget) +
                  Number(this._.findWhere(this.data5, { code: "B0103099" }).currentTarget)),
          )).toFixed(1);

      // Number(this._.findWhere(this.data5, { code: "B0105160" }).currentTarget);
      //零服吸收率 ((B0105160+B0105134)*B0302001)/(C0206001-(C0205005*0.6)-A0202024)
      let val = 0;
      if(this.BackendData.TargetValues) {
        val = ((Number(this._.findWhere(this.data5, { code: "B0105160" }).currentTarget) + Number(this._.findWhere(this.data5, { code: "B0105134" }).currentTarget)) * Number(this._.findWhere(this.data5, { code: "B0302001" }).currentTarget)) / (Number(this._.findWhere(this.data5, { code: "C0206001" }).currentTarget) - (Number(this._.findWhere(this.data5, { code: "C0205005" }).currentTarget) * 0.6) - Number(this._.findWhere(this.data5, { code: "A0202024" }).currentTarget));
      }
      this._.findWhere(this.data4, { key: "B0107002" }).forcast =
          (this.fixNaN(val) * 100).toFixed(1) + "%";

      //利润预测
      //终端台次
      this.forecastAndAchievement.A0302001.TargetValues =
          this.fixNaN(Number(this._.findWhere(this.data5, { code: "A0303004" }).currentTarget).toFixed(0));
      // 单车销售毛利
      if(this.BackendData.TargetValues["A0303004_avg"] && this.BackendData.TargetValues["A0303004_avg"] !== 0) {
        this.forecastAndAchievement.A0107004.TargetValues =
            this.fixNaN(Number(
                // (
                //     (this.BackendData.TargetValues["A0303311_avg"] ?? 0) * (this.BackendData.TargetValues["A0106027_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303312_avg"] ?? 0) * (this.BackendData.TargetValues["A0106028_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303313_avg"] ?? 0) * (this.BackendData.TargetValues["A0106029_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303314_avg"] ?? 0) * (this.BackendData.TargetValues["A0106030_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303315_avg"] ?? 0) * (this.BackendData.TargetValues["A0106031_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303316_avg"] ?? 0) * (this.BackendData.TargetValues["A0106032_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303317_avg"] ?? 0) * (this.BackendData.TargetValues["A0106033_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303318_avg"] ?? 0) * (this.BackendData.TargetValues["A0106034_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303319_avg"] ?? 0) * (this.BackendData.TargetValues["A0106035_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303320_avg"] ?? 0) * (this.BackendData.TargetValues["A0106036_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303321_avg"] ?? 0) * (this.BackendData.TargetValues["A0106037_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303322_avg"] ?? 0) * (this.BackendData.TargetValues["A0106038_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303323_avg"] ?? 0) * (this.BackendData.TargetValues["A0106039_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303324_avg"] ?? 0) * (this.BackendData.TargetValues["A0106040_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303325_avg"] ?? 0) * (this.BackendData.TargetValues["A0106041_avg"] ?? 0) +
                //     (this.BackendData.TargetValues["A0303326_avg"] ?? 0) * (this.BackendData.TargetValues["A0106042_avg"] ?? 0)
                // )
                // / (this.BackendData.TargetValues["A0303004_avg"])
                this._.findWhere(this.data5, { code: "A0107003" }).currentTarget
                + (this.BackendData.TargetValues["A0105072_avg"] ?? 0),
            ).toFixed(0));
      } else {
        return 0;
      }

      // 来厂台次
      this.forecastAndAchievement.B0302001.TargetValues =
          this.fixNaN(Number(this._.findWhere(this.data5, { code: "B0302001" }).currentTarget).toFixed(0));
      // 单车售后毛利
      this.forecastAndAchievement.B0106027.TargetValues =
          this.fixNaN(((this.BackendData.TargetValues["B0105160_avg"] ?? 0) +
              (this.BackendData.TargetValues["B0105134_avg"] ?? 0)).toFixed(0));
      // 总费用
      this.forecastAndAchievement.C0206001.TargetValues =
          // Number(this._.findWhere(this.data, {key: "C0206001"}).target).toFixed(0);
          this.fixNaN(Number(this.BackendData.TargetValues["C0206001_avg"]).toFixed(0));
      // 税前利润
      // 因为这个的数据用的就是data5的，data5已经全部采用了均值
      this.forecastAndAchievement.C0408004.TargetValues =
          Number(this._.findWhere(this.data, { key: "C0408004" }).target).toFixed(0);
      // this.fixNaN(Number(this.BackendData.TargetValues["C0408004_avg"]).toFixed(0));

    },

    fixNaN(value) {
      return isNaN(value) ? 0 : value;
    },

    handleChange2(value, index) {
      this.data2[index].forcast = parseInt(value);
    },
    getShowInput(index) {
      return [0, 43, 46, 49].indexOf(index) <= -1;
    },
    changeTableTitle(yearMonth) {
      let strLastYear;
      let strCurrentYear;
      if(yearMonth) {
        strLastYear = yearMonth - 1 + "年";
        strCurrentYear = yearMonth + "年";
      } else {
        strLastYear = `${moment().add(-1, "years").format("YYYY")}年`;
        strCurrentYear = `${moment().format("YYYY")}年`;
      }

      this.columns[2].title = strLastYear + "实绩";
      this.columns[3].title = strCurrentYear + "目标";
      this.columns[4].title = strCurrentYear + "实绩";
      this.columns2[1].title = strCurrentYear + "预测";
      this.columns3[1].title = strLastYear + "实绩";
      this.columns3[2].title = strCurrentYear + "目标";
      this.columns4[1].title = strCurrentYear + "预测";
      this.columns5[4].title = strLastYear + "实绩";
      this.columns5[5].title = strCurrentYear + "目标";
      this.columns5[6].title = strCurrentYear + "实绩";
      this.columns5[7].title = strCurrentYear + "目标完成率";
    },
    //获取数据
    async getData() {
      // 利润报表Code
      if(!this.dealerCodeFromUser) {
        return;
      }
      if(!this.selectedDate) {
        return;
      }

      //清空盈利预测数据
      this.forecastAndAchievement.A0302001.TargetValues = 0;
      this.forecastAndAchievement.A0302001.CurrentValues = 0;
      this.forecastAndAchievement.A0107004.TargetValues = 0;
      this.forecastAndAchievement.A0107004.CurrentValues = 0;
      this.forecastAndAchievement.B0302001.TargetValues = 0;
      this.forecastAndAchievement.B0302001.CurrentValues = 0;
      this.forecastAndAchievement.B0106027.TargetValues = 0;
      this.forecastAndAchievement.B0106027.CurrentValues = 0;
      this.forecastAndAchievement.C0206001.TargetValues = 0;
      this.forecastAndAchievement.C0206001.CurrentValues = 0;
      this.forecastAndAchievement.C0408004.TargetValues = 0;
      this.forecastAndAchievement.C0408004.CurrentValues = 0;
      let codeList = [];
      // 利润预测和阶段实绩Code
      let forecastAndAchievement_CodeList = this._.keys(this.forecastAndAchievement);
      let table1_CodeList = this._.pluck(this.data, "key");
      let table2_CodeList = ["年度利润目标"];
      let table3_CodeList = this._.pluck(this.data3, "key");
      let table4_CodeList = this._.pluck(this.data4, "key");
      let table5_CodeList = this._.uniq(this._.pluck(this.data5, "code"));
      codeList = codeList
          .concat(forecastAndAchievement_CodeList)
          .concat(table1_CodeList)
          .concat(table2_CodeList)
          .concat(table3_CodeList)
          .concat(table4_CodeList)
          .concat(table5_CodeList);

      let queryParam = {
        year: this.selectedDate,
        filterCodes: codeList,
        avgFilterCodes: this._.uniq(["A0107003", "A0105072", "B0106027", "A0107004", "B0105134", "B0105160",
          "A0205034", "A0202007", "A0202043", "C0206001", "B0105113", "A0303004",
          "A0106027", "A0106028", "A0106029", "A0106030", "A0106031", "A0106032", "A0106033", "A0106034", "A0106035",
          "A0106036", "A0106037", "A0106038", "A0106039", "A0106040", "A0106041", "A0106042",
          "A0303311", "A0303312", "A0303313", "A0303314", "A0303315", "A0303316", "A0303317", "A0303318", "A0303319", "A0303320", "A0303321",
          "A0303322", "A0303323", "A0303324", "A0303325", "A0303326",
          "B0104226", "B0103097", "B0302001", "年度利润目标",
        ].concat(forecastAndAchievement_CodeList).concat(table5_CodeList).concat(table1_CodeList)),
        allMonthFilterCodes: ["A0302001"],
      };

      //证明选的是地区
      if(this.dealerCodeFromUser.length === "94AA0AD2-A667-421A-AEAD-72EC300F2696".length) {
        queryParam.partId = this.dealerCodeFromUser;
      }
      //选择销售店
      if(this.dealerCodeFromUser.length === "DSXA040".length) {
        queryParam.dealerCode = this.dealerCodeFromUser;
      }
      let res = await this.$Http.QueryAnnual(
          queryParam,
      );
      if(!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.BackendData = res.Data;
      // 基本信息+主要经营策略+当前保存状态（提交OR保存）
      this.basicInfo = this.BackendData;
      this.disabled = this.basicInfo.Status !== "NONE" || !this.isDealer;

      // 利润预测和阶段实绩
      forecastAndAchievement_CodeList.forEach(item => {
        //后端返回数据中的code，因为有些数据需要取得均值
        let dataCode = item;
        if(item === "A0302001" || item === "B0302001" || item === "C0206001" || item === "C0408004") {
          dataCode = item + "_avg";
        }
        //除数
        this.forecastAndAchievement[item].CurrentValues = (this.BackendData.CurrentValues[dataCode] ?? 0).toFixed(0);
      });
      // 利润报表
      table1_CodeList.forEach(item => {
        let itemObj = this._.findWhere(this.data, { key: item });
        // 保留位数
        let numBit = 0;
        //后端返回数据中的code，因为有些数据需要取得均值
        let dataCode = item;
        if (item !== "A0107004" && item !== "B0106027" && item !== "A0205034") {
          dataCode = item + "_avg";
        }
        itemObj.achievement = parseFloat((this.BackendData.YesteryearValues[dataCode] ?? 0).toFixed(numBit));
        itemObj.currentAchievement = parseFloat((this.BackendData.CurrentValues[dataCode] ?? 0).toFixed(numBit));
        itemObj.target = parseFloat((this.BackendData.TargetValues[dataCode] ?? 0).toFixed(numBit));
      });
      // 辅助工具-盈利达成测算模型
      table2_CodeList.forEach(item => {
        let itemObj = this._.findWhere(this.data2, { key: item });
        if(item === "年度利润目标") {
          item = item + "_avg";
        }
        if(this.BackendData.TargetValues[item]) {
          itemObj.forcast = parseInt(((this.BackendData.TargetValues[item]) ?? 0).toFixed(0));
        } else {
          itemObj.forcast = 0;
        }
      });
      // 关键KPI
      table3_CodeList.forEach(item => {
        let itemObj = this._.findWhere(this.data3, { key: item });
        itemObj.achievement = ((this.BackendData.YesteryearValues[item] ?? 0) * 100).toFixed(1) + "%";
        itemObj.target = ((this.BackendData.TargetValues[item] ?? 0) * 100).toFixed(1) + "%";
      });
      // 辅助工具-判断售前售后是否平衡经营
      table4_CodeList.forEach(item => {
        let itemObj = this._.findWhere(this.data4, { key: item });
        itemObj.forcast = ((this.BackendData.TargetValues[item] ?? 0) * 100).toFixed(1) + "%";
      });
      // 目标分解（A0302001这个code是要按月去赋值的）
      table5_CodeList.forEach(item => {
        //后端返回数据中的code，因为有些数据需要取得均值
        if(item !== "A0302001") {
          let itemObj = this._.findWhere(this.data5, { code: item });
          //全部取均值
          let dataCode = itemObj.useSum ? item : (item + "_avg");
          // 保留位数
          let numBit = 0;
          itemObj.lastYearAchievement = parseFloat((this.BackendData.YesteryearValues[dataCode] ?? 0).toFixed(numBit));
          itemObj.currentTarget = parseFloat((this.BackendData.TargetValues[item + "_avg"] ?? 0).toFixed(numBit));
          itemObj.currentAchievement = parseFloat((this.BackendData.CurrentValues[dataCode] ?? 0).toFixed(numBit));
        }
      });
      //获取月度数据
      //先清空全部月度数据
      for (let i = 1; i < 13; i++) {
        let itemObj = this._.findWhere(this.data5, { key: i.toString() });
        itemObj.lastYearAchievement = 0;
        itemObj.currentTarget = 0;
        itemObj.currentAchievement = 0;
      }
      //获取月度数据去年实绩
      let yesteryearKeys = this._.keys(this.BackendData.AllMonthYesteryearValues["A0302001"]);
      yesteryearKeys.forEach(item => {
        let itemObj = this._.findWhere(this.data5, { key: item });
        itemObj.lastYearAchievement = parseInt((this.BackendData.AllMonthYesteryearValues["A0302001"][item]).toFixed(0));
      });

      //获取月度数据今年目标
      let targetValueKeys = this._.keys(this.BackendData.AllMonthTargetValues["A0302001"]);
      targetValueKeys.forEach(item => {
        let itemObj = this._.findWhere(this.data5, { key: item });
        itemObj.currentTarget = parseInt((this.BackendData.AllMonthTargetValues["A0302001"][item]).toFixed(0));
      });

      //获取月度数据今年实绩
      let currentValueKeys = this._.keys(this.BackendData.AllMonthCurrentValues["A0302001"]);
      currentValueKeys.forEach(item => {
        let itemObj = this._.findWhere(this.data5, { key: item });
        itemObj.currentAchievement = parseInt((this.BackendData.AllMonthCurrentValues["A0302001"][item]).toFixed(0));
      });

      //前端计算表格5目标完成率
      this.data5.forEach(item => {
        if(item.currentAchievement === 0 && item.currentTarget !== 0)
          item.currentCompleteRate = 0;
        else if(item.currentTarget === 0)
          item.currentCompleteRate = 100;
        else
          item.currentCompleteRate = parseFloat(((item.currentAchievement / item.currentTarget) * 100).toFixed(1));
      });

      this.tableCalc();

      this.getTable2Data();

    },
    // 获取表格二的数据
    getTable2Data() {
      const valData = (val) => {
        return this._.findWhere(this.data5, { code: val }).currentTarget ;
      };
//      ((B0105160+B0105134)*B0302001))/（C0206001-（C0205005*0.6）-A0202024）
      let fwmzl = (((valData("B0105160") + valData("B0105134"))) * valData("B0302001")) / (valData("C0206001") - (valData("C0205005") * 0.6) - valData("A0202024"));
      /*let fwmzl = this.fixNaN(
          this._.findWhere(this.data, { key: "B0105113" }).target /
          this._.findWhere(this.data, { key: "C0206001" }).target);*/
      // 辅助工具-盈利达成测算模型开始
      // 盈利达成台次
      // 当服务满足率C0207021>=1或者单车销售综合毛利A0107004<0，满足其中一条就显示"-",
      // 否则显示(总费用C0206001-售后业务综合毛利B0105113+年度利润目标)/单车销售综合毛利A0107004)
      if(fwmzl >= 1
          || this._.findWhere(this.data, { key: "A0107004" }).target < 0
      ) {
        this.data2[1].forcast = "-";
      } else {
        let value = 0;
        if(
            (this.BackendData.TargetValues["A0107003_avg"] && this.BackendData.TargetValues["A0107003_avg"] !== 0) ||
            (this.BackendData.TargetValues["A0105072_avg"] && this.BackendData.TargetValues["A0105072_avg"] !== 0)
        ) {
          value = (
                  this._.findWhere(this.data, { key: "C0206001" }).target
                  // (this.BackendData.TargetValues["C0206001_avg"] ?? 0)
                  - (
                      this._.findWhere(this.data, { key: "B0105113" }).target
                      // (this.BackendData.TargetValues["B0105160_avg"] ?? 0)
                      // + (this.BackendData.TargetValues["B0105134_avg"] ?? 0)
                  )
                  // * (this.BackendData.TargetValues["B0302001_avg"] ?? 0)
                  + this.data2[0].forcast)

              / (
                  this._.findWhere(this.data, { key: "A0107004" }).target
              );

        }
        this.data2[1].forcast = parseInt(this.fixNaN(value).toFixed(0));
      }
      // 盈利达成单台毛利
      // 当服务满足率C0207021>=1,
      // 满足条件显示"-",否则显示(总费用C0206001-售后业务综合毛利B0105113+年度利润目标)/年终端目标A0303004)
      if(fwmzl >= 1) {
        this.data2[2].forcast = "-";
      } else {
        let value = 0;
        if(this.BackendData.TargetValues["A0303004_avg"] && this.BackendData.TargetValues["A0303004_avg"] !== 0) {
          value = (
                  this._.findWhere(this.data, { key: "C0206001" }).target
                  // (this.BackendData.TargetValues["C0206001_avg"] ?? 0)
                  - (
                      this._.findWhere(this.data, { key: "B0105113" }).target
                      // ((this.BackendData.TargetValues["B0105160_avg"] ?? 0)
                      //     + (this.BackendData.TargetValues["B0105134_avg"] ?? 0))
                      // * (this.BackendData.TargetValues["B0302001_avg"] ?? 0)
                  )
                  + this.data2[0].forcast
              ) /
              // this.BackendData.TargetValues["A0303004_avg"]
              this._.findWhere(this.data5, { key: "A0303004" }).currentTarget;
        }
        this.data2[2].forcast = parseInt(this.fixNaN(value).toFixed(1));
      }
      //辅助工具-盈利达成测算模型结束
    },
    async getUserDealerInfo() {
      let res = await this.$Http.getCurrDealerInfo();
      if(res.Success) {
        this.dealerCodeFromUser = res.Data.DealerCode;
      }
    },
  }
  ,
  async mounted() {
    this.changeTableTitle(null);
    await this.getUserDealerInfo();
    await this.getData();
  },
};
</script>

<style lang="less" scoped>

.uparror:before {
  content: "↑";
  }

.downarror:before {
  content: "↓";
  }

.green {
  color: #248A74;
  }

.red {
  color: #24619e;
  }

.part2 {
  .title {
    width: 100%;
    height: 50px;
    background-color: #248A74;
    font-weight: bold;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: white;
    }

  .operator {
    font-size: 40px;
    line-height: 80px;
    }

  .content {
    height: 120px;

    .item {
      width: 160px;
      height: 100px;
      background-color: #FFF9E0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 1rem;

      .item-title {
        font-size: 18px;
        font-weight: bold;
        }

      .item-num {
        color: #248A74;
        font-size: 24px;
        }
      }
    }


  }

.part3 {
  .table-item {
    width: 50vw;

    .title {
      font-weight: bold;
      font-size: 16px;
      }

    .text {
      padding-top: 30px;
      font-size: 14px;
      color: #248A74;
      }
    }
  }

.part4 {
  .title {
    width: 100%;
    height: 50px;
    background-color: #248A74;
    font-weight: bold;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: white;
    }
  }

.part3 {
  ::v-deep {

    .ant-table-thead > tr > th {
      padding: 5px 5px 5px 7px;
      //  border-bottom: 2px solid #86A3A1 !important;
      border-right: 0 solid #ffffff !important;
      border-left: 0 solid #ffffff !important;
      font-size: 12px;
      background: #248A74;
      color: #fff;
      text-align: right;

      &:first-child {
        text-align: left;
        }
      }

    .ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table {
      // border-top: 2px solid #86A3A1;
      border-bottom: 1px solid #86A3A1;
      border-left: 0;
      }

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: none;
      }

    .ant-table-tbody .ant-table-row {
      &:nth-child(even) {
        background: #E6ECEC !important;
        }

      &:hover {
        td {
          color: #405E5C !important;
          }

        background: #adcbca !important;
        box-shadow: 1px 2px 5px 1px #768e86
        }

      td {
        font-size: 12px;
        padding: 5px 5px;
        text-align: right;
        border-right: 0 solid #fff;
        border-bottom: 0 solid #fff;
        color: #3E5E5C;

        &:first-child {
          text-align: left;
          }

        }
      }
    }
  }

.part4 {
  ::v-deep {
    .ant-table-thead > tr > th {
      padding: 5px 5px 5px 7px;
      //border-right: 0 solid #ffffff !important;
      //border-left: 0 solid #ffffff !important;
      font-size: 14px;
      background: #F2F5F8;
      text-align: center;
      }

    .ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table {
      // border-top: 2px solid #86A3A1;
      border-bottom: 1px solid #86A3A1;
      }

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: none;
      }

    .ant-table-tbody .ant-table-row {

      //&:nth-child(even) {
      //    background: #E6ECEC !important;
      //}

      //&:hover {
      //    td {
      //        color: #405E5C !important;
      //    }
      //
      //    background: #adcbca !important;
      //    box-shadow: 1px 2px 5px 1px #768e86
      //}

      td {
        font-size: 12px;
        padding: 5px 5px;
        text-align: center;
        color: #3E5E5C;

        &:first-child {
          text-align: center;
          }

        }
      }
    }
  }


::v-deep .ant-table-row-cell-break-word {
  font-size: 14px !important;
  }

::v-deep .ant-table-row input {
  text-align: right;
  }

::v-deep .ant-table-row-cell-break-word input {
  text-align: center;
  }


::v-deep .ant-table-thead th {
  font-size: 14px !important;
  }

::v-deep .ant-table-row td {
  font-size: 14px !important;
  }

::v-deep .ant-input[disabled] {
  color: rgb(62, 94, 92);
  }

.part5 {
  .title {
    width: 100%;
    height: 50px;
    background-color: #248A74;
    font-weight: bold;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: white;
  }
}
</style>
