<template>
  <div>
    <a-card style="margin-bottom: 10px">
      生效时间：
      <a-range-picker style="margin-right: 20px"
                      format="YYYY-MM" :placeholder="['开始月份', '结束月份']"
                      :value="search.dateRange" :mode="search.dataMode"
                      @change="dateHandleChange" @panelChange="dateHandlePanelChange"/>
      指标编码：
      <a-input v-model="search.kpiCode" style="width: 200px;margin-right: 20px;" placeholder="请输入完整的指标编码"
               @pressEnter="refreshData(1)"/>
      <a-button type="primary" @click="refreshData(1)">搜索</a-button>
      <a-button type="primary" style="margin-left: 30px" @click="addRecord">
        <a-icon type="file-add"/>
        添加
      </a-button>
    </a-card>
    <a-card>
      <a-table :rowKey="(record, index) => index" :columns="columns" :data-source="data" :pagination="false">
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" @click="deleteRecord(record)">删除</a-button>
      </span>
      </a-table>
      <a-pagination class="page"
                    size="small" v-model="search.page.pageIndex" :total="search.page.totalCount"
                    :pageSize="search.page.pageSize"
                    :show-total="(total, range) => `${range[0]} 到 ${range[1]} (共 ${total} 条)`"
                    @change="refreshData" @showSizeChange="refreshData"
                    show-size-changer show-quick-jumper/>
    </a-card>
    <!--信息窗口-->
    <a-modal v-model="modifyForm.visible" title="添加标杆" okText="保存" @ok="saveRecord">
      <a-form-model ref="modifyForm" :model="modifyForm.data" :label-col="{span:6}" :wrapper-col="{span:12}"
                    :rules="modifyForm.rules">
        <a-form-model-item label="指标编码" prop="KpiCode">
          <a-input v-model="modifyForm.data.KpiCode" placeholder="请输入指标编码"/>
        </a-form-model-item>
        <a-form-model-item label="生效时间" prop="EffectiveDate">
          <a-month-picker style="width: 100%" placeholder="请选择生效时间" v-model="modifyForm.data.EffectiveDate"/>
        </a-form-model-item>
        <a-form-model-item label="标杆值" prop="NumValue">
          <a-input v-model="modifyForm.data.NumValue" placeholder="请输入标杆值"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text, record, index) => ++index
        },
      /*  {
          title: '指标编码',
          dataIndex: 'KpiCode',
          key: 'KpiCode',
        },*/
        {
          title: '指标名称',
          dataIndex: 'KpiName',
          key: 'KpiName',
        },
        {
          title: '生效时间',
          dataIndex: 'EffectiveDate',
          key: 'EffectiveDate',
          customRender: text => moment(text).format("YYYY年MM月")
        },
        {
          title: '标杆值',
          dataIndex: 'NumValue',
          key: 'NumValue',
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          scopedSlots: {customRender: 'Percentage'},
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ],
      data: [],
      search: {
        dateRange: [],
        dataMode: ["month", "month"],
        kpiCode: "",
        page: {
          pageIndex: 1,
          pageSize: 10,
          totalCount: 0
        },
      },
      modifyForm: {
        visible: false,
        data: {},
        rules: {
          KpiCode: {required: true, message: '请输入指标编码', trigger: ['change', 'blur'], whitespace: true},
          EffectiveDate: {
            required: true, validator: (rule, value, callback) => {
              if (value == null) {
                callback(new Error("请输入生效时间"));
              } else {
                callback();
              }
            }, trigger: ['change', 'blur']
          },
          NumValue: {
            required: true, validator: (rule, value, callback) => {
              value = value == null ? "" : value.trim();
              if (value.length <= 0 || Number.isNaN(Number(value))) {
                callback(new Error("请输入数字标杆指"));
              } else {
                callback();
              }
            }, trigger: ['change', 'blur']
          },
        }
      }
    };
  },
  created() {
    this.refreshData();
  },
  methods: {
    dateHandleChange(value) {
      this.search.dateRange = value;
    },
    dateHandlePanelChange(value, mode) {
      this.search.dateRange = value;
      this.search.dataMode = [mode[0] === "date" ? "month" : mode[0], mode[1] === "date" ? "month" : mode[1]];
    },
    async refreshData(pageIndex, pageSize) {
      if (pageIndex != null) {
        this.search.page.PageIndex = pageIndex;
      }
      if (pageSize != null) {
        this.search.page.PageSize = pageSize;
      }
      let pars = {};
      pars.kpiCode = this.search.kpiCode;
      if (this.search.dateRange.length === 2) {
        pars.effectiveDateStart = this.search.dateRange[0].format("YYYY-MM-01");
        pars.effectiveDateEnd = this.search.dateRange[1].format("YYYY-MM-01");
      }
      pars.pageIndex = this.search.page.pageIndex;
      pars.pageSize = this.search.page.pageSize;
      let res = await this.$Http.SearchIndustryStandard(pars);
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.search.page.pageIndex = res.PageIndex;
      this.search.page.pageSize = res.PageSize;
      this.search.page.totalCount = res.TotalCount;
      this.data = res.Data;
    },
    async deleteRecord(record) {
      let res = await this.$Http.DeleteIndustryStandard({
        KpiCode: record.KpiCode,
        EffectiveDate: record.EffectiveDate
      });
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      await this.refreshData(1);
    },
    addRecord() {
      if (this.$refs.modifyForm !== undefined)
        this.$refs.modifyForm.resetFields();

      this.modifyForm.data = {};
      this.modifyForm.visible = true;
    },
    async saveRecord() {
      let valid = await this.$refs.modifyForm.validate();
      if (!valid)
        return;

      let res = await this.$Http.SaveIndustryStandard(this.modifyForm.data);
      if (!res.Success) {
        this.$message.error(res.Message);
        return;
      }
      this.modifyForm.visible = false;
      await this.refreshData();
    },
  },
};
</script>
