import humanMannage from "@/views/humanMannage/list";
import humanForm from "@/views/humanMannage/form";
import changePwd from "@/views/humanMannage/changePwd";

import bgList from "@/views/bgMannage/list";
import bgForm from "@/views/bgMannage/form";
import areaList from "@/views/areaMannage/list";
import areaForm from "@/views/areaMannage/form";
import cityList from "@/views/cityMannage/list";
import cityForm from "@/views/cityMannage/form";
import positionList from "@/views/positionMannage/positionList";
import positionForm from "@/views/positionMannage/form";
import departmentList from "@/views/departmentMannage/list";
import departmentForm from "@/views/departmentMannage/form";
import companyList from "@/views/companyMannage/list";
import companyForm from "@/views/companyMannage/form";
import msgList from "@/views/msgMannage/list";
import msgForm from "@/views/msgMannage/form";
import Permissions from "@/views/authManagement/Permissions";
import AddPermissions from "@/views/authManagement/AddPermissiones";
import VChartsDemo from "@/components/VChartsDemo";
import MenuPermissions from "@/views/authManagement/MenuPermissiones";
import ViewPermissions from "@/views/authManagement/ViewPermissiones";
import msgCenter from "../views/msgCenter/form";
import itinerantList from "@/views/Itinerant/list"
import qujianList from "@/views/qujianMannage/list"
import dealerAccountMannageList from "@/views/dealerAccountMannage/list"

let systemSettingRouter = [
    //个人中心
    {
        path: "/personalCenter",
        component: humanForm,
    },
    //经销商账户管理
    {
        path: "/dealerAccountMannage",
        component: dealerAccountMannageList,
    },
    //修改密码
    {
        path: "/changePwd",
        component: changePwd,
    },
    /*人员管理*/
    {
        path: "/humanMannage/list",
        component: humanMannage,
    },
    //编辑人员
    {
        path: "/humanMannage/form",
        component: humanForm,
    },
    //新增人员
    {
        path: "/humanMannage/add",
        component: humanForm,
    },
    //大区列表
    {
        path: "/bgMannage/list",
        component: bgList,
    },
    //新增大区
    {
        path: "/bgMannage/add",
        component: bgForm,
    },
    //编辑大区
    {
        path: "/bgMannage/edit",
        component: bgForm,
    },
    //小区列表
    {
        path: "/areaMannage/list",
        component: areaList,
    },
    //新增小区
    {
        path: "/areaMannage/add",
        component: areaForm,
    },
    //编辑小区
    {
        path: "/areaMannage/edit",
        component: areaForm,
    },
    //城市列表
    {
        path: "/cityMannage/list",
        component: cityList,
    },
    //新增城市
    {
        path: "/cityMannage/add",
        component: cityForm,
    },
    //编辑城市
    {
        path: "/cityMannage/edit",
        component: cityForm,
    },
    // 职位列表
    {
        path: "/positionMannage/positionList",
        component: positionList,
    },
    //新增职位
    {
        path: "/positionMannage/add",
        component: positionForm,
    },
    //编辑职位
    {
        path: "/positionMannage/edit",
        component: positionForm,
    },
    // 部门列表
    {
        path: "/departmentMannage/list",
        component: departmentList,
    },
    //新增部门
    {
        path: "/departmentMannage/add",
        component: departmentForm,
    },
    //编辑部门
    {
        path: "/departmentMannage/edit",
        component: departmentForm,
    },
    //销售店列表
    {
        path: "/companyMannage/list",
        component: companyList,
    },
    //新增销售店
    {
        path: "/companyMannage/add",
        component: companyForm,
    },
    //编辑销售店
    {
        path: "/companyMannage/edit",
        component: companyForm,
    },
    //站内信列表
    {
        path: "/msgmannage/list",
        component: msgList,
    },
    //新增站内信
    {
        path: "/msgmannage/add",
        component: msgForm,
    },
    //编辑站内信
    {
        path: "/msgmannage/edit",
        component: msgForm,
    },
    //查看站内信
    {
        path: "/msgmannage/view",
        component: msgForm,
    },
    /*权限管理*/
    {
        path: "/permissions",
        component: Permissions,
    },
    /*编辑权限管理*/
    {
        path: "/VChartsDemo",
        component: VChartsDemo,
    },
    /*添加权限管理*/
    {
        path: "/addPermissions",
        component: AddPermissions,
    },
    /*查看权限菜单*/
    {
        path: "/viewPermissions",
        component: ViewPermissions,
    },
    /*配置权限菜单*/
    {
        path: "/menuPermissions",
        component: MenuPermissions,
    },
    //通知中心
    {
        path: "/msgCenter",
        component: msgCenter,
    },
    //巡回员列表
    {
        path: "/Itinerant/list",
        component: itinerantList
    },
    //区间管理列表
    {
        path:"/qujian/list",
        component:qujianList
    }
];
export default systemSettingRouter;
